<template>
  <div>
    <div
      :class="[
        { 'bg-theme-32': isActive },
        `loan-${getActiveApplicationQualityClass()}`
      ]"
      class="flex mt-1 p-4 box cursor-pointer mb-0 shadow-lg section-header"
      @click="setNavActiveSection('income-and-expense-details-title')"
    >
      <h2
        id="income-and-expense-details-title"
        class="font-medium text-theme-7 text-base w-full"
      >
        INCOME AND EXPENSE DETAILS
      </h2>
      <ChevronDownIcon :class="{ 'transform rotate-180': !isActive }" />
    </div>

    <div
      v-if="isActive"
      id="income-and-exp-dts-section"
      class="grid grid-cols-2 gap-6"
    >
      <template v-if="!fetchingIncomeExpensesDetails">
        <div class="grid col-span-12 gap-6">
          <div
            v-if="
              Object.keys(aggregatorIncomeExpenseDetailsTransitLobby).length > 0
            "
            :class="{
              'opacity-50 pointer-events-none': updatingIncomeExpenseDetails
            }"
            class="col-span-12"
          >
            <UpdateLoader :show="updatingIncomeExpenseDetails" />
            <!-- BEGIN: Personal Information -->
            <div class="box">
              <div class="p-5">
                <div class="grid grid-cols-12 gap-x-3">
                  <div
                    v-for="(aggInExpDetValue,
                    aggInExpDetKey) in aggregatorIncomeExpenseDetailsTransitLobby"
                    :key="aggInExpDetKey"
                    class="col-span-6"
                  >
                    <h4 class="font-medium text-theme-3 text-base mb-2 mt-1">
                      {{
                        aggInExpDetKey
                          .split("_")
                          .map(w => w.charAt(0).toUpperCase() + w.slice(1))
                          .join(" ")
                      }}
                    </h4>
                    <div id="income-dts-table" class="p-1 w-full">
                      <div class="preview">
                        <div class="overflow-x-auto">
                          <table class="w-full">
                            <colgroup>
                              <col class="p-3" style="width: 40%" />
                              <col class="p-3" style="width: 20%" />
                              <col class="p-3" style="width: 40%" />
                            </colgroup>
                            <thead>
                              <tr>
                                <th
                                  class="
                                    border-b-2
                                    dark:border-dark-5
                                    whitespace-nowrap
                                  "
                                >
                                  Heading
                                </th>
                                <th
                                  class="
                                    border-b-2
                                    dark:border-dark-5
                                    whitespace-nowrap
                                    pr-3
                                  "
                                >
                                  Declared
                                </th>
                                <th
                                  class="
                                    border-b-2
                                    dark:border-dark-5
                                    whitespace-nowrap
                                  "
                                >
                                  Bank Statement
                                </th>
                              </tr>
                            </thead>
                            <tbody :key="updateCounter">
                              <template
                                v-for="(aggInExpDetValDatum,
                                aggInExpDetValDatumKey,
                                aggInExpDetValDatumIndex) in aggInExpDetValue.data"
                                :key="aggInExpDetValDatumKey"
                              >
                                <tr
                                  :class="{
                                    'bg-gray-200 dark:bg-dark-1':
                                      aggInExpDetValDatumIndex % 2 === 0
                                  }"
                                >
                                  <td
                                    class="
                                      border-b
                                      dark:border-dark-5
                                    "
                                  >
                                    <ChevronDownIcon
                                      v-if="
                                        aggInExpDetValDatum.hasOwnProperty(
                                          'description'
                                        )
                                      "
                                      :class="{
                                        'transform rotate-180': activeDescriptions.includes(
                                          aggInExpDetValDatumKey
                                        )
                                      }"
                                      :title="
                                        `Click to update ${
                                          aggInExpDetValDatum['label']
                                            ? aggInExpDetValDatum['label']
                                            : ''
                                        } note`
                                      "
                                      class="w-4 h-4 cursor-pointer"
                                      v-on:click="
                                        updateActiveDescriptions(
                                          aggInExpDetValDatumKey
                                        )
                                      "
                                    />
                                    <span>
                                      {{ aggInExpDetValDatum["label"] }}
                                      <template
                                        v-if="
                                          aggInExpDetValDatum['verification']
                                        "
                                      >
                                        <span
                                          :class="
                                            `text-xs ${textClassBySource(
                                              aggInExpDetValDatum[
                                                'verification-source'
                                              ]
                                            )}`
                                          "
                                          >({{
                                            aggInExpDetValDatum["verification"]
                                          }})</span
                                        >
                                      </template>
                                    </span>
                                    <p
                                      v-if="
                                        expenseCategoryInfo(
                                          aggInExpDetValDatum['label']
                                        ).exist
                                      "
                                    >
                                      <InfoIcon
                                        :name="
                                          `iconA-${aggInExpDetValDatumKey}`
                                        "
                                        class="w-3 h-3 ml-2 mr-2 cursor-pointer"
                                      />
                                      <TippyContent
                                        :options="tippyOptions"
                                        :to="`iconA-${aggInExpDetValDatumKey}`"
                                      >
                                        <span class="text-xs">{{
                                          expenseCategoryInfo(
                                            aggInExpDetValDatum["label"]
                                          ).tooltipLabel
                                        }}</span>
                                      </TippyContent>
                                      <span class="text-xs"
                                        >{{
                                          expenseCategoryInfo(
                                            aggInExpDetValDatum["label"]
                                          ).value
                                        }}
                                      </span>
                                    </p>
                                    <template
                                      v-if="
                                        aggInExpDetValDatum?.history &&
                                          Object.keys(
                                            aggInExpDetValDatum?.history
                                          ).length &&
                                          updateRecordsExists(
                                            aggInExpDetValDatum?.history
                                          )
                                      "
                                    >
                                      <AlertCircleIcon
                                        :name="`icon-${aggInExpDetValDatumKey}`"
                                        class="w-4 h-4 cursor-pointer"
                                      />
                                      <TippyContent
                                        :options="tippyOptions"
                                        :to="`icon-${aggInExpDetValDatumKey}`"
                                      >
                                        <ServiceabilityChangeTimeline
                                          :category="aggInExpDetValDatum.label"
                                          :data="aggInExpDetValDatum?.history"
                                        />
                                      </TippyContent>
                                    </template>
                                  </td>
                                  <td class="border-b dark:border-dark-5">
                                    {{
                                      aggInExpDetValDatum[
                                        "customer-declared-value"
                                      ]
                                    }}
                                  </td>
                                  <td
                                    class="border-b dark:border-dark-5"
                                    style="min-width: 160px"
                                  >
                                    <LoanApplicationField
                                      :activeInputId="activeInputId"
                                      :details="aggInExpDetValDatum"
                                      :enable="true"
                                      :index="aggInExpDetValDatumIndex"
                                      :loading="updatingIncomeExpenseDetails"
                                      :name="aggInExpDetValDatumKey"
                                      :type="`income`"
                                      v-on:update="
                                        aggInExpDetValDatum[
                                          'assessment-analysis-value'
                                        ] = $event.value;
                                        onCloseAndSubmitAgreggatorInput(
                                          'IncomeExpenseDetails',
                                          true,
                                          $event.id
                                        );
                                        updateShouldChangeTab(false);
                                      "
                                    />
                                  </td>
                                </tr>
                                <tr
                                  v-if="
                                    aggInExpDetValDatum.hasOwnProperty(
                                      'description'
                                    ) &&
                                      activeDescriptions.includes(
                                        aggInExpDetValDatumKey
                                      )
                                  "
                                >
                                  <td colspan="3">
                                    <LoanApplicationDescriptionField
                                      :activeInputId="activeInputId"
                                      :details="aggInExpDetValDatum"
                                      :enable="true"
                                      :index="aggInExpDetValDatumIndex"
                                      :name="aggInExpDetValDatumKey"
                                      :type="`income`"
                                      v-on:update="
                                        aggInExpDetValDatum[
                                          'description'
                                        ] = $event;
                                        onCloseAndSubmitAgreggatorInputDescription(
                                          aggInExpDetValDatumKey,
                                          $event
                                        );
                                      "
                                    />
                                  </td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="
                    flex flex-col
                    sm:flex-row
                    items-center
                    p-5
                    border-b border-gray-200
                  "
                >
                  <!--                <div class="w-full sm:w-auto flex sm:ml-auto mt-1 sm:mt-0">-->
                  <!--                  <button-->
                  <!--                    v-if="enabledAgreggatorDataField === ''"-->
                  <!--                    type="button"-->
                  <!--                    class="btn btn-rounded bg-theme-3 w-1/2 text-white sm:w-auto mr-2"-->
                  <!--                    @click.prevent="onAggregatorIncomeExpenseDetailsSubmit"-->
                  <!--                  >-->
                  <!--                    <SaveIcon class="w-6 h-6 mr-2" /> Save Changes-->
                  <!--                  </button>-->
                  <!--                </div>-->
                </div>
              </div>
            </div>
            <!-- END: Personal Information -->
          </div>
          <noRecordDivTemplate v-else marginTop="0" />
        </div>
      </template>
      <FetchingRecordDivTemplate v-else marginTop="0" />
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, watch } from "vue";
import noRecordDivTemplate from "@/components/no-record-div-template/Main.vue";
import FetchingRecordDivTemplate from "@/components/fetching-record-div-template/Main";
import LoanApplicationField from "@/components/loan-application-details/loan-application-input-field/Main";
import LoanApplicationDescriptionField from "@/components/loan-application-details/loan-application-input-field/TextArea";
import ServiceabilityChangeTimeline from "../common/ServiceabilityChangeTimeline.vue";
import UpdateLoader from "../common/Loader.vue";
import { loanQuality } from "@/composables/loanQuality";

export default defineComponent({
  components: {
    noRecordDivTemplate,
    FetchingRecordDivTemplate,
    LoanApplicationField,
    LoanApplicationDescriptionField,
    ServiceabilityChangeTimeline,
    UpdateLoader
  },
  props: {
    aggregatorIncomeExpenseDetailsTransitLobby: {
      type: Object,
      required: true
    },
    setNavActiveSection: {
      type: Function,
      require: true,
      default: null
    },
    isActive: {
      type: Boolean,
      required: true,
      default: false
    },
    getAggregatorIncomeExpensesDetailsForLoanAssessment: {
      type: Function,
      required: true,
      default: null
    },
    adminUser: {
      type: Object,
      required: true
    },
    fetchingIncomeExpensesDetails: {
      type: Boolean,
      required: true,
      default: false
    },
    updatingIncomeExpenseDetails: {
      type: Boolean,
      required: true,
      default: false
    },
    enabledAgreggatorDataField: {
      type: String,
      required: true,
      default: ""
    },
    onAggregatorIncomeExpenseDetailsSubmit: {
      type: Function,
      required: true,
      default: null
    },
    onCloseAgreggatorInputBox: {
      type: Function,
      required: true,
      default: null
    },
    onCloseAndSubmitAgreggatorInput: {
      type: Function,
      required: true,
      default: null
    },
    onCloseAndSubmitAgreggatorInputDescription: {
      type: Function,
      required: true,
      default: null
    },
    onClickTransformToInput: {
      type: Function,
      required: true,
      default: null
    },
    activeDescriptions: {
      type: Array,
      required: true
    },
    updateActiveDescriptions: {
      type: Function,
      required: true,
      default: null
    },
    bulkActivateDescriptions: {
      type: Function,
      required: true,
      default: null
    },
    updateRecordsExists: {
      type: Function,
      required: true,
      default: null
    },
    updateShouldChangeTab: {
      type: Function,
      required: true,
      default: null
    },
    filterHistory: {
      type: Function,
      required: true
    },
    activeInputId: {
      type: String,
      required: true,
      default: ""
    },
    getKycAttributeValueByName: {
      type: Function,
      required: true,
      default: null
    }
  },
  setup(props) {
    const updateCounter = ref(0);
    const tippyOptions = {
      trigger: "mouseenter"
    };

    props.getAggregatorIncomeExpensesDetailsForLoanAssessment();

    watch(
      () => props.aggregatorIncomeExpenseDetailsTransitLobby,
      details => {
        if (!updateCounter.value) {
          let descriptions = [];
          for (let category in details) {
            Object.entries(details[category].data).forEach(([key, value]) => {
              if (value.description && value.description.length) {
                descriptions.push(key);
              }
            });
          }
          props.bulkActivateDescriptions(descriptions);
        }
        updateCounter.value++;
      },
      { deep: true }
    );

    watch(
      () => props.activeDescriptions,
      _ => updateCounter.value++
    );

    const operationsForExpenseCategoryType = {
      accommodation: function(info) {
        let accommodationDiffWhy = props.getKycAttributeValueByName(
          "accomodation_diff_why"
        );
        if (accommodationDiffWhy && accommodationDiffWhy !== "-") {
          info.exist = true;
          info.value = accommodationDiffWhy;
          info.tooltipLabel = "Accommodation Difference Reason";
        }
        return info;
      }
    };
    const expenseCategoryInfo = label => {
      let info = {
        exist: false,
        value: null,
        tooltipLabel: null
      };
      const operation = operationsForExpenseCategoryType[label.toLowerCase()];
      return operation ? operation(info) : info;
    };

    const textClassBySource = source => {
      return {
        income_expenses_analyses: "text-yellow-500",
        centrelink_statement_analyses: "text-green-500",
        personal_loan: "text-yellow-500"
      }[source];
    };

    const { getActiveApplicationQualityClass } = loanQuality();

    return {
      updateCounter,
      tippyOptions,
      expenseCategoryInfo,
      textClassBySource,
      getActiveApplicationQualityClass
    };
  }
});
</script>
<style scoped>
td {
  padding: 5px;
}

th {
  text-align: left;
}
</style>
