import { computed, ref, watch } from "vue";
import { useStore } from "@/store";

export const createMultipleSchedules = props => {
  const store = useStore();

  function getFormDefaults() {
    return {
      date: null,
      no_of_repayments: null,
      repayment_amount: null,
      type: "RSC",
      repayment_frequency: null
    };
  }
  const form = ref(getFormDefaults());

  store.commit("createMultipleSchedules/setApplicationId", props.applicationId);

  const isProcessing = computed(
    () => store.state.createMultipleSchedules.isProcessing
  );
  const validationErrors = computed(
    () => store.state.createMultipleSchedules.validationErrors
  );
  const errorMessage = computed(
    () => store.state.createMultipleSchedules.errorMessage
  );

  function submitCreateMultipleSchedules(form) {
    store.dispatch("createMultipleSchedules/createMultipleSchedules", form);
  }

  const createMultipleSchedulesSuccessCount = computed(
    () =>
      store.state.createMultipleSchedules.createMultipleSchedulesSuccessCount
  );

  function clearForm() {
    form.value = getFormDefaults();
  }

  watch(
    () => createMultipleSchedulesSuccessCount.value,
    () => {
      clearForm();
    }
  );

  return {
    form,
    submitCreateMultipleSchedules,
    validationErrors,
    errorMessage,
    isProcessing
  };
};
