import $ from "cash-dom";
export const loanQualityHelper = {
  color: null,
  default: {
    colors: {
      background: {
        32: "#dfdfdf",
        23: "#478a3c"
      },
      text: {
        25: "#C7D2FF"
      }
    }
  },
  qualities: {
    highest: "Highest",
    high: "High",
    medium: "Medium",
    low: "Low",
    default: "unknown-app",
    board: "unknown-board",
    bad: "bad"
  },
  colors: {
    highest: "#FFD700",
    high: "#90EE90",
    low: "#FFCCCB",
    medium: "#FED8B1",
    UnknownApp: "#DFDFDF",
    UnknownBoard: "#FFFFFF",
    bad: "#ff683b"
  },
  qualityMap: {
    "-1": "bad",
    1: "low",
    2: "medium",
    3: "high",
    4: "highest"
  },
  get col() {
    return this.color;
  },
  set col(val) {
    this.color = val;
  },
  getLoanQualityClassByQuality(score, section) {
    let quality = this.qualities["default"].toLowerCase();
    let color = this.colors[quality];
    let multiple = false;
    score = parseInt(score);
    //Case where multiple class can occur
    if (score === 0) {
      quality =
        section && section === "board"
          ? this.qualities["board"]
          : this.qualities["default"];
      multiple = section === "board";
      color =
        section === "board"
          ? this.colors["UnknownBoard"]
          : this.colors["UnknownApp"];
    } else if (this.qualityMap[score]) {
      quality = this.qualities[this.qualityMap[score]].toLowerCase();
      color = this.colors[quality];
    }
    return {
      quality,
      color,
      multiple
    };
  },
  setQualityIndicatorForPage(className) {
    $(document).ready(function() {
      cash(".section-header").each(function(index, element) {
        $(element)
          .removeClass("bg-theme-32")
          .addClass(className);
      });
    });
  },
  resetEverything(className) {
    cash(".section-header").each(function(index, element) {
      if ($(element).hasClass(className)) {
        $(element)
          .addClass("bg-theme-32")
          .removeClass(className);
      }
    });
  }
};
