import { computed, onMounted, ref, watch } from "vue";
import feather from "feather-icons";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { useStore } from "@/store";
import { useRoute } from "vue-router";
import { authGetterService } from "../services/auth/auth-getter-service";
import fileDownload from "js-file-download";
import { styleHelper } from "@/utils/styleHelper";
import loanApplicationStatus from "@/constants/loan-application-status";
import { handleErrorResponse } from "@/services/auth/responseHandler/handle-response-service";

export const loanApplications = props => {
  const tableRef = ref();
  const tabulator = ref();
  const searchType = ref("fullname");
  const searchTerm = ref();
  const baseUrl = `${process.env.VUE_APP_BASE_URL}/getLoanApplicationsWithCustomers`;

  const route = useRoute();
  const path = computed(() => route.path);
  const store = useStore();
  const adminUser = computed(() => store.state.auth.loggedUser);
  const componentTitle = ref(props.title);

  const showSpecificSearch = computed(() =>
    ["loan-applications", "search", "side-menu-search"].includes(route.name)
  );

  const getInitialFilter = function() {
    return props.customerId
      ? [
          {
            field: "user_id",
            type: "=",
            value: props.customerId
          }
        ]
      : [];
  };

  function onClickResolveAppDetailsAndReRoute(applicationId) {
    store.dispatch(
      "lnAppDtsRouter/resolveAndReRouteLnAssessmentDtsPage",
      applicationId
    );
  }

  const statuses = Object.values(loanApplicationStatus);

  const excelReportBlob = computed(
    () => store.state.disbursalPayout.excelReportBlob
  );

  watch(
    () => excelReportBlob.value,
    excelData => {
      fileDownload(excelData, "loan_applications.xlsx");
    }
  );

  onMounted(() => {
    initTabulator();
    reInitOnResizeWindow();
  });

  const initTabulator = () => {
    tabulator.value = new Tabulator(tableRef.value, {
      initialFilter: getInitialFilter(),
      ajaxURL: baseUrl,
      ajaxConfig: {
        method: "GET",
        headers: authGetterService.getAuthHeader()
      },

      ajaxResponse: function(url, params, response) {
        if (response.meta) {
          if (response.meta.last_page) {
            response["last_page"] = response.meta.last_page;
          }

          if (response.meta.current_page) {
            response["current_page"] = response.meta.current_page;
          }
        }
        return response;
      },
      filterMode: "remote",
      sortMode: "remote",
      printAsHtml: true,
      printStyled: true,
      reactiveData: true,
      pagination: true,
      paginationMode: "remote",
      paginationSize: 10,
      paginationSizeSelector: [10, 20, 30, 40],
      layout: "fitColumns",
      responsiveLayout: "collapse",
      placeholder: "No matching Loan applications / Customers found",
      columns: [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false
        },

        // For HTML table
        {
          title: "Application ID",
          minWidth: 120,
          responsive: 0,
          field: "id",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            if (!["Approved", "Applied"].includes(cell.getData().status)) {
              return `<div>
                <div class="font-medium whitespace-nowrap"><a target="_blank" href="/customer/${
                  cell.getData().customer.id
                }/loan-application/${cell.getData().id}"><u>${
                cell.getData().id
              }</u></a></div>
              </div>`;
            }
            const a = cash(`<div class="font-medium whitespace-nowrap"></div>`);
            cash(a).append(
              `<a target="_blank" ><u>${cell.getData().id}</u></a>`
            );
            cash(cash(a).children()[0]).on("click", function() {
              onClickResolveAppDetailsAndReRoute(cell.getData().id);
            });

            return a[0];
          }
        },
        {
          title: "Customer ID",
          minWidth: 100,
          width: 135,
          field: "customer.id",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="font-medium whitespace-nowrap"><a target="_blank" href="/loan-applications/customer/${
                  cell.getData().customer.id
                }"><u>${cell.getData().customer.id}</u></a></div>
              </div>`;
          }
        },
        {
          title: "Payer",
          minWidth: 120,
          field: "customer.full_name",
          hozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().customer.full_name
                }</div>
              </div>`;
          }
        },
        {
          title: "Loan Term",
          minWidth: 100,
          field: "loan_term",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${cell.getData().loan_term}</div>
              </div>`;
          }
        },
        {
          title: "Total Amount",
          minWidth: 120,
          field: "total_amount",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().total_amount
                }</div>
              </div>`;
          }
        },
        {
          title: "Status",
          minWidth: 120,
          field: "status",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="${styleHelper.getLoanStatusBadgeClass(
                  cell.getData().status
                )}">
                ${cell.getData().status_label}
                </div>
              </div>`;
          }
        },
        {
          title: "Payment Method",
          minWidth: 120,
          field: "payment_method",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="font-medium whitespace-nowrap"><a target="_blank" href="https://fundo.com.au/adminpanel/payments/schedules/${
                  cell.getData().id
                }"><u>Payments</u></a></div>
              </div>`;
          }
        },
        {
          title: "Created At",
          minWidth: 120,
          field: "created_at",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().created_at
                }</div>
              </div>`;
          }
        },
        {
          title: "Lender",
          minWidth: 100,
          field: "lender",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${cell.getData().lender ??
                  "N/A"}</div>
              </div>`;
          }
        },
        {
          title: "Assessment Status",
          minWidth: 155,
          field: "assessment_status",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${cell.getData().assessment
                  ?.status ?? "N/A"}</div>
              </div>`;
          }
        },
        {
          title: "Follow Up Status",
          minWidth: 120,
          field: "follow_up_status",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${cell.getData()
                  .follow_up_status ?? "N/A"}</div>
              </div>`;
          }
        },
        {
          title: "Follow Up Agent",
          minWidth: 100,
          field: "follow_up_agent",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${cell.getData()
                  .follow_up_agent ?? "N/A"}</div>
              </div>`;
          }
        }
      ]
    });
    tabulator.value.on("dataLoadError", function(errorResponse) {
      handleErrorResponse(errorResponse);
    });

    tabulator.value.on("renderComplete", function() {
      feather.replace({
        "stroke-width": 1.5
      });
    });
  };

  // Redraw table onresize
  const reInitOnResizeWindow = () => {
    window.addEventListener("resize", () => {
      tabulator.value.redraw();
      feather.replace({
        "stroke-width": 1.5
      });
    });
  };

  // Filter function

  const onSearch = () => {
    let url = `${baseUrl}?search=${encodeURIComponent(searchTerm.value)}`;
    //Only add filtered type when specific search page
    if (searchType.value && showSpecificSearch.value) {
      url += `&field=${encodeURIComponent(searchType.value)}`;
    }
    tabulator.value.setData(url);
  };

  const onResetSearch = () => {
    searchTerm.value = "";
    searchType.value = "fullname";
    onSearch();
  };

  const onFilter = status => {
    let filters = getInitialFilter();
    if (status !== "All") {
      filters.push({
        field: "status",
        type: "=",
        value: status
      });
    }
    tabulator.value.setFilter(filters);
  };

  return {
    adminUser,
    tableRef,
    statuses,
    searchType,
    searchTerm,
    onSearch,
    onFilter,
    onResetSearch,
    path,
    route,
    componentTitle,
    showSpecificSearch
  };
};
