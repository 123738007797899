<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Customers</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div class="dropdown ml-auto sm:ml-0">
          <div class="dropdown-menu w-40">
            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
              <a
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                href=""
              >
                <FilePlusIcon class="w-4 h-4 mr-2" />
                New Category
              </a>
              <a
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                href=""
              >
                <UserPlusIcon class="w-4 h-4 mr-2" />
                New Group
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-3"
              >Search</label
            >
            <template v-if="showSpecificSearch">
              <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                >Field:</label
              >
              <select
                id="tabulator-html-filter-field2"
                v-model="searchType"
                class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
              >
                <option selected value="fullname">Name</option>
                <option value="email">Email</option>
                <option value="mobile">Mobile</option>
              </select>

              <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2 ml-2"
                >Value:</label
              >
            </template>
            <input
              id="tabulator-html-filter-value"
              v-model="searchTerm"
              class="form-control sm:w-36 xxl:w-full mt-2 sm:mt-0"
              placeholder="Search Application id, customer name, email, mobile, status"
              type="text"
              @keyup.enter="onSearch"
            />
            <button
              id="tabulator-html-filter-go"
              class="btn w-full sm:w-16 sm:ml-1 bg-yellow-400"
              type="button"
              @click="onSearch"
            >
              Go
            </button>
            <button
              id="tabulator-html-filter-reset"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
              type="button"
              @click="onResetSearch"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator-customers"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { customers } from "../../composables/customers";

export default defineComponent({
  setup() {
    const {
      adminUser,
      tableRef,
      fromDate,
      toDate,
      onSearch,
      onFilter,
      onResetSearch,
      searchTerm,
      searchType,
      showSpecificSearch
    } = customers();

    return {
      adminUser,
      tableRef,
      fromDate,
      toDate,
      onSearch,
      onFilter,
      onResetSearch,
      searchTerm,
      searchType,
      showSpecificSearch
    };
  }
});
</script>
<style scoped>
.tabulator {
  font-size: 13px;
}
</style>
