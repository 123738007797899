<template>
  <!-- BEGIN: Modal Content -->
  <div
    id="new-card-payment-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base mr-auto">
            New Card Payment
          </h2>
        </div>
        <!-- END: Modal Header -->
        <!-- BEGIN: Modal Body -->
        <div
          v-if="errorMessage !== ''"
          class="alert alert-danger-soft show flex items-center mb-2"
          role="alert"
        >
          <AlertOctagonIcon class="w-6 h-6 mr-2" />
          {{ errorMessage }}
        </div>
        <div class="modal-body grid grid-cols-12">
          <div class="col-span-12 grid grid-cols-2">
            <div class="mt-2">
              <label class="form-label">Amount:</label>
              <div class="relative w-11/12">
                <input
                  v-model="form.amount"
                  type="number"
                  class="form-control"
                />
              </div>
              <div v-if="validationErrors.amount" class="p-1 text-red-600">
                {{ validationErrors.amount[0] }}
              </div>
            </div>
          </div>
          <div class="col-span-12 grid grid-cols-2 mt-2">
            <div class="mt-2">
              <label class="form-label">Cardholder Name:</label>
              <div class="relative w-full mx-auto">
                <input
                  v-model="form.card.card_holder"
                  type="text"
                  class="form-control w-11/12"
                />
              </div>
              <div
                v-if="validationErrors['card.card_holder']"
                class="p-1 text-red-600"
              >
                {{ validationErrors["card.card_holder"][0] }}
              </div>
            </div>
            <div class="mt-2">
              <label class="form-label">Card Number:</label>
              <div class="relative w-full mx-auto">
                <input
                  v-model="form.card.card_number"
                  type="number"
                  class="form-control w-11/12"
                />
              </div>
              <div
                v-if="validationErrors['card.card_number']"
                class="p-1 text-red-600"
              >
                {{ validationErrors["card.card_number"][0] }}
              </div>
            </div>
          </div>
          <div class="col-span-12 grid grid-cols-2 mt-2">
            <div class="col-span-24 grid grid-cols-2">
              <div class="mt-2 mr-2">
                <label class="form-label">Card Expiry Month:</label>
                <select
                  v-model="form.card.card_expiry_mm"
                  class="form-select w-11/12"
                >
                  <option value="" disabled hidden>MM</option>
                  <option v-for="month in 12" :key="month" :value="month">
                    {{ month }}
                  </option>
                </select>
                <div
                  v-if="validationErrors['card.card_expiry_mm']"
                  class="p-1 text-red-600"
                >
                  {{ validationErrors["card.card_expiry_mm"][0] }}
                </div>
              </div>
              <div class="mt-2 mr-2">
                <label class="form-label">Card Expiry Year:</label>
                <select
                  v-model="form.card.card_expiry_yy"
                  class="form-select w-11/12"
                >
                  <option value="" disabled hidden>MM</option>
                  <option v-for="year in cardYears" :key="year" :value="year">{{
                    year
                  }}</option>
                </select>
                <div
                  v-if="validationErrors['card.card_expiry_yy']"
                  class="p-1 text-red-600"
                >
                  {{ validationErrors["card.card_expiry_yy"][0] }}
                </div>
              </div>
            </div>
            <div class="mt-2">
              <label class="form-label">CVV:</label>
              <div class="relative w-full mx-auto">
                <input
                  v-model="form.card.card_cvv"
                  type="text"
                  class="form-control w-11/12"
                />
              </div>
              <div
                v-if="validationErrors['card.card_cvv']"
                class="p-1 text-red-600"
              >
                {{ validationErrors["card.card_cvv"][0] }}
              </div>
            </div>
          </div>
        </div>
        <!-- END: Modal Body -->
        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer text-right">
          <button
            type="button"
            data-dismiss="modal"
            class="btn btn-dark-soft w-20 mr-1"
          >
            Cancel
          </button>
          <button
            v-if="!isBusy"
            type="button"
            class="btn btn-success w-40"
            @click="submitForm"
          >
            Submit
          </button>
          <button v-else class="btn btn-success w-40">
            Processing
            <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  setup() {
    const store = useStore();

    const form = {
      amount: null,
      card: {
        card_number: null,
        card_holder: null,
        card_expiry_mm: null,
        card_expiry_yy: null,
        card_cvv: null
      }
    };

    const isBusy = computed(
      () => store.state.paymentScheduleLoanActions.isBusy
    );

    const validationErrors = computed(
      () => store.state.paymentScheduleLoanActions.validationErrors
    );
    const errorMessage = computed(
      () => store.state.paymentScheduleLoanActions.errorMessage
    );

    function submitForm() {
      store.dispatch("paymentScheduleLoanActions/newCardPayment", {
        form
      });
    }

    const cardYears = computed(() => {
      const thisYear = new Date().getFullYear();
      let cardYears = [];
      for (let i = 0; i <= 10; i++) {
        cardYears.push(thisYear + i);
      }
      return cardYears;
    });

    return {
      form,
      submitForm,
      validationErrors,
      errorMessage,
      cardYears,
      isBusy
    };
  }
});
</script>

<style scoped></style>
