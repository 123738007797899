<template>
  <div>
    <div
      id="approval-rejection-modal"
      aria-hidden="true"
      class="modal"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              header
            </h2>
            <div class="dropdown sm:hidden">
              <a
                aria-expanded="false"
                class="dropdown-toggle w-5 h-5 block"
                href="javascript:"
              >
                <MoreHorizontalIcon
                  class="w-5 h-5 text-gray-600 dark:text-gray-600"
                />
              </a>
              <div class="dropdown-menu w-40">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <a
                    class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    href="javascript:"
                  >
                    <FileIcon class="w-4 h-4 mr-2" />
                    Download Docs
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="modal-body grid grid-cols-6">
            <div class="col-span-12 sm:col-span-6"></div>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer text-right">
            dfdfg
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
    </div>

    <div v-if="customer" class="intro-y box px-5 pt-5 mt-5">
      <div
        class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5"
      >
        <div class="flex px-5 items-top justify-center lg:justify-start">
          <div>
            <div class="truncate sm:whitespace-normal font-medium text-lg">
              {{ customer.full_name
              }}<span class="status-active ml-2">{{
                customer.status_label
              }}</span>
            </div>
            <div class="text-gray-600 capitalize">
              {{ customer.employment_type }}
            </div>
          </div>
        </div>
        <div
          class="lg:mt-0 flex-1 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
        >
          <div class="flex flex-col justify-center items-top lg:items-start">
            <div class="truncate sm:whitespace-normal flex items-center">
              <PhoneIcon class="mr-2" height="24" width="16"></PhoneIcon>
              <a :href="'tel:' + customer.mobile">{{ customer.mobile }}</a
              ><CheckCircleIcon
                v-if="customer.mobile_verified_at"
                class="ml-2"
                height="24"
                width="16"
              ></CheckCircleIcon>
            </div>
            <div class="truncate sm:whitespace-normal flex items-center mt-3">
              <MailIcon class="mr-2" height="24" width="16"></MailIcon>
              <a href="mailto:john.barista@gmail.com" target="_blank"
                >{{ customer.email
                }}<CheckCircleIcon
                  v-if="customer.email_verified_at"
                  class="ml-2"
                  height="24"
                  width="16"
                ></CheckCircleIcon>
              </a>
            </div>
            <div class="truncate sm:whitespace-normal flex items-center mt-3">
              <CalendarIcon class="mr-2" height="24" width="16"></CalendarIcon>
              <a href="mailto:john.barista@gmail.com" target="_blank">{{
                customer.birthday
              }}</a>
            </div>
          </div>
        </div>
        <div
          v-if="customer.full_address"
          class="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
        >
          <div class="flex flex-col justify-center items-center lg:items-start">
            <div class="truncate sm:whitespace-normal flex items-center">
              <HomeIcon class="mr-2" height="24" width="16"></HomeIcon>
              {{ customer.full_address }}
            </div>
          </div>
        </div>
        <div
          class="mt-6 lg:mt-0 flex-1 flex items-center justify-end px-5 border-t lg:border-0 border-gray-200 dark:border-dark-5 pt-5 lg:pt-0"
        >
          <div
            v-if="customer.loan_applications_summary.active_account"
            class="text-center rounded-md w-20 py-3"
          >
            <div class="text-3xl font-bold leading-8">
              {{ customer.loan_applications_summary.active_account }}
            </div>
            <div class="text-gray-600">Active</div>
          </div>
          <div
            v-if="customer.loan_applications_summary.declined"
            class="text-center rounded-md w-20 py-3"
          >
            <div class="text-3xl font-bold leading-8">
              {{ customer.loan_applications_summary.declined }}
            </div>
            <div class="text-red-600">Declined</div>
          </div>
          <div
            v-if="customer.loan_applications_summary.cancelled"
            class="text-center rounded-md w-20 py-3"
          >
            <div class="text-3xl font-bold leading-8">
              {{ customer.loan_applications_summary.cancelled }}
            </div>
            <div class="text-gray-600">Cancelled</div>
          </div>
          <div
            v-if="customer.loan_applications_summary.total"
            class="text-center rounded-md w-20 py-3"
          >
            <div class="text-3xl font-bold leading-8">
              {{ customer.loan_applications_summary.total }}
            </div>
            <div class="text-gray-600 font-medium">Applications</div>
          </div>
        </div>
      </div>

      <div
        class="nav nav-tabs flex-col sm:flex-row justify-center lg:justify-start"
        role="tablist"
      >
        <a
          id="applications-tab"
          aria-controls="dashboard"
          aria-selected="true"
          class="pt-4 pb-2 sm:mr-8 active"
          data-target="#applications-tab-content"
          data-toggle="tab"
          role="tab"
          @click="currentTab = 'applications'"
          >Applications</a
        >
        <a
          id="notes-tab"
          aria-selected="false"
          class="pt-4 pb-2 sm:mr-8"
          data-target="#notes"
          data-toggle="tab"
          role="tab"
          @click="currentTab = 'notes'"
          >Notes</a
        >
        <a
          id="documents-tab"
          aria-selected="false"
          class="pt-4 pb-2 sm:mr-8"
          data-target="#documents"
          data-toggle="tab"
          role="tab"
          @click="currentTab = 'documents'"
          >Documents</a
        >
      </div>
    </div>

    <div class="tab tab-contents">
      <div
        v-if="currentTab === 'applications'"
        id="applications-tab-content"
        class="tab-content"
      >
        <div class="intro-y box px-5 pt-2 mt-5">
          <keep-alive>
            <LoanApplications
              :customer-id="customerId"
              title="Customer Applications"
              type="simple"
            ></LoanApplications>
          </keep-alive>
        </div>
      </div>

      <div
        v-if="currentTab === 'notes'"
        id="notes-tab-content"
        class="tab-content"
      >
        <div class="intro-y box p-5 my-5">
          <keep-alive>
            <LoanNotes :customer-id="customerId"></LoanNotes>
          </keep-alive>
        </div>
      </div>
      <div
        v-if="currentTab === 'documents'"
        id="documents-tab-content"
        class="tab-content"
      >
        <div class="intro-y box p-5 my-5">
          <keep-alive>
            <LnAppDocReq :customer-id="customerId" />
          </keep-alive>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, defineComponent, watchEffect } from "vue";
import { getCustomer } from "@/composables/customer";
import LoanApplications from "../loan-applications/Main";
import LoanNotes from "../customers/LoanNotes";
import LnAppDocReq from "@/components/ln-app-doc-req/DocRequestContent.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  components: {
    LoanApplications,
    LoanNotes,
    LnAppDocReq
  },
  setup() {
    let currentTab = ref("");
    const route = useRoute();

    const showDocs = route.query.showDocs ?? null;
    const initialTab = showDocs ? "documents" : "applications";

    watchEffect(() => (currentTab.value = initialTab));

    const customerId = parseInt(useRoute().params.customerId || "");

    const { adminUser, customer } = getCustomer(customerId);

    return {
      adminUser,
      customerId,
      customer,
      currentTab,
      LoanNotes
    };
  }
});
</script>
<style scoped>
.nav.nav-tabs a {
  cursor: pointer;
}
.box {
  width: 100%;
}
</style>
