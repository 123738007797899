<template>
  <div>
    <div
      :class="[
        { 'bg-theme-32': isActive },
        `loan-${getActiveApplicationQualityClass()}`
      ]"
      class="mt-1 p-4 box cursor-pointer shadow-lg section-header"
      @click="setNavActiveSection('lending-details-title')"
    >
      <div class="flex justify-between">
        <div class="flex flex-row">
          <div class="">
            <h2
              id="lending-details-title"
              class="font-medium text-theme-7 text-base mr-5"
            >
              LENDING DETAILS
            </h2>
          </div>
          <div v-if="!isActive" class="flex flex-row">
            <div
              v-for="(s, index) in summary"
              :key="index"
              class="mr-1 border-l pr-2 pl-2"
              style="font-size: smaller"
            >
              <span class="font-bold">{{ s.name }}:</span>
              <span class="ml-2">{{ s.value }}</span>
            </div>
          </div>
        </div>
        <div class="">
          <ChevronDownIcon :class="{ 'transform rotate-180': !isActive }" />
        </div>
      </div>
    </div>

    <div
      v-if="isActive"
      id="lending-dts-section"
      class="grid grid-cols-2 gap-6"
    >
      <template v-if="!fetchingLendingDetails">
        <div class="grid col-span-12 gap-6">
          <div
            :class="{
              'opacity-50 pointer-events-none': updatingLendingDetails
            }"
            class="col-span-12"
          >
            <UpdateLoader :show="updatingLendingDetails" />
            <!-- BEGIN: Personal Information -->
            <div class="box">
              <div class="p-5">
                <div
                  v-if="
                    Object.keys(aggregatorLoanDetailsTransitLobby).length > 0
                  "
                >
                  <div class="grid grid-cols-12 gap-x-3">
                    <div
                      v-for="(aggLnDt,
                      aggLnDtKey) in aggregatorLoanDetailsTransitLobby"
                      :key="aggLnDtKey"
                      class="col-span-6"
                    >
                      <h4 class="font-medium text-theme-3 text-base mb-2 mt-0">
                        {{
                          aggLnDtKey
                            .split("_")
                            .map(w => w.charAt(0).toUpperCase() + w.slice(1))
                            .join(" ")
                        }}
                      </h4>
                      <div class="p-1 w-full">
                        <div class="preview">
                          <div class="overflow-x-auto">
                            <table class="w-full">
                              <colgroup>
                                <col class="p-3" style="width: 60%" />
                                <col class="p-3" style="width: 40%" />
                              </colgroup>
                              <tbody :key="updateCounter">
                                <tr
                                  v-for="(aggLnDtDatum,
                                  aggLnDtDatumKey,
                                  aggLnDtDatumIndex) in aggLnDt.data"
                                  :key="aggLnDtDatumKey"
                                  :class="{
                                    'bg-gray-200 dark:bg-dark-1':
                                      aggLnDtDatumIndex % 2 === 0
                                  }"
                                  class="p-4"
                                >
                                  <th
                                    :class="{
                                      'flex items-center justify-between':
                                        aggLnDtDatum.verification
                                    }"
                                    class="border-b dark:border-dark-5 pl-2 h-10"
                                  >
                                    <span v-if="aggLnDtDatum.verification">
                                      {{ aggLnDtDatum["label"] }}
                                      <template
                                        v-if="
                                          aggLnDtDatum?.history &&
                                            Object.keys(aggLnDtDatum?.history)
                                              .length &&
                                            updateRecordsExists(
                                              aggLnDtDatum?.history
                                            )
                                        "
                                      >
                                        <AlertCircleIcon
                                          :name="`icon-${aggLnDtDatumKey}`"
                                          class="w-4 h-4 cursor-pointer"
                                        />
                                        <TippyContent
                                          :options="tippyOptions"
                                          :to="`icon-${aggLnDtDatumKey}`"
                                        >
                                          <ServiceabilityChangeTimeline
                                            :category="aggLnDtDatum['label']"
                                            :data="aggLnDtDatum?.history"
                                          />
                                        </TippyContent>
                                      </template>
                                    </span>
                                    <template v-else>
                                      {{ aggLnDtDatum["label"] }}
                                      <template
                                        v-if="
                                          aggLnDtDatum?.history &&
                                            Object.keys(aggLnDtDatum?.history)
                                              .length &&
                                            updateRecordsExists(
                                              aggLnDtDatum?.history
                                            )
                                        "
                                      >
                                        <AlertCircleIcon
                                          :name="`icon-${aggLnDtDatumKey}`"
                                          class="w-4 h-4 cursor-pointer"
                                        />
                                        <TippyContent
                                          :options="tippyOptions"
                                          :to="`icon-${aggLnDtDatumKey}`"
                                        >
                                          <ServiceabilityChangeTimeline
                                            :category="aggLnDtDatum['label']"
                                            :data="aggLnDtDatum?.history"
                                          />
                                        </TippyContent> </template
                                    ></template>
                                    <template v-if="aggLnDtDatum.verification">
                                      <LoanApplicationVerificationLink
                                        :details="aggLnDtDatum.verification"
                                        :operation="{
                                          handler: null,
                                          payload: {
                                            type: aggLnDtDatumKey.replace(
                                              /_/g,
                                              '-'
                                            ),
                                            value: aggLnDtDatum.value
                                          }
                                        }"
                                        :small="true"
                                      />
                                    </template>
                                  </th>
                                  <td
                                    class="
                                      border-b
                                      dark:border-dark-5
                                      text-center
                                    "
                                  >
                                    <LoanApplicationField
                                      :activeInputId="activeInputId"
                                      :aggregatorLoanDetailsTransitLobby="
                                        aggregatorLoanDetailsTransitLobby
                                      "
                                      :details="aggLnDtDatum"
                                      :enable="true"
                                      :index="aggLnDtDatumIndex"
                                      :loading="updatingLendingDetails"
                                      :name="aggLnDtDatumKey"
                                      :type="`lending`"
                                      v-on:update="
                                        aggLnDtDatum['value'] = $event.value;
                                        onCloseAndSubmitAgreggatorInput(
                                          'LendingDetails',
                                          aggLnDtDatumKey !== 'customer_name',
                                          $event.id
                                        );
                                        updateShouldChangeTab(false);
                                      "
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      flex flex-col
                      sm:flex-row
                      items-center
                      p-5
                      border-b border-gray-200
                    "
                  >
                    <div class="w-full sm:w-auto flex sm:ml-auto mt-1 sm:mt-0">
                      <!--                    <button-->
                      <!--                      v-if="enabledAgreggatorDataField === ''"-->
                      <!--                      type="button"-->
                      <!--                      class="btn btn-rounded bg-theme-3 w-1/2 text-white sm:w-auto mr-2"-->
                      <!--                      @click.prevent="onAggregatorLendingDetailsSubmit"-->
                      <!--                    >-->
                      <!--                      <SaveIcon class="w-6 h-6 mr-2" /> Save Changes-->
                      <!--                    </button>-->
                    </div>
                  </div>
                </div>
                <noRecordDivTemplate v-else />
                <!-- <hr class="bg-gray-400 h-1" /> -->
              </div>
            </div>
            <!-- END: Personal Information -->
          </div>
        </div>
      </template>
      <FetchingRecordDivTemplate v-else marginTop="0" />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref, watch } from "vue";
import noRecordDivTemplate from "@/components/no-record-div-template/Main.vue";
import FetchingRecordDivTemplate from "@/components/fetching-record-div-template/Main";
import LoanApplicationField from "@/components/loan-application-details/loan-application-input-field/Main";
import LoanApplicationVerificationLink from "@/components/loan-application-details/loan-application-verification-link/Main";
import ServiceabilityChangeTimeline from "../common/ServiceabilityChangeTimeline.vue";
import UpdateLoader from "../common/Loader.vue";
import { loanQuality } from "@/composables/loanQuality";

export default defineComponent({
  components: {
    noRecordDivTemplate,
    FetchingRecordDivTemplate,
    LoanApplicationField,
    LoanApplicationVerificationLink,
    ServiceabilityChangeTimeline,
    UpdateLoader
  },
  props: {
    aggregatorLoanDetailsTransitLobby: {
      type: Object,
      default: null
    },
    setNavActiveSection: {
      type: Function,
      require: true,
      default: null
    },
    isActive: {
      type: Boolean,
      default: false,
      required: true
    },
    getAggregatorLendingDetailsForLoanAssessment: {
      type: Function,
      require: true,
      default: null
    },
    adminUser: {
      type: Object,
      default: null
    },
    fetchingLendingDetails: {
      type: Boolean,
      default: false
    },
    updatingLendingDetails: {
      type: Boolean,
      default: false
    },
    onClickTransformToInput: {
      type: Function,
      require: true,
      default: null
    },
    enabledAgreggatorDataField: {
      type: String,
      default: ""
    },
    onAggregatorLendingDetailsSubmit: {
      type: Function,
      require: true,
      default: null
    },
    onCloseAgreggatorInputBox: {
      type: Function,
      require: true,
      default: null
    },
    onCloseAndSubmitAgreggatorInput: {
      type: Function,
      require: true,
      default: null
    },
    updateRecordsExists: {
      type: Function,
      required: true,
      default: null
    },
    updateShouldChangeTab: {
      type: Function,
      required: true,
      default: null
    },
    filterHistory: {
      type: Function,
      required: true
    },
    activeInputId: {
      type: String,
      required: true,
      default: ""
    }
  },
  setup(props) {
    const updateCounter = ref(0);
    const tippyOptions = {
      trigger: "mouseenter"
    };
    props.getAggregatorLendingDetailsForLoanAssessment(props.adminUser);

    //Summary items to show on banner
    const summary = computed(() => {
      if (Object.keys(props.aggregatorLoanDetailsTransitLobby).length < 1) {
        return [];
      }
      let data = props.aggregatorLoanDetailsTransitLobby?.affordability.data;
      return [
        {
          name: "Total Income",
          value: data.total_income?.value
        },
        {
          name: "Total Expenses",
          value: data.total_expenses?.value
        },
        {
          name: "Gross Surplus",
          value: data.gross_surplus?.value
        }
      ];
    });

    watch(
      () => props.aggregatorLoanDetailsTransitLobby,
      _ => updateCounter.value++,
      { deep: true }
    );

    const { getActiveApplicationQualityClass } = loanQuality();

    return {
      summary,
      updateCounter,
      tippyOptions,
      getActiveApplicationQualityClass
    };
  }
});
</script>
<style scoped>
td {
  padding: 5px;
}

th {
  text-align: left;
}
</style>
