<template>
  <div v-if="show" class="flex justify-center relative z-10 top-1/2 h-0">
    <LoadingIcon class="w-12 h-12 ml-2" color="green" icon="oval" />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: false,
      required: true
    }
  }
});
</script>
