<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Refund Requests</h2>
      <button
        id="tabulator-print"
        class="btn btn-outline-secondary bg-green-500 w-1/2 sm:w-auto mr-2"
        @click="onExport"
      >
        <FileTextIcon class="w-4 h-4 mr-2" /> Export
      </button>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <!--        <button class="btn btn-primary shadow-md mr-2">Add New Product</button>-->
        <div class="dropdown ml-auto sm:ml-0">
          <!--          <button-->
          <!--            class="dropdown-toggle btn px-2 box text-gray-700 dark:text-gray-300"-->
          <!--            aria-expanded="false"-->
          <!--          >-->
          <!--            <span class="w-5 h-5 flex items-center justify-center">-->
          <!--              <PlusIcon class="w-4 h-4" />-->
          <!--            </span>-->
          <!--          </button>-->
          <div class="dropdown-menu w-40">
            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
              <a
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              >
                <FilePlusIcon class="w-4 h-4 mr-2" /> New Category
              </a>
              <a
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              >
                <UserPlusIcon class="w-4 h-4 mr-2" /> New Group
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center sm:mr-4">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Field</label
            >
            <select
              id="tabulator-html-filter-field"
              v-model="filter.field"
              class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
            >
              <option value="fullname">Name</option>
              <option value="email">Email</option>
              <option value="mobile">Mobile</option>
              <option value="amount">Amount</option>
              <option value="transaction_id">Transac ID</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Value</label
            >
            <input
              id="tabulator-html-filter-value"
              v-model="filter.value"
              type="text"
              class="form-control sm:w-36 xxl:w-full mt-2 sm:mt-0"
              placeholder="Search..."
            />
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >From</label
            >
            <div class="relative sm:w-36 xxl:w-full mx-auto">
              <div
                class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
              >
                <CalendarIcon class="w-4 h-4" />
              </div>
              <Litepicker
                v-model="fromDate.value"
                :options="{
                  autoApply: true,
                  showWeekNumbers: true,
                  format: 'YYYY-MM-DD',
                  dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true
                  }
                }"
                class="form-control w-full pl-12"
              />
            </div>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >To</label
            >
            <div class="relative sm:w-36 xxl:w-full mx-auto">
              <div
                class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
              >
                <CalendarIcon class="w-4 h-4" />
              </div>
              <Litepicker
                v-model="toDate.value"
                :options="{
                  autoApply: true,
                  showWeekNumbers: true,
                  format: 'YYYY-MM-DD',
                  dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true
                  }
                }"
                class="form-control pl-12"
              />
            </div>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn w-full sm:w-16 bg-yellow-400"
              @click="onFilter"
            >
              Go
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </div>
        <div class="flex mt-5 sm:mt-0">
          <button
            type="button"
            class="btn btn-rounded btn-dark-soft w-1/2 sm:w-auto mr-2"
          >
            Total Amount : ${{ totalRefundedFund }}
          </button>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
        <button
          type="button"
          class="btn btn-outline-secondary w-1/2 sm:w-auto mt-4 "
        >
          Total Refund Requests :
          {{ totalRefunds }}
        </button>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Slide Over Content -->
    <!-- BEGIN: Super Large Slide Over Content -->
    <div
      id="requestor-note-slide-over-preview"
      class="modal modal-slide-over"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header p-5">
            <h2 class="font-medium text-base mr-auto">
              Refund Request Notes
            </h2>
          </div>
          <div class="modal-body">
            <table class="table table--sm">
              <thead>
                <tr>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Message
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border-b dark:border-dark-5">
                    <strong>{{ refundRequestRequestorNotes.name }} :</strong>
                    {{ refundRequestRequestorNotes.message }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div
      id="assessor-note-slide-over-preview"
      class="modal modal-slide-over"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header p-5">
            <h2 class="font-medium text-base mr-auto">
              Manager Notes (Last Approved/Rejected By:
              {{ refundRequestAssessorNotes.name }})
            </h2>
          </div>
          <div class="modal-body">
            <table class="table table--sm">
              <thead>
                <tr>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Message
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(assessorNote,
                  assessorNoteKey) in refundRequestAssessorNotes.messages"
                  :key="assessorNoteKey"
                >
                  <td class="border-b dark:border-dark-5">
                    {{ assessorNote }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div
      id="header-footer-slide-over-preview"
      class="modal modal-slide-over"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header p-5">
            <h2 class="font-medium text-base mr-auto">
              Pre Check Failure Logs
            </h2>
          </div>
          <div class="modal-body">
            <table class="table table--sm">
              <thead>
                <tr>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Message
                  </th>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Date Time
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(failureLog,
                  failureLogKey) in refundPreCheckFailureLogs"
                  :key="failureLogKey"
                >
                  <td class="border-b dark:border-dark-5">
                    {{ failureLog.message }}
                  </td>
                  <td class="border-b dark:border-dark-5">
                    {{ failureLog.created_at }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Super Large Slide Over Content -->
    <!-- END: Slide Over Content -->
    <!-- START: Approval/Rejection modal -->

    <!-- BEGIN: Modal Content -->
    <div
      id="approval-rejection-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              {{ approvalRejectionDataObject.title }}
            </h2>
            <div class="dropdown sm:hidden">
              <a
                class="dropdown-toggle w-5 h-5 block"
                href="javascript:;"
                aria-expanded="false"
              >
                <MoreHorizontalIcon
                  class="w-5 h-5 text-gray-600 dark:text-gray-600"
                />
              </a>
              <div class="dropdown-menu w-40">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <a
                    href="javascript:;"
                    class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  >
                    <FileIcon class="w-4 h-4 mr-2" />
                    Download Docs
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="modal-body grid grid-cols-6">
            <div class="col-span-12 sm:col-span-6">
              <label for="modal-form-1" class="form-label">
                {{ approvalRejectionDataObject.title }} Note:</label
              >
              <textarea
                id="modal-form-1"
                v-model="approvalRejectionDataObject.note"
                type="text"
                class="form-control"
              >
              </textarea>
            </div>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-dark-soft w-20 mr-1"
            >
              Cancel
            </button>
            <button
              v-if="approvalRejectionDataObject.type === 'approval'"
              type="button"
              class="btn btn-success w-40"
              @click="onApprovalSubmit"
            >
              Approve Refund
            </button>
            <button
              v-else
              type="button"
              class="btn btn-danger w-40"
              @click="onRejectionSubmit"
            >
              Reject Refund
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
    <!-- END: Modal Content -->
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { allRefundRequests } from "../../composables/allRefundRequests";

export default defineComponent({
  setup() {
    const {
      totalRefundedFund,
      totalRefunds,
      adminUser,
      refundPreCheckFailureLogs,
      refundRequestAssessorNotes,
      refundRequestRequestorNotes,
      showPreCheckLogs,
      tableRef,
      fromDate,
      toDate,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      onExport,
      breadcrumb,
      path,
      refundRequestList,
      getRefundRequestsAction,
      approvalRejectionDataObject,
      onApprovalSubmit,
      onRejectionSubmit
    } = allRefundRequests();

    return {
      totalRefundedFund,
      totalRefunds,
      adminUser,
      refundPreCheckFailureLogs,
      refundRequestAssessorNotes,
      refundRequestRequestorNotes,
      showPreCheckLogs,
      tableRef,
      fromDate,
      toDate,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      onExport,
      breadcrumb,
      path,
      refundRequestList,
      getRefundRequestsAction,
      approvalRejectionDataObject,
      onApprovalSubmit,
      onRejectionSubmit
    };
  }
});
</script>
<style scoped>
.tabulator {
  font-size: 11px;
}
</style>
