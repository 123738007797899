import { loanQualityHelper } from "../utils/loanQualityHelper";
// actions
const actions = {
  sortAndSetApplicationQualityByScore: async (
    { commit, dispatch },
    { score, section }
  ) => {
    const {
      quality,
      color,
      multiple
    } = loanQualityHelper.getLoanQualityClassByQuality(score, section);
    commit("setQuality", quality);
    commit("setScore", score);
    dispatch("updateSectionClassForQuality", { color, multiple });
  },
  updateSectionClassForQuality: async (
    { commit, dispatch, state },
    { color, multiple }
  ) => {
    const className = `loan-${state.quality.toLowerCase()}`;
    loanQualityHelper.col = color;
    loanQualityHelper.setQualityIndicatorForPage(className);
  },
  resetQualityAndScore: async ({ commit, dispatch, state }) => {
    loanQualityHelper.resetEverything(`loan-${state.quality?.toLowerCase()}`);
    commit("setQuality", null);
    commit("setScore", null);
  }
};

// mutations
const mutations = {
  //Customer
  setQuality(state, quality) {
    state.quality = quality;
  },
  setScore(state, score) {
    state.score = score;
  }
};

const state = () => {
  return {
    quality: null,
    score: null
  };
};

// getters
const getters = {
  quality: state => state.quality,
  score: state => state.score
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
