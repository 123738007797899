import { computed, onMounted, ref, watch } from "vue";
import feather from "feather-icons";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { useStore } from "@/store";
import { useRoute } from "vue-router";
import { authGetterService } from "../services/auth/auth-getter-service";
import fileDownload from "js-file-download";
import { styleHelper } from "@/utils/styleHelper";
import { handleErrorResponse } from "@/services/auth/responseHandler/handle-response-service";

export const customers = () => {
  const tableRef = ref();
  const tabulator = ref();
  const searchTerm = ref();
  const searchType = ref("fullname");
  const baseUrl = `${process.env.VUE_APP_BASE_URL}/getCustomers`;

  const route = useRoute();
  const path = computed(() => route.path);
  const store = useStore();
  const adminUser = computed(() => store.state.auth.loggedUser);

  const excelReportBlob = computed(
    () => store.state.disbursalPayout.excelReportBlob
  );

  const showSpecificSearch = computed(() =>
    ["customers", "search", "side-menu-search"].includes(route.name)
  );

  watch(
    () => excelReportBlob.value,
    excelData => {
      fileDownload(excelData, "payouts.xlsx");
    }
  );

  onMounted(() => {
    initTabulator();
    reInitOnResizeWindow();
  });

  const initTabulator = () => {
    tabulator.value = new Tabulator(tableRef.value, {
      ajaxURL: baseUrl,
      ajaxConfig: {
        method: "GET",
        headers: authGetterService.getAuthHeader()
      },
      ajaxResponse: function(url, params, response) {
        if (response.meta) {
          if (response.meta.last_page) {
            response["last_page"] = response.meta.last_page;
          }

          if (response.meta.current_page) {
            response["current_page"] = response.meta.current_page;
          }
        }

        return response; //return the response data to tabulator
      },
      filterMode: "remote",
      sortMode: "remote",
      printAsHtml: true,
      printStyled: true,
      reactiveData: true, //enable reactive data
      pagination: true,
      paginationMode: "remote",
      paginationSize: 10,
      paginationSizeSelector: [10, 20, 30, 40],
      layout: "fitColumns",
      responsiveLayout: "collapse",
      placeholder: "No matching customers found",
      columnDefaults: {
        headerSort: false
      },
      columns: [
        // For HTML table
        {
          title: "Customer ID",
          minWidth: 100,
          field: "id",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="font-medium whitespace-nowrap"><a target="_blank" href="/customers/customer/${
                  cell.getData().id
                }"><u>${cell.getData().id}</u></a></div>
              </div>`;
          }
        },
        {
          title: "Full Name",
          minWidth: 200,
          field: "full_name",
          hozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${cell.getData().full_name}</div>
              </div>`;
          }
        },
        {
          title: "Email",
          minWidth: 200,
          field: "email",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${cell.getData().email}</div>
              </div>`;
          }
        },
        {
          title: "Phone",
          minWidth: 200,
          field: "phone",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${cell.getData().mobile}</div>
              </div>`;
          }
        },
        {
          title: "Date of Birth",
          minWidth: 120,
          width: 120,
          field: "birthday",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${cell.getData().birthday}</div>
              </div>`;
          }
        },
        {
          title: "Status",
          minWidth: 120,
          width: 120,
          field: "status",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="${styleHelper.getCustomerStatusBadgeClass(
                  cell.getData().status
                )}">
                ${cell.getData().status_label}
                </div>
              </div>`;
          }
        },
        {
          title: "Email Verified At",
          minWidth: 150,
          width: 150,
          field: "email_verified_at",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().email_verified_at
                }</div>
              </div>`;
          }
        },
        {
          title: "Mobile Verified At",
          minWidth: 160,
          width: 160,
          field: "mobile_verified_at",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().mobile_verified_at
                }</div>
              </div>`;
          }
        },
        {
          title: "Created At",
          minWidth: 160,
          width: 160,
          field: "created_at",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().created_at
                }</div>
              </div>`;
          }
        },
        {
          title: "Last Updated",
          minWidth: 160,
          width: 160,
          field: "updated_at",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().updated_at
                }</div>
              </div>`;
          }
        }
      ]
    });

    tabulator.value.on("dataLoadError", function(errorResponse) {
      handleErrorResponse(errorResponse);
    });

    tabulator.value.on("renderComplete", function() {
      feather.replace({
        "stroke-width": 1.5
      });
    });
  };

  // Redraw table onresize
  const reInitOnResizeWindow = () => {
    window.addEventListener("resize", () => {
      tabulator.value.redraw();
      feather.replace({
        "stroke-width": 1.5
      });
    });
  };

  // Search function
  const onSearch = () => {
    let url = `${baseUrl}?search=${encodeURIComponent(searchTerm.value)}`;
    if (searchType.value && showSpecificSearch.value) {
      url += `&field=${encodeURIComponent(searchType.value)}`;
    }
    tabulator.value.setData(url);
  };

  const onResetSearch = () => {
    searchTerm.value = "";
    searchType.value = "fullname";
    onSearch();
  };

  return {
    adminUser,
    tableRef,
    onSearch,
    onResetSearch,
    path,
    searchTerm,
    searchType,
    showSpecificSearch
  };
};
