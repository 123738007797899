import { useRoute, useRouter } from "vue-router";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useStore } from "@/store";
import Swal from "sweetalert2";
import { customSwalMessageBuilder } from "@/composables/utils/customSwalMessageBuilder";
import Toastify from "toastify-js";

export const loanFollowUpDetail = () => {
  const { buildSwalSuccess, buildSwalHtmlError } = customSwalMessageBuilder();
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const showFinalizingButtons = ref(false);
  const applicationId = computed(
    () => store.state.loanFollowUpInfos.applicationId
  );
  const customerId = computed(() => store.state.loanFollowUpInfos.customerId);
  const loanFollowUpUuid = computed(
    () => store.state.loanFollowUpInfos.loanFollowUpUuid
  );
  const assessmentUuid = computed(
    () => store.state.loanFollowUpInfos.assessmentUuid
  );
  const adminUser = computed(() => store.state.auth.loggedUser);

  const busy = computed(() => store.state.loanFollowUpInfos.busy);

  const loanFollowUp = computed(
    () => store.state.loanFollowUpInfos.loanFollowUp
  );

  const fetchingKYC = computed(
    () => store.state.customerAndLoanDetails.fetchingKYC
  );

  const followUpBucketUpdateReqCounter = computed(
    () => store.state.loanFollowUpInfos.followUpBucketUpdateReqCounter
  );

  const isGlobalNotesDisplayed = computed(
    () => store.state.timelineNotesViewer.isGlobalNotesDisplayed
  );

  // listen for request for bucket update
  watch(
    () => followUpBucketUpdateReqCounter.value,
    () => {
      cash("#follow-up-finalization-confirmation-modal").modal("hide");
      store.commit("loanFollowUps/incrementUpdateCount");
      router.push("/loan-follow-up-dashboard");
    }
  );

  const followUpFinalizationFormValidationMessage = ref("");

  function validateFollowUpFinalizationForm() {
    if (followUpFinalizationConfirmationDataObject.note === "") {
      followUpFinalizationFormValidationMessage.value =
        "Note Field is required !";
      return false;
    }
    return true;
  }

  const followUpFinalizationConfirmationDataObject = reactive({
    uuid: null,
    note: "",
    title: "",
    type: ""
  });

  function clearFollowUpFinalizationFormValidationMessages() {
    followUpFinalizationFormValidationMessage.value = "";
  }

  function showContactFailedConfirmationModal() {
    clearFollowUpFinalizationFormValidationMessages();

    followUpFinalizationConfirmationDataObject.uuid = loanFollowUpUuid.value;
    followUpFinalizationConfirmationDataObject.note = "";
    followUpFinalizationConfirmationDataObject.title = "Contact Failed";
    followUpFinalizationConfirmationDataObject.type = "contact_failed";
    cash("#follow-up-finalization-confirmation-modal").modal("show");
  }

  function showFollowUpStatusCancelledConfirmationModal() {
    clearFollowUpFinalizationFormValidationMessages();

    followUpFinalizationConfirmationDataObject.uuid = loanFollowUpUuid.value;
    followUpFinalizationConfirmationDataObject.note = "";
    followUpFinalizationConfirmationDataObject.title = "Follow Up Cancel";
    followUpFinalizationConfirmationDataObject.type = "cancelled";
    cash("#follow-up-finalization-confirmation-modal").modal("show");
  }

  function showWithdrawalAssessmentModal() {
    clearFollowUpFinalizationFormValidationMessages();

    followUpFinalizationConfirmationDataObject.uuid = assessmentUuid.value;
    followUpFinalizationConfirmationDataObject.note = "";
    followUpFinalizationConfirmationDataObject.title = "Withdraw Application";
    followUpFinalizationConfirmationDataObject.type = "withdrawal";
    cash("#follow-up-finalization-confirmation-modal").modal("show");
  }

  function showFollowUpCompletedConfirmationModal() {
    clearFollowUpFinalizationFormValidationMessages();

    followUpFinalizationConfirmationDataObject.uuid = loanFollowUpUuid.value;
    followUpFinalizationConfirmationDataObject.note = "";
    followUpFinalizationConfirmationDataObject.title = "Follow Up Completed";
    followUpFinalizationConfirmationDataObject.type = "completed";
    cash("#follow-up-finalization-confirmation-modal").modal("show");
  }

  function onWithdrawAssessmentAction() {
    if (validateFollowUpFinalizationForm()) {
      store.commit("main/setFullPageLoading");
      cash("#follow-up-finalization-confirmation-modal").modal("hide");
      store.dispatch(
        "loanFollowUpInfos/withdrawLoanApplication",
        followUpFinalizationConfirmationDataObject
      );
    }
  }

  function onFollowUpCompletionSubmit() {
    if (validateFollowUpFinalizationForm()) {
      cash("#follow-up-finalization-confirmation-modal").modal("hide");
      store.commit("main/setFullPageLoading");
      store.dispatch(
        "loanFollowUpInfos/completeLoanFollowUp",
        followUpFinalizationConfirmationDataObject.note
      );
    }
  }

  function onFollowUpContactFailedSubmit() {
    if (validateFollowUpFinalizationForm()) {
      cash("#follow-up-finalization-confirmation-modal").modal("hide");
      store.commit("main/setFullPageLoading");
      store.dispatch(
        "loanFollowUpInfos/setLoanFollowUpAsContactFailed",
        followUpFinalizationConfirmationDataObject.note
      );
    }
  }

  function onFollowUpFollowUpStatusCancelSubmit() {
    if (validateFollowUpFinalizationForm()) {
      cash("#follow-up-finalization-confirmation-modal").modal("hide");
      store.commit("main/setFullPageLoading");
      store.dispatch(
        "loanFollowUpInfos/setLoanFollowUpStatusToFollowUpStatusCancelled",
        followUpFinalizationConfirmationDataObject.note
      );
    }
  }

  function clearFollowUpMessages() {
    store.commit("loanFollowUpInfos/clearLoanFollowUpDetailMessages");
  }

  watch(
    () => loanFollowUp.value,
    () => {
      store.dispatch("loanFollowUpInfos/getDocumentRequestsOfLoan");
    }
  );

  const kycWithAdditionalAttributes = computed(
    () => store.state.loanFollowUpInfos.kycWithAdditionalAttributes
  );

  function getKycAttributeValueByName(name) {
    let attrValue = "";
    if (
      kycWithAdditionalAttributes.value.basicAttributes
        .additional_attributes !== undefined
    ) {
      kycWithAdditionalAttributes.value.basicAttributes.additional_attributes.forEach(
        function(attrItem) {
          if (name === attrItem.attr) {
            attrValue = attrItem.value;
          }
        }
      );
    }
    return attrValue;
  }

  const loanFollowUpDetailSuccessMessage = computed(
    () => store.state.loanFollowUpInfos.loanFollowUpDetailSuccessMessage
  );

  watch(
    () => loanFollowUpDetailSuccessMessage.value,
    message => {
      if (message) {
        Swal.fire(buildSwalSuccess(message)).then(confirmed => {
          if (confirmed.isConfirmed) {
            store.commit("loanFollowUpInfos/clearLoanFollowUpDetailMessages");
          }
        });
      }
    }
  );

  const loanFollowUpDetailAlertMessage = computed(
    () => store.state.loanFollowUpInfos.loanFollowUpDetailAlertMessage
  );

  watch(
    () => loanFollowUpDetailAlertMessage.value,
    message => {
      if (message) {
        if (message !== "") {
          document.getElementById(
            "followup-dts-alert-note-message-body"
          ).innerHTML = message;
          Toastify({
            node: cash("#followup-dts-success-alert-toast-content")
              .clone()
              .removeClass("hidden")[0],
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: "top",
            position: "right",
            stopOnFocus: true
          }).showToast();
          store.commit("loanFollowUpInfos/clearLoanFollowUpDetailMessages");
          store.commit("main/resetFullPageLoading");
        }
      }
    }
  );

  const loanFollowUpDetailErrorMessage = computed(
    () => store.state.loanFollowUpInfos.loanFollowUpDetailErrorMessage
  );

  watch(
    () => loanFollowUpDetailErrorMessage.value,
    message => {
      if (message !== "") {
        Swal.fire(buildSwalHtmlError(message)).then(confirmed => {
          if (confirmed.isConfirmed) {
            store.commit("loanFollowUpInfos/clearLoanFollowUpDetailMessages");
          }
        });
      }
    }
  );

  store.commit("loanFollowUpInfos/setApplicationId", 0);
  store.commit("loanFollowUpInfos/setAssessmentUuid", "");
  onMounted(() => {
    store.commit(
      "loanFollowUpInfos/setLoanFollowUpUuid",
      route.params.ln_follow_up_uuid
    );
    store.dispatch("loanFollowUpInfos/getLoanFollowUp");
  });

  return {
    adminUser,
    busy,
    isGlobalNotesDisplayed,
    followUpFinalizationConfirmationDataObject,
    followUpFinalizationFormValidationMessage,
    clearFollowUpFinalizationFormValidationMessages,
    showFinalizingButtons,
    showContactFailedConfirmationModal,
    showWithdrawalAssessmentModal,
    showFollowUpCompletedConfirmationModal,
    showFollowUpStatusCancelledConfirmationModal,
    onFollowUpCompletionSubmit,
    onFollowUpContactFailedSubmit,
    onFollowUpFollowUpStatusCancelSubmit,
    onWithdrawAssessmentAction,
    applicationId,
    customerId,
    loanFollowUpUuid,
    assessmentUuid,
    loanFollowUp,
    kycWithAdditionalAttributes,
    getKycAttributeValueByName,
    clearFollowUpMessages,
    fetchingKYC
  };
};
