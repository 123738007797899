<template>
  <div
    v-if="applicationId < 1"
    class="bg-theme-16 cursor-pointer shadow-md bottom-0 left-80 box shadow-md border rounded-full w-40 h-12 flex items-center justify-center z-50 mr-56"
  >
    <LoadingIcon class="w-8 h-8 ml-2" color="green" icon="three-dots" />
  </div>
  <div>
    <div
      v-if="!isBusy"
      class="text-theme-16 cursor-pointer shadow-md box top-2 right-2 shadow-md border w-8 h-8 flex items-center justify-center z-50 "
      @click="showAddLoanNotificationModal"
    >
      <span> <BellIcon class="w-4 h-4"/></span>
    </div>
    <!-- BEGIN: Modal Content -->
    <div
      id="loan-notification-modal"
      aria-hidden="true"
      class="modal"
      tabindex="1"
    >
      <div v-if="!showExisting" class="modal-dialog">
        <div class="modal-content">
          <!-- BEGIN: Modal Body -->
          <div class="modal-body grid grid-cols-12">
            <div class="col-span-12 mt-2">
              <div
                class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
              >
                <div class="sm:flex items-center sm:mr-2">
                  <label
                    class="w-full flex-none xl:flex-initial mr-2"
                    v-text="
                      `${notificationExists ? 'Update' : 'Add'} Notification`
                    "
                  />
                </div>
              </div>
              <div
                v-if="loanNotificationValidationMessage.length"
                class="alert alert-danger-soft show flex items-center mb-2"
                role="alert"
              >
                <AlertOctagonIcon class="w-6 h-6 mr-2" />
                {{ loanNotificationValidationMessage }}
                <button
                  class="btn-close"
                  type="button"
                  @click="resetLoanNotificationValidationMessage"
                >
                  <XIcon class="w-4 h-4" />
                </button>
              </div>
              <!--                <label class="form-label mt-4 w-full"> Description:</label>-->
              <textarea
                id="description"
                v-model="form.description"
                class="form-control mt-4"
                cols="20"
                placeholder="Enter your description"
                rows="7"
                type="text"
              >
              </textarea>
            </div>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer text-right">
            <button
              v-if="!submitProcessing"
              class="btn btn-dark-soft w-20 mr-1"
              data-dismiss="modal"
              type="button"
              @click="closeAddLoanNotificationModal"
            >
              Cancel
            </button>
            <button
              v-if="submitProcessing"
              class="btn btn-success w-40"
              type="button"
            >
              <LoadingIcon class="w-4 h-4 ml-2" color="green" icon="oval" />
            </button>
            <button
              v-else
              class="btn btn-success w-40"
              type="button"
              @click="submitLoanNotificationForm"
            >
              Submit
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
      <div v-else class="modal-dialog">
        <div
          class="modal-content rounded-lg"
          style="background-color: rgb(236 252 203);"
        >
          <!-- BEGIN: Modal Body -->
          <div class="modal-body grid grid-cols-12">
            <div class="col-span-12 mt-2 font-medium font-sans text-lg py-5">
              <div
                class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
              >
                <div class="sm:flex items-center sm:mr-2">
                  <label class="w-full flex-none xl:flex-initial mr-2"
                    >Alert! (NEW / RETURNING)</label
                  >
                </div>
              </div>
              <p
                class="w-full flex items-center mt-4"
                v-html="form.description"
              ></p>
            </div>
          </div>
          <!-- END: Modal Body -->
        </div>
      </div>
    </div>
    <!-- END: Modal Content -->
  </div>
</template>
<script>
import { computed, defineComponent, ref, reactive, watch } from "vue";
import { useStore } from "@/store";
import cash from "cash-dom/dist/cash";
import Swal from "sweetalert2";

export default defineComponent({
  props: {
    applicationId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const store = useStore();

    const notification = computed(
      () => store.state.criticalAlert.notifications
    );

    const isBusy = computed(() => store.state.criticalAlert.isBusy);

    const submitProcessing = computed(
      () => store.state.criticalAlert.submitProcessing
    );

    const successMessage = computed(
      () => store.state.criticalAlert.successMessage
    );

    const loanNotificationValidationMessage = ref("");
    const notificationExists = ref(false);
    const showExisting = ref(false);

    const form = reactive({
      application_id: props.applicationId,
      description: ""
    });

    watch(
      () => notification.value,
      (val, prev) => {
        if (typeof val === "object" && Object.keys(val).length) {
          cash("#loan-notification-modal").modal("hide");
          notificationExists.value = true;
          showExisting.value = true;
          form.description = val.description;
          if (!Object.keys(prev).length) {
            cash("#loan-notification-modal").modal("show");
          }
        }
      }
    );

    watch(
      () => successMessage.value,
      message =>
        message.length &&
        Swal.fire({
          text: message,
          icon: "success",
          timer: 2000,
          showConfirmButton: false
        })
    );

    function resetFormFields() {
      form.description = notificationExists.value
        ? notification.value.description
        : "";
    }

    function resetLoanNotificationValidationMessage() {
      loanNotificationValidationMessage.value = "";
    }

    function showAddLoanNotificationModal() {
      resetLoanNotificationValidationMessage();
      resetFormFields();
      showExisting.value = false;
      cash("#loan-notification-modal").modal("show");
    }

    function closeAddLoanNotificationModal() {
      resetLoanNotificationValidationMessage();
      resetFormFields();
      cash("#loan-notification-modal").modal("hide");
    }

    function checkFormValidations() {
      if (form.description.length) {
        return true;
      }
      loanNotificationValidationMessage.value =
        "Please set notification content before submitting.";
      return false;
    }

    function submitLoanNotificationForm() {
      resetLoanNotificationValidationMessage();
      if (checkFormValidations()) {
        store.dispatch(
          "criticalAlert/updateLoanApplicationCriticalAlert",
          form
        );
      }
    }

    function initAndFetchNotifications() {
      store.dispatch(
        "criticalAlert/getApplicationCriticalAlert",
        props.applicationId
      );
    }

    initAndFetchNotifications();

    return {
      showAddLoanNotificationModal,
      submitLoanNotificationForm,
      closeAddLoanNotificationModal,
      submitProcessing,
      form,
      loanNotificationValidationMessage,
      isBusy,
      showExisting,
      notificationExists
    };
  }
});
</script>
