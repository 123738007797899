<template>
  <!-- BEGIN: External Details Links -->
  <div class="flex justify-center">
    <a
      class="btn bg-theme-3 w-56 text-white mr-6"
      target="_blank"
      @click="openZohoLink"
    >
      ZOHO CRM
    </a>
    <a
      class="btn bg-theme-3 w-56 text-white mr-6"
      target="_blank"
      @click="openLmsProfile"
    >
      LMS PROFILE
    </a>
    <a
      class="btn bg-theme-3 w-56 text-white mr-6"
      target="_blank"
      @click="openBankStatement"
    >
      ILLION STATEMENT
    </a>
    <a
      class="btn bg-theme-3 w-56 text-white mr-6"
      target="_blank"
      @click="openBankStatementForData"
    >
      FUNDO STATEMENT
    </a>
    <a
      class="btn bg-theme-3 w-56 text-white mr-6"
      target="_blank"
      @click="forceBankStatement"
    >
      FORCE STATEMENT
    </a>
    <a
      class="btn bg-theme-3 w-56 text-white mr-6"
      target="_blank"
      @click="exportSignedDocs"
    >
      EXPORT SIGNED DOCS
    </a>
    <a
      class="btn bg-theme-3 w-56 text-white mr-6"
      target="_blank"
      @click="openFrankieLink"
    >
      FRANKIE ONE
    </a>
  </div>
  <!-- END: External Details Links -->
</template>

<script>
import { computed, defineComponent } from "vue";
import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { useStore } from "vuex";
import { formatErrorMessage } from "@/utils/messageFormatter";

export default defineComponent({
  props: {
    applicationId: {
      type: Number,
      require: true
    }
  },
  setup(props) {
    const store = useStore();

    function openZohoLink() {
      const url =
        "https://crm.zoho.com/crm/org678972816/tab/Leads/" +
        kycWithAdditionalAttributes.value.basicAttributes.lead_id;
      window.open(url, "_blank").focus();
    }

    function openLmsProfile() {
      const url = `${adminPanelBaseUrl}/detail/${kycWithAdditionalAttributes.value.basicAttributes.user_id}`;
      window.open(url, "_blank").focus();
    }

    function openBankStatement() {
      const url = `${adminPanelBaseUrl}/bankstatement?email=${kycWithAdditionalAttributes.value.basicAttributes.email}`;
      window.open(url, "_blank").focus();
    }

    function openBankStatementForData() {
      const url = `${process.env.VUE_APP_BANK_STATEMENT_URL}/?application_id=${props.applicationId}`;
      window.open(url, "_blank").focus();
    }

    function forceBankStatement() {
      const {
        email,
        user_id
      } = kycWithAdditionalAttributes.value.basicAttributes;
      store.commit("main/setFullPageLoading");
      axiosWrapperService
        .axiosPost(`/applications/${props.applicationId}/forceBankStatement`, {
          email
        })
        .then(response => {
          const token = response.data.tracking_token;
          window
            .open(
              `${adminPanelBaseUrl}/loading/bankstatement?userId=${user_id}&token=${token}`,
              "_blank"
            )
            .focus();
        })
        .catch(error => {
          store.dispatch(
            "globalToastMessage/showSimpleToast",
            {
              message: formatErrorMessage(error),
              type: "error"
            },
            { root: true }
          );
        })
        .finally(_ => {
          store.commit("main/resetFullPageLoading", null, { root: true });
        });
    }

    function exportSignedDocs() {
      store.commit("main/setFullPageLoading");
      axiosWrapperService
        .axiosGet(
          `/applications/${props.applicationId}/exportCustomerSignedDocs`,
          {
            responseType: "blob"
          }
        )
        .then(response => {
          const pdfBlob = new Blob([response.data]);

          const blobUrl = window.URL.createObjectURL(pdfBlob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.setAttribute(
            "download",
            `signed_docs_${props.applicationId}.zip`
          );
          link.click();
          link.remove();
          URL.revokeObjectURL(blobUrl);
        })
        .catch(() => {
          store.dispatch(
            "globalToastMessage/showSimpleToast",
            {
              message: "Something went wrong while exporting signed doc.",
              type: "error"
            },
            { root: true }
          );
        })
        .finally(_ => {
          store.commit("main/resetFullPageLoading");
        });
    }

    const kycWithAdditionalAttributes = computed(
      () => store.state.customerAndLoanDetails.kycWithAdditionalAttributes
    );

    const adminPanelBaseUrl = process.env.VUE_APP_LEGACY_ADMINPANEL_URL;

    function openFrankieLink() {
      let strippedName =
        kycWithAdditionalAttributes.value.basicAttributes?.fullname ?? "";

      if (strippedName.length) {
        if (strippedName.includes(".")) {
          strippedName = strippedName.split(".")[1];
        }
      }
      const url = `https://portal.kycaml.frankiefinancial.io/onboarding?nameFilter=${strippedName}`;
      window.open(url, "_blank").focus();
    }

    return {
      openZohoLink,
      openLmsProfile,
      forceBankStatement,
      exportSignedDocs,
      openFrankieLink,
      openBankStatement,
      openBankStatementForData
    };
  }
});
</script>
