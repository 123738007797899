import { computed, ref, watch } from "vue";
import { useStore } from "@/store";
import Swal from "sweetalert2";
import { customSwalMessageBuilder } from "@/composables/utils/customSwalMessageBuilder";

export const loanTags = props => {
  const { buildSwalWarning } = customSwalMessageBuilder();

  const blacklisted_slug = "black_listed";

  const store = useStore();

  const busy = computed(() => store.state.loanTags.busy);

  const applicationId = props.applicationId;

  const adminUser = computed(() => store.state.auth.loggedUser);

  const isGlobalNotesDisplayed = computed(
    () => store.state.timelineNotesViewer.isGlobalNotesDisplayed
  );

  const lendingErrorTags = computed(
    () => store.state.loanTags.lendingErrorTags
  );
  const fetchingLnErrorTags = computed(
    () => store.state.loanTags.fetchingLnErrorTags
  );
  const lendingErrorTagsAttachedToLoan = computed(
    () => store.state.loanTags.lendingErrorTagsAttachedToLoan
  );
  const lendingNonErrorTags = computed(
    () => store.state.loanTags.lendingNonErrorTags
  );
  const fetchingLnNonErrorTags = computed(
    () => store.state.loanTags.fetchingLnNonErrorTags
  );
  const lendingErrorNonTagsAttachedToLoan = computed(
    () => store.state.loanTags.lendingErrorNonTagsAttachedToLoan
  );
  const lendingTagsAttachedToLoan = computed(
    () => store.state.loanTags.lendingTagsAttachedToLoan
  );

  const selectedErrorTags = ref([]);
  const selectedNonErrorTags = ref([]);
  const reasonField = ref({
    enable: false,
    readOnly: false,
    reason: ""
  });
  const blacklist_reasons = [
    "Outsourced",
    "Chargeback",
    "Fraud Check",
    "Disrespect/Abusive Behaviour",
    "Hardship",
    "Part IX",
    "Bankruptcy",
    "AFCA Complaint",
    "Deceased",
    "Other"
  ];
  function resetSelectedErrorTags() {
    selectedErrorTags.value = lendingErrorTagsAttachedToLoan.value.map(
      tag => tag.slug
    );
  }
  function resetSelectedNonErrorTags() {
    selectedNonErrorTags.value = lendingErrorNonTagsAttachedToLoan.value.map(
      tag => tag.slug
    );
    resetBlackListedReason();
    store.commit("loanTags/setInitialSystemTags", selectedNonErrorTags.value);
  }

  function resetBlackListedReason() {
    reasonField.value = {
      enable: false,
      readOnly: false,
      reason: ""
    };
  }

  watch(
    () => lendingErrorTagsAttachedToLoan.value,
    tags => {
      resetSelectedErrorTags();
    },
    { deep: true }
  );
  watch(
    () => lendingErrorNonTagsAttachedToLoan.value,
    tags => {
      resetSelectedNonErrorTags();
    },
    { deep: true }
  );

  watch(
    [
      () =>
        store.state.customerAndLoanDetails.kycWithAdditionalAttributes
          .basicAttributes.blacklisting_reason,
      () => selectedNonErrorTags.value
    ],
    ([reason, tags]) => {
      if (
        tags.includes(blacklisted_slug) &&
        blacklist_reasons.includes(reason)
      ) {
        reasonField.value = {
          enable: true,
          readOnly: true,
          reason
        };
      }
    }
  );

  watch(
    () => selectedNonErrorTags.value,
    tags => {
      if (tags.includes(blacklisted_slug)) {
        if (!reasonField.value.reason.length) {
          //enable reason field to get blacklisted reason
          reasonField.value.enable = true;
        }
      } else {
        //disable reason field
        reasonField.value.reason = "";
        reasonField.value.enable = false;
      }
    },
    { deep: true }
  );
  function areGivenArraysNonEmptyAndEquivalents(arr1, arr2) {
    return (
      // both are non empty
      !(arr1.length < 1 && arr2.length < 1) &&
      // both non-nested arrays are different in terms of values
      (arr1.length !== arr2.length ||
        !arr1.every(element => arr2.includes(element)))
    );
  }
  const changeInSelectedErrorTags = computed(() =>
    areGivenArraysNonEmptyAndEquivalents(
      selectedErrorTags.value,
      lendingErrorTagsAttachedToLoan.value.map(tagName => tagName.slug)
    )
  );

  const changeInSelectedErrorNonTags = computed(() =>
    areGivenArraysNonEmptyAndEquivalents(
      selectedNonErrorTags.value,
      lendingErrorNonTagsAttachedToLoan.value.map(tagName => tagName.slug)
    )
  );

  const canManuallyChangeSystemTags = computed(() => {
    //return true if the dropdown and the initial are different but that a tag has been added
    let initial = store.state.loanTags.initialSystemTags;
    let current = selectedNonErrorTags.value;
    let canEdit = false;
    //if the current has tags that are not in the initial - can edit
    current.forEach(tag => {
      if (!initial.includes(tag)) {
        canEdit = true;
      }
    });
    //Let's ensure all the initial tags are preset in case the user deletes one
    let tags = [];
    initial.forEach(tag => {
      tags.push(tag);
    });
    current.forEach(tag => {
      if (!tags.includes(tag)) {
        tags.push(tag);
      }
    });
    selectedNonErrorTags.value = tags;
    return canEdit;
  });

  function onCancelSelectedErrorTagsEditMode() {
    resetSelectedErrorTags();
  }
  function onSubmitSelectedErrorTags() {
    if (selectedErrorTags.value.length < 1) {
      Swal.fire(
        buildSwalWarning(
          "You have removed all tags ! Are sure to commit the changes?",
          true
        )
      ).then(confirmed => {
        if (confirmed.isConfirmed) {
          store.commit("main/setFullPageLoading");
          store.dispatch("loanTags/submitSelectedLendingErrorTags", {
            applicationId: applicationId,
            postData: {
              truncate_tags: 1,
              tags: selectedErrorTags.value
            },
            resetTags: _ => resetSelectedErrorTags()
          });
        }
      });
    } else {
      store.commit("main/setFullPageLoading");
      store.dispatch("loanTags/submitSelectedLendingErrorTags", {
        applicationId: applicationId,
        postData: {
          truncate_tags: 1,
          tags: selectedErrorTags.value
        },
        resetTags: _ => resetSelectedErrorTags()
      });
    }
  }
  function onCancelSelectedNonErrorTagsEditMode() {
    resetSelectedNonErrorTags();
  }
  function onSubmitSelectedNonErrorTags() {
    if (selectedNonErrorTags.value.length < 1) {
      Swal.fire(
        buildSwalWarning(
          "You have removed all tags ! Are sure to commit the changes?",
          true
        )
      ).then(confirmed => {
        if (confirmed.isConfirmed) {
          store.commit("main/setFullPageLoading");
          store.dispatch("loanTags/submitSelectedLendingNonErrorTags", {
            applicationId: applicationId,
            postData: {
              truncate_tags: 1,
              tags: selectedNonErrorTags.value
            },
            resetTags: _ => resetSelectedNonErrorTags()
          });
        }
      });
    } else {
      let postData = {
        truncate_tags: 1,
        add_reason: 0,
        tags: selectedNonErrorTags.value
      };
      //Check if need to add blacklisting reason
      if (reasonField.value.enable) {
        postData = {
          ...postData,
          add_reason: 1,
          reason: reasonField.value.reason
        };
      }
      store.commit("main/setFullPageLoading");
      store.dispatch("loanTags/submitSelectedLendingNonErrorTags", {
        applicationId: applicationId,
        postData,
        resetTags: _ => resetSelectedNonErrorTags()
      });
    }
  }

  function initialiseLoanTags() {
    store.dispatch("loanTags/getLendingErrorTagsAttachedToLoan", {
      applicationId: applicationId,
      messageOnSuccess: ""
    });
    store.dispatch("loanTags/getLendingNonErrorTagsAttachedToLoan", {
      applicationId: applicationId,
      messageOnSuccess: ""
    });
  }

  return {
    adminUser,
    fetchingLnNonErrorTags,
    fetchingLnErrorTags,
    selectedErrorTags,
    selectedNonErrorTags,
    changeInSelectedErrorTags,
    changeInSelectedErrorNonTags,
    onCancelSelectedErrorTagsEditMode,
    onSubmitSelectedErrorTags,
    onSubmitSelectedNonErrorTags,
    onCancelSelectedNonErrorTagsEditMode,
    isGlobalNotesDisplayed,
    lendingErrorTags,
    lendingErrorTagsAttachedToLoan,
    lendingNonErrorTags,
    lendingTagsAttachedToLoan,
    lendingErrorNonTagsAttachedToLoan,
    initialiseLoanTags,
    canManuallyChangeSystemTags,
    busy,
    reasonField,
    blacklist_reasons
  };
};
