const sections = [
  "kyc-title",
  "application-detail-title",
  "loan-history-with-fundo-title",
  "ln-approval-pre-check-title",
  "bank-details-title",
  "payment-detail-title",
  "employment-detail-title",
  "personal-loans-title",
  "income-and-expense-details-title",
  "lending-details-title",
  "loan-tags-title",
  "doc-req-title",
  "loan-rebuttal-title",
  "verification-detail-title",
  "ln-app-general-follow-up"
];

const state = () => {
  return {
    isQuickNavExpanded: true,
    activeSections: sections,
    showMenu: true
  };
};

// getters
const getters = {
  isQuickNavExpanded: state => state.isQuickNavExpanded,
  getActiveSection: state => state.activeSections,
  getShowMenu: state => state.showMenu
};

// actions
const actions = {
  addSectionToActiveSection({ commit }, section) {
    try {
      commit("setActiveSection", section);
    } catch (e) {
      commit("resetActiveSection");
    }
  },
  removeSectionFromActiveSection({ commit }, section) {
    try {
      commit("removeActiveSection", section);
    } catch (e) {
      commit("resetActiveSection");
    }
  },
  resetActiveSection({ commit }) {
    commit("resetActiveSection");
  },
  setShowMenu({ commit }, showMenu) {
    commit("setShowMenu", showMenu);
  }
};

// mutations
const mutations = {
  toggleIsQuickNavExpanded(state) {
    state.isQuickNavExpanded = !state.isQuickNavExpanded;
  },
  setActiveSection(state, section) {
    state.activeSections = [...state.activeSections, section];
  },
  removeActiveSection(state, section) {
    state.activeSections = state.activeSections.filter(
      activeSection => activeSection !== section
    );
  },
  resetActiveSection(state) {
    state.activeSection = [];
  },
  setShowMenu(state, showMenu) {
    state.showMenu = showMenu;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
