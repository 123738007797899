class AuthGetterService {
  getAuthHeader() {
    return {
      "Content-type": "application/json; charset=utf-8", //set specific content type
      Authorization: `Bearer ${localStorage.getItem("access_token")}`
    };
  }
}

export let authGetterService = new AuthGetterService();
