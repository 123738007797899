<template>
  <!-- BEGIN: Modal Content -->
  <div id="add-fees-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base mr-auto">
            Add Fees
          </h2>
        </div>
        <!-- END: Modal Header -->
        <!-- BEGIN: Modal Body -->
        <div
          v-if="errorMessage !== ''"
          class="alert alert-danger-soft show flex items-center mb-2"
          role="alert"
        >
          <AlertOctagonIcon class="w-6 h-6 mr-2" />
          {{ errorMessage }}
        </div>
        <div class="modal-body grid grid-cols-12">
          <div class="col-span-12 grid grid-cols-2">
            <div class="mt-2">
              <label class="form-label">Amount:</label>
              <div class="relative w-full mx-auto">
                <input
                  v-model="form.amount"
                  type="number"
                  class="form-control w-11/12"
                />
              </div>
              <div v-if="validationErrors.amount" class="p-1 text-red-600">
                {{ validationErrors.amount[0] }}
              </div>
            </div>
          </div>
          <div class="col-span-12 grid grid-cols-2">
            <div class="mt-2">
              <label class="form-label">Fee Type:</label>
              <select v-model="form.feetype" class="form-select w-11/12 pt-2">
                <option value="" selected> -- Select -- </option>
                <option
                  v-for="feeType in feeTypes"
                  :key="feeType.value"
                  :value="feeType.value"
                  >{{ feeType.name }}</option
                >
              </select>
              <div v-if="validationErrors.feetype" class="p-1 text-red-600">
                {{ validationErrors.feetype[0] }}
              </div>
            </div>
            <div class="mt-2">
              <label class="form-label"> Date:</label>
              <div class="relative w-full mx-auto">
                <input
                  type="date"
                  v-model="form.date"
                  class="form-control w-11/12"
                />
              </div>
              <div v-if="validationErrors.date" class="p-1 text-red-600">
                {{ validationErrors.date[0] }}
              </div>
            </div>
          </div>

          <div class="col-span-12 mt-2">
            <label class="form-label"> Notes:</label>
            <textarea
              v-model="form.notes"
              type="text"
              class="form-control"
              rows="5"
            >
            </textarea>
            <div v-if="validationErrors.notes" class="p-1 text-red-600">
              {{ validationErrors.notes[0] }}
            </div>
          </div>
        </div>
        <!-- END: Modal Body -->
        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer text-right">
          <button
            type="button"
            data-dismiss="modal"
            class="btn btn-dark-soft w-20 mr-1"
          >
            Cancel
          </button>
          <button
            v-if="!isBusy"
            type="button"
            class="btn btn-success w-40"
            @click="submitForm"
          >
            Submit
          </button>
          <button v-else class="btn btn-success w-40">
            Processing
            <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  setup() {
    const store = useStore();

    const form = {
      amount: null,
      date: null,
      notes: null,
      feetype: ""
    };

    const isBusy = computed(
      () => store.state.paymentScheduleLoanActions.isBusy
    );

    const validationErrors = computed(
      () => store.state.paymentScheduleLoanActions.validationErrors
    );
    const errorMessage = computed(
      () => store.state.paymentScheduleLoanActions.errorMessage
    );

    function submitForm() {
      store.dispatch("paymentScheduleLoanActions/addFees", {
        form
      });
    }

    const feeTypes = [
      {
        value: "Monthly Fee",
        name: "Monthly Fee"
      },
      {
        value: "RESCHED FEE",
        name: "Reschedule Fee"
      },
      {
        value: "DISHNR",
        name: "Dishonour Fee"
      },
      {
        value: "LPF",
        name: "Late Payment Fee"
      },
      {
        value: "Add Fees",
        name: "Others"
      }
    ];

    return {
      form,
      submitForm,
      validationErrors,
      errorMessage,
      isBusy,
      feeTypes
    };
  }
});
</script>

<style scoped></style>
