<template>
  <div>
    <div
      v-if="showEmployeeInfo"
      :class="[
        { 'bg-theme-32': isActive },
        `loan-${getActiveApplicationQualityClass()}`
      ]"
      class="flex mt-1 p-4 box cursor-pointer section-header"
      @click="setNavActiveSection('employment-detail-title')"
    >
      <h2
        id="employment-detail-title"
        class="font-medium text-theme-7 text-base w-full"
      >
        EMPLOYMENT AND ACCOMMODATION DETAIL
      </h2>
      <ChevronDownIcon :class="{ 'transform rotate-180': !isActive }" />
    </div>
    <div
      v-if="isActive"
      id="emp-and-accom-dts-section"
      class="grid grid-cols-12 gap-6"
    >
      <div v-if="!fetchingKYC" class="col-span-12">
        <div class="box">
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div
                v-if="showEmployeeInfo"
                class="col-span-12 xl:col-span-6 p-5"
              >
                <h4 class="font-medium text-theme-3 text-base mb-5">
                  Employee Information
                </h4>
                <div class="grid grid-cols-12">
                  <label
                    class="form-label col-span-3 mt-1.5"
                    for="ln-asses-company-name"
                    >Company Name</label
                  >
                  <input
                    id="ln-asses-company-name"
                    :value="getKycAttributeValueByName('Company_Name')"
                    class="form-control col-span-6 gap-3"
                    disabled
                    placeholder="Input text"
                    type="text"
                  />
                </div>
                <div class="mt-3 grid grid-cols-12">
                  <label
                    class="form-label col-span-3 mt-1.5"
                    for="ln-assess-company-email"
                    >Company Email</label
                  >
                  <input
                    id="ln-assess-company-email"
                    :value="getKycAttributeValueByName('Employer_Email')"
                    class="form-control col-span-6 gap-3"
                    disabled
                    placeholder="Input text"
                    type="text"
                  />
                </div>
                <div class="mt-3 grid grid-cols-12">
                  <label
                    class="form-label col-span-3 mt-1.5"
                    for="ln-asses-employment"
                    >Employment</label
                  >
                  <input
                    id="ln-asses-employment"
                    :value="getKycAttributeValueByName('Employment')"
                    class="form-control col-span-6 gap-3"
                    disabled
                    placeholder="Input text"
                    type="text"
                  />
                </div>
                <div class="mt-3 grid grid-cols-12">
                  <label
                    class="form-label col-span-3 mt-1.5"
                    for="ln-assess-emp-type"
                    >Employment Type</label
                  >
                  <input
                    id="ln-assess-emp-type"
                    :value="getKycAttributeValueByName('Employment_Type')"
                    class="form-control col-span-6 gap-3"
                    disabled
                    placeholder="Input text"
                    type="text"
                  />
                </div>
                <div class="mt-3 grid grid-cols-12">
                  <label
                    class="form-label col-span-3 mt-1.5"
                    for="ln-assess-inc-type"
                    >Income Type</label
                  >
                  <input
                    id="ln-assess-inc-type"
                    :value="getKycAttributeValueByName('Income_Type')"
                    class="form-control col-span-6 gap-3"
                    disabled
                    placeholder="Input text"
                    type="text"
                  />
                </div>
              </div>
              <div class="col-span-12 xl:col-span-6 p-5">
                <h4 class="font-medium text-theme-3 text-base mb-5">
                  Accommodation Information
                </h4>
                <div class="grid grid-cols-12">
                  <label
                    class="form-label col-span-5 mt-1.5"
                    for="ln-assess-pay-accommodation"
                    >Paying For Accommodation</label
                  >
                  <input
                    id="ln-assess-pay-accommodation"
                    :value="
                      getKycAttributeValueByName(
                        'do_you_pay_for_any_accomodation'
                      )
                    "
                    class="form-control col-span-5 gap-2"
                    disabled
                    placeholder="Input text"
                    type="text"
                  />
                </div>
                <div class="mt-3 grid grid-cols-12">
                  <label
                    class="form-label col-span-5 mt-1.5"
                    for="ln-assess-accommodation-amt"
                    >Amount</label
                  >
                  <input
                    id="ln-assess-accommodation-amt"
                    :value="getKycAttributeValueByName('accomodation_amount')"
                    class="form-control col-span-5 gap-2"
                    disabled
                    placeholder="Input text"
                    type="text"
                  />
                </div>
                <div class="mt-3 grid grid-cols-12">
                  <label
                    class="form-label col-span-5 mt-1.5"
                    for="ln-assess-accomm-amt-diff"
                    >Amount different from Bank Statement?</label
                  >
                  <input
                    id="ln-assess-accomm-amt-diff"
                    :value="
                      getKycAttributeValueByName('accomodation_diff_from_bank')
                    "
                    class="form-control col-span-5 gap-2"
                    disabled
                    placeholder="Input text"
                    type="text"
                  />
                </div>
                <div class="mt-3 grid grid-cols-12">
                  <label
                    class="form-label col-span-5 mt-1.5"
                    for="ln-assess-diff-reason"
                    >Difference Reason</label
                  >
                  <input
                    id="ln-assess-diff-reason"
                    :value="getKycAttributeValueByName('accomodation_diff_why')"
                    class="form-control col-span-5 gap-2"
                    disabled
                    placeholder="Input text"
                    type="text"
                  />
                </div>
                <div class="mt-3 grid grid-cols-12">
                  <label
                    class="form-label col-span-5 mt-1.5"
                    for="ln-assess-accomm-pay-cycle"
                    >Accommodation pay cycle</label
                  >
                  <input
                    id="ln-assess-accomm-pay-cycle"
                    :value="
                      getKycAttributeValueByName('accomodation_pay_cycle')
                    "
                    class="form-control col-span-5 gap-2"
                    disabled
                    placeholder="Input text"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FetchingRecordDivTemplate v-else marginTop="0" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { customerAndLoanDetails } from "@/composables/components/customerAndLoanDetails";
import FetchingRecordDivTemplate from "@/components/fetching-record-div-template/Main";
import { loanQuality } from "@/composables/loanQuality";

export default defineComponent({
  components: {
    FetchingRecordDivTemplate
  },
  props: {
    applicationId: {
      type: Number,
      require: true,
      default: null
    },
    showEmployeeInfo: {
      type: Boolean,
      require: false,
      default: true
    },
    isActive: {
      type: Boolean,
      require: false,
      default: false
    },
    setNavActiveSection: {
      type: Function,
      require: true,
      default: null
    }
  },
  setup(props) {
    const { getKycAttributeValueByName, fetchingKYC } = customerAndLoanDetails(
      props,
      false
    );
    const { getActiveApplicationQualityClass } = loanQuality();

    return {
      getKycAttributeValueByName,
      fetchingKYC,
      getActiveApplicationQualityClass
    };
  }
});
</script>
