import { formatErrorMessage } from "../utils/messageFormatter";
import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";

const state = () => {
  return {
    documentTypeList: [],
    busy: false,
    uploadSuccessMessage: "",
    uploadErrorMessage: ""
  };
};

// getters
const getters = {
  documentTypeList: state => state.documentTypeList,
  busy: state => state.busy,
  uploadSuccessMessage: state => state.uploadSuccessMessage,
  uploadErrorMessage: state => state.uploadErrorMessage
};

// actions
const actions = {
  getAllDefaultDocumentTypes: async ({ commit }) => {
    try {
      commit("clearMessages");
      axiosWrapperService
        .axiosGet("/getAllDefaultDocumentTypes")
        .then(response => {
          commit("clearAndPushToDocumentTypeList", response.data.data);
        })
        .catch(error => {
          commit("setUploadErrorMessage", formatErrorMessage(error, true));
        });
    } catch (e) {
      commit("setUploadErrorMessage", "Failed to get the list.");
    }
  },
  submitFilesToUpload: async (
    { dispatch, state, commit },
    { applicationId, formData }
  ) => {
    commit("clearMessages");
    commit("setBusy");
    axiosWrapperService
      .axiosPost(`/applications/${applicationId}/uploadDocs`, formData)
      .then(() => {
        commit(
          "setUploadSuccessMessage",
          "Successfully added files to document request"
        );
      })
      .catch(error => {
        commit("setUploadErrorMessage", formatErrorMessage(error, false));
      })
      .finally(() => {
        commit("resetBusy");
      });
  }
};

// mutations
const mutations = {
  setBusy(state) {
    state.busy = true;
  },
  resetBusy(state) {
    state.busy = false;
  },
  clearAndPushToDocumentTypeList(state, documentTypeList) {
    state.documentTypeList = documentTypeList;
  },

  setDocFileUploadValidationMessage(state, docFileUploadValidationMessage) {
    state.docFileUploadValidationMessage = docFileUploadValidationMessage;
  },
  setDocumentRequestSuccessMessage(state, documentRequestSuccessMessage) {
    state.documentRequestSuccessMessage = documentRequestSuccessMessage;
  },
  setDocumentRequestErrorMessage(state, documentRequestErrorMessage) {
    state.documentRequestErrorMessage = documentRequestErrorMessage;
  },
  setUploadErrorMessage(state, message) {
    state.uploadErrorMessage = message;
  },
  setUploadSuccessMessage(state, message) {
    state.uploadSuccessMessage = message;
  },
  clearMessages(state) {
    state.uploadErrorMessage = "";
    state.uploadSuccessMessage = "";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
