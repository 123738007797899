<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-2">
      <h2 class="text-lg font-medium">
        {{ componentTitle }}
      </h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div
        v-if="type !== 'simple'"
        class="flex flex-col sm:flex-row sm:items-end xl:items-start"
      >
        <div
          id="tabulator-html-filter-form"
          class="xl:flex sm:mr-auto w-full"
          style="justify-content: space-between;"
        >
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-3"
              >Search</label
            >
            <input
              id="tabulator-html-filter-value"
              v-model="searchTerm"
              class="form-control sm:w-36 xxl:w-full mt-2 sm:mt-0"
              placeholder="Search Page"
              type="text"
              @keyup.enter="onSearch"
            />
            <button
              id="tabulator-html-filter-go"
              class="btn w-full sm:w-16 sm:ml-1 bg-yellow-400"
              type="button"
              @click="onSearch"
            >
              Go
            </button>
            <button
              id="tabulator-html-filter-reset"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
              type="button"
              @click="onResetSearch"
            >
              Reset
            </button>
          </div>

          <div v-if="showStatusFilter" class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-3"
              >Status</label
            >
            <select
              id="tabulator-html-filter-field"
              class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
              @change="onFilter($event.target.value)"
            >
              <option class="placeholder" selected="selected">All</option>
              <option value="1">Published</option>
              <option value="0">UnPublished</option>
            </select>
          </div>

          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-3"
            >View</label
            >
            <select
                id="tabulator-html-filter-field"
                class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
                @change="updateTabulatorBasedOnSelection($event.target.value)"
            >
              <option value="0">Component</option>
              <option value="1" selected="selected">Static Page</option>
            </select>
          </div>

        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator-cms"
          ref="tableRef"
          class="mt-2 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { cmsDetails } from "@/composables/components/content-manager/cms";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false,
      default: "Content Management"
    },
    filters: {
      type: Object,
      require: false
    },
    customerId: {
      type: Number,
      require: true,
      default: null
    },
    type: {
      type: String,
      require: false,
      default: "full"
    }
  },
  setup(props) {
    const {
      adminUser,
      tableRef,
      statuses,
      searchTerm,
      onSearch,
      showStatusFilter,
      onFilter,
      onResetSearch,
      path,
      route,
      componentTitle,
      updateTabulatorBasedOnSelection
    } = cmsDetails(props, true);

    return {
      adminUser,
      tableRef,
      statuses,
      searchTerm,
      showStatusFilter,
      onSearch,
      onFilter,
      onResetSearch,
      path,
      route,
      componentTitle,
      updateTabulatorBasedOnSelection
    };
  }
});
</script>
<style scoped>
.tabulator,
.tabulator {
  font-size: 13px;
}
.tabulator-col-title {
  text-align: center;
}
.tabulator .tabulator-header {
  border-top-width: 0;
}
</style>
