<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Edit Admin</h2>
    </div>
    <div v-if="adminRoles" class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Validation -->
        <div v-if="admin" class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">
              Admin Form
            </h2>
          </div>
          <div id="form-validation" class="p-5">
            <div class="preview">
              <!-- BEGIN: Validation Form -->
              <form class="validate-form" @submit.prevent="save">
                <div class="input-form">
                  <label
                    for="validation-form-2"
                    class="form-label w-full flex flex-col sm:flex-row"
                  >
                    Full Name
                    <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600"
                      >Required, at least 2 characters</span
                    >
                  </label>
                  <input
                    id="validation-form-2"
                    v-model.trim="validate.fullname.$model"
                    type="text"
                    name="fullname"
                    class="form-control"
                    :class="{ 'border-theme-6': validate.fullname.$error }"
                    placeholder="name"
                  />
                  <template v-if="validate.fullname.$error">
                    <div
                      v-for="(error, index) in validate.fullname.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
                </div>
                <div class="input-form mt-3">
                  <label
                    for="validation-form-3"
                    class="form-label w-full flex flex-col sm:flex-row"
                  >
                    Email
                    <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600"
                      >Required, email address format</span
                    >
                  </label>
                  <input
                    id="validation-form-3"
                    v-model.trim="validate.email.$model"
                    type="email"
                    name="email"
                    class="form-control"
                    :class="{ 'border-theme-6': validate.email.$error }"
                    placeholder="example@gmail.com"
                  />
                  <template v-if="validate.email.$error">
                    <div
                      v-for="(error, index) in validate.email.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
                </div>
                <div class="input-form mt-3">
                  <label class="form-label w-full flex flex-col sm:flex-row">
                    Assign Roles
                    <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600"
                      >Required</span
                    >
                  </label>
                  <div
                    v-for="(adminRole, adminRoleKey) in adminRoles"
                    :key="adminRoleKey"
                    class="input-form mt-3"
                  >
                    <label class="form-label w-full flex flex-col sm:flex-row">
                      {{ beautifySlug(adminRole) }}
                    </label>
                    <input
                      v-model="formData.roles"
                      type="checkbox"
                      :value="adminRole"
                      class="form-control form-check-input"
                    />
                  </div>
                </div>
                <button type="submit" class="bg-yellow-500 btn mt-5">
                  Save
                </button>
                <button
                  type="button"
                  class="btn btn-dark-soft w-20 ml-1"
                  @click="cancel"
                >
                  Cancel
                </button>
              </form>
              <!-- END: Validation Form -->
              <!-- BEGIN: Success Notification Content -->
              <div
                id="success-notification-content"
                class="toastify-content hidden flex"
              >
                <CheckCircleIcon class="text-theme-9" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Registration success!</div>
                  <div class="text-gray-600 mt-1">
                    Please check your e-mail for further info!
                  </div>
                </div>
              </div>
              <!-- END: Success Notification Content -->
              <!-- BEGIN: Failed Notification Content -->
              <div
                id="failed-notification-content"
                class="toastify-content hidden flex"
              >
                <XCircleIcon class="text-theme-6" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Registration failed!</div>
                  <div class="text-gray-600 mt-1">
                    Please check the field form.
                  </div>
                </div>
              </div>
              <!-- END: Failed Notification Content -->
            </div>
          </div>
        </div>
        <!-- END: Form Validation -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  watch
} from "vue";
import { required, minLength, email } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useStore } from "@/store";
import Toastify from "toastify-js";
import Swal from "sweetalert2";
import { customSwalMessageBuilder } from "@/composables/utils/customSwalMessageBuilder";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const formData = reactive({
      fullname: "",
      email: "",
      roles: []
    });
    const rules = {
      fullname: {
        required,
        minLength: minLength(5)
      },
      email: {
        required,
        email
      }
    };

    const validate = useVuelidate(rules, toRefs(formData));
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const adminRoles = computed(() => store.state.editAdmin.adminRoles);
    const admin = computed(() => store.state.editAdmin.admin);

    function init() {
      store.dispatch("editAdmin/getAdminRoles");
      store.dispatch("editAdmin/getAdmin", { uuid: route.params.uuid });
    }

    const { buildSwalSuccess, buildSwalHtmlError } = customSwalMessageBuilder();

    const editAdminSuccessMessage = computed(
      () => store.state.editAdmin.editAdminSuccessMessage
    );

    const editAdminErrorMessage = computed(
      () => store.state.editAdmin.editAdminErrorMessage
    );

    watch(
      () => admin.value,
      admin => {
        if (admin) {
          formData.email = admin.email;
          formData.fullname = admin.fullname;
          formData.roles = admin.roles;
        }
      }
    );

    watch(
      () => editAdminSuccessMessage.value,
      message => {
        if (message) {
          Swal.fire(buildSwalSuccess(message)).then(confirmed => {
            if (confirmed.isConfirmed) {
              store.commit("editAdmin/clearMessages");
              router.push('/admin-list');
            }
          });
        }
      }
    );

    watch(
      () => editAdminErrorMessage.value,
      message => {
        if (message) {
          Swal.fire(buildSwalHtmlError(message)).then(confirmed => {
            if (confirmed.isConfirmed) {
              store.commit("editAdmin/clearMessages");
            }
          });
        }
      }
    );

    const cancel = () => {
      router.back();
    };

    const save = () => {
      store.commit("main/setFullPageLoading");
      validate.value.$touch();
      if (validate.value.$invalid) {
        Toastify({
          node: cash("#failed-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
        store.commit("main/resetFullPageLoading");
      } else {
        const payload = formData;
        formData.uuid = route.params.uuid;
        store.dispatch("editAdmin/updateAdmin", payload);
      }
    };

    function beautifySlug(givenSlug) {
      let slug = givenSlug.replace("-", " ");
      return slug.toUpperCase().charAt(0) + slug.slice(1);
    }

    onMounted(() => {
      init();
    });

    return {
      beautifySlug,
      adminRoles,
      admin,
      validate,
      formData,
      save,
      cancel
    };
  }
});
</script>
