<template>
  <div
    v-if="applicationId && kycWithAdditionalAttributes?.basicAttributes?.mobile"
    class="text-theme-16 cursor-pointer shadow-md box top-2 right-2 shadow-md border w-8 h-8 flex items-center justify-center z-50 "
    @click="redirect()"
  >
    <span><PhoneCallIcon class="w-4 h-4"/></span>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  props: {
    applicationId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const kycWithAdditionalAttributes = computed(
      () => store.state.customerAndLoanDetails.kycWithAdditionalAttributes
    );
    function redirect() {
      window.location =
        "tel:" + kycWithAdditionalAttributes.value.basicAttributes.mobile;
    }
    return {
      redirect,
      kycWithAdditionalAttributes
    };
  }
});
</script>

<style scoped></style>
