import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";

const state = () => {
  return {
    applicationId: 0,
    cancelledSuccessMessage: "",
    errorMessage: "",
    schedules: {},
    isFetchingGetPayments: {
      NULL: false,
      PTP: false,
      RSC: false
    }
  };
};

const getters = {
  cancelledSuccessMessage: state => state.cancelledSuccessMessage,
  errorMessage: state => state.errorMessage,
  schedules: state => state.schedules
};

const mutations = {
  setIsFetchingGetPayments(state, type) {
    state.isFetchingGetPayments[type] = true;
  },
  resetIsFetchingGetPayments(state, type) {
    state.isFetchingGetPayments[type] = false;
  },
  setApplicationId(state, applicationId) {
    state.applicationId = applicationId;
  },
  setCancelledSuccessMessage(state, message) {
    state.cancelledSuccessMessage = message;
  },
  setErrorMessage(state, message) {
    state.errorMessage = message;
  },
  clearMessages(state) {
    state.cancelledSuccessMessage = "";
    state.errorMessage = "";
  },
  setPaymentSchedules(state, { type, schedules }) {
    state.schedules[type] = schedules;
  }
};

const actions = {
  getPaymentSchedules: async (
    { commit, dispatch },
    { applicationId, type }
  ) => {
    commit("setIsFetchingGetPayments", type);
    axiosWrapperService
      .axiosGet(`/applications/${applicationId}/paymentSchedules?type=${type}`)
      .then(response => {
        commit("setPaymentSchedules", { type, schedules: response.data.data });
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: formatErrorMessage(error),
            type: "error"
          },
          { root: true }
        );
      })
      .finally(() => commit("resetIsFetchingGetPayments", type));
  },
  cancelMultipleSchedules: async ({ dispatch, state, commit }, ids) => {
    commit("main/setFullPageLoading", null, { root: true });
    axiosWrapperService
      .axiosPost(
        `/applications/${state.applicationId}/cancelPaymentSchedules`,
        { ids: ids }
      )
      .then(response => {
        commit("setCancelledSuccessMessage", response.data.message);
      })
      .catch(error => {
        commit("setErrorMessage", formatErrorMessage(error, true));
      })
      .finally(() => {
        commit("main/resetFullPageLoading", null, { root: true });
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
