import { computed, onMounted, ref } from "vue";
import { useStore } from "@/store";
import { getValuesInBetween } from "./utils/delegationBoardRecodsHelper";

export const lendingClosureReviewDelegationBoard = () => {
  const store = useStore();

  const sideMenuCollapsed = computed(() => store.state.main.sideMenuCollapsed);

  const adminUser = computed(() => store.state.auth.loggedUser);

  function onClickResolveAppDetailsAndReRoute(applicationId) {
    store.dispatch(
      "lnAppDtsRouter/resolveAndReRouteLnAssessmentDtsPage",
      applicationId
    );
  }

  const busy = computed(() => store.state.lnClosureReviewDelegBrd.busy);

  const agentNameForFilter = ref("");

  const selectedAgent = computed(
    () => store.state.lnClosureReviewDelegBrd.selectedAgent
  );

  const lendingAgents = computed(
    () => store.state.lnClosureReviewDelegBrd.lendingAgents
  );

  const fetchingLendingAgents = computed(
    () => store.state.lnClosureReviewDelegBrd.fetchingLendingAgents
  );

  const unassignedLoans = computed(
    () => store.state.lnClosureReviewDelegBrd.unassignedLoans
  );

  const fetchingUnassignedLoans = computed(
    () => store.state.lnClosureReviewDelegBrd.fetchingUnassignedLoans
  );

  const selectedUnassignedLoans = computed(
    () => store.state.lnClosureReviewDelegBrd.selectedUnassignedLoans
  );

  function toggleInsertionToAssignmentSelectionBag(id, massSelection = false) {
    let entries = [];

    if (selectedAgent.value > 0) {
      //If mass selection applied
      if (massSelection && selectedUnassignedLoans.value.length) {
        let lastEntry =
          selectedUnassignedLoans.value[
            selectedUnassignedLoans.value.length - 1
          ];
        if (lastEntry === id) return false;
        entries = getValuesInBetween({
          records: unassignedLoans.value,
          start: lastEntry,
          end: id,
          reverse: selectedUnassignedLoans.value.includes(id)
        });
      } else {
        entries = getValuesInBetween({
          records: unassignedLoans.value,
          start: id
        });
      }
      entries.forEach(value => {
        if (selectedUnassignedLoans.value.includes(value)) {
          store.commit(
            "lnClosureReviewDelegBrd/removeFromSelectedUnassignedLoans",
            value
          );
        } else {
          store.commit(
            "lnClosureReviewDelegBrd/pushToSelectedUnassignedLoans",
            value
          );
        }
      });
    }
  }

  function removeFromSelectedUnassignedLoans(id) {
    store.commit(
      "lnClosureReviewDelegBrd/removeFromSelectedUnassignedLoans",
      id
    );
  }

  function emptySelectedUnassignedLoansBag() {
    store.commit("lnClosureReviewDelegBrd/clearSelectedUnassignedLoans");
  }

  const selectedAssignedLoans = computed(
    () => store.state.lnClosureReviewDelegBrd.selectedAssignedLoans
  );

  function toggleInsertionToUnAssignmentSelectionBag(assessmentRecord) {
    if (selectedAssignedLoans.value.includes(assessmentRecord.uuid)) {
      store.commit(
        "lnClosureReviewDelegBrd/removeFromSelectedAssignedLoans",
        assessmentRecord.uuid
      );
    } else {
      store.commit(
        "lnClosureReviewDelegBrd/pushToSelectedAssignedLoans",
        assessmentRecord.uuid
      );
    }
  }

  function removeFromSelectedAssignedLoans(id) {
    store.commit("lnClosureReviewDelegBrd/removeFromSelectedAssignedLoans", id);
  }

  function emptySelectedAssignedLoansBag() {
    store.commit("lnClosureReviewDelegBrd/clearSelectedAssignedLoans");
  }

  function setSelectedAgent(id) {
    store.commit("lnClosureReviewDelegBrd/setSelectedAgent", id);
  }

  function selectAllUnassigned() {
    store.commit("lnClosureReviewDelegBrd/setAllUnassignedLoansForSelection");
  }
  function assignSelectedUnassigned(agentId) {
    if (selectedAgent.value < 1 || selectedUnassignedLoans.value.length < 1) {
      store.dispatch("globalToastMessage/showSimpleToast", {
        message:
          "Both Agent and Applications must be selected for assignment !",
        type: "warning"
      });
    } else {
      store.commit("main/setFullPageLoading");
      store.dispatch(
        "lnClosureReviewDelegBrd/submitUnassignedClosureReviewsForAssignment",
        agentId
      );
    }
  }

  function unassignSelectedAssigned(agentId) {
    if (selectedAssignedLoans.value.length < 1) {
      store.dispatch("globalToastMessage/showSimpleToast", {
        message:
          "Both Agent and Applications must be selected for Un-Assignment !",
        type: "warning"
      });
    } else {
      store.commit("main/setFullPageLoading");
      store.dispatch(
        "lnClosureReviewDelegBrd/submitAssignedClosureReviewsForUnassignment",
        agentId
      );
    }
  }

  function resetAgentSelection() {
    store.commit("lnClosureReviewDelegBrd/setSelectedAgent", 0);
    emptySelectedUnassignedLoansBag();
  }

  function getLendingAgents() {
    store.dispatch(
      "lnClosureReviewDelegBrd/getLendingClosureReviewAssessorAdminsWithAssociationsForLendingBoard"
    );
    store.dispatch(
      "lnClosureReviewDelegBrd/getUnassignedLoansClosureReviewsForAssignment"
    );
  }

  function clearAgentFilter() {
    agentNameForFilter.value = "";
    store.commit(
      "lnClosureReviewDelegBrd/setFilteredAgentName",
      agentNameForFilter.value
    );
    store.dispatch(
      "lnClosureReviewDelegBrd/getLendingClosureReviewAssessorAdminsWithAssociationsForLendingBoard"
    );
  }
  function submitAgentFilter() {
    store.commit(
      "lnClosureReviewDelegBrd/setFilteredAgentName",
      agentNameForFilter.value
    );
    if (agentNameForFilter.value !== "")
      store.dispatch(
        "lnClosureReviewDelegBrd/getLendingClosureReviewAssessorAdminsWithAssociationsForLendingBoard"
      );
  }

  onMounted(() => {
    getLendingAgents();
  });

  return {
    adminUser,
    agentNameForFilter,
    clearAgentFilter,
    submitAgentFilter,
    fetchingLendingAgents,
    lendingAgents,
    unassignedLoans,
    fetchingUnassignedLoans,
    selectedUnassignedLoans,
    selectedAssignedLoans,
    toggleInsertionToAssignmentSelectionBag,
    toggleInsertionToUnAssignmentSelectionBag,
    removeFromSelectedUnassignedLoans,
    removeFromSelectedAssignedLoans,
    emptySelectedUnassignedLoansBag,
    emptySelectedAssignedLoansBag,
    selectAllUnassigned,
    assignSelectedUnassigned,
    unassignSelectedAssigned,
    selectedAgent,
    setSelectedAgent,
    resetAgentSelection,
    onClickResolveAppDetailsAndReRoute,
    busy,
    sideMenuCollapsed
  };
};
