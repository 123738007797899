import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";

const state = () => {
  return {
    processingSubmit: false,
    existingFollowUps: [],
    addLoanFollowUpCounter: 0
  };
};

// getters
const getters = {
  processingSubmit: state => state.processingSubmit,
  existingFollowUps: state => state.existingFollowUps,
  addLoanFollowUpCounter: state => state.addLoanFollowUpCounter
};

// actions
const actions = {
  getNonCompleteFollowUpsForApplication: async (
    { dispatch, commit },
    { applicationId }
  ) => {
    commit("setProcessingSubmit");
    axiosWrapperService
      .axiosGet(`/getNonCompleteFollowUpsForApplication/${applicationId}`)
      .then(response => {
        commit("setNonCompleteFollowupsForApplication", response.data.data);
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("resetProcessingSubmit");
      });
  },

  addCardVerfFollowUpOnLoan: async (
    { dispatch, state, commit },
    { applicationId, formData }
  ) => {
    commit("setProcessingSubmit");
    axiosWrapperService
      .axiosPost("/addCardVerfFollowUpOnLoan/" + applicationId, formData)
      .then(() => {
        // TODO : temporarily disabled
        // commit("timelineNotesViewer/incrementUpdateCounter", null, {
        //   root: true
        // });
        commit("incrementAddFollowupCounter");
        dispatch("loanAssessmentInfos/getLoanAssessment", "", { root: true });
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: "Successfully added Follow Up on loan.", type: "success" },
          { root: true }
        );
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("resetProcessingSubmit");
      });
  },
  addBankReauthFollowUpOnLoan: async (
    { dispatch, state, commit },
    { applicationId, formData }
  ) => {
    commit("setProcessingSubmit");
    axiosWrapperService
      .axiosPost("/addBankReauthFollowUpOnLoan/" + applicationId, formData)
      .then(() => {
        // TODO : temporarily disabled
        // commit("timelineNotesViewer/incrementUpdateCounter", null, {
        //   root: true
        // });
        commit("incrementAddFollowupCounter");
        dispatch("loanAssessmentInfos/getLoanAssessment", "", { root: true });
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: "Successfully added Follow Up on loan.", type: "success" },
          { root: true }
        );
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("resetProcessingSubmit");
      });
  },
  addBankStatementsUnavailableFollowUpOnLoan: async (
    { dispatch, state, commit },
    { applicationId, formData }
  ) => {
    commit("setProcessingSubmit");
    axiosWrapperService
      .axiosPost(
        "/addBankStatementsUnavailableFollowUpOnLoan/" + applicationId,
        formData
      )
      .then(() => {
        commit("incrementAddFollowupCounter");
        dispatch("loanAssessmentInfos/getLoanAssessment", "", { root: true });
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: "Successfully added Follow Up on loan.", type: "success" },
          { root: true }
        );
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("resetProcessingSubmit");
      });
  },
  addGenericDocReqFollowUpOnLoan: async (
    { dispatch, state, commit },
    { applicationId, formData }
  ) => {
    commit("setProcessingSubmit");
    axiosWrapperService
      .axiosPost("/addGenericDocReqFollowUpOnLoan/" + applicationId, formData)
      .then(() => {
        // TODO : temporarily disabled
        // commit("timelineNotesViewer/incrementUpdateCounter", null, {
        //   root: true
        // });
        commit("incrementAddFollowupCounter");
        dispatch("loanAssessmentInfos/getLoanAssessment", "", { root: true });
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: "Successfully added Follow Up on loan.", type: "success" },
          { root: true }
        );
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        dispatch(
          "lnAppDocReq/getDocumentRequestsOfLoan",
          {
            applicationId: applicationId,
            successMessage: ""
          },
          { root: true }
        );
        commit("resetProcessingSubmit");
      });
  }
};

// mutations
const mutations = {
  setProcessingSubmit(state) {
    state.processingSubmit = true;
  },
  resetProcessingSubmit(state) {
    state.processingSubmit = false;
  },
  setNonCompleteFollowupsForApplication(state, followUps) {
    state.existingFollowUps = followUps;
  },
  incrementAddFollowupCounter(state) {
    state.addLoanFollowUpCounter++;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
