<template>
  <div
    class="grid grid-cols-12"
    :class="{ 'gap-6': !loggedUser.roles.includes('lending-manager') }"
  >
    <div
      class="col-span-12 xxl:col-span-9"
      v-if="!loggedUser.roles.includes('lending-manager')"
    >
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: General Report -->
        <div class="col-span-12 mt-8">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">General Report</h2>
            <a
              class="ml-auto flex items-center text-theme-1 dark:text-theme-10 cursor-pointer"
              @click.prevent="getDisbursalStatCounters"
            >
              <RefreshCcwIcon class="w-4 h-4 mr-3" /> Reload Data
            </a>
          </div>
          <div class="grid grid-cols-12 gap-6 mt-5">
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <DownloadIcon class="report-box__icon text-theme-10" />
                    <div class="ml-auto"></div>
                  </div>
                  <div class="text-3xl font-bold leading-8 mt-6">
                    {{ totalDisbursed }}
                  </div>
                  <div class="text-base text-gray-600 mt-1">
                    Total Disbursals
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <AlertOctagonIcon class="report-box__icon text-red-400" />
                    <div class="ml-auto"></div>
                  </div>
                  <div class="text-3xl font-bold leading-8 mt-6">
                    {{ totalFailedDisbursal }}
                  </div>
                  <div class="text-base text-gray-600 mt-1">
                    Total Failed Disbursals
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <CalendarIcon class="report-box__icon text-theme-12" />
                    <div class="ml-auto"></div>
                  </div>
                  <div class="text-3xl font-bold leading-8 mt-6">
                    {{ totalDisbursalsOfThisWeek }}
                  </div>
                  <div class="text-base text-gray-600 mt-1">
                    Total Disbursal This Week
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <CalendarIcon class="report-box__icon text-theme-9" />
                    <div class="ml-auto"></div>
                  </div>
                  <div class="text-3xl font-bold leading-8 mt-6">
                    {{ totalDisbursalsOfThisMonth }}
                  </div>
                  <div class="text-base text-gray-600 mt-1">
                    Total Disbursal This Month
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12" v-else>
      <iframe
        title="New LMS Reports"
        height="800"
        class="w-full"
        src="https://app.powerbi.com/reportEmbed?reportId=5326285f-2cbb-442f-ba40-3f0d9e0f128c&autoAuth=true&ctid=f3672705-039a-40b1-9820-0e77c47f7030&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWF1c3RyYWxpYS1lYXN0LWItcHJpbWFyeS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D"
        frameborder="0"
        allowFullScreen="true"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, provide, onMounted, computed } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  setup() {
    const salesReportFilter = ref();
    const importantNotesRef = ref();

    const store = useStore();

    const loggedUser = computed(() => store.state.auth.loggedUser);

    provide("bind[importantNotesRef]", el => {
      importantNotesRef.value = el;
    });

    const totalDisbursed = computed(() => store.state.dashboard.totalDisbursed);

    const totalFailedDisbursal = computed(
      () => store.state.dashboard.totalFailedDisbursal
    );

    const totalDisbursalsOfThisMonth = computed(
      () => store.state.dashboard.totalDisbursalsOfThisMonth
    );

    const totalDisbursalsOfThisWeek = computed(
      () => store.state.dashboard.totalDisbursalsOfThisWeek
    );

    const prevImportantNotes = () => {
      const el = importantNotesRef.value;
      el.tns.goTo("prev");
    };
    const nextImportantNotes = () => {
      const el = importantNotesRef.value;
      el.tns.goTo("next");
    };
    function getDisbursalStatCounters() {
      store.dispatch("dashboard/getDisbursalStatCounters");
    }
    function init() {
      getDisbursalStatCounters();
    }

    onMounted(() => {
      init();
    });

    return {
      loggedUser,
      salesReportFilter,
      prevImportantNotes,
      nextImportantNotes,
      totalDisbursed,
      totalFailedDisbursal,
      totalDisbursalsOfThisMonth,
      totalDisbursalsOfThisWeek
    };
  }
});
</script>
