import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";

// actions
const actions = {
  clearMetaErrors: async ({ commit }) => commit("clearMetaError"),
  savePage: async ({ commit, dispatch }, { endpoint, payload }) => {
    commit("setBusy");
    axiosWrapperService
      .axiosPost(endpoint, payload)
      .then(response => {
        commit("setRecent", response.data.data);
        commit("setSuccessMessage", "Successfully added static page.");
        commit("resetBusy");
      })
      .catch(error => {
        if (error.response.data?.errors) {
          commit("setValidationErrors", error.response.data.errors);
        }
        if (error.response.data?.error) {
          commit("setValidationErrors", error.response.data.error);
        }
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
        commit("resetBusy");
      });
  },
  updatePage: async ({ commit, dispatch }, { endpoint, payload }) => {
    commit("setBusy");
    axiosWrapperService
      .axiosPost(endpoint, payload)
      .then(response => {
        commit("setRecent", response.data.data);
        commit("setSuccessMessage", "Successfully updated static page.");
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: "Successfully updated static page.", type: "success" },
          { root: true }
        );
        commit("resetBusy");
      })
      .catch(error => {
        if (error.response.data?.errors) {
          commit("setValidationErrors", error.response.data.errors);
        }
        if (error.response.data?.error) {
          commit("setValidationErrors", error.response.data.error);
        }
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
        commit("resetBusy");
      });
    commit("main/resetFullPageLoading", null, { root: true });
  },
  deletePage: async ({ commit, dispatch }, { id, deleteEndpoint }) => {
    return await axiosWrapperService
      .axiosDelete(`/${deleteEndpoint}/${id}`)
      .then(response => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: "Successfully deleted", type: "success" },
          { root: true }
        );
        commit("main/resetFullPageLoading", null, { root: true });
        return response.data.result;
      })
      .catch(error => {
        commit("main/resetFullPageLoading", null, { root: true });
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
        return false;
      });
  },
  updateStatus: async ({ commit, dispatch }, payload) => {
    return await axiosWrapperService
      .axiosPost(`/cms/pages/${payload.id}/status`, { status: payload.value })
      .then(response => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: "Successfully updated static page.", type: "success" },
          { root: true }
        );
        return true;
      })
      .catch(error => {
        if (error.response.data?.errors) {
          commit("setValidationErrors", error.response.data.errors);
        }

        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
        return false;
      });
  }
};

// mutations
const mutations = {
  //Customer
  setCustomer(state, customer) {
    state.customer = customer;
  },
  clearMetaError(state) {
    state.metaErrors = "";
  },
  setRecent(state, page) {
    state.recent = page;
  },
  setSuccessMessage(state, message) {
    state.successMessage = message;
  },
  setValidationErrors(state, errors) {
    state.validationErrors = errors;
  },
  setActiveRecords(state, records) {
    state.activeRecords = records;
  },
  setActivePage(state, record) {
    state.activeRecord = record;
  },
  setBusy(state) {
    state.isBusy = true;
  },
  resetBusy(state) {
    state.isBusy = false;
  }
};

const state = () => {
  return {
    customer: null,
    activeRecords: [],
    activeRecord: [],
    successMessage: "",
    customerDeleted: false,
    validationErrors: [],
    metaErrors: "",
    recent: null,
    isBusy: false
  };
};

// getters
const getters = {
  customer: state => state.customer,
  metaErrors: state => state.metaErrors,
  validationErrors: state => state.validationErrors,
  activeRecords: state => state.activeRecords,
  activeRecord: state => state.activeRecord,
  successMessage: state => state.successMessage,
  isBusy: state => state.isBusy
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
