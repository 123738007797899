<template>
  <!-- BEGIN: Success Notification Content -->
  <div>
    <div
      id="success-alert-message-content"
      class="toastify-content hidden flex"
    >
      <CheckCircleIcon id="check-circle" class="text-theme-3" />
      <div class="ml-4 mr-4">
        <div id="success-alert-message-title" class="font-medium"></div>
        <div id="success-alert-message-body" class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <div id="error-alert-message-content" class="toastify-content hidden flex">
      <XCircleIcon id="xcircle" class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div id="error-alert-message-title" class="font-medium"></div>
        <div id="error-alert-message-body" class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <div
      id="warning-alert-message-content"
      class="toastify-content hidden flex"
    >
      <AlertTriangleIcon id="alert-triangle" class="text-theme-11" />
      <div class="ml-4 mr-4">
        <div id="warning-alert-message-title" class="font-medium"></div>
        <div id="warning-alert-message-body" class="text-gray-600 mt-1"></div>
      </div>
    </div>
  </div>
  <!-- END: Success Notification Content -->
</template>

<script>
import { defineComponent, onMounted, watch, reactive, computed } from "vue";
import { useStore } from "@/store";
import Toastify from "toastify-js";

export default defineComponent({
  setup() {
    const alertIconType = reactive({
      checkCircle: "check-circle",
      xCircle: "xcircle",
      alertTriangle: "alert-triangle"
    });
    const alertBackGround = reactive({
      success: "linear-gradient(to right, #00b09b, #96c93d)",
      warning: "#fe9d1e",
      error: "#ff683b"
    });
    const store = useStore();
    const isSimple = computed(() => store.state.globalToastMessage.isSimple);
    const toastMessageIcon = computed(
      () => store.state.globalToastMessage.toastMessageIcon
    );
    const toastMessageTitle = computed(
      () => store.state.globalToastMessage.toastMessageTitle
    );
    const toastMessageBody = computed(
      () => store.state.globalToastMessage.toastMessageBody
    );
    const toastMessageType = computed(
      () => store.state.globalToastMessage.toastMessageType
    );
    const toastReqCounter = computed(
      () => store.state.globalToastMessage.toastReqCounter
    );
    function getStyleBackgroundByType() {
      if (toastMessageType.value === "warning") {
        return alertBackGround.warning;
      } else if (toastMessageType.value === "error") {
        return alertBackGround.error;
      }

      return alertBackGround.success;
    }

    watch(
      () => toastReqCounter.value,
      countNumber => {
        if (countNumber > 0) {
          if (isSimple.value) {
            Toastify({
              text: toastMessageBody.value,
              duration: 4000,
              newWindow: true,
              close: false,
              gravity: "top", // `top` or `bottom`
              position: "right", // `left`, `center` or `right`
              stopOnFocus: true, // Prevents dismissing of toast on hover
              style: {
                // background: "linear-gradient(to right, #00b09b, #96c93d)",
                background: getStyleBackgroundByType(),
                padding: "10px",
                "border-radius": "10px",
                "font-size": "medium",
                color: "white",
                "box-shadow": "-5px 8px 20px 2px rgba(0,0,0,0.62)"
              },
              onClick: function() {} // Callback after click
            }).showToast();
          } else {
            let toastIdsPrefix = "success";
            if (toastMessageIcon.value === alertIconType.xCircle) {
              toastIdsPrefix = "error";
            }
            if (toastMessageIcon.value === alertIconType.alertTriangle) {
              toastIdsPrefix = "warning";
            }

            document.getElementById(
              toastIdsPrefix + "-alert-message-title"
            ).innerHTML = toastMessageTitle.value;
            document.getElementById(
              toastIdsPrefix + "-alert-message-body"
            ).innerHTML = toastMessageBody.value;
            Toastify({
              text: toastMessageBody.value,
              className: "alert alert-dark-soft show flex items-center mb-2",
              style: {
                // background: "#b0b0b0",
                padding: "10px"
              },
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: "top",
              position: "right",
              stopOnFocus: true
            }).showToast();
          }
          store.commit("main/resetFullPageLoading");
          return;
        }
      }
    );

    onMounted(() => {});

    return {
      alertIconType,
      toastMessageIcon
    };
  }
});
</script>
