export const lenderDashToQueueMapper = {
  "lending-agent": "assessment-queue",
  "lending-manager": "approval-queue",
  "lending-converter": "converter-queue"
};

export const defaultOrder = {
  "assessment-queue": "priority",
  "approval-queue": "priority",
  "converter-queue": "priority"
};
