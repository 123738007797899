<template>
  <div
    class="
        bg-white
        opacity-75
        flex flex-col
        items-center
        justify-center
        h-screen
      "
  >
    <div class="relative flex justify-center items-center h-3">
      <LoadingIcon class="w-16 h-16" color="green" icon="bars" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "@/store";
import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";
import { useRoute } from "vue-router";
import router from "@/router";

export default defineComponent({
  setup() {
    const store = useStore();
    const route = useRoute();
    function getLoan() {
      let urlSegments = route.path.split("/");
      let applicationId = parseInt(urlSegments[urlSegments.length - 1]);
      axiosWrapperService
        .axiosGet("/getLoanApplications/" + applicationId)
        .then(response => {
          if (["Applied", "Approved"].includes(response.data.data.status)) {
            store.dispatch(
              "lnAppDtsRouter/resolveAndReRouteLnAssessmentDtsPage",
              applicationId
            );
          } else {
            router.push({
              name: "loan-application-details",
              params: {
                customerId: response.data.data.user_id,
                applicationId: response.data.data.id
              }
            });
          }
        })
        .catch(error => {
          store.dispatch("globalToastMessage/showSimpleToast", {
            message: formatErrorMessage(error),
            type: "error"
          });
        });
    }
    store.commit("main/setFullPageLoading");
    getLoan();
  }
});
</script>
