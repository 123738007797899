<template>
  <!-- BEGIN: Mobile Menu -->
  <div class="mobile-menu md:hidden">
    <div class="mobile-menu-bar">
      <a class="flex mr-auto" href>
        <img
          alt="Icewall Tailwind HTML Admin Template"
          class="w-6"
          src="@/assets/images/logo.svg"
        />
      </a>
      <BarChart2Icon
        class="w-8 h-8 text-white transform -rotate-90"
        @click="toggleMobileMenu"
      />
    </div>
    <transition @enter="enter" @leave="leave">
      <ul v-if="activeMobileMenu" class="border-t border-theme-29 py-5">
        <!-- BEGIN: First Child -->
        <template v-for="(menu, menuKey) in formattedMenu">
          <li
            v-if="menu == 'devider'"
            :key="menu + menuKey"
            class="menu__devider my-6"
          ></li>
          <li v-else :key="menu + menuKey">
            <a
              :class="{
                'menu--active': menu.active,
                'menu--open': menu.activeDropdown
              }"
              class="menu"
              href="javascript:"
              @click="linkTo(menu, router)"
            >
              <div class="menu__icon">
                <component :is="menu.icon" />
              </div>
              <div class="menu__title">
                {{ menu.title }}
                <div
                  v-if="menu.subMenu"
                  :class="{ 'transform rotate-180': menu.activeDropdown }"
                  class="menu__sub-icon"
                >
                  <ChevronDownIcon />
                </div>
              </div>
            </a>
            <!-- BEGIN: Second Child -->
            <transition @enter="enter" @leave="leave">
              <ul v-if="menu.subMenu && menu.activeDropdown">
                <li
                  v-for="(subMenu, subMenuKey) in menu.subMenu"
                  :key="subMenuKey"
                >
                  <a
                    :class="{ 'menu--active': subMenu.active }"
                    class="menu"
                    href="javascript:"
                    @click="linkTo(subMenu, router)"
                  >
                    <div class="menu__icon">
                      <ActivityIcon />
                    </div>
                    <div class="menu__title">
                      {{ subMenu.title }}
                      <div
                        v-if="subMenu.subMenu"
                        :class="{
                          'transform rotate-180': subMenu.activeDropdown
                        }"
                        class="menu__sub-icon"
                      >
                        <ChevronDownIcon />
                      </div>
                    </div>
                  </a>
                  <!-- BEGIN: Third Child -->
                  <transition @enter="enter" @leave="leave">
                    <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                      <li
                        v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu"
                        :key="lastSubMenuKey"
                      >
                        <a
                          :class="{ 'menu--active': lastSubMenu.active }"
                          class="menu"
                          href="javascript:"
                          @click="linkTo(lastSubMenu, router)"
                        >
                          <div class="menu__icon">
                            <ZapIcon />
                          </div>
                          <div class="menu__title">
                            {{ lastSubMenu.title }}
                          </div>
                        </a>
                      </li>
                    </ul>
                  </transition>
                  <!-- END: Third Child -->
                </li>
              </ul>
            </transition>
            <!-- END: Second Child -->
          </li>
        </template>
        <!-- END: First Child -->
      </ul>
    </transition>
  </div>
  <!-- END: Mobile Menu -->
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { helper as $h } from "@/utils/helper";
import {
  activeMobileMenu,
  toggleMobileMenu,
  linkTo,
  enter,
  leave
} from "./index";
import { nestedMenu } from "@/layouts/side-menu";

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const formattedMenu = ref([]);
    const mobileMenu = computed(() =>
      nestedMenu(store.state.sideMenu.menu, route)
    );

    const loggedUser = computed(() => store.state.auth.loggedUser);

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = filterMenu(mobileMenu);
      }
    );

    function filterMenu(menu) {
      let finalMenu = $h.toRaw(menu.value);
      if (loggedUser.value) {
        let userRoles = loggedUser.value.roles;
        if (
          !userRoles.includes("refund-approver") &&
          !userRoles.includes("reporter-admin")
        ) {
          finalMenu.splice(
            finalMenu.findIndex(e => e.pageName === "refunds"),
            1
          );
        }
        if (
          !userRoles.includes("disburser-admin") &&
          !userRoles.includes("reporter-admin")
        ) {
          finalMenu.splice(
            finalMenu.findIndex(e => e.pageName === "payouts"),
            1
          );
          if (
            !userRoles.includes("lms-admin") &&
            !userRoles.includes("reporter-admin") &&
            !userRoles.includes("ln-app-follow-up-officer") &&
            !userRoles.includes("lending-agent") &&
            !userRoles.includes("lending-manager") &&
            !userRoles.includes("lending-converter")
          ) {
            finalMenu.splice(
              finalMenu.findIndex(e => e.pageName === "unsigned-loans"),
              1
            );
            finalMenu.splice(
              finalMenu.findIndex(e => e.pageName === "contact-failed-loans"),
              1
            );
          }
        }
        if (!userRoles.includes("lms-admin")) {
          finalMenu.splice(
            finalMenu.findIndex(e => e.pageName === "bank-account-list"),
            1
          );
        }

        if (!userRoles.includes("content-moderator")) {
          finalMenu.splice(
            finalMenu.findIndex(e => e.pageName === "content-management"),
            1
          );
        }

        if (
          !userRoles.includes("lms-admin") &&
          !userRoles.includes("lending-agents-manager")
        ) {
          finalMenu.splice(
            finalMenu.findIndex(e => e.pageName === "admins"),
            1
          );
        }
        if (
          !userRoles.includes("lms-admin") &&
          !userRoles.includes("reporter-admin")
        ) {
          finalMenu.splice(
            finalMenu.findIndex(e => e.pageName === "side-menu-search"),
            1
          );
          finalMenu.splice(
            finalMenu.findIndex(e => e.pageName === "customers"),
            1
          );
          finalMenu.splice(
            finalMenu.findIndex(e => e.pageName === "loan-applications"),
            1
          );
        }
        if (!userRoles.includes("ln-app-follow-up-officer")) {
          finalMenu.splice(
            finalMenu.findIndex(e => e.pageName === "loan-follow-up-dashboard"),
            1
          );
        }
        if (!userRoles.includes("lending-manager")) {
          finalMenu.splice(
            finalMenu.findIndex(e => e.pageName === "delegation-dashboard"),
            1
          );

          finalMenu.forEach(finalMenu => {
            if (finalMenu.subMenu !== undefined) {
              finalMenu.subMenu = finalMenu.subMenu.filter(
                subMenu => subMenu.pageName !== "approval-lending-dashboard"
              );
            }
          });
        }
        if (!userRoles.includes("lending-agent")) {
          finalMenu.forEach(finalMenu => {
            if (finalMenu.subMenu !== undefined)
              finalMenu.subMenu = finalMenu.subMenu.filter(
                subMenu => subMenu.pageName !== "assessment-lending-dashboard"
              );
          });
        }
        if (!userRoles.includes("lending-converter")) {
          finalMenu.forEach(finalMenu => {
            if (finalMenu.subMenu !== undefined)
              finalMenu.subMenu = finalMenu.subMenu.filter(
                subMenu => subMenu.pageName !== "conversion-lending-dashboard"
              );
          });
        }
      }

      finalMenu.forEach(menu => {
        if (menu.subMenu !== undefined && menu.subMenu.length < 1)
          finalMenu = finalMenu.filter(fm => fm.pageName !== menu.pageName);
      });

      return finalMenu;
    }

    onMounted(() => {
      formattedMenu.value = filterMenu(mobileMenu);
    });

    return {
      activeMobileMenu,
      toggleMobileMenu,
      formattedMenu,
      router,
      linkTo,
      enter,
      leave
    };
  }
});
</script>
