import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";

const state = () => {
  return {
    applicationId: 0,
    type: null,
    errorMessage: "",
    validationErrors: [],
    isBusy: false
  };
};

const getters = {
  type: state => state.type,
  reason: state => state.reason,
  errorMessage: state => state.errorMessage,
  validationErrors: state => state.validationErrors,
  isBusy: state => state.isBusy
};

const mutations = {
  setApplicationId(state, applicationId) {
    state.applicationId = applicationId;
  },
  setType(state, type) {
    state.type = type;
  },
  setErrorMessage(state, message) {
    state.errorMessage = message;
  },
  setValidationErrors(state, validationErrors) {
    state.validationErrors = validationErrors;
  },
  clearErrors(state) {
    state.validationErrors = [];
    state.errorMessage = "";
  },
  setIsBusy(state, busy) {
    state.isBusy = busy;
  }
};

const actions = {
  manualVerification: async ({ dispatch, state, commit }) => {
    commit("clearErrors");
    const payload = {
      type: state.type
    };

    axiosWrapperService
      .axiosPatch(
        `/applications/${state.applicationId}/manualVerification`,
        payload
      )
      .then(response => {
        dispatch("skipChecks/getSkipChecks", {}, { root: true });
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: `Verification successful.`,
            type: "success"
          },
          { root: true }
        );
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      });
  },
  manualCentreLinkVerification: async ({ dispatch, state, commit }) => {
    commit("clearErrors");
    commit("setIsBusy", true);
    const payload = {
      type: state.type
    };

    axiosWrapperService
      .axiosPatch(
        `/applications/${state.applicationId}/manualCenterLinkVerification`,
        payload
      )
      .then(response => {
        dispatch("skipChecks/getCenterLinkCheck", {}, { root: true });
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: `Verification successful.`,
            type: "success"
          },
          { root: true }
        );
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("setIsBusy", false);
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
