<template>
  <!-- BEGIN: No record template-->
  <tbody>
    <tr>
      <td colspan="20">
        <div
          class="alert alert-secondary show"
          role="alert"
          :style="`background-color:${backgroundColor}`"
        >
          <div class="loader">
            {{ text }}
            <LoadingIcon icon="oval" color="green" class="w-4 h-4 ml-2" />
          </div>
        </div>
      </td>
    </tr>
  </tbody>
  <!-- END: No record template-->
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    text: {
      type: String,
      require: false,
      default: "Fetching Records! ..."
    },
    backgroundColor: {
      type: String,
      require: false,
      default: "white"
    }
  },
  setup() {}
});
</script>
<style scoped>
.loader {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>
