<template>
  <!-- BEGIN: Modal Content -->
  <div
    id="create-new-reschedule-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base mr-auto">
            Create New Reschedule
          </h2>
        </div>
        <!-- END: Modal Header -->
        <!-- BEGIN: Modal Body -->
        <div
          v-if="errorMessage !== ''"
          class="alert alert-danger-soft show flex items-center mb-2"
          role="alert"
        >
          <AlertOctagonIcon class="w-6 h-6 mr-2" />
          {{ errorMessage }}
        </div>
        <div class="modal-body grid grid-cols-12">
          <div class="col-span-12 mt-2">
            Debit Bank Account:
            <div class="mt-3">
              <input
                type="radio"
                class="align-middle"
                name="set-bank-account"
                id="set-bank-primary"
                v-model="isSecondaryBankSelected"
                @change="primaryBankSelected"
                :value="false"
              />
              <label class="pl-2" for="set-bank-primary"
                >Primary Income Account</label
              >
            </div>
            <div class="mt-3 h-12 col-span-12 grid grid-cols-2">
              <div class="pt-1">
                <input
                  type="radio"
                  class="align-middle"
                  name="set-bank-account"
                  id="set-bank-secondary"
                  v-model="isSecondaryBankSelected"
                  :value="true"
                />
                <label for="set-bank-secondary" class="pl-2"
                  >Secondary bank account</label
                >
              </div>

              <div v-if="isSecondaryBankSelected">
                <select
                  class="form-select w-11/12"
                  v-model="form.secondary_bank_account_id"
                >
                  <option value="null" selected> -- Select -- </option>
                  <option
                    v-for="bankAccount in debitableBankAccounts"
                    :value="bankAccount.id"
                    :key="bankAccount.id"
                    >{{
                      bankAccount.account_holder
                        ? bankAccount.account_holder
                        : "N/A"
                    }}
                    - {{ bankAccount.account_name }}
                    {{ bankAccount.bsb_number }}-{{
                      bankAccount.account_number
                    }}</option
                  >
                </select>
              </div>
            </div>
          </div>
          <div class="col-span-12 grid grid-cols-2">
            <div class="mt-2">
              <label class="form-label">Amount:</label>
              <div class="relative w-full mx-auto">
                <input
                  v-model="form.amount"
                  type="number"
                  class="form-control w-11/12"
                />
              </div>
              <div v-if="validationErrors.amount" class="p-1 text-red-600">
                {{ validationErrors.amount[0] }}
              </div>
            </div>
            <div class="mt-2">
              <label class="form-label"> Date:</label>
              <div class="relative w-full mx-auto">
                <input
                  type="date"
                  v-model="form.date"
                  class="form-control w-11/12"
                />
              </div>
              <div v-if="validationErrors.date" class="p-1 text-red-600">
                {{ validationErrors.date[0] }}
              </div>
            </div>
          </div>
          <div class="col-span-12 mt-2">
            <label class="form-label"> Notes:</label>
            <textarea
              v-model="form.notes"
              type="text"
              class="form-control"
              rows="5"
            >
            </textarea>
            <div v-if="validationErrors.notes" class="p-1 text-red-600">
              {{ validationErrors.notes[0] }}
            </div>
          </div>
        </div>
        <!-- END: Modal Body -->
        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer text-right">
          <button
            type="button"
            data-dismiss="modal"
            class="btn btn-dark-soft w-20 mr-1"
          >
            Cancel
          </button>
          <button
            v-if="!isBusy"
            type="button"
            class="btn btn-success w-40"
            @click="submitForm"
          >
            Submit
          </button>
          <button v-else class="btn btn-success w-40">
            Processing
            <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { defineComponent, computed, ref, onMounted } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  props: {
    customerId: {
      type: Number,
      require: true
    }
  },
  setup(props) {
    const store = useStore();

    const form = {
      amount: null,
      date: null,
      notes: null,
      secondary_bank_account_id: null
    };

    const isSecondaryBankSelected = ref(false);

    const debitableBankAccounts = computed(
      () => store.state.bankDetails.debitableBankAccounts
    );

    const isBusy = computed(
      () => store.state.paymentScheduleLoanActions.isBusy
    );

    const validationErrors = computed(
      () => store.state.paymentScheduleLoanActions.validationErrors
    );
    const errorMessage = computed(
      () => store.state.paymentScheduleLoanActions.errorMessage
    );

    function submitForm() {
      store.dispatch("paymentScheduleLoanActions/createReschedule", {
        form
      });
    }

    function fetchDebitableBankAccounts() {
      store.dispatch("bankDetails/getDebitableBankAccounts", {
        customerId: props.customerId
      });
    }
    onMounted(() => {
      fetchDebitableBankAccounts();
    });

    function primaryBankSelected() {
      form.secondary_bank_account_id = null;
    }

    return {
      form,
      submitForm,
      validationErrors,
      errorMessage,
      debitableBankAccounts,
      isSecondaryBankSelected,
      primaryBankSelected,
      isBusy
    };
  }
});
</script>

<style scoped></style>
