<template>
  <RefundTransactionForm />
  <div v-if="!isBusy" class="intro-y box p-5 mt-5">
    <div class="overflow-x-auto">
      <table class="table">
        <thead>
          <tr>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              #
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              PSID
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              Credit Amount
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              Debit Amount
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              Balance Amount
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              Type
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              Payment Method
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              Transaction Date
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              Refund Status
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              Notes
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="transaction in transactions"
            :key="transaction.id"
            class="border-b"
          >
            <td>
              {{ transaction.id }}
            </td>
            <td>
              {{ transaction.payment_schedule_id }}
            </td>
            <td>
              {{ $h.formatCurrency(transaction.credit_amount) }}
            </td>
            <td>
              {{ $h.formatCurrency(transaction.debit_amount) }}
            </td>
            <td>{{ $h.formatCurrency(transaction.balance_amount) }}</td>
            <td>
              {{ transaction.type }}
            </td>
            <td>
              {{ transaction.payment_method }}
            </td>
            <td>
              {{ $h.formatDate(transaction.transaction_date) }}
            </td>
            <td>
              <button
                v-if="parseFloat(transaction.debit_amount) > 0"
                :class="getRefundStatusClass(transaction.refund_status)"
                @click="refundStatusClicked(transaction)"
              >
                {{ getRefundStatusTitle(transaction.refund_status) }}
              </button>
            </td>
            <td>
              {{ transaction.notes }}
            </td>
          </tr>
          <tr class="font-bold bg-gray-400">
            <td>Total</td>
            <td></td>
            <td>{{ totalCredit }}</td>
            <td>{{ totalDebit }}</td>
            <td>{{ totalBalanceAmount }}</td>
            <td colspan="5"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <FetchingRecordDivTemplate v-else />
</template>

<script>
import { defineComponent } from "vue";
import { getTransactions } from "@/composables/components/transactions";
import FetchingRecordDivTemplate from "@/components/fetching-record-div-template/Main";
import RefundTransactionForm from "@/components/transactions/RefundTransactionForm";

export default defineComponent({
  components: { RefundTransactionForm, FetchingRecordDivTemplate },
  props: {
    applicationId: {
      type: Number,
      require: true,
      default: null
    }
  },
  setup(props) {
    const {
      isBusy,
      transactions,
      getRefundStatusTitle,
      getRefundStatusClass,
      refundStatusClicked,
      totalCredit,
      totalDebit,
      totalBalanceAmount
    } = getTransactions(props.applicationId);

    return {
      isBusy,
      transactions,
      getRefundStatusTitle,
      getRefundStatusClass,
      refundStatusClicked,
      totalCredit,
      totalDebit,
      totalBalanceAmount
    };
  }
});
</script>

<style scoped></style>
