<template>
  <!-- BEGIN: Dark Mode Switcher-->
  <div
    class="dark-mode-switcher cursor-pointer shadow-md fixed bottom-0 right-0 box dark:bg-dark-2 border rounded-full w-12 h-12 flex items-center justify-center z-50 mb-10 mr-96"
    :style="[isGlobalNotesDisplayed ? { 'margin-right': '1075px' } : '']"
    @click="switchMode"
  >
    <div class="mr-0 text-gray-700 dark:text-gray-300" v-if="sideMenuCollapsed">
      <MaximizeIcon class="w-4 h-4" />
    </div>
    <div class="mr-0 text-gray-700 dark:text-gray-300" v-else>
      <MinimizeIcon class="w-4 h-4" />
    </div>
  </div>
  <!-- END: Dark Mode Switcher-->
</template>

<script>
import { computed, defineComponent } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  setup() {
    const store = useStore();
    const sideMenuCollapsed = computed(
      () => store.state.main.sideMenuCollapsed
    );

    const isGlobalNotesDisplayed = computed(
      () => store.state.timelineNotesViewer.isGlobalNotesDisplayed
    );

    const switchMode = () => {
      store.commit("main/toggleSideMenuCollapse");
    };

    return {
      switchMode,
      sideMenuCollapsed,
      isGlobalNotesDisplayed
    };
  }
});
</script>
