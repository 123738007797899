<template>
  <!-- BEGIN: Modal Content -->
  <div
    id="record-paid-payment-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base mr-auto">
            Record Paid Payment
          </h2>
        </div>
        <!-- END: Modal Header -->
        <!-- BEGIN: Modal Body -->
        <div
          v-if="errorMessage !== ''"
          class="alert alert-danger-soft show flex items-center mb-2"
          role="alert"
        >
          <AlertOctagonIcon class="w-6 h-6 mr-2" />
          {{ errorMessage }}
        </div>
        <div class="modal-body grid grid-cols-12">
          <div class="col-span-12 grid grid-cols-2">
            <div class="mt-2">
              <label class="form-label">Amount:</label>
              <div class="relative w-full mx-auto">
                <input
                  v-model="form.amount"
                  type="number"
                  class="form-control w-11/12"
                />
              </div>
              <div v-if="validationErrors.amount" class="p-1 text-red-600">
                {{ validationErrors.amount[0] }}
              </div>
            </div>
          </div>
          <div class="col-span-12 grid grid-cols-2">
            <div class="mt-2">
              <label class="form-label">Payment Method:</label>
              <select
                v-model="form.paymentmethod"
                class="form-select w-11/12 pt-2"
              >
                <option value="" selected> -- Select -- </option>
                <option
                  v-for="paymentMethod in paymentMethods"
                  :value="paymentMethod.value"
                  :key="paymentMethod.value"
                  >{{ paymentMethod.name }}</option
                >
              </select>
              <div
                v-if="validationErrors.paymentmethod"
                class="p-1 text-red-600"
              >
                {{ validationErrors.paymentmethod[0] }}
              </div>
            </div>
            <div class="mt-2">
              <label class="form-label"> Date:</label>
              <div class="relative w-full mx-auto">
                <input
                  type="date"
                  v-model="form.date"
                  class="form-control w-11/12"
                />
              </div>
              <div v-if="validationErrors.date" class="p-1 text-red-600">
                {{ validationErrors.date[0] }}
              </div>
            </div>
          </div>

          <div class="col-span-12 mt-2">
            <label class="form-label"> Notes:</label>
            <textarea
              v-model="form.notes"
              type="text"
              class="form-control"
              rows="5"
            >
            </textarea>
            <div v-if="validationErrors.notes" class="p-1 text-red-600">
              {{ validationErrors.notes[0] }}
            </div>
          </div>
        </div>
        <!-- END: Modal Body -->
        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer text-right">
          <button
            type="button"
            data-dismiss="modal"
            class="btn btn-dark-soft w-20 mr-1"
          >
            Cancel
          </button>
          <button
            v-if="!isBusy"
            type="button"
            class="btn btn-success w-40"
            @click="submitForm"
          >
            Submit
          </button>
          <button v-else class="btn btn-success w-40">
            Processing
            <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  setup() {
    const store = useStore();

    const form = {
      amount: null,
      date: null,
      notes: null,
      paymentmethod: ""
    };

    const isBusy = computed(
      () => store.state.paymentScheduleLoanActions.isBusy
    );

    const validationErrors = computed(
      () => store.state.paymentScheduleLoanActions.validationErrors
    );
    const errorMessage = computed(
      () => store.state.paymentScheduleLoanActions.errorMessage
    );

    function submitForm() {
      store.dispatch("paymentScheduleLoanActions/recordPaidPayment", {
        form
      });
    }

    const paymentMethods = [
      {
        value: "Fiona",
        name: "Fiona"
      },
      {
        value: "EziDebit",
        name: "EziDebit"
      },
      {
        value: "Transfer",
        name: "Transfer"
      },
      {
        value: "CentrePay",
        name: "CentrePay"
      },
      {
        value: "BPay",
        name: "BPay"
      },
      {
        value: "PayPal",
        name: "PayPal"
      },
      {
        value: "ANZ Merchant",
        name: "ANZ Merchant"
      },
      {
        value: "PayGate",
        name: "PayGate"
      },
      {
        value: "Split",
        name: "Split"
      },
      {
        value: "Card",
        name: "Card"
      }
    ];

    return {
      form,
      paymentMethods,
      submitForm,
      validationErrors,
      errorMessage,
      isBusy
    };
  }
});
</script>

<style scoped></style>
