const DocReqStructure = {
  qna: {
    name: "Question and Answer",
    slug: "qna",
    subType: {
      elementType: "options",
      title: "Question"
    },
    items: [
      {
        formElement: {
          type: "option",
          label: "Rent",
          val: "Rent"
        },
        description: {
          required: true,
          title: "Question Details",
          hints: {
            var: undefined,
            lite: undefined,
            full:
              "Please confirm the amount of rent you pay per fortnight and if anyone helps make contributions"
          },
          default:
            "Please confirm the amount of rent you pay per fortnight and if anyone helps make contributions"
        }
      },
      {
        formElement: {
          type: "option",
          label: "Credit Card",
          val: "Credit Card"
        },
        description: {
          required: true,
          title: "Question Details",
          hints: {
            var: undefined,
            lite: undefined,
            full: "Please confirm your credit card limit with [provider XXX]."
          },
          default: "Please confirm your credit card limit with [provider XXX]."
        }
      },
      {
        formElement: {
          type: "option",
          label: "Rent declared",
          val: "Rent declared"
        },
        description: {
          required: true,
          title: "Question Details",
          hints: {
            var: undefined,
            lite: undefined,
            full:
              "We note that you have declared [$XXX] amount for rent. Please confirm if you pay this weekly, fortnightly or monthly."
          },
          default:
            "We note that you have declared [$XXX] amount for rent. Please confirm if you pay this weekly, fortnightly or monthly."
        }
      },
      {
        formElement: {
          type: "option",
          label: "Additional Income",
          val: "Additional Income"
        },
        description: {
          required: true,
          title: "Question Details",
          hints: {
            var: undefined,
            lite: undefined,
            full:
              "We note that you are receiving additional income from the transaction named [XXX]. Could you please confirm if we can consider this as additional income and if it will continue for the duration of the loan (if approved)?"
          },
          default:
            "We note that you are receiving additional income from the transaction named [XXX]. Could you please confirm if we can consider this as additional income and if it will continue for the duration of the loan (if approved)?"
        }
      },
      {
        formElement: {
          type: "option",
          label: "Custom",
          val: "Custom"
        },
        description: {
          required: true,
          title: "Question Details",
          hints: {
            var: undefined,
            lite: undefined,
            full: ""
          },
          default: " "
        }
      }
    ]
  },
  recent_payslip: {
    name: "Recent Payslip",
    slug: "recent_payslip",
    subType: {
      elementType: "options",
      title: "Options"
    },
    employer: null,
    items: [
      {
        employer: "",
        get formElement() {
          const parent = this;
          return {
            type: "option",
            label: "Two most recent",
            set val(text) {
              parent.employer = text;
            },
            get val() {
              return parent.employer;
            }
          };
        },
        get description() {
          const parent = this;
          return {
            required: false,
            title: "Comments",
            get hints() {
              return {
                var: parent.employer,
                lite: `From your employer ${parent.employer}`,
                full: `Upload your two most recent payslips from your employer ${parent.employer}.`
              };
            },
            default: ""
          };
        }
      },
      {
        employer: "",
        get formElement() {
          const parent = this;
          return {
            type: "option",
            label: "Most recent",
            set val(text) {
              parent.employer = text;
            },
            get val() {
              return parent.employer;
            }
          };
        },
        get description() {
          const parent = this;
          return {
            required: false,
            title: "Comments",
            get hints() {
              return {
                var: parent.employer,
                lite: `From your employer ${parent.employer}`,
                full: `Upload your most recent payslips from your employer ${parent.employer}.`
              };
            },
            default: ""
          };
        }
      }
    ]
  },
  centrelink_income_statement: {
    name: "Centrelink Income Statement",
    slug: "centrelink_income_statement",
    subType: {
      elementType: "textbox",
      title: "Comments"
    },
    items: [
      {
        formElement: undefined,
        description: {
          required: false,
          title: "Comments",
          hints: {
            var: undefined,
            lite:
              "Please log into your MyGov and provide your most recent Centrelink Income Statement that shows your future payments and deductions",
            full:
              "Please log into your MyGov and provide your most recent Centrelink Income Statement that shows your future payments and deductions"
          },
          default: " "
        }
      }
    ]
  },
  statement_of_accounts: {
    name: "Loan Transaction History",
    slug: "statement_of_accounts",
    subType: {
      elementType: "textbox",
      title: "Lender(s)"
    },
    items: [
      {
        log: "",
        get formElement() {
          const parent = this;
          return {
            type: "textbox",
            label: "Lender(s)",
            set val(text) {
              parent.log = text;
            },
            get val() {
              return parent.log;
            }
          };
        },
        get description() {
          const parent = this;
          return {
            required: false,
            title: "Comments",
            get hints() {
              return {
                var: parent.log,
                lite: (function() {
                  let loans = parent.log
                    .split(",")
                    .map(loan => `${loan}`)
                    .join(", ")
                    .replace(/,([^,]+)$/, " and$1");
                  return `Upload your statement(s) with ${loans} that shows your payment history and balance(s) outstanding.`;
                })(),
                full: (function() {
                  let loans = parent.log
                    .split(",")
                    .map(loan => `${loan}`)
                    .join(", ")
                    .replace(/,([^,]+)$/, " and$1");
                  return `Upload your statement(s) with ${loans} that shows your payment history and balance(s) outstanding.`;
                })()
              };
            },
            default: " "
          };
        }
      }
    ]
  },
  loan_contract: {
    name: "Loan Contract",
    slug: "loan_contract",
    subType: {
      elementType: "textbox",
      title: "Lender(s)"
    },
    items: [
      {
        log: "",
        get formElement() {
          const parent = this;
          return {
            type: "textbox",
            label: "Lender(s)",
            set val(text) {
              parent.log = text;
            },
            get val() {
              return parent.log;
            }
          };
        },
        get description() {
          const parent = this;
          return {
            required: false,
            title: "Comments",
            get hints() {
              return {
                var: parent.log,
                lite: undefined,
                full: `Upload screenshot or photo of your Loan Contract for your  ${parent.log
                  .split(",")
                  .map(lender => lender)
                  .join(", ")
                  .replace(/,([^,]+)$/, " and$1")} loans(s).`
              };
            },
            default: " "
          };
        }
      }
    ]
  },
  loan_repayment_schedule: {
    name: "Loan Repayment Schedule",
    slug: "loan_repayment_schedule",
    subType: {
      elementType: "textbox",
      title: "Lender(s)"
    },
    items: [
      {
        log: "",
        get formElement() {
          const parent = this;
          return {
            type: "textbox",
            label: "Lender(s)",
            set val(text) {
              parent.log = text;
            },
            get val() {
              return parent.log;
            }
          };
        },
        get description() {
          const parent = this;
          return {
            required: false,
            title: "Comments",
            get hints() {
              return {
                var: parent.log,
                lite: `Upload a screenshot or photo of your future Loan Repayment Schedule for ${parent.log
                  .split(",")
                  .map(lender => lender)
                  .join(", ")
                  .replace(
                    /,([^,]+)$/,
                    " and$1"
                  )} showing your next fortnight (or 15/30/60 days)  amount.`,
                full: `Upload a screenshot or photo of your future Loan Repayment Schedule for ${parent.log
                  .split(",")
                  .map(lender => lender)
                  .join(", ")
                  .replace(
                    /,([^,]+)$/,
                    " and$1"
                  )} showing your next fortnight (or 15/30/60 days)  amount.`
              };
            },
            default: " "
          };
        }
      }
    ]
  },
  id_verification: {
    name: "ID Verification",
    slug: "id_verification",
    subType: {
      elementType: "textbox",
      title: "Comments"
    },
    items: [
      {
        formElement: undefined,
        description: {
          required: false,
          title: "Comments",
          hints: {
            var: undefined,
            lite:
              "Acceptable documents include: Passport, Australian Driver Licence, Medicare Card",
            full:
              "Upload a photo or scanned copy of your Passport, Australian Driver Licence or Medicare Card to verify your identity"
          },
          default: " "
        }
      }
    ]
  },
  license: {
    name: "Driver Licence (Front and Back)",
    slug: "license",
    subType: {
      elementType: "textbox",
      title: "Comments"
    },
    items: [
      {
        formElement: undefined,
        description: {
          required: false,
          title: "Comments",
          hints: {
            var: undefined,
            lite: undefined,
            full: "A photo of your Driver Licence (front and back)"
          },
          default: " "
        }
      }
    ]
  },
  // extra_income_request: {
  //   name: "Extra Income Request",
  //   slug: "extra_income_request",
  // subType: "textbox",
  //   items: [
  //     {
  //       log: {},
  //       get formElement() {
  //         return {
  //           type: "textbox",
  //           label: "Amount(Fortnightly)",
  //           set val(text) {
  //             this.log["amount"] = text;
  //           },
  //           get val() {
  //             return this.log["amount"];
  //           }
  //         };
  //       },
  //       get description() {
  //         const parent = this;
  //         return {
  //           required: false,
  //           title: "Comments",
  //           get hints() {
  //             return {
  //               var: parent.log["amount"],
  //               lite: `Extra Income Request requested for $${parent.log["amount"]} fortnightly.`,
  //               full: `Provide proof of your extra income.`
  //             };
  //           },
  //           default: ""
  //         };
  //       }
  //     }
  //   ]
  // },
  loan_closure: {
    name: "Loan Closure",
    slug: "loan_closure",
    realTimeFetch: true,
    subType: {
      elementType: "textbox",
      title: "Description"
    },
    items: [
      {
        formElement: undefined,
        description: {
          required: true,
          title: "Description",
          hints: {
            var: undefined,
            lite: `Loans [var]`,
            full: `Please provide a copy of a Loan Finalisation email / letter OR a screenshot of your transaction history for loans that you have closed.`
          },
          default: " "
        }
      }
    ]
  },
  other_document: {
    name: "Other Document",
    slug: "other_document",
    subType: {
      elementType: "textbox",
      title: "Document"
    },
    items: [
      {
        log: "",
        get formElement() {
          const parent = this;
          return {
            type: "textbox",
            label: "Document",
            set val(text) {
              parent.log = text;
            },
            get val() {
              return parent.log;
            }
          };
        },
        get description() {
          const parent = this;
          return {
            required: false,
            title: "Comments",
            get hints() {
              return {
                var: parent.log,
                lite: undefined,
                full: `Your ${parent.log}`
              };
            },
            default: " "
          };
        }
      }
    ]
  }
};
export default DocReqStructure;
