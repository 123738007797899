import axios from "axios";
import { formatErrorMessage } from "../utils/messageFormatter";
import { axiosWrapperService } from "../services/auth/axios-wrapper-service";
import router from "../router";
import invalidateToken from "@/services/auth/invalidate-token-service";
import identifyUser from "@/services/user-identify-service";

const state = () => {
  return {
    baseApiUrl: process.env.VUE_APP_BASE_URL,
    loggedUser:
      localStorage.getItem("user") !== null
        ? JSON.parse(localStorage.getItem("user"))
        : "",
    accessToken: localStorage.getItem("access_token") || "",
    errorMessage: "",
    alertMessage: "",
    successMessage: "",
    busy: false
  };
};

// getters
const getters = {
  baseApiUrl: state => state.baseApiUrl,
  loggedUser: state => state.loggedUser,
  accessToken: state => state.accessToken
};

// actions
const actions = {
  loginUser: async ({ commit }, { email, password }) => {
    commit("setBusy");
    commit("clearError");
    axiosWrapperService
      .axiosPost("/login", { email, password })
      .then(response => {
        const user = response.data.user;
        const accessToken = response.data.access_token;
        localStorage.setItem("access_token", accessToken);
        localStorage.setItem("user", JSON.stringify(user));
        identifyUser(user);
        commit("LOGIN_USER", { user, accessToken });
        router.push("/");
      })
      .catch(error => {
        commit("setError", formatErrorMessage(error, true));
      })
      .finally(() => {
        commit("clearBusy");
      });
  },
  ssoLogin: async ({ commit }, { accessToken }) => {
    localStorage.setItem("access_token", accessToken);
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

    commit("clearError");
    axiosWrapperService
      .axiosGet(`/getCurrentAdmin`)
      .then(response => {
        const user = response.data.data;
        localStorage.setItem("user", JSON.stringify(user));
        identifyUser(user);
        commit("LOGIN_USER", { user, accessToken });
        router.push("/");
      })
      .catch(error => {
        commit("setError", formatErrorMessage(error, true));
      });
  },
  ssoLoginUsingToken: async ({ commit }, { token }) => {
    commit("clearError");

    axiosWrapperService
      .axiosPost(`/sso/ssoLoginUsingToken`, { token })
      .then(response => {
        const user = response.data.user;
        const accessToken = response.data.access_token;
        localStorage.setItem("access_token", accessToken);
        localStorage.setItem("user", JSON.stringify(user));
        identifyUser(user);
        commit("LOGIN_USER", { user, accessToken });
        router.push("/");
      })
      .catch(error => {
        commit("setError", formatErrorMessage(error, true));
      })
      .finally(() => {
        commit("clearBusy");
      });
  },
  logoutUser: ({ dispatch, commit }) => {
    commit("setBusy");
    commit("clearError");
    axiosWrapperService
      .axiosPost("/logout")
      .then(() => {
        invalidateToken();
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: "Successfully Logged out!", type: "success" },
          { root: true }
        );
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("clearBusy");
      });
  },
  requestPasswordReset: async ({ commit, dispatch }, { email }) => {
    commit("setBusy");
    commit("clearError");
    axiosWrapperService
      .axiosPost("/requestPasswordReset", { email })
      .then(response => {
        commit("setSuccessMessage", response.data.message);
      })
      .catch(error => {
        commit("setError", formatErrorMessage(error, true));
      })
      .finally(() => {
        commit("clearBusy");
      });
  },
  resetPassword: async ({ commit, dispatch }, form) => {
    commit("setBusy");
    commit("clearError");
    axiosWrapperService
      .axiosPost("/resetPassword", form)
      .then(response => {
        commit("setSuccessMessage", response.data.message);
      })
      .catch(error => {
        commit("setError", formatErrorMessage(error, true));
      })
      .finally(() => {
        commit("clearBusy");
      });
  }
};

// mutations
const mutations = {
  LOGIN_USER(state, { user, accessToken }) {
    state.loggedUser = user;
    state.accessToken = accessToken;
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  },
  setError(state, errorMessage) {
    state.errorMessage = errorMessage;
  },
  setSuccessMessage(state, successMessage) {
    state.successMessage = successMessage;
  },
  clearError(state) {
    state.errorMessage = "";
  },
  setBusy(state) {
    state.busy = true;
  },
  clearBusy(state) {
    state.busy = false;
  },
  clearToken(state) {
    state.loggedUser = null;
    state.accessToken = "";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
