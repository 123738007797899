const stages = {
  "lending-agent": ["Assessor"],
  "lending-manager": ["Approver", "Assessor"],
  "lending-converter": ["Converter"],
  "lending-agent-q": ["Assessor"],
  "lending-manager-q": ["Assessor", "Approver"],
  "lending-converter-q": ["Converter"]
};

const bucket = {
  "lending-agent": "Assessor",
  "lending-manager": "Approver",
  "lending-converter": "Converter"
};

const stages_roles = {
  assessor: "lending-agent",
  approver: "lending-manager",
  converter: "lending-converter"
};

export function getLendingAssessmentAccessType(loanAssessmentRefValue) {
  if (
    (loanAssessmentRefValue.agent_id < 1 &&
      loanAssessmentRefValue.manager_id < 1 &&
      loanAssessmentRefValue.converter_id < 1) ||
    (loanAssessmentRefValue.agent_id > 0 &&
      ["New", "Assigned", "In Progress"].includes(
        loanAssessmentRefValue.status
      ))
  ) {
    return "lending-agent";
  } else if (
    loanAssessmentRefValue.manager_id > 0 &&
    ["Approved", "Pre Conversion Check", "Pre Conversion Finalized"].includes(
      loanAssessmentRefValue.status
    )
  ) {
    return "lending-converter";
  } else if (
    loanAssessmentRefValue.agent_id > 0 &&
    ["Completed", "In Review", "Request Decline", "In Decline Review"].includes(
      loanAssessmentRefValue.status
    )
  ) {
    return "lending-manager";
  }
  return "";
}

export function getLendingAssessmentAccessBucket(loanAssessmentRefValue) {
  let access_level = getLendingAssessmentAccessType(loanAssessmentRefValue);
  return (access_level === "lending-agent" &&
    loanAssessmentRefValue.agent_id < 1) ||
    (access_level === "lending-manager" &&
      loanAssessmentRefValue.manager_id < 1) ||
    (access_level === "lending-converter" &&
      loanAssessmentRefValue.converter_id < 1)
    ? `${bucket[access_level]} Queue`
    : bucket[access_level];
}

export function getSelfAssignableRoleType(loanAssessmentRefValue) {
  if (
    ["New"].includes(loanAssessmentRefValue.status) &&
    loanAssessmentRefValue.agent_id < 1
  ) {
    return "lending-agent";
  } else if (
    ["Approved"].includes(loanAssessmentRefValue.status) &&
    loanAssessmentRefValue.converter_id < 1
  ) {
    return "lending-converter";
  } else if (
    ["Completed"].includes(loanAssessmentRefValue.status) &&
    loanAssessmentRefValue.manager_id < 1
  ) {
    return "lending-manager";
  }
  return "";
}

export function getLoanAssessmentStage(loanAssessmentRefValue) {
  let access_level = getLendingAssessmentAccessType(loanAssessmentRefValue);
  if (access_level in stages) {
    if (
      (access_level === "lending-agent" &&
        loanAssessmentRefValue.agent_id < 1) ||
      (access_level === "lending-manager" &&
        loanAssessmentRefValue.manager_id < 1) ||
      (access_level === "lending-converter" &&
        loanAssessmentRefValue.converter_id < 1)
    ) {
      return stages[`${access_level}-q`];
    }
    return stages[access_level];
  } else {
    return stages.lending_agent;
  }
}

export function getRoleAccordingToStage(stage) {
  return stages_roles[stage.toLowerCase()];
}

export function getCurrentAssignedUserId(loanAssessmentRefValue) {
  let collection = {
    "lending-agent": loanAssessmentRefValue.agent_id,
    "lending-manager": loanAssessmentRefValue.manager_id,
    "lending-converter": loanAssessmentRefValue.converter_id
  };
  return collection[getLendingAssessmentAccessType(loanAssessmentRefValue)];
}

export function getLendingAssessmentModifierRole(roles) {
  let adminRole = "";
  if (roles.includes("lending-converter")) {
    adminRole = bucket["lending-converter"]
  } else if (roles.includes("lending-manager")) {
    adminRole = bucket["lending-manager"]
  } else if (roles.includes("lending-agent")) {
    adminRole = bucket["lending-agent"]
  }

  return adminRole;
}
