import { formatErrorMessage } from "../utils/messageFormatter";
import { axiosWrapperService } from "../services/auth/axios-wrapper-service";
import { genTabulatorJsFormatQueryStringFromParamsObject } from "../utils/QueryStringGenerator/tabulatorJsFormatQueryStringGenerator";

const state = () => {
  return {
    loanFollowUpsActivityStats: [],
    recentFollowUps: [],
    loanFollowUpsBucketList: [],
    loanFollowUpsQueryParam: "",
    busyLoanFollowUpsListPage: false,
    loanFollowUpsBucketListUpdateCounter: 0,
    reqForFollowUpDetailsNavigationCounter: 0,
    loanFollowUpsAlertMessage: "",
    loanFollowUpsErrorMessage: "",
    loanFollowUpsSuccessMessage: ""
  };
};

// getters
const getters = {
  loanFollowUpsActivityStats: state => state.loanFollowUpsActivityStats,
  recentFollowUps: state => state.recentFollowUps,
  loanFollowUpsBucketList: state => state.loanFollowUpsBucketList,
  loanFollowUpsQueryParam: state => state.loanFollowUpsQueryParam,
  busyLoanFollowUpsListPage: state => state.busyLoanFollowUpsListPage,
  loanFollowUpsBucketListUpdateCounter: state =>
    state.loanFollowUpsBucketListUpdateCounter,
  reqForFollowUpDetailsNavigationCounter: state =>
    state.reqForFollowUpDetailsNavigationCounter,
  loanFollowUpsAlertMessage: state => state.loanFollowUpsAlertMessage,
  loanFollowUpsErrorMessage: state => state.loanFollowUpsErrorMessage,
  loanFollowUpsSuccessMessage: state => state.loanFollowUpsSuccessMessage
};

// actions
const actions = {
  getFollowUpActivityStatsOfAuthLender: async ({ commit }) => {
    try {
      commit("clearError");
      axiosWrapperService
        .axiosGet("/getFollowUpActivityStatsOfAuthLender")
        .then(response => {
          commit("setLoanFollowUpsActivityStats", response.data.data);
        });
    } catch (e) {
      commit("setError", "Failed to get the list.");
    } finally {
      commit("resetBusy");
    }
  },
  getRecentLoanFollowUpsOfAuthAgent: async ({ commit }) => {
    try {
      commit("clearError");
      axiosWrapperService
        .axiosGet("/getRecentLoanFollowUpsOfAuthAgent")
        .then(response => {
          commit("setRecentFollowUps", response.data.data);
        });
    } catch (e) {
      commit("setError", "Failed to get the list.");
    } finally {
      commit("resetBusy");
    }
  },
  getLoanFollowUpsList: async ({ commit }) => {
    try {
      commit("clearError");
      axiosWrapperService
        .axiosGet("/getLoanApplicationFollowUpsOfAssignee")
        .then(response => {
          commit("setLoanFollowUpsList", response.data.data);
        });
    } catch (e) {
      commit("setError", "Failed to get the list.");
    } finally {
      commit("resetBusy");
    }
  },
  selfAssignAndGetLoanFollowUps: async ({ commit }, followUpTypeSlug) => {
    try {
      commit("clearError");
      axiosWrapperService
        .axiosPost("/selfAssignAndGetLoanFollowUps", {
          follow_up_type_slug: followUpTypeSlug
        })
        .then(() => {
          commit("incrementUpdateCount");
        })
        .catch(error => {
          commit("setError", formatErrorMessage(error, true));
        });
    } catch (e) {
      commit("setError", "Failed to get the list.");
    } finally {
      commit("resetBusy");
    }
  },
  startFollowUp: async ({ commit }, lnFollowUpUuid) => {
    try {
      commit("clearError");
      axiosWrapperService
        .axiosPatch("/setLoanFollowUpStatusInProgress/" + lnFollowUpUuid)
        .then(() => {
          commit("incrementUpdateCount");
          commit("incrementReqForFollowUpDetailsNavigationCounter");
        });
    } catch (e) {
      commit("setError", "Failed to get the list.");
    } finally {
      commit("resetBusy");
    }
  }
};

// mutations
const mutations = {
  setLoanFollowUpsQueryParam(state, { params, statusParam }) {
    let queryString = statusParam !== "" ? statusParam + "&" : "";
    queryString += encodeURI(
      genTabulatorJsFormatQueryStringFromParamsObject(params)
    );
    state.loanFollowUpsQueryParam = queryString;
  },
  setLoanFollowUpsList(state, list) {
    state.loanFollowUpsBucketList = list;
  },
  setLoanFollowUpsActivityStats(state, loanFollowUpsActivityStats) {
    state.loanFollowUpsActivityStats = loanFollowUpsActivityStats;
  },
  setRecentFollowUps(state, recentFollowUps) {
    state.recentFollowUps = recentFollowUps;
  },
  setLoanFollowUpsSuccessMessage(state, loanFollowUpsSuccessMessage) {
    state.loanFollowUpsSuccessMessage = loanFollowUpsSuccessMessage;
  },
  setLoanFollowUpsAlertMessage(state, loanFollowUpsAlertMessage) {
    state.loanFollowUpsAlertMessage = loanFollowUpsAlertMessage;
  },
  setError(state, loanFollowUpsErrorMessage) {
    state.loanFollowUpsErrorMessage = loanFollowUpsErrorMessage;
  },
  incrementUpdateCount(state) {
    state.loanFollowUpsBucketListUpdateCounter++;
  },
  incrementReqForFollowUpDetailsNavigationCounter(state) {
    state.reqForFollowUpDetailsNavigationCounter++;
  },
  clearError(state) {
    state.loanFollowUpsErrorMessage = "";
  },
  clearMessages(state) {
    state.loanFollowUpsErrorMessage = "";
    state.loanFollowUpsAlertMessage = "";
    state.loanFollowUpsSuccessMessage = "";
    state.loanFollowUpsBucketListUpdateCounter++;
  },
  clearAlertMessages(state) {
    state.loanFollowUpsAlertMessage = "";
  },
  setBusy(state) {
    state.busyLoanFollowUpsListPage = true;
  },
  resetBusy(state) {
    state.busyLoanFollowUpsListPage = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
