import { createRouter, createWebHistory } from "vue-router";
import SideMenu from "../layouts/side-menu/Main.vue";
import SimpleMenu from "../layouts/simple-menu/Main.vue";
import TopMenu from "../layouts/top-menu/Main.vue";
import DashboardOverview1 from "../views/dashboard-overview-1/Main.vue";
import loanAssessmentDetail from "../views/loan-assessment-detail/Main.vue";
import loanFollowUpDetail from "../views/loan-follow-up-detail/Main.vue";
import LendingDashboard from "../views/lending-dashboard/Main.vue";
import LoanClosureReviewDashboard from "../views/ln-closure-review-dashboard/Main.vue";
import loanFollowUpDashboard from "../views/loan-follow-up-dashboard/Main.vue";
import LnAppClosureReviewDelegationBoard from "../views/lending-closure-review-delegation-board/Main.vue";
import LnAppAssessorDelegationBoard from "../views/lending-assessor-delegation-board/Main.vue";
import LnAppApproverDelegationBoard from "../views/lending-approver-delegation-board/Main.vue";
import LnAppConverterDelegationBoard from "../views/lending-converter-delegation-board/Main.vue";
import CustFollowUpDelegationBoard from "../views/customer-follow-up-delegation-board/Main.vue";
import Payout from "../views/payouts/Main.vue";
import Refund from "../views/refunds/Main.vue";
import PayerBankAccountList from "../views/payer-bank-acc-list/Main.vue";
import CrudForm from "../views/crud-form/Main.vue";

import UsersLayout2 from "../views/users-layout-2/Main.vue";
import UsersLayout3 from "../views/users-layout-3/Main.vue";

import Customers from "../views/customers/Main";
import CustomerDetails from "../views/customer/Main";
import LoanApplications from "../views/loan-applications/Main.vue";
import UnsignedLoans from "../views/unsigned-loans/Main";
import ContactFailedLoans from "../views/contact-failed-loans/Main";

import LoanApplicationDetails from "../views/loan-application-details/Main.vue";
import LoanDtsRouter from "../views/ln-app-dts-router/Main.vue";
import Search from "../views/search/Main.vue";

import AdminForm from "../views/admin-form/Main.vue";
import AdminDataList from "../views/admin-data-list/Main.vue";
import EditAdminForm from "../views/edit-admin/Main.vue";

import ContentManagementList from "../views/content-management/Main.vue";
import ContentManagementForm from "../views/content-management/Form";

import Login from "../views/login/Main.vue";
import SSOLogin from "../views/sso-login/Main.vue";
import RequestPasswordReset from "../views/request-password-reset/Main.vue";
import ResetPassword from "../views/reset-password/Main.vue";

import Register from "../views/register/Main.vue";
import ErrorPage from "../views/error-page/Main.vue";

import { resetIsGlobalNotesDisplayed } from "@/composables/utils/globalTimeLineHelpers";
import StaticPage from "@/views/content-management/content-type/StaticPage.vue";
import Component from "@/views/content-management/content-type/Component.vue";

import { useStore } from "@/store";

const routes = [
  {
    path: "/",
    component: SideMenu,
    children: [
      {
        path: "/",
        name: "side-menu-dashboard-overview-1",
        component: DashboardOverview1,
        meta: {
          breadcrumbs: [
            {
              title: "Dashboard",
              path: "#"
            }
          ]
        }
      },
      {
        path: "/search",
        name: "side-menu-search",
        component: Search,
        meta: {
          breadcrumbs: [
            {
              title: "Search",
              path: "#"
            }
          ]
        }
      },
      {
        path: "payouts",
        name: "payouts",
        component: Payout,
        meta: {
          breadcrumbs: [
            {
              title: "Payouts",
              path: "#"
            }
          ]
        }
      },
      {
        path: "new-payouts",
        name: "new-payouts",
        component: Payout,
        meta: {
          breadcrumbs: [
            {
              title: "New Payouts",
              path: "#"
            }
          ]
        }
      },
      {
        path: "disbursed-payouts",
        name: "disbursed-payouts",
        component: Payout,
        meta: {
          breadcrumbs: [
            {
              title: "Disbursed Payouts",
              path: "#"
            }
          ]
        }
      },
      {
        path: "pending-payouts",
        name: "pending-payouts",
        component: Payout,
        meta: {
          breadcrumbs: [
            {
              title: "Pending Payouts",
              path: "#"
            }
          ]
        }
      },
      {
        path: "pre-check-failed",
        name: "pre-check-failed",
        component: Payout,
        meta: {
          breadcrumbs: [
            {
              title: "Pre Check Failed",
              path: "#"
            }
          ]
        }
      },
      {
        path: "failed-payouts",
        name: "failed-payouts",
        component: Payout,
        meta: {
          breadcrumbs: [
            {
              title: "Failed Payouts",
              path: "#"
            }
          ]
        }
      },
      {
        path: "refunds",
        name: "refunds",
        component: Refund,
        meta: {
          breadcrumbs: [
            {
              title: "Refunds",
              path: "#"
            }
          ]
        }
      },
      {
        path: "new-refunds",
        name: "new-refunds",
        component: Refund,
        meta: {
          breadcrumbs: [
            {
              title: "New Refunds",
              path: "#"
            }
          ]
        }
      },
      {
        path: "completed-refunds",
        name: "completed-refunds",
        component: Refund,
        meta: {
          breadcrumbs: [
            {
              title: "Completed Refunds",
              path: "#"
            }
          ]
        }
      },
      {
        path: "rejected-refunds",
        name: "rejected-refunds",
        component: Refund,
        meta: {
          breadcrumbs: [
            {
              title: "Rejected Refunds",
              path: "#"
            }
          ]
        }
      },
      {
        path: "failed-refunds",
        name: "failed-refunds",
        component: Refund,
        meta: {
          breadcrumbs: [
            {
              title: "Failed Refunds",
              path: "#"
            }
          ]
        }
      },
      {
        path: "lending-dashboard/lending-agent",
        name: "assessment-lending-dashboard",
        component: LendingDashboard,
        meta: {
          breadcrumbs: [
            {
              title: "Assessment Dashboard",
              path: "#"
            }
          ]
        }
      },
      {
        path: "lending-dashboard/lending-manager",
        name: "approval-lending-dashboard",
        component: LendingDashboard,
        meta: {
          breadcrumbs: [
            {
              title: "Approval Dashboard",
              path: "#"
            }
          ]
        }
      },
      {
        path: "/lending-dashboard/lending-converter",
        name: "conversion-lending-dashboard",
        component: LendingDashboard,
        meta: {
          breadcrumbs: [
            {
              title: "Conversion Dashboard",
              path: "#"
            }
          ]
        }
      },
      {
        path: "/lending-dashboard/ln-closure-reviewer",
        name: "closure-review-dashboard",
        component: LoanClosureReviewDashboard,
        meta: {
          breadcrumbs: [
            {
              title: "Closure Review Dashboard",
              path: "#"
            }
          ]
        }
      },
      {
        path: "delegation-dashboard/ln-follow-up-delegation-brd",
        name: "ln-follow-up-delegation-brd",
        component: CustFollowUpDelegationBoard,
        meta: {
          breadcrumbs: [
            {
              title: "Follow Up Delegation Board",
              path: "#"
            }
          ]
        }
      },
      {
        path: "delegation-dashboard/ln-app-assessor-delegation-board",
        name: "ln-app-assessor-delegation-board",
        component: LnAppAssessorDelegationBoard,
        meta: {
          breadcrumbs: [
            {
              title: "Assessor Delegation Board",
              path: "#"
            }
          ]
        }
      },
      {
        path: "delegation-dashboard/ln-app-approver-delegation-board",
        name: "ln-app-approver-delegation-board",
        component: LnAppApproverDelegationBoard,
        meta: {
          breadcrumbs: [
            {
              title: "Approver Delegation Board",
              path: "#"
            }
          ]
        }
      },
      {
        path: "delegation-dashboard/ln-app-converter-delegation-board",
        name: "ln-app-converter-delegation-board",
        component: LnAppConverterDelegationBoard,
        meta: {
          breadcrumbs: [
            {
              title: "Converter Delegation Board",
              path: "#"
            }
          ]
        }
      },
      {
        path: "delegation-dashboard/ln-app-closure-review-delegation-board",
        name: "ln-app-closure-review-delegation-board",
        component: LnAppClosureReviewDelegationBoard,
        meta: {
          breadcrumbs: [
            {
              title: "Closure Review Delegation Board",
              path: "#"
            }
          ]
        }
      },
      {
        path: "loan-follow-up-dashboard",
        name: "loan-follow-up-dashboard",
        component: loanFollowUpDashboard,
        meta: {
          breadcrumbs: [
            {
              title: "Follow Ups Dashboard",
              path: "#"
            }
          ]
        }
      },
      {
        path: "bank-account-list",
        name: "bank-account-list",
        component: PayerBankAccountList,
        meta: {
          breadcrumbs: [
            {
              title: "Payer Bank Account",
              path: "#"
            }
          ]
        }
      },
      {
        path: "/add-admin",
        name: "add-admin",
        component: AdminForm,
        meta: {
          breadcrumbs: [
            {
              title: "Add Admin",
              path: "#"
            }
          ]
        }
      },
      {
        path: "/edit-admin/:uuid",
        name: "edit-admin",
        component: EditAdminForm,
        meta: {
          breadcrumbs: [
            {
              title: "Admin Account List",
              path: "/admin-list"
            },
            {
              title: "Edit Admin",
              path: "#"
            }
          ]
        }
      },
      {
        path: "/admin-list",
        name: "admin-list",
        component: AdminDataList,
        meta: {
          breadcrumbs: [
            {
              title: "Admin Account List",
              path: "#"
            }
          ]
        }
      },
      {
        path: "add-payer-bank-account-form",
        name: "add-payer-bank-account-form",
        component: CrudForm
      },
      {
        path: "agents-list",
        name: "agents-list",
        component: UsersLayout2
      },
      {
        path: "customer-contacts-list",
        name: "customer-contacts-list",
        component: UsersLayout3
      },
      {
        path: "loan-assessment-detail/:ln_assessment_uuid",
        name: "loan-assessment-detail",
        component: loanAssessmentDetail,
        meta: {
          breadcrumbs: [
            {
              title: "Assessment Detail",
              path: "#"
            }
          ]
        }
      },
      {
        path: "loan-follow-up-detail/:ln_follow_up_uuid",
        name: "loan-follow-up-detail",
        component: loanFollowUpDetail,
        meta: {
          breadcrumbs: [
            {
              title: "Follow Ups Dashboard",
              path: "/loan-follow-up-dashboard"
            },
            {
              title: "Follow Ups Detail",
              path: "#"
            }
          ]
        }
      },
      {
        path: "customers",
        name: "customers",
        component: Customers,
        meta: {
          breadcrumbs: [
            {
              title: "Customers",
              path: "#"
            }
          ]
        }
      },
      {
        path: "customers/customer/:customerId",
        name: "customers-customer-details",
        component: CustomerDetails,
        meta: {
          breadcrumbs: [
            {
              title: "Customers",
              path: "/customers"
            },
            {
              title: "Customer Details",
              path: "#"
            }
          ]
        }
      },
      {
        path: "loan-applications/customer/:customerId",
        name: "loan-application-details-customer-details",
        component: CustomerDetails,
        meta: {
          breadcrumbs: [
            {
              title: "Loan Applications",
              path: "/loan-applications"
            },
            {
              title: "Customer Details",
              path: "#"
            }
          ]
        }
      },
      {
        path: "loan-applications",
        name: "loan-applications",
        component: LoanApplications,
        meta: {
          breadcrumbs: [
            {
              title: "Loan Applications",
              path: "#"
            }
          ]
        }
      },
      {
        path: "unsigned-loans",
        name: "unsigned-loans",
        component: UnsignedLoans,
        meta: {
          breadcrumbs: [
            {
              title: "Unsigned Loans",
              path: "#"
            }
          ]
        }
      },
      {
        path: "contact-failed-loans",
        name: "contact-failed-loans",
        component: ContactFailedLoans,
        meta: {
          breadcrumbs: [
            {
              title: "Contact Failed Loans",
              path: "#"
            }
          ]
        }
      },
      {
        path: "loan-application/:applicationId",
        name: "ln-app-dts-router",
        component: LoanDtsRouter
      },
      {
        path: "customer/:customerId/loan-application/:applicationId",
        name: "loan-application-details",
        component: LoanApplicationDetails,
        meta: {
          breadcrumbs: [
            {
              title: "Customers",
              path: "/customers"
            },
            {
              title: "Loan Application Details",
              path: "#"
            }
          ]
        }
      },
      {
        path: "/content/add",
        name: "content-management-add",
        component: ContentManagementForm,
        redirect: "/content/add/static-page",
        children: [
          {
            path: "static-page",
            name: "content-management-add-page",
            label: "Static Page",
            component: StaticPage,
            meta: {
              breadcrumbs: [
                {
                  title: "Add Static Page",
                  path: "#"
                }
              ]
            }
          },
          {
            path: "component",
            name: "content-management-add-component",
            label: "Component",
            component: Component,
            meta: {
              breadcrumbs: [
                {
                  title: "Add Static Component",
                  path: "#"
                }
              ]
            }
          }
        ]
      },
      {
        path: "/content/edit",
        name: "content-management-edit",
        component: ContentManagementForm,
        children: [
          {
            path: "static-page/:uuid",
            name: "content-management-edit-page",
            label: "Edit Static Page",
            component: StaticPage,
            meta: {
              breadcrumbs: [
                {
                  title: "Static Pages Edit",
                  path: "#"
                }
              ]
            }
          },
          {
            path: "component/:uuid",
            name: "content-management-edit-component",
            label: "Edit Component",
            component: Component,
            meta: {
              breadcrumbs: [
                {
                  title: "Component Edit",
                  path: "#"
                }
              ]
            }
          }
        ]
      },
      {
        path: "/content/list",
        name: "content-management-list",
        component: ContentManagementList,
        meta: {
          breadcrumbs: [
            {
              title: "Static Pages List",
              path: "#"
            }
          ]
        }
      }
    ]
  },
  {
    path: "/simple-menu",
    component: SimpleMenu,
    children: [
      {
        path: "/",
        name: "simple-menu-dashboard-overview-1",
        component: DashboardOverview1,
        meta: {
          breadcrumbs: [
            {
              title: "Dashboard",
              path: "#"
            }
          ]
        }
      },
      {
        path: "inbox",
        name: "simple-menu-inbox",
        component: Payout
      },
      {
        path: "crud-data-list",
        name: "simple-menu-crud-data-list",
        component: PayerBankAccountList
      },
      {
        path: "crud-form",
        name: "simple-menu-crud-form",
        component: CrudForm
      },
      {
        path: "users-layout-2",
        name: "simple-menu-users-layout-2",
        component: UsersLayout2
      },
      {
        path: "users-layout-3",
        name: "simple-menu-users-layout-3",
        component: UsersLayout3
      }
    ]
  },
  {
    path: "/top-menu",
    component: TopMenu,
    children: [
      {
        path: "/",
        name: "top-menu-dashboard-overview-1",
        component: DashboardOverview1,
        meta: {
          breadcrumbs: [
            {
              title: "Dashboard",
              path: "#"
            }
          ]
        }
      },
      {
        path: "payouts",
        name: "top-menu-payouts",
        component: Payout
      },
      {
        path: "new-payouts",
        name: "top-menu-new-payouts",
        component: Payout,
        meta: {
          breadcrumbs: [
            {
              title: "New Payouts",
              path: "#"
            }
          ]
        }
      },
      {
        path: "disbursed-payouts",
        name: "top-menu-disbursed-payouts",
        component: Payout,
        meta: {
          breadcrumbs: [
            {
              title: "Disbursed Payouts",
              path: "#"
            }
          ]
        }
      },
      {
        path: "pending-payouts",
        name: "top-menu-pending-payouts",
        component: Payout
      },
      {
        path: "pre-check-failed",
        name: "top-menu-pre-check-failed",
        component: Payout
      },
      {
        path: "failed-payouts",
        name: "top-menu-failed-payouts",
        component: Payout
      },
      {
        path: "refunds",
        name: "top-menu-refunds",
        component: Refund
      },
      {
        path: "new-refunds",
        name: "top-menu-new-refunds",
        component: Refund
      },
      {
        path: "completed-refunds",
        name: "top-menu-completed-refunds",
        component: Refund
      },
      {
        path: "rejected-refunds",
        name: "top-menu-rejected-refunds",
        component: Refund
      },
      {
        path: "failed-refunds",
        name: "top-menu-failed-refunds",
        component: Refund
      },
      {
        path: "lending-dashboard/lending-agent",
        name: "top-menu-assessment-lending-dashboard",
        component: LendingDashboard,
        meta: {
          breadcrumbs: [
            {
              title: "Assessment Dashboard",
              path: "#"
            }
          ]
        }
      },
      {
        path: "lending-dashboard/lending-manager",
        name: "top-menu-approval-lending-dashboard",
        component: LendingDashboard,
        meta: {
          breadcrumbs: [
            {
              title: "Approval Dashboard",
              path: "#"
            }
          ]
        }
      },
      {
        path: "lending-dashboard/lending-converter",
        name: "top-menu-conversion-lending-dashboard",
        component: LendingDashboard,
        meta: {
          breadcrumbs: [
            {
              title: "Conversion Dashboard",
              path: "#"
            }
          ]
        }
      },
      {
        path: "lending-dashboard/ln-closure-reviewer",
        name: "top-menu-closure-review-dashboard",
        component: LoanClosureReviewDashboard,
        meta: {
          breadcrumbs: [
            {
              title: "Closure Review Dashboard",
              path: "#"
            }
          ]
        }
      },
      {
        path: "loan-follow-up-dashboard",
        name: "top-menu-loan-follow-up-dashboard",
        component: loanFollowUpDashboard
      },
      {
        path: "bank-account-list",
        name: "top-menu-bank-account-list",
        component: PayerBankAccountList,
        meta: {
          breadcrumbs: [
            {
              title: "Payer Bank Account",
              path: "#"
            }
          ]
        }
      },
      {
        path: "/add-admin",
        name: "top-menu-add-admin",
        component: AdminForm,
        meta: {
          breadcrumbs: [
            {
              title: "Add Admin",
              path: "#"
            }
          ]
        }
      },
      {
        path: "/admin-list",
        name: "top-menu-admin-list",
        component: AdminDataList,
        meta: {
          breadcrumbs: [
            {
              title: "Admin Account List",
              path: "#"
            }
          ]
        }
      },
      {
        path: "add-payer-bank-account-form",
        name: "top-menu-add-payer-bank-account-form",
        component: CrudForm
      },
      {
        path: "agents-list",
        name: "top-menu-agents-list",
        component: UsersLayout2
      },
      {
        path: "customer-contacts-list",
        name: "top-menu-customer-contacts-list",
        component: UsersLayout3
      },
      {
        path:
          "loan-req-dec-assessment-detail/lending-manager/:ln_assessment_uuid",
        name: "top-menu-loan-req-dec-assessment-detail",
        component: loanAssessmentDetail
      },
      {
        path: "loan-assessment-detail/lending-agent/:ln_assessment_uuid",
        name: "top-menu-loan-assessment-detail",
        component: loanAssessmentDetail
      },
      {
        path: "loan-assessment-detail/lending-manager/:ln_assessment_uuid",
        name: "top-menu-loan-approval-detail",
        component: loanAssessmentDetail
      },
      {
        path: "loan-assessment-detail/lending-converter/:ln_assessment_uuid",
        name: "top-menu-loan-conversion-detail",
        component: loanAssessmentDetail
      },
      {
        path: "loan-follow-up-detail/:ln_follow_up_uuid",
        name: "top-menu-loan-follow-up-detail",
        component: loanFollowUpDetail
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/sso/login",
    name: "sso-login",
    component: SSOLogin
  },
  {
    path: "/request-password-reset",
    name: "request-password-reset",
    component: RequestPasswordReset
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPassword
  },
  {
    path: "/register",
    name: "register",
    component: Register
  },
  {
    path: "/error-page",
    name: "error-page",
    component: ErrorPage
  },
  {
    path: "/:pathMatch(.*)*",
    component: ErrorPage
  }
];

const store = useStore();

function isUserSet() {
  return (
    localStorage.getItem("user") !== "" &&
    localStorage.getItem("user") !== null &&
    localStorage.getItem("user") !== undefined
  );
}

function getUserObject() {
  return JSON.parse(localStorage.getItem("user"));
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  }
});

router.beforeEach((to, from, next) => {
  resetIsGlobalNotesDisplayed();

  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = [
    "/sso/login",
    "/login",
    "/register",
    "/request-password-reset",
    "/reset-password"
  ];
  const techSupportPages = [
    "/",
    "/search",
    "/delegation-dashboard",
    "/customer",
    "/loan-applications",
    "/loan-assessment-detail"
  ];

  const superAdminProtected = ["/bank-account-list"];

  const adminAndLendingManagerProtected = ["/add-admin", "/admin-list"];

  const followupAdminProtected = [
    "/loan-follow-up-dashboard",
    "/loan-follow-up-detail"
  ];

  const authRequired = !publicPages.includes(to.path);
  const isLogged = localStorage.getItem("access_token");

  if (authRequired && !isLogged) {
    return next("/login");
  }

  // if already logged in and travels to login/register redirect to home page
  if (isLogged && publicPages.includes(to.path)) {
    return next("/");
  }

  // lms admin route middleware
  if (
    isUserSet() &&
    !getUserObject().roles.includes("lms-admin") &&
    superAdminProtected.includes(to.path)
  ) {
    return next("/");
  }

  //lmd lending agent middleware
  if (
    isUserSet() &&
    !getUserObject().roles.includes("lms-admin") &&
    !getUserObject().roles.includes("lending-agents-manager") &&
    adminAndLendingManagerProtected.includes(to.path)
  ) {
    return next("/");
  }
  // followup route middleware
  const routeFirstSegment = to.path.split("/");
  if (
    isUserSet() &&
    !getUserObject().roles.includes("ln-app-follow-up-officer") &&
    followupAdminProtected.includes("/" + routeFirstSegment[1])
  ) {
    return next("/");
  }

  //tech-support routes middleware
  if (
    isUserSet() &&
    !getUserObject().roles.filter(role => role !== "tech-support").length &&
    !techSupportPages.includes("/" + routeFirstSegment[1])
  ) {
    return next("/");
  }

  store.dispatch("loanQuality/resetQualityAndScore", { root: true });

  next();
});

export default router;
