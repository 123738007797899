<template>
  <div>
    <div
      v-if="Object.keys(payoutAccountBalance).length < 1"
      class="alert alert-primary-soft show flex items-center mb-2"
      role="alert"
    >
      <AlertCircleIcon class="w-6 h-6 mr-2" />
      Fetching NPP Payout Balance Info !...<LoadingIcon
        icon="oval"
        color="green"
        class="w-4 h-4 ml-2"
      />
    </div>
    <div
      v-else-if="payoutAccountBalance.amount <= 500000"
      class="alert alert-warning-soft show flex items-center mb-2"
      role="alert"
    >
      <AlertCircleIcon class="w-6 h-6 mr-2" />
      <strong
        >NPP Payout Account is low in balance! Available fund is ${{
          payoutAccountBalance.amount / 100
        }}</strong
      >
    </div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Disbursal Payouts</h2>
      <button
        id="tabulator-print"
        class="btn btn-outline-secondary bg-green-500 w-1/2 sm:w-auto mr-2"
        @click="onExport"
      >
        <FileTextIcon class="w-4 h-4 mr-2" /> Export
      </button>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <!--        <button class="btn btn-primary shadow-md mr-2">Add New Product</button>-->
        <div class="dropdown ml-auto sm:ml-0">
          <!--          <button-->
          <!--            class="dropdown-toggle btn px-2 box text-gray-700 dark:text-gray-300"-->
          <!--            aria-expanded="false"-->
          <!--          >-->
          <!--            <span class="w-5 h-5 flex items-center justify-center">-->
          <!--              <PlusIcon class="w-4 h-4" />-->
          <!--            </span>-->
          <!--          </button>-->
          <div class="dropdown-menu w-40">
            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
              <a
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              >
                <FilePlusIcon class="w-4 h-4 mr-2" /> New Category
              </a>
              <a
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              >
                <UserPlusIcon class="w-4 h-4 mr-2" /> New Group
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center sm:mr-4">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Field</label
            >
            <select
              id="tabulator-html-filter-field"
              v-model="filter.field"
              class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
            >
              <option value="fullname">Name</option>
              <option value="email">Email</option>
              <option value="mobile">Mobile</option>
              <option value="amount">Amount</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Value</label
            >
            <input
              id="tabulator-html-filter-value"
              v-model="filter.value"
              type="text"
              class="form-control sm:w-36 xxl:w-full mt-2 sm:mt-0"
              placeholder="Search..."
            />
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >From</label
            >
            <div class="relative sm:w-36 xxl:w-full mx-auto">
              <div
                class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
              >
                <CalendarIcon class="w-4 h-4" />
              </div>
              <Litepicker
                v-model="fromDate.value"
                :options="{
                  autoApply: true,
                  showWeekNumbers: true,
                  format: 'YYYY-MM-DD',
                  dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true
                  }
                }"
                class="form-control w-full pl-12"
              />
            </div>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >To</label
            >
            <div class="relative sm:w-36 xxl:w-full mx-auto">
              <div
                class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
              >
                <CalendarIcon class="w-4 h-4" />
              </div>
              <Litepicker
                v-model="toDate.value"
                :options="{
                  autoApply: true,
                  showWeekNumbers: true,
                  format: 'YYYY-MM-DD',
                  dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true
                  }
                }"
                class="form-control pl-12"
              />
            </div>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn w-full sm:w-16 bg-yellow-400"
              @click="onFilter"
            >
              Go
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </div>
        <div class="flex mt-5 sm:mt-0">
          <button
            type="button"
            class="btn btn-rounded btn-dark-soft w-1/2 sm:w-auto mr-2"
          >
            Total Amount : ${{ totalDisbursedFund }}
          </button>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
        <button
          type="button"
          class="btn btn-outline-secondary w-1/2 sm:w-auto mt-4 "
        >
          Total
          {{ path === "/payouts" ? "Conversions" : "Disbursals" }} :
          {{ totalDisbursals }}
        </button>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Slide Over Content -->
    <!-- BEGIN: Super Large Slide Over Content -->
    <div
      id="header-footer-slide-over-preview"
      class="modal modal-slide-over"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header p-5">
            <h2 class="font-medium text-base mr-auto">
              Pre Check Failure Logs
            </h2>
          </div>
          <div class="modal-body">
            <table class="table table--sm">
              <thead>
                <tr>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Message
                  </th>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Date Time
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(failureLog,
                  failureLogKey) in payoutPreCheckFailureLogs"
                  :key="failureLogKey"
                >
                  <td class="border-b dark:border-dark-5">
                    {{ failureLog.message }}
                  </td>
                  <td class="border-b dark:border-dark-5">
                    {{ failureLog.created_at }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Super Large Slide Over Content -->
    <!-- END: Slide Over Content -->
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { allPayouts } from "../../composables/allPayouts";

export default defineComponent({
  setup() {
    const {
      payoutAccountBalance,
      totalDisbursedFund,
      totalDisbursals,
      adminUser,
      payoutPreCheckFailureLogs,
      showPreCheckLogs,
      tableRef,
      fromDate,
      toDate,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      onExport,
      breadcrumb,
      path,
      disbursalPayoutList,
      getDisbursalPayoutsAction
    } = allPayouts();

    return {
      payoutAccountBalance,
      totalDisbursedFund,
      totalDisbursals,
      adminUser,
      payoutPreCheckFailureLogs,
      showPreCheckLogs,
      tableRef,
      fromDate,
      toDate,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      onExport,
      breadcrumb,
      path,
      disbursalPayoutList,
      getDisbursalPayoutsAction
    };
  }
});
</script>
<style scoped>
.tabulator {
  font-size: 13px;
}
</style>
