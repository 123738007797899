import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";

const state = () => {
  return {
    busy: true,
    lnApprovalPreRequisiteVerifications: []
  };
};

// getters
const getters = {
  busy: state => state.busy,
  lnApprovalPreRequisiteVerifications: state =>
    state.lnApprovalPreRequisiteVerifications
};

// actions
const actions = {
  getApprovalPreRequisiteVerificationsOfLoan: async (
    { commit, dispatch },
    applicationId
  ) => {
    commit("setBusy");
    axiosWrapperService
      .axiosGet("/getApprovalPreRequisiteVerificationsOfLoan/" + applicationId)
      .then(response => {
        commit(
          "setLnApprovalPreRequisiteVerifications",
          response.data.data.admin_request
        );
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("resetBusy");
      });
  }
};

// mutations
const mutations = {
  setBusy(state) {
    state.busy = true;
  },
  resetBusy(state) {
    state.busy = false;
  },
  setLnApprovalPreRequisiteVerifications(
    state,
    lnApprovalPreRequisiteVerifications
  ) {
    state.lnApprovalPreRequisiteVerifications = lnApprovalPreRequisiteVerifications;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
