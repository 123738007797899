import { formatErrorMessage } from "@/utils/messageFormatter";
import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";

// state
const state = () => {
  return {
    transactions: [],
    isBusy: false,
    visibleTransactionsShownForScheduleId: 0
  };
};

// actions
const actions = {
  getTransactions: async ({ commit, dispatch }, { scheduleId }) => {
    commit("setIsBusy", true);
    axiosWrapperService
      .axiosGet(`/paymentSchedules/${scheduleId}/transactions`)
      .then(response => {
        commit("setTransactions", response.data.data);
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: formatErrorMessage(error),
            type: "error"
          },
          { root: true }
        );
      })
      .finally(() => commit("setIsBusy", false));
  }
};

// mutations
const mutations = {
  setTransactions(state, transactions) {
    state.transactions = transactions;
  },

  setIsBusy(state, isBusy) {
    state.isBusy = isBusy;
  },

  setVisibleTransactionsShownForScheduleId(state, scheduleId) {
    state.visibleTransactionsShownForScheduleId = scheduleId;
  }
};

// getters
const getters = {
  transactions: state => state.transactions,
  isBusy: state => state.isBusy,
  visibleTransactionsShownForScheduleId: state =>
    state.visibleTransactionsShownForScheduleId
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
