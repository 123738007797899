<template>
  <div>
    <div v-if="applicationId > 0" class="grid grid-cols-3 gap-1">
      <div
        :class="{
          'col-span-2': isGlobalNotesDisplayed,
          'col-span-3': !isGlobalNotesDisplayed
        }"
      >
        <NoteComponentToggler
          :application-id="applicationId"
          :associated-model-id="0"
          :associated-model-key="'assessment'"
          :associated-model-uuid="loanAssessmentUuid"
        />

        <div
          v-if="loanAssessmentOwners.length"
          class="grid grid-cols-2 py-4 pt-2 pb-[2px]"
        >
          <div class="text-left">
            <h2
              v-if="getLoanAssessmentStage(loanAssessment).length"
              class="font-medium text-theme-7 text-base mt-6 mr-auto"
            >
              State:
              <select
                class="form-select form-select-sm w-3/5"
                @change="
                  updateLoanAssessmentOwners(
                    getRoleAccordingToStage($event.target.value)
                  )
                "
              >
                <option
                  v-for="(stage, index) in getLoanAssessmentStage(
                    loanAssessment
                  )"
                  :key="index"
                  :value="stage"
                >
                  {{ stage }}
                </option>
              </select>
            </h2>
          </div>
          <div class="text-left">
            <h2
              v-if="loanAssessmentOwners.length"
              class="font-medium text-theme-7 text-base mt-6 mr-auto flex"
              @change="
                changeAssessmentOwner($event.target.value, loanAssessmentUuid)
              "
            >
              Owner: &nbsp;
              <select
                v-model="assessmentOwner"
                :disabled="busy"
                class="form-select form-select-sm w-3/5"
              >
                <option disabled value="0">Select Owner</option>
                <option
                  v-for="(owner, index) in loanAssessmentOwners"
                  :key="index"
                  :value="owner.id"
                >
                  {{ owner.fullname }}
                </option>
              </select>
              <div v-if="busy" class="flex items-center w-1/5">
                <LoadingIcon color="green" icon="tail-spin" />
              </div>
            </h2>
          </div>
          <div class="flex text-center col-span-3">
            <template v-if="loanAssessment">
              <h2
                v-if="loanAssessment.status.length"
                class="font-medium text-theme-7 text-base mt-6"
              >
                Lending Status:
                {{
                  getLendingAssessmentAccessBucket(loanAssessment).includes(
                    "Queue"
                  )
                    ? "Applied"
                    : loanAssessment.status
                }}
                | &nbsp;
              </h2>
              <h2
                v-if="
                  loanAssessment.on_loan_closure_eval &&
                    !loanAssessment.on_follow_up
                "
                class="font-medium text-theme-7 text-base mt-6"
              >
                Loan Closure Review Queue
              </h2>
              <h2
                v-else-if="
                  !getLendingAssessmentAccessBucket(loanAssessment).includes(
                    'Queue'
                  )
                "
                class="font-medium text-theme-7 text-base mt-6"
              >
                {{ getLendingAssessmentAccessBucket(loanAssessment) }} Bucket :
                <!-- <UserIcon class="h-5 w-5" /> -->
                ({{
                  getLendingAssessmentAccessType(loanAssessment) ===
                  "lending-agent"
                    ? loanAssessment.agent.fullname
                    : getLendingAssessmentAccessType(loanAssessment) ===
                      "lending-converter"
                    ? loanAssessment.converter.fullname
                    : getLendingAssessmentAccessType(loanAssessment) ===
                      "lending-manager"
                    ? loanAssessment.manager.fullname
                    : ""
                }})
              </h2>
              <h2 v-else class="font-medium text-theme-7 text-base mt-6">
                {{ getLendingAssessmentAccessBucket(loanAssessment) }}
              </h2>
              <h2
                v-if="!loanAssessment.on_follow_up"
                class="font-medium text-theme-7 text-base mt-6"
              >
                &nbsp; | Follow up Status: None
              </h2>
              <template v-else>
                <h2
                  v-if="loanAssessment.loan.follow_up_agent"
                  class="font-medium text-theme-7 text-base mt-6"
                >
                  &nbsp; | Follow up Status: Assigned ({{
                    loanAssessment.loan.follow_up_agent
                  }})
                </h2>
                <h2 v-else class="font-medium text-theme-7 text-base mt-6">
                  &nbsp; | Follow up Status: Queue
                </h2>
              </template>
            </template>
          </div>
        </div>

        <LoanApplicationCommon
          :application-id="applicationId"
          :customer-id="customerId"
        />
        <div
          v-if="
            showFinalizingButtons && applicationTransitionReasonTypes !== null
          "
          class="flow-root grid grid-cols-5 box mt-1"
        >
          <div
            v-if="enabledAgreggatorDataField !== ''"
            class="flex flex-col col-span-5 sm:flex-row items-center p-5 w-full"
          >
            <div class="w-full flex sm:ml-auto mt-3 sm:mt-0">
              <button
                class="
                  btn btn-rounded
                  bg-gray-400
                  w-96
                  text-white
                  sm:w-auto
                  mr-2
                "
                type="button"
              >
                <MinusCircleIcon class="w-6 h-6 mr-2" />
                Edit Mode ...
              </button>
            </div>
          </div>
          <div
            v-if="enabledAgreggatorDataField === ''"
            class="flex flex-col sm:flex-row items-center p-5"
          >
            <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0">
              <button
                class="
                  btn btn-rounded
                  bg-red-400
                  w-1/2
                  text-white
                  sm:w-auto
                  mr-2
                "
                type="button"
                @click.prevent="showDismissalAssessmentModal()"
              >
                <XCircleIcon class="w-6 h-6 mr-2" />
                {{ getAssesmentDismissLabelAsPerAdminRole() }}
              </button>
            </div>
          </div>
          <div class="flex flex-col sm:flex-row items-center p-5">
            <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0">
              <button
                class="
                  btn btn-rounded
                  bg-red-400
                  w-1/2
                  text-white
                  sm:w-auto
                  mr-2
                "
                type="button"
                @click.prevent="showWithdrawalAssessmentModal()"
              >
                <XOctagonIcon class="w-6 h-6 mr-2" />
                Withdraw Application
              </button>
            </div>
          </div>
          <!--          <div-->
          <!--            v-if="canBeRequestedToDecline && enabledAgreggatorDataField === ''"-->
          <!--            class="flex flex-col sm:flex-row items-center p-5"-->
          <!--          >-->
          <!--            <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0">-->
          <!--              <button-->
          <!--                type="button"-->
          <!--                class="-->
          <!--                  btn btn-rounded-->
          <!--                  bg-theme-6-->
          <!--                  w-1/2-->
          <!--                  text-white-->
          <!--                  sm:w-auto-->
          <!--                  mr-2-->
          <!--                "-->
          <!--                @click.prevent="showRequestDeclineAssessmentModal()"-->
          <!--              >-->
          <!--                <AlertTriangleIcon class="w-6 h-6 mr-2" />-->
          <!--                Request Decline-->
          <!--              </button>-->
          <!--            </div>-->
          <!--          </div>-->
          <div
            v-if="
              reAssignable &&
                enabledAgreggatorDataField === '' &&
                loanAssessment.loan.customer.is_blacklisted < 1
            "
            class="flex flex-col sm:flex-row items-center p-5"
          >
            <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0">
              <button
                class="
                  btn btn-rounded
                  bg-theme-7
                  w-1/2
                  text-white
                  sm:w-auto
                  mr-2
                "
                type="button"
                @click.prevent="showRedoAssessmentModal()"
              >
                <RotateCcwIcon class="w-6 h-6 mr-2" />
                Request Re-Assessment
              </button>
            </div>
          </div>
          <div
            v-if="
              enabledAgreggatorDataField === '' &&
                !isApplicationBlacklisted &&
                loanAssessment.loan.customer.is_blacklisted < 1
            "
            class="flex flex-col sm:flex-row items-center p-5"
          >
            <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0">
              <button
                class="
                  btn btn-rounded
                  bg-theme-3
                  w-1/2
                  text-white
                  sm:w-auto
                  mr-2
                "
                type="button"
                @click.prevent="showSubmitAssessmentModal(loanAssessment)"
              >
                <ArrowRightCircleIcon class="w-6 h-6 mr-2" />
                {{
                  getAssesmentSubmitLabelAsPerAssessmentStatus(loanAssessment)
                }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        :class="{
          none: !isGlobalNotesDisplayed
        }"
      >
        <NoteComponentBody
          :application_id="applicationId"
          :associated_model_id="0"
          :associated_model_key="'assessment'"
          :associated_model_uuid="loanAssessmentUuid"
        />
      </div>

      <AddFollowUpForm
        v-if="shouldShowAddFollowup"
        :application-id="applicationId"
      />
      <FundoLmsToastAlert />
      <!-- BEGIN: assessment-finalization-confirmation-modal Content -->
      <div
        id="assessment-finalization-confirmation-modal"
        aria-hidden="true"
        class="modal"
        tabindex="-1"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <!-- BEGIN: Modal Header -->
            <div class="modal-header">
              <h2 class="font-medium text-base mr-auto">
                {{ assessmentFinalizationConfirmationDataObject.title }}
              </h2>
              <div class="dropdown sm:hidden">
                <a
                  aria-expanded="false"
                  class="dropdown-toggle w-5 h-5 block"
                  href="javascript:"
                >
                  <MoreHorizontalIcon
                    class="w-5 h-5 text-gray-600 dark:text-gray-600"
                  />
                </a>
                <div class="dropdown-menu w-40">
                  <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                    <a
                      class="
                        flex
                        items-center
                        p-2
                        transition
                        duration-300
                        ease-in-out
                        bg-white
                        dark:bg-dark-1
                        hover:bg-gray-200
                        dark:hover:bg-dark-2
                        rounded-md
                      "
                      href="javascript:"
                    >
                      <FileIcon class="w-4 h-4 mr-2" />
                      Download Docs
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!-- END: Modal Header -->
            <!-- BEGIN: Modal Body -->
            <div class="modal-body grid grid-cols-6">
              <div class="col-span-12 sm:col-span-6">
                <div
                  v-if="finalizationFormValidationMessage"
                  class="alert alert-danger-soft show flex items-center mb-2"
                  role="alert"
                >
                  <AlertOctagonIcon class="w-6 h-6 mr-2" />
                  {{ finalizationFormValidationMessage }}
                  <button
                    class="btn-close"
                    type="button"
                    @click="clearFinalizationFormValidationMessages"
                  >
                    <XIcon class="w-4 h-4" />
                  </button>
                </div>
                <div
                  v-if="
                    assessmentFinalizationConfirmationDataObject.type ===
                      'dismissal' ||
                      assessmentFinalizationConfirmationDataObject.type ===
                        'request-decline'
                  "
                  class="col-span-12"
                >
                  <label class="form-label"> Transition Reason:</label>
                  <select
                    v-if="
                      assessmentFinalizationConfirmationDataObject.type ===
                        'dismissal'
                    "
                    v-model="
                      assessmentFinalizationConfirmationDataObject.transition_reason_type
                    "
                    aria-label=".form-select-sm example"
                    class="form-select form-select-sm mt-2"
                    @change="
                      adjustReactivationDateAccordingToReasonDaysLimit(
                        'declined',
                        $event.target.value
                      )
                    "
                  >
                    <option selected value="0">None</option>
                    <option
                      v-for="(traReaType,
                      traReaTypeIndex) in applicationTransitionReasonTypes.declined"
                      :key="traReaTypeIndex"
                      :value="traReaType.id"
                    >
                      {{ traReaType.transition_reason }}
                    </option>
                  </select>
                  <select
                    v-else-if="
                      assessmentFinalizationConfirmationDataObject.type ===
                        'request-decline'
                    "
                    v-model="
                      assessmentFinalizationConfirmationDataObject.transition_reason_type
                    "
                    aria-label=".form-select-sm example"
                    class="form-select form-select-sm mt-2"
                    @change="
                      adjustReactivationDateAccordingToReasonDaysLimit(
                        'request_decline',
                        $event.target.value
                      )
                    "
                  >
                    <option selected value="0">None</option>
                    <option
                      v-for="(traReaType,
                      traReaTypeIndex) in applicationTransitionReasonTypes.request_decline"
                      :key="traReaTypeIndex"
                      :value="traReaType.id"
                    >
                      {{ traReaType.transition_reason }}
                    </option>
                  </select>
                </div>
                <div
                  v-if="
                    assessmentFinalizationConfirmationDataObject.type ===
                      'dismissal'
                  "
                  class="col-span-12 pt-1"
                >
                  <label class="form-label"> Next Reapply Date:</label>
                  <FlatPickr
                    v-model="
                      assessmentFinalizationConfirmationDataObject.reapplication_activation_date
                    "
                    class="px-3
                           py-1
                           w-full
                           rounded-lg
                           col-span-6
                           border border-gray-300
                           gap-3"
                  ></FlatPickr>
                </div>
                <label class="form-label">
                  {{ assessmentFinalizationConfirmationDataObject.title }}
                  Note:</label
                >
                <textarea
                  id="assessmnet-finalization-note"
                  v-model="assessmentFinalizationConfirmationDataObject.note"
                  class="form-control"
                  type="text"
                >
                </textarea>
              </div>
            </div>
            <!-- END: Modal Body -->
            <!-- BEGIN: Modal Footer -->
            <div class="modal-footer text-right">
              <button
                class="btn btn-dark-soft w-20 mr-1"
                data-dismiss="modal"
                type="button"
              >
                Cancel
              </button>
              <button
                v-if="
                  assessmentFinalizationConfirmationDataObject.type ===
                    'dismissal'
                "
                class="btn btn-danger w-40"
                type="button"
                @click="triggerDismissActionAsPerAdminRole"
              >
                {{ getAssesmentDismissLabelAsPerAdminRole() }}
              </button>
              <button
                v-else-if="
                  assessmentFinalizationConfirmationDataObject.type ===
                    'withdrawal'
                "
                class="btn btn-danger w-40"
                type="button"
                @click="onWithdrawAssessmentAction"
              >
                Withdraw Application
              </button>
              <button
                v-else-if="
                  assessmentFinalizationConfirmationDataObject.type ===
                    'request-decline'
                "
                class="btn btn-danger w-40"
                type="button"
                @click="onReqForAssessmentDeclineAction"
              >
                Request Decline
              </button>
              <button
                v-else-if="
                  assessmentFinalizationConfirmationDataObject.type ===
                    'redo-assessment'
                "
                class="btn btn-danger w-52"
                type="button"
                @click="onRedoAssessmentReqAction"
              >
                Request Re-Assessment
              </button>
              <button
                v-else-if="
                  assessmentFinalizationConfirmationDataObject.type ===
                    'submit-assessment'
                "
                class="btn btn-success w-40"
                type="button"
                @click="triggerSubmitActionAsPerAssessmentStatus"
              >
                {{
                  getAssesmentSubmitLabelAsPerAssessmentStatus(
                    loanAssessment
                  ).split(" ")[0]
                }}
              </button>
            </div>
            <!-- END: Modal Footer -->
          </div>
        </div>
      </div>
    </div>
    <!-- END: assessment-finalization-confirmation-modal Content -->
    <div
      v-else
      class="
        bg-white
        opacity-75
        flex flex-col
        items-center
        justify-center
        h-screen
      "
    >
      <div class="relative flex justify-center items-center h-3">
        <LoadingIcon class="w-16 h-16" color="green" icon="bars" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { loanAssessmentDetail } from "@/composables/loanAssessmentDetail";
import NoteComponentToggler from "@/components/note-component-toggler/Main.vue";
import NoteComponentBody from "@/components/note-component-body/Main.vue";
import AddFollowUpForm from "@/components/add-follow-up-form/Main";
import FundoLmsToastAlert from "@/components/fundo-lms-toast-alert/Main";
import LoanApplicationCommon from "@/components/loan-application-details/common/Main";

import FlatPickr from "vue-flatpickr-component";
// base css for flatpickr
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/plugins/confirmDate/confirmDate.css";
import "flatpickr/dist/plugins/monthSelect/style.css";
import "flatpickr/dist/themes/material_green.css";

export default defineComponent({
  components: {
    NoteComponentToggler,
    NoteComponentBody,
    AddFollowUpForm,
    FundoLmsToastAlert,
    LoanApplicationCommon,
    FlatPickr
  },
  setup() {
    const {
      assessorPageType,
      getSelfAssignableRoleType,
      getLendingAssessmentAccessType,
      isGlobalNotesDisplayed,
      onToggleIsGlobalNotesDisplayed,
      busy,
      adminUser,
      applicationId,
      customerId,
      loanAssessmentUuid,
      loanAssessment,
      onSelfAssignAssessment,
      showFinalizingButtons,
      applicationTransitionReasonTypes,
      enabledAgreggatorDataField,
      showDismissalAssessmentModal,
      getAssesmentDismissLabelAsPerAdminRole,
      withdrawable,
      showWithdrawalAssessmentModal,
      canBeRequestedToDecline,
      showRequestDeclineAssessmentModal,
      reAssignable,
      showRedoAssessmentModal,
      showSubmitAssessmentModal,
      getAssesmentSubmitLabelAsPerAssessmentStatus,
      assessmentFinalizationConfirmationDataObject,
      finalizationFormValidationMessage,
      clearFinalizationFormValidationMessages,
      triggerSubmitActionAsPerAssessmentStatus,
      onRedoAssessmentReqAction,
      onReqForAssessmentDeclineAction,
      onWithdrawAssessmentAction,
      triggerDismissActionAsPerAdminRole,
      getLoanAssessmentStage,
      loanAssessmentOwners,
      getRoleAccordingToStage,
      updateLoanAssessmentOwners,
      shouldShowAddFollowup,
      changeAssessmentOwner,
      getCurrentAssignedUserId,
      getLendingAssessmentAccessBucket,
      isApplicationBlacklisted,
      assessmentOwner,
      adjustReactivationDateAccordingToReasonDaysLimit
    } = loanAssessmentDetail();

    return {
      assessorPageType,
      getSelfAssignableRoleType,
      getLendingAssessmentAccessType,
      isGlobalNotesDisplayed,
      onToggleIsGlobalNotesDisplayed,
      busy,
      adminUser,
      applicationId,
      customerId,
      loanAssessmentUuid,
      loanAssessment,
      onSelfAssignAssessment,
      showFinalizingButtons,
      applicationTransitionReasonTypes,
      enabledAgreggatorDataField,
      showDismissalAssessmentModal,
      getAssesmentDismissLabelAsPerAdminRole,
      withdrawable,
      showWithdrawalAssessmentModal,
      canBeRequestedToDecline,
      showRequestDeclineAssessmentModal,
      reAssignable,
      showRedoAssessmentModal,
      showSubmitAssessmentModal,
      getAssesmentSubmitLabelAsPerAssessmentStatus,
      assessmentFinalizationConfirmationDataObject,
      finalizationFormValidationMessage,
      clearFinalizationFormValidationMessages,
      triggerSubmitActionAsPerAssessmentStatus,
      onRedoAssessmentReqAction,
      onReqForAssessmentDeclineAction,
      onWithdrawAssessmentAction,
      triggerDismissActionAsPerAdminRole,
      getLoanAssessmentStage,
      loanAssessmentOwners,
      shouldShowAddFollowup,
      getRoleAccordingToStage,
      updateLoanAssessmentOwners,
      changeAssessmentOwner,
      getCurrentAssignedUserId,
      getLendingAssessmentAccessBucket,
      isApplicationBlacklisted,
      assessmentOwner,
      adjustReactivationDateAccordingToReasonDaysLimit
    };
  }
});
</script>
