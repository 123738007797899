import { formatErrorMessage } from "@/utils/messageFormatter";
import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";

const state = () => {
  return {
    loanRebuttalList: [],
    rebuttalCategoryList: [],
    rebuttalTemplateList: [],
    busy: false,
    addRebuttalValidationMessage: "",
    loanRebuttalErrorMessage: "",
    loanRebuttalSuccessMessage: ""
  };
};

// getters
const getters = {
  applicationId: state => state.applicationId,
  loanRebuttalList: state => state.loanRebuttalList,
  rebuttalCategoryList: state => state.rebuttalCategoryList,
  rebuttalTemplateList: state => state.rebuttalTemplateList,
  busy: state => state.busy,
  addRebuttalValidationMessage: state => state.addRebuttalValidationMessage,
  loanRebuttalErrorMessage: state => state.loanRebuttalErrorMessage,
  loanRebuttalSuccessMessage: state => state.loanRebuttalSuccessMessage
};

// actions
const actions = {
  getApplicableRebuttalTemplateCategoriesForLoan: async (
    { dispatch, state, commit },
    { applicationId, successMessage }
  ) => {
    try {
      commit("clearLoanRebuttalMessages");
      axiosWrapperService
        .axiosGet(
          "/getApplicableRebuttalTemplateCategoriesForLoan/" + applicationId
        )
        .then(response => {
          commit("clearAndPushToRebuttalCategoryList", response.data.data);
          if (successMessage !== "") {
            dispatch(
              "globalToastMessage/showSimpleToast",
              { message: successMessage, type: "success" },
              { root: true }
            );
          }
        })
        .catch(error => {
          dispatch(
            "globalToastMessage/showSimpleToast",
            { message: formatErrorMessage(error), type: "error" },
            { root: true }
          );
        });
    } catch (e) {
      commit("setDocumentRequestErrorMessage", "Failed to get the list.");
    }
  },
  getRebuttalsOnLoan: async (
    { dispatch, state, commit },
    { applicationId, successMessage }
  ) => {
    try {
      commit("clearLoanRebuttalMessages");
      commit("setBusy");
      axiosWrapperService
        .axiosGet("/getRebuttalsOnLoan/" + applicationId)
        .then(response => {
          commit("clearAndPushToLoanRebuttalList", response.data.data);
          if (successMessage !== "") {
            dispatch(
              "globalToastMessage/showSimpleToast",
              { message: successMessage, type: "success" },
              { root: true }
            );
          }
        })
        .catch(error => {
          dispatch(
            "globalToastMessage/showSimpleToast",
            { message: formatErrorMessage(error), type: "error" },
            { root: true }
          );
        })
        .finally(() => {
          commit("resetBusy");
        });
    } catch (error) {
      dispatch(
        "globalToastMessage/showSimpleToast",
        { message: formatErrorMessage(error), type: "error" },
        { root: true }
      );
    }
  },
  addRebuttalToLoan: async (
    { dispatch, state, commit },
    { applicationId, formData }
  ) => {
    commit("clearLoanRebuttalMessages");
    axiosWrapperService
      .axiosPost("/addRebuttalToLoan/" + applicationId, formData)
      .then(() => {
        dispatch("getApplicableRebuttalTemplateCategoriesForLoan", {
          applicationId: applicationId,
          successMessage: ""
        });
        dispatch("getRebuttalsOnLoan", {
          applicationId: applicationId,
          successMessage: "Successfully added rebuttal to Loan."
        });
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("resetBusy");
      });
  },
  deleteExistingRebuttalOnLoan: async (
    { dispatch, state, commit },
    { uuid, applicationId }
  ) => {
    commit("clearLoanRebuttalMessages");
    axiosWrapperService
      .axiosDelete("/deleteExistingRebuttalOnLoan/" + uuid)
      .then(() => {
        dispatch("getApplicableRebuttalTemplateCategoriesForLoan", {
          applicationId: applicationId,
          successMessage: ""
        });
        dispatch("getRebuttalsOnLoan", {
          applicationId: applicationId,
          successMessage: "Successfully removed rebuttal from Loan."
        });
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("resetBusy");
      });
  }
};

// mutations
const mutations = {
  setBusy(state) {
    state.busy = true;
  },
  resetBusy(state) {
    state.busy = false;
  },
  clearAndPushToRebuttalCategoryList(state, rebuttalCategoryList) {
    state.rebuttalCategoryList = rebuttalCategoryList;
  },
  clearAndPushToLoanRebuttalList(state, loanRebuttalList) {
    state.loanRebuttalList = loanRebuttalList;
  },
  clearAndPushToRebuttalTemplateList(state, rebuttalTemplateList) {
    state.rebuttalTemplateList = rebuttalTemplateList;
  },
  clearRebuttalTemplateList(state, rebuttalTemplateList) {
    state.rebuttalTemplateList = [];
  },
  clearLoanRebuttalMessages(state) {
    state.addRebuttalValidationMessage = "";
    state.loanRebuttalErrorMessage = "";
    state.loanRebuttalSuccessMessage = "";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
