<template>
  <!-- BEGIN: Modal Content -->
  <div
    id="change-payment-frequency-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content w-1/2 mx-auto">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base mr-auto">
            Change Payment Frequency
          </h2>
        </div>
        <!-- END: Modal Header -->
        <!-- BEGIN: Modal Body -->
        <div
          v-if="errorMessage !== ''"
          class="alert alert-danger-soft show flex items-center mb-2"
          role="alert"
        >
          <AlertOctagonIcon class="w-6 h-6 mr-2" />
          {{ errorMessage }}
        </div>
        <div class="modal-body">
          <div class="">
            <div class="mt-2">
              <label class="form-label">Payment Frequency:</label>
              <select v-model="form.frequency" class="form-select pt-2 mt-2">
                <option value="" selected> -- Select -- </option>
                <option value="Weekly">Weekly</option>
                <option value="Fortnightly">Fortnightly</option>
              </select>
              <div v-if="validationErrors.frequency" class="p-1 text-red-600">
                {{ validationErrors.frequency[0] }}
              </div>
            </div>
          </div>
        </div>
        <!-- END: Modal Body -->
        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer text-right">
          <button
            type="button"
            data-dismiss="modal"
            class="btn btn-dark-soft w-20 mr-1"
          >
            Cancel
          </button>
          <button
            v-if="!isBusy"
            type="button"
            class="btn btn-success w-40"
            @click="submitForm"
          >
            Submit
          </button>
          <button v-else class="btn btn-success w-40">
            Processing
            <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  setup() {
    const store = useStore();

    const form = {
      frequency: ""
    };

    const isBusy = computed(
      () => store.state.paymentScheduleLoanActions.isBusy
    );

    const validationErrors = computed(
      () => store.state.paymentScheduleLoanActions.validationErrors
    );
    const errorMessage = computed(
      () => store.state.paymentScheduleLoanActions.errorMessage
    );

    function submitForm() {
      store.dispatch("paymentScheduleLoanActions/changePaymentFrequency", {
        form
      });
    }

    return {
      form,
      submitForm,
      validationErrors,
      errorMessage,
      isBusy
    };
  }
});
</script>

<style scoped></style>
