import { onMounted, computed } from "vue";
import { useStore } from "@/store";
import { useRouter } from "vue-router";

export const getCustomer = customerId => {
  const store = useStore();
  const adminUser = computed(() => store.state.auth.loggedUser);

  const customer = computed(() => store.state.customerDetails.customer);

  function init() {
    getCustomer(customerId);
  }

  function getCustomer(customerId) {
    store.dispatch("customerDetails/getCustomer", { customerId });
  }

  onMounted(() => {
    init();
  });

  return {
    adminUser,
    init,
    customer
  };
};

export const customerOperation = _ => {
  const store = useStore();
  const router = useRouter();
  const loggedUser = computed(() => store.state.auth.loggedUser);
  const userRoles = loggedUser.value.roles;

  const customerDeleted = computed(
    () => store.state.customerDetails.customerDeleted
  );

  const customerErrorMessage = computed(
    () => store.state.customerDetails.customerErrorMessage
  );

  function anonymizeCustomer(customerId) {
    store.dispatch(`customerDetails/anonymize`, customerId).then(response => {
      if (userRoles.includes("lending-agent")) {
        router.push("/lending-dashboard/lending-agent");
      } else if (userRoles.includes("lending-manager")) {
        router.push("/lending-dashboard/lending-manager");
      } else if (userRoles.includes("lending-converter")) {
        router.push("/lending-dashboard/lending-converter");
      } else {
        router.push("/lending-dashboard");
      }
    });
  }

  return {
    customerDeleted,
    customerErrorMessage,
    anonymizeCustomer,
    userRoles
  };
};
