export function genTabulatorJsFormatQueryStringFromParamsObject(params) {
  // clone the object, so that integrity of original refrenced Object is preserved
  let paramString = Object.assign({}, params);
  let queryString = "";

  // build generic query string for pagination
  if (paramString !== undefined) {
    for (let k in paramString) {
      if (k === "filter" || k === "sort") {
        continue;
      }
      if (paramString[k].length) {
        queryString += k + "=" + paramString[k] + "&";
      }
    }

    // slice last '&'
    queryString =
      queryString.slice(queryString.length - 1) === "&"
        ? queryString.slice(0, -1)
        : queryString;
  }

  // generate and append query string of sorters if it exists
  if (paramString.sort !== undefined && paramString.sort.length > 0) {
    if (queryString !== "") {
      queryString += "&";
    }
    for (let i = 0; i < paramString.sort.length; i++) {
      queryString +=
        "sort[" +
        i +
        "][field]" +
        "=" +
        paramString.sort[i]["field"] +
        "&" +
        "sort[" +
        i +
        "][dir]" +
        "=" +
        paramString.sort[i]["dir"];

      if (i !== paramString.sort.length - 1) queryString += "&";
    }
  }

  // generate and append query string of filters if it exists
  if (paramString.filter !== undefined && paramString.filter.length > 0) {
    if (queryString !== "" && paramString.filter.length > 0) {
      queryString += "&";
    }

    for (let j = 0; j < paramString.filter.length; j++) {
      queryString +=
        "filter[" +
        j +
        "][field]" +
        "=" +
        paramString.filter[j]["field"] +
        "&" +
        "filter[" +
        j +
        "][value]" +
        "=" +
        paramString.filter[j]["value"] +
        "&" +
        "filter[" +
        j +
        "][type]" +
        "=" +
        paramString.filter[j]["type"];

      if (j !== paramString.filter.length - 1) queryString += "&";
    }
  }

  // return resulting query string
  return queryString;
}
