import { useStore } from "@/store";

export const customSwalMessageBuilder = () => {
  const store = useStore();

  function buildSwalWarning(message, cancelBtn = false, title = "Alert!") {
    const optionObject = {
      title: title,
      text: message,
      icon: "warning",
      confirmButtonColor: "#FBBF24",
      willClose: resetFullPageLoader
    };
    if (cancelBtn) {
      optionObject.showCancelButton = true;
    }
    return optionObject;
  }

  function buildSwalSuccess(message) {
    return {
      title: "Success!",
      text: message,
      type: "success",
      icon: "success",
      confirmButtonColor: "#FBBF24",
      willClose: resetFullPageLoader
    };
  }

  function buildSwalToast(message, icon = "warning") {
    return {
      toast: true,
      icon: icon,
      text: message,
      position: "top-right",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true
    };
  }

  function buildSwalError(message) {
    return {
      title: "Error!",
      text: message,
      type: "error",
      icon: "error",
      confirmButtonColor: "#FBBF24",
      willClose: resetFullPageLoader
    };
  }

  function buildSwalHtmlError(message) {
    return {
      title: "Error!",
      html: message,
      type: "error",
      icon: "error",
      confirmButtonColor: "#FBBF24",
      willClose: resetFullPageLoader
    };
  }

  function buildCustomMessage({
    message = "",
    type = "info",
    timer = 2000,
    showConfirmButton = true
  }) {
    return {
      title: `${type.charAt(0).toUpperCase() + type.slice(1)}!`,
      html: message,
      type: type.toLowerCase(),
      icon: type.toLowerCase(),
      showConfirmButton: showConfirmButton,
      confirmButtonColor: "#FBBF24",
      timer: timer,
      willClose: resetFullPageLoader
    };
  }

  function resetFullPageLoader() {
    store.commit("main/resetFullPageLoading");
  }

  return {
    buildSwalWarning,
    buildSwalSuccess,
    buildSwalToast,
    buildSwalError,
    buildSwalHtmlError,
    buildCustomMessage
  };
};
