<template>
  <div class="grid grid-cols-12 gap-2">
    <div
      :class="{
        'col-span-7 tab tab-contents': isGlobalNotesDisplayed,
        'col-span-12 tab tab-contents': !isGlobalNotesDisplayed
      }"
    >
      <div class="intro-y box px-5 pt-5 mt-5">
        <div
          class="
            nav nav-tabs
            pb-2
            flex-col
            sm:flex-row
            justify-center
            lg:justify-start
          "
          role="tablist"
        >
          <a
            id="application-tab"
            aria-controls="dashboard"
            aria-selected="true"
            class="pt-4 pb-2 sm:mr-8 active"
            data-target="#application-tab-content"
            data-toggle="tab"
            role="tab"
            @click="changeTab('application')"
            >Application</a
          >
          <a
            id="payment-schedules-tab"
            aria-selected="false"
            class="pt-4 pb-2 sm:mr-8"
            data-target="#payment-schedules"
            data-toggle="tab"
            role="tab"
            @click="changeTab('payment-schedules')"
            >Payment Schedules</a
          >
          <a
            id="notes-tab"
            aria-selected="false"
            class="pt-4 pb-2 sm:mr-8"
            data-target="#notes"
            data-toggle="tab"
            role="tab"
            @click="changeTab('notes')"
            >Notes</a
          >
          <a
            id="legacy-lms-link"
            :href="legacyLMSLoanDetailsPageUrl"
            target="_blank"
            >Open Old LMS Loan Details Page</a
          >
        </div>
      </div>

      <NoteComponentToggler
        :application-id="applicationId"
        :associated-model-id="0"
        :associated-model-key="'application_details'"
      />
      <div
        v-if="currentTab === 'application'"
        id="application-tab-content"
        class="tab-content"
      >
        <LoanApplicationCommon
          :application-id="applicationId"
          :customer-id="customerId"
        />
        <!-- <customerAndLoanDetails
          :application-id="applicationId"
          :show-discretionary-info="false"
          :showAccommodationInfo="false"
        /> -->
      </div>

      <div v-if="currentTab === 'payment-schedules'" id="payment-schedules">
        <PaymentScheduleCategorizedByType
          :application-id="applicationId"
          :customer-id="customerId"
        />
        <TransactionDetailsTable :application-id="applicationId" />
      </div>
      <div
        v-if="currentTab === 'notes'"
        id="notes-tab-content"
        class="tab-content"
      >
        <div class="intro-y box p-5 my-5">
          <keep-alive>
            <LoanNotes :customer-id="customerId"></LoanNotes>
          </keep-alive>
        </div>
      </div>
      <FundoLmsToastAlert />
    </div>
    <div
      :class="{
        'col-span-5': isGlobalNotesDisplayed,
        'col-span-12': !isGlobalNotesDisplayed
      }"
    >
      <NoteComponentBody
        :application_id="applicationId"
        :associated_model_id="0"
        :associated_model_key="'application_details'"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "@/store";
import TransactionDetailsTable from "@/components/transactions/Main";
import { loanApplicationDetails } from "@/composables/loanApplicationDetails";
import PaymentScheduleCategorizedByType from "@/components/payment-schedules-categorized-by-type/Main.vue";
import FundoLmsToastAlert from "@/components/fundo-lms-toast-alert/Main";
import LoanNotes from "../customers/LoanNotes";
import NoteComponentToggler from "@/components/note-component-toggler/Main.vue";
import NoteComponentBody from "@/components/note-component-body/Main.vue";
import LoanApplicationCommon from "@/components/loan-application-details/common/Main";

export default defineComponent({
  components: {
    LoanApplicationCommon,
    TransactionDetailsTable,
    PaymentScheduleCategorizedByType,
    FundoLmsToastAlert,
    LoanNotes,
    NoteComponentToggler,
    NoteComponentBody
  },
  setup() {
    const store = useStore();
    const {
      currentTab,
      applicationId,
      customerId,
      isGlobalNotesDisplayed,
      onToggleIsGlobalNotesDisplayed
    } = loanApplicationDetails();

    store.commit("quickNavLinks/setShowMenu", true);

    function changeTab(tab) {
      store.commit("quickNavLinks/setShowMenu", tab === "application");
      currentTab.value = tab;
    }

    const legacyLMSLoanDetailsPageUrl = `${process.env.VUE_APP_LEGACY_ADMINPANEL_URL}/user/loandetail/${applicationId}`;

    return {
      currentTab,
      applicationId,
      customerId,
      changeTab,
      legacyLMSLoanDetailsPageUrl,
      isGlobalNotesDisplayed,
      onToggleIsGlobalNotesDisplayed
    };
  }
});
</script>

<style scoped>
.nav.nav-tabs a {
  cursor: pointer;
}
#legacy-lms-link {
  right: 1rem;
  bottom: 1rem;
  border-bottom: black solid 1px;
  position: absolute;
}
</style>
