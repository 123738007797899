import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";

const state = () => {
  return {
    applicationId: 0,
    statuses: [],
    centreLinkChecks: [],
    isBusy: false,
    type: null,
    reason: "",
    errorMessage: "",
    validationErrors: [],
    successCount: 0
  };
};

const getters = {
  statuses: state => state.statuses,
  centreLinkChecks: state => state.centreLinkChecks,
  isBusy: state => state.isBusy,
  type: state => state.type,
  reason: state => state.reason,
  errorMessage: state => state.errorMessage,
  validationErrors: state => state.validationErrors,
  successCount: state => state.successCount
};

const mutations = {
  setApplicationId(state, applicationId) {
    state.applicationId = applicationId;
  },
  setStatuses(state, statuses) {
    state.statuses = statuses;
  },
  setCentreLinkChecks(state, verification) {
    state.centreLinkChecks = verification;
  },
  setIsBusy(state, isBusy) {
    state.isBusy = isBusy;
  },
  setType(state, type) {
    state.type = type;
  },
  setReason(state, reason) {
    state.reason = reason;
  },
  setErrorMessage(state, message) {
    state.errorMessage = message;
  },
  setValidationErrors(state, validationErrors) {
    state.validationErrors = validationErrors;
  },
  clearErrors(state) {
    state.validationErrors = [];
    state.errorMessage = "";
  },
  incrementSuccessCount(state) {
    state.successCount++;
  }
};

const actions = {
  getSkipChecks: async ({ dispatch, state, commit }) => {
    commit("setIsBusy", true);
    axiosWrapperService
      .axiosGet(`/applications/${state.applicationId}/skipChecks`)
      .then(response => {
        commit("setStatuses", response.data);
        commit("setReason", "");
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("setIsBusy", false);
      });
  },
  getFrankieChecks: async ({ dispatch, state, commit }) => {
    commit("setIsBusy", true);
    axiosWrapperService
      .axiosGet(`/applications/${state.applicationId}/frankieChecks`)
      .then(response => {
        commit("setFrankieStatuses", response.data);
        commit("setReason", "");
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("setIsBusy", false);
      });
  },
  getCenterLinkCheck: async ({ dispatch, state, commit }) => {
    commit("setIsBusy", true);
    axiosWrapperService
      .axiosGet(`/applications/${state.applicationId}/centrelinkChecks`)
      .then(response => {
        commit("setCentreLinkChecks", response.data);
        commit("setReason", "");
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("setIsBusy", false);
      });
  },
  skipCheck: async ({ dispatch, state, commit }) => {
    commit("clearErrors");
    commit("setIsBusy", true);
    const payload = {
      type: state.type,
      reason: state.reason
    };

    axiosWrapperService
      .axiosPatch(`/applications/${state.applicationId}/skipCheck`, payload)
      .then(response => {
        commit("setIsBusy", false);
        commit("incrementSuccessCount");
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: `${state.type} check skipped!`,
            type: "success"
          },
          { root: true }
        );
      })
      .catch(error => {
        commit("setIsBusy", false);
        if (error.response?.data?.errors) {
          commit("setValidationErrors", error.response.data.errors);
        } else {
          commit("setErrorMessage", formatErrorMessage(error));
        }
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
