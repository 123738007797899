import { computed, ref, watch, onMounted } from "vue";
import { useStore } from "@/store";
import Swal from "sweetalert2";
import { customSwalMessageBuilder } from "@/composables/utils/customSwalMessageBuilder";
import { styleHelper } from "@/utils/styleHelper";

export const paymentScheduleTable = props => {
  const store = useStore();
  const {
    buildSwalWarning,
    buildSwalSuccess,
    buildSwalHtmlError
  } = customSwalMessageBuilder();

  const selected = ref([]);

  const isFetchingGetPayments = computed(
    () => store.state.paymentScheduleTable.isFetchingGetPayments
  );

  const cancelledSuccessMessage = computed(
    () => store.state.paymentScheduleTable.cancelledSuccessMessage
  );

  const errorMessage = computed(
    () => store.state.paymentScheduleTable.errorMessage
  );

  const visibleTransactionsShownForScheduleId = computed(
    () =>
      store.state.paymentScheduleTransactions
        .visibleTransactionsShownForScheduleId
  );

  const applicationId = props.applicationId;
  const type = props.type;

  const schedules = computed(
    () => store.state.paymentScheduleTable.schedules[type]
  );

  function getPaymentSchedules() {
    store.dispatch("paymentScheduleTable/getPaymentSchedules", {
      applicationId,
      type
    });
  }

  function showFionaLogs(id) {
    const url = `${process.env.VUE_APP_LEGACY_ADMINPANEL_URL}/repayments/timeline/${id}`;
    window.open(url, "_blank").focus();
  }

  function showCardPayments(id) {
    const url = `${process.env.VUE_APP_LEGACY_ADMINPANEL_URL}/payment/card/detail/${id}`;
    window.open(url, "_blank").focus();
  }

  function showSplitPayments(token) {
    const url = `https://go.split.cash/payment_requests/${token}?current_account_id=efa19685-46d5-47b4-b3fc-2cf7adb1961b`;
    window.open(url, "_blank").focus();
  }

  function showFionaPayments(id) {
    const url = `${process.env.VUE_APP_LEGACY_ADMINPANEL_URL}/payment/fiona/detail/${id}`;
    window.open(url, "_blank").focus();
  }

  function showIntegraPayments(id) {
    const url = `${process.env.VUE_APP_LEGACY_ADMINPANEL_URL}/payment/integrapay-ddr/detail/${id}`;
    window.open(url, "_blank").focus();
  }

  function showCreatePromiseModal(scheduleId) {
    const url = `/paymentSchedules/${scheduleId}/createPromise`;
    store.commit("paymentScheduleDateNoteForm/clearErrors", scheduleId);
    store.commit("paymentScheduleDateNoteForm/setUrl", url);
    store.commit("paymentScheduleDateNoteForm/setType", "Promise");
    cash("#schedule-date-note-form-modal").modal("show");
  }

  function showReschedulePaymentModal(scheduleId) {
    const url = `/paymentSchedules/${scheduleId}/reschedule`;
    store.commit("paymentScheduleDateNoteForm/clearErrors", scheduleId);
    store.commit("paymentScheduleDateNoteForm/setUrl", url);
    store.commit("paymentScheduleDateNoteForm/setType", "Reschedule");
    cash("#schedule-date-note-form-modal").modal("show");
  }

  function showRetryPaymentModal(scheduleId) {
    const url = `/paymentSchedules/${scheduleId}/retry`;
    store.commit("paymentScheduleDateNoteForm/clearErrors", scheduleId);
    store.commit("paymentScheduleDateNoteForm/setUrl", url);
    store.commit("paymentScheduleDateNoteForm/setType", "Retry");
    cash("#schedule-date-note-form-modal").modal("show");
  }

  function showEditAmountModal(scheduleId, setAmount) {
    store.commit("editAmountForm/clearErrors");
    store.commit("editAmountForm/setScheduleId", scheduleId);
    store.commit("editAmountForm/setAmount", setAmount);
    cash("#edit-amount-form-modal").modal("show");
  }

  function showTransactions(scheduleId) {
    if (scheduleId === visibleTransactionsShownForScheduleId.value) {
      store.commit(
        "paymentScheduleTransactions/setVisibleTransactionsShownForScheduleId",
        0
      );
    } else {
      store.commit(
        "paymentScheduleTransactions/setVisibleTransactionsShownForScheduleId",
        scheduleId
      );
    }
  }

  onMounted(() => {
    getPaymentSchedules();
  });

  watch(
    () => cancelledSuccessMessage.value,
    message => {
      if (message && message !== "") {
        getPaymentSchedules();
        Swal.fire(buildSwalSuccess(message)).then(confirmed => {
          if (confirmed.isConfirmed) {
            store.commit("paymentScheduleTable/clearMessages");
          }
        });
      }
    }
  );

  watch(
    () => errorMessage.value,
    message => {
      if (message) {
        Swal.fire(buildSwalHtmlError(message)).then(confirmed => {
          if (confirmed.isConfirmed) {
            store.commit("paymentScheduleTable/clearMessages");
          }
        });
      }
    }
  );

  function onCancelPaymentSchedule(id) {
    cancelPayments([id]);
  }

  function onCancelPaymentSchedules() {
    cancelPayments(selected.value);
  }

  function cancelPayments(ids) {
    Swal.fire(
      buildSwalWarning(
        "Are you sure you want to cancel the payment schedules?",
        true
      )
    ).then(confirmed => {
      if (confirmed.isConfirmed) {
        store.commit("paymentScheduleTable/setApplicationId", applicationId);
        store.dispatch("paymentScheduleTable/cancelMultipleSchedules", ids);
      }
    });
  }

  function isDisabledForCancel(status) {
    return ["pending", "paid", "cleared", "cancelled"].includes(
      status.toLowerCase()
    );
  }

  function selectAll(el) {
    if (el.currentTarget.checked) {
      selected.value = schedules.value
        .filter(item => !isDisabledForCancel(item.status))
        .map(item => item.id);
    } else {
      selected.value = [];
    }
  }
  function getBadgeClass(status) {
    return styleHelper.getPaymentScheduleStatusBadgeClass(status);
  }

  return {
    schedules,
    selected,
    onCancelPaymentSchedule,
    onCancelPaymentSchedules,
    isDisabledForCancel,
    selectAll,
    isFetchingGetPayments,
    visibleTransactionsShownForScheduleId,
    showFionaLogs,
    showCreatePromiseModal,
    showReschedulePaymentModal,
    showRetryPaymentModal,
    showEditAmountModal,
    showTransactions,
    getBadgeClass,
    showCardPayments,
    showSplitPayments,
    showIntegraPayments,
    showFionaPayments
  };
};
