import { createStore } from "vuex";
import main from "./main";
import auth from "./auth";
import admin from "./admin";
import editAdmin from "./edit-admin";
import assessmentBucket from "./assessment-bucket";
import lnClosureReviewsBucket from "./ln-closure-reviews-bucket";
import dashboard from "./dashboard";
import sideMenu from "./side-menu";
import simpleMenu from "./simple-menu";
import topMenu from "./top-menu";
import timelineNotesViewer from "./timeline-notes-viewer";
import lnAppDocReq from "./ln-app-doc-req";
import lnAssessorDelegBrd from "./ln-assessor-deleg-brd";
import lnClosureReviewDelegBrd from "./ln-closure-review-deleg-brd";
import lnApproverDelegBrd from "./ln-approver-deleg-brd";
import lnConverterDelegBrd from "./ln-converter-deleg-brd";
import lnFollowUpDelegationBoard from "./ln-follow-up-delegation-board";
import payoutPreCheckFailureLogs from "./payout-pre-check-failure-logs";
import refundPreCheckFailureLogs from "./refund-pre-check-failure-logs";
import payerBankAccount from "./payer-bank-accounts";
import disbursalPayout from "./disbursal-payouts";
import loanFollowUps from "./loan-follow-ups";
import refundRequests from "./refund-requests";
import lnAppDtsRouter from "./ln-app-dts-router";
import loanAssessmentInfos from "./loan-assessment-infos";
import loanQuality from "./loan-quality";
import loanFollowUpInfos from "./loan-follow-up-infos";
import globalToastMessage from "./global-toast-message";
import quickNavLinks from "./quick-nav-links";
import customerDetails from "./customer-details";
import customerAndLoanDetails from "./components/loan-application-details/customer-and-loan-details";
import loanRebuttals from "./components/ln-rebuttals";
import paymentCards from "./components/loan-applications/payment-cards";
import personalLoans from "./components/loan-applications/personal-loans";
import loanTags from "./components/loan-applications/loan-tags";
import lnGenericFollowUps from "./components/loan-applications/loan-generic-follow-ups";
import lnApprovalPreRequisites from "./components/loan-applications/ln-approval-pre-requisites";
import lnSysAutoDecisions from "./components/loan-applications/ln-sys-auto-decisons";
import addGenericFollowUp from "./add-generic-followup";
import addFollowUp from "./components/ln-follow-ups/add-followups";
import addCardVerfFollowUp from "./components/ln-follow-ups/add-card-verf-followup";
import bankDetails from "./components/bank-accounts/bank-details";
import transactions from "./components/transactions/transactions";
import createMultipleSchedules from "./components/payment-schedules-categorized-by-type/create-multiple-schedules";
import paymentScheduleTable from "./components/payment-schedules-categorized-by-type/payment-schedule-table";
import paymentScheduleDateNoteForm from "./components/payment-schedules-categorized-by-type/payment-schedule-actions/date-note-form";
import editAmountForm from "./components/payment-schedules-categorized-by-type/payment-schedule-actions/edit-amount-form";
import paymentScheduleTransactions from "./components/payment-schedules-categorized-by-type/payment-schedule-transactions";
import paymentScheduleLoanActions from "./components/payment-schedules-categorized-by-type/loan-actions/loan-actions";
import skipChecks from "./components/loan-application-details/skip-checks";
import frankieChecks from "./components/loan-application-details/frankie-checks";
import systemChecks from "./components/loan-application-details/system-checks";
import manualVerification from "./components/loan-application-details/manual-verification";
import unsignedLoans from "./unsigned-loans";
import creditCheck from "./components/loan-application-details/credit-check";
import lnAppUploadDocs from "@/store/ln-app-upload-docs";
import loanFlags from "./components/loan-applications/loan-flags";
import criticalAlert from "./components/loan-applications/critical-alert";
import cms from "./components/content-manager/cms";

const store = createStore({
  modules: {
    main,
    auth,
    admin,
    assessmentBucket,
    lnClosureReviewsBucket,
    dashboard,
    payoutPreCheckFailureLogs,
    refundPreCheckFailureLogs,
    payerBankAccount,
    disbursalPayout,
    loanFollowUps,
    addFollowUp,
    addGenericFollowUp,
    addCardVerfFollowUp,
    refundRequests,
    loanAssessmentInfos,
    lnAppDtsRouter,
    lnAssessorDelegBrd,
    lnClosureReviewDelegBrd,
    lnApproverDelegBrd,
    lnConverterDelegBrd,
    lnFollowUpDelegationBoard,
    loanFollowUpInfos,
    sideMenu,
    simpleMenu,
    globalToastMessage,
    quickNavLinks,
    timelineNotesViewer,
    lnAppDocReq,
    loanRebuttals,
    loanQuality,
    paymentCards,
    loanTags,
    lnGenericFollowUps,
    lnApprovalPreRequisites,
    lnSysAutoDecisions,
    topMenu,
    customerDetails,
    customerAndLoanDetails,
    bankDetails,
    editAdmin,
    transactions,
    createMultipleSchedules,
    paymentScheduleTable,
    paymentScheduleDateNoteForm,
    editAmountForm,
    paymentScheduleTransactions,
    paymentScheduleLoanActions,
    unsignedLoans,
    skipChecks,
    frankieChecks,
    systemChecks,
    manualVerification,
    creditCheck,
    personalLoans,
    lnAppUploadDocs,
    loanFlags,
    criticalAlert,
    cms
  }
});

export function useStore() {
  return store;
}

export default store;
