import { onMounted, computed, ref } from "vue";
import { useStore } from "@/store";

export const getLoanNotes = props => {
  const store = useStore();
  const adminUser = computed(() => store.state.auth.loggedUser);
  const customerId = ref(props.customerId);
  const loanApplicationId = ref(props.loanApplicationId);

  const loanNotes = computed(() => store.state.customerDetails.loanNotes);
  const fetchingLoanNotes = computed(
    () => store.state.customerDetails.fetchingLoanNotes
  );

  function getLoanNotes(customerId, loanApplicationId) {
    store.dispatch("customerDetails/getLoanNotes", {
      customerId,
      loanApplicationId
    });
  }

  onMounted(() => {
    getLoanNotes(customerId.value, loanApplicationId.value);
  });

  return {
    adminUser,
    loanNotes,
    fetchingLoanNotes
  };
};
