<template>
  <div>
    <div class="intro-y box p-5 pt-2">
      <CustomersComponent />
    </div>

    <div class="intro-y box p-5 pt-2 mt-4">
      <LoanApplicationsComponent />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import CustomersComponent from "../customers/Main";
import LoanApplicationsComponent from "../loan-applications/Main";

export default defineComponent({
  components: {
    CustomersComponent,
    LoanApplicationsComponent
  }
});
</script>
