import { axiosWrapperService } from "../services/auth/axios-wrapper-service";
import { formatErrorMessage } from "../utils/messageFormatter";

const state = () => {
  return {
    adminList: [],
    adminRoles: [],
    assignedAdminRoles: [],
    adminListUpdateCounter: 0,
    adminListErrorMessage: "",
    adminRoleErrorMessage: "",
    adminListSuccessMessage: "",
    addAdminSuccessMessage: "",
    addAdminErrorMessage: ""
  };
};

// getters
const getters = {
  adminList: state => state.adminList,
  adminRoles: state => state.adminRoles,
  assignedAdminRoles: state => state.assignedAdminRoles,
  adminListUpdateCounter: state => state.adminListUpdateCounter,
  adminListErrorMessage: state => state.adminListErrorMessage,
  adminRoleErrorMessage: state => state.adminRoleErrorMessage,
  addAdminSuccessMessage: state => state.addAdminSuccessMessage,
  addAdminErrorMessage: state => state.addAdminErrorMessage
};

// actions
const actions = {
  addAdmin: async (
    { commit },
    { fullname, email, password, password_confirmation, roles }
  ) => {
    axiosWrapperService
      .axiosPost("/registerUser", {
        fullname,
        email,
        password,
        password_confirmation,
        roles
      })
      .then(() => {
        commit("setAddAdminSuccessMessage", "Successfully added Admin !");
      })
      .catch(error => {
        commit("setAddAdminErrorMessage", formatErrorMessage(error, true));
      });
  },
  getAdmins: async ({ commit }) => {
    axiosWrapperService
      .axiosGet("/getAdmins")
      .then(response => {
        commit("setAdminList", response.data.data);
      })
      .catch(error => {
        commit("setAdminListErrorMessage", formatErrorMessage(error, true));
      });
  },
  getAdminRoles: async ({ commit }) => {
    axiosWrapperService
      .axiosGet("/getAdminRoles")
      .then(response => {
        commit("setAdminRoles", response.data.data);
      })
      .catch(() => {
        commit(
          "setAdminRoleErrorMessage",
          formatErrorMessage(
            "Something went wrong while fetching list of Admin Roles.",
            true
          )
        );
      });
  }
};

// mutations
const mutations = {
  setAdminList(state, list) {
    state.adminList = list;
  },
  setAdminRoles(state, adminRoles) {
    state.adminRoles = adminRoles;
  },
  pushNewAdminRoles(state, adminRole) {
    state.assignedAdminRoles.push(adminRole);
  },
  popNewAdminRoles(state, adminRole) {
    state.assignedAdminRoles.pop(adminRole);
  },
  setAdminListSuccessMessage(state, adminListSuccessMessage) {
    state.adminListSuccessMessage = adminListSuccessMessage;
  },
  setAddAdminSuccessMessage(state, addAdminSuccessMessage) {
    state.addAdminSuccessMessage = addAdminSuccessMessage;
  },
  setAdminListErrorMessage(state, adminListErrorMessage) {
    state.adminListErrorMessage = adminListErrorMessage;
  },
  setAdminRoleErrorMessage(state, adminRoleErrorMessage) {
    state.adminRoleErrorMessage = adminRoleErrorMessage;
  },
  setAddAdminErrorMessage(state, addAdminErrorMessage) {
    state.addAdminErrorMessage = addAdminErrorMessage;
  },
  incrementUpdateCount(state) {
    state.disbursalPayoutListUpdateCounter++;
    state.fullPageLoading = false;
  },
  clearMessages(state) {
    state.adminListErrorMessage = "";
    state.adminListSuccessMessage = "";
    state.addAdminErrorMessage = "";
    state.addAdminSuccessMessage = "";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
