<template>
  <div v-if="includeModals">
    <CreateMultipleSchedules :application-id="applicationId" />
    <PaymentScheduleDateNoteForm />
    <EditAmountForm />
  </div>

  <div v-if="!isFetchingGetPayments[type]" class="intro-y box p-5 mt-5">
    <div class="overflow-x-auto">
      <table class="table table-report payment-schedule-table">
        <thead>
          <tr class="text-center">
            <th>
              <input type="checkbox" name="checkall" @click="selectAll" />
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              #
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              Reference
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              Pay Date
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              Total Amount
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              Status
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              Paid Amount
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              Paid Date
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              Fee Amount
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              Payment Method Action
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              Action
            </th>
          </tr>
        </thead>
        <tbody class="text-center">
          <template :key="schedule.id" v-for="schedule in schedules">
            <tr class="border-b">
              <td>
                <div v-if="isDisabledForCancel(schedule.status)">
                  <input type="checkbox" name="" disabled />
                </div>
                <div v-else>
                  <input
                    type="checkbox"
                    :value="schedule.id"
                    v-model="selected"
                  />
                </div>
              </td>
              <td>
                <a
                  href="javascript:;"
                  class="underline"
                  @click="showTransactions(schedule.id)"
                  >{{ schedule.id }}</a
                >
              </td>
              <td>
                <div class="">
                  {{
                    schedule.sub_type ?? schedule.type ?? schedule.reference_id
                  }}
                </div>
                <span v-if="schedule.bank_account_id > 0" class="ref-badge"
                  >PV</span
                >
              </td>
              <td>
                {{ $h.formatDate(schedule.pay_date) }}
              </td>
              <td>
                {{ schedule.total_amount }}
              </td>
              <td class="text-center">
                <div :class="getBadgeClass(schedule.status)">
                  {{ schedule.status }}
                </div>
              </td>
              <td>
                {{ schedule.paid_amount }}
              </td>
              <td>
                {{
                  schedule.paid_date ? $h.formatDate(schedule.paid_date) : "-"
                }}
              </td>
              <td>
                {{ schedule.fee_amount }}
              </td>
              <td>
                <a
                  class="p-1 font-bold"
                  href="javascript:;"
                  @click="showCardPayments(schedule.id)"
                >
                  CARD
                </a>
                <a
                  v-if="schedule.split_token"
                  class="p-1 font-bold"
                  href="javascript:;"
                  @click="showSplitPayments(schedule.split_token.token)"
                  >SPLIT</a
                >
                <a
                  v-if="schedule.fiona_token"
                  class="p-1 font-bold"
                  href="javascript:;"
                  @click="showFionaPayments(schedule.id)"
                >
                  FIONA
                </a>
                <a
                  v-if="schedule.integra_pay_token"
                  class="p-1 font-bold"
                  href="javascript:;"
                  @click="showIntegraPayments(schedule.id)"
                  >IPAY</a
                >
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a
                    v-if="schedule.menu_actions.includes('view_fiona_logs')"
                    class="flex items-ce dnter mr-3"
                    @click="showFionaLogs(schedule.id)"
                    href="javascript:;"
                  >
                    <SearchIcon class="w-4 h-4 mr-1" />
                    Fiona_Logs
                  </a>
                  <a
                    v-if="schedule.menu_actions.includes('edit_amount')"
                    class="flex items-ce dnter mr-3"
                    @click="
                      showEditAmountModal(schedule.id, schedule.total_amount)
                    "
                    href="javascript:;"
                  >
                    <EditIcon class="w-4 h-4 mr-1" />
                    Edit
                  </a>
                  <a
                    v-if="schedule.menu_actions.includes('reschedule_payment')"
                    class="flex items-ce dnter mr-3"
                    @click="showReschedulePaymentModal(schedule.id)"
                    href="javascript:;"
                  >
                    <ArrowRightIcon class="w-4 h-4 mr-1" />
                    Reschedule
                  </a>
                  <a
                    v-if="schedule.menu_actions.includes('retry_payment')"
                    class="flex items-ce dnter mr-3"
                    @click="showRetryPaymentModal(schedule.id)"
                    href="javascript:;"
                  >
                    <RepeatIcon class="w-4 h-4 mr-1" />
                    Retry
                  </a>
                  <a
                    v-if="schedule.menu_actions.includes('create_promise')"
                    class="flex items-ce dnter mr-3"
                    href="javascript:;"
                    @click="showCreatePromiseModal(schedule.id)"
                  >
                    <PlusSquareIcon class="w-4 h-4 mr-1" /> Create_Promise
                  </a>
                  <a
                    v-if="schedule.menu_actions.includes('cancel_payment')"
                    class="flex items-center text-theme-6"
                    @click="onCancelPaymentSchedule(schedule.id)"
                    href="javascript:;"
                  >
                    <XIcon class="w-4 h-4 mr-1" />
                    Cancel
                  </a>
                </div>
              </td>
            </tr>
            <tr v-if="visibleTransactionsShownForScheduleId === schedule.id">
              <td colspan="12" class="transaction-row">
                <PaymentScheduleTransactionsTable :schedule-id="schedule.id" />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <button
        v-if="selected.length > 0"
        class="mt-4 btn btn-danger"
        @click="onCancelPaymentSchedules"
      >
        Cancel Payments
      </button>
    </div>
  </div>
  <FetchingRecordDivTemplate v-else />
</template>

<script>
import { defineComponent } from "vue";
import { paymentScheduleTable } from "@/composables/components/payment-schedules-categorized-by-type/paymentScheduleTable";
import FetchingRecordDivTemplate from "@/components/fetching-record-div-template/Main";
import CreateMultipleSchedules from "@/components/payment-schedules-categorized-by-type/CreateMultipleSchedules";
import PaymentScheduleDateNoteForm from "@/components/payment-schedules-categorized-by-type/payment-schedule-actions/PaymentScheduleDateNoteForm";
import EditAmountForm from "@/components/payment-schedules-categorized-by-type/payment-schedule-actions/EditAmountForm";
import PaymentScheduleTransactionsTable from "@/components/payment-schedules-categorized-by-type/PaymentScheduleTransactionsTable";

export default defineComponent({
  components: {
    EditAmountForm,
    FetchingRecordDivTemplate,
    CreateMultipleSchedules,
    PaymentScheduleDateNoteForm,
    PaymentScheduleTransactionsTable
  },
  props: {
    type: {
      type: String,
      require: true
    },
    applicationId: {
      type: Number,
      require: false
    },
    includeModals: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  setup(props) {
    const {
      schedules,
      selected,
      onCancelPaymentSchedule,
      onCancelPaymentSchedules,
      isDisabledForCancel,
      selectAll,
      isFetchingGetPayments,
      visibleTransactionsShownForScheduleId,
      showFionaLogs,
      showCreatePromiseModal,
      showReschedulePaymentModal,
      showRetryPaymentModal,
      showEditAmountModal,
      showTransactions,
      getBadgeClass,
      showCardPayments,
      showSplitPayments,
      showIntegraPayments,
      showFionaPayments
    } = paymentScheduleTable(props);

    return {
      schedules,
      selected,
      onCancelPaymentSchedule,
      onCancelPaymentSchedules,
      isDisabledForCancel,
      selectAll,
      isFetchingGetPayments,
      visibleTransactionsShownForScheduleId,
      showFionaLogs,
      showCreatePromiseModal,
      showReschedulePaymentModal,
      showEditAmountModal,
      showRetryPaymentModal,
      showTransactions,
      getBadgeClass,
      showCardPayments,
      showSplitPayments,
      showIntegraPayments,
      showFionaPayments
    };
  }
});
</script>

<style scoped>
.transaction-row {
  background-color: lightgray !important;
}
.payment-schedule-table {
  border-spacing: 0 1px !important;
}
.ref-badge {
  color: #fff;
  background-color: #36b9cc;
  text-transform: uppercase;
  font-weight: 700;
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
</style>
