<template>
  <!-- BEGIN: No record template-->
  <div class="col-span-12">
    <div class="box " :class="[introClass, mt]">
      <div
        class="alert alert-secondary show"
        role="alert"
        :style="`background-color:${backgroundColor}`"
      >
        <div class="loader">
          {{ text }}
          <LoadingIcon icon="oval" color="green" class="w-4 h-4 ml-2" />
        </div>
      </div>
    </div>
  </div>
  <!-- END: No record template-->
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    text: {
      type: String,
      require: false,
      default: "Fetching Records! ..."
    },
    backgroundColor: {
      type: String,
      require: false,
      default: "white"
    },
    animateIntro: {
      type: Boolean,
      require: false,
      default: true
    },
    marginTop: {
      type: String,
      require: false,
      default: '5'
    }
  },
  setup(props) {
    const introClass = props.animateIntro ? "intro-y" : "";

    const mt = props.marginTop ? `mt-${props.marginTop}` : "mt-5";

    return { introClass, mt };
  }
});
</script>
<style scoped>
.loader {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>
