<template>
  <!-- BEGIN: Modal Content -->
  <div
    id="create-multiple-schedules-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base mr-auto">
            Create Multiple Schedules
          </h2>
        </div>
        <!-- END: Modal Header -->
        <!-- BEGIN: Modal Body -->
        <div
          v-if="errorMessage !== ''"
          class="alert alert-danger-soft show flex items-center mb-2"
          role="alert"
        >
          <AlertOctagonIcon class="w-6 h-6 mr-2" />
          {{ errorMessage }}
        </div>
        <div class="modal-body grid grid-cols-12">
          <div class="col-span-12 grid grid-cols-2">
            <div class="mt-2">
              <label class="form-label"> Starting Date:</label>
              <div class="relative w-full mx-auto">
                <input
                  type="date"
                  v-model="form.date"
                  class="form-control w-11/12"
                />
              </div>
              <div v-if="validationErrors.date" class="p-1 text-red-600">
                {{ validationErrors.date[0] }}
              </div>
            </div>
            <div class="mt-2">
              <label class="form-label"> Number of Repayments</label>
              <div class="relative w-full mx-auto">
                <input
                  v-model="form.no_of_repayments"
                  type="number"
                  class="form-control w-11/12"
                />
              </div>
              <div
                v-if="validationErrors.no_of_repayments"
                class="p-1 text-red-600"
              >
                {{ validationErrors.no_of_repayments[0] }}
              </div>
            </div>
          </div>
          <div class="col-span-12 grid grid-cols-2">
            <div class="mt-2">
              <label class="form-label"> Repayment Amount:</label>
              <div class="relative w-full mx-auto">
                <input
                  v-model="form.repayment_amount"
                  type="number"
                  class="form-control w-11/12"
                />
              </div>
              <div
                v-if="validationErrors.repayment_amount"
                class="p-1 text-red-600"
              >
                {{ validationErrors.repayment_amount[0] }}
              </div>
            </div>
            <div class="mt-2">
              <label class="form-label"> Payment Type</label>
              <select v-model="form.type" class="form-select w-11/12">
                <option value="RSC">Reschedule</option>
                <option value="PTP">Promises</option>
              </select>
              <div v-if="validationErrors.type" class="p-1 text-red-600">
                {{ validationErrors.type[0] }}
              </div>
            </div>
          </div>
          <div class="col-span-12 grid grid-cols-2">
            <div class="mt-2">
              <label class="form-label"> Repayment Frequency:</label>
              <select
                v-model="form.repayment_frequency"
                class="form-select w-11/12"
              >
                <option value="Weekly">Weekly</option>
                <option value="Fortnightly">Fortnightly</option>
                <option value="Monthly">Monthly</option>
              </select>
              <div
                v-if="validationErrors.repayment_frequency"
                class="p-1 text-red-600"
              >
                {{ validationErrors.repayment_frequency[0] }}
              </div>
            </div>
          </div>
        </div>
        <!-- END: Modal Body -->
        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer text-right">
          <button
            type="button"
            data-dismiss="modal"
            class="btn btn-dark-soft w-20 mr-1"
          >
            Cancel
          </button>
          <button
            v-if="!isProcessing"
            type="button"
            class="btn btn-success w-40"
            @click="submitCreateMultipleSchedules(form)"
          >
            Submit
          </button>
          <button v-else class="btn btn-success w-40">
            Processing
            <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { defineComponent } from "vue";
import { createMultipleSchedules } from "@/composables/components/payment-schedules-categorized-by-type/createMultipleSchedules";

export default defineComponent({
  props: {
    applicationId: {
      type: Number,
      require: true
    }
  },
  setup(props) {
    const {
      form,
      submitCreateMultipleSchedules,
      validationErrors,
      errorMessage,
      isProcessing
    } = createMultipleSchedules(props);

    return {
      form,
      submitCreateMultipleSchedules,
      validationErrors,
      errorMessage,
      isProcessing
    };
  }
});
</script>

<style scoped></style>
