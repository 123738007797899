<template>
  <div>
    <DarkModeSwitcher />
    <!--    Hide QuickLinks-->
    <!--    <QuickNavLinksBody-->
    <!--      v-if="-->
    <!--        route.path.split('/')[1] === 'loan-assessment-detail' ||-->
    <!--          route.path.split('/')[1] === 'loan-req-dec-assessment-detail' ||-->
    <!--          route.path.split('/')[3] === 'loan-application'-->
    <!--      "-->
    <!--    />-->
    <!--    <QuickNavLinks-->
    <!--      v-if="route.path.split('/')[1] === 'loan-assessment-detail'"-->
    <!--    />-->
    <MobileMenu v-if="!sideMenuCollapsed" />
    <div class="flex">
      <!-- BEGIN: Side Menu -->
      <nav
        v-if="!sideMenuCollapsed"
        :class="{ 'side-nav--simple': isGlobalNotesDisplayed }"
        class="side-nav"
      >
        <!-- BEGIN: Logo -->
        <router-link
          :to="{ name: 'side-menu-dashboard-overview-1' }"
          class="intro-x flex items-center pl-5 pt-4"
          tag="a"
        >
          <span class="hidden xl:block text-white text-lg">
            <img
              v-if="!isGlobalNotesDisplayed"
              alt="Icewall Tailwind HTML Admin Template"
              class="w-22 h-12"
              src="@/assets/images/logo.png"
            />
            <img
              v-else
              alt="Icewall Tailwind HTML Admin Template"
              class="w-16 h-12"
              src="@/assets/images/play-img.png"
            />
            <!--Fundo<span class="font-medium"> LMS</span>-->
          </span>
        </router-link>
        <!-- END: Logo -->
        <div class="side-nav__devider my-6"></div>
        <ul>
          <!-- BEGIN: First Child -->
          <template v-for="(menu, menuKey) in formattedMenu">
            <li
              v-if="menu == 'devider'"
              :key="menu + menuKey"
              class="side-nav__devider my-6"
            ></li>
            <li
              v-else-if="
                !menu.subMenu || (menu.subMenu && menu.subMenu.length > 0)
              "
              :key="menu + menuKey"
            >
              <SideMenuTooltip
                :class="{
                  'side-menu--active': menu.active,
                  'side-menu--open': menu.activeDropdown
                }"
                :content="menu.title"
                :href="
                  menu.subMenu
                    ? 'javascript:;'
                    : router.resolve({ name: menu.pageName }).path
                "
                class="side-menu"
                tag="a"
                @click="linkTo(menu, router, $event)"
              >
                <div class="side-menu__icon">
                  <component :is="menu.icon" />
                </div>
                <div class="side-menu__title">
                  {{ menu.title }}
                  <div
                    v-if="menu.subMenu"
                    :class="{ 'transform rotate-180': menu.activeDropdown }"
                    class="side-menu__sub-icon"
                  >
                    <ChevronDownIcon />
                  </div>
                </div>
              </SideMenuTooltip>
              <!-- BEGIN: Second Child -->
              <transition @enter="enter" @leave="leave">
                <ul v-if="menu.subMenu && menu.activeDropdown">
                  <li
                    v-for="(subMenu, subMenuKey) in menu.subMenu"
                    :key="subMenuKey"
                  >
                    <SideMenuTooltip
                      :class="{ 'side-menu--active': subMenu.active }"
                      :content="subMenu.title"
                      :href="
                        subMenu.subMenu
                          ? 'javascript:;'
                          : router.resolve({ name: subMenu.pageName }).path
                      "
                      class="side-menu"
                      tag="a"
                      @click="linkTo(subMenu, router, $event)"
                    >
                      <div class="side-menu__icon">
                        <ActivityIcon />
                      </div>
                      <div class="side-menu__title">
                        {{ subMenu.title }}
                        <div
                          v-if="subMenu.subMenu"
                          :class="{
                            'transform rotate-180': subMenu.activeDropdown
                          }"
                          class="side-menu__sub-icon"
                        >
                          <ChevronDownIcon />
                        </div>
                      </div>
                    </SideMenuTooltip>
                    <!-- BEGIN: Third Child -->
                    <transition @enter="enter" @leave="leave">
                      <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                        <li
                          v-for="(lastSubMenu,
                          lastSubMenuKey) in subMenu.subMenu"
                          :key="lastSubMenuKey"
                        >
                          <SideMenuTooltip
                            :class="{
                              'side-menu--active': lastSubMenu.active
                            }"
                            :content="lastSubMenu.title"
                            :href="
                              lastSubMenu.subMenu
                                ? 'javascript:;'
                                : router.resolve({
                                    name: lastSubMenu.pageName
                                  }).path
                            "
                            class="side-menu"
                            tag="a"
                            @click="linkTo(lastSubMenu, router, $event)"
                          >
                            <div class="side-menu__icon">
                              <ZapIcon />
                            </div>
                            <div class="side-menu__title">
                              {{ lastSubMenu.title }}
                            </div>
                          </SideMenuTooltip>
                        </li>
                      </ul>
                    </transition>
                    <!-- END: Third Child -->
                  </li>
                </ul>
              </transition>
              <!-- END: Second Child -->
            </li>
          </template>
          <!-- END: First Child -->
        </ul>
      </nav>
      <!-- END: Side Menu -->
      <!-- BEGIN: Content -->
      <div class="content">
        <TopBar :style="[isGlobalNotesDisplayed ? { width: '55%' } : '']" />
        <router-view :key="$route.fullPath" />
      </div>
      <!-- END: Content -->
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { helper as $h } from "@/utils/helper";
import TopBar from "@/components/top-bar/Main.vue";
import MobileMenu from "@/components/mobile-menu/Main.vue";
import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
import SideMenuTooltip from "@/components/side-menu-tooltip/Main.vue";
import QuickNavLinks from "@/components/quick-nav-links/Main.vue";
import QuickNavLinksBody from "@/components/quick-nav-links-body/Main.vue";
import { enter, leave, linkTo, nestedMenu } from "./index";

export default defineComponent({
  components: {
    TopBar,
    MobileMenu,
    DarkModeSwitcher,
    SideMenuTooltip,
    QuickNavLinks,
    QuickNavLinksBody
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const sideMenuCollapsed = computed(
      () => store.state.main.sideMenuCollapsed
    );
    const isQuickNavExpanded = computed(
      () => store.state.quickNavLinks.isQuickNavExpanded
    );
    const isGlobalNotesDisplayed = computed(
      () => store.state.timelineNotesViewer.isGlobalNotesDisplayed
    );
    const formattedMenu = ref([]);
    const sideMenu = computed(() =>
      nestedMenu(store.state.sideMenu.menu, route)
    );

    const loggedUser = computed(() => store.state.auth.loggedUser);

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = filterMenu(sideMenu);
      }
    );

    function filterMenu(menu) {
      let finalMenu = $h.toRaw(menu.value);
      if (loggedUser.value) {
        let userRoles = loggedUser.value.roles;
        if (
          !userRoles.includes("refund-approver") &&
          !userRoles.includes("reporter-admin")
        ) {
          finalMenu.splice(
            finalMenu.findIndex(e => e.pageName === "refunds"),
            1
          );
        }
        if (
          !userRoles.includes("disburser-admin") &&
          !userRoles.includes("reporter-admin")
        ) {
          finalMenu.splice(
            finalMenu.findIndex(e => e.pageName === "payouts"),
            1
          );
          if (
            !userRoles.includes("lms-admin") &&
            !userRoles.includes("reporter-admin") &&
            !userRoles.includes("ln-app-follow-up-officer") &&
            !userRoles.includes("lending-agent") &&
            !userRoles.includes("lending-manager") &&
            !userRoles.includes("lending-converter")
          ) {
            finalMenu.splice(
              finalMenu.findIndex(e => e.pageName === "unsigned-loans"),
              1
            );
            finalMenu.splice(
              finalMenu.findIndex(e => e.pageName === "contact-failed-loans"),
              1
            );
          }
        }
        if (!userRoles.includes("lms-admin")) {
          finalMenu.splice(
            finalMenu.findIndex(e => e.pageName === "bank-account-list"),
            1
          );
        }

        if (!userRoles.includes("content-moderator")) {
          finalMenu.splice(
            finalMenu.findIndex(e => e.pageName === "content-management"),
            1
          );
        }

        if (
          !userRoles.includes("lms-admin") &&
          !userRoles.includes("lending-agents-manager")
        ) {
          finalMenu.splice(
            finalMenu.findIndex(e => e.pageName === "admins"),
            1
          );
        }
        if (
          !userRoles.includes("lms-admin") &&
          !userRoles.includes("reporter-admin")
        ) {
          finalMenu.splice(
            finalMenu.findIndex(e => e.pageName === "customers"),
            1
          );
          finalMenu.splice(
            finalMenu.findIndex(e => e.pageName === "loan-applications"),
            1
          );
        }
        if (!userRoles.includes("ln-app-follow-up-officer")) {
          finalMenu.splice(
            finalMenu.findIndex(e => e.pageName === "loan-follow-up-dashboard"),
            1
          );
        }
        if (!userRoles.includes("lending-manager")) {
          finalMenu.forEach(finalMenu => {
            if (finalMenu.subMenu !== undefined) {
              finalMenu.subMenu = finalMenu.subMenu.filter(
                subMenu => subMenu.pageName !== "approval-lending-dashboard"
              );
            }
          });
        }

        if (
          !userRoles.includes("lending-manager") &&
          !userRoles.includes("tech-support")
        ) {
          finalMenu.splice(
            finalMenu.findIndex(e => e.pageName === "delegation-dashboard"),
            1
          );
        }
        if (!userRoles.includes("lending-agent")) {
          finalMenu.forEach(finalMenu => {
            if (finalMenu.subMenu !== undefined)
              finalMenu.subMenu = finalMenu.subMenu.filter(
                subMenu => subMenu.pageName !== "assessment-lending-dashboard"
              );
          });
        }
        if (!userRoles.includes("lending-converter")) {
          finalMenu.forEach(finalMenu => {
            if (finalMenu.subMenu !== undefined)
              finalMenu.subMenu = finalMenu.subMenu.filter(
                subMenu => subMenu.pageName !== "conversion-lending-dashboard"
              );
          });
        }
      }

      finalMenu.forEach(menu => {
        if (menu.subMenu !== undefined && menu.subMenu.length < 1)
          finalMenu = finalMenu.filter(fm => fm.pageName !== menu.pageName);
      });

      return finalMenu;
    }

    onMounted(() => {
      cash("body")
        .removeClass("error-page")
        .removeClass("login")
        .addClass("main");
      formattedMenu.value = filterMenu(sideMenu);
    });

    return {
      loggedUser,
      sideMenuCollapsed,
      isGlobalNotesDisplayed,
      isQuickNavExpanded,
      formattedMenu,
      route,
      router,
      linkTo,
      enter,
      leave
    };
  }
});
</script>
