<template>
  <div>
    <div
      :class="[
        { 'bg-theme-32': isActive },
        `loan-${getActiveApplicationQualityClass()}`
      ]"
      class="flex mt-1 p-4 box cursor-pointer section-header"
      @click="setNavActiveSection('bank-details-title')"
    >
      <h2
        id="bank-details-title"
        class="font-medium text-theme-7 text-base w-full"
      >
        BANK DETAILS
      </h2>
      <ChevronDownIcon :class="{ 'transform rotate-180': !isActive }" />
    </div>
    <div v-if="isActive" id="bank-dts-section" class="grid grid-cols-12 gap-6">
      <template v-if="!fetchingApplicantBankAccounts">
        <!-- BEGIN: Striped Rows -->
        <div class="col-span-12">
          <!-- BEGIN: Striped Rows -->
          <div class="box">
            <div
              class="
                flex flex-row
                items-center
                p-5
                border-b border-gray-200
              "
            >
              <h2 class="font-medium text-theme-3 text-base mr-auto">
                Customer Banking Information
              </h2>
              <div
                v-if="latestBankStatement"
                class="mr-1 border-l pr-2 pl-2"
                style="font-size: smaller"
              >
                <span class="font-bold">Recently updated:</span>
                <span class="ml-2">{{
                  latestBankStatement.created_at_human_format
                }}</span>
              </div>
            </div>
            <div id="cust-banking-info-table" class="p-5">
              <div class="preview">
                <div class="overflow-x-auto">
                  <table class="table">
                    <thead>
                      <tr>
                        <th
                          class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                        >
                          Account Name
                        </th>
                        <th
                          class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                        >
                          Account Holder
                        </th>
                        <th
                          class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                        >
                          Account Number
                        </th>
                        <th
                          class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                        >
                          Account Type
                        </th>
                        <th
                          class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                        >
                          Income Account
                        </th>
                        <th
                          class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(bankAccount,
                        bankAccountKey) in bankAccountOfApplicantLinkedToAssessment"
                        :key="bankAccountKey"
                        :class="{
                          'bg-gray-200 dark:bg-dark-1': bankAccountKey % 2 < 1
                        }"
                      >
                        <td
                          class="border-b dark:border-dark-5 grid grid-rows-2"
                        >
                          <span>{{ bankAccount.account_name }}</span>
                          <span v-if="bankAccount.bank?.length"
                            >({{ bankAccount.bank }})</span
                          >
                        </td>
                        <td class="border-b dark:border-dark-5">
                          {{ bankAccount.account_holder }}
                        </td>
                        <td class="border-b dark:border-dark-5">
                          {{ bankAccount.bsb }}
                          {{ bankAccount.account_number }}
                        </td>
                        <td class="border-b dark:border-dark-5">
                          {{ bankAccount.type }}
                        </td>
                        <td
                          :class="{
                            flex:
                              bankAccount.verification &&
                              bankAccount.verification.system_verified &&
                              showSystemVerifiedLabel
                          }"
                          class="border-b dark:border-dark-5 text-left"
                        >
                          <label
                            v-if="bankAccount.debit_account"
                            :class="{
                              'pt-2':
                                bankAccount.verification &&
                                bankAccount.verification.system_verified &&
                                showSystemVerifiedLabel
                            }"
                            class="form-label text-theme-20"
                          >
                            Yes
                          </label>
                          <label
                            v-else
                            :class="{
                              'pt-2':
                                bankAccount.verification &&
                                bankAccount.verification.system_verified &&
                                showSystemVerifiedLabel
                            }"
                            class="form-label text-theme-6"
                          >
                            No
                          </label>
                          <template
                            v-if="
                              bankAccount.verification &&
                                bankAccount.verification.system_verified &&
                                showSystemVerifiedLabel
                            "
                          >
                            <LoanApplicationVerificationLink
                              :cover="true"
                              :details="bankAccount.verification"
                              :operation="{
                                handler: null,
                                payload: {
                                  type: 'bank-account',
                                  value: bankAccount.id
                                }
                              }"
                              :small="true"
                            />
                          </template>
                        </td>
                        <td class="border-b dark:border-dark-5">
                          <template v-if="bankAccount.debit_account">
                            <LoanApplicationVerificationLink
                              :details="bankAccount.verification"
                              :operation="{
                                handler: null,
                                payload: {
                                  type: 'bank-account',
                                  value: bankAccount.id
                                }
                              }"
                              :small="true"
                            />
                          </template>
                          <button
                            v-else
                            class="btn bg-theme-3 w-32 text-white"
                            @click="
                              onSubmitToSetActiveBankingIncomeAccount(
                                bankAccount.id
                              )
                            "
                          >
                            Enable
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="flex flex-row justify-end p-1 mr-10">
                <span
                  id="ln-assess-bank-statment"
                  class="text-blue-700 text-sm underline float-right cursor-pointer"
                  @click="showBankStatements"
                >
                  Statements
                </span>
              </div>
            </div>
            <!-- END: Striped Rows -->
          </div>
        </div>
      </template>
      <FetchingRecordDivTemplate v-else marginTop="0" />
    </div>
    <!-- BEGIN: Modal Content -->
    <div
      id="bank-statements-modal"
      aria-hidden="true"
      class="modal"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              Bank Statements
            </h2>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="modal-body grid grid-cols-12">
            <div class="col-span-12 mt-2">
              <div
                v-if="allBankReports.length"
                class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
              >
                <div class="preview">
                  <div class="overflow-x-auto">
                    <table class="table">
                      <thead>
                        <tr>
                          <th
                            class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                          >
                            Bank Name
                          </th>
                          <th
                            class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                          >
                            Fetched On
                          </th>
                          <th
                            class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                            text-center
                          "
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(report, reportKey) in allBankReports"
                          :key="reportKey"
                          :class="{
                            'bg-gray-200 dark:bg-dark-1': reportKey % 2 < 1
                          }"
                        >
                          <td class="border-b dark:border-dark-5">
                            {{ report.bank }}
                          </td>
                          <td class="border-b dark:border-dark-5">
                            {{ report.created_date }}
                          </td>
                          <td
                            class="border-b dark:border-dark-5 flex justify-between"
                          >
                            <a
                              :href="report.view"
                              class="btn bg-theme-3 w-24 text-white cursor-pointer"
                              target="_blank"
                            >
                              <svg
                                class="bi bi-eye-fill"
                                fill="currentColor"
                                height="16"
                                viewBox="0 0 16 16"
                                width="16"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"
                                ></path>
                                <path
                                  d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
                                ></path>
                              </svg>
                              <span class="text-white ml-2">View</span>
                            </a>
                            <a
                              :href="report.download"
                              class="btn bg-theme-3 w-28 text-white cursor-pointer"
                              target="_blank"
                            >
                              <svg
                                class="bi bi-cloud-arrow-down-fill"
                                fill="currentColor"
                                height="16"
                                viewBox="0 0 16 16"
                                width="16"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z"
                                />
                              </svg>
                              <span class="text-white ml-2">Download</span>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <noRecordDivTemplate v-else marginTop="2" />
            </div>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer text-right">
            <button
              class="btn btn-dark-soft w-20 mr-1"
              data-dismiss="modal"
              type="button"
              @onclick="hideBankStatements"
            >
              Close
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
    <!-- END: Modal Content -->
  </div>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import noRecordDivTemplate from "@/components/no-record-div-template/Main.vue";
import FetchingRecordDivTemplate from "@/components/fetching-record-div-template/Main";
import LoanApplicationVerificationLink from "@/components/loan-application-details/loan-application-verification-link/Main";
import cash from "cash-dom/dist/cash";
import { loanQuality } from "@/composables/loanQuality";

export default defineComponent({
  components: {
    noRecordDivTemplate,
    FetchingRecordDivTemplate,
    LoanApplicationVerificationLink
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    setNavActiveSection: {
      type: Function,
      require: true,
      default: null
    },
    bankAccountOfApplicantLinkedToAssessment: {
      type: Array,
      required: true
    },
    getBankAccountsOfApplicantLinkedToLoanAssessment: {
      type: Function,
      required: true
    },
    latestBankStatement: {
      type: Object,
      default: null
    },
    allBankReports: {
      type: Array,
      required: true
    },
    getAllBankStatements: {
      type: Function,
      required: true
    },
    fetchingApplicantBankAccounts: {
      type: Boolean,
      required: true
    },
    onSubmitToSetActiveBankingIncomeAccount: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const showSystemVerifiedLabel = ref(true);

    props.getBankAccountsOfApplicantLinkedToLoanAssessment();

    function showBankStatements() {
      props.getAllBankStatements();
    }

    function hideBankStatements() {
      cash("#bank-statements-modal").modal("hide");
    }

    watch(
      () => props.bankAccountOfApplicantLinkedToAssessment,
      accounts => {
        let debit_account = accounts.find(acc => acc.debit_account);
        if (debit_account) {
          let verification = debit_account.verification;
          if (verification.last_modified_by || verification.system_verified) {
            showSystemVerifiedLabel.value = false;
          }
        }
      }
    );
    const { getActiveApplicationQualityClass } = loanQuality();

    return {
      showBankStatements,
      hideBankStatements,
      showSystemVerifiedLabel,
      getActiveApplicationQualityClass
    };
  }
});
</script>
<style scoped>
.modal .modal-dialog {
  min-width: 640px;
}
</style>
