<template>
  <div id="upload-doc-modal" aria-hidden="true" class="modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base mr-auto">Upload Documents</h2>
        </div>
        <div
          v-if="uploadErrorMessage !== ''"
          class="alert alert-danger-soft show flex items-center mb-2"
          role="alert"
        >
          <AlertOctagonIcon class="w-6 h-6 mr-2" />
          {{ uploadErrorMessage }}
          <button
            class="btn-close"
            type="button"
            @click="clearUploadDocMessages"
          >
            <XIcon class="w-4 h-4" />
          </button>
        </div>
        <!-- END: Modal Header -->
        <!-- BEGIN: Modal Body -->
        <div class="modal-body grid grid-cols-12">
          <div class="col-span-12">
            <label class="form-label"> Document Type:</label>
            <select
              v-model="docType"
              aria-label=".form-select-sm example"
              class="form-select form-select-sm mt-2"
            >
              <option value="">None</option>
              <option
                v-for="(docType, docTypeIndex) in Handler.docReqList"
                :key="docTypeIndex"
                :value="docType.slug"
              >
                {{ docType.name }}
              </option>
            </select>
          </div>
          <div class="col-span-12">
            <!-- BEGIN: Multiple File Upload -->
            <div class="intro-y box mt-5">
              <div id="multiple-file-upload" class="p-5">
                <div class="preview">
                  <input
                    id="upload-files-input"
                    ref="uploadFilesInput"
                    multiple
                    type="file"
                  />
                </div>
              </div>
            </div>
            <!-- END: Multiple File Upload -->
          </div>
        </div>
        <!-- END: Modal Body -->
        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer text-right">
          <button
            class="btn btn-dark-soft w-20 mr-1"
            data-dismiss="modal"
            type="button"
          >
            Cancel
          </button>
          <button
            v-if="!busy"
            class="btn btn-success w-40"
            type="button"
            @click="submit"
          >
            Submit
          </button>
          <button v-else class="btn btn-success mr-1 mb-2">
            Processing
            <LoadingIcon class="w-4 h-4 ml-2" color="white" icon="oval" />
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "@/store";
import Swal from "sweetalert2";
import { customSwalMessageBuilder } from "@/composables/utils/customSwalMessageBuilder";
import Handler from "@/utils/DocumentRequests/Handler";

export default defineComponent({
  components: {},
  props: {
    applicationId: {
      type: Number,
      required: false
    }
  },
  setup(props) {
    const store = useStore();

    //Type Dropdown
    const docType = ref("");

    //Files Select
    const uploadFilesInput = ref(null);

    const clearUploadDocMessages = function() {
      store.commit("lnAppUploadDocs/clearMessages");
    };

    const submit = function() {
      const uploadedFiles = uploadFilesInput.value.files;

      if (uploadedFiles.length < 1 || uploadedFiles.length > 4) {
        alert(
          "At least 1 file must be uploaded and total maximum allowed number of files uploads is 4."
        );
        return;
      }

      let formData = new FormData();

      formData.append("doc_type", docType.value);

      for (let i = 0; i < uploadedFiles.length; i++) {
        const file = uploadedFiles[i];

        if (
          !["image/png", "image/jpeg", "image/jpg", "application/pdf"].includes(
            file.type
          )
        ) {
          alert(
            "At least one of the uploaded file is of unsupported format. The supported file formats are pdf and images of type png,jpeg/jpg."
          );
          return;
        }

        if (file.size > 52428800) {
          alert(
            "At least one of the uploaded file is over size limit of 50MB."
          );
          return;
        }

        formData.append(`files[${i}]`, file);
      }

      const payload = {
        applicationId: props.applicationId,
        formData: formData
      };

      store.dispatch("lnAppUploadDocs/submitFilesToUpload", payload);
    };

    const busy = computed(() => store.getters["lnAppUploadDocs/busy"]);
    const uploadErrorMessage = computed(
      () => store.getters["lnAppUploadDocs/uploadErrorMessage"]
    );

    const uploadSuccessMessage = computed(
      () => store.getters["lnAppUploadDocs/uploadSuccessMessage"]
    );

    const { buildSwalSuccess } = customSwalMessageBuilder();

    watch(
      () => uploadSuccessMessage.value,
      message => {
        if (message) {
          uploadFilesInput.value.value = "";
          cash("#upload-doc-modal").modal("hide");
          Swal.fire(buildSwalSuccess(message));
          store.dispatch("lnAppDocReq/getDocumentRequestsOfLoan", {
            applicationId: props.applicationId
          });
        }
      }
    );

    return {
      uploadErrorMessage,
      busy,
      docType,
      clearUploadDocMessages,
      submit,
      uploadFilesInput,
      Handler
    };
  }
});
</script>

<style scoped></style>
