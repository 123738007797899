<template>
  <!-- BEGIN: Modal Content -->
  <div
    id="credit-check-report-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base mr-auto">
            Credit Check Report
          </h2>
        </div>
        <!-- END: Modal Header -->
        <!-- BEGIN: Modal Body -->
        <div class="modal-body grid grid-cols-12" v-if="creditCheckData">
          <div class="col-span-12 mt-2">
            <h3>Credit Score</h3>
            <div class="mt-2">
              <div class="">{{ `Score: ${creditCheckData.risk_score}` }}</div>
              <div class="">
                {{ `Score Date: ${creditCheckData.risk_score_date}` }}
              </div>
            </div>
          </div>
          <div class="col-span-12 mt-2">
            <h3>Personal Information</h3>
            <div class="">
              <table class="table">
                <thead>
                  <tr>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Full Name
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Date of Birth
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Address
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="hover:bg-gray-200">
                    <td>
                      {{
                        `${creditCheckData.more_info.IndividualDetails.personName.FirstName} ${creditCheckData.more_info.IndividualDetails.personName.Surname}`
                      }}
                    </td>
                    <td>
                      {{
                        new Date(
                          creditCheckData.more_info.DateOfBirth.dob
                        ).toLocaleDateString()
                      }}
                    </td>
                    <td>
                      {{
                        `${creditCheckData.more_info.Address.address.StreetNumber} ${creditCheckData.more_info.Address.address.StreetName} ${creditCheckData.more_info.Address.address.SuburbTown} ${creditCheckData.more_info.Address.address.State}`
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- Court Records-->
          <div class="col-span-12 mt-2">
            <h3>Court Records</h3>
            <div class="" v-if="creditCheckData.more_info.CourtRecords">
              <table class="table">
                <thead>
                  <tr>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      #
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Proceeding Type
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Date
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Court
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Proceedings Date
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Amount
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Plantiff Name
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(courtRecord, index) in creditCheckData.more_info
                      .CourtRecords"
                    :key="index + 1"
                    class="hover:bg-gray-200"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{ courtRecord.CourtProceedingType }}
                    </td>
                    <td>
                      {{
                        new Date(
                          courtRecord.DateLodged ?? courtRecord.DateDeclared
                        ).toLocaleDateString()
                      }}
                    </td>

                    <td>{{ courtRecord.Court }}</td>
                    <td v-if="courtRecord.ProceedingsDate">
                      {{
                        new Date(
                          courtRecord.ProceedingsDate
                        ).toLocaleDateString()
                      }}
                    </td>
                    <td v-else>N/A</td>

                    <td>
                      {{
                        courtRecord.Amount
                          ? `$${parseFloat(courtRecord.Amount).toFixed(2)}`
                          : "N/A"
                      }}
                    </td>
                    <td>{{ courtRecord.PlantiffName ?? "N/A" }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!--          //DEFAULTS-->
          <div class="col-span-12 mt-2">
            <h3>Defaults</h3>

            <div v-if="creditCheckData.has_default">
              <table class="table">
                <thead>
                  <tr>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      #
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Date
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Current Amount
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Current Credit Provider
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Original Amount
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Original Credit Provider
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(_default, index) in creditCheckData.more_info
                      .Defaults"
                    :key="index + 1"
                    class="hover:bg-gray-200"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{
                        new Date(_default.DateOfDefault).toLocaleDateString()
                      }}
                    </td>
                    <td>
                      ${{ parseFloat(_default.CurrentAmount).toFixed(2) }}
                    </td>
                    <td>{{ _default.CurrentCreditProvider }}</td>
                    <td>
                      ${{ parseFloat(_default.OriginalAmount).toFixed(2) }}
                    </td>
                    <td>{{ _default.OriginalCreditProvider }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class=" mt-2">
              There are no any defaults found for this customer.
            </div>
          </div>
          <!--          //Enquiries-->
          <div class="col-span-12 mt-2">
            <h3>Enquiries</h3>
            <div class="" v-if="creditCheckData.more_info.Enquiries">
              <table class="table">
                <thead>
                  <tr>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      #
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Date
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Organization
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(enquiry, index) in creditCheckData.more_info
                      .Enquiries"
                    :key="index + 1"
                    class="hover:bg-gray-200"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ new Date(enquiry.Date).toLocaleDateString() }}</td>
                    <td>{{ enquiry.Organisation }}</td>
                    <td>${{ enquiry.Amount }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- END: Modal Body -->
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { computed, defineComponent } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  setup() {
    const store = useStore();

    const creditCheckData = computed(
      () => store.getters["creditCheck/creditCheck"]
    );

    return {
      creditCheckData
    };
  }
});
</script>

<style scoped>
h3 {
  font-weight: 500;
  font-size: medium;
  margin-bottom: 10px;
  margin-top: 10px;
}
</style>
