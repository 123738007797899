import { useRoute, useRouter } from "vue-router";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useStore } from "@/store";
import Swal from "sweetalert2";
import { customSwalMessageBuilder } from "@/composables/utils/customSwalMessageBuilder";
import { lendingRoleTypeAuthenticators } from "@/composables/utils/lendings/lendingRoleTypeAuthenticators";
import Toastify from "toastify-js";
import {
  getCurrentAssignedUserId,
  getLendingAssessmentAccessBucket,
  getLendingAssessmentAccessType,
  getLoanAssessmentStage,
  getRoleAccordingToStage,
  getSelfAssignableRoleType
} from "@/composables/utils/lendings/LendingAssessmentAccessTypeHelper";
import cash from "cash-dom/dist/cash";
import moment from "moment";

export const loanAssessmentDetail = (loadOnCreated = true) => {
  const adminUser = computed(() => store.state.auth.loggedUser);

  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  if (loadOnCreated) {
    store.dispatch("loanQuality/resetQualityAndScore", { root: true });
    store.commit("loanAssessmentInfos/setApplicationId", 0); // reset ApplicationId Everytime Components gets mounted
    store.commit(
      "loanAssessmentInfos/setLoanAssessmentUuid",
      route.params.ln_assessment_uuid
    );
    store.dispatch("loanAssessmentInfos/getLoanAssessment", adminUser.value);
    store.dispatch("loanAssessmentInfos/getAllApplicationTransitionReasons");
  }

  const { buildSwalSuccess } = customSwalMessageBuilder();

  const {
    checkIfLender,
    checkIfWithdrawableOrDeclinable,
    checkIfcanBeRequestedToDecline,
    checkIfReAssignable
  } = lendingRoleTypeAuthenticators();

  const finalizationActions = {
    decline: "decline",
    withdraw: "withdraw",
    complete: "complete",
    approve: "approve",
    finalizePreConversionCheck: "finalize-pre-conversion-check",
    reDoAssessment: "re-do-assessment",
    requestToDecline: "request-to-decline"
  };

  function onSelfAssignAssessment(uuid) {
    store.dispatch("loanAssessmentInfos/singleAssignAssessmentToLender", {
      role_type: getLendingAssessmentAccessType(loanAssessment.value),
      assessment_uuid: uuid,
      admin_id: adminUser.value.id
    });
  }

  function changeAssessmentOwner(owner, uuid) {
    store.dispatch("loanAssessmentInfos/changeAssessmentOwner", {
      role_type: targetRole.value,
      assessment_uuid: uuid,
      owner_id: owner
    });
  }

  function adjustReactivationDateAccordingToReasonDaysLimit(status, event) {
    event = parseInt(event);
    if (
      status === "declined" &&
      status in applicationTransitionReasonTypes.value
    ) {
      const record = applicationTransitionReasonTypes.value[status].find(
        type => type.id === event
      );
      let days = 60;
      //Record has min days limit added
      if (record && parseInt(record.min_days_limit) > 0) {
        //Setting value to record
        days = record.min_days_limit;
      }
      //Setting default value
      return setReactivateDateForDaysInFuture(days);
    }
  }

  function setReactivateDateForDaysInFuture(days) {
    assessmentFinalizationConfirmationDataObject.reapplication_activation_date = moment()
      .add(days, "days")
      .utcOffset(0, false)
      .format("YYYY-MM-DD");
  }

  const loanAssessmentOwners = computed(
    () => store.state.loanAssessmentInfos.loanAssessmentOwners
  );

  const toggleUrl = computed(() => store.state.loanAssessmentInfos.toggleUrl);

  const activeDescriptions = computed(
    () => store.state.loanAssessmentInfos.activeDescriptions
  );

  const withdrawable = computed(
    () => store.state.loanAssessmentInfos.withdrawable
  );
  const canBeRequestedToDecline = computed(
    () => store.state.loanAssessmentInfos.canBeRequestedToDecline
  );
  const reAssignable = computed(
    () => store.state.loanAssessmentInfos.reAssignable
  );
  const showFinalizingButtons = ref(false);

  const isApplicationBlacklisted = computed(
    () => store.state.customerAndLoanDetails.blackListed
  );

  const applicationId = computed(
    () => store.state.loanAssessmentInfos.applicationId
  );

  const customerId = computed(() => store.state.loanAssessmentInfos.customerId);

  const activeInputId = computed(
    () => store.state.loanAssessmentInfos.activeInputId
  );

  const isGlobalNotesDisplayed = computed(
    () => store.state.timelineNotesViewer.isGlobalNotesDisplayed
  );

  function onToggleIsGlobalNotesDisplayed() {
    store.commit("timelineNotesViewer/toggleIsGlobalNotesDisplayed");
  }

  const busy = computed(() => store.state.loanAssessmentInfos.busy);
  const path = computed(() => route.path);
  const assessorPageType = computed(
    () => store.state.loanAssessmentInfos.assessmentState
  );
  const loanAssessmentUuid = computed(
    () => store.state.loanAssessmentInfos.loanAssessmentUuid
  );

  const personalLoanStatuses = ref([
    { name: "Ongoing", value: "ongoing" },
    { name: "Closing Soon", value: "closing_soon" },
    { name: "Closed", value: "closed" },
    { name: "Graced", value: "graced" }
  ]);

  const aggregatorPersonalLoanDetails = computed(
    () => store.state.loanAssessmentInfos.aggregatorPersonalLoanDetails
  );
  const aggregatorLoanDetailsTransitLobby = computed(
    () => store.state.loanAssessmentInfos.aggregatorLoanDetailsTransitLobby
  );
  const aggregatorIncomeExpenseDetailsTransitLobby = computed(
    () =>
      store.state.loanAssessmentInfos.aggregatorIncomeExpenseDetailsTransitLobby
  );
  const loanAssessment = computed(
    () => store.state.loanAssessmentInfos.loanAssessment
  );
  const customerName = computed(
    () => store.state.loanAssessmentInfos.customerName
  );

  const kycWithAdditionalAttributes = computed(
    () => store.state.loanAssessmentInfos.kycWithAdditionalAttributes
  );
  const pastLoansOfApplicantLinkedToAssessment = computed(
    () => store.state.loanAssessmentInfos.pastLoansOfApplicantLinkedToAssessment
  );
  const fetchingApplicantBankAccounts = computed(
    () => store.state.loanAssessmentInfos.fetchingApplicantBankAccounts
  );

  const fetchingKYC = computed(
    () => store.state.customerAndLoanDetails.fetchingKYC
  );

  const bankAccountOfApplicantLinkedToAssessment = computed(
    () =>
      store.state.loanAssessmentInfos.bankAccountOfApplicantLinkedToAssessment
  );

  const latestBankStatement = computed(
    () => store.state.loanAssessmentInfos.latestBankStatement
  );
  const applicationTransitionReasonTypes = computed(
    () => store.state.loanAssessmentInfos.applicationTransitionReasonTypes
  );
  const fetchingIncomeExpensesDetails = computed(
    () => store.state.loanAssessmentInfos.fetchingIncomeExpensesDetails
  );

  const fetchingLendingDetails = computed(
    () => store.state.loanAssessmentInfos.fetchingLendingDetails
  );

  const updatingPersonalLoansDetails = computed(
    () => store.state.loanAssessmentInfos.updatingPersonalLoansDetails
  );

  const updatingIncomeExpenseDetails = computed(
    () => store.state.loanAssessmentInfos.updatingIncomeExpenseDetails
  );

  const updatingLendingDetails = computed(
    () => store.state.loanAssessmentInfos.updatingLendingDetails
  );

  const finalizationFormValidationMessage = ref("");

  const aggregatorLendingDetailsValueContainer = ref({});
  const aggregatorIncomeExpenseDetailsValueContainer = ref({});

  const targetRole = ref("");

  const assessmentOwner = ref(0);

  function aggregatorLendingDetailsValueBuilder() {
    for (let aggLnDtKey of Object.keys(
      aggregatorLoanDetailsTransitLobby.value
    )) {
      for (let [valDatumKey, valDatum] of Object.entries(
        aggregatorLoanDetailsTransitLobby.value[aggLnDtKey].data
      )) {
        aggregatorLendingDetailsValueContainer.value[valDatumKey] =
          valDatum["data-type"] === "integer" ||
          valDatum["data-type"] === "float"
            ? parseFloat(valDatum["value"])
            : valDatum["value"];
      }
    }
    aggregatorLendingDetailsValueContainer.value["admin_id"] =
      adminUser.value.id;
  }

  function aggregatorIncomeExpenseDetailsValueBuilder() {
    for (let aggLnDtKey of Object.keys(
      aggregatorIncomeExpenseDetailsTransitLobby.value
    )) {
      for (let [valDatumKey, valDatum] of Object.entries(
        aggregatorIncomeExpenseDetailsTransitLobby.value[aggLnDtKey].data
      )) {
        aggregatorIncomeExpenseDetailsValueContainer.value[valDatumKey] =
          valDatum["data-type"] === "integer" ||
          valDatum["data-type"] === "float"
            ? parseFloat(valDatum["assessment-analysis-value"])
            : valDatum["assessment-analysis-value"];
      }
    }
    aggregatorIncomeExpenseDetailsValueContainer.value["admin_id"] =
      adminUser.value.id;
  }

  function getAggregatorIncomeExpensesDetailsForLoanAssessment() {
    store.dispatch(
      "loanAssessmentInfos/getAggregatorIncomeExpensesDetailsForLoanAssessment",
      { showLoader: true }
    );
  }

  function getAggregatorLendingDetailsForLoanAssessment(adminUser) {
    store.dispatch(
      "loanAssessmentInfos/getAggregatorLendingDetailsForLoanAssessment",
      { showLoader: true }
    );
  }

  function onAggregatorLendingDetailsSubmit(batchSubmit = true, id = "") {
    aggregatorLendingDetailsValueBuilder();
    // Input loosing focus due to loader
    // store.commit("main/setFullPageLoading");
    if (!batchSubmit) {
      return;
    }
    store.dispatch("loanAssessmentInfos/submitAggregatorLendingDetails", {
      postData: aggregatorLendingDetailsValueContainer.value,
      id
    });
  }

  function onAggregatorIncomeExpenseDetailsSubmit(batchSubmit = true, id = "") {
    aggregatorIncomeExpenseDetailsValueBuilder();
    // Input loosing focus due to loader
    // store.commit("main/setFullPageLoading");
    if (!batchSubmit) {
      return;
    }

    store.dispatch("loanAssessmentInfos/updateAggregatorIncomeExpenses", {
      postData: aggregatorIncomeExpenseDetailsValueContainer.value,
      id: id
    });
  }

  function onSubmitToSetActiveBankingIncomeAccount(bankId) {
    store.commit("main/setFullPageLoading");
    store.dispatch(
      "loanAssessmentInfos/updateLegacyLmsApplicationBankingIncomeAccount",
      { bank_account_id: bankId, admin_id: adminUser.value.id }
    );
  }

  const assesmentBucketUpdateReqCounter = computed(
    () => store.state.loanAssessmentInfos.assesmentBucketUpdateReqCounter
  );

  // listen for request for bucket update
  watch(
    () => assesmentBucketUpdateReqCounter.value,
    () => {
      cash("#assessment-finalization-confirmation-modal").modal("hide");
      store.commit("assessmentBucket/incrementUpdateCount");
      if (path.value.split("/")[1] === "loan-assessment-detail")
        router.push("/lending-dashboard/" + assessorPageType.value);
      else router.push("/req-decline-dashboard/" + assessorPageType.value);
    }
  );

  const loanAssessmentFetchCounter = computed(
    () => store.state.loanAssessmentInfos.loanAssessmentFetchCounter
  );

  const bankReportsFetchCounter = computed(
    () => store.state.loanAssessmentInfos.bankReportsFetchCounter
  );

  watch(
    () => bankReportsFetchCounter.value,
    records => cash("#bank-statements-modal").modal("show")
  );

  // listen for refetching of this loan assessment record.
  watch(
    () => loanAssessmentFetchCounter.value,
    () => {
      setActionables();
      enabledPersonalLoanStatusObject.application_id = applicationId.value;
      enabledPersonalLoanStatusObject.admin_id = adminUser.value.id;
      targetRole.value = getLendingAssessmentAccessType(loanAssessment.value);
      if (targetRole.value.length) {
        store.dispatch(
          "loanAssessmentInfos/getLendersByRoleForOwnerSelectionList",
          targetRole.value
        );
      }
    }
  );

  watch(
    () => loanAssessment.value,
    () => {
      assessmentOwner.value = getCurrentAssignedUserId(loanAssessment.value);
      setVisbilityOfFinalizingbuttonAsPerAdminRole();
      store.dispatch("loanAssessmentInfos/getDocumentRequestsOfLoan");
      if (toggleUrl.value) {
        store.commit("loanAssessmentInfos/setToggleUrl");
        router.push(
          getAssessmentDetailRouterNameForDashboard(
            getLendingAssessmentAccessType(loanAssessment.value),
            loanAssessmentUuid.value
          )
        );
      }
    }
  );

  const addLoanFollowUpCounter = computed(
    () => store.getters["addFollowUp/addLoanFollowUpCounter"]
  );

  const systemCheckVerified = computed(
    () => store.getters["systemChecks/isVerified"]
  );

  const frankieCheckVerified = computed(
    () => store.getters["frankieChecks/isVerified"]
  );

  const customerNameCheckVerified = computed(
    () => store.getters["loanAssessmentInfos/isCustomerNameVerified"]
  );

  watch(
    () => addLoanFollowUpCounter.value,
    () => {
      //Logic to redirect user to associated page after follow up added
      if (addLoanFollowUpCounter.value)
        router.push(getDashboardRouteForAccess());
    }
  );

  function getDashboardRouteForAccess() {
    return `/lending-dashboard/${getLendingAssessmentAccessType(
      loanAssessment.value
    )}`;
  }

  function getAssessmentDetailRouterNameForDashboard(type, uuid) {
    return `/loan-assessment-detail/${type}/${uuid}`;
  }

  function setActionables() {
    store.commit(
      "loanAssessmentInfos/setWithdrawable",
      checkIfWithdrawableOrDeclinable(loanAssessment.value.status)
    );
    store.commit(
      "loanAssessmentInfos/setCanBeRequestedToDecline",
      checkIfcanBeRequestedToDecline(loanAssessment.value.status)
    );
    store.commit(
      "loanAssessmentInfos/setReAssignable",
      checkIfReAssignable(loanAssessment.value.status)
    );
  }

  function getKycAttributeValueByName(name) {
    let attrValue = "";
    if (
      kycWithAdditionalAttributes.value.basicAttributes
        .additional_attributes !== undefined
    ) {
      kycWithAdditionalAttributes.value.basicAttributes.additional_attributes.forEach(
        function(attrItem) {
          if (name === attrItem.attr) {
            attrValue = attrItem.value;
          }
        }
      );
    }

    if (attrValue === "") return "-";

    return attrValue;
  }

  function clearLoanAssessmentDetailsMessages() {
    store.commit("loanAssessmentInfos/clearLoanAssessmentDetailMessages");
  }

  function validateAssessmentFinalizationForm(finalizationAction) {
    let errorMessage = "";
    if (
      finalizationAction === finalizationActions.decline &&
      parseInt(
        assessmentFinalizationConfirmationDataObject.transition_reason_type
      ) < 1
    ) {
      errorMessage += "Transition Type must be selected.";
    }

    if (errorMessage !== "") {
      finalizationFormValidationMessage.value = errorMessage;
      return false;
    }

    return true;
  }

  function validateApplicationBeforeConversionFinalization() {
    //Checking if all names related verifications are complete
    if (
      frankieCheckVerified.value &&
      systemCheckVerified.value &&
      customerNameCheckVerified.value
    ) {
      return true;
    }
    finalizationFormValidationMessage.value = "Please complete";
    finalizationFormValidationMessage.value += !frankieCheckVerified.value
      ? " Bank Statement Name Verification"
      : "";
    finalizationFormValidationMessage.value += !systemCheckVerified.value
      ? " System Name Verification"
      : "";
    finalizationFormValidationMessage.value += !customerNameCheckVerified.value
      ? " Customer Name Verification"
      : "";

    finalizationFormValidationMessage.value += " to proceed further!";
    return false;
  }

  function onDeclineApplication() {
    if (validateAssessmentFinalizationForm(finalizationActions.decline)) {
      store.commit("main/setFullPageLoading");
      cash("#assessment-finalization-confirmation-modal").modal("hide");
      store.dispatch(
        "loanAssessmentInfos/declineApplication",
        assessmentFinalizationConfirmationDataObject
      );
    }
  }

  function onCompleteAssesment() {
    if (validateAssessmentFinalizationForm(finalizationActions.complete)) {
      store.commit("main/setFullPageLoading");
      cash("#assessment-finalization-confirmation-modal").modal("hide");
      store.dispatch(
        "loanAssessmentInfos/completeAssesment",
        assessmentFinalizationConfirmationDataObject
      );
    }
  }

  function onApproveApplication() {
    if (validateAssessmentFinalizationForm(finalizationActions.approve)) {
      store.commit("main/setFullPageLoading");
      cash("#assessment-finalization-confirmation-modal").modal("hide");
      store.dispatch("loanAssessmentInfos/approveApplication", {
        lnAssessmentFinalizationObj: assessmentFinalizationConfirmationDataObject,
        applicationId: applicationId.value
      });
    }
  }

  // function onFailPreConversionCheck() {
  //   if (validateAssessmentFinalizationForm()) {
  //     store.commit("main/setFullPageLoading");
  //     cash("#assessment-finalization-confirmation-modal").modal("hide");
  //     store.dispatch(
  //       "loanAssessmentInfos/failPreConversionCheck",
  //       assessmentFinalizationConfirmationDataObject
  //     );
  //   }
  // }
  function onFinalizePreConversionCheck() {
    if (
      validateApplicationBeforeConversionFinalization() &&
      validateAssessmentFinalizationForm(
        finalizationActions.finalizePreConversionCheck
      )
    ) {
      store.commit("main/setFullPageLoading");
      cash("#assessment-finalization-confirmation-modal").modal("hide");
      store.dispatch("loanAssessmentInfos/finalizePreConversionCheck", {
        lnAssessmentFinalizationObj: assessmentFinalizationConfirmationDataObject,
        applicationId: applicationId.value
      });
    }
  }

  function triggerSubmitActionAsPerAssessmentStatus() {
    if (
      ["New", "Assigned", "In Progress"].includes(loanAssessment.value.status)
    ) {
      onCompleteAssesment();
    } else if (
      [
        "Completed",
        "In Review",
        "Request Decline",
        "In Decline Review"
      ].includes(loanAssessment.value.status)
    ) {
      onApproveApplication();
    } else if (
      ["Approved", "Pre Conversion Check", "Pre Conversion Finalized"].includes(
        loanAssessment.value.status
      )
    ) {
      onFinalizePreConversionCheck();
    }
  }

  function onRedoAssessmentReqAction() {
    if (
      validateAssessmentFinalizationForm(finalizationActions.reDoAssessment)
    ) {
      store.commit("main/setFullPageLoading");
      cash("#assessment-finalization-confirmation-modal").modal("hide");
      store.dispatch(
        "loanAssessmentInfos/reAssignAssessment",
        assessmentFinalizationConfirmationDataObject
      );
    }
  }

  function onWithdrawAssessmentAction() {
    if (validateAssessmentFinalizationForm(finalizationActions.withdraw)) {
      store.commit("main/setFullPageLoading");
      cash("#assessment-finalization-confirmation-modal").modal("hide");
      store.dispatch(
        "loanAssessmentInfos/withdrawLoanApplication",
        assessmentFinalizationConfirmationDataObject
      );
    }
  }

  function onReqForAssessmentDeclineAction() {
    if (
      validateAssessmentFinalizationForm(finalizationActions.requestToDecline)
    ) {
      store.commit("main/setFullPageLoading");
      cash("#assessment-finalization-confirmation-modal").modal("hide");
      store.dispatch(
        "loanAssessmentInfos/requestToDeclineOnLoanApplication",
        assessmentFinalizationConfirmationDataObject
      );
    }
  }

  function triggerDismissActionAsPerAdminRole() {
    if (checkIfLender()) {
      onDeclineApplication();
    }
  }

  function setVisbilityOfFinalizingbuttonAsPerAdminRole() {
    // Allow visibility of finalization buttons all the time.
    showFinalizingButtons.value = true;
  }

  function getAssesmentSubmitLabelAsPerAssessmentStatus(loanAssessment) {
    if (["New", "Assigned", "In Progress"].includes(loanAssessment.status)) {
      return "Complete Assessment";
    } else if (
      [
        "Completed",
        "In Review",
        "Request Decline",
        "In Decline Review"
      ].includes(loanAssessment.status)
    ) {
      return "Approve Application";
    } else if (
      ["Approved", "Pre Conversion Check", "Pre Conversion Finalized"].includes(
        loanAssessment.status
      )
    ) {
      return "Convert";
    }
  }

  function getAssesmentDismissLabelAsPerAdminRole() {
    if (checkIfLender()) {
      return "Decline Application";
    }
  }

  function updateLoanAssessmentOwners(role) {
    targetRole.value = role;
    store.commit("loanAssessmentInfos/setToggleUrl");
    store.dispatch(
      "loanAssessmentInfos/getLendersByRoleForOwnerSelectionList",
      role
    );
  }

  const enabledAgreggatorDataField = computed(
    () => store.state.loanAssessmentInfos.enabledAgreggatorDataField
  );

  function resetEnabledAgreggatorDataField() {
    store.commit("loanAssessmentInfos/setEnabledAgreggatorDataField", "");
  }

  function onClickTransformToInput(dataFieldName) {
    if (enabledAgreggatorDataField.value === "") {
      store.commit(
        "loanAssessmentInfos/setEnabledAgreggatorDataField",
        dataFieldName
      );
    }
    if (
      enabledAgreggatorDataField.value !== "" &&
      enabledAgreggatorDataField.value !== dataFieldName
    ) {
      store.commit(
        "loanAssessmentInfos/setLoanAssessmentDetailAlert",
        "There is already an active input field.Please Either complete or dismiss active input field"
      );
    }
  }

  function onCloseAgreggatorInputBox() {
    onCloseAllEnabledInputBoxes();
  }

  function onCloseAndSubmitAgreggatorInput(
    agreggatorSectionName,
    batchSubmit = true,
    id = ""
  ) {
    resetEnabledAgreggatorDataField();

    if (agreggatorSectionName === "IncomeExpenseDetails") {
      onAggregatorIncomeExpenseDetailsSubmit(batchSubmit, id);
    }
    if (agreggatorSectionName === "LendingDetails") {
      onAggregatorLendingDetailsSubmit(batchSubmit, id);
    }
  }

  function updateActiveDescriptions(key) {
    const descriptions =
      activeDescriptions.value.indexOf(key) > -1
        ? activeDescriptions.value.filter(v => v !== key)
        : activeDescriptions.value.concat(key);
    store.commit("loanAssessmentInfos/setActiveDescriptions", descriptions);
  }

  function bulkActivateDescriptions(descriptions) {
    store.commit("loanAssessmentInfos/setActiveDescriptions", descriptions);
  }

  function onCloseAndSubmitAgreggatorInputDescription(field, value) {
    store
      .dispatch(
        "loanAssessmentInfos/updateAggregatorIncomeExpensesDescription",
        {
          category: field,
          description: value,
          admin_id: adminUser.value.id
        }
      )
      .then(
        result =>
          result?.status === "success" &&
          typeof value === "string" &&
          !value.length &&
          updateActiveDescriptions(field)
      );
  }

  const enabledPersonalLoanStatusObject = reactive({
    loan_id: 0,
    application_id: 0,
    admin_id: 0,
    loan_status: "",
    repayment_amount: 0
  });

  function onCloseAndSubmitPersonalLoanStatus(loan_id, event, id) {
    resetEnabledAgreggatorDataField();
    enabledPersonalLoanStatusObject.loan_id = loan_id;
    enabledPersonalLoanStatusObject.application_id = applicationId.value;
    enabledPersonalLoanStatusObject.admin_id = adminUser.value.id;
    enabledPersonalLoanStatusObject.loan_status = event.target.value;
    // store.commit("main/setFullPageLoading");
    store.dispatch("loanAssessmentInfos/updateAggregatorPersonalLoansStatus", {
      postData: enabledPersonalLoanStatusObject,
      id
    });
  }

  function onCloseAndSubmitPersonalLoanRepaymentAmount(
    loan_id,
    event,
    original,
    id
  ) {
    resetEnabledAgreggatorDataField();
    let repayment_amount =
      event.target.value && event.target.value.length ? event.target.value : 0;
    enabledPersonalLoanStatusObject.loan_id = loan_id;
    enabledPersonalLoanStatusObject.application_id = applicationId.value;
    enabledPersonalLoanStatusObject.admin_id = adminUser.value.id;
    enabledPersonalLoanStatusObject.repayment_amount = repayment_amount;
    enabledPersonalLoanStatusObject.loan_frequency = original.frequency;

    if (Number(event.target.value) <= 0) {
      event.target.value = original.repayment_amount;
      return store.dispatch(
        "globalToastMessage/showSimpleToast",
        {
          message:
            "Repayment Amount for Personal Loan should be greater than 0.",
          type: "error"
        },
        { root: true }
      );
    }

    if (
      original.repayment_amount !== parseFloat(event.target.value) &&
      Number(event.target.value) > 0
    ) {
      // store.commit("main/setFullPageLoading");
      store.dispatch(
        "loanAssessmentInfos/updateAggregatorPersonalLoansRepaymentAmount",
        {
          postData: enabledPersonalLoanStatusObject,
          id
        }
      );
    }
  }

  function onCloseAndSubmitPersonalLoanFrequency(loan_id, event, original, id) {
    resetEnabledAgreggatorDataField();
    enabledPersonalLoanStatusObject.loan_id = loan_id;
    enabledPersonalLoanStatusObject.application_id = applicationId.value;
    enabledPersonalLoanStatusObject.admin_id = adminUser.value.id;
    enabledPersonalLoanStatusObject.loan_frequency = event.target.value;
    enabledPersonalLoanStatusObject.repayment_amount =
      original.repayment_amount;
    // store.commit("main/setFullPageLoading");
    store.dispatch(
      "loanAssessmentInfos/updateAggregatorPersonalLoansRepaymentAmount",
      {
        postData: enabledPersonalLoanStatusObject,
        id
      }
    );
  }

  function onCloseAndSubmitPersonalLoanType(loan_id, event, id) {
    resetEnabledAgreggatorDataField();
    enabledPersonalLoanStatusObject.loan_id = loan_id;
    enabledPersonalLoanStatusObject.application_id = applicationId.value;
    enabledPersonalLoanStatusObject.admin_id = adminUser.value.id;
    enabledPersonalLoanStatusObject.loan_type = event.target.value;
    store.dispatch("loanAssessmentInfos/updateAggregatorPersonalLoansType", {
      postData: enabledPersonalLoanStatusObject,
      id
    });
  }

  function onClosePersonalLoanStatusSelectBox() {
    onCloseAllEnabledInputBoxes();
  }

  function onCloseAllEnabledInputBoxes() {
    resetEnabledAgreggatorDataField();
    store.commit("loanAssessmentInfos/resetAggregatorLoanDetailsTransitLobby");
    store.commit(
      "loanAssessmentInfos/resetAggregatorIncomeExpensesDetailsTransitLobby"
    );
  }

  function getLoanHistoryOfApp() {
    store.dispatch(
      "loanAssessmentInfos/getLoansHistoryOfApplicantAssociatedToApplication"
    );
  }

  function getAllBankStatements() {
    store.commit("main/setFullPageLoading");
    store.dispatch("loanAssessmentInfos/getAllBankStatements");
  }

  const allBankReports = computed(
    () => store.state.loanAssessmentInfos.allBankReports
  );

  const fetchingApplicantLoans = computed(
    () => store.state.loanAssessmentInfos.fetchingApplicantLoans
  );

  function getBankAccountsOfApplicantLinkedToLoanAssessment() {
    store.dispatch(
      "loanAssessmentInfos/getBankAccountsOfApplicantLinkedToAssessment"
    );
  }

  onMounted(() => {
    //Load loan assessment in order to get loan application ID
  });

  const assessmentFinalizationConfirmationDataObject = reactive({
    uuid: null,
    note: "",
    reapplication_activation_date: moment()
      .add(60, "days")
      .utcOffset(0, false)
      .format("YYYY-MM-DD"),
    title: "",
    transition_reason_type: 0,
    type: ""
  });

  function showDismissalAssessmentModal() {
    finalizationFormValidationMessage.value = "";

    assessmentFinalizationConfirmationDataObject.uuid =
      loanAssessmentUuid.value;
    assessmentFinalizationConfirmationDataObject.note = "";
    assessmentFinalizationConfirmationDataObject.transition_reason_type = 0;
    assessmentFinalizationConfirmationDataObject.title = getAssesmentDismissLabelAsPerAdminRole();
    assessmentFinalizationConfirmationDataObject.type = "dismissal";
    cash("#assessment-finalization-confirmation-modal").modal("show");
  }

  function showWithdrawalAssessmentModal() {
    finalizationFormValidationMessage.value = "";

    assessmentFinalizationConfirmationDataObject.uuid =
      loanAssessmentUuid.value;
    assessmentFinalizationConfirmationDataObject.note = "";
    assessmentFinalizationConfirmationDataObject.title = "Withdrawal";
    assessmentFinalizationConfirmationDataObject.type = "withdrawal";
    cash("#assessment-finalization-confirmation-modal").modal("show");
  }

  function showRequestDeclineAssessmentModal() {
    finalizationFormValidationMessage.value = "";

    assessmentFinalizationConfirmationDataObject.uuid =
      loanAssessmentUuid.value;
    assessmentFinalizationConfirmationDataObject.note = "";
    assessmentFinalizationConfirmationDataObject.transition_reason_type = 0;
    assessmentFinalizationConfirmationDataObject.title = "Request Decline";
    assessmentFinalizationConfirmationDataObject.type = "request-decline";
    cash("#assessment-finalization-confirmation-modal").modal("show");
  }

  function showRedoAssessmentModal() {
    finalizationFormValidationMessage.value = "";

    assessmentFinalizationConfirmationDataObject.uuid =
      loanAssessmentUuid.value;
    assessmentFinalizationConfirmationDataObject.note = "";
    assessmentFinalizationConfirmationDataObject.title = "Redo Assessment";
    assessmentFinalizationConfirmationDataObject.type = "redo-assessment";
    cash("#assessment-finalization-confirmation-modal").modal("show");
  }

  function showSubmitAssessmentModal(loanAssessment) {
    finalizationFormValidationMessage.value = "";

    assessmentFinalizationConfirmationDataObject.uuid =
      loanAssessmentUuid.value;
    assessmentFinalizationConfirmationDataObject.note = "";
    assessmentFinalizationConfirmationDataObject.title = getAssesmentSubmitLabelAsPerAssessmentStatus(
      loanAssessment
    );
    assessmentFinalizationConfirmationDataObject.type = "submit-assessment";
    cash("#assessment-finalization-confirmation-modal").modal("show");
  }

  function clearFinalizationFormValidationMessages() {
    finalizationFormValidationMessage.value = "";
  }

  function setNavActiveSection(section) {
    store.dispatch("quickNavLinks/addSectionToActiveSection", section);
  }

  function removeSectionFromActiveSections(section) {
    store.dispatch("quickNavLinks/removeSectionFromActiveSection", section);
  }

  const activeSection = computed(
    () => store.state.quickNavLinks.activeSections
  );

  const loanAssessmentDetailSuccessMessage = computed(
    () => store.state.loanAssessmentInfos.loanAssessmentDetailSuccessMessage
  );

  watch(
    () => loanAssessmentDetailSuccessMessage.value,
    message => {
      if (message) {
        Swal.fire(buildSwalSuccess(message)).then(confirmed => {
          if (confirmed.isConfirmed) {
            store.commit(
              "loanAssessmentInfos/clearLoanAssessmentDetailMessages"
            );
          }
        });
      }
    }
  );

  const loanAssessmentDetailAlertMessage = computed(
    () => store.state.loanAssessmentInfos.loanAssessmentDetailAlertMessage
  );

  watch(
    () => loanAssessmentDetailAlertMessage.value,
    message => {
      if (message !== "") {
        document.getElementById(
          "assessment-dts-success-alert-note-message-body"
        ).innerHTML = message;
        Toastify({
          node: cash("#assessment-dts-success-alert-toast-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
        store.commit("loanAssessmentInfos/clearLoanAssessmentDetailMessages");
        store.commit("main/resetFullPageLoading");
      }
    }
  );

  const loanAssessmentDetailErrorMessage = computed(
    () => store.state.loanAssessmentInfos.loanAssessmentDetailErrorMessage
  );

  const loggedUser = computed(() => store.state.auth.loggedUser);

  const shouldShowAddFollowup = computed(
    () =>
      (loggedUser.value.roles ?? []).includes("lending-manager") ||
      (loggedUser.value.roles ?? []).includes("lending-converter")
  );

  watch(
    () => loanAssessmentDetailErrorMessage.value,
    message => {
      if (message) {
        store.dispatch("globalToastMessage/showSimpleToast", {
          message: message,
          type: "error"
        });
      }
    }
  );

  //Disable Submission
  const disableSubmission = computed(() => {
    let lendingErrorTagsAttachedToLoan =
      store.state.loanTags.lendingErrorNonTagsAttachedToLoan;
    return lendingErrorTagsAttachedToLoan.length > 0;
  });

  //Loan Payment Frequency number
  const LOAN_FREQUENCIES = {
    weekly: { name: "Weekly", value: 30 },
    monthly: { name: "Monthly", value: 7 },
    fortnightly: { name: "Fortnightly", value: 15 }
  };

  function loanPaymentFrequencyNumber(frequency) {
    if (frequency === LOAN_FREQUENCIES.monthly.name) {
      return LOAN_FREQUENCIES.monthly.value;
    }
    if (frequency === LOAN_FREQUENCIES.weekly.name) {
      return LOAN_FREQUENCIES.weekly.value;
    }
    if (frequency === LOAN_FREQUENCIES.fortnightly.name) {
      return LOAN_FREQUENCIES.fortnightly.value;
    }
    return 30;
  }

  //Show Toast message
  function showErrorMessage(message) {
    store.dispatch("globalToastMessage/showSimpleToast", {
      message: message,
      type: "error"
    });
  }

  return {
    assessorPageType,
    getSelfAssignableRoleType,
    getLendingAssessmentAccessType,
    route,
    isGlobalNotesDisplayed,
    onToggleIsGlobalNotesDisplayed,
    adminUser,
    showFinalizingButtons,
    applicationId,
    customerId,
    aggregatorPersonalLoanDetails,
    aggregatorLoanDetailsTransitLobby,
    aggregatorIncomeExpenseDetailsTransitLobby,
    onAggregatorLendingDetailsSubmit,
    onAggregatorIncomeExpenseDetailsSubmit,
    loanAssessmentUuid,
    loanAssessment,
    customerName,
    assessmentFinalizationConfirmationDataObject,
    kycWithAdditionalAttributes,
    applicationTransitionReasonTypes,
    getKycAttributeValueByName,
    pastLoansOfApplicantLinkedToAssessment,
    bankAccountOfApplicantLinkedToAssessment,
    latestBankStatement,
    getAllBankStatements,
    allBankReports,
    onSubmitToSetActiveBankingIncomeAccount,
    getAssesmentSubmitLabelAsPerAssessmentStatus,
    getAssesmentDismissLabelAsPerAdminRole,
    triggerSubmitActionAsPerAssessmentStatus,
    showSubmitAssessmentModal,
    showDismissalAssessmentModal,
    triggerDismissActionAsPerAdminRole,
    onRedoAssessmentReqAction,
    showRedoAssessmentModal,
    onReqForAssessmentDeclineAction,
    showRequestDeclineAssessmentModal,
    onWithdrawAssessmentAction,
    showWithdrawalAssessmentModal,
    setVisbilityOfFinalizingbuttonAsPerAdminRole,
    withdrawable,
    canBeRequestedToDecline,
    reAssignable,
    finalizationFormValidationMessage,
    clearFinalizationFormValidationMessages,
    busy,
    personalLoanStatuses,
    clearLoanAssessmentDetailsMessages,
    onClickTransformToInput,
    enabledAgreggatorDataField,
    onCloseAgreggatorInputBox,
    onCloseAndSubmitAgreggatorInput,
    onCloseAndSubmitAgreggatorInputDescription,
    onCloseAndSubmitPersonalLoanStatus,
    onCloseAndSubmitPersonalLoanRepaymentAmount,
    onCloseAndSubmitPersonalLoanFrequency,
    onCloseAndSubmitPersonalLoanType,
    onClosePersonalLoanStatusSelectBox,
    onSelfAssignAssessment,
    activeSection,
    setNavActiveSection,
    removeSectionFromActiveSections,
    fetchingApplicantLoans,
    getLoanHistoryOfApp,
    fetchingApplicantBankAccounts,
    getBankAccountsOfApplicantLinkedToLoanAssessment,
    fetchingIncomeExpensesDetails,
    updatingIncomeExpenseDetails,
    updatingPersonalLoansDetails,
    getAggregatorIncomeExpensesDetailsForLoanAssessment,
    fetchingLendingDetails,
    updatingLendingDetails,
    fetchingKYC,
    getAggregatorLendingDetailsForLoanAssessment,
    disableSubmission,
    getLoanAssessmentStage,
    loanAssessmentOwners,
    getRoleAccordingToStage,
    updateLoanAssessmentOwners,
    changeAssessmentOwner,
    getCurrentAssignedUserId,
    loanPaymentFrequencyNumber,
    showErrorMessage,
    shouldShowAddFollowup,
    getLendingAssessmentAccessBucket,
    isApplicationBlacklisted,
    assessmentOwner,
    activeDescriptions,
    updateActiveDescriptions,
    bulkActivateDescriptions,
    activeInputId,
    adjustReactivationDateAccordingToReasonDaysLimit
  };
};
