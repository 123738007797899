<template>
  <div
    v-show="isGlobalNotesDisplayed"
    :class="{ invisible: !isGlobalNotesDisplayed }"
    class="note-component-body pb-8 h-full overflow-auto"
    style="top:4%;"
  >
    <div
      v-if="busy"
      class="
        w-full
        h-screen
        overflow-hidden
        bg-white
        opacity-75
        flex flex-col
        items-center
        justify-center
      "
      style="z-index: 1000 !important"
    >
      <div class="relative flex justify-center items-center h-3">
        <LoadingIcon class="w-16 h-16" color="green" icon="bars" />
      </div>
    </div>
    <div v-show="!busy" class="grid grid-cols-12" style="height: 98%">
      <!-- BEGIN: Transactions -->
      <!--      <div class="col-span-12 md:col-span-6 xl:col-span-4 xxl:col-span-12 mt-3">-->
      <div class="col-span-12 mt-3" style="height: inherit">
        <div class="border-b border-gray-400 dark:border-gray-600">
          <ul class="flex flex-wrap -mb-px">
            <li class="mr-2">
              <a
                :class="{
                  'border-transparent hover:text-gray-600 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300':
                    tabNum !== 0,
                  'border-blue-600 active dark:text-blue-500 dark:border-blue-500 text-blue-600':
                    tabNum === 0
                }"
                aria-current="page"
                class="inline-block py-4 px-4 text-sm font-medium text-center  rounded-t-lg border-b-2 "
                href="#"
                @click.prevent="changeNoteTab(0)"
                >Notes</a
              >
            </li>
            <li class="mr-2">
              <a
                :class="{
                  'border-transparent hover:text-gray-600 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300':
                    tabNum !== 1,
                  'border-blue-600 active dark:text-blue-500 dark:border-blue-500 text-blue-600':
                    tabNum === 1
                }"
                aria-current="page"
                class="inline-block py-4 px-4 text-sm font-medium text-center  rounded-t-lg border-b-2 "
                href="#"
                @click.prevent="changeNoteTab(1)"
              >
                Checklist ( {{ checkListPercent }}% )</a
              >
            </li>
            <li class="mr-2">
              <a
                :class="{
                  'border-transparent hover:text-gray-600 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300':
                    tabNum !== 2,
                  'border-blue-600 active dark:text-blue-500 dark:border-blue-500 text-blue-600':
                    tabNum === 2
                }"
                aria-current="page"
                class="inline-block py-4 px-4 text-sm font-medium text-center  rounded-t-lg border-b-2 "
                href="#"
                @click.prevent="changeNoteTab(2)"
              >
                Illion Statement
              </a>
            </li>
          </ul>
        </div>

        <!-- BEGIN: Checklists -->
        <div v-show="tabNum === 1" class="bg-white p-5 rounded-lg mt-3 intro-y">
          <ApplicationsCheckList
            v-for="(checklist, index) in allChecklists"
            :key="index"
            :checklist="checklist"
            :enable="enableChecklistNumber"
            :index="index"
            @save="saveChecklist"
          />
        </div>
        <!-- END: Checklists -->

        <!-- BEGIN: Recent Activities -->
        <!-- BEGIN: Tabulator -->
        <div v-show="tabNum === 0" class="intro-y box p-2 mt-2">
          <div class="grid grid-cols-5 flex flex-col flex-row items-end pb-2">
            <div class="col-span-2 grid grid-cols-1">
              <div class="flex">
                <label
                  class="leading-8 w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                  >Type</label
                >
                <select
                  id="tabulator-html-filter-field"
                  v-model="filter.value"
                  class="form-select sm:mt-0"
                  @change="onChange"
                >
                  <option value="All">All</option>
                  <option value="Event">Events</option>
                  <option value="Agent Entered">Agent Entered</option>
                  <option value="Comms">Comms</option>
                  <option value="CuriousThing">CuriousThing</option>
                </select>
              </div>
            </div>
            <div class="col-span-3 grid grid-cols-1 px-2">
              <div class="flex">
                <label
                  class="leading-8 w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                  >Order</label
                >
                <select
                  id="tabulator-html-filter-order"
                  v-model="sortDir"
                  class="form-select sm:mt-0"
                  @change="onChange"
                >
                  <option value="desc">Newest to Oldest</option>
                  <option value="asc">Oldest to Newest</option>
                </select>
              </div>
            </div>
          </div>
          <div
            class="overflow-x-auto overflow-y-auto scrollbar-hidden"
            style="max-height: 33rem"
          >
            <div
              id="loan-notes-tabulator"
              ref="lnNotesTableRef"
              class="table-report table-report--tabulator"
            ></div>
          </div>
        </div>
        <!-- END: Tabulator -->
        <!-- END: Recent Activities -->
        <!-- BEGIN: Note Input -->
        <div v-show="tabNum === 0" class="col-span-12 sm:col-span-6 mt-2">
          <div>
            <div class="float-right mt-2 mb-2">
              <div class="form-check">
                <input
                  id="checkbox-switch-7"
                  v-model="isSMSModeActive"
                  class="form-check-switch"
                  type="checkbox"
                  @change="onSetSmsMode($event)"
                />
                <label class="form-check-label" for="checkbox-switch-7">
                  <strong> Send as sms</strong></label
                >
              </div>
            </div>
            <label
              v-if="activeMessageMode === messageModes.general_note"
              class="float-left form-label"
            >
              <strong> Write a Note:</strong>
              <button
                class="bg-gray-200 p-1 ml-1 px-2 rounded-lg"
                @click="loanNote = ''"
              >
                clear
                <XCircleIcon class="text-theme-13" />
              </button>
            </label>
            <label
              v-if="activeMessageMode === messageModes.sms"
              class="float-left form-label"
            >
              <strong> Write Text SMS:</strong></label
            >
            <label
              v-if="activeMessageMode === messageModes.sms && smsTemplates"
              class="ml-2"
              ><span class="hover:cursor-pointer"
                ><u
                  class="text-blue-700"
                  @click="setSmsTextTemplate('card_verification')"
                  >Card Verf</u
                >
                |
                <u
                  class="text-blue-700"
                  @click="setSmsTextTemplate('loan_on_arrears')"
                  >Arrears</u
                ></span
              ></label
            >
          </div>

          <textarea
            id="note-form"
            v-model="loanNote"
            class="form-control"
            type="text"
          >
          </textarea>
        </div>
        <div v-show="tabNum === 0" class="row-span-1 mb-7">
          <button
            v-if="activeMessageMode === messageModes.general_note"
            class="float-right btn btn-success w-60 bg-theme-16"
            type="button"
            @click.prevent="submitNoteOnLoanAsPerAssociation"
          >
            Publish Note
            <span v-if="busy" class="w-4 h-4 ml-2"
              ><svg
                class="w-full h-full"
                stroke="white"
                viewBox="-2 -2 42 42"
                width="25"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="none" fill-rule="evenodd">
                  <g stroke-width="4" transform="translate(1 1)">
                    <circle cx="18" cy="18" r="18" stroke-opacity=".5"></circle>
                    <path d="M36 18c0-9.94-8.06-18-18-18">
                      <animateTransform
                        attributeName="transform"
                        dur="1s"
                        from="0 18 18"
                        repeatCount="indefinite"
                        to="360 18 18"
                        type="rotate"
                      ></animateTransform>
                    </path>
                  </g>
                </g></svg
            ></span>
          </button>
          <button
            v-if="activeMessageMode === messageModes.sms"
            class="float-right btn btn-success w-40"
            type="button"
            @click.prevent="submitNoteOnLoanAsPerAssociation"
          >
            <MessageSquareIcon class="w-6 h-6 mr-2" />
            Send SMS
            <span v-if="busy" class="w-4 h-4 ml-2"
              ><svg
                class="w-full h-full"
                stroke="white"
                viewBox="-2 -2 42 42"
                width="25"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="none" fill-rule="evenodd">
                  <g stroke-width="4" transform="translate(1 1)">
                    <circle cx="18" cy="18" r="18" stroke-opacity=".5"></circle>
                    <path d="M36 18c0-9.94-8.06-18-18-18">
                      <animateTransform
                        attributeName="transform"
                        dur="1s"
                        from="0 18 18"
                        repeatCount="indefinite"
                        to="360 18 18"
                        type="rotate"
                      ></animateTransform>
                    </path>
                  </g>
                </g></svg
            ></span>
          </button>
        </div>
        <!-- END: Note Input -->
        <!-- BEGIN: Illion Statement -->
        <div
          v-show="tabNum === 2"
          class="bg-white p-1 rounded-lg mt-3 intro-y"
          style="height: inherit"
        >
          <iframe
            id="bank-statement-iframe"
            :src="illionBankStatementUrl"
            class="w-full"
            style="height: inherit"
            title="Illion Statement"
          >
          </iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch
} from "vue";
import { useStore } from "@/store";
import Toastify from "toastify-js";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { authGetterService } from "@/services/auth/auth-getter-service";
import feather from "feather-icons";
import { handleErrorResponse } from "@/services/auth/responseHandler/handle-response-service";
import ApplicationsCheckList from "@/components/checklists/ApplicationsCheckList";
import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";

export default defineComponent({
  components: { ApplicationsCheckList },
  props: {
    application_id: Number,
    associated_model_key: String,
    associated_model_id: Number,
    associated_model_uuid: String
  },
  setup(props) {
    const store = useStore();
    const tabulator = ref();
    const filter = reactive({
      field: "source_type",
      type: "=",
      value: "All"
    });

    const sortDir = ref("desc");

    const smsTemplates = computed(
      () => store.state.timelineNotesViewer.smsTemplates
    );

    function setSmsTextTemplate(templateSlug) {
      loanNote.value = smsTemplates.value[templateSlug];
    }

    const timelineNotesUpdateCounter = computed(
      () => store.state.timelineNotesViewer.timelineNotesUpdateCounter
    );

    /* =====================================
     Checklists
     =====================================*/
    let tabNum = ref(0);

    function changeNoteTab(num) {
      tabNum.value = num;
      //Collapse side menu when displaying bank statement
      if (num === 2) {
        store.commit("main/collapseSideMenu");
      } else {
        store.commit("main/expandSideMenu");
      }
    }

    const allChecklists = ref(null);

    function fetchChecklistForLoanApplication() {
      axiosWrapperService
        .axiosGet(
          `/loanApplications/${props.application_id}/getChecklistForApplication`
        )
        .then(({ data }) => {
          allChecklists.value = data.data.checklist;
        });
    }

    function saveChecklist() {
      axiosWrapperService
        .axiosPut(
          `/loanApplications/${props.application_id}/updateChecklistForApplication`,
          { checklist: allChecklists.value }
        )
        .then(resp => {});
    }

    const checkListPercent = computed(() => {
      if (!allChecklists.value) {
        return 0;
      }
      let percent = 0;
      let listsLength = allChecklists.value.length;
      allChecklists.value.forEach(list => {
        percent += parseInt(listPercent(list));
      });
      return Math.round(percent / listsLength).toFixed(0);
    });

    //is the checklist enabled e.g. if the number is 1 then the next checklist is enabled
    const enableChecklistNumber = computed(() => {
      if (!allChecklists.value) {
        return 0;
      }
      let number = 0;
      allChecklists.value.forEach(list => {
        let doneCount = 0;
        list.items.forEach(item => {
          if (item.status) {
            doneCount++;
          }
        });
        if (doneCount === list.items.length) {
          number++;
        }
      });
      return number;
    });

    function listPercent(list) {
      let count = list.items.length;
      let doneCount = 0;
      list.items.forEach(item => {
        if (item.status) {
          doneCount++;
        }
      });
      if (doneCount > 0) {
        return Math.round((doneCount / count) * 100).toFixed(1);
      }
      return 0;
    }

    //: END Checklists

    watch(
      () => timelineNotesUpdateCounter.value,
      () => {
        reloadTabulatorNotes();
      }
    );

    function reloadTabulatorNotes() {
      store.commit("timelineNotesViewer/setBusy");
      tabulator.value.replaceData();
    }

    const messageModes = reactive({
      general_note: "general_note",
      sms: "sms",
      email: "email"
    });

    const activeMessageMode = ref(messageModes.general_note);

    function onSetSmsMode(event) {
      if (event.target.checked) {
        activeMessageMode.value = messageModes.sms;
      } else {
        loanNote.value = "";
        activeMessageMode.value = messageModes.general_note;
      }
    }

    const noteEnabled = computed(
      () => store.state.timelineNotesViewer.noteEnabled
    );

    const isGlobalNotesDisplayed = computed(
      () => store.state.timelineNotesViewer.isGlobalNotesDisplayed
    );

    function onToggleIsGlobalNotesDisplayed() {
      store.commit("timelineNotesViewer/toggleIsGlobalNotesDisplayed");
      showNotes();
    }

    const applicationId = computed(
      () => store.state.timelineNotesViewer.applicationId
    );

    const gettingNotes = computed(
      () => store.state.timelineNotesViewer.gettingNotes
    );
    const busy = computed(() => store.state.timelineNotesViewer.busy);

    const timelineNotesList = computed(
      () => store.state.timelineNotesViewer.timelineNotesList
    );

    const associatedModelKey = computed(
      () => store.state.timelineNotesViewer.associatedModelKey
    );

    const timelineNotesSuccessMessage = computed(
      () => store.state.timelineNotesViewer.timelineNotesSuccessMessage
    );

    watch(
      () => timelineNotesSuccessMessage.value,
      message => {
        if (message !== "") {
          Toastify({
            node: cash("#success-note-submit-content")
              .clone()
              .removeClass("hidden")[0],
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: "top",
            position: "right",
            stopOnFocus: true
          }).showToast();
          loanNote.value = "";
          store.commit("timelineNotesViewer/clearTimelineNotesMessages");
          store.commit("timelineNotesViewer/resetBusy");
          activeMessageMode.value = messageModes.general_note;
        }
      }
    );

    const timelineNotesErrorMessage = computed(
      () => store.state.timelineNotesViewer.timelineNotesErrorMessage
    );

    watch(
      () => timelineNotesErrorMessage.value,
      message => {
        if (message) {
          cash("#failed-note-message-body").html(message);
          Toastify({
            node: cash("#failed-note-submit-content")
              .clone()
              .removeClass("hidden")[0],
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: "top",
            position: "right",
            stopOnFocus: true
          }).showToast();
          store.commit("timelineNotesViewer/clearTimelineNotesMessages");
          store.commit("timelineNotesViewer/resetBusy");
        }
      }
    );

    const toggleNoteViewer = () => {
      // everytime set correct application_id and note post method
      store.commit("timelineNotesViewer/toggleNoteEnabled");
    };

    const loanNote = ref("");

    function showNotes() {
      activeMessageMode.value = messageModes.general_note;
      if (isGlobalNotesDisplayed.value && applicationId.value > 0) {
        initTabulator();
      }
      toggleNoteViewer();
    }

    function getTimelineNotes() {
      store.dispatch("timelineNotesViewer/getTimelineNotes");
    }

    watch(
      () => props.application_id,
      appId => {
        if (isGlobalNotesDisplayed.value && appId > 0) {
          store.commit("timelineNotesViewer/setApplicationId", appId);
          getTimelineNotes();
          store.dispatch(
            "timelineNotesViewer/getWrittenSMSTextForCardVerfAndArrearsOnLoan"
          );
          initTabulator();
        }
      }
    );

    watch(
      () => isGlobalNotesDisplayed.value,
      displayed => {
        if (displayed && props.application_id > 0) {
          store.commit(
            "timelineNotesViewer/setApplicationId",
            props.application_id
          );
          showNotes();
        }
      }
    );

    function submitNoteOnLoanAsPerAssociation() {
      if (!busy.value) {
        if (loanNote.value === "") {
          Toastify({
            node: cash("#failed-note-submit-content")
              .clone()
              .removeClass("hidden")[0],
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: "top",
            position: "right",
            stopOnFocus: true
          }).showToast();
          store.commit("timelineNotesViewer/resetBusy");
          return;
        }
        if (associatedModelKey.value === "assessment") {
          store.commit("timelineNotesViewer/setBusy");
          store.dispatch("timelineNotesViewer/submitApplicationNotes", {
            noteContent: loanNote.value,
            messageMode: activeMessageMode.value,
            applicationId: props.application_id,
            associatedModelKey: associatedModelKey.value
          });
        } else if (associatedModelKey.value === "application_details") {
          store.commit("timelineNotesViewer/setBusy");
          store.dispatch("timelineNotesViewer/submitApplicationNotes", {
            noteContent: loanNote.value,
            messageMode: activeMessageMode.value,
            applicationId: props.application_id,
            associatedModelKey: associatedModelKey.value
          });
        } else if (associatedModelKey.value === "followup") {
          store.commit("timelineNotesViewer/setBusy");
          store.dispatch("timelineNotesViewer/submitFollowUpNotes", {
            noteContent: loanNote.value,
            messageMode: activeMessageMode.value,
            followUpUuid: props.associated_model_uuid
          });
        }
        loanNote.value = "";
      }
    }

    onMounted(() => {
      activeMessageMode.value = messageModes.general_note;
      store.commit("timelineNotesViewer/setGettingNotes");
      store.commit(
        "timelineNotesViewer/setAssociatedModelKey",
        props.associated_model_key
      );
      fetchChecklistForLoanApplication();
    });

    const generateAjaxUrl = (filter, sort = "") => {
      let url =
        process.env.VUE_APP_BASE_URL +
        "/getTimelineMessagesOfLoan/" +
        props.application_id;
      let query = [filter]
        .map((singleFilter, key) => {
          return `${encodeURIComponent(
            `filter[${key}][field]`
          )}=${encodeURIComponent(singleFilter.field)}&${encodeURIComponent(
            `filter[${key}][type]`
          )}=${encodeURIComponent(singleFilter.type)}&${encodeURIComponent(
            `filter[${key}][value]`
          )}=${encodeURIComponent(singleFilter.value)}`;
        })
        .join("&");
      if (sort) {
        query += `&sortDir=${encodeURIComponent(sort)}`;
      }

      if (query) {
        url += `?${query}`;
      }
      return url;
    };

    const initTabulator = () => {
      store.commit("timelineNotesViewer/setBusy");

      tabulator.value = new Tabulator("#loan-notes-tabulator", {
        ajaxURL: generateAjaxUrl(filter, sortDir.value),
        ajaxConfig: {
          method: "GET", //set request type to GET
          headers: authGetterService.getAuthHeader()
        },
        ajaxResponse: function(url, params, response) {
          //url - the URL of the request
          //params - the parameters passed with the request
          //response - the JSON object returned in the body of the response.
          store.commit("timelineNotesViewer/resetBusy");
          return response.data; //return the response data to tabulator
        },
        filterMode: "remote",
        sortMode: "remote",
        printAsHtml: true,
        printStyled: true,
        reactiveData: true, //enable reactive data
        layout: "fitColumns",
        responsiveLayout: "collapse",
        placeholder: "No matching records found",
        rowFormatter: function(cell) {
          switch (cell.getData().source_type) {
            case "Agent Entered":
              cell.getElement().style.backgroundColor = "#fffff1";
              break;
            case "Comms":
              cell.getElement().style.color = "black";
              cell.getElement().style.backgroundColor = "#fef8ff";
              break;
            case "Event":
              cell.getElement().style.color = "black";
              cell.getElement().style.backgroundColor = "#fafffa";
              break;
          }
          cell.getElement().style.color = "black";
        },
        columns: [
          // For HTML table
          {
            title: "Agent",
            responsive: 0,
            field: "admin_full_name",
            vertAlign: "middle",
            print: false,
            download: false,
            variableHeight: true,
            widthGrow: 1,
            formatter(cell) {
              return `<div>
                <div class="font-medium whitespace-nowrap">${
                  cell.getData().admin_full_name
                }</div><div class="text-xs whitespace-nowrap"><i>${
                cell.getData().source_type
              }</i></div><div class="text-xs whitespace-nowrap"><i>${
                cell.getData().created_at
              }</i></div>
              </div>`;
            }
          },
          {
            responsive: 0,
            title: "Content",
            field: "content",
            hozAlign: "left",
            print: false,
            download: false,
            formatter(cell) {
              cell.getElement().style.whiteSpace = "pre-wrap";
              return cell
                .getData()
                .content.replace(/(\b(https?:\/\/|www\.)[^\s]+)/g, function(
                  url,
                  p1,
                  p2
                ) {
                  return `<a href="${
                    p2 === "www." ? `https://${url}` : url
                  }" class="underline text-blue-600 hover:cursor-pointer" target="_blank">${url}</a>`;
                });
            },
            variableHeight: true,
            widthGrow: 2
          }
        ]
      });
      tabulator.value.on("renderComplete", function() {
        feather.replace({
          "stroke-width": 1.5
        });
      });

      tabulator.value.on("dataLoadError", function(errorResponse) {
        handleErrorResponse(errorResponse);
      });
    };

    // Filter function
    const onChange = () => {
      tabulator.value.setData(generateAjaxUrl(filter, sortDir.value));
    };

    // On reset filter
    const onResetFilter = () => {
      filter.field = "source_type";
      filter.type = "=";
      filter.value = "All";

      onChange();
    };

    const isSMSModeActive = computed(
      () => activeMessageMode.value === messageModes.sms
    );

    const email = computed(() => {
      return store.state.customerAndLoanDetails.kycWithAdditionalAttributes
        .basicAttributes.email;
    });

    const illionBankStatementUrl = computed(() => {
      return `${process.env.VUE_APP_LEGACY_ADMINPANEL_URL}/bankstatement?email=${email.value}`;
    });

    return {
      isGlobalNotesDisplayed,
      onToggleIsGlobalNotesDisplayed,
      smsTemplates,
      setSmsTextTemplate,
      timelineNotesList,
      toggleNoteViewer,
      loanNote,
      activeMessageMode,
      messageModes,
      onSetSmsMode,
      showNotes,
      submitNoteOnLoanAsPerAssociation,
      onChange,
      onResetFilter,
      gettingNotes,
      busy,
      filter,
      noteEnabled,
      //checklists
      checkListPercent,
      tabNum,
      changeNoteTab,
      allChecklists,
      enableChecklistNumber,
      saveChecklist,
      isSMSModeActive,
      email,
      illionBankStatementUrl,
      sortDir
    };
  }
});
</script>
<style scoped></style>
