import { formatErrorMessage } from "../utils/messageFormatter";
import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import Swal from "sweetalert2";

const state = () => {
  return {
    customerId: 0,
    documentRequestList: [],
    documentTypeList: [],
    otherDocsCount: 0,
    busy: false,
    fetchingDocReq: true,
    docFileUploadValidationMessage: "",
    documentRequestSuccessMessage: "",
    documentRequestErrorMessage: ""
  };
};

// getters
const getters = {
  documentRequestList: state => state.documentRequestList,
  documentTypeList: state => state.documentTypeList,
  busy: state => state.busy,
  docFileUploadValidationMessage: state => state.docFileUploadValidationMessage,
  documentRequestSuccessMessage: state => state.documentRequestSuccessMessage,
  documentRequestErrorMessage: state => state.documentRequestErrorMessage
};

// actions
const actions = {
  getDocumentRequestsOfLoan: async (
    { state, commit },
    { applicationId, successMessage, include_other_docs_count = true }
  ) => {
    try {
      commit("setFetchingDocReq");
      commit("clearDocumentRequestMessages");
      let url = `/getDocumentRequestsOfLoan/${applicationId}`;
      if (include_other_docs_count) {
        url += "?include_other_docs_count=true";
      }
      axiosWrapperService
        .axiosGet(url)
        .then(response => {
          commit("clearAndPushToDocumentRequestList", response.data.data);

          if (include_other_docs_count) {
            commit("setOtherDocsCount", response.data.other_docs_count ?? 0);
            commit("setCustomerId", response.data.customer_id ?? 0);
          }

          if (successMessage.length) {
            commit("setDocumentRequestSuccessMessage", successMessage);
          }
        })
        .catch(error => {
          commit(
            "setDocumentRequestErrorMessage",
            formatErrorMessage(error, true)
          );
        })
        .finally(() => {
          commit("resetFetchingDocReq");
        });
    } catch (e) {
      commit("resetFetchingDocReq");
      commit("setDocumentRequestErrorMessage", "Failed to get the list.");
    }
  },
  getDocumentRequestsOfCustomer: async ({ state, commit }, { customerId }) => {
    try {
      commit("setFetchingDocReq");
      commit("clearDocumentRequestMessages");
      axiosWrapperService
        .axiosGet("/getDocumentRequestsOfCustomer/" + customerId)
        .then(response => {
          commit("resetFetchingDocReq");
          commit("clearAndPushToDocumentRequestList", response.data.data);
        })
        .catch(error => {
          commit(
            "setDocumentRequestErrorMessage",
            formatErrorMessage(error, true)
          );
        });
    } catch (e) {
      commit("setDocumentRequestErrorMessage", "Failed to get the list.");
    }
  },
  getDocumentGalleryItemFile: async ({ commit }, galleryItemInstance) => {
    try {
      commit("clearDocumentRequestMessages");
      axiosWrapperService
        .axiosGet("/getDocumentGalleryItemFile/" + galleryItemInstance.id, {
          responseType: "blob"
        })
        .then(response => {
          const url = window.URL.createObjectURL(response.data);
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.click();
          link.remove();
          URL.revokeObjectURL(url);
        })
        .catch(error => {
          commit(
            "setDocumentRequestErrorMessage",
            formatErrorMessage(error, true)
          );
        });
    } catch (e) {
      commit("setDocumentRequestErrorMessage", "Failed to get the list.");
    }
  },
  getAllDefaultDocumentTypes: async ({ dispatch, commit }, applicationId) => {
    try {
      commit("clearDocumentRequestMessages");
      axiosWrapperService
        .axiosGet("/getAllDefaultDocumentTypes")
        .then(response => {
          commit("clearAndPushToDocumentTypeList", response.data.data);
          dispatch("getDocumentRequestsOfLoan", {
            applicationId: applicationId,
            successMessage: ""
          });
        })
        .catch(error => {
          commit(
            "setDocumentRequestErrorMessage",
            formatErrorMessage(error, true)
          );
        });
    } catch (e) {
      commit("setDocumentRequestErrorMessage", "Failed to get the list.");
    }
  },
  storeDocTypes: async ({ dispatch, commit }, { applicationId, types }) => {
    try {
      commit("clearDocumentRequestMessages");
      commit("clearAndPushToDocumentTypeList", types);
      dispatch("getDocumentRequestsOfLoan", {
        applicationId: applicationId,
        successMessage: ""
      });
    } catch (e) {
      commit("setDocumentRequestErrorMessage", "Failed to get the list.");
    }
  },
  submitFilesToAttachOnDocRequest: async (
    { dispatch, state, commit },
    { applicationId, formData, customerId }
  ) => {
    commit("clearDocumentRequestMessages");
    axiosWrapperService
      .axiosPost(
        "/addFilesToDocReq/" +
          applicationId +
          "/" +
          formData.get("doc_req_type_slug") +
          "/" +
          formData.get("doc_req_id"),
        formData
      )
      .then(() => {
        if (customerId) {
          dispatch("getDocumentRequestsOfCustomer", { customerId: customerId });
        } else {
          dispatch("getDocumentRequestsOfLoan", {
            applicationId: applicationId,
            successMessage: ""
          });
        }

        commit(
          "setDocumentRequestSuccessMessage",
          "Successfully added files to document request"
        );
      })
      .catch(error => {
        commit(
          "setDocFileUploadValidationMessage",
          formatErrorMessage(error, false)
        );
      })
      .finally(() => {
        commit("resetBusy");
      });
  },

  requestForDocumentsOnLoanApplication: async (
    { state, dispatch, commit },
    { applicationId, newDocReqFormObject }
  ) => {
    try {
      commit("clearDocumentRequestMessages");
      axiosWrapperService
        .axiosPost(
          "/requestForDocumentsOnLoanApplication/" + applicationId,
          newDocReqFormObject
        )
        .then(() => {
          dispatch("getDocumentRequestsOfLoan", {
            applicationId: applicationId,
            successMessage: "Successfully created New Document Request"
          });
        })
        .catch(error => {
          commit(
            "setDocumentRequestErrorMessage",
            formatErrorMessage(error, true)
          );
        });
    } catch (e) {
      commit("setDocumentRequestErrorMessage", "Failed to get the list.");
    }
  },
  removeDocumentRequestOnLoanApplication: async (
    { dispatch, commit },
    { applicationId, docReqId, customerId, redirect, router }
  ) => {
    try {
      commit("setFetchingDocReq");
      commit("clearDocumentRequestMessages");

      axiosWrapperService
        .axiosDelete("/removeDocumentRequestOnLoanApplication/" + docReqId)
        .then(response => {
          // Redirect if request coming from follow-up page
          if (redirect) {
            Swal.fire({
              title: "Success!",
              text: response.data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
              onClose: async function() {
                await router.push("/loan-follow-up-dashboard");
              }
            }).then(_ => Swal.close());
          } else {
            if (customerId) {
              dispatch("getDocumentRequestsOfCustomer", {
                customerId: customerId
              });
            } else {
              dispatch("getDocumentRequestsOfLoan", {
                applicationId: applicationId,
                successMessage: "Successfully Removed Document Request"
              });
            }
          }
        })
        .catch(error => {
          commit(
            "setDocumentRequestErrorMessage",
            formatErrorMessage(error, true)
          );
        });
    } catch (e) {
      commit("setDocumentRequestErrorMessage", "Failed to get the list.");
    }
  }
};

// mutations
const mutations = {
  setBusy(state) {
    state.busy = true;
  },
  resetBusy(state) {
    state.busy = false;
  },
  setFetchingDocReq(state) {
    state.fetchingDocReq = true;
  },
  resetFetchingDocReq(state) {
    state.fetchingDocReq = false;
  },
  clearAndPushToDocumentRequestList(state, documentRequestList) {
    state.documentRequestList = documentRequestList;
  },
  setOtherDocsCount(state, otherDocsCount) {
    state.otherDocsCount = otherDocsCount;
  },
  setCustomerId(state, customerId) {
    state.customerId = customerId;
  },
  clearDocumentRequestList(state) {
    state.documentRequestList = [];
  },
  clearAndPushToDocumentTypeList(state, documentTypeList) {
    state.documentTypeList = documentTypeList;
  },
  setCustomerName(state, customerName) {
    state.customerName = customerName;
  },
  setDocFileUploadValidationMessage(state, docFileUploadValidationMessage) {
    state.docFileUploadValidationMessage = docFileUploadValidationMessage;
  },
  setDocumentRequestSuccessMessage(state, documentRequestSuccessMessage) {
    state.documentRequestSuccessMessage = documentRequestSuccessMessage;
  },
  setDocumentRequestErrorMessage(state, documentRequestErrorMessage) {
    state.documentRequestErrorMessage = documentRequestErrorMessage;
  },
  clearDocumentRequestMessages(state) {
    state.docFileUploadValidationMessage = "";
    state.documentRequestErrorMessage = "";
    state.documentRequestSuccessMessage = "";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
