<template>
  <div>
    <div
      class="flex mt-1 p-4 box cursor-pointer"
      :class="{ 'bg-theme-32': isActive }"
      @click="setNavActiveSection('ln-app-general-follow-up')"
    >
      <h2
        class="font-medium text-theme-7 text-base w-full"
        id="ln-app-general-follow-up"
      >
        GENERAL FOLLOW UP NOTES
      </h2>
      <ChevronDownIcon :class="{ 'transform rotate-180': !isActive }" />
    </div>
    <!-- BEGIN: Striped Rows -->
    <div class="intro-y box" v-if="isActive">
      <div
        class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
      ></div>
      <div id="doc-req-table" class="p-5">
        <div class="preview">
          <div class="overflow-x-auto">
            <table class="table">
              <thead>
                <tr>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Note
                  </th>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Created At
                  </th>
                </tr>
              </thead>
              <template v-if="!busy">
                <tbody v-if="lnGenericFollowUpNotes.length > 0">
                  <tr
                    v-for="(note, noteIndex) in lnGenericFollowUpNotes"
                    :key="noteIndex"
                    :class="{
                      'bg-gray-200 dark:bg-dark-1': noteIndex % 2 > 0
                    }"
                  >
                    <td class="border-b">
                      {{ note.content }}
                    </td>
                    <td class="border-b dark:border-dark-5">
                      {{ note.created_at }}
                    </td>
                  </tr>
                </tbody>
                <NoRecordCustTblTemplate v-else />
              </template>
              <FetchingRecordTblTemplate v-else />
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import { useStore } from "@/store";
import NoRecordCustTblTemplate from "@/components/no-record-tbl-template/Main.vue";
import FetchingRecordTblTemplate from "@/components/fetching-record-tbl-template/Main";

export default defineComponent({
  components: {
    NoRecordCustTblTemplate,
    FetchingRecordTblTemplate
  },
  props: {
    applicationId: {
      type: Number,
      require: true
    },
    isActive: {
      type: Boolean,
      require: true
    },
    setNavActiveSection: {
      type: Function,
      require: true,
      default: () => ({})
    }
  },
  setup(props) {
    const store = useStore();

    const busy = computed(() => store.state.lnGenericFollowUps.busy);

    const lnGenericFollowUpNotes = computed(
      () => store.state.lnGenericFollowUps.lnGenericFollowUpNotes
    );

    store.dispatch(
      "lnGenericFollowUps/getGenericFollowUpOnLoan",
      props.applicationId
    );

    return {
      lnGenericFollowUpNotes,
      busy
    };
  }
});
</script>
