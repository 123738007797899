import { computed, watch, reactive } from "vue";
import { useStore } from "@/store";

export const loanFlags = props => {
  const store = useStore();

  const rebuttalCategories = {
    loan: "2 SACC loan Rebuttal",
    dh: "DH with SACC loan",
    gambling: "Gambling rebuttals"
  };

  //Fetching
  const fetchingLnErrorTags = computed(
    () => store.state.loanTags.fetchingLnErrorTags
  );

  const fetchingDocReq = computed(() => store.state.lnAppDocReq.fetchingDocReq);

  const creditCheckData = computed(
    () => store.getters["creditCheck/creditCheck"]
  );

  //Data
  const creditVerification = reactive({
    verified: false,
    message: ""
  });

  const basic = computed(() => store.state.loanFlags.basic);

  const kycWithAdditionalAttributes = computed(
    () => store.state.customerAndLoanDetails.kycWithAdditionalAttributes
  );

  //Loan-history
  const history = computed(() => store.state.loanFlags.history);

  //Banking
  const banking = computed(() => store.state.loanFlags.banking);

  const bankAccountOfApplicantLinkedToAssessment = computed(
    () =>
      store.state.loanAssessmentInfos.bankAccountOfApplicantLinkedToAssessment
  );

  const pastLoansOfApplicantLinkedToAssessment = computed(
    () => store.state.loanAssessmentInfos.pastLoansOfApplicantLinkedToAssessment
  );

  const tokenizedPaymentCards = computed(
    () => store.state.paymentCards.tokenizedPaymentCards
  );

  const serviceability = computed(() => store.state.loanFlags.serviceability);

  const rebuttalsList = computed(
    () => store.state.loanRebuttals.loanRebuttalList
  );

  const rebuttalCounts = computed(() => store.state.loanFlags.rebuttalCounts);

  const tags = computed(
    () => store.state.loanTags.lendingErrorNonTagsAttachedToLoan
  );

  const tagString = computed(() => store.state.loanFlags.tags);

  const docsAndRebuttals = computed(
    () => store.state.loanFlags.docsAndRebuttals
  );

  const documentRequestList = computed(
    () => store.state.lnAppDocReq.documentRequestList
  );

  const docRequestFlags = computed(() => store.state.loanFlags.docRequests);

  const aggregatorLoanDetails = computed(
    () => store.state.loanAssessmentInfos.aggregatorLoanDetails
  );

  const creditCheckLoading = computed(
    () => store.state.loanFlags.creditCheckLoading
  );

  function convertTagsIntoString(tags) {
    let data = Object.keys(tags).reduce((result, key) => {
      let tag = tags[key].name.replace("_", " ").toUpperCase();
      return `${result}${(result.length ? ", " : "") + tag}`;
    }, "");
    if (!data.length) data = "No tag attached";
    store.commit("loanFlags/setTags", data);
  }

  //Changes related operations and commits
  watch(
    () => kycWithAdditionalAttributes.value.basicAttributes,
    attributes => {
      if (attributes && attributes.additional_attributes) {
        let citizenship = attributes.additional_attributes.find(
          attribute => attribute.attr === "Australian_Citizen"
        )?.value;
        store.commit("loanFlags/setBasic", {
          citizen: ["Yes", "yes"].indexOf(citizenship) !== -1
        });
      }
    }
  );

  watch(
    () => tags.value,
    value => convertTagsIntoString(value)
  );

  watch(
    () => pastLoansOfApplicantLinkedToAssessment.value,
    loans => {
      store.commit("loanFlags/setHistory", {
        count: loans.length,
        premature: loans.filter(loan => loan.is_premature_payment).length > 0
      });
    }
  );

  watch(
    () => bankAccountOfApplicantLinkedToAssessment.value,
    bankAccounts => {
      let debitAccnts = bankAccounts.filter(accnt => accnt.debit_account);
      let details = {
        last_modified_by: 0,
        system_verified: false,
        verification_required: false
      };
      //is_premature_payment
      if (debitAccnts.length) {
        details = debitAccnts[0].verification;
      }
      store.commit("loanFlags/setBankingAccountVerification", details);
    }
  );

  watch(
    () => tokenizedPaymentCards.value,
    cards => {
      let details = {
        verified: false
      };
      if (
        cards.filter(card => card.system_verified || card.last_modified_by)
          .length
      ) {
        details = {
          verified: true
        };
      }
      store.commit("loanFlags/setBankingCardVerification", details);
    }
  );

  watch(
    () => rebuttalsList.value,
    rebuttals => {
      let counts = {};
      Object.keys(rebuttalCategories).forEach(
        (key, value) =>
          (counts[key] = rebuttals.filter(
            reb => reb.category_title === rebuttalCategories[key]
          ).length)
      );
      store.commit("loanFlags/setRebutallCounts", counts);
    }
  );

  watch(
    () => aggregatorLoanDetails.value,
    loanDetails => {
      //update serviceability
      let affordability = loanDetails.affordability.data;
      let summary = loanDetails.loan_summary.data;
      store.commit("loanFlags/setServiceabilityFlags", {
        netSurplusAmount: affordability?.net_surplus?.value,
        pei: affordability?.pei?.value,
        fpd: summary?.first_payment_date?.verification,
        frequency: summary?.payment_frequency?.verification,
        customer: summary?.customer_name?.verification,
        pei_before_repayment: +(
          affordability?.pei?.value +
          convertRepaymentAmountToFortnightly(
            summary.repayment_amount?.value,
            summary?.payment_frequency?.value
          )
        ).toFixed(2)
      });
    }
  );

  function convertRepaymentAmountToFortnightly(repaymentAmount, frequency) {
    let default_frequency = "Fortnightly";
    let frequencyConversionToFortnightly = {
      Weekly: 1,
      Fortnightly: 2,
      Monthly: parseFloat(52 / 12)
    };

    let weeks = frequencyConversionToFortnightly[frequency];
    return (
      (repaymentAmount / weeks) *
      frequencyConversionToFortnightly[default_frequency]
    );
  }

  function getRequestTypeTitle(slug) {
    let splitArray = slug.split("_");
    let title = splitArray[splitArray.length - 1];
    return title.charAt(0).toUpperCase() + title.slice(1);
  }

  watch(
    () => documentRequestList.value,
    docRequests => {
      let completed = docRequests.filter(
        request => request.uploaded_at !== null
      );
      let pending = docRequests.filter(request => !request.uploaded_at);

      store.commit("loanFlags/setDocRequest", {
        completed: {
          count: completed.length,
          types: completed
            .map(request => getRequestTypeTitle(request.doc_type_slug))
            .join(",")
        },
        pending: {
          count: pending.length,
          types: pending
            .map(request => getRequestTypeTitle(request.doc_type_slug))
            .join(",")
        }
      });
    }
  );

  watch(
    () => creditCheckData.value,
    checkData => {
      let creditVerfData = !!checkData;
      creditVerification.verified = creditVerfData;
      creditVerification.message = creditVerfData
        ? "Credit check done"
        : "Credit check not done";
      store.commit("loanFlags/setCreditCheckLoading", false);
    }
  );

  return {
    basic,
    history,
    banking,
    serviceability,
    tags,
    docsAndRebuttals,
    tagString,
    creditVerification,
    rebuttalCounts,
    rebuttalCategories,
    docRequestFlags,
    fetchingLnErrorTags,
    fetchingDocReq,
    creditCheckLoading
  };
};
