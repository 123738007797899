<template>
  <span class="text-theme-7">
    <svg
      class="feather feather-plus w-4 h-4 mr-1 stroke-[2px]"
      fill="none"
      height="24"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="12" x2="12" y1="5" y2="19"></line>
      <line x1="5" x2="19" y1="12" y2="12"></line>
    </svg>
  </span>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {}
});
</script>
