import { computed, onMounted, reactive, ref, watch } from "vue";
import feather from "feather-icons";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { useStore } from "@/store";
import Swal from "sweetalert2";
import { customSwalMessageBuilder } from "./utils/customSwalMessageBuilder";
import { useRoute, useRouter } from "vue-router";
import { authGetterService } from "@/services/auth/auth-getter-service";
import Toastify from "toastify-js";
import { handleErrorResponse } from "@/services/auth/responseHandler/handle-response-service";

export const customerFollowUpDashboard = () => {
  const selectedFollowUpType = ref("");
  const { buildSwalSuccess, buildSwalHtmlError } = customSwalMessageBuilder();
  const tableRef = ref();
  const tabulator = ref();
  const filter = reactive({
    field: "fullname",
    type: "like",
    value: "",
    from: "",
    to: ""
  });
  const fromDate = reactive({
    field: "from",
    type: ">=",
    value: ""
  });
  const toDate = reactive({
    field: "to",
    type: "<=",
    value: ""
  });
  const newStatus = ["New"];

  const pendingStatus = ["In Progress"];

  const completedStatus = ["Closed"];

  const approvalRejectionDataObject = reactive({
    uuid: "",
    note: "",
    title: "",
    type: ""
  });

  const route = useRoute();
  const router = useRouter();
  const path = computed(() => route.path);
  const store = useStore();
  const assessmentUuidToNavigate = ref("");
  const adminUser = computed(() => store.state.auth.loggedUser);
  const reqForFollowUpDetailsNavigationCounter = computed(
    () => store.state.loanFollowUps.reqForFollowUpDetailsNavigationCounter
  );

  watch(
    () => reqForFollowUpDetailsNavigationCounter.value,
    () => {
      if (assessmentUuidToNavigate.value !== "") {
        router.push("/loan-follow-up-detail/" + assessmentUuidToNavigate.value);
      }
    }
  );

  const loanFollowUpsBucketList = computed(
    () => store.state.loanFollowUps.loanFollowUpsBucketList
  );

  const loanFollowUpsActivityStats = computed(
    () => store.state.loanFollowUps.loanFollowUpsActivityStats
  );

  const recentFollowUps = computed(
    () => store.state.loanFollowUps.recentFollowUps
  );

  const loanFollowUpsQueryParam = computed(
    () => store.state.loanFollowUps.loanFollowUpsQueryParam
  );

  const busyLoanFollowUpsListPage = computed(
    () => store.state.loanFollowUps.busyLoanFollowUpsListPage
  );

  watch(
    () => busyLoanFollowUpsListPage.value,
    isBusy => {
      if (isBusy) store.commit("main/setFullPageLoading");
      else store.commit("main/resetFullPageLoading");
    }
  );

  const loanFollowUpsBucketListUpdateCounter = computed(
    () => store.state.loanFollowUps.loanFollowUpsBucketListUpdateCounter
  );

  watch(
    () => loanFollowUpsBucketListUpdateCounter.value,
    () => {
      tabulator.value.replaceData();
    }
  );

  const loanFollowUpsSuccessMessage = computed(
    () => store.state.loanFollowUps.loanFollowUpsSuccessMessage
  );

  watch(
    () => loanFollowUpsSuccessMessage.value,
    message => {
      if (message) {
        Swal.fire(buildSwalSuccess(message)).then(confirmed => {
          if (confirmed.isConfirmed) {
            store.commit("loanFollowUps/clearMessages");
          }
        });
      }
    }
  );

  const loanFollowUpsAlertMessage = computed(
    () => store.state.loanFollowUps.loanFollowUpsAlertMessage
  );

  watch(
    () => loanFollowUpsAlertMessage.value,
    message => {
      if (message !== "") {
        document.getElementById("alert-note-message-body").innerHTML = message;
        Toastify({
          node: cash("#success-alert-toast-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
        store.commit("loanFollowUps/clearAlertMessages");
        store.commit("main/resetFullPageLoading");
      }
    }
  );

  const loanFollowUpsErrorMessage = computed(
    () => store.state.loanFollowUps.loanFollowUpsErrorMessage
  );

  watch(
    () => loanFollowUpsErrorMessage.value,
    message => {
      if (message) {
        Swal.fire(buildSwalHtmlError(message)).then(confirmed => {
          if (confirmed.isConfirmed) {
            store.commit("loanFollowUps/clearMessages");
          }
        });
      }
    }
  );

  function init() {
    store.dispatch("loanFollowUps/getFollowUpActivityStatsOfAuthLender");
    store.dispatch("loanFollowUps/getRecentLoanFollowUpsOfAuthAgent");
  }

  function onAssignFollowUpAndGetReqSubmit() {
    if (selectedFollowUpType.value !== "") {
      store.commit("main/setFullPageLoading");
      store.dispatch(
        "loanFollowUps/selfAssignAndGetLoanFollowUps",
        selectedFollowUpType.value
      );
    } else {
      store.dispatch("globalToastMessage/showSimpleToast", {
        message: "Follow Up Type cannot be None !",
        type: "error"
      });
    }
  }

  function getLoanFollowUpsListAction() {
    store.dispatch("loanFollowUps/getLoanFollowUpsList");
  }

  function onStartFollowUp(ln_follow_up_uuid) {
    assessmentUuidToNavigate.value = ln_follow_up_uuid;
    store.commit("main/setFullPageLoading");
    store.dispatch("loanFollowUps/startFollowUp", ln_follow_up_uuid);
  }

  function onReqNavToFollowUpDetails(ln_follow_up_uuid) {
    assessmentUuidToNavigate.value = ln_follow_up_uuid;
    store.commit("main/setFullPageLoading");
    store.commit(
      "loanFollowUps/incrementReqForFollowUpDetailsNavigationCounter"
    );
  }

  function getIconNameAsPerRecordStatus(status) {
    if ("New" === status) return "file-plus";
    else if ("In Progress" === status) return "clock";
    else if ("Closed" === status) return "pause-circle";
  }

  function getFollowUpStartActionAsPerAdminRole() {}

  function compareExistingBucketWithNewListAndDisplayAppropriateMessage(
    newListData
  ) {
    let newRecord = true;
    if (loanFollowUpsBucketList.value.length === newListData.length) {
      newRecord = false;
      for (let item of newListData) {
        if (
          !loanFollowUpsBucketList.value.map(a => a.uuid).includes(item.uuid)
        ) {
          newRecord = true;
          break;
        }
      }
    }

    if (newRecord) {
      return "Successfully fetched data in Bucket";
    }
    return "No new Record to fetch for bucket or bucket limit might have reached. New changes if any on existing records have been updated.";
  }

  onMounted(() => {
    init();
    initTabulator();
    reInitOnResizeWindow();
  });

  const initTabulator = () => {
    tabulator.value = new Tabulator(tableRef.value, {
      ajaxURL:
        process.env.VUE_APP_BASE_URL + "/getLoanApplicationFollowUpsOfAssignee",
      ajaxConfig: {
        method: "GET", //set request type to GET
        headers: authGetterService.getAuthHeader()
      },
      ajaxRequesting: function(url, params) {
        store.commit("loanFollowUps/setLoanFollowUpsQueryParam", {
          params
        });
        //url - the URL of the request
        //params - the parameters passed with the request
      },
      ajaxResponse: function(url, params, response) {
        //url - the URL of the request
        //params - the parameters passed with the request
        //response - the JSON object returned in the body of the response.
        if (loanFollowUpsBucketListUpdateCounter.value > 0) {
          store.commit(
            "loanFollowUps/setLoanFollowUpsAlertMessage",
            compareExistingBucketWithNewListAndDisplayAppropriateMessage(
              response.data
            )
          );
        }
        store.commit("loanFollowUps/setLoanFollowUpsList", response.data);

        return response.data; //return the response data to tabulator
      },
      filterMode: "remote",
      sortMode: "remote",
      printAsHtml: true,
      printStyled: true,
      reactiveData: true, //enable reactive data
      layout: "fitColumns",
      responsiveLayout: "collapse",
      placeholder: "No matching records found",
      columns: [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false
        },

        // For HTML table
        {
          title: "CUSTOMER",
          minWidth: 150,
          responsive: 0,
          field: "fullname",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            return `<div>
                <div class="font-medium whitespace-nowrap"><a target="_blank" href="https://fundo.com.au/adminpanel/payments/schedules/${
                  cell.getData().uuid
                }"><u>${cell.getData().fullname}</u></a></div>
                <div class="text-gray-600 text-xs whitespace-nowrap"><i>${
                  cell.getData().email
                }</i></div>
                <div class="text-gray-600 text-xs whitespace-nowrap">${
                  cell.getData().mobile
                }</div>
              </div>`;
          }
        },
        {
          title: "Start At",
          minWidth: 100,
          field: "start_follow_up_at",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            return `<div>
                <div class="font-medium whitespace-nowrap">${
                  cell.getData().start_follow_up_at
                }</div>
                </div>`;
          }
        },
        {
          title: "STATUS",
          minWidth: 100,
          field: "status",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            return `<div class="flex items-center lg:justify-center ${
              newStatus.includes(cell.getData().status)
                ? "text-theme-9"
                : completedStatus.includes(cell.getData().status)
                ? "text-blue-400"
                : pendingStatus.includes(cell.getData().status)
                ? "text-yellow-400"
                : "text-red-600"
            }">
                <i data-feather="${getIconNameAsPerRecordStatus(
                  cell.getData().status
                )}" class="w-4 h-4 mr-2"></i> ${cell.getData().status}
              </div>`;
          }
        },
        {
          title: "ACTIONS",
          minWidth: 100,
          field: "ACTIONS",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            const a = cash(
              `<div class="flex lg:justify-left items-left"></div>`
            );
            const c = cash(`<div></div>`);
            if (
              adminUser.value.permissions.includes(
                "lnfollowups.update.status"
              ) &&
              cell.getData().status === "New"
            ) {
              cash(a)
                .append(`<a class="flex items-left text-blue-400 mr-3" href="javascript:;">
                  <i data-feather="play" class="w-4 h-4 mr-1"></i> Start Follow Up
                </a>`);
              cash(cash(a).children()[0]).on("click", function() {
                onStartFollowUp(cell.getData().uuid);
              });
              cash(c).append(a[0]);
            } else if (
              adminUser.value.permissions.includes(
                "lnfollowups.update.status"
              ) &&
              (cell.getData().status === "In Progress" ||
                cell.getData().status === "Contact Failed")
            ) {
              cash(a)
                .append(`<a class="flex items-left text-blue-400 mr-3" href="javascript:;">
                  <i data-feather="list" class="w-4 h-4 mr-1"></i> View Follow Up Details
                </a>`);
              cash(cash(a).children()[0]).on("click", function() {
                onReqNavToFollowUpDetails(cell.getData().uuid);
              });
              cash(c).append(a[0]);
            }
            return c[0];
          }
        }
      ]
    });
    tabulator.value.on("dataLoadError", function(errorResponse) {
      handleErrorResponse(errorResponse);
    });

    tabulator.value.on("renderComplete", function() {
      feather.replace({
        "stroke-width": 1.5
      });
    });
  };

  // Redraw table onresize
  const reInitOnResizeWindow = () => {
    window.addEventListener("resize", () => {
      tabulator.value.redraw();
      feather.replace({
        "stroke-width": 1.5
      });
    });
  };

  // Filter function
  const onFilter = () => {
    const filterBuilder = [];
    filterBuilder.push({
      field: filter.field,
      type: filter.type,
      value: filter.value
    });
    if (fromDate.value !== "") {
      filterBuilder.push({
        field: fromDate.field,
        type: fromDate.type,
        value: fromDate.value
      });
    }
    if (toDate.value !== "") {
      filterBuilder.push({
        field: toDate.field,
        type: toDate.type,
        value: toDate.value
      });
    }

    tabulator.value.setFilter(filterBuilder);
  };

  // On reset filter
  const onResetFilter = () => {
    filter.field = "fullname";
    filter.type = "like";
    filter.value = "";

    fromDate.field = "from";
    fromDate.type = ">=";
    fromDate.value = "";

    toDate.field = "to";
    toDate.type = "<=";
    toDate.value = "";
    onFilter();
  };

  // Export

  const onExport = () => {
    store.commit("main/setFullPageLoading");
    store.dispatch(
      "loanFollowUps/requestForExportingList",
      loanFollowUpsQueryParam.value
    );
  };

  return {
    selectedFollowUpType,
    adminUser,
    tableRef,
    fromDate,
    toDate,
    filter,
    onFilter,
    onResetFilter,
    onExport,
    path,
    loanFollowUpsActivityStats,
    recentFollowUps,
    loanFollowUpsBucketList,
    getLoanFollowUpsListAction,
    getFollowUpStartActionAsPerAdminRole,
    approvalRejectionDataObject,
    onAssignFollowUpAndGetReqSubmit,
    init
  };
};
