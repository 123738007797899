<template>
  <div>
    <CreateNewRescheduleForm :customer-id="customerId" />
    <CreateNewPromiseForm />
    <RecordPaidPaymentForm />
    <RecordRefundedPaymentForm />
    <AddFeesForm />
    <WaiveFeesForm />
    <ChangePaymentDateForm />
    <ChangePaymentFrequencyForm />
    <NewCardPaymentForm />

    <div class="dropdown ml-auto sm:ml-0">
      <button
        id="hamburger-action-button"
        class="dropdown-toggle btn px-2 box text-gray-700 dark:text-gray-300"
        aria-expanded="false"
      >
        <span class="w-5 h-5 flex items-center justify-center">
          <MenuIcon class="w-4 h-4" />
        </span>
      </button>
      <div class="dropdown-menu w-60">
        <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
          <div
            class="cursor-pointer text-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
            @click="createNewReschedule"
          >
            Create New Reschedule
          </div>
          <div
            class="cursor-pointer text-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
            @click="createNewPromise"
          >
            Create New Promise
          </div>
          <div
            class="cursor-pointer text-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
            @click="recordPaidPayment"
          >
            Record Paid Payment
          </div>
          <div
            class="cursor-pointer text-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
            @click="recordRefundedPayment"
          >
            Record Refunded Payment
          </div>
          <div
            class="cursor-pointer text-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
            @click="addFees"
          >
            Add Fees
          </div>
          <div
            class="cursor-pointer text-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
            @click="waiveFees"
          >
            Waive Fees
          </div>
          <div
            class="cursor-pointer text-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
            @click="changePaymentDate"
          >
            Change Payment Date
          </div>
          <div
            class="cursor-pointer text-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
            @click="changePaymentFrequency"
          >
            Change Payment Frequency
          </div>
          <div
            class="cursor-pointer text-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
            @click="newCardPayment"
          >
            New Card Payment
          </div>
          <div
            class="cursor-pointer text-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
            @click="refreshAllPayments"
          >
            Refresh All Payments
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { loanActions } from "@/composables/components/payment-schedules-categorized-by-type/loan-actions/loanActions";
import ChangePaymentFrequencyForm from "@/components/payment-schedules-categorized-by-type/loan-actions/ChangePaymentFrequencyForm";
import CreateNewRescheduleForm from "@/components/payment-schedules-categorized-by-type/loan-actions/CreateNewRescheduleForm";
import CreateNewPromiseForm from "@/components/payment-schedules-categorized-by-type/loan-actions/CreateNewPromiseForm";
import RecordPaidPaymentForm from "@/components/payment-schedules-categorized-by-type/loan-actions/RecordPaidPaymentForm";
import RecordRefundedPaymentForm from "@/components/payment-schedules-categorized-by-type/loan-actions/RecordRefundedPaymentForm";
import AddFeesForm from "@/components/payment-schedules-categorized-by-type/loan-actions/AddFeesForm";
import WaiveFeesForm from "@/components/payment-schedules-categorized-by-type/loan-actions/WaiveFeesForm";
import ChangePaymentDateForm from "@/components/payment-schedules-categorized-by-type/loan-actions/ChangePaymentDateForm";
import NewCardPaymentForm from "@/components/payment-schedules-categorized-by-type/loan-actions/NewCardPaymentForm";

export default defineComponent({
  components: {
    NewCardPaymentForm,
    ChangePaymentDateForm,
    WaiveFeesForm,
    AddFeesForm,
    RecordRefundedPaymentForm,
    RecordPaidPaymentForm,
    CreateNewPromiseForm,
    CreateNewRescheduleForm,
    ChangePaymentFrequencyForm
  },
  props: {
    applicationId: {
      type: Number,
      require: false
    },
    customerId: {
      type: Number,
      require: true
    }
  },
  setup(props) {
    const {
      createNewReschedule,
      createNewPromise,
      recordPaidPayment,
      recordRefundedPayment,
      addFees,
      waiveFees,
      changePaymentDate,
      changePaymentFrequency,
      newCardPayment,
      refreshAllPayments
    } = loanActions(props);

    return {
      createNewReschedule,
      createNewPromise,
      recordPaidPayment,
      recordRefundedPayment,
      addFees,
      waiveFees,
      changePaymentDate,
      changePaymentFrequency,
      newCardPayment,
      refreshAllPayments
    };
  }
});
</script>

<style scoped></style>
