import { useStore } from "@/store";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";

export const lendingRoleTypeAuthenticators = () => {
  const store = useStore();
  const route = useRoute();
  const adminUser = computed(() => store.state.auth.loggedUser);
  const path = computed(() => route.path);
  const assessorPageType = ref(path.value.split("/")[2]);

  /**
   * Admin Roles and corresponding page checker
   * @returns {boolean}
   */

  function checkIfLender() {
    return (
      adminUser.value.roles.includes("lending-manager") ||
      adminUser.value.roles.includes("lending-agent") ||
      adminUser.value.roles.includes("lending-converter")
    );
  }

  function checkIfManagerAndManagerPage() {
    return (
      adminUser.value.roles.includes("lending-manager") &&
      assessorPageType.value === "lending-manager"
    );
  }

  function checkIfAgentAndAgentPage() {
    return (
      adminUser.value.roles.includes("lending-agent") &&
      assessorPageType.value === "lending-agent"
    );
  }

  function checkIfConverterAndConverterPage() {
    return (
      adminUser.value.roles.includes("lending-converter") &&
      assessorPageType.value === "lending-converter"
    );
  }

  function checkIfManagerAndManagerReqDeclineAssessmentPage() {
    return (
      adminUser.value.roles.includes("lending-manager") &&
      assessorPageType.value === "lending-manager"
    );
  }

  /**
   * status modifying privilege checkers
   * @param status
   * @returns {boolean}
   */

  function checkIfAssesseable(status) {
    return (
      adminUser.value.roles.includes("lending-agent") &&
      assessorPageType.value === "lending-agent" &&
      ["Assigned"].includes(status)
    );
  }

  function checkIfWithdrawableOrDeclinable(status) {
    // check for lending manager and corresponding page
    if (
      adminUser.value.roles.includes("lending-manager") &&
      [
        "New",
        "Assigned",
        "In Progress",
        "In Review",
        "In Decline Review",
        "Request Decline"
      ].includes(status) &&
      assessorPageType.value === "lending-manager"
    ) {
      return true;
    }

    return (
      // check for lending agent and corresponding page
      (adminUser.value.roles.includes("lending-agent") &&
        ["In Progress", "In Review"].includes(status) &&
        assessorPageType.value === "lending-agent") ||
      // check for lending converter and corresponding page
      (adminUser.value.roles.includes("lending-converter") &&
        ["Pre Conversion Check"].includes(status) &&
        assessorPageType.value === "lending-converter")
    );
  }

  function checkIfcanBeRequestedToDecline(status) {
    return (
      adminUser.value.roles.includes("lending-agent") &&
      assessorPageType.value === "lending-agent" &&
      ["In Progress"].includes(status)
    );
  }

  function checkIfCompleteable(status) {
    return (
      adminUser.value.roles.includes("lending-agent") &&
      assessorPageType.value === "lending-agent" &&
      ["In Progress"].includes(status)
    );
  }

  function checkIfReviewable(status) {
    return (
      adminUser.value.roles.includes("lending-agent") &&
      assessorPageType.value === "lending-agent" &&
      ["Completed"].includes(status)
    );
  }

  function checkIfApprovable(status) {
    return (
      adminUser.value.roles.includes("lending-manager") &&
      assessorPageType.value === "lending-manager" &&
      ["In Review", "In Decline Review"].includes(status)
    );
  }

  function checkIfPreConvertionCheckeable(status) {
    return (
      adminUser.value.roles.includes("lending-converter") &&
      assessorPageType.value === "lending-converter" &&
      ["Approved"].includes(status)
    );
  }

  function checkIfPreConvertionCanBeFinalizedOrFailed(status) {
    return (
      adminUser.value.roles.includes("lending-converter") &&
      assessorPageType.value === "lending-converter" &&
      ["Pre Conversion Check"].includes(status)
    );
  }

  function checkIfReAssignable(status) {
    return (
      adminUser.value.roles.includes("lending-manager") &&
      assessorPageType.value === "lending-manager" &&
      [
        "Completed",
        "In Review",
        "In Decline Review",
        "Request Decline"
      ].includes(status)
    );
  }

  return {
    checkIfLender,
    checkIfAgentAndAgentPage,
    checkIfManagerAndManagerPage,
    checkIfConverterAndConverterPage,
    checkIfManagerAndManagerReqDeclineAssessmentPage,
    checkIfAssesseable,
    checkIfWithdrawableOrDeclinable,
    checkIfcanBeRequestedToDecline,
    checkIfCompleteable,
    checkIfReviewable,
    checkIfApprovable,
    checkIfPreConvertionCheckeable,
    checkIfPreConvertionCanBeFinalizedOrFailed,
    checkIfReAssignable
  };
};
