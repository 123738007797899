<template>
  <div>
    <div
      :class="[
        { 'bg-theme-32': isActive },
        `loan-${getActiveApplicationQualityClass()}`
      ]"
      class="flex mt-1 p-4 box cursor-pointer section-header"
      @click="setNavActiveSection('personal-loans-title')"
    >
      <h2
        id="personal-loans-title"
        class="font-medium text-theme-7 text-base w-full"
      >
        PERSONAL LOANS
      </h2>
      <ChevronDownIcon :class="{ 'transform rotate-180': !isActive }" />
    </div>
    <div
      v-if="isActive"
      id="personal-loans-dts-section"
      class="grid grid-cols-2 gap-6"
    >
      <template v-if="!fetchingPersonalLoansDetails">
        <div
          :class="{
            'opacity-50 pointer-events-none': updatingPersonalLoansDetails
          }"
          class="col-span-12"
        >
          <UpdateLoader :show="updatingPersonalLoansDetails" />
          <!-- BEGIN: Striped Rows -->
          <div class="box">
            <div
              class="
                flex flex-col
                sm:flex-row
                items-center
                p-2
                border-b border-gray-200
              "
            >
              <h2 class="font-medium text-theme-3 text-base mr-auto">
                Personal Loans Information
              </h2>
            </div>
            <div id="personal-loans-info-table" class="p-2">
              <div class="preview">
                <div class="overflow-x-auto">
                  <table class="table">
                    <thead>
                      <tr>
                        <th
                          class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                        >
                          Loan Provider
                        </th>
                        <th
                          class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                        >
                          Loan Type
                        </th>
                        <th
                          class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                        >
                          Loan Date
                        </th>
                        <th
                          class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                        >
                          Predicted Closing Date
                        </th>
                        <th
                          class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                        >
                          Loan Amount
                        </th>
                        <th
                          class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                        >
                          Total Paid
                        </th>
                        <th
                          class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                        >
                          Repayment Amount
                        </th>
                        <th
                          class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                        >
                          Frequency
                        </th>
                        <th
                          class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      v-if="
                        Object.keys(aggregatorPersonalLoanDetails).length > 0
                      "
                      id="personal-loans-details"
                      :key="updateCounter"
                      class="p-2"
                    >
                      <tr
                        v-for="(plDts,
                        plDtsKey) in aggregatorPersonalLoanDetails"
                        :key="plDtsKey"
                        :class="{
                          'bg-gray-200 dark:bg-dark-1': plDtsKey % 2 < 1
                        }"
                        class="border"
                      >
                        <td class="border-b dark:border-dark-5">
                          {{ plDts.loan_provider }}
                        </td>
                        <td class="border-b dark:border-dark-5">
                          <select
                            :id="`${plDts.loan_id}_type`"
                            v-model="plDts.loan_type"
                            aria-label=".form-select-sm example"
                            class="form-select form-select-sm mt-0 w-full"
                            @change="
                              onCloseAndSubmitPersonalLoanType(
                                plDts.loan_id,
                                $event,
                                plDts,
                                `${plDts.loan_id}_type`
                              )
                            "
                          >
                            <option
                              v-for="(type, key) in loanTypes"
                              :key="key"
                              :value="type"
                            >
                              {{ type }}
                            </option>
                          </select>
                        </td>
                        <td class="border-b dark:border-dark-5">
                          {{ plDts.loan_date }}
                        </td>
                        <td class="border-b dark:border-dark-5">
                          {{ plDts.predicted_closing_date }}
                        </td>
                        <td class="border-b dark:border-dark-5">
                          {{ plDts.loan_amount }}
                        </td>
                        <td class="border-b dark:border-dark-5">
                          {{ plDts.loan_repaid }}
                        </td>
                        <td class="border-b dark:border-dark-5">
                          <input
                            :id="`${plDts.loan_id}_amount`"
                            :value="plDts.repayment_amount"
                            class="form-control mt-0 p-1 w-3/5"
                            min="0"
                            type="number"
                            @blur="
                              $event =>
                                onCloseAndSubmitPersonalLoanRepaymentAmount(
                                  plDts.loan_id,
                                  $event,
                                  plDts,
                                  `${plDts.loan_id}_amount`
                                )
                            "
                            @keyup.enter="$event.target.blur()"
                          />
                        </td>
                        <td class="border-b dark:border-dark-5">
                          <select
                            :id="`${plDts.loan_id}_freq`"
                            v-model="plDts.frequency"
                            aria-label=".form-select-sm example"
                            class="form-select form-select-sm mt-0 w-full"
                            @change="
                              onCloseAndSubmitPersonalLoanFrequency(
                                plDts.loan_id,
                                $event,
                                plDts,
                                `${plDts.loan_id}_freq`
                              )
                            "
                          >
                            <option
                              v-for="(freq, key) in frequency"
                              :key="key"
                              :value="freq"
                            >
                              {{ freq }}
                            </option>
                            <option disabled value="Unknown">Unknown</option>
                          </select>
                        </td>
                        <td class="border-b dark:border-dark-5">
                          <select
                            :id="`${plDts.loan_id}_status`"
                            v-model="plDts.loan_status"
                            aria-label=".form-select-sm example"
                            class="form-select form-select-sm mt-0 w-full"
                            @change="
                              onCloseAndSubmitPersonalLoanStatus(
                                plDts.loan_id,
                                $event,
                                `${plDts.loan_id}_status`
                              )
                            "
                          >
                            <option
                              v-for="(pls, plsKey) in personalLoanStatuses"
                              :key="plsKey"
                              :disabled="
                                disabledLoanStatus.shouldDisable(pls.value)
                              "
                              :value="pls.value"
                            >
                              {{ pls.name }}
                            </option>
                          </select>
                        </td>
                        <td></td>
                      </tr>
                    </tbody>

                    <tbody>
                      <tr
                        v-for="(newLoan, index) in newLoans"
                        :key="index"
                        :class="{
                          'bg-gray-200 dark:bg-dark-1': index % 2 < 1
                        }"
                        class="border"
                      >
                        <td>
                          <input
                            v-model="newLoan.loan_provider"
                            class="form-control mt-0 p-1 loan-input"
                            type="text"
                          />
                          <div
                            v-if="
                              hasErrors &&
                                (!newLoan.loan_provider ||
                                  newLoan.loan_provider === '')
                            "
                            class="text-red-500  validation-text"
                          >
                            *required
                          </div>
                        </td>
                        <td>
                          <select
                            v-model="newLoan.loan_type"
                            class="p-1 rounded border loan-input"
                          >
                            <option
                              v-for="(value, key) in loanTypes"
                              :key="key"
                              :value="value"
                              >{{ value }}</option
                            >
                          </select>
                          <div
                            v-if="hasErrors && !newLoan.loan_type"
                            class="text-red-500 validation-text"
                          >
                            *required
                          </div>
                        </td>
                        <td class="text-center">---</td>
                        <td class="text-center">---</td>
                        <td class="text-center">---</td>
                        <td class="text-center">---</td>
                        <td>
                          <input
                            v-model="newLoan.repayment_amount"
                            class="form-control mt-0 p-1 w-3/5 loan-input"
                            type="number"
                          />
                          <div
                            v-if="hasErrors && !newLoan.repayment_amount > 0"
                            class="text-red-500 validation-text"
                          >
                            *required and > 0
                          </div>
                        </td>
                        <td>
                          <select
                            v-model="newLoan.loan_frequency"
                            class="p-1 rounded border loan-input"
                          >
                            <option
                              v-for="(item, index) in frequencies"
                              :key="index"
                              :value="item.value"
                              >{{ item.name }}</option
                            >
                          </select>
                          <div
                            v-if="hasErrors && newLoan.loan_frequency === ''"
                            class="text-red-500 validation-text"
                          >
                            *required
                          </div>
                        </td>
                        <td>
                          <select
                            v-model="newLoan.actions"
                            class="p-1 rounded border loan-input"
                          >
                            <option
                              v-for="(item, index) in actions"
                              :key="index"
                              :value="item.value"
                              >{{ item.name }}</option
                            >
                          </select>
                        </td>
                        <td>
                          <XCircleIcon
                            class=" w-4 h-4"
                            @click="removeLoanRow(index)"
                          />
                        </td>
                      </tr>
                    </tbody>
                    <noRecordTblTemplate
                      v-show="
                        Object.keys(aggregatorPersonalLoanDetails).length === 0
                      "
                    />
                  </table>
                </div>
              </div>

              <!--  Handling Errors on adding new loans-->
              <div
                v-if="errorMessage !== '' || newLoansErrorMessage !== ''"
                class="alert alert-danger-soft show  mb-2 p-2 pt-4 mt-1"
                role="alert"
              >
                <div class="flex justify-between">
                  <div class="w-4/5">
                    <AlertOctagonIcon class="w-6 h-6 mr-2" />
                    {{ errorMessage }} {{ newLoansErrorMessage }}
                  </div>
                  <div class="1/5">
                    <button
                      v-if="errorMessage !== '' || newLoansErrorMessage !== ''"
                      class="btn btn-rounded bg-theme-6 w-1/2 text-white sm:w-auto mr-2 "
                      @click="clearErrors"
                    >
                      <AlertCircleIcon class="w-6 h-6 mr-2" />
                      Clear Errors
                    </button>
                  </div>
                </div>
              </div>

              <div
                v-if="
                  validationErrors.length > 0 ||
                    Object.keys(validationErrors).length > 0
                "
                class="alert alert-danger-soft show   mb-1"
                role="alert"
              >
                <div class="flex justify-between">
                  <div class="w-4/5">
                    <div v-if="validationErrors.length > 0">
                      <div
                        v-for="(error, index) in validationErrors"
                        :key="index"
                      >
                        {{ error }}
                      </div>
                    </div>
                    <div v-if="Object.keys(validationErrors).length > 0">
                      <div
                        v-for="key in Object.keys(validationErrors)"
                        :key="key"
                      >
                        {{ validationErrors[key][0] }}
                      </div>
                    </div>
                  </div>
                  <div class="1/5">
                    <button
                      class="btn btn-rounded bg-theme-6 w-1/2 text-white sm:w-auto mr-2 "
                      @click="clearErrors"
                    >
                      <AlertCircleIcon class="w-6 h-6 mr-2" />
                      Clear Errors
                    </button>
                  </div>
                </div>
              </div>

              <div
                class="flex flex-col sm:flex-row justify-end items-center p-2 border-b border-gray-200"
              >
                <div class="end-actions flex flex-1 w-40">
                  <div v-if="saccGraceAction.actionable" class="toggle-grace">
                    <button
                      class="btn bg-theme-3 text-white form-control"
                      @click="saccGraceAction.action"
                    >
                      {{ saccGraceAction.slug }} SACC Grace
                    </button>
                  </div>
                </div>
                <button
                  v-if="newLoans.length > 0"
                  class="btn btn-rounded bg-primary-1 w-1/2 text-white sm:w-auto mr-2"
                  @click="saveAllNewLoans"
                >
                  <SaveIcon class="w-6 h-6 mr-2" />
                  Save
                </button>

                <button
                  class="btn btn-rounded bg-theme-3 w-1/2 text-white sm:w-auto mr-2"
                  @click="addNewLoanRow"
                >
                  <PlusCircleIcon class="w-6 h-6 mr-2" />
                  Add Row
                </button>
              </div>
            </div>
          </div>
          <!-- END: Striped Rows -->
        </div>
      </template>
      <FetchingRecordDivTemplate v-else marginTop="0" />
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  ref,
  watch,
  nextTick,
  onUpdated,
  reactive
} from "vue";
import { useStore } from "@/store";
import noRecordTblTemplate from "@/components/no-record-tbl-template/Main.vue";
import FetchingRecordDivTemplate from "@/components/fetching-record-div-template/Main";
import UpdateLoader from "../common/Loader.vue";
import { loanQuality } from "@/composables/loanQuality";

export default defineComponent({
  components: {
    noRecordTblTemplate,
    FetchingRecordDivTemplate,
    UpdateLoader
  },
  props: {
    aggregatorPersonalLoanDetails: {
      type: Object,
      default: () => ({})
    },
    isActive: {
      type: Boolean,
      default: false,
      required: true
    },
    setNavActiveSection: {
      type: Function,
      require: true,
      default: null
    },
    adminUser: {
      type: Object,
      default: () => ({})
    },
    applicationId: {
      type: Number,
      require: true
    },
    personalLoanStatuses: {
      type: Array,
      default: () => []
    },
    onCloseAndSubmitPersonalLoanStatus: {
      type: Function,
      default: null
    },
    onCloseAndSubmitPersonalLoanType: {
      type: Function,
      default: null
    },
    onCloseAndSubmitPersonalLoanRepaymentAmount: {
      type: Function,
      default: null
    },
    onCloseAndSubmitPersonalLoanFrequency: {
      type: Function,
      default: null
    },
    onClosePersonalLoanStatusSelectBox: {
      type: Function,
      default: null
    },
    onCloseAgreggatorInputBox: {
      type: Function,
      default: null
    },
    onClickTransformToInput: {
      type: Function,
      default: null
    },
    enabledAgreggatorDataField: {
      type: String,
      default: ""
    },
    fetchingApplicantBankAccounts: {
      type: Boolean,
      default: false
    },
    updatingPersonalLoansDetails: {
      type: Boolean,
      default: false
    },
    bankAccountOfApplicantLinkedToAssessment: {
      type: Object,
      default: () => ({})
    },
    activeInputId: {
      type: String,
      required: true,
      default: ""
    }
  },
  setup(props) {
    const updateCounter = ref(0);
    const activeElement = ref("");
    const store = useStore();
    const frequency = ["Monthly", "Fortnightly", "Weekly"];

    const fetchingPersonalLoansDetails = computed(
      () => store.state.loanAssessmentInfos.fetchingPersonalLoansDetails
    );

    init();

    function init() {
      clearNewLoans();
      store.commit("personalLoans/clearErrors");
      store.dispatch(
        "loanAssessmentInfos/getAggregatorPersonalLoansForLoanAssessment",
        { showLoader: true }
      );
    }

    let newLoansErrorMessage = ref("");
    const loanTypes = ["SACC Loans", "Non SACC Loans", "Wage Advance", "BNPL"];
    const actions = [
      { name: "On Going", value: "ongoing" },
      { name: "Closing soon", value: "closing_soon" }
    ];
    const frequencies = [
      { name: "Weekly", value: "Weekly" },
      { name: "Monthly", value: "Monthly" },
      { name: "Fortnightly", value: "Fortnightly" }
    ];

    const disabledLoanStatus = {
      status: ["graced"],
      shouldDisable: function(status) {
        return this.status.includes(status);
      }
    };

    const validationErrors = computed(
      () => store.state.personalLoans.validationErrors
    );
    const errorMessage = computed(() => store.state.personalLoans.errorMessage);

    const newLoans = computed(() => store.state.personalLoans.newLoans);

    const hasErrors = computed(() => {
      return (
        validationErrors.value.length > 0 || newLoansErrorMessage.value !== ""
      );
    });

    const successCounter = computed(
      () => store.state.personalLoans.successCounter
    );

    function clearErrors() {
      newLoansErrorMessage.value = "";
      store.commit("personalLoans/clearErrors");
    }
    //Methods
    function addNewLoanRow() {
      store.commit("personalLoans/addNewLoan");
    }
    function removeLoanRow(index) {
      store.commit("personalLoans/removeLoanIndex", index);
    }
    function clearNewLoans() {
      store.commit("personalLoans/clearNewLoans");
    }
    function saveAllNewLoans() {
      if (!validateNewLoans()) {
        newLoansErrorMessage.value =
          "Please check the new loan details and try again ";
        return;
      }
      //validate the loan -
      const primaryBankAccount = props.bankAccountOfApplicantLinkedToAssessment.find(
        bank => bank.debit_account
      )?.account_number;
      store.dispatch("personalLoans/addMultiplePersonalLoans", {
        loan_bank: primaryBankAccount,
        admin_id: props.adminUser.id,
        application_id: props.applicationId
      });
    }

    function validateNewLoans() {
      let validated = true;
      newLoans.value.forEach((loan, index) => {
        if (!loan.loan_provider || loan.loan_provider === "") validated = false;
        if (!loan.loan_type) validated = false;
        if (!loan.repayment_amount > 0) validated = false;
        if (!loan.loan_frequency) validated = false;
      });
      return validated;
    }

    onUpdated(() => {
      if (activeElement.value) {
        let focusable = Array.from(
          document.querySelectorAll(
            "input[type=text]:not([disabled]),input[type=number]:not([disabled]), select:not([disabled])"
          )
        );
        let focusedInput = document.getElementById(activeElement.value);
        let index = focusable.indexOf(focusedInput);
        if (index > -1 && index < focusable.length - 1) {
          activeElement.value = "";
          let nextElement = focusable[index + 1];
          nextTick(() => nextElement.focus());
        }
      }
    });

    const { getActiveApplicationQualityClass } = loanQuality();

    //END adding new Loans

    // Sacc Grace Fetch and Update
    const saccGraceData = computed(
      () => store.state.loanAssessmentInfos.saccGraceInformation
    );
    const saccGraceAction = reactive({
      actionable: computed(
        () =>
          saccGraceData.value &&
          (saccGraceData.value.isEligible || saccGraceData.value.enabled)
      ),
      slug: computed(() =>
        saccGraceData.value.isEligible && !saccGraceData.value.enabled
          ? "Enable"
          : "Disable"
      ),
      getSaccLoanDetails: () =>
        store.dispatch("loanAssessmentInfos/getClosableSaccLoanDetails"),
      action: function() {
        saccGraceData.value.enabled
          ? this.resetClosedLoans()
          : this.closeLoans();
      },
      closeLoans: () => store.dispatch("loanAssessmentInfos/closeSaccLoans"),
      resetClosedLoans: () =>
        store.dispatch("loanAssessmentInfos/resetClosedSaccLoans")
    });
    saccGraceAction.getSaccLoanDetails();
    // END Sacc Grace Fetch and Update

    watch(
      () => props.aggregatorPersonalLoanDetails,
      _ => {
        updateCounter.value++;
        activeElement.value = props.activeInputId;
      },
      { deep: true }
    );

    watch(
      () => successCounter.value,
      value => {
        store.dispatch(
          "loanAssessmentInfos/getAggregatorPersonalLoansForLoanAssessment",
          { showLoader: true }
        );
        store.dispatch(
          "loanAssessmentInfos/getAggregatorIncomeExpensesDetailsForLoanAssessment"
        );
        store.dispatch(
          "loanAssessmentInfos/getAggregatorLendingDetailsForLoanAssessment",
          {
            showLoader: true
          }
        );
      }
    );

    return {
      fetchingPersonalLoansDetails,
      updateCounter,
      init,
      frequency,
      addNewLoanRow,
      loanTypes,
      newLoans,
      actions,
      disabledLoanStatus,
      frequencies,
      validationErrors,
      errorMessage,
      clearErrors,
      saveAllNewLoans,
      newLoansErrorMessage,
      hasErrors,
      removeLoanRow,
      saccGraceAction,
      getActiveApplicationQualityClass
    };
  }
});
</script>
<style scoped>
table td {
  padding: 2px !important;
}
table tr {
  padding: 2px !important;
}
.loan-input {
  width: 95%;
}
.validation-text {
  font-size: x-small;
}

#personal-loans-info-table thead tr th {
  text-align: center;
}

#personal-loans-info-table tbody#personal-loans-details tr td {
  text-align: center;
}
</style>
