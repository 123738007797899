import { datadogRum } from "@datadog/browser-rum";

export function init(user) {
  //initialise on first attempt
  if (!datadogRum.getInitConfiguration()) {
    datadogRum.init({
      applicationId: process.env.VUE_APP_DD_APPID,
      clientToken: process.env.VUE_APP_DD_TOKEN,
      service: process.env.VUE_APP_DD_SERVICE,
      version: process.env.VUE_APP_DD_VERSION,
      env: process.env.VUE_APP_DD_ENV,
      site: "datadoghq.com",
      allowedTracingUrls: ["https://lms.fundo.com.au", /https:\/\/*\.fundo\.com\.au/, (url) => url.startsWith("https://lms.fundo.com.au")],
      defaultPrivacyLevel: "mask-user-input",
      sampleRate: 100,
      trackInteractions: true
    });
  }
  datadogRum.getInternalContext()
  datadogRum.setUser({
    id: user.id,
    name: user.fullname,
    email: user.email
  });
  datadogRum.startSessionReplayRecording();
}
