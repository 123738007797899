<template>
  <div
    v-if="applicationId > 0 && !fetchingKYC && showMenu"
    class="
      cursor-pointer
      fixed
      bottom-36
      left-6
      dark:bg-dark-2
      h-48
      flex
      items-center
      justify-center
      z-50
      mb-0
      mr-56
    "
    :class="{ 'w-16': isGlobalNotesDisplayed, 'w-40': !isGlobalNotesDisplayed }"
  >
    <!-- BEGIN: Striped Rows -->
    <div class="intro-y box mb-5 mt-5 bg-theme-26 bg-opacity-50 shadow-2xl">
      <div id="activity-overview-table" class="p-1">
        <div class="preview">
          <div class="overflow-x-auto">
            <table class="table">
              <thead>
                <tr>
                  <th
                    class="
                      border-b-2
                      dark:border-dark-5
                      text-white
                      whitespace-nowrap
                    "
                    style="padding: 6px !important"
                  >
                    {{ !isGlobalNotesDisplayed ? "Quick Links" : "QL" }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="isGlobalNotesDisplayed">
                <tr
                  v-for="(
                    quickLink, qlKey
                  ) in assessmentDetailShortQuickLinksArray"
                  :key="qlKey"
                  class="hover:bg-theme-10"
                  @click="changeSectionAndScroll(quickLink)"
                >
                  <td
                    class="border-none dark:border-dark-5 text-white"
                    style="padding: 4px !important"
                  >
                    <a :target-id="quickLink.hyper_link">{{
                      quickLink.title
                    }}</a>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr
                  v-for="(quickLink, qlKey) in assessmentDetailQuickLinksArray"
                  :key="qlKey"
                  class="hover:bg-theme-10"
                  @click="changeSectionAndScroll(quickLink)"
                >
                  <td
                    class="border-none dark:border-dark-5 text-white"
                    style="padding: 4px !important"
                  >
                    <a :target-id="quickLink.hyper_link">{{
                      quickLink.title
                    }}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Striped Rows -->
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "@/store";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();
    const store = useStore();

    const processingInternalNavClick = ref(false);

    async function changeSectionAndScroll(quickLink) {
      let id = quickLink.hyper_link.replace("#", "");
      await store.dispatch("quickNavLinks/addSectionToActiveSection", id);
      scrollToElementAtOneThirdPosToViewport(id);
    }

    function scrollToElementAtOneThirdPosToViewport(idString) {
      /*
      The scrolling event after click to Nav action brings the element at 1/3rd position of
      viewport vertically. This puts targeted element always within upper half of viewport.
      The link corresponding to element in upper half of viewport gets highlighted automatically by handler of
      scroll listener.
       */
      processingInternalNavClick.value = true;

      const element = document.getElementById(idString);
      const elementRect = element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const middle = absoluteElementTop - window.innerHeight / 3;
      // window.scrollTo(0, middle);
      window.scrollTo({
        top: middle,
        left: 0,
        behavior: "smooth"
      });

      processingInternalNavClick.value = false;
      highLightNavMenuLink(
        document.querySelector('[target-id="#' + idString + '"]')
      );
    }

    const isGlobalNotesDisplayed = computed(
      () => store.state.timelineNotesViewer.isGlobalNotesDisplayed
    );

    const applicationId = computed(
      () => store.state.loanAssessmentInfos.applicationId
    );

    const fetchingKYC = computed(
      () => store.state.customerAndLoanDetails.fetchingKYC
    );

    const fetchingLoan = computed(
      () => store.state.customerAndLoanDetails.fetchingLoan
    );

    const fetchingPaymentCards = computed(() => store.state.paymentCards.busy);

    const getActiveSection = computed(
      () => store.state.quickNavLinks.activeSection
    );

    const showMenu = computed(() => store.state.quickNavLinks.showMenu);

    watch(
      () => getActiveSection.value,
      (newVal) => {
        if (newVal.length) scrollToElementAtOneThirdPosToViewport(newVal);
      }
    );

    let assessmentDetailQuickLinksArray = [
      {
        hyper_link: "#kyc-title",
        title: "KYC"
      },
      {
        hyper_link: "#application-detail-title",
        title: "Application Detail"
      },
      {
        hyper_link: "#employment-detail-title",
        title: "Employment / Accommodation Detail"
      },
      {
        hyper_link: "#loan-history-with-fundo-title",
        title: "Loan History With Fundo"
      },
      {
        hyper_link: "#bank-details-title",
        title: "Bank Details"
      },
      {
        hyper_link: "#payment-detail-title",
        title: "Payment Cards"
      },
      {
        hyper_link: "#personal-loans-title",
        title: "Personal Loans"
      },
      {
        hyper_link: "#income-and-expense-details-title",
        title: "Income / Expense"
      },
      {
        hyper_link: "#lending-details-title",
        title: "Lending Details"
      },
      {
        hyper_link: "#loan-tags-title",
        title: "Loan Tags"
      },
      {
        hyper_link: "#doc-req-title",
        title: "Doc Requests"
      },
      {
        hyper_link: "#loan-rebuttal-title",
        title: "Loan Rebuttals"
      },
      {
        hyper_link: "#verification-detail-title",
        title: "Verification Details"
      }
    ];
    let assessmentDetailShortQuickLinksArray = [
      {
        hyper_link: "#kyc-title",
        title: "KYC"
      },
      {
        hyper_link: "#application-detail-title",
        title: "App Dts"
      },
      {
        hyper_link: "#employment-detail-title",
        title: "Emp/Acco Detail"
      },
      {
        hyper_link: "#loan-history-with-fundo-title",
        title: "Fn Ln Hist"
      },
      {
        hyper_link: "#bank-details-title",
        title: "BD"
      },
      {
        hyper_link: "#payment-detail-title",
        title: "PayC"
      },
      {
        hyper_link: "#personal-loans-title",
        title: "PL"
      },
      {
        hyper_link: "#income-and-expense-details-title",
        title: "I/E Dts"
      },
      {
        hyper_link: "#lending-details-title",
        title: "Len Dts"
      },
      {
        hyper_link: "#loan-tags-title",
        title: "Tags"
      },
      {
        hyper_link: "#doc-req-title",
        title: "DocReq"
      },
      {
        hyper_link: "#verification-detail-title",
        title: "Verification"
      }
    ];

    function initAssessmentDetailQuickLinksArray() {
      if (
        route.path.split("/")[1] === "loan-assessment-detail" &&
        route.path.split("/")[2] === "lending-converter"
      ) {
        assessmentDetailQuickLinksArray.splice(3, 0, {
          hyper_link: "#ln-approval-pre-check-title",
          title: "Pre Checks"
        });
        assessmentDetailShortQuickLinksArray.splice(3, 0, {
          hyper_link: "#ln-approval-pre-check-title",
          title: "Pre Checks"
        });
      }
    }

    function elementInAroundMiddleOfViewport(elementId) {
      const ele = document.getElementById(elementId);
      if (!ele) {
        return false;
      }
      const { top, bottom } = ele.getBoundingClientRect();

      return (top > 0 || bottom > 0) && top < window.innerHeight / 2;
    }

    function handleScroll() {
      if (!processingInternalNavClick.value) {
        assessmentDetailQuickLinksArray.forEach((qlink) => {
          if (
            elementInAroundMiddleOfViewport(qlink.hyper_link.replace("#", ""))
          ) {
            const qlinkDom = document.querySelector(
              '[target-id="' + qlink.hyper_link + '"]'
            );
            if (qlinkDom !== undefined) {
              highLightNavMenuLink(qlinkDom);
            }
          }
        });
      }
    }

    function highLightNavMenuLink(navLinkDom) {
      if (navLinkDom) {
        navLinkDom.parentNode.parentNode.parentNode.children.forEach((tr) => {
          tr.classList.remove("bg-theme-10");
        });
        navLinkDom.parentNode.parentNode.classList.add("bg-theme-10");
      }
    }

    onMounted(() => {
      initAssessmentDetailQuickLinksArray();
      store.dispatch("quickNavLinks/resetActiveSection");
      document.addEventListener("scroll", handleScroll);
    });

    return {
      applicationId,
      fetchingKYC,
      fetchingLoan,
      showMenu,
      fetchingPaymentCards,
      isGlobalNotesDisplayed,
      assessmentDetailQuickLinksArray,
      assessmentDetailShortQuickLinksArray,
      scrollToElementAtOneThirdPosToViewport,
      changeSectionAndScroll
    };
  }
});
</script>
