import { computed, onMounted, reactive, ref, watch } from "vue";
import xlsx from "xlsx";
import feather from "feather-icons";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { useStore } from "@/store";
import Swal from "sweetalert2";
import { customSwalMessageBuilder } from "./utils/customSwalMessageBuilder";
import { useRoute } from "vue-router";
import { payoutEnums } from "./enums/payoutEnums";
import { authGetterService } from "../services/auth/auth-getter-service";
import fileDownload from "js-file-download";
import { handleErrorResponse } from "@/services/auth/responseHandler/handle-response-service";
import cash from "cash-dom/dist/cash";

export const allPayouts = () => {
  const {
    buildSwalWarning,
    buildSwalSuccess,
    buildSwalHtmlError
  } = customSwalMessageBuilder();
  const { getStatusParamForPayoutViews } = payoutEnums();
  const tableRef = ref();
  const tabulator = ref();
  const filter = reactive({
    field: "fullname",
    type: "like",
    value: "",
    from: "",
    to: ""
  });
  const fromDate = reactive({
    field: "from",
    type: ">=",
    value: ""
  });
  const toDate = reactive({
    field: "to",
    type: "<=",
    value: ""
  });
  const newStatus = ["New"];

  const errorStatus = ["Pre Disbursal Check Failed", "Disbursal Failed"];

  const successStatus = ["Settled"];

  const cancelledStatus = ["Cancelled"];

  const route = useRoute();
  const path = computed(() => route.path);
  const statusParam = getStatusParamForPayoutViews(path.value);
  const displayedDate =
    path.value === "/disbursed-payouts" ? "DISBURSAL DATE" : "CONVERSION DATE";
  const baseUrlSuffix = statusParam !== "" ? "?" + statusParam : "";
  const store = useStore();
  const adminUser = computed(() => store.state.auth.loggedUser);
  const totalDisbursedFund = ref(0);
  const totalDisbursals = ref(0);

  const payoutPreCheckFailureLogs = computed(
    () => store.state.payoutPreCheckFailureLogs.preCheckFailureLogs
  );

  const showPreCheckLogs = logs => {
    store.commit("payoutPreCheckFailureLogs/setLogs", logs);
    cash("#header-footer-slide-over-preview").modal("show");
  };

  const payoutAccountBalance = computed(
    () => store.state.disbursalPayout.payoutAccountBalance
  );

  const disbursalPayoutList = computed(
    () => store.state.disbursalPayout.disbursalPayoutList
  );

  const disbursalQueryParam = computed(
    () => store.state.disbursalPayout.disbursalQueryParam
  );

  const disbursalPayoutListUpdateCounter = computed(
    () => store.state.disbursalPayout.disbursalPayoutListUpdateCounter
  );

  watch(
    () => disbursalPayoutListUpdateCounter.value,
    () => {
      tabulator.value.replaceData();
    }
  );

  const excelReportBlob = computed(
    () => store.state.disbursalPayout.excelReportBlob
  );

  watch(
    () => excelReportBlob.value,
    excelData => {
      fileDownload(excelData, "payouts.xlsx");
    }
  );

  const disbursalPayoutSuccessMessage = computed(
    () => store.state.disbursalPayout.disbursalPayoutSuccessMessage
  );

  watch(
    () => disbursalPayoutSuccessMessage.value,
    message => {
      if (message) {
        Swal.fire(buildSwalSuccess(message)).then(confirmed => {
          if (confirmed.isConfirmed) {
            store.commit("disbursalPayout/clearMessages");
          }
        });
      }
    }
  );

  const disbursalPayoutAlertMessage = computed(
    () => store.state.disbursalPayout.disbursalPayoutAlertMessage
  );

  watch(
    () => disbursalPayoutAlertMessage.value,
    message => {
      if (message) {
        Swal.fire(buildSwalWarning(message)).then(confirmed => {
          if (confirmed.isConfirmed) {
            store.commit("disbursalPayout/clearAlertMessages");
          }
        });
      }
    }
  );

  const disbursalPayoutErrorMessage = computed(
    () => store.state.disbursalPayout.disbursalPayoutErrorMessage
  );

  watch(
    () => disbursalPayoutErrorMessage.value,
    message => {
      if (message) {
        Swal.fire(buildSwalHtmlError(message)).then(confirmed => {
          if (confirmed.isConfirmed) {
            store.commit("disbursalPayout/clearMessages");
          }
        });
      }
    }
  );

  function onResetStatus(e) {
    Swal.fire(buildSwalWarning("Are you sure to renew?", true)).then(
      confirmed => {
        if (confirmed.isConfirmed) {
          store.commit("main/setFullPageLoading");
          store.dispatch("disbursalPayout/resetDisbursalStatus", e);
        }
      }
    );
  }

  function onDisbursalSubmit(e) {
    Swal.fire(buildSwalWarning("Are you sure to Disburse?", true)).then(
      confirmed => {
        if (confirmed.isConfirmed) {
          store.commit("main/setFullPageLoading");
          store.dispatch("disbursalPayout/sendForDisbursal", e);
        }
      }
    );
  }

  function onCancelSubmit(e) {
    Swal.fire(buildSwalWarning("Are you sure to Cancel?", true)).then(
      confirmed => {
        if (confirmed.isConfirmed) {
          store.commit("main/setFullPageLoading");
          store.dispatch("disbursalPayout/sendForCancellation", e);
        }
      }
    );
  }

  function getDisbursalPayoutsAction() {
    store.dispatch("disbursalPayout/getDisbursalPayoutList");
  }

  onMounted(() => {
    store.commit("disbursalPayout/unInitializePayoutAccountBalance");
    store.dispatch("disbursalPayout/getAccountBalanceFromPayoutProvider");
    initTabulator();
    reInitOnResizeWindow();
  });

  const initTabulator = () => {
    tabulator.value = new Tabulator(tableRef.value, {
      ajaxURL:
        process.env.VUE_APP_BASE_URL + "/loanDisbursalPayouts" + baseUrlSuffix,
      ajaxConfig: {
        method: "GET", //set request type to Position
        headers: authGetterService.getAuthHeader()
      },
      ajaxRequesting: function(url, params) {
        store.commit("disbursalPayout/setDisbursalQueryParam", {
          params,
          statusParam
        });
        //url - the URL of the request
        //params - the parameters passed with the request
      },
      ajaxResponse: function(url, params, response) {
        //url - the URL of the request
        //params - the parameters passed with the request
        //response - the JSON object returned in the body of the response.
        totalDisbursedFund.value =
          response.total_amount === undefined || response.total_amount === null
            ? 0
            : response.total_amount;
        totalDisbursals.value =
          response.total_disbursals === undefined ||
          response.total_disbursals === null
            ? 0
            : response.total_disbursals;
        return response; //return the response data to tabulator
      },
      filterMode: "remote",
      sortMode: "remote",
      printAsHtml: true,
      printStyled: true,
      reactiveData: true, //enable reactive data
      pagination: true,
      paginationMode: "remote",
      paginationSize: 10,
      paginationSizeSelector: [10, 20, 30, 40],
      layout: "fitColumns",
      responsiveLayout: "collapse",
      placeholder: "No matching records found",
      columns: [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false
        },

        // For HTML table
        {
          title: "CUSTOMER NAME",
          minWidth: 150,
          responsive: 0,
          field: "fullname",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            return `<div>
                <div class="font-medium whitespace-nowrap"><a target="_blank" href="/customer/${
                  cell.getData().customer_id
                }/loan-application/${cell.getData().application_id}"><u>${
              cell.getData().fullname
            }</u></a></div>
                <div class="text-gray-600 text-xs whitespace-nowrap"><i>${
                  cell.getData().email
                }</i></div>
                <div class="text-gray-600 text-xs whitespace-nowrap">${
                  cell.getData().mobile
                }</div>
              </div>`;
          }
        },
        {
          title: "AMOUNT",
          minWidth: 100,
          field: "amount",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            return `<div>
                <div class="font-medium whitespace-nowrap">${
                  cell.getData().amount
                }</div>
              </div>`;
          }
        },
        {
          title: displayedDate,
          minWidth: 100,
          field: "matures_at",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            return `<div>
                <div class="font-medium whitespace-nowrap">${
                  cell.getData().disbursed_at !== undefined
                    ? cell.getData().disbursed_at
                    : cell.getData().matures_at
                }</div>
                </div>`;
          }
        },
        {
          title: "STATUS",
          minWidth: 200,
          field: "status",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            const statusBar = cash(`<div><div class="flex items-center lg:justify-center ${
              newStatus.includes(cell.getData().status)
                ? "text-theme-9"
                : errorStatus.includes(cell.getData().status)
                ? "text-theme-6"
                : successStatus.includes(cell.getData().status)
                ? "text-theme-9"
                : cancelledStatus.includes(cell.getData().status)
                ? "text-yellow-600"
                : "text-yellow-400"
            }">
                <i data-feather="${
                  newStatus.includes(cell.getData().status)
                    ? "file-plus"
                    : successStatus.includes(cell.getData().status)
                    ? "check-square"
                    : errorStatus.includes(cell.getData().status)
                    ? "alert-octagon"
                    : cancelledStatus.includes(cell.getData().status)
                    ? "slash"
                    : "loader"
                }" class="w-4 h-4 mr-2"></i> ${cell.getData().status}
              </div></div>`);

            if (
              newStatus.includes(cell.getData().status) &&
              cell.getData().last_staged_disbursal &&
              cell.getData().last_staged_disbursal.permanent_fail_on_vendor > 0
            ) {
              cash(statusBar).append(
                `<div class="text-xs whitespace-nowrap text-theme-11"><i>&nbsp;[Previously failed on '${
                  cell.getData().last_staged_disbursal.vendor
                }']</i></div>`
              );
            }
            return statusBar[0];
          }
        },
        {
          title: "ACTIONS",
          minWidth: 200,
          field: "actions",
          responsive: 1,
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            const a = cash(
              `<div class="flex lg:justify-center items-center"></div>`
            );
            if (
              errorStatus.includes(cell.getData().status) &&
              adminUser.value.permissions.includes(
                "payouts.update.status.renew"
              )
            ) {
              cash(a)
                .append(`<a class="flex items-center text-blue-400 mr-3" href="javascript:;">
                  <i data-feather="refresh-ccw" class="w-4 h-4 mr-1"></i> Renew
                </a>`);
              cash(cash(a).children()[0]).on("click", function() {
                onResetStatus(cell.getData().uuid);
              });
            } else if (
              newStatus.includes(cell.getData().status) &&
              adminUser.value.permissions.includes(
                "payouts.update.status.disburse"
              )
            ) {
              cash(a)
                .append(`<a class="flex items-center text-theme-9 mr-3" href="javascript:;">
                  <i data-feather="download" class="w-4 h-4 mr-1"></i> Disburse
                </a><a class="flex items-center text-red-500 mr-3" href="javascript:;">
                  <i data-feather="x-circle" class="w-4 h-4 mr-1"></i> Cancel
                </a>`);
              cash(cash(a).children()[0]).on("click", function() {
                onDisbursalSubmit(cell.getData().uuid);
              });
              cash(cash(a).children()[1]).on("click", function() {
                onCancelSubmit(cell.getData().uuid);
              });
            }

            if (
              typeof cell.getData().pre_check_failure_logs !== "undefined" &&
              cell.getData().pre_check_failure_logs.length > 0
            ) {
              cash(a)
                .append(`<a class="flex items-center text-yellow-500 mr-3" href="javascript:;">
                  <i data-feather="list" class="w-4 h-4 mr-1"></i> Pre Check Logs
                </a>`);
              cash(cash(a).children()[cash(a).children().length - 1]).on(
                "click",
                function() {
                  showPreCheckLogs(cell.getData().pre_check_failure_logs);
                }
              );
            }

            return a[0];
          }
        }
        // For print format
      ]
    });

    tabulator.value.on("dataLoadError", function(errorResponse) {
      handleErrorResponse(errorResponse);
    });

    tabulator.value.on("renderComplete", function() {
      feather.replace({
        "stroke-width": 1.5
      });
    });
  };

  // Redraw table onresize
  const reInitOnResizeWindow = () => {
    window.addEventListener("resize", () => {
      tabulator.value.redraw();
      feather.replace({
        "stroke-width": 1.5
      });
    });
  };

  // Filter function
  const onFilter = () => {
    const filterBuilder = [];
    filterBuilder.push({
      field: filter.field,
      type: filter.type,
      value: filter.value
    });
    if (fromDate.value !== "") {
      filterBuilder.push({
        field: fromDate.field,
        type: fromDate.type,
        value: fromDate.value
      });
    }
    if (toDate.value !== "") {
      filterBuilder.push({
        field: toDate.field,
        type: toDate.type,
        value: toDate.value
      });
    }

    tabulator.value.setFilter(filterBuilder);
  };

  // On reset filter
  const onResetFilter = () => {
    filter.field = "fullname";
    filter.type = "like";
    filter.value = "";

    fromDate.field = "from";
    fromDate.type = ">=";
    fromDate.value = "";

    toDate.field = "to";
    toDate.type = "<=";
    toDate.value = "";
    onFilter();
  };

  // Export
  const onExportCsv = () => {
    tabulator.value.download("csv", "data.csv");
  };

  const onExportJson = () => {
    tabulator.value.download("json", "data.json");
  };

  const onExportXlsx = () => {
    const win = window;
    win.XLSX = xlsx;
    tabulator.value.download("xlsx", "data.xlsx", {
      sheetName: "Products"
    });
  };

  const onExportHtml = () => {
    tabulator.value.download("html", "data.html", {
      style: true
    });
  };

  // Print
  const onPrint = () => {
    tabulator.value.print();
  };

  const onExport = () => {
    store.commit("main/setFullPageLoading");
    store.dispatch(
      "disbursalPayout/requestForExportingList",
      disbursalQueryParam.value
    );
  };

  return {
    payoutAccountBalance,
    totalDisbursedFund,
    totalDisbursals,
    adminUser,
    payoutPreCheckFailureLogs,
    showPreCheckLogs,
    tableRef,
    fromDate,
    toDate,
    filter,
    onFilter,
    onResetFilter,
    onExportCsv,
    onExportJson,
    onExportXlsx,
    onExportHtml,
    onPrint,
    onExport,
    path,
    disbursalPayoutList,
    getDisbursalPayoutsAction
  };
};
