<template>
  <div
    class="
      bg-theme-16
      dark-mode-switcher
      cursor-pointer
      shadow-md
      fixed
      bottom-0
      right-0
      box
      dark:bg-dark-2
      border
      rounded-full
      w-40
      h-12
      flex
      items-center
      justify-center
      z-50
      mb-10
      mr-52
    "
    :style="[isGlobalNotesDisplayed ? { 'margin-right': '700px' } : '']"
    @click="onToggleIsGlobalNotesDisplayed"
  >
    <div class="mr-4 text-white">
      <ListIcon class="w-4 h-4" />
      View Notes
    </div>
    <!-- BEGIN: Failed Notification Content -->
    <div id="failed-note-submit-content" class="toastify-content hidden flex">
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Submit failed!</div>
        <div id="failed-note-message-body" class="text-gray-600 mt-1">
          Note Content Cannot be empty.
        </div>
      </div>
    </div>
    <!-- END: Failed Notification Content -->
    <!-- BEGIN: Success Notification Content -->
    <div id="success-note-submit-content" class="toastify-content hidden flex">
      <XCircleIcon class="text-theme-3" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Success!</div>
        <div id="passed-note-message-body" class="text-gray-600 mt-1">
          Note added successfully, scroll a little down to find the note that
          got just added.
        </div>
      </div>
    </div>
    <!-- END: Success Notification Content -->
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  props: {
    applicationId: Number,
    associatedModelKey: String,
    associatedModelId: Number,
    associatedModelUuid: {
      default: null,
      type: String,
      required: false
    }
  },
  setup(props) {
    const store = useStore();
    // const tabulator = ref();
    const dataLoaded = ref(false);

    const noteEnabled = computed(
      () => store.state.timelineNotesViewer.noteEnabled
    );

    const isGlobalNotesDisplayed = computed(
      () => store.state.timelineNotesViewer.isGlobalNotesDisplayed
    );

    function onToggleIsGlobalNotesDisplayed() {
      store.commit("main/expandSideMenu");
      store.commit("timelineNotesViewer/toggleIsGlobalNotesDisplayed");
      if (!dataLoaded.value) {
        getTimelineNotes();
        store.dispatch(
          "timelineNotesViewer/getWrittenSMSTextForCardVerfAndArrearsOnLoan"
        );
        dataLoaded.value = true;
      }
    }

    const gettingNotes = computed(
      () => store.state.timelineNotesViewer.gettingNotes
    );
    const busy = computed(() => store.state.timelineNotesViewer.busy);

    const timelineNotesList = computed(
      () => store.state.timelineNotesViewer.timelineNotesList
    );

    const toggleNoteViewer = () => {
      // everytime set correct applicationId and note post method
      store.commit("timelineNotesViewer/toggleNoteEnabled");
    };

    const loanNote = ref("");

    function getTimelineNotes() {
      store.dispatch("timelineNotesViewer/getTimelineNotes");
    }

    onMounted(() => {
      store.commit("timelineNotesViewer/setGettingNotes");
      store.commit(
        "timelineNotesViewer/setAssociatedModelKey",
        props.associatedModelKey
      );
    });

    store.commit("timelineNotesViewer/setApplicationId", props.applicationId);

    return {
      isGlobalNotesDisplayed,
      onToggleIsGlobalNotesDisplayed,
      timelineNotesList,
      toggleNoteViewer,
      loanNote,
      gettingNotes,
      busy,
      noteEnabled
    };
  }
});
</script>
<style scoped>
.report-timeline::before {
  content: "";
  height: 120%;
  @apply w-px bg-theme-15 ml-5 mt-5 dark:bg-dark-7;
}

.report-timeline .report-timeline__image::before {
  content: "";
  @apply w-20 h-px bg-theme-15 absolute mt-5 ml-5 dark:bg-dark-7;
}
</style>
