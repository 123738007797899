import { useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { authGetterService } from "@/services/auth/auth-getter-service";
import { handleErrorResponse } from "@/services/auth/responseHandler/handle-response-service";
import feather from "feather-icons";
import cmsStatuses from "@/constants/cms";
import { computed, onMounted, ref } from "vue";
import cash from "cash-dom/dist/cash";

export const cmsDetails = (props, load = false) => {
  const router = useRouter();
  const route = useRoute();

  const tableRef = ref();
  const tabulator = ref();
  const searchTerm = ref("");
  const showStatusFilter = ref(true);
  const selectedTabulator = ref(1);
  const baseUrl = process.env.VUE_APP_BASE_URL;
  const coreUrl = `${process.env.VUE_APP_LEGACY_APP_URL}`;

  const path = computed(() => route.path);
  const store = useStore();
  const adminUser = computed(() => store.state.auth.loggedUser);
  const validationErrors = computed(() => store.state.cms.validationErrors);
  const activeRecords = computed(() => store.state.cms.activeRecords);
  const activeRecord = computed(() => store.state.cms.activeRecord);
  const successMessage = computed(() => store.state.cms.successMessage);
  const isBusy = computed(() => store.state.cms.isBusy);

  const componentTitle = ref(props.title);

  const statuses = Object.values(cmsStatuses);

  const staticTabulatorApis = {
    get: "cms/pages",
    update: "cms/pages",
    delete: "cms/pages",
    changeStatus: "cms/pages/status",
    redirectUpdatePage: "content/edit/static-page"
  }
  const componentTabulatorApis = {
    get: "cms/components",
    update: "cms/component",
    delete: "cms/component",
    changeStatus: "cms/component/status",
    redirectUpdatePage: "content/edit/component"
  }

  const activeApis = ref(staticTabulatorApis);

  const setListActionButtons = (selectedView, actionApi) => {
    let redirectPathKey = selectedView === 1 ? "url_slug" : "target_page";
    return function (cell) {
      const a = cash(
        `<div class="flex lg:justify-center items-center"></div>`
      );

      cash(a)
        .append(`<a class="flex items-center text-theme-9 mr-3" href="javascript:;">
                  <i data-feather="eye" class="w-4 h-4 mr-1"></i> Preview
                </a><a class="flex items-center text-blue-600 mr-3" href="javascript:;">
                  <i data-feather="edit" class="w-4 h-4 mr-1"></i> Edit
                </a><a class="flex items-center text-red-500 mr-3" href="javascript:;">
                  <i data-feather="delete" class="w-4 h-4 mr-1"></i> Delete
                </a>`);
      cash(cash(a).children()[0]).on("click", function() {
        openPreview(cell.getData()[redirectPathKey]);
      });
      cash(cash(a).children()[1]).on("click", function() {
        redirectUpdatePage(cell.getData().id, actionApi.redirectUpdatePage);
      });
      cash(cash(a).children()[2]).on("click", function() {
        deletePage(cell.getData().id, actionApi.delete);
      });

      return a[0];
    }
  }
  const staticTabulatorColumns = [
    {
      formatter: "responsiveCollapse",
      width: 40,
      minWidth: 30,
      hozAlign: "center",
      resizable: false,
      headerSort: false
    },
    // For HTML table
    {
      title: "SN",
      maxWidth: 80,
      field: "id",
      vertAlign: "middle",
      print: false,
      download: false,
      headerSort: false,
      formatter: "rownum"
      // formatter(cell) {
      //   return `<div>
      //       <div class="font-medium whitespace-nowrap">
      //           ${cell.getData().id}
      //           </div>
      //     </div>`;
      // }
    },
    {
      title: "Title",
      minWidth: 100,
      field: "title",
      hozAlign: "center",
      vertAlign: "middle",
      tooltip: true,
      print: false,
      download: false,
      headerSort: false,
      formatter(cell) {
        return `<div class="overflow-hidden">
                <div class="font-medium whitespace-nowrap">${
          cell.getData().title
        }</div>
              </div>`;
      }
    },
    {
      title: "Slug",
      minWidth: 120,
      field: "slug",
      hozAlign: "center",
      vertAlign: "middle",
      print: false,
      download: false,
      headerSort: false,
      formatter(cell) {
        return `<div>
                <div class="whitespace-nowrap">${cell.getData().url_slug}</div>
              </div>`;
      }
    },
    {
      title: "H1",
      minWidth: 100,
      field: "h1",
      hozAlign: "center",
      vertAlign: "middle",
      headerHozAlign: "center",
      print: false,
      download: false,
      headerSort: false,
      tooltip: true,
      formatter(cell) {
        return `<div class="overflow-hidden">
                <div class="font-small whitespace-nowrap">${
          cell.getData().h1
        }</div>
              </div>`;
      }
    },
    {
      title: "Created By",
      minWidth: 120,
      field: "created_by",
      hozAlign: "center",
      vertAlign: "middle",
      headerHozAlign: "center",
      print: false,
      download: false,
      headerSort: false,
      formatter(cell) {
        return `<div>
                <div class="whitespace-nowrap">${
          cell.getData().created_by
        }</div>
              </div>`;
      }
    },
    {
      title: "Status",
      minWidth: 150,
      field: "status",
      hozAlign: "center",
      vertAlign: "middle",
      headerHozAlign: "center",
      print: false,
      download: false,
      headerSort: false,
      formatter(cell) {
        const a = cash(
          `<div class="flex lg:justify-center items-center"></div>`
        );
        cash(a)
          .append(`<select class="form-select form-select-sm mt-2" aria-label=".form-select-sm example">
                      <option value="1" ${
            cell.getData().is_published ? "selected" : ""
          }>Publish </option>
                      <option value="0" ${
            !cell.getData().is_published ? "selected" : ""
          }>UnPublish</option>
                      </select>`);
        cash(cash(a).children()[0]).on("change", function(event) {
          event.preventDefault();
          changePageStatus(cell.getData().id, event.target.value);
        });
        return a[0];
      }
    },
    {
      title: "Actions",
      minWidth: 150,
      hozAlign: "center",
      vertAlign: "middle",
      headerHozAlign: "center",
      print: false,
      download: false,
      headerSort: false,
      formatter: setListActionButtons(selectedTabulator.value, staticTabulatorApis)
    }
  ];

  const initTabulator = () => {
    tabulator.value = new Tabulator(tableRef.value, {
      initialFilter: [],
      ajaxURL: `${baseUrl}/${staticTabulatorApis.get}`,
      ajaxConfig: {
        method: "GET",
        headers: authGetterService.getAuthHeader()
      },

      ajaxResponse: function(url, params, response) {
        if (response.meta) {
          if (response.meta.last_page) {
            response["last_page"] = response.meta.last_page;
          }

          if (response.meta.current_page) {
            response["current_page"] = response.meta.current_page;
          }
        }
        store.commit("cms/setActiveRecords", response.data);
        return response;
      },
      filterMode: "remote",
      sortMode: "remote",
      printAsHtml: true,
      printStyled: true,
      reactiveData: true,
      pagination: true,
      paginationMode: "remote",
      paginationSize: 10,
      paginationSizeSelector: [10, 20, 30, 40],
      layout: "fitColumns",
      responsiveLayout: "collapse",
      placeholder: "No matching content found",
      columns: staticTabulatorColumns
    });
    tabulator.value.on("dataLoadError", function(errorResponse) {
      handleErrorResponse(errorResponse);
    });

    tabulator.value.on("renderComplete", function() {
      feather.replace({
        "stroke-width": 1.5
      });
    });
  };

  onMounted(() => {
    if (load) {
      initTabulator();
    }
  })
  const updateTabulatorBasedOnSelection = (value) => {
    selectedTabulator.value = value;
    selectedTabulator.value === '0' ? setComponentTableData() : setStaticTableData();
  }

  const setStaticTableData = () => {
    tabulator.value.clearData();
    tabulator.value.setData(`${baseUrl}/${staticTabulatorApis.get}`);
    tabulator.value.setColumns(staticTabulatorColumns);
    activeApis.value = staticTabulatorApis;
    showStatusFilter.value = true;
  }

  const setComponentTableData = () => {
    const componentTabulatorColumns = [
      {
        formatter: "responsiveCollapse",
        width: 40,
        minWidth: 30,
        hozAlign: "center",
        resizable: false,
        headerSort: false
      },
      // For HTML table
      {
        title: "SN",
        maxWidth: 80,
        field: "id",
        vertAlign: "middle",
        print: false,
        download: false,
        headerSort: false,
        formatter: "rownum"
      },
      {
        title: "Page Type",
        minWidth: 100,
        field: "target",
        hozAlign: "center",
        vertAlign: "middle",
        print: false,
        download: false,
        headerSort: false,
        formatter(cell) {
          return `<div>
                <div class="font-medium whitespace-nowrap">${
            cell.getData().target?.toUpperCase()
          }</div>
              </div>`;
        }
      },
      {
        title: "Page Path",
        minWidth: 100,
        field: "target_page",
        hozAlign: "center",
        vertAlign: "middle",
        print: false,
        download: false,
        headerSort: false,
        formatter(cell) {
          return `<div>
                <div class="font-medium whitespace-nowrap">${
            cell.getData().target_page
          }</div>
              </div>`;
        }
      },
      {
        title: "Component Type",
        minWidth: 100,
        field: "type",
        hozAlign: "center",
        vertAlign: "middle",
        headerHozAlign: "center",
        print: false,
        download: false,
        headerSort: false,
        formatter(cell) {
          return `<div>
                <div class="font-medium whitespace-nowrap">${cell.getData().type?.toUpperCase()}</div>
              </div>`;
        }
      },
      {
        title: "Created By",
        minWidth: 120,
        field: "created_by",
        hozAlign: "center",
        vertAlign: "middle",
        headerHozAlign: "center",
        print: false,
        download: false,
        headerSort: false,
        formatter(cell) {
          return `<div>
                <div class="whitespace-nowrap">${
            cell.getData().created_by
          }</div>
              </div>`;
        }
      },
      {
        title: "Actions",
        minWidth: 150,
        hozAlign: "center",
        vertAlign: "middle",
        headerHozAlign: "center",
        print: false,
        download: false,
        headerSort: false,
        formatter: setListActionButtons(selectedTabulator.value, componentTabulatorApis)
      }
    ]
    tabulator.value.clearData();
    tabulator.value.setData(`${baseUrl}/${componentTabulatorApis.get}`);
    tabulator.value.setColumns(componentTabulatorColumns);
    activeApis.value = componentTabulatorApis;
    showStatusFilter.value = false;
  }

  function getChildRoutes() {
    const rootRoutes = router.options.routes.filter(r => {
      return r.path === "/";
    });

    let matched = {
      'content-management-edit-page': '/content/edit',
      'content-management-edit-component': '/content/edit',
      'content-management-add-page': '/content/add',
      'content-management-add-component': '/content/add'
    }

    return rootRoutes[0].children.find(r => {
      return r.path === matched[route.name];
    }).children || [];
  }

  function openPreview(slug = "") {
    slug = slug.charAt(0) === "/" ? slug.substring(1) : slug;
    window.open(coreUrl.replace("api/lms", "") + slug, "_blank").focus();
  }

  function changePageStatus(id, value) {
    const response = store.dispatch("cms/updateStatus", { id, value });
    response.then(result => {
      if (result) {
        initTabulator();
      }
    });
  }

  function savePage(endpoint, payload) {
    store.dispatch("cms/savePage", {
      endpoint,
      payload
    });
  }

  function updatePage(endpoint, payload) {
    store.commit("main/setFullPageLoading", null, { root: true });
    store.dispatch("cms/updatePage", {
      endpoint,
      payload
    });
  }

  function deletePage(id, deleteEndpoint) {
    store.commit("main/setFullPageLoading", null, { root: true });
    const response = store.dispatch("cms/deletePage", { id, deleteEndpoint });
    response.then(_ => tabulator.value.setData());
  }

  async function redirectUpdatePage(id, redirectUrl) {
    let active = activeRecords.value.filter(record => record.id === id);
    if (active && active.length) {
      await store.commit("cms/setActivePage", active[0]);
      await router.push(`/${redirectUrl}/${id}`);
    }
  }

  // Filter function
  const onSearch = () => {
    const url = `${baseUrl}/${activeApis.value.get}?search=${searchTerm.value}`;
    tabulator.value.setData(url);
  };

  const onResetSearch = () => {
    searchTerm.value = "";
    onSearch();
  };

  const onFilter = status => {
    let filters = [];
    if (status !== "All") {
      filters.push({
        field: "is_published",
        type: "=",
        value: status
      });
    }
    tabulator.value.setFilter(filters);
  };

  function clearMessage() {
    store.commit("cms/setSuccessMessage", "");
    store.commit("cms/setValidationErrors", "");
  }

  return {
    adminUser,
    tableRef,
    statuses,
    searchTerm,
    onSearch,
    onFilter,
    onResetSearch,
    path,
    route,
    router,
    isBusy,
    openPreview,
    successMessage,
    clearMessage,
    activeRecord,
    savePage,
    updatePage,
    componentTitle,
    validationErrors,
    showStatusFilter,
    getChildRoutes,
    updateTabulatorBasedOnSelection,
    setStaticTableData,
    setComponentTableData
  };
};
