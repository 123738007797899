import { formatErrorMessage } from "@/utils/messageFormatter";
import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";

// state
const state = () => {
  return {
    transactions: [],
    isFetchingTransactions: false,
    isProcessingRefund: false,
    transactionIdToRefund: null,
    refundAmount: null,
    errorMessage: "",
    validationErrors: [],
    refundRequestSuccessCount: 0
  };
};

// actions
const actions = {
  getTransactions: async ({ commit, dispatch }, { applicationId }) => {
    commit("setIsFetchingTransactions", true);
    axiosWrapperService
      .axiosGet(`/applications/${applicationId}/transactions`)
      .then(response => {
        commit("setTransactions", response.data.data);
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: formatErrorMessage(error),
            type: "error"
          },
          { root: true }
        );
      })
      .finally(() => commit("setIsFetchingTransactions", false));
  },
  requestRefund: async ({ commit, dispatch, state }, { notes, amount }) => {
    commit("setIsProcessingRefund", true);
    axiosWrapperService
      .axiosPost(`/transactions/${state.transactionIdToRefund}/requestRefund`, {
        refund_notes: notes,
        transaction_amount: amount
      })
      .then(response => {
        commit("incrementRefundRequestSuccessCount");
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: `Refund Requested Successfully!`,
            type: "success"
          },
          { root: true }
        );
      })
      .catch(error => {
        if (error.response?.data?.errors) {
          commit("setValidationErrors", error.response.data.errors);
        } else {
          commit("setErrorMessage", formatErrorMessage(error));
        }
      })
      .finally(() => commit("setIsProcessingRefund", false));
  }
};

// mutations
const mutations = {
  //Transactions
  setTransactions(state, transactions) {
    state.transactions = transactions;
  },

  setIsProcessingRefund(state, isProcessing) {
    state.isProcessingRefund = isProcessing;
  },

  setIsFetchingTransactions(state, isFetching) {
    state.isFetchingTransactions = isFetching;
  },

  setTransactionToRefundId(state, transactionIdToRefund) {
    state.transactionIdToRefund = transactionIdToRefund;
  },

  setRefundAmount(state, amount) {
    state.refundAmount = amount;
  },

  setErrorMessage(state, message) {
    state.errorMessage = message;
  },
  setValidationErrors(state, validationErrors) {
    state.validationErrors = validationErrors;
  },

  clearErrors(state) {
    state.validationErrors = [];
    state.errorMessage = "";
  },

  incrementRefundRequestSuccessCount(state) {
    state.refundRequestSuccessCount++;
  }
};

// getters
const getters = {
  transactions: state => state.transactions,
  isFetchingTransactions: state => state.isFetchingTransactions,
  isProcessingRefund: state => state.isProcessingRefund,
  transactionIdToRefund: state => state.transactionIdToRefund,
  refundAmount: state => state.refundAmount,
  errorMessage: state => state.errorMessage,
  validationErrors: state => state.validationErrors,
  refundRequestSuccessCount: state => state.refundRequestSuccessCount
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
