export default {
  NEW: "New",
  ASSIGNED: "Assigned",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
  REQUEST_DECLINED: "Request Decline",
  DECLINED: "Declined",
  IN_REVIEW: "In Review",
  IN_DECLINE_REVIEW: "In Decline Review",
  WITHDRAWN: "Withdrawn",
  APPROVED: "Approved",
  PRE_CONVERSION_CHECK: "Pre Conversion Check",
  PRE_CONVERSION_FINALIZED: "Pre Conversion Finalized",
  PRE_CONVERSION_FAILED: "Pre Conversion Failed",
  CONVERTED: "Converted"
};
