import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";

const state = () => {
  return {
    isBusy: false,
    applicationId: 0,
    errorMessage: "",
    validationErrors: [],
    successCount: 0
  };
};

const getters = {
  isBusy: state => state.isBusy,
  applicationId: state => state.applicationId,
  errorMessage: state => state.errorMessage,
  validationErrors: state => state.validationErrors,
  successCount: state => state.successCount
};

const mutations = {
  setApplicationId(state, applicationId) {
    state.applicationId = applicationId;
  },

  setErrorMessage(state, message) {
    state.errorMessage = message;
  },
  setValidationErrors(state, validationErrors) {
    state.validationErrors = validationErrors;
  },

  setIsBusy(state, isBusy) {
    state.isBusy = isBusy;
  },

  incrementSuccessCount(state) {
    state.successCount++;
  },

  clearErrors(state) {
    state.validationErrors = [];
    state.errorMessage = "";
  }
};

const actions = {
  createReschedule: async ({ dispatch, state, commit }, { form }) => {
    commit("setIsBusy", true);
    commit("clearErrors");
    axiosWrapperService
      .axiosPost(`/applications/${state.applicationId}/createReschedule`, form)
      .then(() => {
        commit("incrementSuccessCount");
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: "Created Reschedule Successfully!",
            type: "success"
          },
          { root: true }
        );
      })
      .catch(error => {
        if (error.response?.data?.errors) {
          commit("setValidationErrors", error.response.data.errors);
        } else {
          commit("setErrorMessage", formatErrorMessage(error));
        }
      })
      .finally(() => {
        commit("setIsBusy", false);
      });
  },
  createPromise: async ({ dispatch, state, commit }, { form }) => {
    commit("setIsBusy", true);
    commit("clearErrors");
    axiosWrapperService
      .axiosPost(`/applications/${state.applicationId}/createPromise`, form)
      .then(() => {
        commit("incrementSuccessCount");
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: "Created Promise Successfully!",
            type: "success"
          },
          { root: true }
        );
      })
      .catch(error => {
        if (error.response?.data?.errors) {
          commit("setValidationErrors", error.response.data.errors);
        } else {
          commit("setErrorMessage", formatErrorMessage(error));
        }
      })
      .finally(() => {
        commit("setIsBusy", false);
      });
  },
  recordPaidPayment: async ({ dispatch, state, commit }, { form }) => {
    commit("setIsBusy", true);
    commit("clearErrors");
    axiosWrapperService
      .axiosPost(`/applications/${state.applicationId}/recordPaidPayment`, form)
      .then(() => {
        commit("incrementSuccessCount");
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: "Paid Payment Recorded Successfully!",
            type: "success"
          },
          { root: true }
        );
      })
      .catch(error => {
        if (error.response?.data?.errors) {
          commit("setValidationErrors", error.response.data.errors);
        } else {
          commit("setErrorMessage", formatErrorMessage(error));
        }
      })
      .finally(() => {
        commit("setIsBusy", false);
      });
  },
  recordRefundedPayment: async ({ dispatch, state, commit }, { form }) => {
    commit("setIsBusy", true);
    commit("clearErrors");
    axiosWrapperService
      .axiosPost(
        `/applications/${state.applicationId}/recordRefundedPayment`,
        form
      )
      .then(() => {
        commit("incrementSuccessCount");
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: "Refunded Payment Recorded Successfully!",
            type: "success"
          },
          { root: true }
        );
      })
      .catch(error => {
        if (error.response?.data?.errors) {
          commit("setValidationErrors", error.response.data.errors);
        } else {
          commit("setErrorMessage", formatErrorMessage(error));
        }
      })
      .finally(() => {
        commit("setIsBusy", false);
      });
  },
  addFees: async ({ dispatch, state, commit }, { form }) => {
    commit("setIsBusy", true);
    commit("clearErrors");
    axiosWrapperService
      .axiosPost(`/applications/${state.applicationId}/addFees`, form)
      .then(() => {
        commit("incrementSuccessCount");
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: "Fees Added Successfully!",
            type: "success"
          },
          { root: true }
        );
      })
      .catch(error => {
        if (error.response?.data?.errors) {
          commit("setValidationErrors", error.response.data.errors);
        } else {
          commit("setErrorMessage", formatErrorMessage(error));
        }
      })
      .finally(() => {
        commit("setIsBusy", false);
      });
  },
  waiveFees: async ({ dispatch, state, commit }, { form }) => {
    commit("setIsBusy", true);
    commit("clearErrors");
    axiosWrapperService
      .axiosPost(`/applications/${state.applicationId}/waiveFees`, form)
      .then(() => {
        commit("incrementSuccessCount");
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: "Fees Waived Successfully!",
            type: "success"
          },
          { root: true }
        );
      })
      .catch(error => {
        if (error.response?.data?.errors) {
          commit("setValidationErrors", error.response.data.errors);
        } else {
          commit("setErrorMessage", formatErrorMessage(error));
        }
      })
      .finally(() => {
        commit("setIsBusy", false);
      });
  },
  refreshPayments: async ({ dispatch, state, commit }) => {
    commit("setIsBusy", true);
    commit("clearErrors");
    axiosWrapperService
      .axiosPost(`/applications/${state.applicationId}/refreshPayments`)
      .then(() => {
        commit("incrementSuccessCount");
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: "Payments Refreshed Successfully!",
            type: "success"
          },
          { root: true }
        );
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("setIsBusy", false);
      });
  },
  changePaymentDate: async ({ dispatch, state, commit }, { form }) => {
    commit("setIsBusy", true);
    commit("clearErrors");
    axiosWrapperService
      .axiosPost(`/applications/${state.applicationId}/changePaymentDate`, form)
      .then(() => {
        commit("incrementSuccessCount");
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: "Payment Date Updated Successfully!",
            type: "success"
          },
          { root: true }
        );
      })
      .catch(error => {
        if (error.response?.data?.errors) {
          commit("setValidationErrors", error.response.data.errors);
        } else {
          commit("setErrorMessage", formatErrorMessage(error));
        }
      })
      .finally(() => {
        commit("setIsBusy", false);
      });
  },
  changePaymentFrequency: async ({ dispatch, state, commit }, { form }) => {
    commit("setIsBusy", true);
    commit("clearErrors");
    axiosWrapperService
      .axiosPost(
        `/applications/${state.applicationId}/changePaymentFrequency`,
        form
      )
      .then(() => {
        commit("incrementSuccessCount");
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: "Payment Frequency Updated Successfully!",
            type: "success"
          },
          { root: true }
        );
      })
      .catch(error => {
        if (error.response?.data?.errors) {
          commit("setValidationErrors", error.response.data.errors);
        } else {
          commit("setErrorMessage", formatErrorMessage(error));
        }
      })
      .finally(() => {
        commit("setIsBusy", false);
      });
  },
  newCardPayment: async ({ dispatch, state, commit }, { form }) => {
    commit("setIsBusy", true);
    commit("clearErrors");
    axiosWrapperService
      .axiosPost(`/applications/${state.applicationId}/newCardPayment`, form)
      .then(() => {
        commit("incrementSuccessCount");
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: "Card Payment Successful!",
            type: "success"
          },
          { root: true }
        );
      })
      .catch(error => {
        if (error.response?.data?.errors) {
          commit("setValidationErrors", error.response.data.errors);
        } else {
          commit("setErrorMessage", formatErrorMessage(error));
        }
      })
      .finally(() => {
        commit("setIsBusy", false);
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
