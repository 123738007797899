<template>
  <div>
    <div v-if="applicationId > 0" class="grid grid-cols-12 gap-6">
      <div
        :class="{
          'col-span-7': isGlobalNotesDisplayed,
          'col-span-12': !isGlobalNotesDisplayed
        }"
      >
        <NoteComponentToggler
          :application-id="applicationId"
          :associated-model-id="0"
          :associated-model-key="'followup'"
          :associated-model-uuid="loanFollowUpUuid"
        />
        <div
          v-if="loanFollowUp.status === 'Contact Failed'"
          class="alert alert-warning-soft show flex items-center mb-2"
          role="alert"
        >
          <AlertCircleIcon class="w-6 h-6 mr-2" />
          Contact Failed !
        </div>
        <div
          v-if="loanFollowUp.status === 'Completed'"
          class="alert alert-success-soft show flex items-center mb-2"
          role="alert"
        >
          <AlertCircleIcon class="w-6 h-6 mr-2" />
          Followup already Completed !
        </div>
        <div
          v-if="!['Applied', 'Approved'].includes(loanFollowUp.loan.status)"
          class="alert alert-success-soft show flex items-center mb-2"
          role="alert"
        >
          <AlertCircleIcon class="w-6 h-6 mr-2" />
          Found Application status to be {{ loanFollowUp.loan.status }}! The
          Loan Application Status must be either 'Applied' or 'Approved' for
          actioning assessment.
        </div>

        <LoanApplicationCommon
          :application-id="applicationId"
          :customer-id="customerId"
        />

        <div class="intro-y box mt-2">
          <!-- <hr class="bg-gray-400 h-1" /> -->
          <div
            v-if="
              ['In Progress', 'Contact Failed'].includes(loanFollowUp.status) &&
                ['Applied', 'Approved'].includes(loanFollowUp.loan.status) &&
                ![
                  'Declined',
                  'Withdrawn',
                  'Pre Conversion Finalized',
                  'Converted'
                ].includes(loanFollowUp.assessment.status)
            "
            class="flow-root"
          >
            <template v-if="conditionalStatuses.includes(loanFollowUp.status)">
              <div
                v-if="loanFollowUp.status !== 'Contact Failed'"
                class="flex flex-col sm:flex-row items-center p-5 float-left"
              >
                <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0">
                  <button
                    class="
                    btn btn-rounded
                    bg-red-400
                    w-1/2
                    text-white
                    sm:w-auto
                    mr-2
                  "
                    type="button"
                    @click="showContactFailedConfirmationModal()"
                  >
                    <XCircleIcon class="w-6 h-6 mr-2" />
                    Add Back To Queue
                  </button>
                </div>
              </div>
              <div
                class="flex flex-col sm:flex-row items-center p-5 float-left"
              >
                <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0">
                  <button
                    class="
                    btn btn-rounded
                    bg-red-400
                    w-1/2
                    text-white
                    sm:w-auto
                    mr-2
                  "
                    type="button"
                    @click="showFollowUpStatusCancelledConfirmationModal()"
                  >
                    <XCircleIcon class="w-6 h-6 mr-2" />
                    Cancel Followup
                  </button>
                </div>
              </div>
            </template>
            <div class="flex flex-col sm:flex-row items-center p-5 float-left">
              <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0">
                <button
                  class="
                    btn btn-rounded
                    bg-red-400
                    w-1/2
                    text-white
                    sm:w-auto
                    mr-2
                  "
                  type="button"
                  @click="showWithdrawalAssessmentModal()"
                >
                  <XCircleIcon class="w-6 h-6 mr-2" />
                  Withdraw Application
                </button>
              </div>
            </div>
            <div
              v-if="loanFollowUp.status !== 'Completed'"
              class="flex flex-col sm:flex-row items-center p-5 float-right"
            >
              <div class="w-full sm:w-auto flex sm:ml-auto mt-3 sm:mt-0">
                <button
                  class="
                    btn btn-rounded
                    bg-theme-3
                    w-1/2
                    text-white
                    sm:w-auto
                    mr-2
                  "
                  type="button"
                  @click="showFollowUpCompletedConfirmationModal()"
                >
                  <ArrowRightCircleIcon class="w-6 h-6 mr-2" />
                  Remove From Follow Ups
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- BEGIN: follow-up-finalization Modal Content -->
        <div
          id="follow-up-finalization-confirmation-modal"
          aria-hidden="true"
          class="modal"
          tabindex="-1"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <!-- BEGIN: Modal Header -->
              <div class="modal-header">
                <h2 class="font-medium text-base mr-auto">
                  {{ followUpFinalizationConfirmationDataObject.title }}
                </h2>
                <div class="dropdown sm:hidden">
                  <a
                    aria-expanded="false"
                    class="dropdown-toggle w-5 h-5 block"
                    href="javascript:"
                  >
                    <MoreHorizontalIcon
                      class="w-5 h-5 text-gray-600 dark:text-gray-600"
                    />
                  </a>
                  <div class="dropdown-menu w-40">
                    <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                      <a
                        class="
                          flex
                          items-center
                          p-2
                          transition
                          duration-300
                          ease-in-out
                          bg-white
                          dark:bg-dark-1
                          hover:bg-gray-200
                          dark:hover:bg-dark-2
                          rounded-md
                        "
                        href="javascript:"
                      >
                        <FileIcon class="w-4 h-4 mr-2" />
                        Download Docs
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END: Modal Header -->
              <!-- BEGIN: Modal Body -->
              <div class="modal-body grid grid-cols-6">
                <div class="col-span-12 sm:col-span-6">
                  <div
                    v-if="followUpFinalizationFormValidationMessage !== ''"
                    class="alert alert-danger-soft show flex items-center mb-2"
                    role="alert"
                  >
                    <AlertOctagonIcon class="w-6 h-6 mr-2" />
                    {{ followUpFinalizationFormValidationMessage }}
                    <button
                      class="btn-close"
                      type="button"
                      @click="clearFollowUpFinalizationFormValidationMessages"
                    >
                      <XIcon class="w-4 h-4" />
                    </button>
                  </div>
                  <label class="form-label">
                    {{ followUpFinalizationConfirmationDataObject.title }}
                    Note:</label
                  >
                  <textarea
                    id="final-confirm-modal-form-1"
                    v-model="followUpFinalizationConfirmationDataObject.note"
                    class="form-control"
                    type="text"
                  >
                  </textarea>
                </div>
              </div>
              <!-- END: Modal Body -->
              <!-- BEGIN: Modal Footer -->
              <div class="modal-footer text-right">
                <button
                  class="btn btn-dark-soft w-20 mr-1"
                  data-dismiss="modal"
                  type="button"
                >
                  Cancel
                </button>
                <button
                  v-if="
                    followUpFinalizationConfirmationDataObject.type ===
                      'completed'
                  "
                  class="btn btn-success w-40"
                  type="button"
                  @click="onFollowUpCompletionSubmit"
                >
                  Complete Follow Up
                </button>
                <button
                  v-else-if="
                    followUpFinalizationConfirmationDataObject.type ===
                      'withdrawal'
                  "
                  class="btn btn-danger w-40"
                  type="button"
                  @click="onWithdrawAssessmentAction"
                >
                  Withdraw Application
                </button>
                <button
                  v-else-if="
                    followUpFinalizationConfirmationDataObject.type ===
                      'cancelled'
                  "
                  class="btn btn-danger w-44"
                  type="button"
                  @click="onFollowUpFollowUpStatusCancelSubmit"
                >
                  Cancel Follow Up
                </button>
                <button
                  v-else
                  class="btn btn-danger w-44"
                  type="button"
                  @click="onFollowUpContactFailedSubmit"
                >
                  Confirm Contact Failed
                </button>
              </div>
              <!-- END: Modal Footer -->
            </div>
          </div>
        </div>
        <!-- END: Modal Content -->
      </div>
      <div
        :class="{
          'col-span-5': isGlobalNotesDisplayed,
          'col-span-12': !isGlobalNotesDisplayed
        }"
      >
        <NoteComponentBody
          :application_id="applicationId"
          :associated_model_id="0"
          :associated_model_key="'followup'"
          :associated_model_uuid="loanFollowUpUuid"
        />
      </div>
    </div>
    <div
      v-else
      class="
        bg-white
        opacity-75
        flex flex-col
        items-center
        justify-center
        h-screen
      "
    >
      <div class="relative flex justify-center items-center h-3">
        <LoadingIcon class="w-16 h-16" color="green" icon="bars" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { loanFollowUpDetail } from "@/composables/loanFollowUpDetail";
import NoteComponentToggler from "@/components/note-component-toggler/Main.vue";
import NoteComponentBody from "@/components/note-component-body/Main.vue";
import LoanApplicationCommon from "@/components/loan-application-details/common/Main";

export default defineComponent({
  components: {
    NoteComponentToggler,
    NoteComponentBody,
    LoanApplicationCommon
  },
  setup() {
    const conditionalStatuses = ["New", "In Progress"];
    const {
      adminUser,
      busy,
      isGlobalNotesDisplayed,
      followUpFinalizationConfirmationDataObject,
      followUpFinalizationFormValidationMessage,
      clearFollowUpFinalizationFormValidationMessages,
      showFinalizingButtons,
      showContactFailedConfirmationModal,
      showFollowUpCompletedConfirmationModal,
      showFollowUpStatusCancelledConfirmationModal,
      showWithdrawalAssessmentModal,
      onFollowUpCompletionSubmit,
      onFollowUpContactFailedSubmit,
      onFollowUpFollowUpStatusCancelSubmit,
      onWithdrawAssessmentAction,
      applicationId,
      customerId,
      loanFollowUpUuid,
      assessmentUuid,
      loanFollowUp,
      docFileUploadValidationMessage,
      newDocReqFormObject,
      visibleGallery,
      kycWithAdditionalAttributes,
      documentRequestList,
      documentTypeList,
      getKycAttributeValueByName,
      uploadedFilesHandler,
      onSubmitFile,
      onNewDocumentReqSubmit,
      onDocReqRemoveSubmit,
      onDocumentGalleryItemDownload,
      showNewDocReqFormModal,
      showMultiFileUploadModal,
      clearFollowUpMessages,
      showDocReqGallery,
      fetchingKYC
    } = loanFollowUpDetail();

    return {
      adminUser,
      busy,
      isGlobalNotesDisplayed,
      followUpFinalizationConfirmationDataObject,
      followUpFinalizationFormValidationMessage,
      clearFollowUpFinalizationFormValidationMessages,
      showFinalizingButtons,
      showContactFailedConfirmationModal,
      showFollowUpCompletedConfirmationModal,
      showFollowUpStatusCancelledConfirmationModal,
      showWithdrawalAssessmentModal,
      onFollowUpCompletionSubmit,
      onFollowUpContactFailedSubmit,
      onFollowUpFollowUpStatusCancelSubmit,
      onWithdrawAssessmentAction,
      applicationId,
      customerId,
      loanFollowUpUuid,
      assessmentUuid,
      loanFollowUp,
      docFileUploadValidationMessage,
      newDocReqFormObject,
      visibleGallery,
      kycWithAdditionalAttributes,
      documentRequestList,
      documentTypeList,
      getKycAttributeValueByName,
      uploadedFilesHandler,
      onSubmitFile,
      onNewDocumentReqSubmit,
      onDocReqRemoveSubmit,
      onDocumentGalleryItemDownload,
      showNewDocReqFormModal,
      showMultiFileUploadModal,
      clearFollowUpMessages,
      showDocReqGallery,
      fetchingKYC,
      conditionalStatuses
    };
  }
});
</script>
