const state = () => {
  return {
    basic: {},
    history: {
      count: 0,
      premature: false
    },
    banking: {
      account: {},
      card: {
        verified: false
      }
    },
    serviceability: {
      netSurplusAmount: 0,
      pei: 0,
      fpd: {},
      frequency: {},
      customer: {}
    },
    tags: "Loading tag list",
    docRequests: {},
    rebuttalCounts: {},
    creditCheckLoading: true
  };
};

//getters
const getters = {
  basic: state => state.basic,
  history: state => state.history,
  banking: state => state.banking,
  serviceability: state => state.serviceability,
  tags: state => state.tags,
  docRequests: state => state.docRequests,
  rebuttalCounts: state => state.rebuttalCounts,
  creditCheckLoading: state => state.creditCheckLoading
};

//actions
const actions = {};

//mutations
const mutations = {
  setBasic(state, basic) {
    state.basic = basic;
  },
  setHistory(state, history) {
    state.history = history;
  },
  setBankingAccountVerification(state, banking) {
    state.banking.account = banking;
  },
  setBankingCardVerification(state, card) {
    state.banking.card = card;
  },
  setServiceabilityFlags(state, serviceability) {
    state.serviceability = serviceability;
  },
  setTags(state, tags) {
    state.tags = tags;
  },
  setDocRequest(state, requests) {
    state.docRequests = requests;
  },
  setRebutallCounts(state, rebuttalCounts) {
    state.rebuttalCounts = rebuttalCounts;
  },
  setCreditCheckLoading(state, status) {
    state.creditCheckLoading = status;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
