import { onMounted, ref } from "vue";
import feather from "feather-icons";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { authGetterService } from "@/services/auth/auth-getter-service";
import { styleHelper } from "@/utils/styleHelper";
import { handleErrorResponse } from "@/services/auth/responseHandler/handle-response-service";
import { useStore } from "@/store";

export const unsignedLoans = props => {
  const tableRef = ref();
  const tabulator = ref();
  const searchTerm = ref();
  const store = useStore();
  const baseUrl = `${process.env.VUE_APP_BASE_URL}/applications/getUnsignedLoansForFollowup`;

  onMounted(() => {
    initTabulator();
    reInitOnResizeWindow();
  });

  const initTabulator = () => {
    const url = generateUrl();

    tabulator.value = new Tabulator(tableRef.value, {
      ajaxURL: url,
      ajaxConfig: {
        method: "GET",
        headers: authGetterService.getAuthHeader()
      },

      ajaxResponse: function(url, params, response) {
        if (response.meta) {
          if (response.meta.last_page) {
            response["last_page"] = response.meta.last_page;
          }

          if (response.meta.current_page) {
            response["current_page"] = response.meta.current_page;
          }
        }
        return response;
      },
      filterMode: "remote",
      sortMode: "remote",
      printAsHtml: true,
      printStyled: true,
      reactiveData: true,
      pagination: true,
      paginationMode: "remote",
      paginationSize: 10,
      paginationSizeSelector: [10, 20, 30, 40],
      layout: "fitColumns",
      responsiveLayout: "collapse",
      placeholder: "No matching results",
      columns: [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false
        },

        // For HTML table
        {
          title: "Application ID",
          minWidth: 150,
          responsive: 0,
          field: "id",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="font-medium whitespace-nowrap"><a target="_blank" href="https://lms.fundo.com.au/${genAssessmentLink(
              cell.getData()
            )}"><u>${cell.getData().id}</u></a></div>
              </div>`;
          }
        },
        {
          title: "Customer ID",
          minWidth: 100,
          width: 135,
          field: "customer.id",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="font-medium whitespace-nowrap"><a target="_blank" href="/loan-applications/customer/${
              cell.getData().customer.id
            }"><u>${cell.getData().customer.id}</u></a></div>
              </div>`;
          }
        },
        {
          title: "Payer",
          minWidth: 200,
          field: "customer.full_name",
          hozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${
              cell.getData().customer.full_name
            }</div>
              </div>`;
          }
        },
        {
          title: "Loan Term",
          minWidth: 100,
          field: "loan_term",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${cell.getData().loan_term}</div>
              </div>`;
          }
        },
        {
          title: "Total Amount",
          minWidth: 120,
          field: "total_amount",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${
              cell.getData().total_amount
            }</div>
              </div>`;
          }
        },
        {
          title: "Status",
          minWidth: 200,
          field: "status",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="${styleHelper.getLoanStatusBadgeClass(
              cell.getData().status
            )}">
                ${cell.getData().status_label}
                </div>
              </div>`;
          }
        },
        {
          title: "Created At",
          minWidth: 200,
          field: "created_at",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${
              cell.getData().created_at
            }</div>
              </div>`;
          }
        },
        {
          title: "Email",
          minWidth: 200,
          field: "email",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${
              cell.getData().customer.email
            }</div>
              </div>`;
          }
        },
        {
          title: "Phone",
          minWidth: 200,
          field: "phone",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${
              cell.getData().customer.mobile
            }</div>
              </div>`;
          }
        },
        {
          title: "First Payment Date",
          minWidth: 200,
          field: "first_payment_date",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${
              cell.getData().first_payment_date
            }</div>
              </div>`;
          }
        }
      ]
    });

    tabulator.value.on("dataLoadError", function(errorResponse) {
      handleErrorResponse(errorResponse);
    });

    tabulator.value.on("renderComplete", function() {
      feather.replace({
        "stroke-width": 1.5
      });
    });
  };

  // Redraw table onresize
  const reInitOnResizeWindow = () => {
    window.addEventListener("resize", () => {
      tabulator.value.redraw();
      feather.replace({
        "stroke-width": 1.5
      });
    });
  };

  function genAssessmentLink(loan) {
    if (loan.assessment !== undefined && loan.assessment !== null) {
      if (loan.assessment.converter_id > 0) {
        return (
          "loan-assessment-detail/lending-converter/" + loan.assessment.uuid
        );
      } else if (loan.assessment.manager_id > 0) {
        return "loan-assessment-detail/lending-manager/" + loan.assessment.uuid;
      } else if (loan.assessment.agent_id > 0) {
        return "loan-assessment-detail/lending-agent/" + loan.assessment.uuid;
      }

      return "#";
    }

    return "#";
  }

  const generateQueryString = () => {
    const queryParams = new URLSearchParams();
    if (searchTerm.value) {
      queryParams.append("search", searchTerm.value);
    }

    return queryParams.toString();
  };

  const generateUrl = () => {
    let url = baseUrl;
    const queryString = generateQueryString();
    if (queryString !== "") {
      url += `?${queryString}`;
    }
    return url;
  };

  const onSearch = () => {
    const url = generateUrl();
    tabulator.value.setData(url);
  };

  const onResetSearch = () => {
    searchTerm.value = null;
    onSearch();
  };

  const onExport = () => {
    store.dispatch("unsignedLoans/requestForExport", {
      query: generateQueryString()
    });
  };

  return {
    tableRef,
    genAssessmentLink,
    searchTerm,
    onSearch,
    onResetSearch,
    onExport
  };
};
