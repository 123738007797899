import { onMounted, computed } from "vue";
import { useStore } from "@/store";
import { loanQualityHelper } from "@/utils/loanQualityHelper";

export const loanQuality = props => {
  const store = useStore();

  const quality = computed(() => store.state.loanQuality.quality);

  const score = computed(() => store.state.loanQuality.score);

  function resetQualityAndScore() {
    store.dispatch("loanQuality/resetQualityAndScore", { root: true });
  }

  function getLoanQualityClassByQuality(score, section, ignoreHighest = false) {
    //To ignore color
    if (ignoreHighest && parseInt(score) > 3) {
      score = 3;
    }
    const data = loanQualityHelper.getLoanQualityClassByQuality(score, section);
    return data["quality"];
  }

  function getActiveApplicationQualityClass() {
    return getLoanQualityClassByQuality(score.value, "application");
  }

  onMounted(() => {
    resetQualityAndScore();
  });

  return {
    score,
    quality,
    getLoanQualityClassByQuality,
    getActiveApplicationQualityClass,
    resetQualityAndScore
  };
};
