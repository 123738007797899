<template>
  <div>
    <!--    START TABS-->
    <div class="intro-y box px-5 pt-5 mt-5">
      <Navigation
        :application-id="applicationId"
        :changeTab="changeTab"
        :fetchingApplicantBankAccounts="fetchingApplicantBankAccounts"
        :fetchingApplicantLoans="fetchingApplicantLoans"
        :fetchingKYC="fetchingKYC"
        :fetchingLendingDetails="fetchingLendingDetails"
      />
    </div>
    <div v-if="!fetchingKYC" class="mt-3">
      <LnExtDtsLinks :application-id="applicationId" />
    </div>

    <!--    END TABS-->
    <CustomerAndLoanDetails
      v-show="currentTab === 'customer-details'"
      :application-id="applicationId"
      :isActive="ifSectionActive('kyc-title')"
      :setNavActiveSection="
        !ifSectionActive('kyc-title')
          ? setNavActiveSection
          : removeSectionFromActiveSections
      "
    />
    <CustomerApplicationDetails
      v-show="currentTab === 'customer-details'"
      :active-field="enabledAgreggatorDataField"
      :application-id="applicationId"
      :isActive="ifSectionActive('application-detail-title')"
      :setNavActiveSection="
        !ifSectionActive('application-detail-title')
          ? setNavActiveSection
          : removeSectionFromActiveSections
      "
    />
    <CustomerEmployement
      v-show="currentTab === 'customer-details'"
      :application-id="applicationId"
      :isActive="ifSectionActive('employment-detail-title')"
      :setNavActiveSection="
        !ifSectionActive('employment-detail-title')
          ? setNavActiveSection
          : removeSectionFromActiveSections
      "
    />
    <LoanHistory
      v-show="currentTab === 'history'"
      :fetchingApplicantLoans="fetchingApplicantLoans"
      :getLoanHistoryOfApp="getLoanHistoryOfApp"
      :isActive="ifSectionActive('loan-history-with-fundo-title')"
      :pastLoansOfApplicantLinkedToAssessment="
        pastLoansOfApplicantLinkedToAssessment
      "
      :setNavActiveSection="
        !ifSectionActive('loan-history-with-fundo-title')
          ? setNavActiveSection
          : removeSectionFromActiveSections
      "
    />
    <LnApprovalPreRequisites
      v-show="currentTab === 'verification'"
      :application-id="applicationId"
      :isActive="ifSectionActive('ln-approval-pre-check-title')"
      :setNavActiveSection="
        !ifSectionActive('ln-approval-pre-check-title')
          ? setNavActiveSection
          : removeSectionFromActiveSections
      "
    />
    <CustomerBankingDetails
      v-show="currentTab === 'banking'"
      :allBankReports="allBankReports"
      :bankAccountOfApplicantLinkedToAssessment="
        bankAccountOfApplicantLinkedToAssessment
      "
      :fetchingApplicantBankAccounts="fetchingApplicantBankAccounts"
      :getAllBankStatements="getAllBankStatements"
      :getBankAccountsOfApplicantLinkedToLoanAssessment="
        getBankAccountsOfApplicantLinkedToLoanAssessment
      "
      :isActive="ifSectionActive('bank-details-title')"
      :latestBankStatement="latestBankStatement"
      :onSubmitToSetActiveBankingIncomeAccount="
        onSubmitToSetActiveBankingIncomeAccount
      "
      :setNavActiveSection="
        !ifSectionActive('bank-details-title')
          ? setNavActiveSection
          : removeSectionFromActiveSections
      "
    />
    <PaymentCards
      v-show="currentTab === 'banking'"
      :agent-id="adminUser.id"
      :application-id="applicationId"
      :isActive="ifSectionActive('payment-detail-title')"
      :setNavActiveSection="
        !ifSectionActive('payment-detail-title')
          ? setNavActiveSection
          : removeSectionFromActiveSections
      "
    />
    <PersonalLoans
      v-show="currentTab === 'serviceability'"
      :activeInputId="activeInputId"
      :adminUser="adminUser"
      :aggregatorPersonalLoanDetails="aggregatorPersonalLoanDetails"
      :application-id="applicationId"
      :bankAccountOfApplicantLinkedToAssessment="
        bankAccountOfApplicantLinkedToAssessment
      "
      :enabledAgreggatorDataField="enabledAgreggatorDataField"
      :fetchingApplicantBankAccounts="fetchingApplicantBankAccounts"
      :isActive="ifSectionActive('personal-loans-title')"
      :onClickTransformToInput="onClickTransformToInput"
      :onCloseAgreggatorInputBox="onCloseAgreggatorInputBox"
      :onCloseAndSubmitPersonalLoanFrequency="
        onCloseAndSubmitPersonalLoanFrequency
      "
      :onCloseAndSubmitPersonalLoanRepaymentAmount="
        onCloseAndSubmitPersonalLoanRepaymentAmount
      "
      :onCloseAndSubmitPersonalLoanStatus="onCloseAndSubmitPersonalLoanStatus"
      :onCloseAndSubmitPersonalLoanType="onCloseAndSubmitPersonalLoanType"
      :onClosePersonalLoanStatusSelectBox="onClosePersonalLoanStatusSelectBox"
      :personalLoanStatuses="personalLoanStatuses"
      :setNavActiveSection="
        !ifSectionActive('personal-loans-title')
          ? setNavActiveSection
          : removeSectionFromActiveSections
      "
      :updatingPersonalLoansDetails="updatingPersonalLoansDetails"
    />

    <CustomerIncomeDetails
      v-show="currentTab === 'serviceability'"
      :activeDescriptions="activeDescriptions"
      :activeInputId="activeInputId"
      :adminUser="adminUser"
      :aggregatorIncomeExpenseDetailsTransitLobby="
        aggregatorIncomeExpenseDetailsTransitLobby
      "
      :bulkActivateDescriptions="bulkActivateDescriptions"
      :enabledAgreggatorDataField="enabledAgreggatorDataField"
      :fetchingIncomeExpensesDetails="fetchingIncomeExpensesDetails"
      :filterHistory="filterHistory"
      :getAggregatorIncomeExpensesDetailsForLoanAssessment="
        getAggregatorIncomeExpensesDetailsForLoanAssessment
      "
      :isActive="ifSectionActive('income-and-expense-details-title')"
      :onAggregatorIncomeExpenseDetailsSubmit="
        onAggregatorIncomeExpenseDetailsSubmit
      "
      :onClickTransformToInput="onClickTransformToInput"
      :onCloseAgreggatorInputBox="onCloseAgreggatorInputBox"
      :onCloseAndSubmitAgreggatorInput="onCloseAndSubmitAgreggatorInput"
      :onCloseAndSubmitAgreggatorInputDescription="
        onCloseAndSubmitAgreggatorInputDescription
      "
      :setNavActiveSection="
        !ifSectionActive('income-and-expense-details-title')
          ? setNavActiveSection
          : removeSectionFromActiveSections
      "
      :updateActiveDescriptions="updateActiveDescriptions"
      :updateRecordsExists="updateRecordsExists"
      :updateShouldChangeTab="updateShouldChangeTab"
      :updatingIncomeExpenseDetails="updatingIncomeExpenseDetails"
      :getKycAttributeValueByName="getKycAttributeValueByName"
    />
    <LendingDetails
      v-show="currentTab === 'serviceability'"
      :activeInputId="activeInputId"
      :adminUser="adminUser"
      :aggregatorLoanDetailsTransitLobby="aggregatorLoanDetailsTransitLobby"
      :enabledAgreggatorDataField="enabledAgreggatorDataField"
      :fetchingLendingDetails="fetchingLendingDetails"
      :filterHistory="filterHistory"
      :getAggregatorLendingDetailsForLoanAssessment="
        getAggregatorLendingDetailsForLoanAssessment
      "
      :isActive="ifSectionActive('lending-details-title')"
      :onAggregatorLendingDetailsSubmit="onAggregatorLendingDetailsSubmit"
      :onClickTransformToInput="onClickTransformToInput"
      :onCloseAgreggatorInputBox="onCloseAgreggatorInputBox"
      :onCloseAndSubmitAgreggatorInput="onCloseAndSubmitAgreggatorInput"
      :setNavActiveSection="
        !ifSectionActive('lending-details-title')
          ? setNavActiveSection
          : removeSectionFromActiveSections
      "
      :updateRecordsExists="updateRecordsExists"
      :updateShouldChangeTab="updateShouldChangeTab"
      :updatingLendingDetails="updatingLendingDetails"
    />
    <LoanTags
      v-show="currentTab === 'tags'"
      :application-id="applicationId"
      :isActive="ifSectionActive('loan-tags-title')"
      :setNavActiveSection="
        !ifSectionActive('loan-tags-title')
          ? setNavActiveSection
          : removeSectionFromActiveSections
      "
    />

    <LnAppDocReq
      v-show="currentTab === 'docs-and-rebuttals'"
      :application-id="applicationId"
      :customer-name="customerName"
      :isActive="ifSectionActive('doc-req-title')"
      :setNavActiveSection="
        !ifSectionActive('doc-req-title')
          ? setNavActiveSection
          : removeSectionFromActiveSections
      "
    />
    <LoanRebuttals
      v-show="currentTab === 'docs-and-rebuttals'"
      :application-id="applicationId"
      :isActive="ifSectionActive('loan-rebuttal-title')"
      :setNavActiveSection="
        !ifSectionActive('loan-rebuttal-title')
          ? setNavActiveSection
          : removeSectionFromActiveSections
      "
    />
    <Verification
      v-show="currentTab === 'verification'"
      :application-id="applicationId"
      :customer-id="customerId"
      :isActive="ifSectionActive('verification-detail-title')"
      :setNavActiveSection="
        !ifSectionActive('verification-detail-title')
          ? setNavActiveSection
          : removeSectionFromActiveSections
      "
    />
    <LnAppGenericFollowUps
      v-if="route.name === 'loan-follow-up-detail'"
      v-show="currentTab === 'customer-details'"
      :application-id="applicationId"
      :isActive="ifSectionActive('ln-app-general-follow-up')"
      :setNavActiveSection="
        !ifSectionActive('ln-app-general-follow-up')
          ? setNavActiveSection
          : removeSectionFromActiveSections
      "
    />
    <!-- BEGIN: Basic intro-y box sample -->
    <!--      <div class="intro-y box mt-5">-->
    <!--        <div-->
    <!--          class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"-->
    <!--        >-->
    <!--          <h2 class="font-medium text-base mr-auto">Multiple Select</h2>-->
    <!--        </div>-->
    <!--        <div id="multiple-select" class="p-5">-->
    <!--          <div class="preview">-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!-- END: Basic intro-y box sample -->

    <!-- BEGIN: assessment-dts-success Notification Content -->
    <div
      id="assessment-dts-success-alert-toast-content"
      class="toastify-content hidden flex"
    >
      <XCircleIcon class="text-theme-3" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Success!</div>
        <div
          id="assessment-dts-success-alert-note-message-body"
          class="text-gray-600 mt-1"
        ></div>
      </div>
    </div>
    <!-- END: assessment-dts-success Notification Content -->
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, watchEffect } from "vue";
import { loanAssessmentDetail } from "@/composables/loanAssessmentDetail";
import LnAppDocReq from "@/components/ln-app-doc-req/Main.vue";
// import noRecordTblTemplate from "@/components/no-record-tbl-template/Main.vue";
// import noRecordDivTemplate from "@/components/no-record-div-template/Main.vue";
import LoanRebuttals from "@/components/ln-rebuttals/Main";
import LoanTags from "@/components/loan-applications/loan-tags/Main";
import LnApprovalPreRequisites from "@/components/loan-applications/ln-approval-pre-requisites/Main";
import PaymentCards from "@/components/loan-applications/payment-cards/Main";
import CustomerAndLoanDetails from "@/components/loan-application-details/customer-and-loan-details/Main";
import LoanHistory from "@/components/loan-application-details/ln-history/Main";
import CustomerEmployement from "@/components/loan-application-details/customer-employement-details/Main";
import CustomerApplicationDetails from "@/components/loan-application-details/customer-application-details/Main";
import CustomerBankingDetails from "@/components/loan-application-details/customer-bank-details/Main";
import CustomerIncomeDetails from "@/components/loan-application-details/customer-income-details/Main";
import LendingDetails from "@/components/loan-application-details/lending-details/Main";
import PersonalLoans from "@/components/loan-application-details/personal-loans/Main";
import Verification from "@/components/loan-application-details/loan-verification/Main";
import LnExtDtsLinks from "@/components/loan-application-details/ln-extended-dts-links/Main";
import LnAppGenericFollowUps from "@/components/loan-applications/ln-app-generic-follow-ups/Main.vue";
import Navigation from "./Navigation.vue";
import { useStore } from "@/store";

export default defineComponent({
  components: {
    CustomerAndLoanDetails,
    LoanHistory,
    LnAppDocReq,
    LoanRebuttals,
    LoanTags,
    LnApprovalPreRequisites,
    PaymentCards,
    CustomerEmployement,
    CustomerApplicationDetails,
    CustomerBankingDetails,
    CustomerIncomeDetails,
    LendingDetails,
    PersonalLoans,
    Verification,
    LnExtDtsLinks,
    LnAppGenericFollowUps,
    Navigation
  },
  props: {
    applicationId: {
      type: Number,
      required: true
    },
    customerId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const {
      route,
      adminUser,
      aggregatorPersonalLoanDetails,
      aggregatorLoanDetailsTransitLobby,
      aggregatorIncomeExpenseDetailsTransitLobby,
      onAggregatorLendingDetailsSubmit,
      onAggregatorIncomeExpenseDetailsSubmit,
      customerName,
      kycWithAdditionalAttributes,
      applicationTransitionReasonTypes,
      getKycAttributeValueByName,
      pastLoansOfApplicantLinkedToAssessment,
      getLoanHistoryOfApp,
      fetchingApplicantLoans,
      bankAccountOfApplicantLinkedToAssessment,
      latestBankStatement,
      getAllBankStatements,
      allBankReports,
      onSubmitToSetActiveBankingIncomeAccount,
      getAssesmentSubmitLabelAsPerAssessmentStatus,
      triggerDismissActionAsPerAdminRole,
      setVisbilityOfFinalizingbuttonAsPerAdminRole,
      withdrawable,
      canBeRequestedToDecline,
      personalLoanStatuses,
      clearLoanAssessmentDetailsMessages,
      onClickTransformToInput,
      enabledAgreggatorDataField,
      onCloseAgreggatorInputBox,
      onCloseAndSubmitAgreggatorInput,
      onCloseAndSubmitAgreggatorInputDescription,
      onCloseAndSubmitPersonalLoanStatus,
      onCloseAndSubmitPersonalLoanType,
      onCloseAndSubmitPersonalLoanRepaymentAmount,
      onCloseAndSubmitPersonalLoanFrequency,
      onClosePersonalLoanStatusSelectBox,
      activeSection,
      setNavActiveSection,
      removeSectionFromActiveSections,
      fetchingApplicantBankAccounts,
      getBankAccountsOfApplicantLinkedToLoanAssessment,
      updatingPersonalLoansDetails,
      fetchingIncomeExpensesDetails,
      updatingIncomeExpenseDetails,
      getAggregatorIncomeExpensesDetailsForLoanAssessment,
      fetchingLendingDetails,
      updatingLendingDetails,
      getAggregatorLendingDetailsForLoanAssessment,
      fetchingKYC,
      activeDescriptions,
      updateActiveDescriptions,
      bulkActivateDescriptions,
      activeInputId
    } = loanAssessmentDetail(false);

    useStore().commit("loanAssessmentInfos/setCustomerId", props.customerId);

    const ifSectionActive = section => activeSection.value.includes(section);
    onMounted(() => {
      setNavActiveSection("kyc-title");
    });

    let currentTab = ref("");
    let shouldChangeTab = ref(true);
    const tabKeysWithId = {
      49: "application-tab",
      50: "history-tab",
      51: "bank-details-tab",
      52: "personal-loans-tab",
      53: "tags-tab",
      54: "doc-reqs-tab",
      55: "verification-tab"
    };

    watchEffect(() => (currentTab.value = "customer-details"));

    function changeTab(tab) {
      currentTab.value = tab;
    }

    function updateShouldChangeTab(status) {
      shouldChangeTab.value = status;
    }

    const updateRecordsExists = records => {
      let result = false;
      let updateRecordsCount = 0;
      for (const key in records) {
        updateRecordsCount += records[key].filter(
          timeline => !timeline.is_added
        ).length;
        if (updateRecordsCount) {
          result = true;
          break;
        }
      }
      return result;
    };

    const filterHistory = history => {
      let filteredRecords = {};
      Object.keys(history).forEach((key, data) => {
        filteredRecords[key] = history[key].filter(rec => !rec.is_added);
      });
      return filteredRecords;
    };

    const shouldSwitchTab = event =>
      event.target.value === undefined && event.which in tabKeysWithId;

    document.addEventListener("keyup", function(e) {
      if (shouldSwitchTab(e) && shouldChangeTab.value) {
        this.getElementById(tabKeysWithId[e.which])?.click();
      }
      !shouldChangeTab.value && updateShouldChangeTab(true);
      return false;
    });

    return {
      route,
      adminUser,
      aggregatorPersonalLoanDetails,
      aggregatorLoanDetailsTransitLobby,
      aggregatorIncomeExpenseDetailsTransitLobby,
      onAggregatorLendingDetailsSubmit,
      onAggregatorIncomeExpenseDetailsSubmit,
      customerName,
      kycWithAdditionalAttributes,
      applicationTransitionReasonTypes,
      getKycAttributeValueByName,
      pastLoansOfApplicantLinkedToAssessment,
      getLoanHistoryOfApp,
      fetchingApplicantLoans,
      bankAccountOfApplicantLinkedToAssessment,
      latestBankStatement,
      getAllBankStatements,
      allBankReports,
      onSubmitToSetActiveBankingIncomeAccount,
      getAssesmentSubmitLabelAsPerAssessmentStatus,
      triggerDismissActionAsPerAdminRole,
      setVisbilityOfFinalizingbuttonAsPerAdminRole,
      withdrawable,
      canBeRequestedToDecline,
      personalLoanStatuses,
      clearLoanAssessmentDetailsMessages,
      onClickTransformToInput,
      enabledAgreggatorDataField,
      onCloseAgreggatorInputBox,
      onCloseAndSubmitAgreggatorInput,
      onCloseAndSubmitAgreggatorInputDescription,
      onCloseAndSubmitPersonalLoanStatus,
      onCloseAndSubmitPersonalLoanType,
      onCloseAndSubmitPersonalLoanRepaymentAmount,
      onCloseAndSubmitPersonalLoanFrequency,
      onClosePersonalLoanStatusSelectBox,
      activeSection,
      setNavActiveSection,
      removeSectionFromActiveSections,
      ifSectionActive,
      fetchingApplicantBankAccounts,
      getBankAccountsOfApplicantLinkedToLoanAssessment,
      updatingPersonalLoansDetails,
      fetchingIncomeExpensesDetails,
      updatingIncomeExpenseDetails,
      getAggregatorIncomeExpensesDetailsForLoanAssessment,
      fetchingLendingDetails,
      updatingLendingDetails,
      getAggregatorLendingDetailsForLoanAssessment,
      changeTab,
      currentTab,
      fetchingKYC,
      activeDescriptions,
      updateActiveDescriptions,
      bulkActivateDescriptions,
      updateRecordsExists,
      filterHistory,
      activeInputId,
      updateShouldChangeTab
    };
  }
});
</script>

<style scoped>
.nav.nav-tabs a {
  cursor: pointer;
}
</style>
