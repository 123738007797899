export const refundRequestEnums = () => {
  function getStatusParamForRefundRequestViews(path) {
    if (path === "/new-refunds") {
      return "status_group=new";
    } else if (path === "/rejected-refunds") {
      return "status_group=rejected";
    } else if (path === "/completed-refunds") {
      return "status_group=success";
    } else if (path === "/failed-refunds") {
      return "status_group=failed";
    }

    return "";
  }

  return {
    getStatusParamForRefundRequestViews
  };
};
