import { ref, watchEffect, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "@/store";
import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";

export const loanApplicationDetails = () => {
  const store = useStore();

  const route = useRoute();

  const applicationIdParam = route.params.applicationId ?? "";
  const applicationId = parseInt(applicationIdParam);
  //set applciation id
  store.commit("loanAssessmentInfos/setApplicationId", applicationId);

  const customerIdParam = route.params.customerId ?? "";
  const customerId = ref(parseInt(customerIdParam));

  let currentTab = ref("");

  watchEffect(() => (currentTab.value = "application"));

  const isGlobalNotesDisplayed = computed(
    () => store.state.timelineNotesViewer.isGlobalNotesDisplayed
  );
  function onToggleIsGlobalNotesDisplayed() {
    store.commit("timelineNotesViewer/toggleIsGlobalNotesDisplayed");
  }

  function getLoan() {
    axiosWrapperService
      .axiosGet("/getLoanApplications/" + applicationId)
      .then(response => {
        if (["Applied", "Approved"].includes(response.data.data.status)) {
          store.dispatch(
            "lnAppDtsRouter/resolveAndReRouteLnAssessmentDtsPage",
            applicationId
          );
        }
        customerId.value = response.data.data.user_id;
      })
      .catch(error => {
        store.dispatch("globalToastMessage/showSimpleToast", {
          message: formatErrorMessage(error),
          type: "error"
        });
      });
  }

  getLoan();

  return {
    currentTab,
    applicationId,
    customerId,
    isGlobalNotesDisplayed,
    onToggleIsGlobalNotesDisplayed
  };
};
