<template>
  <div>
    <!--    <DarkModeSwitcher />-->
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a class="-intro-x flex items-center pt-5" href="">
            <img
              :src="require(`@/assets/images/logo-white.svg`)"
              alt="Icewall Tailwind HTML Admin Template"
              class="w-22 h-12"
            />
          </a>
          <div class="my-auto">
            <img
              :src="require(`@/assets/images/logo.png`)"
              alt="Icewall Tailwind HTML Admin Template"
              class="-intro-x w-1/2 -mt-16"
            />
            <div
              class="-intro-x text-white font-medium text-4xl leading-tight mt-10"
            >
              All in one portal to Manage <br />
              Loans for Fundo Lenders.
            </div>
            <div
              class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500"
            >
              Manage all your Loan Management accounts in one place
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
            >
              Sign In
            </h2>
            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              A few more clicks to sign in to your account. Manage all your
              e-commerce accounts in one place
            </div>
            <div class="intro-x mt-8">
              <input
                v-model="credentials.email"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                placeholder="Email"
                type="email"
              />
              <input
                v-model="credentials.password"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                placeholder="Password"
                type="password"
              />
              <div class="mt-3">
                <span>
                  <a
                    class="text-blue-600 underline"
                    href="/request-password-reset"
                  >
                    Forgot your password?</a
                  ></span
                >
                <span class="ml-4">
                  <a
                    class="text-blue-600 underline"
                    href="javascript:void(0);"
                    @click="openGoogleSSO"
                  >
                    <!--                  <a-->
                    <!--                    class="text-blue-600 underline"-->
                    <!--                    href="https://accounts.google.com/o/saml2/idp?idpid=C034lgjxy"-->
                    <!--                  >-->
                    Sign in with SSO</a
                  ></span
                >
              </div>
            </div>

            <!--            <div-->
            <!--              class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"-->
            <!--            >-->
            <!--              <div class="flex items-center mr-auto">-->
            <!--                <input-->
            <!--                  id="remember-me"-->
            <!--                  type="checkbox"-->
            <!--                  class="form-check-input border mr-2"-->
            <!--                />-->
            <!--                <label class="cursor-pointer select-none" for="remember-me"-->
            <!--                  >Remember me</label-->
            <!--                >-->
            <!--              </div>-->
            <!--              <a href="">Forgot Password?</a>-->
            <!--            </div>-->
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                class="bg-yellow-500 btn py-3 px-4 w-full xl:w-32 xl:mr-3 align-top "
                @click.prevent="loginAction"
              >
                Login
              </button>
            </div>
            <!--            <div-->
            <!--              class="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left"-->
            <!--            >-->
            <!--              By signin up, you agree to our <br />-->
            <!--              <a class="text-theme-1 dark:text-theme-10" href=""-->
            <!--                >Terms and Conditions</a-->
            <!--              >-->
            <!--              &-->
            <!--              <a class="text-theme-1 dark:text-theme-10" href=""-->
            <!--                >Privacy Policy</a-->
            <!--              >-->
            <!--            </div>-->
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
    <div
      v-if="fullPageLoading"
      class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen overflow-hidden bg-white opacity-75 flex flex-col items-center justify-center"
      style="z-index: 1000 !important;"
    >
      <div class="relative flex justify-center items-center h-3">
        <LoadingIcon class="w-16 h-16" color="green" icon="bars" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, watch } from "vue";
import Swal from "sweetalert2";
import { customSwalMessageBuilder } from "../../composables/utils/customSwalMessageBuilder";
import { useStore } from "@/store";
import { loginUsingSSO } from "@/libs/google-sso";

export default defineComponent({
  setup() {
    const store = useStore();

    const fullPageLoading = computed(() => store.state.main.fullPageLoading);

    const { buildSwalHtmlError } = customSwalMessageBuilder();

    const errorMessage = computed(() => store.state.auth.errorMessage);

    watch(
      () => errorMessage.value,
      message => {
        if (message) {
          Swal.fire(buildSwalHtmlError(message)).then(confirmed => {
            if (confirmed.isConfirmed) {
              store.commit("auth/setError");
            }
          });
        }
      }
    );

    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("login");
      switchOffDarkMode();
    });

    const credentials = reactive({
      email: null,
      password: null
    });

    const darkMode = computed(() => store.state.main.darkMode);

    const setDarkModeClass = () => {
      darkMode.value
        ? cash("html").addClass("dark")
        : cash("html").removeClass("dark");
    };

    const switchOffDarkMode = () => {
      store.dispatch("main/setDarkMode", false);
      setDarkModeClass();
    };

    function loginAction() {
      store.commit("main/setFullPageLoading");
      store.dispatch("auth/loginUser", credentials);
    }

    async function openGoogleSSO() {
      store.commit("main/setFullPageLoading");
      await loginUsingSSO();
    }

    return {
      fullPageLoading,
      credentials,
      loginAction,
      openGoogleSSO
    };
  }
});
</script>
