import { customSwalMessageBuilder } from "@/composables/utils/customSwalMessageBuilder";
import { useStore } from "@/store";
import Swal from "sweetalert2";
import { onMounted, watch, computed } from "vue";

export const loanActions = props => {
  const { buildSwalWarning } = customSwalMessageBuilder();

  const store = useStore();

  onMounted(() => {
    store.commit(
      "paymentScheduleLoanActions/setApplicationId",
      props.applicationId
    );
  });

  function createNewReschedule() {
    hideDropdownAndClearErrors();
    cash("#create-new-reschedule-modal").modal("show");
  }

  function createNewPromise() {
    hideDropdownAndClearErrors();
    cash("#create-new-promise-modal").modal("show");
  }

  function recordPaidPayment() {
    hideDropdownAndClearErrors();
    cash("#record-paid-payment-modal").modal("show");
  }

  function recordRefundedPayment() {
    hideDropdownAndClearErrors();
    cash("#record-refunded-payment-modal").modal("show");
  }

  function addFees() {
    hideDropdownAndClearErrors();
    cash("#add-fees-modal").modal("show");
  }

  function waiveFees() {
    hideDropdownAndClearErrors();
    cash("#waive-fees-modal").modal("show");
  }

  function changePaymentDate() {
    hideDropdownAndClearErrors();
    cash("#change-payment-date-modal").modal("show");
  }

  function changePaymentFrequency() {
    hideDropdownAndClearErrors();
    cash("#change-payment-frequency-modal").modal("show");
  }

  function newCardPayment() {
    hideDropdownAndClearErrors();
    cash("#new-card-payment-modal").modal("show");
  }

  function hideDropdownAndClearErrors() {
    cash(".dropdown-menu").hide();
    store.commit("paymentScheduleLoanActions/clearErrors");
  }

  function refreshAllPayments() {
    cash(".dropdown-menu").hide();
    store.commit("paymentScheduleLoanActions/clearErrors");
    Swal.fire(
      buildSwalWarning(
        "Checking scheduling status of all partial and future payments.",
        true,
        "Refresh All Payments"
      )
    ).then(confirmed => {
      if (confirmed.isConfirmed) {
        store.dispatch("paymentScheduleLoanActions/refreshPayments");
      }
    });
  }

  const successCount = computed(
    () => store.state.paymentScheduleLoanActions.successCount
  );

  function dismissModals() {
    // fn.modal('hide') will only proceed to hide element IF it is currently being shown
    cash("#create-new-reschedule-modal").modal("hide");
    cash("#create-new-promise-modal").modal("hide");
    cash("#record-paid-payment-modal").modal("hide");
    cash("#record-refunded-payment-modal").modal("hide");
    cash("#add-fees-modal").modal("hide");
    cash("#waive-fees-modal").modal("hide");
    cash("#change-payment-date-modal").modal("hide");
    cash("#change-payment-frequency-modal").modal("hide");
    cash("#new-card-payment-modal").modal("hide");
  }

  watch(
    () => successCount.value,
    () => {
      dismissModals();
    }
  );
  return {
    createNewReschedule,
    createNewPromise,
    recordPaidPayment,
    recordRefundedPayment,
    addFees,
    waiveFees,
    changePaymentDate,
    changePaymentFrequency,
    newCardPayment,
    refreshAllPayments
  };
};
