const sets = [
  "agent_verified",
  "system_verified",
  "model_verified",
  "frankie_verified"
];

const extraSets = ["last_modified_by"];

const specialSet = "verification_required";

export const existsInSets = data => {
  return sets.includes(data);
};

export const sortRecordAndVerify = data => {
  let condition = false;

  if (specialSet in data) {
    condition = !data[specialSet];
  }

  //Checking basic fields
  if (!condition) {
    for (let set in sets) {
      if (sets[set] && Boolean(data[sets[set]])) {
        condition = true;
        break;
      }
    }
  }

  //Checking special fields
  if (!condition) {
    for (let set in extraSets) {
      if (extraSets[set] && Boolean(data[extraSets[set]])) {
        condition = true;
        break;
      }
    }
  }
  return condition;
};
