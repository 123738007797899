import { axiosWrapperService } from "../services/auth/axios-wrapper-service";

const state = () => {
  return {
    totalDisbursed: 0,
    totalFailedDisbursal: 0,
    totalDisbursalsOfThisMonth: 0,
    totalDisbursalsOfThisWeek: 0
  };
};

// getters
const getters = {
  totalDisbursed: state => state.totalDisbursed,
  totalFailedDisbursal: state => state.totalFailedDisbursal,
  totalDisbursalsOfThisMonth: state => state.totalDisbursalsOfThisMonth,
  totalDisbursalsOfThisWeek: state => state.totalDisbursalsOfThisWeek
};

// actions
const actions = {
  getDisbursalStatCounters: async ({ commit, rootState }) => {
    try {
      rootState["main/fullPageLoading"] = true;
      axiosWrapperService.axiosGet("/getDisbursalCounters").then(response => {
        commit(
          "setTotalDisbursed",
          response.data.data.total_settled_disbursals
        );
        commit(
          "setTotalFailedDisbursal",
          response.data.data.total_failed_disbursals
        );
        commit(
          "setTotalDisbursalsOfThisMonth",
          response.data.data.total_disbursals_for_this_month
        );
        commit(
          "setTotalDisbursalsOfThisWeek",
          response.data.data.total_disbursals_for_this_week
        );
      });
    } catch (e) {
      commit("setError", "Failed to login");
    } finally {
      rootState["main/fullPageLoading"] = false;
    }
  }
};

// mutations
const mutations = {
  setTotalDisbursed(state, totalDisbursed) {
    state.totalDisbursed = totalDisbursed;
  },
  setTotalFailedDisbursal(state, totalFailedDisbursal) {
    state.totalFailedDisbursal = totalFailedDisbursal;
  },
  setTotalDisbursalsOfThisMonth(state, totalDisbursalsOfThisMonth) {
    state.totalDisbursalsOfThisMonth = totalDisbursalsOfThisMonth;
  },
  setTotalDisbursalsOfThisWeek(state, totalDisbursalsOfThisWeek) {
    state.totalDisbursalsOfThisWeek = totalDisbursalsOfThisWeek;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
