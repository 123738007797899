<template>
  <div>
    <div
      :class="[
        { 'bg-theme-32': isActive },
        `loan-${getActiveApplicationQualityClass()}`
      ]"
      class="flex mt-1 p-4 box cursor-pointer section-header"
      @click="setNavActiveSection('loan-tags-title')"
    >
      <h2
        id="loan-tags-title"
        class="font-medium text-theme-7 text-base w-full"
      >
        LOAN TAGS
      </h2>
      <ChevronDownIcon :class="{ 'transform rotate-180': !isActive }" />
    </div>
    <div
      v-if="isActive"
      id="loan-tagging-section"
      class="grid grid-cols-2 gap-6"
    >
      <div class="grid grid-cols-12">
        <div class="col-span-12">
          <!-- BEGIN: Personal Information -->
          <div class="box h-full">
            <div class="p-5">
              <div class="grid grid-cols-12 gap-x-5">
                <div class="col-span-12">
                  <h4 class="font-medium text-theme-3 text-base mb-5">
                    Error Tags
                  </h4>
                  <!-- BEGIN: Header -->
                  <div v-if="!fetchingLnErrorTags" class="box mt-5">
                    <div id="lending-tagging-header" class="p-5">
                      <div class="preview">
                        <TomSelect
                          v-if="lendingErrorTags.length > 0"
                          v-model="selectedErrorTags"
                          :options="{
                            placeholder: 'Select application tags',
                            plugins: {
                              dropdown_header: {
                                title: 'Application Error Tags'
                              }
                            }
                          }"
                          class="w-full"
                          multiple
                        >
                          <option
                            v-for="(lenErrorTag,
                            lendErrorTagKey) in lendingErrorTags"
                            :key="lendErrorTagKey"
                            :value="lenErrorTag.slug"
                          >
                            {{ lenErrorTag.cat_hinted_name }}
                          </option>
                        </TomSelect>
                        <div
                          v-if="
                            changeInSelectedErrorTags &&
                              lendingErrorTags.length > 0
                          "
                          id="error-tag-confirm-btn"
                          class="m-3"
                        >
                          <button
                            class="
                              btn btn-rounded
                              bg-theme-15
                              w-1/2
                              text-white
                              sm:w-auto
                              mr-2
                              hover:bg-black
                            "
                            type="button"
                            @click.prevent="onCancelSelectedErrorTagsEditMode"
                          >
                            <XIcon class="w-5 h-5" />
                          </button>
                          <button
                            class="
                              btn btn-rounded
                              bg-theme-3
                              w-1/2
                              text-white
                              sm:w-auto
                              mr-2
                              hover:bg-theme-23
                            "
                            type="button"
                            @click.prevent="onSubmitSelectedErrorTags"
                          >
                            <SaveIcon class="w-5 h-5" />
                          </button>
                        </div>
                      </div>
                      <h5 v-if="lendingErrorTagsAttachedToLoan.length < 1">
                        No Tags has been attached
                      </h5>
                    </div>
                  </div>
                  <FetchingRecordDivTemplate v-else />
                  <!-- END: Header -->
                </div>
              </div>
            </div>
          </div>
          <!-- END: Personal Information -->
        </div>
      </div>
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12">
          <!-- BEGIN: Personal Information -->
          <div class="box h-full">
            <div class="p-5">
              <div class="grid grid-cols-12 gap-x-5">
                <div class="col-span-12">
                  <h4 class="font-medium text-theme-3 text-base mb-5">
                    System Tags
                    <small class="text-gray-600"
                      >(Can only add. Cannot remove.)</small
                    >
                  </h4>
                  <!--                   For later use when the system tags are automated we can remove the dropdown -->
                  <!--                  <div class="mt-2">-->
                  <!--                    <div v-if="selectedSystemErrorTags.length > 0" class="grid grid-cols-2 gap-2">-->
                  <!--                      <div v-for="(tag,index) in selectedSystemErrorTags" :key="index" class="p-2 m-1 bg-red-400 text-white text-sm rounded">{{ tag }}</div>-->
                  <!--                    </div>-->
                  <!--                    <h5 class="mt-2" v-else>No Tags has been attached</h5>-->
                  <!--                  </div>-->
                  <!--                  <FetchingRecordDivTemplate v-if="fetchingLnNonErrorTags"/>-->
                  <!-- BEGIN: Header -->
                  <div v-if="!fetchingLnNonErrorTags" class="box mt-5">
                    <div id="lending-error-tagging-header" class="p-5">
                      <div class="preview">
                        <TomSelect
                          v-if="lendingNonErrorTags.length > 0"
                          v-model="selectedNonErrorTags"
                          :options="{
                            placeholder: 'Select application tags',
                            plugins: {
                              dropdown_header: {
                                title: 'Application Issue Tags'
                              }
                            }
                          }"
                          class="w-full bg-red"
                          multiple
                        >
                          <option
                            v-for="(lenNonErrorTag,
                            lenNonErrorTagKey) in lendingNonErrorTags"
                            :key="lenNonErrorTagKey"
                            :value="lenNonErrorTag.slug"
                          >
                            {{ lenNonErrorTag.cat_hinted_name }}
                          </option>
                        </TomSelect>
                        <div
                          v-if="reasonField.enable"
                          class="w-full flex flex-col px-2 py-2"
                        >
                          <small class="text-gray-600"
                            >Reason for blacklisting</small
                          >
                          <select
                            v-model="reasonField.reason"
                            :disabled="reasonField.readOnly"
                            class=" form-select box mt-3 sm:mt-0"
                          >
                            <option disabled selected>
                              Select any reason</option
                            >
                            <option
                              v-for="(reason, key) in blacklist_reasons"
                              :key="key"
                              :value="reason"
                            >
                              {{ reason }}
                            </option>
                          </select>
                        </div>
                        <div
                          v-if="
                            changeInSelectedErrorNonTags &&
                              lendingNonErrorTags.length > 0
                          "
                          id="other-tag-confirm-btn"
                          class="m-3"
                        >
                          <button
                            class="
                              btn btn-rounded
                              bg-theme-15
                              w-1/2
                              text-white
                              sm:w-auto
                              mr-2
                              hover:bg-black
                            "
                            type="button"
                            @click.prevent="
                              onCancelSelectedNonErrorTagsEditMode
                            "
                          >
                            <XIcon class="w-5 h-5" />
                          </button>
                          <button
                            class="
                              btn btn-rounded
                              bg-theme-3
                              w-1/2
                              text-white
                              sm:w-auto
                              mr-2
                              hover:bg-theme-23
                            "
                            type="button"
                            @click.prevent="onSubmitSelectedNonErrorTags"
                          >
                            <SaveIcon class="w-5 h-5" />
                          </button>
                        </div>
                        <h5
                          v-if="lendingErrorNonTagsAttachedToLoan.length < 1"
                          class="mt-2"
                        >
                          No Tags has been attached
                        </h5>
                      </div>
                    </div>
                  </div>
                  <FetchingRecordDivTemplate v-else />
                  <!-- END: Header -->
                </div>
              </div>
            </div>
          </div>
          <!-- END: Personal Information -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import { loanTags } from "@/composables/components/loan-applications/loanTags";
import FetchingRecordDivTemplate from "@/components/fetching-record-div-template/Main";
import { loanQuality } from "@/composables/loanQuality";

export default defineComponent({
  components: {
    FetchingRecordDivTemplate
  },
  props: {
    applicationId: {
      type: Number,
      require: false
    },
    isActive: {
      type: Boolean,
      require: false,
      default: true
    },
    setNavActiveSection: {
      type: Function,
      require: true,
      default: null
    }
  },
  setup(props, context) {
    const {
      adminUser,
      fetchingLnNonErrorTags,
      fetchingLnErrorTags,
      selectedErrorTags,
      selectedNonErrorTags,
      changeInSelectedErrorTags,
      changeInSelectedErrorNonTags,
      onCancelSelectedErrorTagsEditMode,
      onSubmitSelectedErrorTags,
      onSubmitSelectedNonErrorTags,
      onCancelSelectedNonErrorTagsEditMode,
      isGlobalNotesDisplayed,
      lendingErrorTags,
      lendingErrorTagsAttachedToLoan,
      lendingNonErrorTags,
      lendingTagsAttachedToLoan,
      lendingErrorNonTagsAttachedToLoan,
      busy,
      initialiseLoanTags,
      canManuallyChangeSystemTags,
      reasonField,
      blacklist_reasons
    } = loanTags(props);

    initialiseLoanTags();

    //The selected system errors tags for show - based on the slug
    const selectedSystemErrorTags = computed(() => {
      if (selectedNonErrorTags.value.length === 0) {
        return [];
      }
      let tags = [];
      selectedNonErrorTags.value.forEach(slug => {
        let found = lendingNonErrorTags.value.find(
          element => element.slug === slug
        );
        if (found) {
          tags.push(found.name);
        }
      });
      return tags;
    });

    const { getActiveApplicationQualityClass } = loanQuality();

    return {
      adminUser,
      fetchingLnNonErrorTags,
      fetchingLnErrorTags,
      selectedErrorTags,
      selectedNonErrorTags,
      changeInSelectedErrorTags,
      changeInSelectedErrorNonTags,
      onCancelSelectedErrorTagsEditMode,
      onSubmitSelectedErrorTags,
      onSubmitSelectedNonErrorTags,
      onCancelSelectedNonErrorTagsEditMode,
      isGlobalNotesDisplayed,
      lendingErrorTags,
      lendingErrorTagsAttachedToLoan,
      lendingNonErrorTags,
      lendingTagsAttachedToLoan,
      lendingErrorNonTagsAttachedToLoan,
      busy,
      selectedSystemErrorTags,
      canManuallyChangeSystemTags,
      reasonField,
      blacklist_reasons,
      getActiveApplicationQualityClass
    };
  }
});
</script>
