<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">
      Admin Account List
    </h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
      >
        <div class="dropdown">
          <div class="dropdown-menu w-40">
            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
              <a
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              >
                <PrinterIcon class="w-4 h-4 mr-2" /> Print
              </a>
              <a
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              >
                <FileTextIcon class="w-4 h-4 mr-2" /> Export to Excel
              </a>
              <a
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              >
                <FileTextIcon class="w-4 h-4 mr-2" /> Export to PDF
              </a>
            </div>
          </div>
        </div>
        <div class="hidden md:block mx-auto text-gray-600"></div>
      </div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">Full Name</th>
              <th class="text-center whitespace-nowrap">Email</th>
              <th class="text-center whitespace-nowrap">Status</th>
              <th class="text-center whitespace-nowrap">Created At</th>
              <th class="text-center whitespace-nowrap">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(adminAccount, adminAccountKey) in adminList"
              :key="adminAccountKey"
              class="intro-x"
            >
              <td>
                {{ adminAccount.fullname }}
              </td>
              <td class="text-center">{{ adminAccount.email }}</td>
              <td class="text-center">
                <div
                  class="flex items-center justify-center"
                  :class="{
                    'text-theme-9': 1,
                    'text-theme-6': !1
                  }"
                >
                  <CheckSquareIcon class="w-4 h-4 mr-2" />
                  {{ adminAccount.status }}
                </div>
              </td>
              <td class="text-center">
                {{
                  new Date(adminAccount.created_at).toISOString().slice(0, 10)
                }}
              </td>
              <td class="text-center">
                <button
                  @click="showEditAdminPage(adminAccount.uuid)"
                  class="btn btn-outline-secondary shadow-md mr-2"
                >
                  Edit
                </button>
              </td>
              <!--              <td class="table-report__action w-56">-->
              <!--                <div class="flex justify-center items-center">-->
              <!--                  <a class="flex items-center mr-3" href="javascript:;">-->
              <!--                    <CheckSquareIcon class="w-4 h-4 mr-1" />-->
              <!--                    Edit-->
              <!--                  </a>-->
              <!--                  <a-->
              <!--                    class="flex items-center text-theme-6"-->
              <!--                    href="javascript:;"-->
              <!--                    data-toggle="modal"-->
              <!--                    data-target="#delete-confirmation-modal"-->
              <!--                  >-->
              <!--                    <Trash2Icon class="w-4 h-4 mr-1" /> Delete-->
              <!--                  </a>-->
              <!--                </div>-->
              <!--              </td>-->
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- BEGIN: Pagination -->
      <!--      <div-->
      <!--        class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center"-->
      <!--      >-->
      <!--        <ul class="pagination">-->
      <!--          <li>-->
      <!--            <a class="pagination__link" href="">-->
      <!--              <ChevronsLeftIcon class="w-4 h-4" />-->
      <!--            </a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a class="pagination__link" href="">-->
      <!--              <ChevronLeftIcon class="w-4 h-4" />-->
      <!--            </a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a class="pagination__link" href="">...</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a class="pagination__link" href="">1</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a class="pagination__link pagination__link&#45;&#45;active" href="">2</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a class="pagination__link" href="">3</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a class="pagination__link" href="">...</a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a class="pagination__link" href="">-->
      <!--              <ChevronRightIcon class="w-4 h-4" />-->
      <!--            </a>-->
      <!--          </li>-->
      <!--          <li>-->
      <!--            <a class="pagination__link" href="">-->
      <!--              <ChevronsRightIcon class="w-4 h-4" />-->
      <!--            </a>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--        <select class="w-20 form-select box mt-3 sm:mt-0">-->
      <!--          <option>10</option>-->
      <!--          <option>25</option>-->
      <!--          <option>35</option>-->
      <!--          <option>50</option>-->
      <!--        </select>-->
      <!--      </div>-->
      <!-- END: Pagination -->
    </div>
    <!-- BEGIN: Delete Confirmation Modal -->
    <div
      id="delete-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">Are you sure?</div>
              <div class="text-gray-600 mt-2">
                Do you really want to delete these records? <br />This process
                cannot be undone.
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </button>
              <button type="button" class="btn btn-danger w-24">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
  </div>
</template>

<script>
import { computed, defineComponent, onMounted } from "vue";
import { useStore } from "@/store";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const adminList = computed(() => store.state.admin.adminList);

    function showEditAdminPage(uuid) {
      router.push(`/edit-admin/${uuid}`);
    }

    function getAdminsAction() {
      store.dispatch("admin/getAdmins");
    }

    onMounted(() => {
      getAdminsAction();
    });

    return {
      adminList,
      showEditAdminPage
    };
  }
});
</script>
