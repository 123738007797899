import axios from "axios";
import authHeader from "./auth-header-service";
import { handleResponse } from "@/services/auth/responseHandler/handle-response-service";

class AxiosWrapperService {
  constructor() {
    authHeader();
  }
  axiosGet(url, param = null) {
    const responsePromise = axios.get(
      process.env.VUE_APP_BASE_URL + url,
      param
    );
    return handleResponse(responsePromise);
  }
  axiosPost(url, param) {
    const responsePromise = axios.post(
      process.env.VUE_APP_BASE_URL + url,
      param
    );
    return handleResponse(responsePromise);
  }
  axiosPut(url, param) {
    const responsePromise = axios.put(
      process.env.VUE_APP_BASE_URL + url,
      param
    );
    return handleResponse(responsePromise);
  }
  axiosPatch(url, param) {
    const responsePromise = axios.patch(
      process.env.VUE_APP_BASE_URL + url,
      param
    );
    return handleResponse(responsePromise);
  }
  axiosDelete(url, param) {
    const responsePromise = axios.delete(
      process.env.VUE_APP_BASE_URL + url,
      param
    );
    return handleResponse(responsePromise);
  }

  axiosPurgeAuthorizationHeader() {
    delete axios.defaults.headers.common["Authorization"];
  }
}

export let axiosWrapperService = new AxiosWrapperService();
