<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Add Admin</h2>
    </div>
    <div v-if="adminRoles" class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Validation -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">
              Admin Form
            </h2>
          </div>
          <div id="form-validation" class="p-5">
            <div class="preview">
              <!-- BEGIN: Validation Form -->
              <form class="validate-form" @submit.prevent="save">
                <div class="input-form">
                  <label
                    for="validation-form-2"
                    class="form-label w-full flex flex-col sm:flex-row"
                  >
                    Full Name
                    <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600"
                      >Required, at least 2 characters</span
                    >
                  </label>
                  <input
                    id="validation-form-2"
                    v-model.trim="validate.fullname.$model"
                    type="text"
                    name="fullname"
                    class="form-control"
                    :class="{ 'border-theme-6': validate.fullname.$error }"
                    placeholder="Last Name"
                  />
                  <template v-if="validate.fullname.$error">
                    <div
                      v-for="(error, index) in validate.fullname.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
                </div>
                <div class="input-form mt-3">
                  <label
                    for="validation-form-3"
                    class="form-label w-full flex flex-col sm:flex-row"
                  >
                    Email
                    <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600"
                      >Required, email address format</span
                    >
                  </label>
                  <input
                    id="validation-form-3"
                    v-model.trim="validate.email.$model"
                    type="email"
                    name="email"
                    class="form-control"
                    :class="{ 'border-theme-6': validate.email.$error }"
                    placeholder="example@gmail.com"
                  />
                  <template v-if="validate.email.$error">
                    <div
                      v-for="(error, index) in validate.email.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
                </div>
                <div class="input-form mt-3">
                  <label
                    for="validation-form-5"
                    class="form-label w-full flex flex-col sm:flex-row"
                  >
                    Password
                    <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600"
                      >Required, at least 6 characters</span
                    >
                  </label>
                  <input
                    id="validation-form-5"
                    v-model.trim="validate.password.$model"
                    type="password"
                    name="password"
                    class="form-control"
                    :class="{ 'border-theme-6': validate.password.$error }"
                    placeholder="secret"
                  />
                  <template v-if="validate.password.$error">
                    <div
                      v-for="(error, index) in validate.password.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
                </div>
                <div class="input-form mt-3">
                  <label
                    for="validation-form-6"
                    class="form-label w-full flex flex-col sm:flex-row"
                  >
                    Confirm Password
                    <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600"
                      >Required, at least 6 characters</span
                    >
                  </label>
                  <input
                    id="validation-form-6"
                    v-model.trim="validate.password_confirmation.$model"
                    type="password"
                    name="password_confirmation"
                    class="form-control"
                    :class="{
                      'border-theme-6': validate.password_confirmation.$error
                    }"
                    placeholder="secret"
                  />
                  <template v-if="validate.password_confirmation.$error">
                    <div
                      v-for="(error, index) in validate.password_confirmation
                        .$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
                </div>
                <div class="input-form mt-3">
                  <label class="form-label w-full flex flex-col sm:flex-row">
                    Assign Roles
                    <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600"
                      >Required</span
                    >
                  </label>
                  <div
                    v-for="(adminRole, adminRoleKey) in adminRoles"
                    :key="adminRoleKey"
                    class="input-form mt-3"
                  >
                    <label class="form-label w-full flex flex-col sm:flex-row">
                      {{ beautifySlug(adminRole) }}
                    </label>
                    <input
                      v-model="formData.roles"
                      type="checkbox"
                      :value="adminRole"
                      class="form-control form-check-input"
                    />
                  </div>
                </div>
                <button type="submit" class="bg-yellow-500 btn mt-5">
                  Register
                </button>
              </form>
              <!-- END: Validation Form -->
              <!-- BEGIN: Success Notification Content -->
              <div
                id="success-notification-content"
                class="toastify-content hidden flex"
              >
                <CheckCircleIcon class="text-theme-9" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Registration success!</div>
                  <div class="text-gray-600 mt-1">
                    Please check your e-mail for further info!
                  </div>
                </div>
              </div>
              <!-- END: Success Notification Content -->
              <!-- BEGIN: Failed Notification Content -->
              <div
                id="failed-notification-content"
                class="toastify-content hidden flex"
              >
                <XCircleIcon class="text-theme-6" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Registration failed!</div>
                  <div class="text-gray-600 mt-1">
                    Please check the fileld form.
                  </div>
                </div>
              </div>
              <!-- END: Failed Notification Content -->
            </div>
          </div>
        </div>
        <!-- END: Form Validation -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  watch
} from "vue";
import { required, minLength, email } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useStore } from "@/store";
import Toastify from "toastify-js";
import Swal from "sweetalert2";
import { customSwalMessageBuilder } from "../../composables/utils/customSwalMessageBuilder";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const formData = reactive({
      fullname: "",
      email: "",
      password: "",
      password_confirmation: "",
      roles: []
    });
    const rules = {
      fullname: {
        required,
        minLength: minLength(2)
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6)
      },
      password_confirmation: {
        required,
        maxLength: minLength(6)
      }
    };
    function resetForm() {
      formData.fullname = "";
      formData.email = "";
      formData.password = "";
      formData.password_confirmation = "";
      formData.roles = [];
      validate.value.$reset();
    }
    const validate = useVuelidate(rules, toRefs(formData));
    const store = useStore();
    const router = useRouter();

    const adminRoles = computed(() => store.state.admin.adminRoles);

    const { buildSwalSuccess, buildSwalHtmlError } = customSwalMessageBuilder();

    const addAdminSuccessMessage = computed(
      () => store.state.admin.addAdminSuccessMessage
    );

    const addAdminErrorMessage = computed(
      () => store.state.admin.addAdminErrorMessage
    );

    watch(
      () => addAdminSuccessMessage.value,
      message => {
        if (message) {
          Swal.fire(buildSwalSuccess(message)).then(confirmed => {
            if (confirmed.isConfirmed) {
              store.commit("admin/clearMessages");
              router.push('/admin-list');
            }
          });
          resetForm();
        }
      }
    );

    watch(
      () => addAdminErrorMessage.value,
      message => {
        if (message) {
          Swal.fire(buildSwalHtmlError(message)).then(confirmed => {
            if (confirmed.isConfirmed) {
              store.commit("admin/clearMessages");
            }
          });
        }
      }
    );

    const save = () => {
      store.commit("main/setFullPageLoading");
      validate.value.$touch();
      if (validate.value.$invalid) {
        Toastify({
          node: cash("#failed-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
        store.commit("main/resetFullPageLoading");
      } else {
        store.dispatch("admin/addAdmin", formData);
      }
    };

    function getAdminRolesAction() {
      store.dispatch("admin/getAdminRoles");
    }

    function beautifySlug(givenSlug) {
      let slug = givenSlug.replace("-", " ");

      return slug.toUpperCase().charAt(0) + slug.slice(1);
    }

    onMounted(() => {
      getAdminRolesAction();
    });

    return {
      beautifySlug,
      adminRoles,
      validate,
      formData,
      save
    };
  }
});
</script>
