<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Lending Dashboard</h2>
    </div>

    <div class="grid grid-cols-12 mt-5">
      <div class="flex col-span-2 mt-12 sm:mt-5">
        <div class="flex flex-col w-full">
          <div class="intro-y flex-1 box py-8 mb-5 lg:mb-0">
            <UserIcon
              class="block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"
            />
            <div class="text-xl font-medium text-center mt-10">
              {{ adminUser.fullname }}
            </div>
            <div
              class="
                text-xl
                font-medium
                text-gray-600
                dark:text-gray-400
                px-10
                text-center
                mx-auto
                mt-2
              "
            >
              {{
                path
                  .split("/")[2]
                  .split("-")
                  .map(w => w.charAt(0).toUpperCase() + w.slice(1))
                  .join(" ")
              }}
            </div>
          </div>
        </div>
      </div>
      <!-- BEGIN: Assessment Counter -->

      <!-- END: Assessment Counter -->
      <div class="intro-y col-span-10">
        <!-- BEGIN: Striped Rows -->
        <div class="intro-y box mt-5 ml-5">
          <div
            class="
              flex flex-col
              sm:flex-row
              items-center
              p-5
              border-b border-gray-200
            "
          >
            <h2 class="font-medium text-base mr-auto">Activity Overview</h2>
            <div
              class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
            ></div>
          </div>
          <div id="activity-overview-table" class="p-5">
            <div class="preview">
              <div class="overflow-x-auto">
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Activity
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Today
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        This Week
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Last 7 days
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        This Month
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(activity,
                      activityKey,
                      activityIterationIndex) in activityStats"
                      :key="activityKey"
                      :class="{
                        'bg-gray-200 dark:bg-dark-1':
                          activityIterationIndex % 2 < 1
                      }"
                    >
                      <td class="border-b dark:border-dark-5">
                        {{
                          activityKey
                            .split("_")
                            .map(w => w.charAt(0).toUpperCase() + w.slice(1))
                            .join(" ")
                        }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ activity.total_assessment_for_today }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ activity.total_assessment_for_this_week }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ activity.total_assessment_for_last_7_days }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ activity.total_assessment_for_this_month }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Striped Rows -->
      </div>
      <div class="intro-y col-span-12">
        <!-- BEGIN: Table Head Options -->
        <div class="intro-y box mt-5">
          <div
            class="
              flex flex-col
              sm:flex-row
              items-center
              p-5
              border-b border-gray-200
            "
          >
            <h2 class="font-medium text-base mr-auto">Assessment Bucket</h2>
            <div
              class="sm:flex items-center sm:mr-2"
              v-if="!settingGetCustomerConfigs"
            >
              <label class="w-full flex-none xl:flex-initial mr-2"
                >Select Order Type</label
              >
              <select
                v-model="assessmentFetchSelectionType"
                class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
                @change="onChangeAssessmentFetchOrder"
              >
                <option value="priority" selected>Priority List</option>
                <option value="ascending">Oldest to Newest</option>
              </select>
            </div>
            <div class="sm:w-auto flex items-center mt-3 sm:mt-0">
              <button
                v-if="
                  adminUser.permissions.includes(
                    'lendings.update.agent_id.self'
                  ) && !settingGetCustomerConfigs
                "
                id="tabulator-print"
                class="btn hover:bg-green-700 bg-green-500 w-1/2 sm:w-auto mr-2"
                @click="onFetchAssessmentReqSubmit"
              >
                <span class="text-white"
                  ><PlayCircleIcon class="w-6 h-6 mr-2" /> Get Customer</span
                >
              </button>
              <button v-else class="btn btn-success mr-1 mb-2">
                Processing
                <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
          <div id="head-options-table" class="p-5">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
                  id="tabulator"
                  ref="tableRef"
                  class="mt-5 table-report table-report--tabulator"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Table Head Options -->
        <!-- BEGIN: Striped Rows -->
        <div class="intro-y box mt-5">
          <div
            class="
              flex flex-col
              sm:flex-row
              items-center
              p-5
              border-b border-gray-200
            "
          >
            <h2 class="font-medium text-base mr-auto">Recently Assessed</h2>
            <div
              class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
            >
              <button
                id="recently-assessed-view-all"
                class="
                  btn
                  hover:bg-green-700
                  text-white
                  font-bold
                  bg-green-500
                  w-1/2
                  sm:w-auto
                  mr-2
                "
              >
                <ArrowRightIcon class="w-6 h-6 mr-2" /> View All ...
              </button>
            </div>
          </div>
          <div id="striped-rows-table" class="p-5">
            <div class="preview">
              <div class="overflow-x-auto">
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Customer Name
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Loan Amount
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Frequency
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Repayments
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Rep.Amount
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Current Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(pastAssessment,
                      pastAssessmentKey,
                      pastAssesmentIndex) in recentlyAssessedAssessments"
                      :key="pastAssessmentKey"
                      :class="{
                        'bg-gray-200 dark:bg-dark-1': pastAssesmentIndex % 2 < 1
                      }"
                    >
                      <td class="border-b dark:border-dark-5">
                        {{ pastAssessment.fullname }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ pastAssessment.loan.total_amount }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ pastAssessment.loan.repayment_frequency }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ pastAssessment.loan.repayments }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ pastAssessment.loan.repayment_amount }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ pastAssessment.status }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Striped Rows -->
        <FundoLmsToastAlert />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { lendingDashboard } from "../../composables/lendingDashboard";
import FundoLmsToastAlert from "@/components/fundo-lms-toast-alert/Main.vue";

export default defineComponent({
  components: {
    FundoLmsToastAlert
  },
  setup() {
    const {
      adminUser,
      tableRef,
      fromDate,
      toDate,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      onExport,
      breadcrumb,
      path,
      assessmentBucketList,
      activityStats,
      recentlyAssessedAssessments,
      getAccessBucketsAction,
      approvalRejectionDataObject,
      onFetchAssessmentReqSubmit,
      onApprovalSubmit,
      onRejectionSubmit,
      init,
      assessmentFetchSelectionType,
      settingGetCustomerConfigs,
      onChangeAssessmentFetchOrder
    } = lendingDashboard();

    return {
      adminUser,
      tableRef,
      fromDate,
      toDate,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      onExport,
      breadcrumb,
      path,
      assessmentBucketList,
      activityStats,
      recentlyAssessedAssessments,
      getAccessBucketsAction,
      approvalRejectionDataObject,
      onFetchAssessmentReqSubmit,
      onApprovalSubmit,
      onRejectionSubmit,
      init,
      assessmentFetchSelectionType,
      settingGetCustomerConfigs,
      onChangeAssessmentFetchOrder
    };
  }
});
</script>
