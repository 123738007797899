<template>
  <div>
    <!--    <DarkModeSwitcher />-->
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="-intro-x flex items-center pt-5">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              class="w-22 h-12"
              :src="require(`@/assets/images/logo-white.svg`)"
            />
          </a>
          <div class="my-auto">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/logo.png`)"
            />
            <div
              class="-intro-x text-white font-medium text-4xl leading-tight mt-10"
            >
              All in one portal to Manage <br />
              Loans for Fundo Lenders.
            </div>
            <div
              class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500"
            >
              Manage all your Loan Management accounts in one place
            </div>
          </div>
        </div>
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
            >
              Signing in
            </h2>
            <div class="intro-x mt-2 text-gray-500 text-center">
              Loading...
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, watch } from "vue";
import Swal from "sweetalert2";
import { customSwalMessageBuilder } from "../../composables/utils/customSwalMessageBuilder";
import { useStore } from "@/store";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const store = useStore();
    const route = useRoute();
    const { buildSwalHtmlError } = customSwalMessageBuilder();
    const errorMessage = computed(() => store.state.auth.errorMessage);
    watch(
      () => errorMessage.value,
      message => {
        if (message) {
          Swal.fire(buildSwalHtmlError(message)).then(confirmed => {
            if (confirmed.isConfirmed) {
              store.commit("auth/setError");
            }
          });
        }
      }
    );

    onMounted(() => {
      cash("body").addClass("login");
      store.dispatch("auth/ssoLogin", { accessToken: route.query.token });
    });

    return {};
  }
});
</script>
