<template>
  <div>
    <div
      :class="[
        { 'bg-theme-32': isActive },
        `loan-${getActiveApplicationQualityClass()}`
      ]"
      class="flex mt-1 p-4 box cursor-pointer section-header"
      @click="setNavActiveSection('loan-history-with-fundo-title')"
    >
      <h2
        id="loan-history-with-fundo-title"
        class="font-medium text-theme-7 text-base w-full"
      >
        LOAN HISTORY WITH FUNDO
      </h2>
      <ChevronDownIcon :class="{ 'transform rotate-180': !isActive }" />
    </div>
    <div
      v-if="isActive"
      id="ln-history-with-fundo-section"
      class="grid grid-cols-2 gap-6"
    >
      <div v-if="!fetchingApplicantLoans" class="col-span-12">
        <!-- BEGIN: Striped Rows -->
        <div class="box">
          <div
            class="
                flex flex-col
                sm:flex-row
                items-center
                p-5
                border-b border-gray-200
              "
          >
            <h2 class="font-medium text-theme-3 text-base mr-auto">
              Applications
            </h2>
          </div>
          <div id="closed-applications-table" class="p-5">
            <div class="preview">
              <div class="overflow-x-auto">
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                      >
                        App ID
                      </th>
                      <th
                        class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                      >
                        Status
                      </th>
                      <th
                        class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                      >
                        Declined Reason
                      </th>
                      <th
                        class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                      >
                        Applied Date
                      </th>
                      <th
                        class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                      >
                        Closed Date
                      </th>
                      <th
                        class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                      >
                        Principal Amt
                      </th>
                      <th
                        class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                      >
                        Loan Reason
                      </th>
                      <th
                        class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                      >
                        Installments
                      </th>
                      <th
                        class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                      >
                        Repayment Amt
                      </th>
                      <th
                        class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                      >
                        Regular FPD
                      </th>
                      <th
                        class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                      >
                        Regular LPD
                      </th>
                      <th
                        class="
                            border-b-2
                            dark:border-dark-5
                            whitespace-nowrap
                          "
                      >
                        EP LRS
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    v-if="pastLoansOfApplicantLinkedToAssessment.length > 0"
                  >
                    <tr
                      v-for="(loan,
                      loankey,
                      loanIndex) in pastLoansOfApplicantLinkedToAssessment"
                      :key="loankey"
                      :class="{
                        'bg-gray-200 dark:bg-dark-1': loanIndex % 2 > 1
                      }"
                      class="bg-gray-200 dark:bg-dark-1"
                    >
                      <td class="border-b dark:border-dark-5">
                        <a
                          :href="
                            legacyAdminPanelUrl +
                              '/payments/schedules/' +
                              loan.id
                          "
                          target="_blank"
                          ><u>{{ loan.id }}</u></a
                        >
                      </td>

                      <td class="border-b dark:border-dark-5">
                        {{ loan.status }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{
                          loan.declined_reason
                            ? loan.declined_reason.transition_reason
                            : "-"
                        }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ loan.applied_date }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ loan.last_debit_transaction }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ loan.total_amount }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        <span v-if="loan.loan_reason_1 !== null">
                          {{ loan.loan_reason_1.value }}
                        </span>
                        <span v-if="loan.loan_sub_reason_1 !== null">
                          :
                          {{ loan.loan_sub_reason_1.value }}
                        </span>
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ loan.repayments }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ loan.repayment_amount }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{
                          loan.first_payment_date
                            ? loan.first_payment_date.split(" ")[0]
                            : ""
                        }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{
                          loan.last_payment_date
                            ? loan.last_payment_date.split(" ")[0]
                            : ""
                        }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ loan.ep_lrs }}
                      </td>
                    </tr>
                  </tbody>
                  <noRecordTblTemplate v-else />
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Striped Rows -->
      </div>
      <FetchingRecordDivTemplate v-else marginTop="0" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import noRecordTblTemplate from "@/components/no-record-tbl-template/Main.vue";
import FetchingRecordDivTemplate from "@/components/fetching-record-div-template/Main";
import { loanQuality } from "@/composables/loanQuality";

export default defineComponent({
  components: {
    noRecordTblTemplate,
    FetchingRecordDivTemplate
  },
  props: {
    pastLoansOfApplicantLinkedToAssessment: {
      type: Array,
      required: true
    },
    isActive: {
      type: Boolean,
      require: true,
      default: false
    },
    setNavActiveSection: {
      type: Function,
      require: true,
      default: null
    },
    getLoanHistoryOfApp: {
      type: Function,
      require: true,
      default: null
    },
    fetchingApplicantLoans: {
      type: Boolean,
      require: true,
      default: false
    }
  },
  setup(props) {
    props.getLoanHistoryOfApp();
    const legacyAdminPanelUrl = process.env.VUE_APP_LEGACY_ADMINPANEL_URL;

    const { getActiveApplicationQualityClass } = loanQuality();

    return {
      legacyAdminPanelUrl,
      getActiveApplicationQualityClass
    };
  }
});
</script>

<style scoped></style>
