import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";
import router from "@/router";
import { getLendingAssessmentAccessType } from "@/composables/utils/lendings/LendingAssessmentAccessTypeHelper";
import LoanAssessmentStatus from "@/constants/loan-assessment-status";

const state = () => {
  return {
    processingSubmit: false
  };
};

// getters
const getters = {
  processingSubmit: state => state.processingSubmit
};

// actions
const actions = {
  resolveAndReRouteLnAssessmentDtsPage: async (
    { dispatch, state, commit },
    applicationId
  ) => {
    commit("setProcessingSubmit");
    commit("main/setFullPageLoading", null, { root: true });
    axiosWrapperService
      .axiosGet("/initializeAssessmentOfLoan/" + applicationId)
      .then(response => {
        if (
          ["lending-converter", "lending-manager", "lending-agent"].includes(
            getLendingAssessmentAccessType(response.data.data)
          )
        ) {
          router.push({
            name: "loan-assessment-detail",
            params: { ln_assessment_uuid: response.data.data.uuid }
          });
        } else if (
          response.data.data.status ===
          LoanAssessmentStatus.PRE_CONVERSION_FINALIZED
        ) {
          router.push({
            name: "loan-application-details",
            params: {
              customerId: response.data.data.user_id,
              applicationId: applicationId
            }
          });
        } else {
          dispatch(
            "globalToastMessage/showSimpleToast",
            {
              message: "Something Went Wrong. Please try again",
              type: "error"
            },
            { root: true }
          );
        }
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("resetProcessingSubmit");
        commit("main/resetFullPageLoading", null, { root: true });
      });
  }
};

// mutations
const mutations = {
  setProcessingSubmit(state) {
    state.processingSubmit = true;
  },
  resetProcessingSubmit(state) {
    state.processingSubmit = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
