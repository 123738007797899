import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";

// actions
const actions = {
  getCustomer: async ({ commit, dispatch }, { customerId }) => {
    axiosWrapperService
      .axiosGet(
        "/customers/" + customerId + "/details?customer_id=" + customerId
      )
      .then(response => {
        commit("setCustomer", response.data.data);
        commit("setCustomerDeleted", response.data.data?.is_anonymous);
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      });
  },
  getLoanNotes: async (
    { commit, dispatch },
    { customerId, loanApplicationId }
  ) => {
    commit("setFetchingLoanNotes");
    axiosWrapperService
      .axiosGet(
        "/customers/" +
          customerId +
          "/loan-notes?loan_application_id=" +
          loanApplicationId
      )
      .then(response => {
        commit("setLoanNotes", response.data.data);
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("resetFetchingLoanNotes");
      });
  },
  anonymize: async ({ commit, dispatch }, customerId) => {
    commit("main/setFullPageLoading", null, { root: true });
    commit("setCustomerErrorMessage", "");
    return axiosWrapperService
      .axiosPost(
        `/gateway-proxies/fundo-core/customers/anonymize/${customerId}`
      )
      .then(function(response) {
        commit("setCustomerDeleted", true);
        return true;
      })
      .catch(function() {
        commit(
          "setCustomerErrorMessage",
          "Unable to anonymize the customer record"
        );
      })
      .finally(() => {
        commit("main/resetFullPageLoading", null, { root: true });
      });
  }
};

// mutations
const mutations = {
  //Customer
  setCustomer(state, customer) {
    state.customer = customer;
  },
  //Notes
  setLoanNotes(state, notes) {
    state.loanNotes = notes;
  },
  setFetchingLoanNotes(state) {
    state.fetchingLoanNotes = true;
  },
  resetFetchingLoanNotes(state) {
    state.fetchingLoanNotes = false;
  },
  setCustomerDeleted(state, value) {
    state.customerDeleted = value;
  },
  setCustomerErrorMessage(state, message) {
    state.customerErrorMessage = message;
  }
};

const state = () => {
  return {
    customer: null,
    loanNotes: [],
    fetchingLoanNotes: false,
    customerDeleted: false,
    customerErrorMessage: ""
  };
};

// getters
const getters = {
  customer: state => state.customer,
  loanNotes: state => state.loanNotes,
  fetchingLoanNotes: state => state.fetchingLoanNotes,
  customerDeleted: state => state.customerDeleted
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
