<template>
  <div>
    <div id="metas-dts-section" class="grid grid-cols-2 gap-6 mt-6">
      <div class="col-span-12">
        <!--        <UpdateLoader :show="true" />-->
        <!-- BEGIN: Striped Rows -->
        <div class="box">
          <table
            v-if="data.length || newMetas.length"
            class="table table-report -mt-2"
          >
            <thead>
              <tr>
                <th
                  class="
                            border-b-2 dark:border-dark-5 whitespace-nowrap center
                          "
                >
                  Name
                </th>
                <th
                  class="
                            border-b-2 dark:border-dark-5 whitespace-nowrap
                          "
                >
                  Description
                </th>
              </tr>
            </thead>
            <tbody v-if="data.length > 0" :key="counter" class="p-2">
              <tr
                v-for="(meta, metaKey) in data"
                :key="metaKey"
                class="dark:bg-dark-1"
              >
                <td class="border-b dark:border-dark-5 center">
                  {{ meta.name }}
                </td>
                <td class="border-b dark:border-dark-5 padd-left">
                  {{ meta.description }}
                </td>
                <td>
                  <XCircleIcon
                    class=" w-4 h-4"
                    @click="_ => removeExistingMeta(metaKey)"
                  />
                </td>
              </tr>
            </tbody>

            <tbody>
              <tr v-for="(newMeta, index) in newMetas" :key="index">
                <td class="border-b dark:border-dark-5">
                  <input
                    v-model="newMeta.name"
                    class="form-control mt-0 px-4 pl-4 loan-input"
                    placeholder="Name"
                    type="text"
                  />
                  <div
                    v-if="hasErrors && (!newMeta.name || newMeta.name === '')"
                    class="text-red-500  validation-text"
                  >
                    *required
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <textarea
                    v-model="newMeta.description"
                    class="form-control mt-0 loan-input"
                    placeholder="Description"
                    type="text"
                  />
                  <div
                    v-if="
                      hasErrors &&
                        (!newMeta.description || newMeta.description === '')
                    "
                    class="text-red-500  validation-text"
                  >
                    *required
                  </div>
                </td>
                <td>
                  <XCircleIcon class=" w-4 h-4" @click="removeMetaRow(index)" />
                </td>
              </tr>
            </tbody>
          </table>
          <!--  Handling Errors on adding new metas-->
          <div
            v-if="newMetasErrorMessage !== ''"
            class="alert alert-danger-soft show  mb-2 p-2 pt-4 mt-1"
            role="alert"
          >
            <div class="flex justify-between">
              <div class="w-4/5">
                <AlertOctagonIcon class="w-6 h-6 mr-2" />
                {{ newMetasErrorMessage }}
              </div>
              <div class="1/5">
                <button
                  v-if="newMetasErrorMessage !== ''"
                  class="btn btn-rounded bg-theme-6 w-1/2 text-white sm:w-auto mr-2 "
                  type="button"
                  @click="clearErrors"
                >
                  <AlertCircleIcon class="w-6 h-6 mr-2" />
                  Clear Errors
                </button>
              </div>
            </div>
          </div>

          <div
            v-if="
              validationErrors.length > 0 ||
                Object.keys(validationErrors).length > 0
            "
            class="alert alert-danger-soft show   mb-1"
            role="alert"
          >
            <div class="flex justify-between">
              <div class="w-4/5">
                <div v-if="validationErrors.length > 0">
                  <div v-for="(error, index) in validationErrors" :key="index">
                    {{ error }}
                  </div>
                </div>
                <div v-if="Object.keys(validationErrors).length > 0">
                  <div v-for="key in Object.keys(validationErrors)" :key="key">
                    {{ validationErrors[key][0] }}
                  </div>
                </div>
              </div>
              <div class="1/5">
                <button
                  class="btn btn-rounded bg-theme-6 w-1/2 text-white sm:w-auto mr-2 "
                  type="button"
                  @click="clearErrors"
                >
                  <AlertCircleIcon class="w-6 h-6 mr-2" />
                  Clear Errors
                </button>
              </div>
            </div>
          </div>

          <div class="flex flex-col sm:flex-row justify-end items-center p-2">
            <button
              v-if="newMetas.length > 0"
              class="btn btn-sm btn-rounded bg-primary-1 w-1/2 text-white sm:w-auto mr-2"
              type="button"
              @click="saveAllNewMetas"
            >
              <SaveIcon class="w-4 h-4 mr-1" />
              Sync
            </button>

            <button
              class="btn btn-sm btn-rounded bg-theme-3 w-1/2 text-white sm:w-auto mr-2"
              type="button"
              @click="addNewMetaRow"
            >
              <PlusCircleIcon class="w-4 h-4 mr-1" />
              Add
            </button>
          </div>
        </div>
        <!-- END: Striped Rows -->
      </div>
      <!--      <FetchingRecordDivTemplate marginTop="0" />-->
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  emits: ["update"],
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const counter = ref(0);
    const newMetas = ref([]);
    const store = useStore();

    let newMetasErrorMessage = ref("");

    init();

    function init() {
      clearErrors();
    }

    const validationErrors = computed(() => store.state.cms.metaErrors);

    const hasErrors = computed(() => {
      return (
        validationErrors.value.length > 0 || newMetasErrorMessage.value !== ""
      );
    });

    function clearErrors() {
      newMetasErrorMessage.value = "";
      store.dispatch("cms/clearMetaErrors");
    }
    //Methods
    function addNewMetaRow() {
      newMetas.value.push({
        name: "",
        description: ""
      });
      counter.value++;
    }
    function removeMetaRow(index) {
      newMetas.value.splice(index, 1);
    }
    function removeExistingMeta(index) {
      emit("remove", index);
    }
    function clearNewMetas() {
      newMetas.value = [];
    }
    function saveAllNewMetas() {
      if (!newMetas.value.length) {
        newMetasErrorMessage.value = "No meta added ";
      }
      if (!validateNewMetas()) {
        newMetasErrorMessage.value =
          "Please check the new metas details and try again ";
      } else {
        emit("update", newMetas.value);
        clearNewMetas();
      }
    }

    function validateNewMetas() {
      let validated = true;
      newMetas.value.forEach(meta => {
        if (meta.name === "" || meta.description === "") validated = false;
      });
      return validated;
    }

    return {
      counter,
      init,
      validationErrors,
      clearErrors,
      newMetasErrorMessage,
      hasErrors,
      newMetas,
      addNewMetaRow,
      removeMetaRow,
      removeExistingMeta,
      clearNewMetas,
      saveAllNewMetas
    };
  }
});
</script>
<style scoped>
.center {
  text-align: center;
}
.padd-left {
  padding-left: 1.25rem !important;
}
table td {
  padding: 2px !important;
}
table tr {
  padding: 2px !important;
}
.loan-input {
  width: 95%;
}
.validation-text {
  font-size: x-small;
}
</style>
