<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Loan Closure Review Dashboard</h2>
    </div>

    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12">
        <!-- BEGIN: Table Head Options -->
        <div class="intro-y box mt-5">
          <div
            class="
              flex flex-col
              sm:flex-row
              items-center
              p-5
              border-b border-gray-200
            "
          >
            <h2 class="font-medium text-base mr-auto">
              Closure Review Bucket ({{ adminUser.fullname }})
            </h2>
            <div
              class="sm:flex items-center sm:mr-2"
              v-if="!settingGetCustomerConfigs"
            >
              <label class="w-full flex-none xl:flex-initial mr-2"
                >Select Order Type</label
              >
              <select
                v-model="assessmentFetchSelectionType"
                class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
                @change="onChangeAssessmentFetchOrder"
              >
                <option value="priority" selected>Priority List</option>
                <option value="ascending">Oldest to Newest</option>
              </select>
            </div>
            <div class="sm:w-auto flex items-center mt-3 sm:mt-0">
              <button
                v-if="
                  adminUser.permissions.includes(
                    'lendings.update.agent_id.self'
                  ) && !settingGetCustomerConfigs
                "
                id="tabulator-print"
                class="btn hover:bg-green-700 bg-green-500 w-1/2 sm:w-auto mr-2"
                @click="onFetchAssessmentReqSubmit"
              >
                <span class="text-white"
                  ><PlayCircleIcon class="w-6 h-6 mr-2" /> Get Customer</span
                >
              </button>
              <button v-else class="btn btn-success mr-1 mb-2">
                Processing
                <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
          <div id="head-options-table" class="p-5">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
                  id="tabulator"
                  ref="tableRef"
                  class="mt-5 table-report table-report--tabulator"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Table Head Options -->
        <FundoLmsToastAlert />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { loanClosureReviewDashboard } from "@/composables/loanClosureReviewDashboard";
import FundoLmsToastAlert from "@/components/fundo-lms-toast-alert/Main.vue";

export default defineComponent({
  components: {
    FundoLmsToastAlert
  },
  setup() {
    const {
      adminUser,
      tableRef,
      fromDate,
      toDate,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      onExport,
      breadcrumb,
      path,
      assessmentBucketList,
      activityStats,
      recentlyAssessedAssessments,
      getAccessBucketsAction,
      approvalRejectionDataObject,
      onFetchAssessmentReqSubmit,
      onApprovalSubmit,
      onRejectionSubmit,
      init,
      assessmentFetchSelectionType,
      settingGetCustomerConfigs,
      onChangeAssessmentFetchOrder
    } = loanClosureReviewDashboard();

    return {
      adminUser,
      tableRef,
      fromDate,
      toDate,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      onExport,
      breadcrumb,
      path,
      assessmentBucketList,
      activityStats,
      recentlyAssessedAssessments,
      getAccessBucketsAction,
      approvalRejectionDataObject,
      onFetchAssessmentReqSubmit,
      onApprovalSubmit,
      onRejectionSubmit,
      init,
      assessmentFetchSelectionType,
      settingGetCustomerConfigs,
      onChangeAssessmentFetchOrder
    };
  }
});
</script>
