const state = () => {
  return {
    preCheckFailureLogs: []
  };
};

// getters
const getters = {
  preCheckFailureLogs: state => state.preCheckFailureLogs
};

// actions
const actions = {};

// mutations
const mutations = {
  setLogs(state, preCheckFailureLogs) {
    state.preCheckFailureLogs = preCheckFailureLogs;
  },
  clearLogs(state) {
    state.preCheckFailureLogs = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
