<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ "Unsigned Loans" }}</h2>
      <button
        id="tabulator-print"
        class="btn btn-outline-secondary bg-green-500 w-1/2 sm:w-auto mr-2"
        @click="onExport"
      >
        <FileTextIcon class="w-4 h-4 mr-2" /> Export
      </button>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div
          id="tabulator-html-filter-form"
          class="xl:flex sm:mr-auto w-full"
          style="justify-content: space-between;"
        >
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-3"
              >Search</label
            >
            <input
              id="tabulator-html-filter-value"
              v-model="searchTerm"
              type="text"
              class="form-control sm:w-36 xxl:w-full mt-2 sm:mt-0"
              placeholder="Search Application id, customer name, email, mobile, status"
            />
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn w-full sm:w-16 sm:ml-1 bg-yellow-400"
              @click="onSearch"
            >
              Go
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
              @click="onResetSearch"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator-loan-applications"
          ref="tableRef"
          class="mt-2 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { unsignedLoans } from "@/composables/unsignedLoans";

export default defineComponent({
  setup(props) {
    const {
      tableRef,
      searchTerm,
      onSearch,
      onResetSearch,
      onExport
    } = unsignedLoans(props);

    return {
      tableRef,
      searchTerm,
      onSearch,
      onResetSearch,
      onExport
    };
  }
});
</script>
<style scoped></style>
