export const styleHelper = {
  getCustomerStatusBadgeClass(status) {
    let badgeClass = "";
    switch (status.toLowerCase()) {
      case "new":
        badgeClass = "fnd-badge-success";
        break;
      case "active":
        badgeClass = "fnd-badge-primary";
        break;
      default:
        break;
    }
    return `whitespace-nowrap fnd-badge ${badgeClass}`;
  },
  getLoanStatusBadgeClass(status) {
    let badgeClass = "";
    switch (status.toLowerCase()) {
      case "active_account":
        badgeClass = "fnd-badge-primary";
        break;
      case "applied":
      case "approved":
      case "active":
        badgeClass = "fnd-badge-primary";
        break;
      case "closed":
        badgeClass = "fnd-badge-secondary";
        break;
      case "new":
        badgeClass = "fnd-badge-info";
        break;
      case "pending":
        badgeClass = "fnd-badge-warning";
        break;
      case "trashed":
        badgeClass = "fnd-badge-danger";
        break;
      default:
        break;
    }
    return `whitespace-nowrap fnd-badge ${badgeClass}`;
  },
  getPaymentScheduleStatusBadgeClass(status) {
    let badgeClass = "";
    switch (status.toLowerCase()) {
      case "active_account":
        badgeClass = "fnd-badge-primary";
        break;
      case "applied":
      case "approved":
        badgeClass = "fnd-badge-primary";
        break;
      case "closed":
        badgeClass = "fnd-badge-secondary";
        break;
      case "new":
        badgeClass = "fnd-badge-warning";
        break;
      default:
        break;
    }
    return `whitespace-nowrap fnd-badge ${badgeClass}`;
  }
};
