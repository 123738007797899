import { formatErrorMessage } from "../utils/messageFormatter";
import { axiosWrapperService } from "../services/auth/axios-wrapper-service";
import { genTabulatorJsFormatQueryStringFromParamsObject } from "../utils/QueryStringGenerator/tabulatorJsFormatQueryStringGenerator";

const state = () => {
  return {
    refundRequestList: [],
    refundRequestQueryParam: "",
    excelReportBlob: null,
    busyRefundRequestListPage: false,
    refundRequestListUpdateCounter: 0,
    refundRequestAssessorNotes: {},
    refundRequestRequestorNotes: {},
    refundRequestAlertMessage: "",
    refundRequestErrorMessage: "",
    refundRequestSuccessMessage: ""
  };
};

// getters
const getters = {
  refundRequestList: state => state.refundRequestList,
  refundRequestQueryParam: state => state.refundRequestQueryParam,
  excelReportBlob: state => state.excelReportBlob,
  busyRefundRequestListPage: state => state.busyRefundRequestListPage,
  refundRequestListUpdateCounter: state => state.refundRequestListUpdateCounter,
  refundRequestAssessorNotes: state => state.refundRequestAssessorNotes,
  refundRequestRequestorNotes: state => state.refundRequestRequestorNotes,
  refundRequestAlertMessage: state => state.refundRequestAlertMessage,
  refundRequestErrorMessage: state => state.refundRequestErrorMessage,
  refundRequestSuccessMessage: state => state.refundRequestSuccessMessage
};

// actions
const actions = {
  getRefundRequestList: async ({ commit }) => {
    try {
      commit("clearError");
      axiosWrapperService.axiosGet("/loanRefundRequests").then(response => {
        commit("setRefundRequestList", response.data.data);
      });
    } catch (e) {
      commit("setError", "Failed to get the list.");
    } finally {
      commit("clearBusy");
    }
  },
  resetRefundRequestStatus: async ({ commit }, refundRequestUuid) => {
    axiosWrapperService
      .axiosPatch("/resetRefundRequestStatus/" + refundRequestUuid)
      .then(() => {
        //dispatch("getRefundRequestList");
        commit("incrementUpdateCount");
        commit(
          "setRefundSuccessMessage",
          "Successfully renewed refund request record."
        );
      })
      .catch(error => {
        commit("setError", formatErrorMessage(error, true));
      });
  },
  sendForRejection: async ({ commit }, rejectionRequest) => {
    let patchData = {};
    patchData.refund_req_uuid = rejectionRequest.uuid;
    if (rejectionRequest.note !== "") {
      patchData.assessor_note = rejectionRequest.note;
    }
    axiosWrapperService
      .axiosPatch("/rejectNewRefundRequest", patchData)
      .then(() => {
        //dispatch("getDisbursalPayoutList");
        commit("incrementUpdateCount");
        commit(
          "setRefundSuccessMessage",
          "Successfully Rejected refund request record."
        );
      })
      .catch(error => {
        commit("setError", formatErrorMessage(error, true));
      });
  },
  sendForRefund: async ({ commit }, refundRequest) => {
    let postData = {};
    postData.refund_req_uuid = refundRequest.uuid;
    if (refundRequest.note !== "") {
      postData.assessor_note = refundRequest.note;
    }
    axiosWrapperService
      .axiosPost("/refundViaSplit", postData)
      .then(response => {
        //dispatch("getRefundRequestList");
        if (response.status === 200) {
          commit(
            "setRefundAlertMessage",
            "The RefundRequest for this loan Account is already staged."
          );
        } else if (response.status === 201) {
          commit("setRefundSuccessMessage", "Successfully Approved.");
        }
        commit("incrementUpdateCount");
      })
      .catch(error => {
        commit("incrementUpdateCount");
        commit("setError", formatErrorMessage(error, true));
      });
  },
  requestForExportingList: async ({ commit, state }) => {
    try {
      let params = {};
      params.responseType = "blob";
      axiosWrapperService
        .axiosGet(
          "/exportRefundRequest?" + state.refundRequestQueryParam,
          params
        )
        .then(response => {
          commit("setExcelReportBlob", response.data);
          commit(
            "setRefundSuccessMessage",
            "Successfully bundled Export. Please check Downloading section for progress."
          );
        });
    } catch (e) {
      commit("setError", "Failed to Export");
    } finally {
      commit("clearBusy");
    }
  }
};

// mutations
const mutations = {
  setRefundRequestQueryParam(state, { params, statusParam }) {
    let queryString = statusParam !== "" ? statusParam + "&" : "";
    queryString += encodeURI(
      genTabulatorJsFormatQueryStringFromParamsObject(params)
    );
    state.refundRequestQueryParam = queryString;
  },
  setRefundRequestList(state, list) {
    state.refundRequestList = list;
  },
  setExcelReportBlob(state, blob) {
    state.excelReportBlob = blob;
  },
  setRefundRequestAssessorNotes(state, refundRequestAssessorNotes) {
    state.refundRequestAssessorNotes = refundRequestAssessorNotes;
  },
  setRefundRequestRequestorNotes(state, refundRequestRequestorNotes) {
    state.refundRequestRequestorNotes = refundRequestRequestorNotes;
  },
  setRefundSuccessMessage(state, refundRequestSuccessMessage) {
    state.refundRequestSuccessMessage = refundRequestSuccessMessage;
  },
  setRefundAlertMessage(state, refundRequestAlertMessage) {
    state.refundRequestAlertMessage = refundRequestAlertMessage;
  },
  setError(state, refundRequestErrorMessage) {
    state.refundRequestErrorMessage = refundRequestErrorMessage;
  },
  incrementUpdateCount(state) {
    state.refundRequestListUpdateCounter++;
    state.fullPageLoading = false;
  },
  clearError(state) {
    state.refundRequestErrorMessage = "";
  },
  clearMessages(state) {
    state.refundRequestErrorMessage = "";
    state.refundRequestAlertMessage = "";
    state.refundRequestSuccessMessage = "";
    state.refundRequestListUpdateCounter++;
  },
  clearAlertMessages(state) {
    state.refundRequestAlertMessage = "";
  },
  setBusy(state) {
    state.busyRefundRequestListPage = true;
  },
  clearBusy(state) {
    state.busyRefundRequestListPage = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
