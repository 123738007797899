import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";

const state = () => {
  return {
    fetchingLnErrorTags: false,
    fetchingLnNonErrorTags: true,
    lendingErrorTags: [],
    lendingNonErrorTags: [],
    lendingErrorTagsAttachedToLoan: [],
    lendingErrorNonTagsAttachedToLoan: [],
    initialSystemTags: []
  };
};

// getters
const getters = {
  lendingErrorTags: state => state.lendingErrorTags,
  lendingNonErrorTags: state => state.lendingNonErrorTags,
  lendingErrorTagsAttachedToLoan: state => state.lendingErrorTagsAttachedToLoan,
  lendingErrorNonTagsAttachedToLoan: state =>
    state.lendingErrorNonTagsAttachedToLoan
};

// actions
const actions = {
  getLendingErrorTags: async ({ dispatch, commit }) => {
    try {
      commit("setFetchingLnErrorTags");
      axiosWrapperService
        .axiosGet("/getLendingErrorTags")
        .then(response => {
          commit("setLendingErrorTags", response.data.data);
          commit("setFetchingLnErrorTags");
        })
        .catch(error => {
          dispatch(
            "globalToastMessage/showSimpleToast",
            {
              message: formatErrorMessage(error),
              type: "error"
            },
            { root: true }
          );
          commit("setFetchingLnErrorTags");
        });
    } catch (e) {
      dispatch(
        "globalToastMessage/showSimpleToast",
        {
          message: formatErrorMessage(e),
          type: "error"
        },
        { root: true }
      );
      commit("setFetchingLnErrorTags");
    }
  },
  getLendingNonErrorTags: async ({ dispatch, commit }) => {
    commit("setFetchingLnNonErrorTags");
    axiosWrapperService
      .axiosGet("/getLendingNonErrorTags")
      .then(response => {
        commit("setLendingNonErrorTags", response.data.data);
        commit("resetFetchingLnNonErrorTags");
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: formatErrorMessage(error),
            type: "error"
          },
          { root: true }
        );
        commit("resetFetchingLnNonErrorTags");
      });
  },
  getLendingErrorTagsAttachedToLoan: async (
    { dispatch, state, commit },
    { applicationId, messageOnSuccess }
  ) => {
    commit("setFetchingLnErrorTags");
    axiosWrapperService
      .axiosGet("/getAttachedLendingErrorTagsOfLoan/" + applicationId)
      .then(response => {
        commit(
          "setLendingErrorTagsAttachedToLoan",
          response.data.data.attached_tags
        );
        commit("setLendingErrorTags", response.data.data.available_tags);
        if (messageOnSuccess !== "") {
          dispatch(
            "globalToastMessage/showSimpleToast",
            {
              message: messageOnSuccess,
              type: "success"
            },
            { root: true }
          );
        }
        commit("resetFetchingLnErrorTags");
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: formatErrorMessage(error),
            type: "error"
          },
          { root: true }
        );
      });
  },
  submitSelectedLendingErrorTags: async (
    { dispatch, state, commit },
    { applicationId, postData, resetTags }
  ) => {
    axiosWrapperService
      .axiosPost("/editErrorTagsOnLoan/" + applicationId, postData)
      .then(response => {
        dispatch("getLendingErrorTagsAttachedToLoan", {
          applicationId: applicationId,
          messageOnSuccess: "Successfully updated Tags !"
        });
      })
      .catch(error => {
        if (error?.response?.status === 403 && resetTags instanceof Function)
          resetTags();

        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: formatErrorMessage(error),
            type: "error"
          },
          { root: true }
        );
      });
  },
  getLendingNonErrorTagsAttachedToLoan: async (
    { dispatch, state, commit },
    { applicationId, messageOnSuccess }
  ) => {
    commit("setFetchingLnNonErrorTags");
    axiosWrapperService
      .axiosGet("/getAttachedLendingNonErrorTagsOfLoan/" + applicationId)
      .then(response => {
        commit(
          "setLendingErrorNonTagsAttachedToLoan",
          response.data.data.attached_tags
        );
        commit("setLendingNonErrorTags", response.data.data.available_tags);
        if (messageOnSuccess !== "") {
          dispatch(
            "globalToastMessage/showSimpleToast",
            {
              message: messageOnSuccess,
              type: "success"
            },
            { root: true }
          );
        }
        commit("resetFetchingLnNonErrorTags");
        dispatch("customerAndLoanDetails/getCustomerMetaFlagsForLoan", null, {
          root: true
        });
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: formatErrorMessage(error),
            type: "error"
          },
          { root: true }
        );
        commit("resetFetchingLnNonErrorTags");
      });
  },
  submitSelectedLendingNonErrorTags: async (
    { dispatch, state, commit },
    { applicationId, postData, resetTags }
  ) => {
    axiosWrapperService
      .axiosPost("/editNonErrorTagsOnLoan/" + applicationId, postData)
      .then(response => {
        dispatch(
          "customerAndLoanDetails/getKycAttributesForLoanAssessment",
          "",
          { root: true }
        );
        dispatch("getLendingNonErrorTagsAttachedToLoan", {
          applicationId: applicationId,
          messageOnSuccess: "Successfully updated Tags !"
        });
      })
      .catch(error => {
        //If user tries to remove tag
        if (error?.response?.status === 403 && resetTags instanceof Function)
          resetTags();

        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: formatErrorMessage(error),
            type: "error"
          },
          { root: true }
        );
      });
  }
};

// mutations
const mutations = {
  setBusy(state) {
    state.busy = true;
  },
  resetBusy(state) {
    state.busy = false;
  },
  setFetchingLnErrorTags(state) {
    state.fetchingLnErrorTags = true;
  },
  resetFetchingLnErrorTags(state) {
    state.fetchingLnErrorTags = false;
  },
  setFetchingLnNonErrorTags(state) {
    state.fetchingLnNonErrorTags = true;
  },
  resetFetchingLnNonErrorTags(state) {
    state.fetchingLnNonErrorTags = false;
  },
  setLendingErrorTags(state, lendingErrorTags) {
    state.lendingErrorTags = lendingErrorTags;
  },
  setLendingNonErrorTags(state, lendingNonErrorTags) {
    state.lendingNonErrorTags = lendingNonErrorTags;
  },
  setLendingErrorTagsAttachedToLoan(state, lendingErrorTagsAttachedToLoan) {
    state.lendingErrorTagsAttachedToLoan = lendingErrorTagsAttachedToLoan;
  },
  setLendingErrorNonTagsAttachedToLoan(
    state,
    lendingErrorNonTagsAttachedToLoan
  ) {
    state.lendingErrorNonTagsAttachedToLoan = lendingErrorNonTagsAttachedToLoan;
  },
  setInitialSystemTags(state, tags) {
    state.initialSystemTags = tags;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
