<template>
  <div v-if="list" class="mb-3">
    <div class="border-b font-bold">{{list.name}}<span class="text-green-500 ml-2"> ( {{ checklistPercent }}% ) </span></div>
    <div v-if="!enabled" class="text-red-400 text-xs">Previous checklist is not yet complete</div>
    <ul v-if="enabled" class="mt-1">
      <li v-for="(item,index) in list.items" :key="index"
          @click="updateChecklistItemStatus(index)"
          class="cursor-pointer m-0 mb-1 p-0 "
          :class="{'text-green-600':item.status , 'text-blue-400':!item.status }">
        {{ item.name }} <span v-if="item.status"> <CheckIcon class="ml-2 p-0 m-0 h-4 text-xs"/></span>
      </li>
    </ul>
    <ul v-else class="mt-1">
      <li v-for="(item,index) in list.items" :key="index" class="text-gray-400">
        {{ item.name }}
      </li>
    </ul>
  </div>
  <div v-if="isMounted && list.length === 0" class="p-3 text text-white border bg-red-600">
    Seems something has gone wrong. Please contact the Dev team
  </div>
</template>
<script setup>

import { computed, defineProps, onMounted, ref, watch, defineEmits, nextTick } from "vue";

const props = defineProps({
  checklist: { type: Object, required: true },
  index: { type: Number, required: true },
  enable: { type: Number, required: true }
})

const emit = defineEmits(['save'])

const list = ref(null);

function updateChecklistItemStatus(index) {
  list.value.items[index].status = !list.value.items[index].status
}
//Checklist %
const checklistPercent = computed(() => {
  let count = list.value.items.length;
  let doneCount = 0;
  list.value.items.forEach((item) => {
    if (item.status) {
      doneCount++;
    }
  });
  if (doneCount > 0) {
    return Math.round((doneCount / count) * 100).toFixed(0);
  }
  return 0;
});

const enabled = computed(() => {
  return props.enable >= props.index;
});

//save on list changing
watch(
  () => list,
  () => {
    if (isMounted.value) {
      emit('save')
    }
  },
  { deep: true }
)

//Handle mounting
let isMounted = ref(false)

onMounted(() => {
  nextTick(() => {
    isMounted.value = true
  })
  list.value = props.checklist
})

</script>
<style scoped>
</style>
