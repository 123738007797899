<template>
  <div class="intro-x relative mr-3 sm:mr-6">
    <div class="search sm:block">
      <input
        v-model="searchText"
        type="text"
        class="search__input form-control border-transparent placeholder-theme-13"
        placeholder="Search customers, loan applications"
        @keyup.enter="submitSearch"
      />
      <SearchIcon class="search__icon dark:text-gray-300" />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const searchText = ref("");
    const router = useRouter();
    const submitSearch = () => {
      router.push({
        name: "side-menu-search",
        query: { s: searchText.value }
      });
    };

    return {
      submitSearch,
      searchText
    };
  }
});
</script>
