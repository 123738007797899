import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";

const state = () => {
  return {
    processingSubmit: false
  };
};

// getters
const getters = {
  processingSubmit: state => state.processingSubmit
};

// actions
const actions = {
  addGenericFollowUpOnLoan: async (
    { dispatch, state, commit },
    { applicationId, formData }
  ) => {
    commit("setProcessingSubmit");
    axiosWrapperService
      .axiosPost("/addGenericFollowUpOnLoan/" + applicationId, formData)
      .then(() => {
        // TODO : temporarily disabled
        // commit("timelineNotesViewer/incrementUpdateCounter", null, {
        //   root: true
        // });
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: "Successfully added Follow Up on loan.", type: "success" },
          { root: true }
        );
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("resetProcessingSubmit");
      });
  }
};

// mutations
const mutations = {
  setProcessingSubmit(state) {
    state.processingSubmit = true;
  },
  resetProcessingSubmit(state) {
    state.processingSubmit = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
