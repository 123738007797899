import { googleSdkLoaded } from "vue3-google-login";
import axios from "axios";
import { useStore } from "@/store";

const credentials = {
  id: process.env.VUE_APP_GOOGLE_SSO_CLIENT_ID,
  secret: process.env.VUE_APP_GOOGLE_SSO_CLIENT_SECRET,
  redirect_url: process.env.VUE_APP_GOOGLE_SSO_REDIRECT_URL
};
let store;
(function() {
  store = useStore();
})();

export function loginUsingSSO() {
  return googleSdkLoaded(google => {
    return google.accounts.oauth2
      .initCodeClient({
        client_id: credentials.id,
        scope: "email profile",
        ux_mode: "popup",
        callback: async response => {
          if (response.code) {
            try {
              const res = await axios.post(
                "https://oauth2.googleapis.com/token",
                {
                  code: response.code,
                  client_id: credentials.id,
                  client_secret: credentials.secret,
                  redirect_uri: credentials.redirect_url,
                  grant_type: "authorization_code"
                }
              );

              if (res.data && res.status === 200) {
                store.dispatch("auth/ssoLoginUsingToken", {
                  token: res.data?.access_token
                });
              } else {
                store.commit(
                  "auth/setError",
                  res.statusText || "Something went wrong."
                );
              }
            } catch (e) {
              store.commit("auth/setError", "Something went wrong.");
            }
          } else {
            store.commit("auth/setError", "Something went wrong.");
          }
        },
        error_callback: error => {
          if (error.type === "popup_closed") {
            store.commit("auth/setError", "Popup closed before selection");
          } else {
            store.commit("auth/setError", "Something went wrong.");
          }
        }
      })
      .requestCode();
  });
}
