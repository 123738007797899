<template>
  <!-- BEGIN: No record template-->
  <tbody>
    <tr>
      <td colspan="20">
        <h1 class="text-center text-theme-13" style="font-size: large;">
          <strong> No Records to show!</strong>
        </h1>
      </td>
    </tr>
  </tbody>
  <!-- END: No record template-->
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  setup() {}
});
</script>
