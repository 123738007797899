import { onMounted, computed, watch, ref } from "vue";
import { useStore } from "@/store";

export const getTransactions = applicationId => {
  const store = useStore();

  const isBusy = computed(
    () => store.getters["transactions/isFetchingTransactions"]
  );

  function fetchTransactions() {
    store.dispatch("transactions/getTransactions", { applicationId });
  }

  function showRefundTransactionModal() {
    cash("#refund-transaction-form-modal").modal("show");
  }

  function hideRefundTransactionModal() {
    cash("#refund-transaction-form-modal").modal("hide");
  }

  const transactions = computed(
    () => store.getters["transactions/transactions"]
  );

  const refundRequestSuccessCount = computed(
    () => store.getters["transactions/refundRequestSuccessCount"]
  );

  const loanActionSuccessCount = computed(
    () => store.getters["paymentScheduleLoanActions/successCount"]
  );

  onMounted(() => {
    fetchTransactions();
  });

  watch(
    () => loanActionSuccessCount.value,
    () => {
      fetchTransactions();
    }
  );

  watch(
    () => refundRequestSuccessCount.value,
    () => {
      fetchTransactions();
      hideRefundTransactionModal();
    }
  );

  watch(
    () => transactions.value,
    transactions => {
      calculateTotals(transactions);
    }
  );

  const totalCredit = ref(0);
  const totalDebit = ref(0);
  const totalBalanceAmount = ref(0);

  function calculateTotals(transactions) {
    let _totalCredit = 0;
    let _totalDebit = 0;
    for (const transaction of transactions) {
      _totalCredit += parseFloat(transaction.credit_amount);
      _totalDebit += parseFloat(transaction.debit_amount);
    }

    totalCredit.value = _totalCredit;
    totalDebit.value = _totalDebit;
    totalBalanceAmount.value = _totalCredit - _totalDebit;
  }

  function getRefundStatusTitle(refundStatus) {
    switch (refundStatus) {
      case null:
      case "":
        return "Refund Now";
      case "pending":
        return "Refund Requested";
      case "approved":
        return "Refund Approved";
      case "completed":
        return "Refund Completed";
      case "failed":
        return "Refund Failed";
      case "rejected":
        return "Refund Rejected";
    }
  }

  function getRefundStatusClass(refundStatus) {
    switch (refundStatus) {
      case null:
      case "":
      case "pending":
      case "approved":
        return "btn btn-sm btn-primary";
      case "completed":
        return "btn btn-sm btn-success";
      case "failed":
      case "rejected":
        return "btn btn-sm btn-danger";
    }
  }

  function refundStatusClicked(transaction) {
    switch (transaction.refund_status) {
      case null:
      case "":
        showRefundTransactionModal();
        store.commit("transactions/setTransactionToRefundId", transaction.id);
        store.commit("transactions/setRefundAmount", transaction.debit_amount);
        store.commit("transactions/clearErrors");
        break;
      default:
        return;
    }
  }

  return {
    isBusy,
    transactions,
    getRefundStatusTitle,
    getRefundStatusClass,
    refundStatusClicked,
    totalCredit,
    totalDebit,
    totalBalanceAmount
  };
};
