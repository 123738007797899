//import { formatErrorMessage } from "../utils/messageFormatter";
import { axiosWrapperService } from "../services/auth/axios-wrapper-service";
import { genTabulatorJsFormatQueryStringFromParamsObject } from "../utils/QueryStringGenerator/tabulatorJsFormatQueryStringGenerator";
import { formatErrorMessage } from "@/utils/messageFormatter";
import { defaultOrder } from "@/utils/LendginEnums/lending-dash-list-order-type";

const state = () => {
  return {
    activityStats: [],
    recentlyAssessedAssessments: [],
    lendingDashListOrderType: {},
    assessmentBucketList: [],
    assessmentBucketQueryParam: "",
    busyAssessmentBucketListPage: false,
    settingGetCustomerConfigs: true,
    assessmentBucketListUpdateCounter: 0,
    reqForAssessmentDetailsNavigationCounter: 0,
    assessmentBucketAlertMessage: "",
    assessmentBucketErrorMessage: "",
    assessmentBucketSuccessMessage: ""
  };
};

// getters
const getters = {
  activityStats: state => state.activityStats,
  recentlyAssessedAssessments: state => state.recentlyAssessedAssessments,
  lendingDashListOrderType: state => state.lendingDashListOrderType,
  assessmentBucketList: state => state.assessmentBucketList,
  assessmentBucketQueryParam: state => state.assessmentBucketQueryParam,
  busyAssessmentBucketListPage: state => state.busyAssessmentBucketListPage,
  settingGetCustomerConfigs: state => state.settingGetCustomerConfigs,
  assessmentBucketListUpdateCounter: state =>
    state.assessmentBucketListUpdateCounter,
  reqForAssessmentDetailsNavigationCounter: state =>
    state.reqForAssessmentDetailsNavigationCounter,
  assessmentBucketAlertMessage: state => state.assessmentBucketAlertMessage,
  assessmentBucketErrorMessage: state => state.assessmentBucketErrorMessage,
  assessmentBucketSuccessMessage: state => state.assessmentBucketSuccessMessage
};

// actions
const actions = {
  getAdminPreferencesForDashList: async ({ dispatch, commit }) => {
    try {
      commit("clearError");
      commit("setSettingGetCustomerConfigs");
      axiosWrapperService
        .axiosGet("/getAdminPreferencesByKey/lending-dash-list-order-type")
        .then(response => {
          commit(
            "setLendingDashListOrderType",
            response.data.data.preferences["lending-dash-list-order-type"]
              ? response.data.data.preferences["lending-dash-list-order-type"]
              : defaultOrder
          );
        })
        .catch(error => {
          dispatch(
            "globalToastMessage/showSimpleToast",
            { message: formatErrorMessage(error), type: "error" },
            { root: true }
          );
        })
        .finally(() => {
          commit("main/resetFullPageLoading", null, { root: true });
          commit("resetSettingGetCustomerConfigs");
        });
    } catch (e) {
      dispatch(
        "globalToastMessage/showSimpleToast",
        { message: formatErrorMessage(e), type: "error" },
        { root: true }
      );
    } finally {
      commit("clearBusy");
    }
  },
  setAdminPreferencesForDashList: async (
    { dispatch, commit },
    dashListOrder
  ) => {
    commit("clearError");
    commit("setSettingGetCustomerConfigs");
    axiosWrapperService
      .axiosPatch("/updateAdminPreferencesByKey/lending-dash-list-order-type", {
        preferences: dashListOrder
      })
      .then(response => {
        commit(
          "setLendingDashListOrderType",
          response.data.data.preferences["lending-dash-list-order-type"]
        );
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("main/resetFullPageLoading", null, { root: true });
        commit("resetSettingGetCustomerConfigs");
      });
  },
  fetchAssessment: async ({ dispatch, state, commit }, type) => {
    try {
      commit("clearError");
      axiosWrapperService
        .axiosGet(
          "/fetchClosureReviewRecordForReviewing?selection_type=" + type
        )
        .then(() => {
          //commit("setAssessmentBucketList", response.data.data);
          commit("incrementUpdateCount");
        })
        .catch(error => {
          dispatch(
            "globalToastMessage/showSimpleToast",
            { message: formatErrorMessage(error), type: "error" },
            { root: true }
          );
        })
        .finally(() => {
          commit("clearBusy");
        });
    } catch (e) {
      dispatch(
        "globalToastMessage/showSimpleToast",
        { message: formatErrorMessage(e), type: "error" },
        { root: true }
      );
    } finally {
      commit("clearBusy");
    }
  }
};

// mutations
const mutations = {
  setAssessmentBucketQueryParam(state, { params, statusParam }) {
    let queryString = statusParam !== "" ? statusParam + "&" : "";
    queryString += encodeURI(
      genTabulatorJsFormatQueryStringFromParamsObject(params)
    );
    state.assessmentBucketQueryParam = queryString;
  },
  setAssessmentBucketList(state, list) {
    state.assessmentBucketList = list;
  },
  setActivityStats(state, activityStats) {
    state.activityStats = activityStats;
  },
  setRecentlyAssessedAssessments(state, recentlyAssessedAssessments) {
    state.recentlyAssessedAssessments = recentlyAssessedAssessments;
  },
  setLendingDashListOrderType(state, lendingDashListOrderType) {
    state.lendingDashListOrderType = lendingDashListOrderType;
  },
  setAssessmentBucketLoansSuccessMessage(
    state,
    assessmentBucketSuccessMessage
  ) {
    state.assessmentBucketSuccessMessage = assessmentBucketSuccessMessage;
  },
  setAssessmentBucketLoansAlertMessage(state, assessmentBucketAlertMessage) {
    state.assessmentBucketAlertMessage = assessmentBucketAlertMessage;
  },
  setError(state, assessmentBucketErrorMessage) {
    state.assessmentBucketErrorMessage = assessmentBucketErrorMessage;
  },
  incrementUpdateCount(state) {
    state.assessmentBucketListUpdateCounter++;
    state.fullPageLoading = false;
  },
  incrementReqForAssessmentDetailsNavigationCount(state) {
    state.reqForAssessmentDetailsNavigationCounter++;
    state.fullPageLoading = false;
  },
  clearError(state) {
    state.assessmentBucketErrorMessage = "";
  },
  clearMessages(state, refreshBucket = true) {
    state.assessmentBucketErrorMessage = "";
    state.assessmentBucketAlertMessage = "";
    state.assessmentBucketSuccessMessage = "";
    if (refreshBucket) {
      state.assessmentBucketListUpdateCounter++;
    }
  },
  clearAlertMessages(state) {
    state.assessmentBucketAlertMessage = "";
  },
  setBusy(state) {
    state.busyAssessmentBucketListPage = true;
  },
  clearBusy(state) {
    state.busyAssessmentBucketListPage = false;
  },
  setSettingGetCustomerConfigs(state) {
    state.settingGetCustomerConfigs = true;
  },
  resetSettingGetCustomerConfigs(state) {
    state.settingGetCustomerConfigs = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
