import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";

const state = () => {
  return {
    admin: null,
    adminRoles: [],
    editAdminSuccessMessage: "",
    editAdminErrorMessage: ""
  };
};

// getters
const getters = {
  admin: state => state.admin,
  adminRoles: state => state.adminRoles,
  editAdminSuccessMessage: state => state.editAdminSuccessMessage,
  editAdminErrorMessage: state => state.editAdminErrorMessage
};

// actions
const actions = {
  getAdmin: async ({ commit }, { uuid }) => {
    axiosWrapperService
      .axiosGet(`/getAdmin/${uuid}`)
      .then(response => {
        commit("setAdmin", response.data.data);
      })
      .catch(error => {
        commit("setEditAdminErrorMessage", formatErrorMessage(error, true));
      });
  },
  updateAdmin: async ({ commit }, { uuid, fullname, email, roles }) => {
    axiosWrapperService
      .axiosPatch(`/updateAdmin/${uuid}`, {
        fullname,
        email,
        roles
      })
      .then(() => {
        commit("setEditAdminSuccessMessage", "Successfully updated Admin !");
      })
      .catch(error => {
        commit("setEditAdminErrorMessage", formatErrorMessage(error, true));
      });
  },
  getAdminRoles: async ({ commit }) => {
    axiosWrapperService
      .axiosGet("/getAdminRoles")
      .then(response => {
        commit("setAdminRoles", response.data.data);
      })
      .catch(() => {
        commit(
          "setEditAdminErrorMessage",
          formatErrorMessage(
            "Something went wrong while fetching list of Admin Roles.",
            true
          )
        );
      });
  }
};

// mutations
const mutations = {
  setAdmin(state, admin) {
    state.admin = admin;
  },
  setAdminRoles(state, adminRoles) {
    state.adminRoles = adminRoles;
  },
  setEditAdminSuccessMessage(state, editAdminSuccessMessage) {
    state.editAdminSuccessMessage = editAdminSuccessMessage;
  },
  setEditAdminErrorMessage(state, editAdminErrorMessage) {
    state.editAdminErrorMessage = editAdminErrorMessage;
  },
  clearMessages(state) {
    state.editAdminErrorMessage = "";
    state.editAdminSuccessMessage = "";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
