const state = () => {
  return {
    menu: [
      {
        icon: "HomeIcon",
        pageName: "top-menu-dashboard-overview-1",
        title: "Dashboard"
      },
      {
        icon: "BoxIcon",
        pageName: "top-menu-menu-layout",
        title: "Menu Layout",
        subMenu: [
          {
            icon: "",
            pageName: "side-menu-dashboard-overview-1",
            title: "Side Menu",
            ignore: true
          },
          {
            icon: "",
            pageName: "simple-menu-dashboard-overview-1",
            title: "Simple Menu",
            ignore: true
          },
          {
            icon: "",
            pageName: "top-menu-dashboard-overview-1",
            title: "Top Menu",
            ignore: true
          }
        ]
      },
      {
        icon: "InboxIcon",
        pageName: "payouts",
        title: "Payouts",
        subMenu: [
          {
            icon: "menu",
            pageName: "top-menu-payouts",
            title: "All Conversions"
          },
          {
            icon: "download",
            pageName: "top-menu-disbursed-payouts",
            title: "Fund Disbursed"
          },
          {
            icon: "pause",
            pageName: "top-menu-pending-payouts",
            title: "Pending Clearance"
          },
          {
            icon: "pause",
            pageName: "top-menu-pre-check-failed",
            title: "Pre-check Failed"
          },
          {
            icon: "x-octagon",
            pageName: "top-menu-failed-payouts",
            title: "Disbursal Failed"
          }
        ]
      },
      {
        icon: "ShareIcon",
        pageName: "top-menu-refunds",
        title: "Refund Requests",
        subMenu: [
          {
            icon: "menu",
            pageName: "top-menu-new-refunds",
            title: "New Requests"
          },
          {
            icon: "menu",
            pageName: "top-menu-completed-refunds",
            title: "Completed Refunds"
          },
          {
            icon: "menu",
            pageName: "top-menu-rejected-refunds",
            title: "Rejected Refunds"
          },
          {
            icon: "download",
            pageName: "top-menu-refunds",
            title: "Refunds History"
          },
          {
            icon: "pause",
            pageName: "top-menu-failed-refunds",
            title: "Refund Failed"
          }
        ]
      },
      {
        icon: "ClipboardIcon",
        pageName: "top-menu-lending-dashboard",
        title: "Lending Dashboard",
        subMenu: [
          {
            icon: "ClipboardIcon",
            pageName: "top-menu-assessment-lending-dashboard",
            title: "Assessments Dashboard"
          },
          {
            icon: "ClipboardIcon",
            pageName: "top-menu-ln-app-delegation-board",
            title: "Lending Delegation Board"
          },
          {
            icon: "ClipboardIcon",
            pageName: "top-menu-approval-lending-dashboard",
            title: "Approval Dashboard"
          },
          {
            icon: "ClipboardIcon",
            pageName: "top-menu-conversion-lending-dashboard",
            title: "Conversion Dashboard"
          }
        ]
      },
      {
        icon: "PhoneCallIcon",
        pageName: "top-menu-loan-follow-up-dashboard",
        title: "Application Follow Ups"
      },
      {
        icon: "UsersIcon",
        pageName: "top-menu-admins",
        title: "Admins",
        subMenu: [
          {
            icon: "menu",
            pageName: "top-menu-add-admin",
            title: "Add Admin"
          },
          {
            icon: "star",
            pageName: "top-menu-admin-list",
            title: "Admin Lists"
          }
        ]
      },
      {
        icon: "EditIcon",
        pageName: "top-menu-bank-account-list",
        title: "Payer Bank Accounts"
      }
    ]
  };
};

// getters
const getters = {
  menu: state => state.menu
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
