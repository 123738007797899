<template>
  <div
    class="
          nav nav-tabs
          pb-2
          flex-col
          sm:flex-row
          justify-center
          lg:justify-start
        "
    role="tablist"
  >
    <a
      id="application-tab"
      aria-controls="dashboard"
      aria-selected="true"
      class=" grid grid-rows-3 pt-1 pb-1 sm:mr-8 active cursor-pointer"
      data-toggle="tab"
      role="tab"
      @click="changeTab('customer-details')"
    >
      <template v-if="fetchingKYC">
        <noFlagTemplate /> Customer Details</template
      >
      <template v-else
        ><div class="flex justify-start">
          <span
            :class="
              basic.citizen
                ? `success ${statusColors.success}`
                : `error ${statusColors.error}`
            "
            :data-tooltip="
              basic.citizen ? 'Australian citizen' : 'Not Australian citizen'
            "
            class="tooltip"
          >
            <svg
              height="16"
              preserveAspectRatio="xMidYMid meet"
              version="1.0"
              viewBox="0 0 364.000000 238.000000"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="currentColor"
                stroke="none"
                transform="translate(0.000000,238.000000) scale(0.100000,-0.100000)"
              >
                <path
                  d="M431 2196 c8 -8 470 -296 475 -296 2 0 4 68 4 150 l0 150 -242 0
c-133 0 -239 -2 -237 -4z"
                />
                <path
                  d="M980 1985 l0 -215 -355 0 -355 0 0 -70 0 -70 355 0 355 0 0 -215 0
-215 -35 0 -35 0 0 166 c0 91 -4 164 -8 162 -5 -1 -126 -76 -270 -166 -211
-132 -266 -163 -291 -160 -26 3 6 25 247 176 l276 172 -59 -1 c-60 0 -62 -1
-295 -148 -202 -127 -236 -152 -241 -177 -4 -16 -7 -253 -8 -526 l-1 -498
1580 0 1580 0 0 1000 0 1000 -817 0 -818 0 -277 -173 -277 -172 54 -3 c54 -3
54 -3 292 146 131 81 244 152 251 156 9 5 12 -8 12 -53 l0 -60 -149 -93 -150
-93 150 -3 149 -3 0 -39 0 -40 -360 0 -360 0 0 215 0 215 -70 0 -70 0 0 -215z
m1644 -272 c6 2 22 8 35 12 l24 7 -22 -31 -22 -31 25 -12 c35 -15 33 -28 -3
-28 -29 0 -30 -2 -25 -37 l6 -38 -25 27 -24 26 -25 -24 -24 -25 4 36 c4 34 3
35 -29 35 l-34 1 27 20 c27 20 27 21 12 51 -17 33 -20 33 31 12 11 -4 19 4 27
27 l12 34 10 -33 c5 -18 14 -31 20 -29z m-784 -118 l0 -34 -132 -3 -133 -3
133 -83 c94 -60 132 -89 132 -103 0 -10 -2 -19 -4 -19 -3 0 -77 45 -165 101
-156 98 -161 100 -213 97 l-53 -3 217 -135 218 -135 0 -37 c0 -37 -1 -38 -39
-38 -34 0 -80 25 -318 174 l-278 174 -3 -174 -2 -174 -40 0 -40 0 0 215 0 215
360 0 360 0 0 -35z m1214 -242 c6 2 22 8 35 12 l24 7 -21 -29 -21 -30 24 -13
c33 -18 31 -30 -4 -30 -29 0 -30 -1 -24 -37 l6 -38 -23 28 -22 28 -24 -22 -24
-22 0 31 c0 29 -3 32 -32 32 l-33 1 29 23 c28 23 28 24 11 50 -19 29 -18 30
20 16 20 -8 27 -7 30 5 16 49 18 50 28 17 6 -18 15 -31 21 -29z m-799 -71 c1
-1 14 2 29 7 30 12 33 5 10 -27 -14 -21 -14 -23 10 -34 35 -15 33 -28 -4 -28
-30 0 -31 -1 -25 -37 l6 -38 -23 27 -24 28 -26 -27 -27 -27 6 37 c6 36 5 37
-28 37 l-34 1 28 22 c25 21 26 25 12 42 -19 26 -19 39 0 31 8 -3 21 -9 29 -12
9 -3 18 6 26 28 l12 33 10 -30 c6 -16 12 -31 13 -33z m562 -202 c26 0 31 -15
7 -24 -12 -4 -15 -15 -12 -36 5 -34 5 -34 -24 -19 -14 8 -23 8 -31 0 -18 -18
-29 -12 -23 13 4 15 0 28 -9 36 -21 17 -19 30 5 30 10 0 25 10 31 22 l12 23
10 -23 c6 -14 19 -22 34 -22z m-1722 -298 c1 -2 30 8 64 22 33 13 61 23 61 20
0 -2 -16 -28 -35 -59 l-35 -55 41 -25 c22 -15 47 -29 55 -32 30 -11 12 -23
-35 -23 -85 0 -88 -5 -65 -120 5 -22 -1 -19 -46 25 l-51 50 -45 -50 -45 -50 6
67 c7 76 8 75 -85 80 l-45 3 50 32 c28 18 54 34 60 36 8 3 -8 36 -50 101 l-18
27 54 -22 c30 -11 59 -23 65 -26 7 -2 19 18 29 49 9 29 19 60 22 68 3 9 14
-13 25 -50 11 -36 21 -66 23 -68z m1544 -141 c28 11 40 4 23 -13 -19 -19 -14
-36 15 -51 l26 -14 -36 -7 c-35 -6 -36 -8 -30 -39 l6 -32 -27 24 -26 25 -16
-22 c-21 -31 -31 -27 -26 8 4 27 1 31 -27 36 l-32 7 23 15 c13 8 24 16 26 18
2 1 -4 14 -13 28 -18 28 -9 34 25 16 15 -8 20 -5 30 23 l12 32 11 -31 c11 -28
15 -30 36 -23z"
                />
                <path
                  d="M1200 2039 l0 -161 23 14 c12 9 126 79 252 158 127 79 232 144 234
146 2 2 -111 4 -252 4 l-257 0 0 -161z"
                />
                <path
                  d="M270 2136 c0 -34 2 -36 201 -161 200 -125 202 -126 256 -123 l53 3
-217 135 c-119 74 -234 145 -255 158 l-38 23 0 -35z"
                />
                <path
                  d="M260 1956 l0 -106 165 0 c91 0 165 2 165 4 0 3 -268 172 -317 200
-10 6 -13 -17 -13 -98z"
                />
                <path
                  d="M264 1547 c-3 -8 -4 -50 -2 -95 l3 -82 140 86 c77 47 144 90 150 95
5 5 -51 9 -138 9 -114 0 -149 -3 -153 -13z"
                />
              </g>
            </svg>
          </span>
        </div>
        <span class="row-span-2">Customer Details</span></template
      >
    </a>
    <a
      id="history-tab"
      aria-selected="false"
      class="grid grid-rows-3 pt-1 pb-2 sm:mr-8 cursor-pointer"
      data-toggle="tab"
      role="tab"
      @click="changeTab('history')"
    >
      <template v-if="fetchingApplicantLoans">
        <noFlagTemplate /> History</template
      >

      <template v-else
        ><span
          :class="
            history.premature
              ? `${statusColors.error} error`
              : `${statusColors.success} success`
          "
          :data-tooltip="prematureLoanMessage(history.premature)"
          class="tooltip flex"
        >
          <AlertCircleIcon size="16"
        /></span>
        <span>History</span>
        <span
          :class="statusColors.error"
          class="tooltip inverted error text-xs"
          data-tooltip="Total number of loan application"
          >{{ history.count }}</span
        ></template
      >
    </a>
    <a
      id="bank-details-tab"
      aria-selected="false"
      class="grid grid-rows-3 pt-1 pb-2 sm:mr-8 cursor-pointer"
      data-toggle="tab"
      role="tab"
      @click="changeTab('banking')"
    >
      <template v-if="fetchingApplicantBankAccounts"
        ><noFlagTemplate /> Banking</template
      >
      <template v-else>
        <div class="flex justify-between">
          <span
            :class="[
              statusColors[verificationClass(banking.account)],
              verificationClass(banking.account, 'Income account')
            ]"
            :data-tooltip="
              verificationMessage(banking.account, 'Income account')
            "
            class="tooltip flex"
            title="Income Account"
            ><HomeIcon size="16"
          /></span>
          <span
            :class="
              banking.card.verified
                ? `success ${statusColors.success}`
                : `error ${statusColors.error}`
            "
            :data-tooltip="cardMessage(banking.card.verified, 'Debit Card')"
            class="tooltip flex"
            ><CreditCardIcon
              :class="
                banking.card.verified
                  ? `${statusColors.success}`
                  : `${statusColors.error}`
              "
              size="16"
          /></span>
        </div>
        <span class="rows-span-2">Banking</span>
      </template>
    </a>
    <a
      id="personal-loans-tab"
      aria-selected="false"
      class="grid grid-rows-3 pt-1 pb-1 sm:mr-8 cursor-pointer"
      data-toggle="tab"
      role="tab"
      @click="changeTab('serviceability')"
    >
      <template v-if="fetchingLendingDetails"
        ><noFlagTemplate /> Serviceability</template
      >
      <template v-else
        ><div class="flex justify-between">
          <span
            :class="[
              statusColors[verificationClass(serviceability.fpd)],
              verificationClass(serviceability.fpd)
            ]"
            :data-tooltip="verificationMessage(serviceability.fpd, 'FPD')"
            class="tooltip flex "
          >
            <CalendarIcon size="16" />
          </span>
          <span
            :class="[
              statusColors[verificationClass(serviceability.frequency)],
              verificationClass(serviceability.frequency)
            ]"
            :data-tooltip="
              verificationMessage(serviceability.frequency, 'Payment frequency')
            "
            class="tooltip flex"
          >
            <svg
              height="16"
              preserveAspectRatio="xMidYMid meet"
              version="1.0"
              viewBox="0 0 564.000000 398.000000"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="currentColor"
                stroke="none"
                transform="translate(0.000000,398.000000) scale(0.100000,-0.100000)"
              >
                <path
                  d="M2420 3693 c-28 -8 -47 -48 -59 -123 -17 -105 -38 -250 -51 -350 -6
-47 -15 -105 -20 -130 -5 -25 -12 -70 -15 -100 -6 -57 -25 -185 -51 -355 -34
-224 -43 -285 -59 -400 -9 -66 -23 -156 -30 -200 -7 -44 -16 -115 -20 -157 -4
-43 -10 -80 -14 -82 -19 -12 -33 19 -46 105 -15 99 -37 233 -54 334 -6 33 -18
103 -27 155 -48 292 -75 447 -90 522 -8 37 -14 79 -14 93 0 33 -59 95 -90 95
-42 0 -86 -37 -105 -87 -41 -110 -88 -233 -117 -303 -32 -80 -37 -92 -93 -240
-20 -52 -40 -104 -45 -115 -5 -11 -21 -51 -35 -89 -15 -39 -32 -80 -38 -93
l-10 -23 -308 0 -308 0 -28 -24 c-38 -33 -40 -104 -3 -138 l25 -23 365 0 c363
0 365 0 387 22 20 20 40 61 78 163 49 128 60 156 67 165 4 5 8 15 8 23 0 7 8
32 19 55 10 23 33 82 51 130 18 48 38 86 44 84 6 -2 14 -21 17 -43 4 -21 12
-66 18 -99 6 -33 18 -103 26 -155 9 -52 22 -131 30 -175 17 -101 40 -233 60
-355 26 -153 45 -269 60 -355 15 -81 29 -170 46 -280 11 -74 25 -103 61 -121
35 -18 54 -18 89 3 40 24 45 45 83 303 9 58 22 150 30 205 8 55 17 123 21 150
3 28 10 70 15 95 5 25 14 83 20 130 6 47 18 130 26 185 8 55 21 145 29 200 8
55 21 145 29 200 8 55 22 154 31 219 14 104 38 186 50 174 2 -2 8 -44 14 -93
5 -50 15 -121 21 -160 6 -38 17 -113 25 -165 8 -52 20 -126 26 -165 6 -38 17
-113 24 -165 13 -89 27 -183 60 -410 8 -52 21 -142 29 -200 9 -58 23 -150 31
-205 9 -55 22 -145 30 -200 39 -272 66 -453 110 -735 19 -122 43 -160 102
-160 55 0 91 42 103 120 21 145 31 209 44 295 8 50 22 137 31 195 15 101 29
190 60 385 8 50 21 137 30 195 9 58 22 146 30 195 27 169 37 240 51 330 7 50
20 135 29 190 8 55 22 146 31 203 23 160 46 198 59 100 4 -29 16 -93 27 -143
10 -49 25 -121 32 -160 8 -38 18 -90 24 -115 5 -25 17 -80 26 -123 29 -140 48
-231 57 -276 6 -23 17 -80 25 -125 32 -166 70 -350 84 -398 13 -46 43 -68 93
-68 52 0 80 32 107 123 40 137 82 272 95 312 7 22 23 74 35 115 12 41 25 86
30 100 5 14 18 59 30 100 12 41 30 98 40 125 l18 50 436 5 435 5 23 23 c35 35
32 106 -5 138 l-28 24 -483 0 -483 0 -26 -24 c-24 -22 -74 -161 -118 -326 -6
-19 -16 -53 -23 -75 -8 -22 -24 -73 -36 -113 -31 -99 -38 -90 -76 103 -9 44
-22 109 -29 145 -37 180 -47 230 -60 290 -7 36 -20 99 -28 140 -9 41 -23 109
-32 150 -8 41 -22 109 -30 151 -8 43 -18 97 -24 120 -5 24 -16 78 -25 119 -8
41 -22 107 -30 145 -8 39 -18 87 -21 107 -7 46 -54 98 -88 98 -78 0 -101 -37
-122 -194 -8 -55 -19 -130 -25 -166 -6 -36 -17 -110 -26 -165 -15 -97 -30
-196 -59 -380 -8 -49 -22 -137 -30 -195 -9 -58 -23 -145 -31 -195 -7 -49 -19
-121 -24 -160 -17 -110 -40 -262 -55 -355 -7 -47 -20 -136 -29 -199 -17 -122
-20 -131 -41 -131 -9 0 -15 22 -19 73 -4 39 -13 113 -21 162 -8 50 -21 140
-30 200 -9 61 -23 148 -30 195 -7 47 -16 110 -19 140 -4 30 -11 75 -16 100 -5
25 -14 81 -20 125 -30 212 -49 346 -85 585 -8 55 -21 144 -29 198 -8 53 -19
131 -26 172 -17 114 -39 264 -56 380 -35 249 -45 289 -71 310 -24 19 -66 24
-103 13z"
                />
              </g>
            </svg>
          </span>
          <span
            :class="[
              statusColors[verificationClass(serviceability.customer)],
              verificationClass(serviceability.customer)
            ]"
            :data-tooltip="
              verificationMessage(serviceability.customer, 'Customer name')
            "
            class="tooltip flex"
          >
            <svg
              height="16"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 276.000000 216.000000"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="currentColor"
                stroke="none"
                transform="translate(0.000000,216.000000) scale(0.100000,-0.100000)"
              >
                <path
                  d="M449 1683 c-61 -37 -59 -16 -59 -653 l0 -582 39 -39 39 -39 907 0
c893 0 908 0 940 20 66 41 65 28 65 644 0 604 0 601 -55 644 l-28 22 -911 0
c-824 -1 -913 -2 -937 -17z m1835 -85 c14 -20 16 -90 16 -563 0 -473 -2 -543
-16 -563 l-15 -22 -880 0 c-588 0 -887 3 -900 10 -18 10 -19 27 -19 573 0 425
3 566 12 575 9 9 223 12 899 12 l888 0 15 -22z"
                />
                <path
                  d="M905 1460 c-107 -24 -163 -105 -152 -217 18 -172 246 -225 348 -81
20 28 24 45 24 110 0 70 -2 79 -31 115 -47 58 -124 88 -189 73z"
                />
                <path
                  d="M1420 1405 c-10 -12 -10 -18 0 -30 11 -13 60 -15 364 -13 334 3 351
4 354 22 2 10 -2 22 -10 27 -7 5 -167 9 -354 9 -292 0 -343 -2 -354 -15z"
                />
                <path
                  d="M1419 1164 c-11 -14 -10 -18 6 -30 15 -11 83 -14 354 -14 185 0 342
4 349 9 8 5 12 17 10 27 -3 18 -20 19 -354 22 -314 2 -353 1 -365 -14z"
                />
                <path
                  d="M727 1048 c-63 -63 -105 -146 -118 -234 -13 -96 -2 -134 48 -162 73
-40 171 -55 323 -49 139 6 190 15 248 45 40 20 48 33 57 93 14 93 -29 219
-100 292 -67 68 -75 71 -129 41 -41 -23 -56 -26 -121 -22 -57 2 -81 8 -104 26
-17 12 -35 22 -40 22 -6 0 -34 -24 -64 -52z"
                />
                <path
                  d="M1425 936 c-16 -12 -17 -16 -6 -30 12 -15 51 -16 365 -14 334 3 351
4 354 22 2 10 -2 22 -10 27 -7 5 -164 9 -349 9 -271 0 -339 -3 -354 -14z"
                />
                <path
                  d="M1420 695 c-10 -12 -10 -18 0 -30 11 -13 60 -15 364 -13 l351 3 0 25
0 25 -351 3 c-304 2 -353 0 -364 -13z"
                />
              </g>
            </svg>
          </span>
        </div>

        <span class="">Serviceability</span>
        <div class="info flex justify-between">
          <span
            :class="
              serviceability.netSurplusAmount >= 0
                ? statusColors.success
                : statusColors.error
            "
            class=" tooltip inverted text-xs"
            data-tooltip="Net Surplus Amount"
            >{{ serviceability.netSurplusAmount }}</span
          >
          <div class="grid grid-rows-2">
            <span
              :class="
                serviceability.pei_before_repayment >= 0
                  ? statusColors.success
                  : statusColors.error
              "
              class=" tooltip inverted text-xs ml-3"
              data-tooltip="PEI Surplus Amount Before Repayment"
              >{{
                serviceability.pei_before_repayment == "Not Applicable"
                  ? "N/A"
                  : serviceability.pei_before_repayment
              }}</span
            >
            <span
              :class="
                serviceability.pei >= 0
                  ? statusColors.success
                  : statusColors.error
              "
              class=" tooltip inverted text-xs ml-3"
              data-tooltip="PEI Surplus Amount After Repayment"
              >{{
                serviceability.pei == "Not Applicable"
                  ? "N/A"
                  : serviceability.pei
              }}</span
            >
          </div>
        </div>
      </template>
    </a>
    <a
      id="tags-tab"
      aria-selected="false"
      class="grid grid-rows-3 pt-1 pb-2 sm:mr-8 cursor-pointer"
      data-toggle="tab"
      role="tab"
      @click="changeTab('tags')"
    >
      <template v-if="fetchingLnErrorTags"><noFlagTemplate /> Tags</template>
      <template v-else
        ><span
          v-if="tagString"
          :class="statusColors.error"
          :data-tooltip="tagString"
          class="tooltip flex error cursor-pointer"
          ><AlertCircleIcon size="16"
        /></span>
        <span class="rows-span-2">Tags</span></template
      >
    </a>
    <a
      id="doc-reqs-tab"
      aria-selected="false"
      class="grid grid-rows-3 pt-1 pb-2 sm:mr-8 cursor-pointer"
      data-toggle="tab"
      role="tab"
      @click="changeTab('docs-and-rebuttals')"
    >
      <template v-if="fetchingDocReq"
        ><noFlagTemplate /> Docs & Rebuttals</template
      >
      <template v-else
        ><div class="flex justify-between">
          <span
            :class="rebuttalsClass(rebuttalCounts.loan)"
            :data-tooltip="
              rebuttalsMessage(rebuttalCounts.loan, rebuttalCategories.loan)
            "
            class="tooltip flex"
          >
            <svg
              height="16"
              preserveAspectRatio="xMidYMid meet"
              version="1.0"
              viewBox="0 0 187.000000 176.000000"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="currentColor"
                stroke="none"
                transform="translate(0.000000,176.000000) scale(0.100000,-0.100000)"
              >
                <path
                  d="M460 1718 c-63 -22 -142 -43 -175 -46 l-60 -7 0 -155 0 -155 56 -15
c31 -8 82 -32 112 -54 70 -48 117 -54 238 -31 86 17 95 17 190 0 92 -16 101
-16 124 -1 56 37 15 105 -98 161 -40 20 -95 39 -124 44 -60 9 -83 24 -83 55 0
29 18 46 49 46 52 0 183 -74 267 -151 58 -53 101 -74 120 -58 30 24 19 53 -78
212 l-60 98 -99 50 c-95 47 -102 49 -181 48 -70 0 -101 -7 -198 -41z"
                />
                <path
                  d="M7 1704 c-4 -4 -7 -94 -7 -201 l0 -193 90 0 90 0 -2 198 -3 197 -80
3 c-45 1 -84 0 -88 -4z"
                />
                <path
                  d="M1177 1308 c-15 -12 -8 -28 69 -173 47 -88 113 -212 146 -275 34 -63
67 -124 75 -135 18 -27 16 -59 -4 -77 -10 -9 -78 -46 -150 -82 -73 -36 -133
-69 -133 -74 0 -4 7 -15 14 -24 12 -17 27 -11 197 77 112 57 183 100 181 107
-3 11 -168 326 -222 423 -9 17 -42 78 -74 138 -32 59 -63 107 -70 107 -7 0
-20 -6 -29 -12z"
                />
                <path
                  d="M1007 1256 c-20 -47 -11 -64 40 -68 34 -3 36 -5 144 -206 102 -188
109 -205 95 -221 -9 -10 -16 -31 -16 -47 0 -27 -7 -33 -76 -67 -61 -30 -79
-35 -91 -26 -8 7 -30 15 -49 18 -34 6 -39 13 -146 209 -104 190 -110 204 -94
221 9 9 16 31 16 47 0 26 7 33 48 54 l47 24 -68 8 c-64 7 -71 6 -128 -22 -33
-17 -59 -34 -57 -38 4 -9 43 -82 102 -192 25 -47 92 -172 148 -277 56 -106
106 -193 111 -193 8 0 354 175 389 197 4 2 -16 48 -44 101 -29 53 -103 191
-164 307 l-112 209 -39 1 c-36 1 -40 -2 -56 -39z"
                />
                <path
                  d="M1014 1011 c-63 -27 -84 -116 -40 -164 64 -69 176 -29 176 64 0 69
-76 125 -136 100z"
                />
                <path
                  d="M1675 498 c-3 -8 -4 -101 -3 -208 l3 -195 98 -3 97 -3 -2 208 -3 208
-93 3 c-69 2 -94 -1 -97 -10z"
                />
                <path
                  d="M1424 466 c-22 -7 -72 -29 -110 -49 -60 -33 -77 -37 -140 -37 -57 0
-74 -4 -88 -19 -43 -48 11 -93 122 -101 69 -4 77 -7 77 -25 0 -17 -8 -20 -55
-23 -95 -6 -162 25 -196 90 -17 33 -184 118 -232 118 -39 0 -82 -38 -82 -72 0
-13 7 -33 15 -46 23 -32 194 -142 351 -224 l139 -73 90 0 90 0 105 57 105 57
0 178 0 178 -75 2 c-41 1 -93 -4 -116 -11z"
                />
              </g>
            </svg>
          </span>
          <span
            :class="rebuttalsClass(rebuttalCounts.gambling)"
            :data-tooltip="
              rebuttalsMessage(
                rebuttalCounts.gambling,
                rebuttalCategories.gambling
              )
            "
            class="tooltip flex"
            ><svg
              height="16"
              preserveAspectRatio="xMidYMid meet"
              version="1.0"
              viewBox="0 0 95.000000 105.000000"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="currentColor"
                stroke="none"
                transform="translate(0.000000,105.000000) scale(0.100000,-0.100000)"
              >
                <path
                  d="M380 1000 c-8 -5 -50 -10 -92 -10 -82 0 -101 -9 -119 -57 -7 -18 -16
-23 -45 -23 -49 0 -103 -27 -110 -55 -5 -21 13 -242 52 -635 20 -203 22 -205
149 -196 50 3 135 11 189 16 124 12 267 5 326 -17 31 -11 57 -14 83 -9 42 8
100 54 123 97 31 61 6 164 -51 206 -24 17 -25 21 -14 51 17 51 7 137 -20 166
-21 23 -22 28 -11 73 50 209 54 240 38 265 -11 17 -34 29 -79 40 -35 9 -125
32 -199 51 -171 44 -202 49 -220 37z m171 -60 c68 -17 147 -37 175 -45 28 -8
60 -15 71 -15 12 0 31 -8 44 -19 l23 -18 -23 -94 c-12 -52 -27 -108 -32 -126
l-10 -31 -59 5 c-74 7 -110 -7 -155 -60 -32 -37 -37 -39 -60 -29 -50 23 -131
6 -178 -36 -48 -43 -62 -152 -27 -210 38 -62 121 -94 189 -73 37 11 85 50 107
87 20 34 26 8 10 -46 -14 -48 -14 -56 3 -106 10 -30 17 -54 16 -54 -3 0 -184
42 -235 55 -30 8 -85 21 -121 30 -112 27 -112 -2 -9 410 50 198 94 370 100
383 12 28 23 28 171 -8z m-215 7 c2 -2 -37 -164 -86 -360 -50 -197 -90 -369
-90 -382 0 -14 9 -34 20 -45 19 -19 19 -20 1 -20 -41 0 -42 16 -21 266 51 616
38 561 123 550 27 -3 51 -7 53 -9z m-176 -109 l-1 -43 -20 28 c-27 35 -40 34
-63 -4 -18 -30 -18 -32 0 -65 22 -40 37 -43 58 -12 16 22 16 22 16 -17 0 -22
-9 -141 -20 -265 -11 -124 -20 -231 -20 -239 0 -8 -2 -12 -4 -10 -3 2 -16 123
-30 269 -14 146 -28 286 -30 311 -7 67 10 83 92 88 20 1 22 -4 22 -41z m-45
-47 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m670 -251 c0 -7 -5
-15 -10 -17 -16 -5 -49 23 -41 35 9 14 51 -1 51 -18z m-87 8 c-7 -21 -36 -34
-49 -21 -6 6 -8 16 -5 22 10 15 59 14 54 -1z m-72 -52 c3 -8 0 -24 -6 -36 -16
-29 -35 -11 -26 24 7 28 23 34 32 12z m212 -11 c15 -33 15 -35 -3 -35 -17 0
-39 36 -30 50 10 17 21 11 33 -15z m-78 -5 c24 -24 26 -67 3 -92 -36 -40 -113
-8 -113 47 0 14 9 34 20 45 11 11 31 20 45 20 14 0 34 -9 45 -20z m-310 -14
c0 -14 -21 -26 -46 -26 -17 0 -19 26 -1 33 24 10 47 7 47 -7z m75 1 c12 -10
15 -16 6 -21 -16 -10 -51 3 -51 20 0 18 21 18 45 1z m65 -78 c0 -11 -6 -19
-14 -19 -17 0 -31 34 -21 50 10 16 35 -8 35 -31z m-217 25 c10 -10 -12 -44
-28 -44 -18 0 -20 21 -3 38 13 13 22 15 31 6z m132 -19 c27 -26 31 -46 15 -75
-17 -32 -48 -43 -81 -30 -48 20 -52 96 -7 118 35 16 47 14 73 -13z m345 21 c0
-1 -5 -17 -11 -35 -8 -23 -17 -31 -35 -31 l-23 0 20 35 c12 19 27 35 35 35 8
0 14 -2 14 -4z m-160 -88 c0 -14 -20 -19 -40 -8 -11 6 -20 19 -20 30 0 18 1
18 30 2 16 -9 30 -20 30 -24z m-320 -17 c13 -26 13 -31 -5 -31 -12 0 -35 28
-35 44 0 14 30 4 40 -13z m220 6 c0 -20 -26 -52 -33 -40 -10 15 4 53 19 53 8
0 14 -6 14 -13z m174 -21 c-7 -19 -30 -27 -50 -19 -15 6 -15 8 2 20 24 18 55
17 48 -1z m81 1 c12 -10 15 -16 6 -21 -16 -10 -51 3 -51 20 0 18 21 18 45 1z
m-405 -52 c7 -9 10 -18 7 -21 -11 -11 -57 6 -57 21 0 20 34 19 50 0z m95 6 c6
-10 -25 -31 -44 -31 -15 0 -14 27 2 33 20 9 35 8 42 -2z m165 -8 c0 -15 -23
-43 -35 -43 -18 0 -18 2 -4 34 7 14 17 22 25 19 8 -3 14 -7 14 -10z m210 -30
c0 -21 -28 -15 -35 7 -10 30 5 47 22 25 7 -11 13 -25 13 -32z m-95 25 c49 -22
42 -98 -11 -118 -26 -10 -61 1 -76 24 -16 24 -8 73 15 89 27 19 39 20 72 5z
m-122 -98 c7 -27 -13 -37 -30 -15 -18 26 -16 35 6 35 10 0 21 -9 24 -20z m217
11 c0 -21 -13 -41 -26 -41 -15 0 -17 10 -8 34 7 17 34 22 34 7z m-684 -52 c43
-4 105 -8 137 -8 91 -1 58 -18 -50 -25 -54 -4 -114 -9 -134 -12 -46 -7 -69 14
-69 63 l0 35 18 -22 c15 -18 33 -24 98 -31z m540 -32 c1 -10 -3 -17 -10 -17
-20 0 -50 26 -42 38 9 16 49 -1 52 -21z m94 13 c0 -10 -32 -30 -49 -30 -15 0
-14 27 2 33 21 9 47 7 47 -3z"
                />
                <path
                  d="M415 896 c-27 -22 -29 -26 -20 -62 12 -45 20 -47 59 -16 27 21 29 26
20 59 -11 46 -21 49 -59 19z m25 -36 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3
6 8 10 11 10 2 0 4 -4 4 -10z"
                />
                <path
                  d="M522 843 c2 -11 17 -20 43 -25 22 -4 56 -12 75 -18 19 -5 55 -15 79
-21 l44 -10 -6 -42 c-4 -23 -9 -57 -13 -74 -5 -26 -3 -33 9 -33 10 0 21 19 31
54 32 108 22 120 -126 157 -133 34 -140 34 -136 12z"
                />
                <path
                  d="M235 903 c-29 -33 -30 -35 -10 -73 20 -38 38 -38 64 -1 21 29 21 31
4 65 -9 20 -20 36 -24 36 -5 0 -20 -12 -34 -27z m35 -43 c0 -5 -4 -10 -10 -10
-5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"
                />
              </g>
            </svg>
          </span>
          <span
            :class="rebuttalsClass(rebuttalCounts.dh)"
            :data-tooltip="
              rebuttalsMessage(rebuttalCounts.dh, rebuttalCategories.dh)
            "
            class="tooltip flex"
            ><svg
              height="16"
              preserveAspectRatio="xMidYMid meet"
              version="1.0"
              viewBox="0 0 570.000000 388.000000"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="currentColor"
                stroke="none"
                transform="translate(0.000000,388.000000) scale(0.100000,-0.100000)"
              >
                <path
                  d="M3683 3873 c-24 -2 -43 -8 -43 -14 0 -5 -13 -9 -30 -9 -16 0 -30 -4
-30 -10 0 -5 -9 -10 -20 -10 -11 0 -20 -4 -20 -10 0 -5 -9 -10 -20 -10 -11 0
-20 -4 -20 -10 0 -5 -9 -10 -20 -10 -11 0 -20 -4 -20 -10 0 -5 -9 -10 -20 -10
-11 0 -20 -4 -20 -10 0 -5 -8 -10 -18 -10 -10 0 -24 -9 -32 -20 -8 -11 -20
-20 -27 -20 -7 0 -65 -52 -128 -115 -63 -63 -115 -121 -115 -128 0 -7 -9 -19
-20 -27 -11 -8 -20 -22 -20 -32 0 -10 -4 -18 -10 -18 -5 0 -10 -9 -10 -20 0
-11 -4 -20 -10 -20 -5 0 -10 -9 -10 -20 0 -11 -4 -20 -10 -20 -5 0 -10 -9 -10
-20 0 -11 -4 -20 -10 -20 -5 0 -10 -9 -10 -20 0 -11 -4 -20 -10 -20 -5 0 -10
-13 -10 -30 0 -16 -4 -30 -10 -30 -5 0 -10 -18 -10 -40 0 -22 -4 -40 -10 -40
-6 0 -10 -27 -10 -60 0 -33 -4 -60 -10 -60 -6 0 -10 -47 -10 -120 0 -73 4
-120 10 -120 6 0 10 -27 10 -60 0 -33 4 -60 10 -60 6 0 10 -13 10 -30 0 -16 5
-30 10 -30 6 0 10 -13 10 -30 0 -16 5 -30 10 -30 6 0 10 -13 10 -30 0 -16 5
-30 10 -30 6 0 10 -9 10 -20 0 -11 5 -20 10 -20 6 0 10 -9 10 -20 0 -11 5 -20
10 -20 6 0 10 -8 10 -18 0 -10 9 -24 20 -32 11 -8 20 -21 20 -30 0 -9 9 -22
20 -30 11 -8 20 -20 20 -27 0 -16 165 -183 181 -183 6 0 24 -13 39 -30 15 -16
35 -30 44 -30 9 0 16 -4 16 -10 0 -5 8 -10 18 -10 10 0 24 -9 32 -20 8 -11 22
-20 32 -20 10 0 18 -4 18 -10 0 -5 14 -10 30 -10 17 0 30 -4 30 -10 0 -5 9
-10 20 -10 11 0 20 -4 20 -10 0 -5 14 -10 30 -10 17 0 30 -4 30 -10 0 -5 23
-10 50 -10 28 0 50 -4 50 -10 0 -6 33 -10 80 -10 47 0 80 -4 80 -10 0 -5 14
-10 30 -10 17 0 30 5 30 10 0 6 37 10 90 10 53 0 90 4 90 10 0 6 18 10 40 10
22 0 40 5 40 10 0 6 18 10 40 10 22 0 40 5 40 10 0 6 9 10 20 10 11 0 20 5 20
10 0 6 9 10 20 10 11 0 20 5 20 10 0 6 9 10 20 10 11 0 20 5 20 10 0 6 9 10
20 10 11 0 20 5 20 10 0 6 9 10 20 10 11 0 20 5 20 10 0 6 7 10 16 10 8 0 33
18 54 40 21 22 43 40 49 40 14 0 135 122 139 140 2 8 12 21 23 31 10 9 19 23
19 30 0 8 9 21 20 29 11 8 20 22 20 32 0 10 5 18 10 18 6 0 10 9 10 21 0 11 5
17 10 14 6 -3 10 1 10 9 0 9 5 16 10 16 6 0 10 14 10 30 0 17 5 30 10 30 6 0
10 9 10 20 0 11 5 20 10 20 6 0 10 14 10 30 0 17 5 30 10 30 6 0 10 23 10 50
0 28 5 50 10 50 6 0 10 27 10 60 0 33 4 60 10 60 6 0 10 30 10 70 0 40 -4 70
-10 70 -6 0 -10 33 -10 80 0 47 -4 80 -10 80 -5 0 -10 18 -10 40 0 22 -4 40
-10 40 -5 0 -10 14 -10 30 0 17 -4 30 -10 30 -5 0 -10 14 -10 30 0 17 -4 30
-10 30 -5 0 -10 9 -10 20 0 11 -4 20 -10 20 -5 0 -10 9 -10 20 0 11 -4 20 -10
20 -5 0 -10 8 -10 18 0 10 -9 24 -20 32 -11 8 -20 21 -20 30 0 9 -9 22 -20 30
-11 8 -20 20 -20 27 0 16 -185 203 -201 203 -6 0 -24 14 -39 30 -15 17 -35 30
-44 30 -9 0 -16 5 -16 10 0 6 -9 10 -20 10 -11 0 -20 5 -20 10 0 6 -9 10 -20
10 -11 0 -20 5 -20 10 0 6 -9 10 -20 10 -11 0 -20 5 -20 10 0 6 -9 10 -20 10
-11 0 -20 5 -20 10 0 6 -13 10 -29 10 -16 0 -31 6 -33 13 -4 9 -69 12 -279 13
-151 1 -293 0 -316 -3z"
                />
                <path
                  d="M0 1700 l0 -1250 2826 0 c2435 0 2827 2 2840 14 12 13 14 193 14
1250 l0 1236 -240 0 -240 0 0 -100 0 -100 140 0 140 0 0 -1050 0 -1050 -2340
0 -2340 0 0 1050 0 1050 946 0 c806 0 948 2 960 14 10 10 14 39 14 100 l0 86
-1360 0 -1360 0 0 -1250z m360 -1010 c0 -16 7 -20 30 -20 17 0 30 -4 30 -10 0
-5 9 -10 20 -10 13 0 20 -7 20 -20 0 -11 -4 -20 -10 -20 -5 0 -10 -9 -10 -20
0 -13 -7 -20 -20 -20 -11 0 -20 -4 -20 -10 0 -21 -32 -9 -65 25 -34 33 -46 65
-25 65 6 0 10 -4 10 -10 0 -5 5 -10 10 -10 6 0 10 14 10 30 0 17 -4 30 -10 30
-5 0 -10 5 -10 10 0 6 9 10 20 10 13 0 20 -7 20 -20z"
                />
                <path
                  d="M980 2384 c0 -61 4 -90 14 -100 22 -21 1110 -21 1132 0 10 10 14 39
14 100 l0 86 -580 0 -580 0 0 -86z"
                />
                <path
                  d="M980 2004 c0 -61 4 -90 14 -100 12 -12 78 -14 390 -14 l376 0 0 100
0 100 -390 0 -390 0 0 -86z"
                />
                <path
                  d="M980 1524 c0 -61 4 -90 14 -100 22 -21 2550 -21 2572 0 10 10 14 39
14 100 l0 86 -1300 0 -1300 0 0 -86z"
                />
                <path
                  d="M980 1044 c0 -61 4 -90 14 -100 22 -21 2550 -21 2572 0 10 10 14 39
14 100 l0 86 -1300 0 -1300 0 0 -86z"
                />
                <path
                  d="M3760 1044 c0 -61 4 -90 14 -100 12 -12 129 -14 770 -14 l756 0 0
100 0 100 -770 0 -770 0 0 -86z"
                />
                <path
                  d="M0 25 l0 -25 2850 0 2850 0 0 25 0 25 -2850 0 -2850 0 0 -25z"
                />
              </g>
            </svg>
          </span>
        </div>
        <span class="flex items-end">Docs & Rebuttals</span>
        <div class="flex justify-between">
          <span
            :class="statusColors.warning"
            :data-tooltip="`Pending Docs: ${docRequestFlags.pending.types} `"
            class="tooltip warning inverted text-xs"
            >{{ docRequestFlags.pending.count }}</span
          >
          <span
            :class="statusColors.success"
            :data-tooltip="`Received Docs: ${docRequestFlags.completed.types} `"
            class="tooltip inverted success text-xs"
            >{{ docRequestFlags.completed.count }}</span
          >
        </div></template
      >
    </a>
    <a
      id="verification-tab"
      aria-selected="false"
      class="grid grid-rows-3 pt-1 pb-2 sm:mr-8 cursor-pointer"
      data-toggle="tab"
      role="tab"
      @click="changeTab('verification')"
    >
      <template v-if="creditCheckLoading"
        ><noFlagTemplate />Verification</template
      >
      <template v-else
        ><span
          :class="{
            'text-theme-15 grey': !creditVerification.verified,
            'text-theme-3 success': creditVerification.verified
          }"
          :data-tooltip="creditVerification.message"
          class="tooltip flex cursor-pointer"
          ><CreditCardIcon size="16"
        /></span>
        <span class="row-span-2">Verification</span></template
      >
    </a>

    <div v-if="!fetchingKYC" class="grid grid-rows-2 gap-1 fixed right-0 top-0">
      <LoanApplicationNotification :application-id="applicationId" />
      <AirCall :application-id="applicationId" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import noFlagTemplate from "@/components/no-flag-template/Main.vue";
import { loanFlags } from "@/composables/components/loan-applications/loanFlags";
import LoanApplicationNotification from "@/components/loan-notifications/Main";
import AirCall from "@/components/loan-applications/ln-aircall/Main";

export default defineComponent({
  props: {
    changeTab: {
      type: Function,
      required: true
    },
    fetchingKYC: {
      type: Boolean,
      require: true
    },
    fetchingApplicantLoans: {
      type: Boolean,
      require: true,
      default: false
    },
    fetchingApplicantBankAccounts: {
      type: Boolean,
      require: true
    },
    fetchingLendingDetails: {
      type: Boolean,
      require: true
    },
    applicationId: {
      type: Number,
      required: true
    }
  },
  components: {
    noFlagTemplate,
    LoanApplicationNotification,
    AirCall
  },
  setup(props) {
    const {
      basic,
      tagString,
      history,
      banking,
      creditVerification,
      serviceability,
      rebuttalCounts,
      rebuttalCategories,
      docRequestFlags,
      fetchingLnErrorTags,
      fetchingDocReq,
      creditCheckLoading
    } = loanFlags(props);

    const statusColors = {
      error: "text-theme-6",
      warning: "text-theme-12",
      success: "text-theme-3"
    };

    const rebuttalsClass = value =>
      value ? `${statusColors.error} error` : `${statusColors.success} success`;

    const rebuttalsMessage = (value, type) =>
      value ? `Total ${type}: ${value}` : `No ${type}`;

    const cardMessage = (condition, type) =>
      `${type} is ${condition ? "verified" : "not verified"}`;

    const prematureLoanMessage = condition =>
      condition ? "Has premature loan closure" : "No premature loan closure";

    function verificationClass({
      last_modified_by = 0,
      system_verified = false,
      verification_required = false
    }) {
      let classType = "error";
      if (last_modified_by) {
        classType = "warning";
      } else if (system_verified) {
        classType = "success";
      }
      return classType;
    }

    function verificationMessage(
      {
        last_modified_by = 0,
        system_verified = false,
        verification_required = false
      },
      type = ""
    ) {
      let message = "needs verification";
      if (last_modified_by) {
        message = "is agent verified";
      } else if (system_verified) {
        message = "is system verified";
      }
      return `${type} ${message}`;
    }

    return {
      basic,
      statusColors,
      verificationClass,
      verificationMessage,
      rebuttalsClass,
      rebuttalsMessage,
      prematureLoanMessage,
      rebuttalCategories,
      cardMessage,
      tagString,
      history,
      banking,
      creditVerification,
      serviceability,
      rebuttalCounts,
      docRequestFlags,
      fetchingLnErrorTags,
      fetchingDocReq,
      creditCheckLoading
    };
  }
});
</script>
<style scoped>
.tooltip {
  position: relative;
}

.tooltip:before,
.tooltip:after {
  position: absolute;
  content: "";
  opacity: 0;
  transition: all 0.4s ease;
  z-index: 1;
}

.tooltip.success:before {
  border-width: 10px 8px 0 8px;
  border-style: solid;
  border-color: #70ce61 transparent transparent;
  top: -10px;
  transform: translateY(20px);
}

.tooltip.warning:before {
  border-width: 10px 8px 0 8px;
  border-style: solid;
  border-color: #ffd602 transparent transparent;
  top: -10px;
  transform: translateY(20px);
}

.tooltip.grey:before {
  border-width: 10px 8px 0 8px;
  border-style: solid;
  border-color: #b1b1b1 transparent transparent;
  top: -10px;
  transform: translateY(20px);
}

.tooltip.error:before {
  border-width: 10px 8px 0 8px;
  border-style: solid;
  border-color: #ff3466 transparent transparent;
  top: -10px;
  transform: translateY(20px);
}
.tooltip.inverted:before {
  border-width: 0px !important;
}
.tooltip.inverted:after {
  top: 10px !important;
  padding: 5px !important;
  height: 30px !important;
}

.tooltip.grey:after {
  content: attr(data-tooltip);
  background: #b1b1b1;
  width: max-content;
  height: 40px;
  font-size: 13px;
  font-weight: 500;
  top: -50px;
  left: -10px;
  padding: 10px;
  border-radius: 5px;
  letter-spacing: 1px;
  transform: translateY(20px);
  color: white;
}
.tooltip.error:after {
  content: attr(data-tooltip);
  background: #ff3466;
  width: max-content;
  height: 40px;
  font-size: 13px;
  font-weight: 500;
  top: -50px;
  left: -10px;
  padding: 10px;
  border-radius: 5px;
  letter-spacing: 1px;
  transform: translateY(20px);
  color: white;
}
.tooltip.warning:after {
  content: attr(data-tooltip);
  background: #ffd602;
  width: max-content;
  height: 40px;
  font-size: 13px;
  font-weight: 500;
  top: -50px;
  left: -10px;
  padding: 10px;
  border-radius: 5px;
  letter-spacing: 1px;
  transform: translateY(20px);
  color: white;
}
.tooltip:after {
  visibility: hidden;
  content: attr(data-tooltip);
  background: #70ce61;
  width: max-content;
  height: 40px;
  font-size: 13px;
  font-weight: 500;
  top: -50px;
  left: -10px;
  padding: 10px;
  border-radius: 5px;
  letter-spacing: 1px;
  transform: translateY(20px);
  color: white;
}

.tooltip:hover::before,
.tooltip:hover::after {
  visibility: visible;
  opacity: 1;
  transform: translateY(-2px);
}

@keyframes shake {
  0% {
    transform: rotate(2deg);
  }
  50% {
    transform: rotate(-3deg);
  }
  70% {
    transform: rotate(3deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

#anim:hover {
  animation: shake 500ms ease-in-out forwards;
}
</style>
