import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";

const state = () => {
  return {
    isProcessing: false,
    url: 0,
    errorMessage: "",
    validationErrors: [],
    dateNoteFormSuccessCount: 0,
    type: null
  };
};

const getters = {
  type: state => state.type,
  errorMessage: state => state.errorMessage,
  validationErrors: state => state.validationErrors,
  dateNoteFormSuccessCount: state => state.dateNoteFormSuccessCount
};

const mutations = {
  setUrl(state, url) {
    state.url = url;
  },
  setType(state, type) {
    state.type = type;
  },

  setErrorMessage(state, message) {
    state.errorMessage = message;
  },
  setValidationErrors(state, validationErrors) {
    state.validationErrors = validationErrors;
  },

  setIsProcessing(state, isProcessing) {
    state.isProcessing = isProcessing;
  },

  incrementDateNotFormSuccessCount(state) {
    state.dateNoteFormSuccessCount++;
  },

  clearErrors(state) {
    state.validationErrors = [];
    state.errorMessage = "";
  }
};

const actions = {
  submit: async ({ dispatch, state, commit }, form) => {
    commit("setIsProcessing", true);
    commit("clearErrors");
    axiosWrapperService
      .axiosPost(state.url, form)
      .then(() => {
        commit("incrementDateNotFormSuccessCount");
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: `${state.type} Created Successfully!`,
            type: "success"
          },
          { root: true }
        );
      })
      .catch(error => {
        if (error.response?.data?.errors) {
          commit("setValidationErrors", error.response.data.errors);
        } else {
          commit("setErrorMessage", formatErrorMessage(error));
        }
      })
      .finally(() => {
        commit("setIsProcessing", false);
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
