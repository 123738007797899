import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";

const state = () => {
  return {
    applicationId: 0,
    fetchingBankDetails: false,
    bankAccountOfApplicantLinkedToApplication: [],
    debitableBankAccounts: []
  };
};

const getters = {
  fetchingBankDetails: state => state.fetchingBankDetails,
  bankAccountOfApplicantLinkedToApplication: state =>
    state.bankAccountOfApplicantLinkedToApplication,
  debitableBankAccounts: state => state.debitableBankAccounts
};

const mutations = {
  setFetchingBankDetails(state) {
    state.fetchingBankDetails = true;
  },
  resetFetchingBankDetails(state) {
    state.fetchingBankDetails = false;
  },
  setApplicationId(state, applicationId) {
    state.applicationId = applicationId;
  },
  setBankAccountOfApplicantLinkedToApplication(state, data) {
    state.bankAccountOfApplicantLinkedToApplication = data;
  },

  setDebitableBankAccounts(state, debitableBankAccounts) {
    state.debitableBankAccounts = debitableBankAccounts;
  },

  resetBankAccounts(state) {
    state.debitableBankAccounts = [];
    state.bankAccountOfApplicantLinkedToApplication = [];
  }
};

const actions = {
  getDebitableBankAccounts: async ({ dispatch, commit }, { customerId }) => {
    axiosWrapperService
      .axiosGet(
        `/customers/${customerId}/getDebitableBankAccounts?omitIncomeDebitAccount=true`
      )
      .then(response => {
        commit("setDebitableBankAccounts", response.data);
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {});
  },
  getBankAccountsOfApplicantLinkedToApplication: async ({
    dispatch,
    state,
    commit
  }) => {
    commit("setFetchingBankDetails");
    axiosWrapperService
      .axiosGet(
        "/getBankAccountsOfApplicantLinkedToApplication/" + state.applicationId
      )
      .then(response => {
        commit(
          "setBankAccountOfApplicantLinkedToApplication",
          response.data.data
        );
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message:
              "Applicant Bank Account Fetch:" + formatErrorMessage(error),
            type: "error"
          },
          { root: true }
        );
      })
      .finally(() => {
        commit("resetFetchingBankDetails");
      });
  },
  updateLegacyLmsApplicationBankingIncomeAccount: async (
    { dispatch, state, commit },
    postData
  ) => {
    postData.application_id = state.applicationId;

    axiosWrapperService
      .axiosPost(
        "/gateway-proxies/fundo-core/banking/income-account/change",
        postData
      )
      .then(response => {
        dispatch("getBankAccountsOfApplicantLinkedToApplication").catch(
          error => {
            dispatch(
              "globalToastMessage/showSimpleToast",
              { message: formatErrorMessage(error), type: "error" },
              { root: true }
            );
          }
        );
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
