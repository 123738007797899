import * as types from "./mutation-types";

const state = () => {
  return {
    darkMode: false,
    fullPageLoading: false,
    sideMenuCollapsed: false
  };
};

// getters
const getters = {
  darkMode: state => state.darkMode,
  sideMenuCollapsed: state => state.sideMenuCollapsed,
  fullPageLoading: state => state.fullPageLoading
};

// actions
const actions = {
  setDarkMode({ commit }, darkMode) {
    commit(types.SET_DARK_MODE, { darkMode });
  }
};

// mutations
const mutations = {
  [types.SET_DARK_MODE](state, { darkMode }) {
    state.darkMode = darkMode;
  },
  toggleSideMenuCollapse(state) {
    state.sideMenuCollapsed = !state.sideMenuCollapsed;
  },
  collapseSideMenu(state) {
    state.sideMenuCollapsed = true;
  },
  expandSideMenu(state) {
    state.sideMenuCollapsed = false;
  },
  setFullPageLoading(state) {
    state.fullPageLoading = true;
  },
  resetFullPageLoading(state) {
    state.fullPageLoading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
