import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";

const state = () => {
  return {
    timelineNotesList: [],
    smsTemplates: [],
    noteEnabled: false,
    applicationId: 0,
    associatedModelKey: "",
    gettingNotes: true,
    busy: false,
    isGlobalNotesDisplayed: false,
    timelineNotesUpdateCounter: 0,
    timelineNotesAlertMessage: "",
    timelineNotesErrorMessage: "",
    timelineNotesSuccessMessage: ""
  };
};

// getters
const getters = {
  timelineNotesList: state => state.timelineNotesList,
  smsTemplates: state => state.smsTemplates,
  noteEnabled: state => state.noteEnabled,
  gettingNotes: state => state.gettingNotes,
  busy: state => state.busy,
  isGlobalNotesDisplayed: state => state.isGlobalNotesDisplayed,
  timelineNotesUpdateCounter: state => state.timelineNotesUpdateCounter,
  timelineNotesAlertMessage: state => state.timelineNotesAlertMessage,
  timelineNotesErrorMessage: state => state.timelineNotesErrorMessage,
  timelineNotesSuccessMessage: state => state.timelineNotesSuccessMessage
};

// actions
const actions = {
  getTimelineNotes({ state, commit }) {
    try {
      commit("clearTimelineNotesMessages");
      commit("setGettingNotes");
      axiosWrapperService
        .axiosGet("/getTimelineMessagesOfLoan/" + state.applicationId)
        .then(response => {
          commit("setTimelineNotesList", response.data.data);
        })
        .catch(() => {
          commit("setTimelineNotesError", "Failed to get the TimeLine Notes.");
        })
        .finally(() => {
          commit("resetGettingNotes");
        });
    } catch (e) {
      commit("setTimelineNotesError", "Failed to get the TimeLine Notes.");
    }
  },
  getWrittenSMSTextForCardVerfAndArrearsOnLoan({ dispatch, state, commit }) {
    try {
      commit("clearTimelineNotesMessages");
      commit("setGettingNotes");
      axiosWrapperService
        .axiosGet(
          "/getWrittenSMSTextForCardVerfAndArrearsOnLoan/" + state.applicationId
        )
        .then(response => {
          commit("setSmsTemplates", response.data.data);
        })
        .catch(error => {
          dispatch(
            "globalToastMessage/showSimpleToast",
            { message: formatErrorMessage(error), type: "error" },
            { root: true }
          );
        })
        .finally(() => {
          commit("resetGettingNotes");
        });
    } catch (e) {
      commit("setTimelineNotesError", "Failed to get the TimeLine Notes.");
    }
  },
  submitApplicationNotes(
    { commit },
    { noteContent, messageMode, associatedModelKey, applicationId }
  ) {
    try {
      commit("clearTimelineNotesMessages");
      axiosWrapperService
        .axiosPost(
          `/addApplicationNote/${associatedModelKey}/${applicationId}`,
          {
            content: noteContent,
            message_mode: messageMode
          }
        )
        .then(() => {
          commit("incrementUpdateCounter");
          commit("setTimelineNotesSuccess", "Successfully added Notes.");
          commit("resetBusy");
        })
        .catch(error => {
          commit("setTimelineNotesError", formatErrorMessage(error));
        });
    } catch (e) {
      commit("setTimelineNotesError", "Failed to get the TimeLine Notes.");
      commit("resetBusy");
    }
  },
  submitFollowUpNotes({ commit }, { noteContent, messageMode, followUpUuid }) {
    try {
      commit("clearTimelineNotesMessages");
      axiosWrapperService
        .axiosPost("/addFollowUpNote/" + followUpUuid, {
          content: noteContent,
          message_mode: messageMode
        })
        .then(() => {
          commit("incrementUpdateCounter");
          commit("setTimelineNotesSuccess", "Successfully added Notes.");
          commit("resetBusy");
        })
        .catch(error => {
          commit("setTimelineNotesError", formatErrorMessage(error));
        });
    } catch (e) {
      commit("setTimelineNotesError", "Failed to get the TimeLine Notes.");
      commit("resetBusy");
    }
  }
};

// mutations
const mutations = {
  toggleNoteEnabled(state) {
    state.noteEnabled = !state.noteEnabled;
  },
  setBusy(state) {
    state.busy = true;
  },
  resetBusy(state) {
    state.busy = false;
  },
  toggleIsGlobalNotesDisplayed(state) {
    state.isGlobalNotesDisplayed = !state.isGlobalNotesDisplayed;
  },
  resetIsGlobalNotesDisplayed(state) {
    state.isGlobalNotesDisplayed = false;
  },
  incrementUpdateCounter(state) {
    state.timelineNotesUpdateCounter++;
  },
  setGettingNotes(state) {
    state.gettingNotes = true;
  },
  resetGettingNotes(state) {
    state.gettingNotes = false;
  },
  setSmsTemplates(state, smsTemplates) {
    state.smsTemplates = smsTemplates;
  },
  setTimelineNotesList(state, timelineNotesList) {
    state.timelineNotesList = timelineNotesList;
  },
  setTimelineNotesError(state, timelineNotesErrorMessage) {
    state.timelineNotesErrorMessage = timelineNotesErrorMessage;
  },
  setTimelineNotesSuccess(state, timelineNotesSuccessMessage) {
    state.timelineNotesSuccessMessage = timelineNotesSuccessMessage;
  },
  setApplicationId(state, applicationId) {
    state.applicationId = applicationId;
  },
  setAssociatedModelKey(state, associatedModelKey) {
    state.associatedModelKey = associatedModelKey;
  },
  clearTimelineNotesMessages(state) {
    state.timelineNotesErrorMessage = "";
    state.timelineNotesAlertMessage = "";
    state.timelineNotesSuccessMessage = "";
  },
  clearTimelineNotesAlertMessages(state) {
    state.timelineNotesAlertMessage = "";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
