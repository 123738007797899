import { useStore } from "@/store";
import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import router from "@/router";

export default function() {
  const store = useStore();
  localStorage.removeItem("access_token");
  localStorage.removeItem("user");
  store.commit("auth/clearToken");
  axiosWrapperService.axiosPurgeAuthorizationHeader();
  router.push("/login");
}
