export function formatErrorMessage(caughtError, asHTML = false) {
  if (caughtError.response !== undefined) {
    if (caughtError.response.data !== undefined) {
      if (caughtError.response.status >= 500) {
        return "Something went wrong. Please try again.";
      }

      if (caughtError.response.data.errors !== undefined) {
        if (
          Array.isArray(caughtError.response.data.errors) ||
          (typeof caughtError.response.data.errors === "object" &&
            caughtError.response.data.errors !== null &&
            caughtError.response.data.errors !== undefined)
        ) {
          return asHTML
            ? getHTMLFormattedMessage(caughtError.response.data.errors)
            : getNormalFormattedMessage(caughtError.response.data.errors);
        } else {
          return caughtError.response.data.errors;
        }
      }

      if (caughtError.response.data.message !== undefined) {
        if (caughtError.response.data.message !== "") {
          return caughtError.response.data.message;
        } else {
          return "Something went wrong. Please try again.";
        }
      }

      if (caughtError.response.data.error !== undefined) {
        if (Array.isArray(caughtError.response.data.error)) {
          return asHTML
            ? getHTMLFormattedMessage(caughtError.response.data.error)
            : getNormalFormattedMessage(caughtError.response.data.error);
        } else if (
          caughtError.response.data.error !== null &&
          typeof caughtError.response.data.error === "object"
        ) {
          return asHTML
            ? getHTMLFormattedMessage(caughtError.response.data.error)
            : getNormalFormattedMessage(caughtError.response.data.error);
        } else {
          return caughtError.response.data.error;
        }
      }
    }
  }

  if (caughtError.message !== undefined) {
    return caughtError.message;
  }
}

function getHTMLFormattedMessage(messageArray) {
  let formattedMessage = "<ul style='color: red'>";
  for (let k in messageArray) {
    if (
      typeof messageArray[k] === "object" &&
      messageArray[k] !== null &&
      messageArray[k] !== undefined
    ) {
      for (let l in k) {
        if (messageArray[k][l] !== null && messageArray[k][l] !== undefined) {
          formattedMessage += "<li>" + messageArray[k][l].trim() + "</li>";
        }
      }
    } else if (
      typeof messageArray[k] === "string" ||
      messageArray[k] instanceof String
    ) {
      formattedMessage += "<li>" + messageArray[k].trim() + "</li>";
    }
  }
  return formattedMessage + "</ul>";
}

function getNormalFormattedMessage(messageArray) {
  let formattedMessage = "";

  if (Array.isArray(messageArray)) {
    messageArray.forEach(function(message) {
      formattedMessage += message + ".";
    });
  } else {
    for (let key in messageArray) {
      formattedMessage += messageArray[key][0] + ". ";
    }
  }

  return formattedMessage;
}
