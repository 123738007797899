import { computed, ref, watch } from "vue";
import { useStore } from "@/store";

export const dateNoteForm = () => {
  const store = useStore();

  function getFormDefaults() {
    return {
      date: null,
      notes: null
    };
  }

  function clearForm() {
    form.value = getFormDefaults();
  }
  const form = ref(getFormDefaults());

  function submitForm(form) {
    store.dispatch("paymentScheduleDateNoteForm/submit", form);
  }

  const isProcessing = computed(
    () => store.state.paymentScheduleDateNoteForm.isProcessing
  );
  const validationErrors = computed(
    () => store.state.paymentScheduleDateNoteForm.validationErrors
  );
  const errorMessage = computed(
    () => store.state.paymentScheduleDateNoteForm.errorMessage
  );

  const type = computed(() => store.state.paymentScheduleDateNoteForm.type);

  const dateNoteFormSuccessCount = computed(
    () => store.state.paymentScheduleDateNoteForm.dateNoteFormSuccessCount
  );

  watch(
    () => dateNoteFormSuccessCount.value,
    () => {
      clearForm();
    }
  );

  return {
    form,
    submitForm,
    validationErrors,
    errorMessage,
    isProcessing,
    type
  };
};
