<template>
  <div>
    <div
      :class="[
        { 'bg-theme-32': isActive },
        `loan-${getActiveApplicationQualityClass()}`
      ]"
      class="flex mt-1 p-4 box cursor-pointer section-header"
      @click="setNavActiveSection('loan-rebuttal-title')"
    >
      <h2
        id="loan-rebuttal-title"
        class="font-medium text-theme-7 text-base w-full"
      >
        LOAN REBUTTALS
      </h2>
      <ChevronDownIcon :class="{ 'transform rotate-180': !isActive }" />
    </div>
    <!-- BEGIN: Striped Rows -->
    <div v-if="isActive">
      <div v-if="!busy" class="box" style="z-index: 0;">
        <div id="doc-req-table" class="p-5">
          <div class="preview">
            <div class="overflow-x-auto">
              <table class="table">
                <thead>
                  <tr>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Title
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Description
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Added By
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Created At
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody v-if="loanRebuttalList.length > 0">
                  <tr
                    v-for="(lnRebuttal, lnRebuttalIndex) in loanRebuttalList"
                    :key="lnRebuttalIndex"
                    :class="{
                      'bg-gray-200 dark:bg-dark-1': lnRebuttalIndex % 2 > 0
                    }"
                  >
                    <td class="border-b dark:border-dark-5">
                      {{ lnRebuttal.category_title }}
                    </td>
                    <td class="border-b dark:border-dark-5 max-w-xs mx-auto">
                      {{ lnRebuttal.rebuttal_content }}
                    </td>
                    <td class="border-b dark:border-dark-5 max-w-xs mx-auto">
                      {{ lnRebuttal.admin.fullname }}
                    </td>
                    <td class="border-b dark:border-dark-5 max-w-xs mx-auto">
                      {{ lnRebuttal.created_at }}
                    </td>
                    <td class="border-b dark:border-dark-5">
                      <button
                        class="btn bg-red-400 xs:w-16 w-32 text-white"
                        @click.prevent="
                          onRebuttalOnLoanRemoveSubmit(lnRebuttal.uuid)
                        "
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                </tbody>
                <noRecordCustTblTemplate v-else />
              </table>
            </div>
          </div>
        </div>
        <div
          class="
            flex flex-col
            sm:flex-row
            items-center
            p-5
            border-b border-gray-200
          "
        >
          <div class="w-full sm:w-auto flex sm:ml-auto mt-1 sm:mt-0">
            <button
              class="btn btn-rounded bg-theme-3 w-1/2 text-white sm:w-auto mr-2"
              type="button"
              @click="showNewRebuttalFormModal(application_id)"
            >
              <PlusCircleIcon class="w-6 h-6 mr-2" />
              Attach New Rebuttal
            </button>
          </div>
        </div>
      </div>
      <FetchingRecordDivTemplate v-else marginTop="0" />
    </div>
    <!-- END: Striped Rows -->
    <!-- BEGIN: Modal Content -->
    <div
      id="add-new-rebuttal-modal"
      aria-hidden="true"
      class="modal"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">Add Rebuttal</h2>
          </div>
          <div
            v-if="newRebuttalFormValidationMessage !== ''"
            class="alert alert-danger-soft show flex items-center mb-2"
            role="alert"
          >
            <AlertOctagonIcon class="w-6 h-6 mr-2" />
            {{ newRebuttalFormValidationMessage }}
            <button
              class="btn-close"
              type="button"
              @click="clearLoanRebuttalMessages"
            >
              <XIcon class="w-4 h-4" />
            </button>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="modal-body grid grid-cols-12">
            <div class="col-span-12">
              <label class="form-label"> Rebuttal Type:</label>
              <select
                id="rebuttal-template-category-select"
                aria-label=".form-select-sm example"
                class="form-select form-select-sm mt-2"
                @change="onChangeRebuttalCategoryType($event)"
              >
                <option value="">None</option>
                <option
                  v-for="(rebuttalCategory,
                  rebuttalCategoryIndex) in rebuttalCategoryList"
                  :key="rebuttalCategoryIndex"
                  :value="rebuttalCategory.slug"
                >
                  {{ rebuttalCategory.title }}
                </option>
              </select>
            </div>
            <div
              v-if="rebuttalTemplateList.length > 0"
              class="col-span-12 mt-2 max-h-96 overflow-y-scroll"
            >
              <label class="form-label">
                Templates ({{ rebuttalTemplateList.length }} available):</label
              >
              <div
                v-for="(rebuttalTemplate,
                rebuttalTemplateIndex) in rebuttalTemplateList"
                :key="rebuttalTemplateIndex"
                :class="{
                  'bg-theme-18':
                    newRebuttalFormObject.slug === rebuttalTemplate.slug
                }"
                class="
                  text-slate-500 text-justify
                  mt-1
                  border-dark-1 border-2
                  rounded-md
                  p-2
                "
                @click="pickRebuttalTemplate(rebuttalTemplate.slug)"
              >
                {{ rebuttalTemplate.content }}
              </div>
            </div>
            <div class="col-span-12 mt-2">
              <label class="form-label"> Description:</label>
              <textarea
                id="modal-form-1"
                v-model="newRebuttalFormObject.rebuttal_content"
                class="form-control"
                rows="7"
                type="text"
              >
              </textarea>
            </div>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer text-right">
            <button
              class="btn btn-dark-soft w-20 mr-1"
              data-dismiss="modal"
              type="button"
            >
              Cancel
            </button>
            <button
              class="btn btn-success w-40"
              type="button"
              @click="onNewRebuttalOnLoanSubmit"
            >
              Submit
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
    <!-- END: Modal Content -->
  </div>
</template>

<script>
import { computed, defineComponent, reactive, ref } from "vue";
import { useStore } from "@/store";
import noRecordCustTblTemplate from "@/components/no-record-tbl-template/Main.vue";
import { customSwalMessageBuilder } from "@/composables/utils/customSwalMessageBuilder";
import Swal from "sweetalert2";
import FetchingRecordDivTemplate from "@/components/fetching-record-div-template/Main";
import { loanQuality } from "@/composables/loanQuality";

export default defineComponent({
  components: {
    noRecordCustTblTemplate,
    FetchingRecordDivTemplate
  },
  props: {
    applicationId: {
      type: Number,
      default: null,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false,
      required: true
    },
    setNavActiveSection: {
      type: Function,
      require: true,
      default: null
    }
  },
  setup(props) {
    const store = useStore();

    const { buildSwalWarning } = customSwalMessageBuilder();

    const rebuttalCategoryList = computed(
      () => store.state.loanRebuttals.rebuttalCategoryList
    );
    const loanRebuttalList = computed(
      () => store.state.loanRebuttals.loanRebuttalList
    );

    const rebuttalTemplateList = computed(
      () => store.state.loanRebuttals.rebuttalTemplateList
    );

    const busy = computed(() => store.state.loanRebuttals.busy);
    const newRebuttalFormObject = reactive({
      rebuttal_content: "",
      slug: ""
    });
    const rebuttalCategoryTypeContentTemplatePlaceholderValues = reactive({
      content: ""
    });

    function resetRebuttalCategoryTypeContentTemplatePlaceholderValues() {
      rebuttalCategoryTypeContentTemplatePlaceholderValues.content = "";
    }

    function showNewRebuttalFormModal() {
      resetDocumentRequestFormValidationMessage();
      newRebuttalFormObject.rebuttal_content = "";
      newRebuttalFormObject.slug = "";
      document.getElementById(
        "rebuttal-template-category-select"
      ).selectedIndex = 0;
      store.commit("loanRebuttals/clearRebuttalTemplateList");
      cash("#add-new-rebuttal-modal").modal("show");
    }

    function onChangeRebuttalCategoryType(event) {
      resetRebuttalCategoryTypeContentTemplatePlaceholderValues();
      resetNewRebuttalFormObject();
      parseAndSetTemplatesFromRebuttalCategory(event.target.value);
      //loadTemplateAsPerType();
    }

    function parseAndSetTemplatesFromRebuttalCategory(categorySlug) {
      store.commit(
        "loanRebuttals/clearAndPushToRebuttalTemplateList",
        rebuttalCategoryList.value.filter(item => item.slug === categorySlug)[0]
          .rebuttal_templates
      );
    }

    function resetNewRebuttalFormObject() {
      newRebuttalFormObject.rebuttal_content = "";
      newRebuttalFormObject.slug = "";
    }

    const newRebuttalFormValidationMessage = ref("");

    function resetDocumentRequestFormValidationMessage() {
      newRebuttalFormValidationMessage.value = "";
    }

    function clearLoanRebuttalMessages() {
      resetDocumentRequestFormValidationMessage();
      store.commit("loanRebuttals/clearLoanRebuttalMessages");
    }

    function validateDocumentRequestForm() {
      resetDocumentRequestFormValidationMessage();
      if (newRebuttalFormObject.slug === "") {
        newRebuttalFormValidationMessage.value =
          " Rebuttal Template must be selected !";
      }
      if (newRebuttalFormObject.rebuttal_content === "") {
        newRebuttalFormValidationMessage.value +=
          " Rebuttal Description cannot be empty !";
      }
      if (newRebuttalFormValidationMessage.value !== "") {
        return false;
      }
      return true;
    }

    function onNewRebuttalOnLoanSubmit() {
      if (validateDocumentRequestForm()) {
        store.commit("main/setFullPageLoading");
        store.dispatch("loanRebuttals/addRebuttalToLoan", {
          applicationId: props.applicationId,
          formData: newRebuttalFormObject
        });
        document.getElementById(
          "rebuttal-template-category-select"
        ).selectedIndex = 0;

        resetNewRebuttalFormObject();
        resetDocumentRequestFormValidationMessage();
        store.commit("loanRebuttals/clearAndPushToRebuttalTemplateList", []);
        cash("#add-new-rebuttal-modal").modal("hide");
      }
    }

    function onRebuttalOnLoanRemoveSubmit(uuid) {
      Swal.fire(buildSwalWarning("Are you sure to remove?", true)).then(
        confirmed => {
          if (confirmed.isConfirmed) {
            store.commit("main/setFullPageLoading");
            store.dispatch("loanRebuttals/deleteExistingRebuttalOnLoan", {
              uuid: uuid,
              applicationId: props.applicationId
            });
          }
        }
      );
    }

    function pickRebuttalTemplate(slug) {
      newRebuttalFormObject.slug = slug;
      newRebuttalFormObject.rebuttal_content = newRebuttalFormObject.rebuttal_content = rebuttalTemplateList.value.filter(
        item => item.slug === slug
      )[0].content;
    }

    init();

    function init() {
      store.dispatch(
        "loanRebuttals/getApplicableRebuttalTemplateCategoriesForLoan",
        { applicationId: props.applicationId, successMessage: "" }
      );
      store.dispatch("loanRebuttals/getRebuttalsOnLoan", {
        applicationId: props.applicationId,
        successMessage: ""
      });
    }

    const { getActiveApplicationQualityClass } = loanQuality();

    return {
      busy,
      rebuttalCategoryList,
      loanRebuttalList,
      rebuttalTemplateList,
      pickRebuttalTemplate,
      showNewRebuttalFormModal,
      onChangeRebuttalCategoryType,
      onNewRebuttalOnLoanSubmit,
      onRebuttalOnLoanRemoveSubmit,
      clearLoanRebuttalMessages,
      newRebuttalFormValidationMessage,
      newRebuttalFormObject,
      getActiveApplicationQualityClass
    };
  }
});
</script>
