import axios from "axios";

const state = () => {
  return {
    payerBankAccountList: [],
    newBankAccountInstance: {},
    busyPayerBankAccountListPage: false,
    payerBankAccErrorMessage: "",
    payerBankAccSuccessMessage: ""
  };
};

// getters
const getters = {
  payerBankAccountList: state => state.payerBankAccountList,
  newBankAccountInstance: state => state.newBankAccountInstance,
  busyPayerBankAccountListPage: state => state.busyPayerBankAccountListPage,
  payerBankAccErrorMessage: state => state.payerBankAccErrorMessage,
  payerBankAccSuccessMessage: state => state.payerBankAccSuccessMessage
};

// actions
const actions = {
  getPayerBankAccountList: async ({ rootState, commit }) => {
    try {
      commit("setBusy");
      commit("clearError");
      // add to axios defaults headers common
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${rootState.auth.accessToken}`;
      axios
        .get(process.env.VUE_APP_BASE_URL + "/getAllSplitPayerBankAccounts")
        .then(response => {
          commit("setPayerBankAccountList", response.data.data);
        });
    } catch (e) {
      commit("setError", "Failed to login");
    } finally {
      commit("clearBusy");
    }
  }
};

// mutations
const mutations = {
  setPayerBankAccountList(state, list) {
    state.payerBankAccountList = list;
  },
  setError(state, payerBankAccErrorMessage) {
    state.payerBankAccErrorMessage = payerBankAccErrorMessage;
  },
  clearError(state) {
    state.payerBankAccErrorMessage = "";
  },
  setBusy(state) {
    state.busyPayerBankAccountListPage = true;
  },
  clearBusy(state) {
    state.busyPayerBankAccountListPage = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
