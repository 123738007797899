export function getValuesInBetween({
  records,
  start,
  end,
  reverse = false,
  key = "id"
}) {
  let result = [];

  if (!end) {
    result.push(start);
  } else {
    let startPush = false;
    //for future use
    // if (reverse) {
    //Reverse the array
    // }
    for (let entry of records) {
      if (startPush) {
        result.push(entry[key]);
      }
      //Conditions to start and stop
      if (entry[key] === start) {
        startPush = true;
      } else if (entry[key] === end) {
        startPush = false;
      }
    }
  }
  return result;
}
