<template>
  <div id="req-new-doc-modal" aria-hidden="true" class="modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base mr-auto">
            Request For Documents
          </h2>
        </div>
        <div
          v-if="documentRequestFormValidationMessage !== ''"
          class="alert alert-danger-soft show flex items-center mb-2"
          role="alert"
        >
          <AlertOctagonIcon class="w-6 h-6 mr-2" />
          {{ documentRequestFormValidationMessage }}
          <button class="btn-close" type="button" @click="clearDocReqMessages">
            <XIcon class="w-4 h-4" />
          </button>
        </div>
        <!-- END: Modal Header -->
        <!-- BEGIN: Modal Body -->
        <div class="modal-body grid grid-cols-12">
          <div class="col-span-12">
            <label class="form-label"> Document Type:</label>
            <select
              v-model="newDocReqFormObject.type"
              aria-label=".form-select-sm example"
              class="form-select form-select-sm mt-2"
              @change="onChangeDocumentType()"
            >
              <option value="">None</option>
              <option
                v-for="(docType, docTypeIndex) in documentTypeList"
                :key="docTypeIndex"
                :value="docType.slug"
              >
                {{ docType.name }}
              </option>
            </select>
          </div>
          <div
            v-if="elementStructure.type === 'options'"
            class="col-span-12 mt-2"
          >
            <div class="col-span-12">
              <label class="form-label"> {{ elementStructure.title }}:</label>
              <select
                v-model="docSubTypeElement.index"
                aria-label=".form-select-sm example"
                class="form-select form-select-sm mt-2"
                @change="onSubTypeChange()"
              >
                <option
                  v-for="(item, itemIndex) in elementStructure.items"
                  :key="itemIndex"
                  :value="itemIndex"
                >
                  {{ item.formElement.label }}
                </option>
              </select>
            </div>
            <div
              v-if="
                newDocReqFormObject.type === 'recent_payslip' &&
                  docSubTypeElement.structure
              "
              class="col-span-12 mt-2"
            >
              <label class="form-label">Employer (Optional):</label>
              <input
                v-model="elementStructure.dependency"
                aria-describedby="input-group-3"
                class="flex form-control my-2"
                placeholder="Name of Employer"
                type="text"
                @change="updateDescriptionDependency"
                @input="updateDescriptionDependency"
              />
            </div>
            <div
              v-if="newDocReqFormObject.description"
              class="col-span-12 mt-2"
            >
              <label class="form-label">
                {{ elementStructure.title }}
                {{
                  docSubTypeElement?.structure?.description?.required
                    ? ""
                    : "(Optional)"
                }}:</label
              >
              <textarea
                id="modal-form-1"
                v-model="newDocReqFormObject.description"
                class="form-control"
                rows="5"
                type="text"
              >
              </textarea>
            </div>
          </div>
          <div v-if="elementStructure.type === 'textbox'" class="col-span-12">
            <template v-if="docSubTypeElement.structure">
              <!--  Setting options for textboxes  -->
              <div v-if="docSubTypeElement.structure.formElement !== undefined">
                <label class="flex col-span-12 my-2">{{
                  docSubTypeElement.structure.formElement.label !== "Document"
                    ? docSubTypeElement.structure.formElement.label
                    : `${docSubTypeElement.structure.formElement.label} Required`
                }}</label>
                <input
                  v-model="elementStructure.dependency"
                  aria-describedby="input-group-3"
                  class="flex form-control my-2"
                  placeholder=""
                  type="text"
                  @change="updateDescriptionDependency"
                  @input="updateDescriptionDependency"
                />
              </div>
              <div v-if="docSubTypeElement.structure.description !== undefined">
                <template v-if="newDocReqFormObject.type === 'loan_closure'">
                  <div class="flex row col-span-12 mt-2">
                    <div class=" w-1/2">
                      <label class="flex col-span-12 my-2"
                        >Next Review Date:</label
                      >
                      <input
                        v-model="newDocReqFormObject.next_review_date"
                        class="form-control w-4/5"
                        type="date"
                      />
                    </div>
                    <div
                      v-if="docSubTypeElement.structure.description.hints.lite"
                      class=" w-1/2"
                    >
                      <small>{{
                        docSubTypeElement.structure.description.hints.lite
                      }}</small>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="flex row">
                    <div
                      v-if="
                        typeof docSubTypeElement.structure.description.hints
                          .lite === 'string' &&
                          docSubTypeElement.structure.description.hints.lite
                            .length
                      "
                      class="col-span-12 px-2 py-2"
                    >
                      <small>{{
                        docSubTypeElement.structure.description.hints.lite
                      }}</small>
                    </div>
                  </div>
                </template>
                <template v-if="newDocReqFormObject.description">
                  <label class="flex col-span-12 my-2"
                    >{{ docSubTypeElement.structure.description.title
                    }}{{
                      docSubTypeElement.structure.description.required
                        ? ""
                        : "(Optional)"
                    }}
                  </label>
                  <textarea
                    v-model="newDocReqFormObject.description"
                    class="form-control"
                    rows="7"
                    type="text"
                  />
                </template>
              </div>
            </template>
          </div>
        </div>
        <!-- END: Modal Body -->
        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer text-right">
          <button
            class="btn btn-dark-soft w-20 mr-1"
            data-dismiss="modal"
            type="button"
          >
            Cancel
          </button>
          <button
            class="btn btn-success w-40"
            type="button"
            @click="preparePayloadAndSubmitDocReq"
          >
            Submit
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, watch, computed } from "vue";
import { useStore } from "@/store";
import DocReqHandler from "@/utils/DocumentRequests/Handler";

export default defineComponent({
  components: {},
  props: {
    documentTypeList: {
      type: Array,
      required: true
    },
    onNewDocumentReqSubmit: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const StructureHandler = DocReqHandler;

    const loans = computed(
      () => store.state.loanAssessmentInfos.aggregatorPersonalLoanDetails
    );

    const documentRequestFormValidationMessage = ref("");

    const realTimeItemFetch = ref(false);

    const keepDescriptionOpen = ref(false);

    const newDocReqFormObject = reactive({
      description: null,
      next_review_date: "",
      type: "",
      description_required: false
    });

    const docSubTypeElement = reactive({
      index: 0,
      type: "",
      structure: null
    });

    const elementStructure = reactive({
      type: "",
      title: "",
      optionalText: "",
      items: [],
      description: false,
      textTitle: "",
      hint: "",
      dependency: ""
    });

    watch(
      () => newDocReqFormObject.description,
      description => {
        if (
          keepDescriptionOpen.value &&
          typeof description === "string" &&
          !description.length
        ) {
          newDocReqFormObject.description = " ";
        }
      }
    );

    //Update personal loans for structure
    watch(
      () => loans.value,
      val => StructureHandler.updateDependency()["loans"](val)
    );

    function resetForm() {
      resetNewDocReqFormObject();
      resetSubTypeElementObject();
      clearDocReqMessages();
      elementStructure.type = "";
    }

    function clearDocReqMessages() {
      resetDocumentRequestFormValidationMessage();
      store.commit("lnAppDocReq/clearDocumentRequestMessages");
    }

    function resetDocumentRequestFormValidationMessage() {
      documentRequestFormValidationMessage.value = "";
    }

    function onChangeDocumentType() {
      //Changes as per new dashboard types -- resetting
      resetValidationFields();
      resetSubTypeElementObject();

      //Changing properties
      StructureHandler.activeType = newDocReqFormObject.type;
      const {
        type,
        title,
        items,
        realTimeFetch
      } = StructureHandler.convertTypeToSubStructure();
      elementStructure.type = type;
      elementStructure.title = title;
      elementStructure.items = items;
      realTimeItemFetch.value = realTimeFetch;
      keepDescriptionOpen.value = true;

      //Type based operations
      if (type === "textbox") {
        setTextBoxStructure();
      } else {
        docSubTypeElement.index = 0;
        this.onSubTypeChange();
      }
    }

    async function setTextBoxStructure() {
      if (realTimeItemFetch.value) {
        docSubTypeElement.structure = StructureHandler.fetchLatestItemFromStructure();
      } else {
        docSubTypeElement.structure = elementStructure.items[0];
      }
      //Setting dependency
      elementStructure.dependency = "";
      if ("log" in docSubTypeElement.structure) {
        newDocReqFormObject.description = " ";
      } else {
        newDocReqFormObject.description =
          docSubTypeElement.structure.description.default;
      }
    }

    async function onSubTypeChange() {
      resetElementStructureObject();
      docSubTypeElement.structure =
        elementStructure.items[docSubTypeElement.index];
      if (docSubTypeElement.structure) {
        docSubTypeElement.type = docSubTypeElement.structure.formElement.type;
        elementStructure.title = docSubTypeElement.structure.description.title;
        //Setting text area content
        if (
          docSubTypeElement.structure.description.title === "Question Details"
        ) {
          newDocReqFormObject.description =
            docSubTypeElement.structure.description.default;
        } else {
          newDocReqFormObject.description = " ";
        }
      }
    }

    function updateDescriptionDependency() {
      docSubTypeElement.structure.formElement.val = elementStructure.dependency;
    }

    function resetSubTypeElementObject() {
      docSubTypeElement.index = 0;
      docSubTypeElement.type = "";
      docSubTypeElement.structure = null;
      //Reset element structure
      resetElementStructureObject();
    }

    function resetElementStructureObject() {
      // elementStructure.type = "";
      elementStructure.description = false;
      elementStructure.textTitle = "";
      newDocReqFormObject.description = null;
      elementStructure.dependency = "";
      elementStructure.hint = "";
      elementStructure.textTitle = "";
      elementStructure.description = false;
    }

    function resetValidationFields() {
      newDocReqFormObject.description_required = false;
      newDocReqFormObject.next_review_date = "";
      documentRequestFormValidationMessage.value = "";
    }

    function resetNewDocReqFormObject() {
      newDocReqFormObject.type = "";
      newDocReqFormObject.description = null;
      newDocReqFormObject.next_review_date = "";
      newDocReqFormObject.description_required = false;
      keepDescriptionOpen.value = false;
    }

    function validateNewDocReqForm() {
      if (newDocReqFormObject.type === "") {
        documentRequestFormValidationMessage.value =
          "Document Type Field is required !";
        return false;
      }

      let result = StructureHandler.basicStructureValidateFails(
        docSubTypeElement.structure,
        newDocReqFormObject,
        elementStructure.title
      );

      if (!result.status) {
        documentRequestFormValidationMessage.value = result.message;
        return false;
      }
      //Special case validations
      if (
        Object.prototype.toString.call(docSubTypeElement.structure) ===
          "[object Object]" &&
        "log" in docSubTypeElement.structure &&
        !docSubTypeElement.structure.log.trim().length &&
        typeof elementStructure.dependency === "string" &&
        !elementStructure.dependency.trim().length
      ) {
        documentRequestFormValidationMessage.value = `${elementStructure.title} field is required!`;
        return false;
      }

      //Add validation to check structure of hints and description
      if (
        docSubTypeElement.structure?.description?.required &&
        !newDocReqFormObject.description?.trim().length
      ) {
        documentRequestFormValidationMessage.value = `${docSubTypeElement.structure.description.title} is required!`;
      }

      //Final condition
      if (documentRequestFormValidationMessage.value !== "") {
        return false;
      }

      return true;
    }

    function preparePayloadAndSubmitDocReq() {
      clearDocReqMessages();
      if (validateNewDocReqForm()) {
        //Setting description for recent payslip if employer not defined
        newDocReqFormObject.hints =
          docSubTypeElement.structure?.description?.hints;
        if (
          newDocReqFormObject.type === "recent_payslip" &&
          elementStructure.type === "options" &&
          typeof elementStructure.dependency === "string" &&
          !elementStructure.dependency.trim().length
        ) {
          newDocReqFormObject.hints.full = StructureHandler.replacementHints[
            newDocReqFormObject.type
          ](newDocReqFormObject.hints.full);
          newDocReqFormObject.hints.var = "current employer";
        }
        //Setting the default structure if does not exists
        if (
          newDocReqFormObject.description.length &&
          typeof newDocReqFormObject.hints?.full === "undefined"
        ) {
          newDocReqFormObject.hints = {
            var: undefined,
            lite: undefined,
            full: newDocReqFormObject.description
          };
        }

        if (docSubTypeElement.structure?.description?.required) {
          newDocReqFormObject.description_required = true;
        }

        props.onNewDocumentReqSubmit(newDocReqFormObject);
        StructureHandler.resetStructure();
      }
    }

    return {
      newDocReqFormObject,
      onChangeDocumentType,
      elementStructure,
      docSubTypeElement,
      onSubTypeChange,
      resetForm,
      preparePayloadAndSubmitDocReq,
      documentRequestFormValidationMessage,
      clearDocReqMessages,
      updateDescriptionDependency
    };
  }
});
</script>
