<template>
  <div>
    <div
      :class="[
        { 'bg-theme-32': isActive },
        `loan-${getActiveApplicationQualityClass()}`
      ]"
      class="flex mt-1 p-4 box cursor-pointer section-header"
      @click="setNavActiveSection('payment-detail-title')"
    >
      <h2
        id="payment-detail-title"
        class="font-medium text-theme-7 text-base w-full"
      >
        PAYMENT CARDS
      </h2>
      <ChevronDownIcon :class="{ 'transform rotate-180': !isActive }" />
    </div>
    <div v-if="isActive" id="card-dts-section" class="grid grid-cols-2 gap-6">
      <template v-if="!busy">
        <div class="grid grid-cols-12">
          <div class="col-span-12">
            <!-- BEGIN: Personal Information -->
            <div class="box h-full">
              <div class="p-5">
                <div class="grid grid-cols-12 gap-x-5">
                  <div class="col-span-12">
                    <h4 class="font-medium text-theme-3 text-base mb-5">
                      Detected Card
                    </h4>
                    <div class="overflow-y-auto">
                      <table class="table">
                        <thead>
                          <tr>
                            <th
                              class="
                                border-b-2
                                dark:border-dark-5
                                whitespace-nowrap
                              "
                            >
                              Card Number
                            </th>
                            <th
                              class="
                                border-b-2
                                dark:border-dark-5
                                whitespace-nowrap
                              "
                            >
                              Account #
                            </th>
                            <th
                              class="
                                border-b-2
                                dark:border-dark-5
                                whitespace-nowrap
                              "
                            >
                              Source
                            </th>
                            <th
                              class="
                                border-b-2
                                dark:border-dark-5
                                whitespace-nowrap
                              "
                            >
                              Last Transaction
                            </th>
                            <th
                              class="
                                border-b-2
                                dark:border-dark-5
                                whitespace-nowrap
                              "
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(detectedCard,
                            detectedCardIndex) in detectedPaymentCards"
                            :key="detectedCardIndex"
                          >
                            <td class="border-b dark:border-dark-5">
                              {{ detectedCard.card_number }}
                            </td>
                            <td class="border-b dark:border-dark-5">
                              {{ detectedCard.account_number }}
                            </td>
                            <td class="border-b dark:border-dark-5">
                              {{
                                detectedCard.source.charAt(0).toUpperCase() +
                                  detectedCard.source.slice(1)
                              }}
                            </td>
                            <td class="border-b dark:border-dark-5">
                              {{ detectedCard.last_transaction_date ?? "N/A" }}
                            </td>
                            <td class="border-b dark:border-dark-5">
                              <button class="btn bg-theme-15 w-32 text-white">
                                Available
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- END: Personal Information -->
          </div>
        </div>
        <div class="grid grid-cols-12 gap-6">
          <div class="col-span-12">
            <!-- BEGIN: Personal Information -->
            <div class="box h-full">
              <div class="p-5">
                <div class="grid grid-cols-12 gap-x-5">
                  <div class="col-span-12">
                    <h4 class="font-medium text-theme-3 text-base mb-5">
                      Available Cards
                    </h4>
                    <div class="overflow-y-auto">
                      <table class="table text-center">
                        <thead>
                          <tr>
                            <th
                              class="
                                border-b-2
                                dark:border-dark-5
                                whitespace-nowrap
                              "
                            >
                              Card Number
                            </th>
                            <th
                              class="
                                border-b-2
                                dark:border-dark-5
                                whitespace-nowrap
                              "
                            >
                              Verification
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(tokenizedCard,
                            tokenizedCardIndex) in tokenizedPaymentCards"
                            :key="tokenizedCardIndex"
                          >
                            <td class="border-b dark:border-dark-5">
                              {{ tokenizedCard.card }}
                            </td>
                            <td class="border-b dark:border-dark-5">
                              <button
                                v-if="
                                  !tokenizedCard.system_verified &&
                                    tokenizedCard.last_modified_by < 1
                                "
                                class="btn bg-theme-3 w-32 text-white"
                                @click="verifyTokenizedCard(tokenizedCard.card)"
                              >
                                Verify
                              </button>
                              <div v-else class="text-theme-20 ml-2">
                                <CheckCircleIcon class="h-5 w-5" width="16" />
                                <span class="text-[10px]">
                                  {{ tokenizedCard.verified_title }}
                                </span>
                              </div>
                              <!-- <LoanApplicationVerificationLink
                        :details="tokenizedCard.verification"
                        :operation="{
                          handler: verifyTokenizedCard,
                          payload: {
                            type: 'card',
                            value: tokenizedCard.card
                          }
                        }"
                      /> -->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END: Personal Information -->
            </div>
          </div>
        </div>
      </template>
      <FetchingRecordDivTemplate v-else marginTop="0" />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, watch } from "vue";
import { useStore } from "@/store";
import FetchingRecordDivTemplate from "@/components/fetching-record-div-template/Main";
import { loanQuality } from "@/composables/loanQuality";

export default defineComponent({
  components: {
    FetchingRecordDivTemplate
  },
  props: {
    applicationId: {
      type: Number,
      require: true
    },
    agentId: {
      type: Number,
      require: true
    },
    isActive: {
      type: Boolean,
      default: false,
      required: true
    },
    setNavActiveSection: {
      type: Function,
      require: true,
      default: null
    }
  },
  setup(props) {
    const store = useStore();

    const tokenizedPaymentCards = computed(
      () => store.state.paymentCards.tokenizedPaymentCards
    );

    const detectedPaymentCards = computed(
      () => store.state.paymentCards.detectedPaymentCards
    );

    const busy = computed(() => store.state.paymentCards.busy);

    loadCardDetails();

    const manuallyVerifiedSuccessCount = computed(
      () => store.state.paymentCards.manuallyVerifiedSuccessCount
    );

    watch(
      () => manuallyVerifiedSuccessCount.value,
      count => {
        if (count) {
          loadCardDetails();
        }
      }
    );

    function loadCardDetails() {
      store.dispatch("paymentCards/getPaymentCardsDetailsForLoanAssessment", {
        applicationId: props.applicationId
      });
    }

    function verifyTokenizedCard(tokenizedCard) {
      store.dispatch("paymentCards/manuallyVerifyTokenizedCard", {
        application_id: props.applicationId,
        admin_id: props.agentId,
        verified_data: tokenizedCard
      });
    }

    const { getActiveApplicationQualityClass } = loanQuality();

    return {
      tokenizedPaymentCards,
      detectedPaymentCards,
      verifyTokenizedCard,
      busy,
      getActiveApplicationQualityClass
    };
  }
});
</script>
