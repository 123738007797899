import DocReqStructure from "./Structure";
import moment from "moment";
import { reactive } from "vue";

function cloneWithGettersSetters(obj) {
  const clone = Array.isArray(obj) ? [] : {};

  Object.getOwnPropertyNames(obj).forEach(key => {
    const descriptor = Object.getOwnPropertyDescriptor(obj, key);
    if (descriptor.get || descriptor.set) {
      Object.defineProperty(clone, key, descriptor);
    } else if (typeof obj[key] === "object" && obj[key] !== null) {
      clone[key] = cloneWithGettersSetters(obj[key]);
    } else {
      clone[key] = obj[key];
    }
  });

  return clone;
}

class DocReqHandler {
  #_doc_types = [];
  #_list = [];
  activeType = null;
  structure = reactive(cloneWithGettersSetters(DocReqStructure));
  originalStructure = cloneWithGettersSetters(DocReqStructure);

  constructor() {
    this.#_doc_types = [...Object.keys(this.structure)];
    this.sortNameAndSlugsOfTypes();
  }

  get docReqList() {
    return this.#_list;
  }

  get types() {
    return this.#_doc_types;
  }

  get lenderDependentDocReq() {
    return {
      employer: {
        title: "Employer",
        values: ["recent_payslip"]
      },
      lenders: {
        title: "Lender(s)",
        values: [
          "statement_of_accounts",
          "loan_contract",
          "loan_repayment_schedule",
          "loan_closure"
        ]
      },
      document: {
        title: "Document Required",
        values: ["other_document"]
      }
    };
  }

  get replacementHints() {
    return {
      recent_payslip: function(hint) {
        hint = hint.replace("your employer ", "your current employer").trim();
        return hint;
      }
    };
  }

  async resetStructure() {
    this.structure = reactive(cloneWithGettersSetters(this.originalStructure));
    this.updateDependencyOnReset();
  }

  getActiveTypeStructure() {
    return this.structure[this.activeType];
  }

  async sortNameAndSlugsOfTypes() {
    for (let type in this.structure) {
      this.#_list.push({
        name: this.structure[type].name,
        type: type,
        slug: this.structure[type].slug
      });
    }
  }

  convertTypeToSubStructure() {
    let custom = this.structure[this.activeType];
    return {
      type: custom?.subType?.elementType,
      title: custom?.subType?.title,
      items: custom?.items,
      realTimeFetch: custom?.realTimeFetch
    };
  }

  fetchLatestItemFromStructure() {
    let item = this.structure[this.activeType].items[0];
    return {
      formElement: item.formElement,
      description: {
        required: item.description.required,
        title: item.description.title,
        default: item.description.default,
        hints: item.description.hints
      }
    };
  }

  updateDependencyOnReset() {
    const dependencyToUpdate = this.updateDependency();
    let keysToUpdate = ["loans"];
    keysToUpdate.forEach(function(key) {
      dependencyToUpdate[key](undefined);
    });
  }

  updateDependency() {
    const _this = this;
    return {
      loans: function(values) {
        if (typeof values === "undefined") {
          values = [];
        }
        if (
          typeof _this.personalLoans === "undefined" ||
          (Array.isArray(_this.personalLoans) && !_this.personalLoans.length)
        ) {
          _this.personalLoans = values;
        }
        let mappedValues = _this.personalLoans
          .map(loan => loan.loan_provider)
          .join(", ")
          .replace(/,([^,]+)$/, " and$1");
        _this.structure["loan_closure"]["items"][0][
          "description"
        ].hints.var = mappedValues;
        _this.structure["loan_closure"]["items"][0][
          "description"
        ].hints.lite = _this.structure["loan_closure"]["items"][0][
          "description"
        ].hints.lite.replace("[var]", mappedValues);
      }
    };
  }

  async handleDescription(descriptionObject) {
    let description = {
      required: false,
      title: "",
      full: "",
      lite: ""
    };
    if (
      typeof descriptionObject !== "undefined" &&
      descriptionObject.default &&
      descriptionObject.default.length
    ) {
      description.required = true;
      description.title = descriptionObject.title;
      description.full = descriptionObject.default;
    }

    //Checking if hints are required
    if (typeof descriptionObject.hints === "object") {
      if (descriptionObject.hints instanceof Promise) {
        try {
          let hints = await descriptionObject.hints;
          description = {
            ...description,
            ...hints,
            title: descriptionObject.title,
            required: !!descriptionObject.required
          };
        } catch (e) {}
      } else {
        if (typeof descriptionObject.hints.var === "undefined") {
          description = {
            ...description,
            full: descriptionObject.hints.full,
            lite: descriptionObject.hints.lite
          };
        }
      }
    }
    return description;
  }

  /**
   * @param structure
   * @param dataObject
   * @param field
   * @returns {{message: string, status: boolean}}
   */
  basicStructureValidateFails(structure, dataObject, field) {
    let result = {
      status: true,
      message: ""
    };
    if (structure === null || typeof structure === "undefined") {
      result.status = false;
      result.message = `${field} Field missing information !`;
    } else if (
      typeof structure === "object" &&
      !Object.keys(structure).length
    ) {
      result.status = false;
      result.message = `${field} Field missing information !`;
    } else if (
      structure.description?.required &&
      !dataObject.description.length
    ) {
      result.status = false;
      result.message = "Document Type description is required !";
    } else if (
      dataObject.type === "loan_closure" &&
      !dataObject.next_review_date.length
    ) {
      result.status = false;
      result.message = "Next review date is required !";
    }

    if (dataObject.type === "loan_closure") {
      if (dataObject.next_review_date === "") {
        result.status = false;
        result.message = "Next Review Date is required !";
      } else if (!moment(dataObject.next_review_date).isAfter(moment())) {
        result.status = false;
        result.message = "Next Review Date should be after today !";
      }
    }

    return result;
  }
}

export default new DocReqHandler();
