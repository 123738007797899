<template>
  <div class="grid grid-cols-12 gap-6">
    <div v-if="!isBusy" class="col-span-12">
      <div class="box">
        <div class="p-5">
          <div class="grid grid-cols-12 gap-x-5">
            <div class="col-span-12 p-5">
              <h4 class="font-medium text-theme-3 text-base mb-5">
                Skip Checks
              </h4>
              <div class="grid grid-cols-12">
                <label class="form-label col-span-5 mt-1.5">
                  <CreditCardIcon class="w-4 h-4" />
                  Card Verification:</label
                >
                <div
                  v-if="statuses.card === 'none'"
                  class="col-span-6 text-center"
                >
                  <button
                    class="btn bg-theme-3 w-48 text-white"
                    @click="skipCardVerification"
                  >
                    Skip Card Verification
                  </button>
                </div>
                <div
                  v-else-if="statuses.card === 'verified'"
                  class="col-span-6 text-center text-green-500 mt-1.5"
                >
                  <CheckCircleIcon class="w-4 h-4 mr-2" />
                  Verified
                </div>
                <div
                  v-else
                  class="col-span-6 text-center text-primary-11 mt-1.5"
                >
                  <FastForwardIcon class="w-4 h-4 mr-2" />
                  Skipped
                </div>
              </div>
              <div class="mt-3 grid grid-cols-12">
                <label class="form-label col-span-5 mt-1.5">
                  <HomeIcon class="w-4 h-4" />
                  Bank Authentication:</label
                >
                <div
                  v-if="statuses.auth === 'none'"
                  class="col-span-6 text-center"
                >
                  <button
                    class="btn bg-theme-3 w-52 text-white"
                    @click="skipBankVerification"
                  >
                    Skip Bank Authentication
                  </button>
                </div>
                <div
                  v-else-if="statuses.auth === 'verified'"
                  class="col-span-6 text-center text-green-500 mt-1.5"
                >
                  <CheckCircleIcon class="w-4 h-4 mr-2" />
                  Verified
                </div>
                <div
                  v-else
                  class="col-span-6 text-center text-primary-11 mt-1.5"
                >
                  <FastForwardIcon class="w-4 h-4 mr-2" />
                  Skipped
                </div>
              </div>
              <div class="mt-3 grid grid-cols-12">
                <label class="form-label col-span-5 mt-1.5">
                  <UsersIcon class="w-4 h-4 mr-2" />
                  Frankie ID Verification:</label
                >
                <div
                  v-if="statuses.id === 'agent_verified'"
                  class="col-span-6 text-center text-green-500 mt-1.5"
                >
                  <CheckCircleIcon class="w-4 h-4 mr-2" />
                  Agent Verified
                </div>
                <div
                  v-else-if="statuses.id === 'system_verified'"
                  class="col-span-6 text-center text-green-500 mt-1.5"
                >
                  <CheckCircleIcon class="w-4 h-4 mr-2" />
                  System Verified
                </div>
                <div
                  v-else-if="statuses.id === 'skipped'"
                  class="col-span-6 text-center text-green-500 mt-1.5"
                >
                  <FastForwardIcon class="w-4 h-4 mr-2" />
                  Skipped
                </div>
                <div
                  v-else
                  class="col-span-6 text-center text-primary-11 mt-1.5"
                >
                  <button
                    class="btn bg-theme-3 w-48 text-white"
                    @click="verifyIdDocuments"
                  >
                    Verify ID Documents
                  </button>
                </div>
              </div>
              <div class="mt-3 grid grid-cols-12">
                <label class="form-label col-span-5 mt-1.5 flex flex-row">
                  <UsersIcon class="w-4 h-4 mr-2" />
                  <div class="flex flex-col">
                    <span>Bankstatement Name Verification:</span>
                    <span class="italic text-xs">(ID vs Bank statement)</span>
                  </div>
                </label>
                <template v-if="!frankieCheckBusy">
                  <div
                    v-if="existsInSets(frankieStatus)"
                    class="col-span-6 text-center text-green-500 mt-1.5"
                  >
                    <CheckCircleIcon class="w-4 h-4 mr-2" />
                    {{
                      frankieStatus
                        .split("_")
                        .map(chr => chr.charAt(0).toUpperCase() + chr.slice(1))
                        .join(" ")
                    }}
                  </div>
                  <div v-else class="col-span-6 text-center text-primary-11">
                    <button
                      class="btn bg-theme-3 w-48 text-white"
                      @click="verifyFrankieId"
                    >
                      <span>Verify Name</span>
                    </button>
                  </div>
                </template>
                <div v-else class="flex justify-center items-center col-span-6">
                  <LoadingIcon class="w-4 h-4 ml-2" color="green" icon="oval" />
                </div>
              </div>
              <div class="mt-3 grid grid-cols-12">
                <label class="form-label col-span-5 mt-1.5 flex flex-row">
                  <UsersIcon class="w-4 h-4 mr-2" />
                  <div class="flex flex-col">
                    <span>System name verification:</span>
                    <span class="italic text-xs"
                      >(ID vs Customer name in system)</span
                    >
                  </div>
                </label>
                <template v-if="!systemCheckBusy">
                  <div
                    v-if="existsInSets(systemStatus)"
                    class="col-span-6 text-center text-green-500 mt-1.5"
                  >
                    <CheckCircleIcon class="w-4 h-4 mr-2" />
                    {{
                      systemStatus
                        .split("_")
                        .map(chr => chr.charAt(0).toUpperCase() + chr.slice(1))
                        .join(" ")
                    }}
                  </div>
                  <div v-else class="col-span-6 text-center text-primary-11">
                    <button
                      class="btn bg-theme-3 w-48 text-white"
                      @click="verifySystemNameWithFrankie"
                    >
                      <span>Verify Name</span>
                    </button>
                  </div>
                </template>
                <div v-else class="flex justify-center items-center col-span-6">
                  <LoadingIcon class="w-4 h-4 ml-2" color="green" icon="oval" />
                </div>
              </div>
              <div class="mt-3 grid grid-cols-12">
                <label class="form-label col-span-5 mt-1.5 flex flex-row">
                  <UsersIcon class="w-4 h-4 mr-2" />
                  <div class="flex flex-col">
                    <span>Centrelink Name Verification:</span>
                    <span class="italic text-xs"
                      >(ID vs Centrelink statement)</span
                    >
                  </div>
                </label>
                <template v-if="!isBusy">
                  <div
                    v-if="
                      centrelinkVerification &&
                        centrelinkVerification?.verification_required === false
                    "
                    class="col-span-6 text-center mt-1.5"
                  >
                    <template
                      v-if="+centrelinkVerification.verified_value === 1"
                    >
                      <CheckCircleIcon class="w-4 h-4 mr-2 text-green-500" />
                      <span class="text-green-500">Centrelink Verified</span>
                    </template>
                    <template
                      v-else-if="+centrelinkVerification.verified_value === 0"
                    >
                      <XCircleIcon class="w-4 h-4 mr-2 text-red-400" />
                      <span class="text-red-400">Centrelink Not Verified</span>
                    </template>
                    <template
                      v-else-if="+centrelinkVerification.verified_value === -1"
                    >
                      <FastForwardIcon class="w-4 h-4 mr-2 text-primary-11" />
                      <span class="text-primary-11">Not Required</span>
                    </template>
                  </div>
                  <template v-else>
                    <div
                      v-if="verificationIsBusy"
                      class="flex justify-center items-center col-span-6"
                    >
                      <LoadingIcon
                        class="w-4 h-4 ml-2"
                        color="green"
                        icon="oval"
                      />
                    </div>
                    <div v-else class="col-span-6 text-center text-primary-11">
                      <button
                        class="btn bg-theme-3 w-48 text-white"
                        @click="verifyCentreLinkName"
                      >
                        <span>Verify Name</span>
                      </button>
                    </div>
                  </template>
                </template>
                <div v-else class="flex justify-center items-center col-span-6">
                  <LoadingIcon class="w-4 h-4 ml-2" color="green" icon="oval" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FetchingRecordDivTemplate v-else marginTop="0" />
  </div>

  <SkipChecksModal />
</template>

<script>
import { computed, defineComponent, watch } from "vue";
import { useStore } from "@/store";
import { existsInSets } from "@/utils/verifications";
import FetchingRecordDivTemplate from "@/components/fetching-record-div-template/Main";
import SkipChecksModal from "@/components/loan-application-details/skip-checks-reason-form/Main.vue";

export default defineComponent({
  components: {
    FetchingRecordDivTemplate,
    SkipChecksModal
  },
  props: {
    applicationId: {
      type: Number,
      require: true
    }
  },
  setup: function(props) {
    const store = useStore();

    const statuses = computed(() => store.getters["skipChecks/statuses"]);
    const frankieStatus = computed(() => store.getters["frankieChecks/status"]);
    const systemStatus = computed(() => store.getters["systemChecks/status"]);
    const isBusy = computed(() => store.getters["skipChecks/isBusy"]);
    const verificationIsBusy = computed(
      () => store.getters["manualVerification/isBusy"]
    );
    const centrelinkVerification = computed(
      () => store.getters["skipChecks/centreLinkChecks"]
    );
    const frankieCheckBusy = computed(
      () => store.getters["frankieChecks/isBusy"]
    );
    const systemCheckBusy = computed(
      () => store.getters["systemChecks/isBusy"]
    );
    const successCount = computed(
      () => store.getters["skipChecks/successCount"]
    );

    watch(
      () => successCount.value,
      () => {
        reloadData();
        hideSkipModal();
      }
    );

    function reloadData() {
      store.dispatch("skipChecks/getSkipChecks");
      store.dispatch("frankieChecks/getFrankieChecks");
      store.dispatch("systemChecks/getSystemChecks");
      store.dispatch("skipChecks/getCenterLinkCheck");
    }

    init();

    function init() {
      store.commit("skipChecks/setApplicationId", props.applicationId);
      store.commit("manualVerification/setApplicationId", props.applicationId);
      store.commit("frankieChecks/setApplicationId", props.applicationId);
      store.commit("systemChecks/setApplicationId", props.applicationId);
      reloadData();
    }

    function skipCardVerification() {
      showSkipModal("card");
    }

    function skipBankVerification() {
      showSkipModal("auth");
    }

    function skipIdVerification() {
      showSkipModal("id");
    }

    function showSkipModal(type) {
      store.commit("skipChecks/setType", type);
      cash("#skip-checks-reason-form-modal").modal("show");
    }

    function hideSkipModal() {
      cash("#skip-checks-reason-form-modal").modal("hide");
    }

    function verifyIdDocuments() {
      store.commit("manualVerification/setType", "id");
      store.dispatch("manualVerification/manualVerification");
    }

    function verifyFrankieId() {
      store.commit("frankieChecks/setType", "frankie");
      store.dispatch("frankieChecks/manualFrankieCheck");
    }

    function verifySystemNameWithFrankie() {
      store.commit("systemChecks/setType", "system_customer_name");
      store.dispatch("systemChecks/manualSystemCheck");
    }

    function verifyCentreLinkName() {
      store.commit("manualVerification/setType", "centrelink_name");
      store.dispatch("manualVerification/manualCentreLinkVerification");
    }

    return {
      statuses,
      isBusy,
      skipCardVerification,
      skipBankVerification,
      skipIdVerification,
      centrelinkVerification,
      verificationIsBusy,
      verifyIdDocuments,
      frankieStatus,
      systemStatus,
      verifyFrankieId,
      verifySystemNameWithFrankie,
      verifyCentreLinkName,
      existsInSets,
      frankieCheckBusy,
      systemCheckBusy
    };
  }
});
</script>
