import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";

const state = () => {
  return {
    filteredFollowUpAgentName: "",
    selectedFollowUpAgent: 0,
    followUpAgents: [],
    unassignedLoanFollowUps: [],
    selectedUnassignedLoanFollowUps: [],
    selectedAssignedLoanFollowUps: [],
    busy: true,
    fetchingFollowUpAgents: false,
    fetchingUnassignedLoanFollowUps: false,
    lnDelegationBrdAlertMessage: "",
    lnDelegationBrdErrorMessage: "",
    lnDelegationBrdSuccessMessage: ""
  };
};

// getters
const getters = {
  filteredFollowUpAgentName: state => state.filteredFollowUpAgentName,
  selectedFollowUpAgent: state => state.selectedFollowUpAgent,
  followUpAgents: state => state.followUpAgents,
  unassignedLoanFollowUps: state => state.unassignedLoanFollowUps,
  selectedUnassignedLoanFollowUps: state =>
    state.selectedUnassignedLoanFollowUps,
  selectedAssignedLoanFollowUps: state => state.selectedAssignedLoanFollowUps,
  busy: state => state.busy,
  fetchingFollowUpAgents: state => state.fetchingFollowUpAgents,
  fetchingUnassignedLoanFollowUps: state =>
    state.fetchingUnassignedLoanFollowUps,
  lnDelegationBrdAlertMessage: state => state.lnDelegationBrdAlertMessage,
  lnDelegationBrdErrorMessage: state => state.lnDelegationBrdErrorMessage,
  lnDelegationBrdSuccessMessage: state => state.lnDelegationBrdSuccessMessage
};

// actions
const actions = {
  getUnassignedLoanFollowUps: async (
    { commit, dispatch },
    customSuccessMessage = ""
  ) => {
    commit("setFetchingUnassignedLoanFollowUps");
    axiosWrapperService
      .axiosGet("/getUnassignedLoanFollowUps")
      .then(response => {
        commit("setUnassignedLoanFollowUps", response.data.data);
        if (customSuccessMessage !== "") {
          dispatch(
            "globalToastMessage/showSimpleToast",
            { message: customSuccessMessage, type: "success" },
            { root: true }
          );
        }
      })
      .catch(error => {
        commit(
          "setFollowUpDelegationBrdErrorMessage",
          formatErrorMessage(error, true)
        );
      })
      .finally(() => {
        commit("resetFetchingUnassignedLoanFollowUps");
      });
  },
  getAdminsWithAssociationsForFollowUpBoard: async (
    { commit, dispatch, state },
    customSuccessMessage = ""
  ) => {
    commit("setFetchingFollowUpAgents");
    let url = "/getAdminsWithAssociationsForFollowUpBoard";
    if (state.filteredFollowUpAgentName !== "")
      url += "?name=" + state.filteredFollowUpAgentName;
    axiosWrapperService
      .axiosGet(url)
      .then(response => {
        commit("setFollowUpAgents", response.data.data);
        if (customSuccessMessage !== "") {
          dispatch(
            "globalToastMessage/showSimpleToast",
            { message: customSuccessMessage, type: "success" },
            { root: true }
          );
        }
      })
      .catch(error => {
        commit("setAdminListErrorMessage", formatErrorMessage(error, true));
      })
      .finally(() => {
        commit("resetFetchingFollowUpAgents");
      });
  },
  submitUnassignedFollowUpsForAssignment: async ({
    commit,
    dispatch,
    state
  }) => {
    axiosWrapperService
      .axiosPost(
        "/assignUnAssignedFollowUpsToAgent/" + state.selectedFollowUpAgent,
        {
          unassigned_follow_up_uuids: state.selectedUnassignedLoanFollowUps
        }
      )
      .then(() => {
        commit("clearSelectedUnassignedLoanFollowUps");
        commit("setSelectedFollowUpAgent", 0);
        dispatch("getAdminsWithAssociationsForFollowUpBoard");
        dispatch(
          "getUnassignedLoanFollowUps",
          "Successfully unassigned Applications to agent !"
        );
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("main/resetFullPageLoading", null, { root: true });
      });
  },
  submitAssignedFollowUpsForUnassignment: async ({
    commit,
    dispatch,
    state
  }) => {
    axiosWrapperService
      .axiosPost("/unAssignAssignedFollowUps", {
        assigned_follow_up_uuids: state.selectedAssignedLoanFollowUps
      })
      .then(() => {
        commit("clearSelectedAssignedLoanFollowUps");
        commit("setSelectedFollowUpAgent", 0);
        dispatch("getAdminsWithAssociationsForFollowUpBoard");
        dispatch(
          "getUnassignedLoanFollowUps",
          "Successfully Unassigned Follow Ups !"
        );
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("main/resetFullPageLoading", null, { root: true });
      });
  }
};

// mutations
const mutations = {
  setFilteredFollowUpAgentName(state, agentId) {
    state.filteredFollowUpAgentName = agentId;
  },
  setSelectedFollowUpAgent(state, agentId) {
    state.selectedFollowUpAgent = agentId;
  },
  setAllUnassignedLoanFollowUpsForSelection(state, agentId) {
    state.selectedUnassignedLoanFollowUps = state.unassignedLoanFollowUps.map(
      unln => unln.id
    );
  },
  pushToSelectedAssignedLoanFollowUps(state, assignedLoanFollowUpId) {
    if (!state.selectedAssignedLoanFollowUps.includes(assignedLoanFollowUpId)) {
      state.selectedAssignedLoanFollowUps.push(assignedLoanFollowUpId);
    }
  },
  removeFromSelectedAssignedLoanFollowUps(state, assignedLoanFollowUpId) {
    if (state.selectedAssignedLoanFollowUps.includes(assignedLoanFollowUpId)) {
      state.selectedAssignedLoanFollowUps = state.selectedAssignedLoanFollowUps.filter(
        item => item !== assignedLoanFollowUpId
      );
    }
  },
  pushToSelectedUnassignedLoanFollowUps(state, unassignedLoanFollowUpId) {
    if (
      !state.selectedUnassignedLoanFollowUps.includes(unassignedLoanFollowUpId)
    ) {
      state.selectedUnassignedLoanFollowUps.push(unassignedLoanFollowUpId);
    }
  },
  removeFromSelectedUnassignedLoanFollowUps(state, unassignedLoanFollowUpId) {
    if (
      state.selectedUnassignedLoanFollowUps.includes(unassignedLoanFollowUpId)
    ) {
      state.selectedUnassignedLoanFollowUps = state.selectedUnassignedLoanFollowUps.filter(
        item => item !== unassignedLoanFollowUpId
      );
    }
  },
  clearSelectedAssignedLoanFollowUps(state) {
    state.selectedAssignedLoanFollowUps = [];
  },
  clearSelectedUnassignedLoanFollowUps(state) {
    state.selectedUnassignedLoanFollowUps = [];
  },
  setBusy(state) {
    state.busy = true;
  },
  resetBusy(state) {
    state.busy = false;
  },
  setFetchingFollowUpAgents(state) {
    state.fetchingFollowUpAgents = true;
  },
  resetFetchingFollowUpAgents(state) {
    state.fetchingFollowUpAgents = false;
  },
  setFetchingUnassignedLoanFollowUps(state) {
    state.fetchingUnassignedLoanFollowUps = true;
  },
  resetFetchingUnassignedLoanFollowUps(state) {
    state.fetchingUnassignedLoanFollowUps = false;
  },
  setFollowUpAgents(state, followUpAgents) {
    state.followUpAgents = followUpAgents;
  },
  setUnassignedLoanFollowUps(state, unassignedLoanFollowUps) {
    state.unassignedLoanFollowUps = unassignedLoanFollowUps;
  },
  setFollowUpDelegationBrdAlertMessage(state, lnDelegationBrdAlertMessage) {
    state.lnDelegationBrdAlertMessage = lnDelegationBrdAlertMessage;
  },
  setFollowUpDelegationBrdErrorMessage(state, lnDelegationBrdErrorMessage) {
    state.lnDelegationBrdErrorMessage = lnDelegationBrdErrorMessage;
  },
  setFollowUpDelegationBrdSuccessMessage(state, lnDelegationBrdSuccessMessage) {
    state.lnDelegationBrdSuccessMessage = lnDelegationBrdSuccessMessage;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
