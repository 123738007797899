import { formatErrorMessage } from "@/utils/messageFormatter";
import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";

const state = () => {
  return {
    isBusy: false,
    submitProcessing: false,
    successMessage: "",
    errorMessage: "",
    validationErrors: [],
    notifications: []
  };
};

const getters = {
  isBusy: state => state.isBusy,
  submitProcessing: state => state.submitProcessing,
  successMessage: state => state.successMessage,
  errorMessage: state => state.errorMessage,
  validationErrors: state => state.validationErrors,
  notifications: state => state.notifications
};

const actions = {
  updateLoanApplicationCriticalAlert: async (
    { state, dispatch, commit },
    payload
  ) => {
    commit("setSubmitProcessing", true);
    commit("setSuccessMessage", "");
    axiosWrapperService
      .axiosPost("/assessmentCriticalAlert/customerAlerts/update", payload)
      .then(res => {
        commit("setSuccessMessage", "Notification updated.");
        commit("setNotifications", res.data.data);
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => commit("setSubmitProcessing", false));
  },
  getApplicationCriticalAlert: async (
    { state, dispatch, commit },
    application_id
  ) => {
    commit("setIsBusy", true);
    axiosWrapperService
      .axiosGet(
        `/assessmentCriticalAlert/customerAlerts/${application_id}/fetch`
      )
      .then(res => commit("setNotifications", res.data.data))
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => commit("setIsBusy", false));
  }
};

const mutations = {
  setIsBusy(state, status) {
    state.isBusy = status;
  },
  setSubmitProcessing(state, status) {
    state.submitProcessing = status;
  },
  setSuccessMessage(state, message = "") {
    state.successMessage = message;
  },
  setErrorMessage(state, message = "") {
    state.errorMessage = message;
  },
  setValidationErrors(state, errors = []) {
    state.validationErrors = errors;
  },
  setNotifications(state, notifications = []) {
    state.notifications = notifications;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
