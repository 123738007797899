<template>
  <div>
    <div
      v-for="(selectData, key) in selectOptions"
      :key="key"
      class="border border-gray-200 dark:border-dark-5 rounded-md p-5"
    >
      <div class="grid grid-cols-3 gap-4 items-center justify-center">
        <div>
          <span class="text-left font-medium">{{ selectData.label }}</span>
          <p class="text-xs">{{ selectData.description }}</p>
        </div>
        <select v-model="validate[key].$model" class="form-select w-full ml-2">
          <option
            v-for="(option, i) in selectData.options"
            :key="i"
            :value="option.value"
          >{{ option.label }}</option>
        </select>
      </div>
      <template v-if="validate[key].$errors">
        <div
          v-for="(error, index) in validate[key].$errors"
          :key="index"
          class="text-theme-6 mt-2"
        >
          {{ error.$message }}
        </div>
      </template>
    </div>
    <div class="target-page-path border border-gray-200 dark:border-dark-5 rounded-md p-5">
      <div class="grid grid-cols-3 gap-4 items-center justify-center">
        <div>
          <span class="text-left font-medium">Target Page Path</span>
          <p class="text-xs">*What is the path of the target page</p>
        </div>
        <input
          v-model="validate.target_page.$model"
          class="form-control w-full ml-2"
          type="text"
        />
      </div>
      <template v-if="validate.target_page.$errors">
        <div
          v-for="(error, index) in validate.target_page.$errors"
          :key="index"
          class="text-theme-6 mt-2"
        >
          {{ error.$message }}
        </div>
      </template>
    </div>
    <div class="component-content border border-gray-200 dark:border-dark-5 rounded-md p-5 mt-5">
      <div class="mt-2">
        <p class="pb-4 text-xs">
          * Note: Do not add extra spaces between paragraphs *
        </p>
        <CKEditor :editor="ckeditor.editor" :config="ckeditor.config" v-model="validate.body.$model"/>
      </div>
      <template v-if="validate.body.$error">
        <div
          v-for="(error, index) in validate.body.$errors"
          :key="index"
          class="text-theme-6 mt-2"
        >
          {{ error.$message }}
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { inject, reactive, defineProps } from "vue";
import { minLength, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { cmsDetails } from "@/composables/components/content-manager/cms";

const props = defineProps();
const {
  route,
  adminUser,
  activeRecord,
  savePage,
  updatePage
} = cmsDetails(props);

const ID = route.params.uuid;
const apiEndpoints = {
  save: "/cms/component/add",
  update: `/cms/component/${ID}/update`
}
const selectOptions = {
  target: {
    label: "Target Page",
    description: "*What type of static page this component belongs to",
    options: [
      {
        label: "PCI Page",
        value: "pci",
        default: true
      }
    ]
  },
  type: {
    label: "Component Type",
    description: "*What is the type of component",
    options: [
      {
        label: "FAQ",
        value: "faq",
        default: true
      }
    ]
  }
};
let data;
if (route.name === "content-management-edit-component") {
  const result = activeRecord.value;
  data = {
    target: result.target,
    type: result.type,
    target_page: result.target_page,
    body: result.body
  };
} else {
  data = {
    target: selectOptions.target.options[0].value,
    type: selectOptions.type.options[0].value,
    target_page: "",
    body: "<p>FAQ Content Goes Here..</p>"
  };
}

const formData = reactive(data);

const ckeditor = {
  editor: ClassicEditor,
  config: {
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "undo",
      "redo"
    ]
  }
}

const rules = {
  body: {
    required,
    maxLength: minLength(6)
  },
  target_page: {
    required,
    minLength: minLength(5)
  },
  type: {
    required
  },
  target: {
    required
  }
};

const validate = useVuelidate(rules, formData);

const validateAndSubmit = inject("validateAndSubmit");

const submit = async () => {
  validate.value.$touch();
  if (validate.value.$invalid) {
    throw new Error("Form Validation Failed");
  }

  if (['content-management-edit-page', 'content-management-edit-component'].includes(route.name)) {
    formData.updated_by = adminUser.value.id;
    await updatePage(apiEndpoints.update, formData);
  } else {
    formData.created_by = adminUser.value.id;
    await savePage(apiEndpoints.save, formData);
  }
};

validateAndSubmit.value = submit;

</script>
