<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Form Layout</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <div>
            <label class="form-label" for="crud-form-1">Product Name</label>
            <input
              id="crud-form-1"
              class="form-control w-full"
              placeholder="Input text"
              type="text"
            />
          </div>
          <div class="mt-3">
            <label class="form-label" for="crud-form-2">Category</label>
            <TailSelect
              id="crud-form-2"
              v-model="categories"
              :options="{
                hideSelected: true,
                hideDisabled: true,
                multiLimit: 15,
                multiShowCount: false,
                multiContainer: true,
                classNames: 'w-full'
              }"
              multiple
            >
              <option value="1">Sport & Outdoor</option>
              <option value="2">PC & Laptop</option>
              <option value="3">Smartphone & Tablet</option>
              <option value="4">Photography</option>
            </TailSelect>
          </div>
          <div class="mt-3">
            <label class="form-label" for="crud-form-3">Quantity</label>
            <div class="input-group">
              <input
                id="crud-form-3"
                aria-describedby="input-group-1"
                class="form-control"
                placeholder="Quantity"
                type="text"
              />
              <div id="input-group-1" class="input-group-text">pcs</div>
            </div>
          </div>
          <div class="mt-3">
            <label class="form-label" for="crud-form-4">Weight</label>
            <div class="input-group">
              <input
                id="crud-form-4"
                aria-describedby="input-group-2"
                class="form-control"
                placeholder="Weight"
                type="text"
              />
              <div id="input-group-2" class="input-group-text">grams</div>
            </div>
          </div>
          <div class="mt-3">
            <label class="form-label">Price</label>
            <div class="sm:grid grid-cols-3 gap-2">
              <div class="input-group">
                <div id="input-group-3" class="input-group-text">Unit</div>
                <input
                  aria-describedby="input-group-3"
                  class="form-control"
                  placeholder="Unit"
                  type="text"
                />
              </div>
              <div class="input-group mt-2 sm:mt-0">
                <div id="input-group-4" class="input-group-text">Wholesale</div>
                <input
                  aria-describedby="input-group-4"
                  class="form-control"
                  placeholder="Wholesale"
                  type="text"
                />
              </div>
              <div class="input-group mt-2 sm:mt-0">
                <div id="input-group-5" class="input-group-text">Bulk</div>
                <input
                  aria-describedby="input-group-5"
                  class="form-control"
                  placeholder="Bulk"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div class="mt-3">
            <label>Active Status</label>
            <div class="mt-2">
              <input class="form-check-switch" type="checkbox" />
            </div>
          </div>
          <div class="mt-3">
            <label>Description</label>
            <!--            <div class="mt-2">-->
            <!--              <CKEditor-->
            <!--                v-model="editorData"-->
            <!--                :editor="classicEditor"-->
            <!--                :config="simpleEditorConfig"-->
            <!--              />-->
            <!--            </div>-->
          </div>
          <div class="text-right mt-5">
            <button class="btn btn-outline-secondary w-24 mr-1" type="button">
              Cancel
            </button>
            <button class="btn btn-primary w-24" type="button">Save</button>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const categories = 1;

    return {
      categories
    };
  }
});
</script>
