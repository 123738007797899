<template>
  <div
    v-if="
      props.name !== 'customer_name' &&
        (dataType === '' ||
          formType === 'static' ||
          formElement === 'static' ||
          !props.enable)
    "
    :class="{
      'text-gray-600': type !== 'income'
    }"
    class="px-3 py-1"
  >
    {{ value }}
  </div>
  <div
    v-else-if="
      props.enable &&
        props.name === 'customer_name' &&
        (props.details['verification']['last_modified_by'] > 0 ||
          props.details['verification']['system_verified'] > 0 ||
          !props.details['verification']['verification_required'])
    "
    :class="{
      'text-gray-600': type !== 'income'
    }"
    class="px-3 py-1"
  >
    {{ value }}
  </div>
  <div v-else>
    <input
      v-if="formType === 'input'"
      :id="formElementId"
      v-model="value"
      :type="inputType"
      class="px-3 py-1 w-full rounded-lg col-span-6 border border-gray-300 gap-3"
      placeholder="Input text"
      @blur="update"
      @keyup.enter="update"
    />
    <div v-if="formType === 'calendar'">
      <FlatPickr
        :id="formElementId"
        v-model="value"
        class="
          px-3
          py-1
          w-full
          rounded-lg
          col-span-6
          border border-gray-300
          gap-3
        "
        @blur="update"
        @keyup.enter="update"
      ></FlatPickr>
    </div>
    <div v-if="formType === 'radio'">
      <div
        v-for="(option, key) in options"
        :key="key"
        class="aggregator-options"
      >
        <label>{{ option }}</label
        ><br />
        <input
          :id="props.name + '-id'"
          v-model="value"
          :checked="option === value"
          :name="formElementName"
          :value="option"
          type="radio"
          @blur="update"
          @keyup.enter="update"
        />
      </div>
    </div>

    <select
      v-if="formType === 'select' && props.name !== 'number_of_repayments'"
      :id="formElementId"
      v-model="value"
      aria-label=".form-select-sm example"
      class="
        px-3
        py-1
        w-full
        rounded-lg
        border border-gray-300
        gap-3
      "
      @blur="update"
      @keyup.enter="update"
    >
      <!--      <option value="">None</option>-->
      <option v-for="(name, key) in dropDownOptions" :key="key" :value="name">
        {{ name }}
      </option>
    </select>

    <select
      v-if="formType === 'select' && props.name === 'number_of_repayments'"
      :id="formElementId"
      v-model="value"
      aria-label=".form-select-sm example"
      class="
        px-3
        py-1
        w-full
        rounded-lg
        border border-gray-300
        gap-3
      "
      @blur="update"
      @keyup.enter="update"
    >
      <option
        v-for="name in aggregatorLoanDetailsTransitLobby.loan_summary.data
          .number_of_repayments['form-element']['options']"
        :key="name"
        :value="name"
      >
        {{ name }}
      </option>
    </select>

    <span v-if="showWarning" class="text-red-600 ml-1 font-bold">!ERROR!</span>
  </div>
</template>

<script setup>
import {
  computed,
  defineEmits,
  nextTick,
  defineProps,
  onMounted,
  ref
} from "vue";
import FlatPickr from "vue-flatpickr-component";
// Need to add base css for flatpickr
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/plugins/confirmDate/confirmDate.css";
import "flatpickr/dist/plugins/monthSelect/style.css";
import "flatpickr/dist/themes/material_green.css";

const emit = defineEmits(["update"]);
//Props
const props = defineProps([
  "details",
  "name",
  "index",
  "enable",
  "type",
  "activeInputId",
  "aggregatorLoanDetailsTransitLobby",
  "loading"
]);

//Data
const value = ref(null);

const originalValue = ref(null);

const zeroDefaults = [
  "accommodation",
  "automotive",
  "food",
  "gambling",
  "groceries",
  "insurance",
  "life_style",
  "credit_card_payment",
  "debt_collection",

  "personal_loan",
  "subscriptions",
  "utilities",

  "wages_income",
  "centrelink_income",
  "other_income",
  "last_income_transaction_amount"
];

//Form Elements
const formType = computed(() => {
  return props.details["form-element"]["type"];
});

const formElement = computed(() => {
  return props.details["form-element"];
});

const dataType = computed(() => {
  return props.details["data-type"];
});

const dropDownOptions = computed(() => {
  return props.details["form-element"]["options"];
});

const formElementName = computed(() => {
  return props.key;
});

const formElementId = computed(() => `${props.name}_id`);

const activeId = computed(() => props.activeInputId);

const inputType = computed(() => {
  if (nameIsInDefaultToZeroArray()) {
    return "number";
  }
  return "text";
});

//Value - Update
function update(e) {
  if (nameIsInDefaultToZeroArray() && (value.value === "" || value.value < 0)) {
    value.value = 0;
  }

  if (props.type === "income") {
    if (value.value !== props.details["assessment-analysis-value"]) {
      emit("update", {
        value: value.value,
        id: e.target.id
      });
      originalValue.value = value.value;
    }
  }

  if (props.type === "lending") {
    if (value.value !== props.details["value"]) {
      emit("update", {
        value: value.value,
        id: e.target?.id
      });
      originalValue.value = value.value;
    }
  }
}

function focusNextElement(focusedElementId) {
  if (focusedElementId && focusedElementId.length) {
    let focusable = Array.from(
      document.querySelectorAll(
        'a:not([disabled]), button:not([disabled]), select:not([disabled]), input[type=text]:not([disabled]),input[type=number]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])'
      )
    );
    let focusedInput = document.getElementById(focusedElementId);
    let index = focusable.indexOf(focusedInput);
    if (index > -1 && index < focusable.length - 1) {
      let nextElement = focusable[index + 1];
      nextTick(() => nextElement.focus());
    }
  }
}

function nameIsInDefaultToZeroArray() {
  return zeroDefaults.includes(props.name);
}

//What numbers to show in the dropdown for number_of_repayments
//Dependent on the value of the payment_frequency

const showWarning = computed(() => {
  return false;
});

//Mounted lifecycle
onMounted(() => {
  if (props.type === "income") {
    value.value = props.details["assessment-analysis-value"];
    originalValue.value = props.details["assessment-analysis-value"];
  }
  if (props.type === "lending") {
    value.value = props.details["value"];
    originalValue.value = props.details["value"];
  }

  if (
    activeId.value &&
    activeId.value !== "" &&
    formElementId.value === activeId.value &&
    props.loading
  ) {
    focusNextElement(activeId.value);
  }
});
</script>

<style scoped>
.form-element {
  padding: 2px;
}
</style>
