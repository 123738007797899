<template>
  <!-- BEGIN: Dark Mode Switcher-->
  <div>
    <div>
      <div v-if="!fetchingDocReq" class="box z-0" style="z-index: 0;">
        <div
          class="
            flex flex-col
            sm:flex-row
            items-center
            p-5
            border-b border-gray-200
            dark:border-dark-5
          "
        ></div>
        <div id="doc-req-table" class="p-5">
          <div class="preview">
            <div class="overflow-x-auto">
              <table class="table">
                <thead>
                  <tr>
                    <th
                      v-if="isCustomerMode"
                      class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                    >
                      Application Id
                    </th>

                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Document Type
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Requested By
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Description
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Uploaded Status
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Uploaded At
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody v-if="documentRequestList.length > 0">
                  <tr
                    v-for="(docReq, docReqIndex) in documentRequestList"
                    :key="docReqIndex"
                    :class="{
                      'bg-gray-200 dark:bg-dark-1': docReqIndex % 2 > 0
                    }"
                  >
                    <td
                      v-if="isCustomerMode"
                      class="border-b dark:border-dark-5"
                    >
                      <a
                        :href="
                          `/customer/${customerId}/loan-application/${docReq.application_id}`
                        "
                        target="_blank"
                      >
                        <u class="font-medium whitespace-nowrap cursor-pointer"
                          >{{ docReq.application_id }}
                        </u>
                      </a>
                    </td>
                    <td class="border-b dark:border-dark-5">
                      {{ docReq.doc_type }}
                    </td>
                    <td class="border-b dark:border-dark-5">
                      {{
                        docReq.admin_requestor !== undefined &&
                        docReq.admin_requestor !== null
                          ? docReq.admin_requestor.fullname
                          : docReq.legacy_requestor
                          ? docReq.legacy_requestor.full_name
                          : ""
                      }}
                    </td>
                    <td
                      class="border-b dark:border-dark-5 max-w-xs mx-auto break-words"
                    >
                      <span
                        v-if="
                          docReq.qna_response_message &&
                            docReq.qna_response_message.length
                        "
                      >
                        <p><strong> Question:</strong></p>
                        {{ convertDescriptionToUserFormat(docReq.description) }}
                        <p><strong>Customer Answer:</strong></p>
                        {{ docReq.qna_response_message }}
                      </span>
                      <ExtraIncomeContent
                        v-else-if="
                          docReq.doc_type_slug === 'extra_income_request'
                        "
                        :data="docReq.extra_income"
                      />
                      <template v-else>
                        <DocRequestDescriptionView
                          :convertDescriptionToUserFormat="
                            convertDescriptionToUserFormat
                          "
                          :description="docReq.description"
                          :slug="docReq?.doc_type_slug"
                        />
                      </template>

                      <span v-if="docReq.payslip_fluctuate_metas !== undefined">
                        <span
                          v-if="
                            payslipDocReqHasValidFluctuateReason(
                              docReq.payslip_fluctuate_metas
                            ) &&
                              getPayslipFluctuateMetaAttrValue(
                                docReq.payslip_fluctuate_metas,
                                'Payslip_fluctuate_reason'
                              ) !== ''
                          "
                        >
                          <p><strong> Payslip Fluctuate reason:</strong></p>
                          {{
                            getPayslipFluctuateMetaAttrValue(
                              docReq.payslip_fluctuate_metas,
                              "Payslip_fluctuate_reason"
                            )
                          }}
                        </span>
                        <span
                          v-if="
                            getPayslipFluctuateMetaAttrValue(
                              docReq.payslip_fluctuate_metas,
                              'payslip_reason'
                            ) !== ''
                          "
                        >
                          <p><strong>Payment proof comment:</strong></p>
                          {{
                            getPayslipUploadReason(
                              docReq.payslip_fluctuate_metas,
                              docReq.id
                            )
                          }}
                        </span>
                      </span>
                    </td>
                    <td class="border-b dark:border-dark-5">
                      <span v-if="docReq.uploaded_at" class="text-theme-9"
                        ><CheckSquareIcon class="w-4 h-4 mr-2" /> Received</span
                      ><span v-else class="text-yellow-400"
                        ><ClockIcon class="w-4 h-4 mr-2" /> Waiting</span
                      >
                    </td>
                    <td class="border-b dark:border-dark-5">
                      {{ docReq.uploaded_at }}
                      <!--                        <input-->
                      <!--                          v-if="-->
                      <!--                            docReq.uploaded_at === '' ||-->
                      <!--                              docReq.uploaded_at === null-->
                      <!--                          "-->
                      <!--                          ref="file"-->
                      <!--                          type="file"-->
                      <!--                          class="btn bg-green-400 xs:w-16 w-32 text-white"-->
                      <!--                        />-->
                      <button
                        v-if="
                          docReq.uploaded_at === '' ||
                            docReq.uploaded_at === null
                        "
                        class="btn bg-green-400 xs:w-16 w-32 text-white"
                        @click.prevent="
                          showMultiFileUploadModal(
                            docReq.doc_type_slug,
                            docReq.id,
                            docReq.application_id
                          )
                        "
                      >
                        Upload Now
                      </button>
                    </td>
                    <td class="border-b dark:border-dark-5">
                      <button
                        v-if="docReq.gallery_items.length > 0"
                        class="btn bg-theme-3 xs:w-16 w-32 text-white"
                        @click.prevent="
                          showDocReqGallery(
                            docReq.gallery_items,
                            docReq.doc_type_slug
                          )
                        "
                      >
                        View{{
                          filterDocsGallery(
                            docReq.gallery_items,
                            docReq.doc_type_slug
                          ).length > 0
                            ? " (" +
                              filterDocsGallery(
                                docReq.gallery_items,
                                docReq.doc_type_slug
                              ).length +
                              ")"
                            : ""
                        }}
                      </button>
                      <button
                        v-if="docReq.gallery_items.length < 1"
                        class="btn bg-red-400 xs:w-16 w-32 text-white"
                        @click.prevent="
                          onDocReqRemoveSubmit(docReq.id, docReq.application_id)
                        "
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                </tbody>
                <noRecordCustTblTemplate v-else />
              </table>
            </div>
          </div>
        </div>
        <div
          v-if="!isCustomerMode"
          class="
            flex flex-col
            sm:flex-row
            items-center
            p-5
            border-b border-gray-200
          "
        >
          <div class="w-full sm:w-auto flex sm:ml-auto mt-1 sm:mt-0">
            <div
              v-if="otherDocsCount > 0 && custId > 0"
              class="pt-4 pr-6 underline text-blue-700"
            >
              <a
                :href="`/customers/customer/${custId}?showDocs=true`"
                target="_blank"
              >
                <ArrowRightIcon class="w-6 h-6 mr-2" />
                {{ `${otherDocsCount} Prior Application Doc(s) Available` }}</a
              >
            </div>
            <button
              class="btn btn-rounded bg-theme-11 w-1/2 text-white sm:w-auto mr-2"
              type="button"
              @click="showUploadDocModal(application_id)"
            >
              <PlusCircleIcon class="w-6 h-6 mr-2" />
              Upload Docs
            </button>
            <button
              class="btn btn-rounded bg-theme-3 w-1/2 text-white sm:w-auto mr-2"
              type="button"
              @click="showNewDocReqFormModal(application_id)"
            >
              <PlusCircleIcon class="w-6 h-6 mr-2" />
              Request New Documents
            </button>
          </div>
        </div>
      </div>
      <FetchingRecordDivTemplate v-else marginTop="0" />
    </div>

    <!-- END: Striped Rows -->
    <!-- BEGIN: Upload Now Modal Content -->
    <div
      id="multi-file-upload-modal"
      aria-hidden="true"
      class="modal"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">File Upload</h2>
            <div class="dropdown sm:hidden">
              <a
                aria-expanded="false"
                class="dropdown-toggle w-5 h-5 block"
                href="javascript:"
              >
                <MoreHorizontalIcon
                  class="w-5 h-5 text-gray-600 dark:text-gray-600"
                />
              </a>
              <div class="dropdown-menu w-40">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <a
                    class="
                      flex
                      items-center
                      p-2
                      transition
                      duration-300
                      ease-in-out
                      bg-white
                      dark:bg-dark-1
                      hover:bg-gray-200
                      dark:hover:bg-dark-2
                      rounded-md
                    "
                    href="javascript:"
                  >
                    <FileIcon class="w-4 h-4 mr-2" />
                    Download Docs
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="modal-body grid grid-cols-6">
            <div class="col-span-12 sm:col-span-6">
              <!-- BEGIN: Multiple File Upload -->
              <div class="intro-y box mt-5">
                <div
                  v-if="docFileUploadValidationMessage !== ''"
                  class="alert alert-danger-soft show flex items-center mb-2"
                  role="alert"
                >
                  <AlertOctagonIcon class="w-6 h-6 mr-2" />
                  {{ docFileUploadValidationMessage }}
                  <button
                    class="btn-close"
                    type="button"
                    @click="clearDocReqMessages"
                  >
                    <XIcon class="w-4 h-4" />
                  </button>
                </div>
                <div id="multiple-file-upload" class="p-5">
                  <div class="preview">
                    <input
                      id="browsed-doc-gallery-files"
                      ref="files"
                      multiple
                      type="file"
                      @change="uploadedFilesHandler"
                    />
                  </div>
                </div>
              </div>
              <!-- END: Multiple File Upload -->
            </div>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer text-right">
            <button
              class="btn btn-dark-soft w-20 mr-1"
              data-dismiss="modal"
              type="button"
            >
              Cancel
            </button>
            <button
              v-if="!busy"
              class="btn btn-success w-40"
              type="button"
              @click="onSubmitFile"
            >
              Submit Files
            </button>
            <button v-else class="btn btn-success mr-1 mb-2">
              Processing
              <LoadingIcon class="w-4 h-4 ml-2" color="white" icon="oval" />
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
    </div>

    <DocRequestModal
      ref="docRequestModelRef"
      :documentTypeList="documentTypeList"
      :newDocReqFormObject="newDocReqFormObject"
      :onNewDocumentReqSubmit="submitDocReq"
    />
    <!-- END: Modal Content -->
    <!-- BEGIN: Super Large Slide Over Content -->
    <div
      id="loan-assessment-slide-over-preview"
      aria-hidden="true"
      class="modal modal-slide-over"
      tabindex="-1"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header p-5">
            <h2 class="font-medium text-base mr-auto">Gallery Item</h2>
          </div>
          <div class="modal-body">
            <table class="table table--sm">
              <thead>
                <tr>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Item
                  </th>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Date Time
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(galleryItem, galleryItemKey) in visibleGallery"
                  :key="galleryItemKey"
                >
                  <td
                    class="border-b dark:border-dark-5"
                    @click="onDocumentGalleryItemDownload(galleryItem)"
                  >
                    <span
                      :class="{
                        'underline underline-offset-2 text-blue-400': isHtmlLink(
                          galleryItem.file_name
                        )
                      }"
                      class="cursor-pointer"
                      >{{ galleryItem.file_name }}</span
                    >
                  </td>
                  <td class="border-b dark:border-dark-5">
                    {{ galleryItem.created_at }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Super Large Slide Over Content -->
    <!-- BEGIN: Success Notification Content -->
    <div
      id="ln-doc-req-success-alert-toast-content"
      class="toastify-content hidden flex"
    >
      <XCircleIcon class="text-theme-3" />
      <div class="ml-4 mr-4">
        <div id="ln-doc-req-success-alert-title" class="font-medium">
          Success!
        </div>
        <div
          id="ln-doc-req-success-alert-note-message-body"
          class="text-gray-600 mt-1"
        ></div>
      </div>
    </div>
    <!-- END: Success Notification Content -->
    <!-- BEGIN: Error Notification Content -->
    <div
      id="ln-doc-req-error-alert-toast-content"
      class="toastify-content hidden flex"
    >
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div id="ln-doc-req-error-alert-title" class="font-medium">Error!</div>
        <div
          id="ln-doc-req-error-alert-note-message-body"
          class="text-gray-600 mt-1"
        ></div>
      </div>
    </div>
    <!-- END: Error Notification Content -->
  </div>

  <UploadDocsModal :application-id="applicationId" />
  <!-- END: Dark Mode Switcher-->
</template>

<script>
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2";
import Toastify from "toastify-js";
import { customSwalMessageBuilder } from "@/composables/utils/customSwalMessageBuilder";
import noRecordCustTblTemplate from "@/components/no-record-tbl-template/Main.vue";
import FetchingRecordDivTemplate from "@/components/fetching-record-div-template/Main";
import UploadDocsModal from "@/components/ln-app-doc-req/UploadDocsModal";
import { helper as $h } from "@/utils/helper";
import DocReqHandler from "@/utils/DocumentRequests/Handler";
import ExtraIncomeContent from "./ExtraIncomeContent";
import DocRequestModal from "./DocRequestModal";
import DocRequestDescriptionView from "./DocRequestDescriptionView";

export default defineComponent({
  components: {
    DocRequestModal,
    ExtraIncomeContent,
    noRecordCustTblTemplate,
    FetchingRecordDivTemplate,
    UploadDocsModal,
    DocRequestDescriptionView
  },
  props: {
    applicationId: {
      type: Number,
      required: false
    },
    customerId: {
      type: Number,
      required: false,
      default: null
    }
  },
  setup: function(props) {
    const { buildSwalWarning } = customSwalMessageBuilder();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const docExcludeFormats = {
      extra_income_request: ["json", "zip"]
    };

    const isCustomerMode = props.customerId !== null;

    const busy = computed(() => store.state.lnAppDocReq.busy);

    const fetchingDocReq = computed(
      () => store.state.lnAppDocReq.fetchingDocReq
    );

    const docFileUploadValidationMessage = computed(
      () => store.state.lnAppDocReq.docFileUploadValidationMessage
    );

    watch(
      () => docFileUploadValidationMessage.value,
      message => {
        if (message) {
          store.commit("main/resetFullPageLoading");
        }
      }
    );

    const documentRequestList = computed(
      () => store.state.lnAppDocReq.documentRequestList
    );
    const documentTypeList = computed(
      () => store.state.lnAppDocReq.documentTypeList
    );

    function payslipDocReqHasValidFluctuateReason(payslipFluctuateMetas) {
      let payslipFluctuateRecord = payslipFluctuateMetas.filter(meta => {
        return meta.attr === "Payslip_fluctuate";
      });
      return (
        payslipFluctuateRecord.length > 0 &&
        payslipFluctuateRecord[0].value.toLowerCase() === "yes"
      );
    }

    function getPayslipFluctuateMetaAttrValue(payslipFluctuateMetas, attrName) {
      let payslipFluctuateRecord = payslipFluctuateMetas.filter(meta => {
        return meta.attr === attrName;
      });
      if (payslipFluctuateRecord.length > 0) {
        return payslipFluctuateRecord[0].value;
      }
      return "";
    }

    function getPayslipUploadReason(payslipFluctuateMetas, documentId) {
      let payslipUploadReasons = payslipFluctuateMetas.filter(meta => {
        return meta.attr === "payslip_reason";
      });
      if (!payslipUploadReasons.length > 0) {
        return "N/A";
      }
      let reasonValue = payslipUploadReasons[0].value;
      if (!$h.isJsonString(reasonValue)) {
        return reasonValue;
      }
      let reasonCollection = JSON.parse(reasonValue);
      let reasonData = reasonCollection.filter(reason => {
        return parseInt(reason.document_request_id) === documentId;
      });
      return reasonData[0]?.reason ?? "N/A";
    }

    function onChangeDocumentType() {
      resetDocumentTypeMessageTemplatePlaceholderValues();
      resetNewDocReqFormObject();
    }

    // function loadTemplateAsPerType() {
    //   newDocReqFormObject.description = docReqTypeMesssageTemplateParser();
    // }
    const documentTypeMessageTemplatePlaceholderValues = reactive({
      cust_name: "",
      emp_name: "",
      soa_name: "",
      date1: "",
      date2: "",
      amount: 0
    });

    watch(
      () => documentTypeMessageTemplatePlaceholderValues,
      () => {
        if (newDocReqFormObject.type !== "") {
          //loadTemplateAsPerType();
        }
      },
      { deep: true }
    );

    function resetDocumentTypeMessageTemplatePlaceholderValues() {
      documentTypeMessageTemplatePlaceholderValues.cust_name = "";
      documentTypeMessageTemplatePlaceholderValues.emp_name = "";
      documentTypeMessageTemplatePlaceholderValues.soa_name = "";
      documentTypeMessageTemplatePlaceholderValues.date1 = "";
      documentTypeMessageTemplatePlaceholderValues.date2 = "";
      documentTypeMessageTemplatePlaceholderValues.amount = 0;
    }

    const newDocReqFormObject = reactive({
      description: "",
      next_review_date: "",
      type: "",
      emp_name: "",
      soa_name: "",
      date1: "",
      date2: "",
      amount: 0,
      is_docs_required: false
    });

    function resetNewDocReqFormObject() {
      newDocReqFormObject.description = "";
      newDocReqFormObject.next_review_date = "";
      newDocReqFormObject.emp_name = "";
      newDocReqFormObject.date1 = "";
      newDocReqFormObject.date2 = "";
      newDocReqFormObject.amount = 0;
    }

    const documentRequestFormValidationMessage = ref("");
    const docRequestModelRef = ref(null);

    function validateDocumentRequestForm() {
      resetDocumentRequestFormValidationMessage();
      if (newDocReqFormObject.type === "") {
        documentRequestFormValidationMessage.value =
          "Document Type Field is required !";
      }
      if (
        newDocReqFormObject.type === "other" &&
        newDocReqFormObject.description === ""
      ) {
        documentRequestFormValidationMessage.value =
          "Document Description is required when Type is set to 'other'!";
      }

      if (
        newDocReqFormObject.type === "qna" &&
        newDocReqFormObject.description === ""
      ) {
        documentRequestFormValidationMessage.value =
          "Document question description is required when Type is set to 'qna'!";
      }

      if (
        newDocReqFormObject.type === "employment_contract" &&
        documentTypeMessageTemplatePlaceholderValues.emp_name === ""
      ) {
        documentRequestFormValidationMessage.value =
          "Employer Name is required when Document type is Employment Contract !";
      }
      if (
        newDocReqFormObject.type === "statement_of_accounts" &&
        documentTypeMessageTemplatePlaceholderValues.soa_name === ""
      ) {
        documentRequestFormValidationMessage.value =
          "Please name the company for the Statement Of Account (SOA) !";
      }
      if (
        newDocReqFormObject.type === "extra_income_request" &&
        documentTypeMessageTemplatePlaceholderValues.amount === 0
      ) {
        documentRequestFormValidationMessage.value =
          "Please provide contribution amount for extra income request!";
      }
      if (
        newDocReqFormObject.type === "recent_payslip" &&
        (documentTypeMessageTemplatePlaceholderValues.date1 === "" ||
          documentTypeMessageTemplatePlaceholderValues.date2 === "" ||
          documentTypeMessageTemplatePlaceholderValues.emp_name === "")
      ) {
        documentRequestFormValidationMessage.value =
          "Both Date 1 and Date 2 as well as Employer Name are required when Document type is Recent Payslip !";
      }
      if (
        newDocReqFormObject.type === "loan_closure" &&
        newDocReqFormObject.next_review_date === ""
      ) {
        documentRequestFormValidationMessage.value =
          "Next Review Date is required when Document type is Loan Closure !";
      }
      if (documentRequestFormValidationMessage.value !== "") {
        return false;
      }

      return true;
    }

    function resetDocumentRequestFormValidationMessage() {
      documentRequestFormValidationMessage.value = "";
    }

    const newFileAttachForDocReqObject = reactive({
      typeSlug: "",
      docReqId: 0,
      applicationId: 0
    });

    init();

    function init() {
      if (props.applicationId) {
        const docReqList = DocReqHandler.docReqList;
        store.dispatch("lnAppDocReq/storeDocTypes", {
          applicationId: props.applicationId,
          types: docReqList
        });
      } else if (props.customerId) {
        store.dispatch("lnAppDocReq/getDocumentRequestsOfCustomer", {
          customerId: props.customerId
        });
      }
    }

    const visibleGallery = ref({});

    function showUploadDocModal() {
      store.commit("lnAppUploadDocs/clearMessages");
      cash("#upload-doc-modal").modal("show");
    }

    function showNewDocReqFormModal() {
      resetDocumentRequestFormValidationMessage();
      newDocReqFormObject.description = "";
      newDocReqFormObject.title = "";
      newDocReqFormObject.type = "";
      resetDocumentTypeMessageTemplatePlaceholderValues();
      docRequestModelRef.value.resetForm();
      cash("#req-new-doc-modal").modal("show");
    }

    function clearDocReqMessages() {
      resetDocumentRequestFormValidationMessage();
      store.commit("lnAppDocReq/clearDocumentRequestMessages");
    }

    const uploadedFiles = ref();

    function uploadedFilesHandler(event) {
      uploadedFiles.value = event.target.files;
    }

    function onSubmitFile() {
      let formDataInstance = new FormData();
      /*
        Iterate over any file sent over appending the files
        to the form data.
      */

      let fileTypeValidationMessage = "";
      let fileSizeValidationMessage = "";

      if (uploadedFiles.value.length < 1 || uploadedFiles.value.length > 4) {
        alert(
          "At least 1 file must be uploaded and total maximum allowed number of files uploads is 4."
        );
        return;
      }

      for (let i = 0; i < uploadedFiles.value.length; i++) {
        if (
          !["image/png", "image/jpeg", "image/jpg", "application/pdf"].includes(
            uploadedFiles.value[i].type
          ) &&
          fileTypeValidationMessage === ""
        ) {
          fileTypeValidationMessage =
            "At least one of the uploaded file is of unsupported format. The supported file formats are pdf and images of type png,jpeg/jpg.";
        }
        if (
          uploadedFiles.value[i].size > 52428800 &&
          fileSizeValidationMessage === ""
        ) {
          fileSizeValidationMessage =
            "At least one of the uploaded file is over size limit of 50MB.";
        }
        if (
          fileTypeValidationMessage === "" &&
          fileSizeValidationMessage === ""
        ) {
          formDataInstance.append("files[" + i + "]", uploadedFiles.value[i]);
        }
      }

      let validationMessages =
        fileTypeValidationMessage + fileSizeValidationMessage;

      if (validationMessages !== "") {
        alert(validationMessages);
        return;
      }

      formDataInstance.append(
        "doc_req_type_slug",
        newFileAttachForDocReqObject.typeSlug
      );
      formDataInstance.append(
        "doc_req_id",
        newFileAttachForDocReqObject.docReqId
      );

      // for (var key of formDataInstance.entries()) {
      //   console.log(key[0] + ", " + key[1].name);
      // }
      // console.log(formDataInstance);
      let payload = {
        applicationId: newFileAttachForDocReqObject.applicationId,
        formData: formDataInstance
      };

      if (isCustomerMode) {
        payload.customerId = props.customerId;
      }
      store.commit("lnAppDocReq/setBusy");
      store.dispatch("lnAppDocReq/submitFilesToAttachOnDocRequest", payload);
    }

    function submitDocReq(payloadObject) {
      const applicationId = props.applicationId;
      if (applicationId) {
        store.commit("main/setFullPageLoading");
        cash("#req-new-doc-modal").modal("hide");

        store.dispatch("lnAppDocReq/requestForDocumentsOnLoanApplication", {
          applicationId: applicationId,
          newDocReqFormObject: payloadObject
        });
      }
    }

    function onNewDocumentReqSubmit() {
      const applicationId = props.applicationId;
      if (applicationId && validateDocumentRequestForm()) {
        if (newDocReqFormObject.type === "employment_contract") {
          newDocReqFormObject.emp_name =
            documentTypeMessageTemplatePlaceholderValues.emp_name;
        }

        if (newDocReqFormObject.type !== "qna") {
          newDocReqFormObject.is_docs_required = true;
        }

        if (newDocReqFormObject.type === "statement_of_accounts") {
          newDocReqFormObject.soa_name =
            documentTypeMessageTemplatePlaceholderValues.soa_name;
        }
        if (newDocReqFormObject.type === "recent_payslip") {
          newDocReqFormObject.date1 =
            documentTypeMessageTemplatePlaceholderValues.date1;
          newDocReqFormObject.date2 =
            documentTypeMessageTemplatePlaceholderValues.date2;
          newDocReqFormObject.emp_name =
            documentTypeMessageTemplatePlaceholderValues.emp_name;
        }
        if (newDocReqFormObject.type === "extra_income_request") {
          newDocReqFormObject.amount =
            documentTypeMessageTemplatePlaceholderValues.amount;
        }
        store.commit("main/setFullPageLoading");
        cash("#req-new-doc-modal").modal("hide");

        store.dispatch("lnAppDocReq/requestForDocumentsOnLoanApplication", {
          applicationId: applicationId,
          newDocReqFormObject: newDocReqFormObject
        });
      }
    }

    function onDocReqRemoveSubmit(docReqId, applicationId) {
      Swal.fire(buildSwalWarning("Are you sure to remove?", true)).then(
        confirmed => {
          if (confirmed.isConfirmed) {
            let payload = {
              applicationId: applicationId,
              docReqId: docReqId,
              redirect: route.name === "loan-follow-up-detail",
              router
            };

            if (isCustomerMode) {
              payload.customerId = props.customerId;
            }
            store.dispatch(
              "lnAppDocReq/removeDocumentRequestOnLoanApplication",
              payload
            );
          }
        }
      );
    }

    function onDocumentGalleryItemDownload(galleryItemInstance) {
      store.dispatch(
        "lnAppDocReq/getDocumentGalleryItemFile",
        galleryItemInstance
      );
    }

    function showMultiFileUploadModal(type_slug, id, application_id) {
      clearDocReqMessages();
      document.getElementById("browsed-doc-gallery-files").value = "";
      newFileAttachForDocReqObject.typeSlug = type_slug;
      newFileAttachForDocReqObject.docReqId = id;
      newFileAttachForDocReqObject.applicationId = application_id;
      cash("#multi-file-upload-modal").modal("show");
    }

    function hideMultiFileUploadModal() {
      cash("#multi-file-upload-modal").modal("hide");
    }

    function hideFormModals() {
      hideMultiFileUploadModal();
    }

    function showDocReqGallery(galleryObj, type) {
      visibleGallery.value = filterDocsGallery(galleryObj, type);
      cash("#loan-assessment-slide-over-preview").modal("show");
    }

    const filterDocsGallery = (documents, type) => {
      if (type in docExcludeFormats) {
        return documents.filter(
          item =>
            docExcludeFormats[type].indexOf(
              item.file_name.split(".").slice(-1)[0]
            ) === -1
        );
      }
      return documents;
    };

    const documentRequestSuccessMessage = computed(
      () => store.state.lnAppDocReq.documentRequestSuccessMessage
    );

    watch(
      () => documentRequestSuccessMessage.value,
      message => {
        if (message) {
          hideFormModals();
          if (message !== "") {
            document.getElementById(
              "ln-doc-req-success-alert-note-message-body"
            ).innerHTML = message;
            Toastify({
              node: cash("#ln-doc-req-success-alert-toast-content")
                .clone()
                .removeClass("hidden")[0],
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: "top",
              position: "right",
              stopOnFocus: true
            }).showToast();
            store.commit("lnAppDocReq/clearDocumentRequestMessages");
            store.commit("main/resetFullPageLoading");
          }
        }
      }
    );

    const documentRequestErrorMessage = computed(
      () => store.state.lnAppDocReq.documentRequestErrorMessage
    );

    watch(
      () => documentRequestErrorMessage.value,
      message => {
        if (message) {
          hideFormModals();
          if (message !== "") {
            document.getElementById(
              "ln-doc-req-error-alert-note-message-body"
            ).innerHTML = message;
            Toastify({
              node: cash("#ln-doc-req-error-alert-toast-content")
                .clone()
                .removeClass("hidden")[0],
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: "top",
              position: "right",
              stopOnFocus: true
            }).showToast();
            store.commit("lnAppDocReq/clearDocumentRequestMessages");
            store.commit("main/resetFullPageLoading");
          }
        }
      }
    );

    function reInit() {
      store.commit("lnAppDocReq/setFetchingDocReq");
      store.commit("lnAppDocReq/clearDocumentRequestList");
    }

    function numberOnly(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    }

    function convertDescriptionToUserFormat(description) {
      try {
        let json = JSON.parse(description);
        if (typeof json === "object" && "description" in json) {
          return json.description;
        }
      } catch (e) {
        // Its a fail safe only if json is valid and just a simple string as description
      }
      return description;
    }

    const isHtmlLink = name => name.split(".").slice(-1)[0] === "html";

    reInit();

    const otherDocsCount = computed(
      () => store.state.lnAppDocReq.otherDocsCount
    );

    const custId = computed(() => store.state.lnAppDocReq.customerId);

    return {
      busy,
      fetchingDocReq,
      payslipDocReqHasValidFluctuateReason,
      getPayslipFluctuateMetaAttrValue,
      getPayslipUploadReason,
      documentRequestFormValidationMessage,
      docFileUploadValidationMessage,
      newDocReqFormObject,
      visibleGallery,
      documentRequestList,
      documentTypeList,
      documentTypeMessageTemplatePlaceholderValues,
      uploadedFilesHandler,
      onChangeDocumentType,
      onSubmitFile,
      onNewDocumentReqSubmit,
      onDocReqRemoveSubmit,
      onDocumentGalleryItemDownload,
      showNewDocReqFormModal,
      showMultiFileUploadModal,
      clearDocReqMessages,
      showDocReqGallery,
      isCustomerMode,
      otherDocsCount,
      custId,
      showUploadDocModal,
      numberOnly,
      isHtmlLink,
      filterDocsGallery,
      DocReqHandler,
      docRequestModelRef,
      submitDocReq,
      convertDescriptionToUserFormat
    };
  }
});
</script>
