<template>
  <div>
    <!--    <DarkModeSwitcher />-->
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="-intro-x flex items-center pt-5">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              class="w-22 h-12"
              :src="require(`@/assets/images/logo-white.svg`)"
            />
          </a>
          <div class="my-auto">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/logo.png`)"
            />
            <div
              class="-intro-x text-white font-medium text-4xl leading-tight mt-10"
            >
              All in one portal to Manage <br />
              Loans for Fundo Lenders.
            </div>
            <div
              class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500"
            >
              Manage all your Loan Management accounts in one place
            </div>
          </div>
        </div>
        <!-- BEGIN: Request Reset Password Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
            >
              Request Password Reset
            </h2>
            <div
              class="intro-x mt-4 text-gray-700 dark:text-gray-600 text-center xl:text-left"
            >
              Enter your email and we will send you a link to reset your
              password...
            </div>
            <div class="intro-x mt-8">
              <input
                v-model="form.email"
                type="email"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                placeholder="Email"
              />
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                class="bg-yellow-500 btn py-3 px-4 w-full xl:w-32 xl:mr-3 align-top "
                @click.prevent="requestPasswordReset"
              >
                Request
              </button>
            </div>
          </div>
        </div>
        <!-- END: Request Reset Password Form -->
      </div>
    </div>
    <div
      v-if="fullPageLoading"
      class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen overflow-hidden bg-white opacity-75 flex flex-col items-center justify-center"
      style="z-index: 1000 !important;"
    >
      <div class="relative flex justify-center items-center h-3">
        <LoadingIcon icon="bars" color="green" class="w-16 h-16" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, watch, reactive } from "vue";
import Swal from "sweetalert2";
import { customSwalMessageBuilder } from "../../composables/utils/customSwalMessageBuilder";
import { useStore } from "@/store";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();

    const fullPageLoading = computed(() => store.state.main.fullPageLoading);

    const { buildSwalHtmlError, buildSwalSuccess } = customSwalMessageBuilder();

    const errorMessage = computed(() => store.state.auth.errorMessage);
    const successMessage = computed(() => store.state.auth.successMessage);

    watch(
      () => errorMessage.value,
      message => {
        store.commit("main/resetFullPageLoading");
        if (message) {
          Swal.fire(buildSwalHtmlError(message)).then(confirmed => {
            if (confirmed.isConfirmed) {
              store.commit("auth/setError");
            }
          });
        }
      }
    );

    watch(
      () => successMessage.value,
      message => {
        store.commit("main/resetFullPageLoading");
        if (message) {
          Swal.fire(buildSwalSuccess(message)).then(confirmed => {
            if (confirmed.isConfirmed) {
              router.push("/login");
            }
          });
        }
      }
    );

    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("login");
    });

    const form = reactive({
      email: null
    });

    function requestPasswordReset() {
      store.commit("main/setFullPageLoading");
      store.dispatch("auth/requestPasswordReset", form);
    }

    return {
      fullPageLoading,
      form,
      requestPasswordReset
    };
  }
});
</script>
