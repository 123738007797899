import { formatErrorMessage } from "../utils/messageFormatter";
import { axiosWrapperService } from "../services/auth/axios-wrapper-service";
import { genTabulatorJsFormatQueryStringFromParamsObject } from "../utils/QueryStringGenerator/tabulatorJsFormatQueryStringGenerator";

const state = () => {
  return {
    disbursalPayoutList: [],
    disbursalQueryParam: "",
    excelReportBlob: null,
    busyDisbursalPayoutListPage: false,
    disbursalPayoutListUpdateCounter: 0,
    payoutAccountBalance: {},
    disbursalPayoutAlertMessage: "",
    disbursalPayoutErrorMessage: "",
    disbursalPayoutSuccessMessage: ""
  };
};

// getters
const getters = {
  disbursalPayoutList: state => state.disbursalPayoutList,
  disbursalQueryParam: state => state.disbursalQueryParam,
  excelReportBlob: state => state.excelReportBlob,
  busyDisbursalPayoutListPage: state => state.busyDisbursalPayoutListPage,
  disbursalPayoutListUpdateCounter: state =>
    state.disbursalPayoutListUpdateCounter,
  payoutAccountBalance: state => state.payoutAccountBalance,
  disbursalPayoutAlertMessage: state => state.disbursalPayoutAlertMessage,
  disbursalPayoutErrorMessage: state => state.disbursalPayoutErrorMessage,
  disbursalPayoutSuccessMessage: state => state.disbursalPayoutSuccessMessage
};

// actions
const actions = {
  getAccountBalanceFromPayoutProvider: async ({ commit }) => {
    try {
      axiosWrapperService
        .axiosGet("/getAccountBalanceFromPayoutProvider")
        .then(response => {
          commit(
            "initAndSetPayoutAccountBalance",
            response.data.data.account_balance
          );
        });
    } catch (e) {
      commit("setError", "Failed to get Payout Account Balance Information.");
    }
  },
  getDisbursalPayoutList: async ({ commit }) => {
    try {
      commit("clearError");
      axiosWrapperService.axiosGet("/loanDisbursalPayouts").then(response => {
        commit("setDisbursalPayoutList", response.data.data);
      });
    } catch (e) {
      commit("setError", "Failed to get the list.");
    } finally {
      commit("clearBusy");
    }
  },
  resetDisbursalStatus: async ({ commit }, disbursalUuid) => {
    axiosWrapperService
      .axiosPatch("/resetDisbursalPayoutStatus/" + disbursalUuid)
      .then(() => {
        //dispatch("getDisbursalPayoutList");
        commit("incrementUpdateCount");
        commit(
          "setPayoutSuccessMessage",
          "Successfully renewed payout record."
        );
      })
      .catch(error => {
        commit("setError", formatErrorMessage(error, true));
      });
  },
  sendForCancellation: async ({ commit }, disbursalUuid) => {
    axiosWrapperService
      .axiosPatch("/cancelNewPayout/" + disbursalUuid)
      .then(() => {
        //dispatch("getDisbursalPayoutList");
        commit("incrementUpdateCount");
        commit(
          "setPayoutSuccessMessage",
          "Successfully cancelled payout record."
        );
      })
      .catch(error => {
        commit("setError", formatErrorMessage(error, true));
      });
  },
  sendForDisbursal: async ({ commit }, disbursalUuid) => {
    axiosWrapperService
      .axiosPost("/disburseFundViaSplit", {
        loan_disbursal_payout_uuid: disbursalUuid
      })
      .then(response => {
        //dispatch("getDisbursalPayoutList");
        if (response.status === 200) {
          commit(
            "setPayoutAlertMessage",
            "The Disbursal for this loan Account is already staged."
          );
        } else if (response.status === 201) {
          commit("setPayoutSuccessMessage", "Successfully disbursed the fund.");
        }
        commit("incrementUpdateCount");
      })
      .catch(error => {
        commit("incrementUpdateCount");
        commit("setError", formatErrorMessage(error, true));
      });
  },
  requestForExportingList: async ({ commit, state }) => {
    try {
      let params = {};
      params.responseType = "blob";
      axiosWrapperService
        .axiosGet("/exportPayout?" + state.disbursalQueryParam, params)
        .then(response => {
          commit("setExcelReportBlob", response.data);
          commit(
            "setPayoutSuccessMessage",
            "Successfully bundled Export. Please check Downloading section for progress."
          );
        });
    } catch (e) {
      commit("setError", "Failed to Export");
    } finally {
      commit("clearBusy");
    }
  }
};

// mutations
const mutations = {
  setDisbursalQueryParam(state, { params, statusParam }) {
    let queryString = statusParam !== "" ? statusParam + "&" : "";
    queryString += encodeURI(
      genTabulatorJsFormatQueryStringFromParamsObject(params)
    );
    state.disbursalQueryParam = queryString;
  },
  initAndSetPayoutAccountBalance(state, payoutAccountBalance) {
    state.payoutAccountBalance.amount = payoutAccountBalance;
  },
  unInitializePayoutAccountBalance(state) {
    state.payoutAccountBalance = {};
  },
  setDisbursalPayoutList(state, list) {
    state.disbursalPayoutList = list;
  },
  setExcelReportBlob(state, blob) {
    state.excelReportBlob = blob;
  },
  setPayoutSuccessMessage(state, disbursalPayoutSuccessMessage) {
    state.disbursalPayoutSuccessMessage = disbursalPayoutSuccessMessage;
  },
  setPayoutAlertMessage(state, disbursalPayoutAlertMessage) {
    state.disbursalPayoutAlertMessage = disbursalPayoutAlertMessage;
  },
  setError(state, disbursalPayoutErrorMessage) {
    state.disbursalPayoutErrorMessage = disbursalPayoutErrorMessage;
  },
  incrementUpdateCount(state) {
    state.disbursalPayoutListUpdateCounter++;
    state.fullPageLoading = false;
  },
  clearError(state) {
    state.disbursalPayoutErrorMessage = "";
  },
  clearMessages(state) {
    state.disbursalPayoutErrorMessage = "";
    state.disbursalPayoutAlertMessage = "";
    state.disbursalPayoutSuccessMessage = "";
    state.disbursalPayoutListUpdateCounter++;
  },
  clearAlertMessages(state) {
    state.disbursalPayoutAlertMessage = "";
  },
  setBusy(state) {
    state.busyDisbursalPayoutListPage = true;
  },
  clearBusy(state) {
    state.busyDisbursalPayoutListPage = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
