<template>
  <div class="float-right mx-4 w-full">
    <button
      v-if="customerDeleted"
      class="btn w-full h-8 text-sm sm:w-full bg-theme-32 text-white"
      disabled
      title="Remove user data from system"
      type="button"
    >
      <!--      <XCircleIcon class="w-6 h-6 mr-1 text-red-400" />-->
      Customer Identity removed
    </button>
    <button
      v-else
      class="btn w-full h-8 text-sm sm:w-full bg-red-400 text-white"
      title="Remove user data from system"
      type="button"
      @click.prevent="showConfirmationModal()"
    >
      <!--      <XCircleIcon class="w-6 h-6 mr-1 text-red-400" />-->
      Delete Customer Data
    </button>
  </div>
</template>

<script>
import { defineComponent, watch } from "vue";
import Swal from "sweetalert2";
import { customSwalMessageBuilder } from "@/composables/utils/customSwalMessageBuilder";
import { customerOperation, getCustomer } from "@/composables/customer";

export default defineComponent({
  props: {
    customerId: {
      require: true,
      type: Number,
      default: null
    },
    fullName: {
      required: true,
      type: String,
      default: null
    }
  },
  setup(props) {
    const {
      customerDeleted,
      customerErrorMessage,
      anonymizeCustomer
    } = customerOperation();

    const { init, customer } = getCustomer(props.customerId);

    if (!props.fullName) {
      init();
    }
    const { buildSwalWarning, buildCustomMessage } = customSwalMessageBuilder();

    watch(
      () => customerErrorMessage.value,
      error => {
        if (error && error.length) {
          Swal.fire(
            buildCustomMessage({
              message: error,
              type: "error",
              showConfirmButton: false
            })
          );
        }
      }
    );

    function showConfirmationModal() {
      const name = props.fullName ? props.fullName : customer.value?.full_name;
      Swal.fire(
        buildSwalWarning(
          `You are about to remove ${
            name?.length ? name : "user"
          } from system. Are you sure?`,
          true
        )
      ).then(confirmed => {
        if (confirmed.isConfirmed) {
          //call to make request to remove user
          anonymizeCustomer(props.customerId);
        }
      });
    }
    return {
      customerDeleted,
      customer,
      showConfirmationModal
    };
  }
});
</script>
