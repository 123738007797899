import { formatErrorMessage } from "@/utils/messageFormatter";
import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";

const state = () => {
  return {
    detectedPaymentCards: [],
    tokenizedPaymentCards: [],
    busy: true,
    manuallyVerifiedSuccessCount: 0
  };
};

// getters
const getters = {
  detectedPaymentCards: state => state.detectedPaymentCards,
  tokenizedPaymentCards: state => state.tokenizedPaymentCards,
  busy: state => state.busy,
  manuallyVerifiedSuccessCount: state => state.manuallyVerifiedSuccessCount
};

// actions
const actions = {
  getPaymentCardsDetailsForLoanAssessment: async (
    { dispatch, state, commit },
    { applicationId }
  ) => {
    commit("setBusy");
    axiosWrapperService
      .axiosGet(
        "/gateway-proxies/fundo-core/debit-cards/fetch-all/" + applicationId
      )
      .then(response => {
        commit("setDetectedPaymentCards", response.data.data.detected);
        commit("setTokenizedPaymentCards", response.data.data.tokenized);
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: "Lending Detail Fetch:" + formatErrorMessage(error),
            type: "error"
          },
          { root: true }
        );
      })
      .finally(() => {
        commit("resetBusy");
      });
  },
  manuallyVerifyTokenizedCard: async ({ dispatch, state, commit }, payload) => {
    commit("setBusy");
    axiosWrapperService
      .axiosPost(
        "/gateway-proxies/fundo-core/manual-verifications/tokenized-cards",
        payload
      )
      .then(response => {
        commit("incrementManuallyVerifiedSuccessCount");
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: response.data.message,
            type: "success"
          },
          { root: true }
        );
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: formatErrorMessage(error),
            type: "error"
          },
          { root: true }
        );
      })
      .finally(() => {
        commit("resetBusy");
      });
  }
};

// mutations
const mutations = {
  setBusy(state) {
    state.busy = true;
  },
  resetBusy(state) {
    state.busy = false;
  },
  setDetectedPaymentCards(state, detectedPaymentCards) {
    state.detectedPaymentCards = detectedPaymentCards;
  },
  setTokenizedPaymentCards(state, tokenizedPaymentCards) {
    state.tokenizedPaymentCards = tokenizedPaymentCards;
  },
  incrementManuallyVerifiedSuccessCount(state) {
    state.manuallyVerifiedSuccessCount++;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
