<template>
  <!-- BEGIN: Modal Content -->
  <div
    id="refund-transaction-form-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base mr-auto">
            Transaction Refund Request
          </h2>
        </div>
        <!-- END: Modal Header -->
        <!-- BEGIN: Modal Body -->
        <div
          v-if="errorMessage !== ''"
          class="alert alert-danger-soft show flex items-center mb-2"
          role="alert"
        >
          <AlertOctagonIcon class="w-6 h-6 mr-2" />
          {{ errorMessage }}
        </div>
        <div class="modal-body grid grid-cols-12">
          <div class="col-span-12 mt-2 font-bold">
            Important! Please investigate the transaction properly before
            submitting the refund request.
          </div>
          <div class="col-span-12 mt-4">
            <label class="form-label"> Refund Amount:</label>
            <input
              v-model="amount"
              type="number"
              class="form-control col-span-6 gap-3"
            />
            <div
              v-if="validationErrors.transaction_amount"
              class="p-1 text-red-600"
            >
              {{ validationErrors.transaction_amount[0] }}
            </div>
          </div>
          <div class="col-span-12 mt-2">
            <label class="form-label"> Reason for refund:</label>
            <textarea v-model="notes" type="text" class="form-control" rows="5">
            </textarea>
            <div v-if="validationErrors.refund_notes" class="p-1 text-red-600">
              {{ validationErrors.refund_notes[0] }}
            </div>
          </div>
        </div>

        <!-- END: Modal Body -->
        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer text-right">
          <button
            type="button"
            data-dismiss="modal"
            class="btn btn-dark-soft w-20 mr-1"
          >
            Cancel
          </button>
          <button
            v-if="!isBusy"
            type="button"
            class="btn btn-success w-40"
            @click="submit"
          >
            Submit
          </button>
          <button v-else class="btn btn-success w-40">
            Processing
            <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  setup() {
    const store = useStore();

    const isBusy = computed(
      () => store.getters["transactions/isProcessingRefund"]
    );

    const validationErrors = computed(
      () => store.getters["transactions/validationErrors"]
    );
    const errorMessage = computed(
      () => store.getters["transactions/errorMessage"]
    );

    const notes = ref("");
    const amount = computed({
      get: () => store.getters["transactions/refundAmount"],
      set: value => {
        store.commit("transactions/setRefundAmount", value);
      }
    });

    function submit() {
      store.dispatch("transactions/requestRefund", {
        notes: notes.value,
        amount: amount.value
      });
    }

    return {
      amount,
      notes,
      submit,
      validationErrors,
      errorMessage,
      isBusy
    };
  }
});
</script>

<style scoped></style>
