<template>
  <div
    class="bg-theme-26 bg-opacity-50 cursor-pointer shadow-md fixed bottom-0 left-4 box dark:bg-white border rounded-full w-40 h-12 flex items-center justify-center z-50 mb-10 mr-56"
  >
    <div class="mr-4 text-white">
      <ListIcon class="w-4 h-4 side-menu__icon" /> Quick Links
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  setup() {
    const store = useStore();

    function onToggleQuickNavBody() {
      store.commit("quickNavLinks/toggleIsQuickNavExpanded");
    }

    return {
      onToggleQuickNavBody
    };
  }
});
</script>
