import { onMounted, ref } from "vue";
import feather from "feather-icons";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { authGetterService } from "@/services/auth/auth-getter-service";
import { styleHelper } from "@/utils/styleHelper";
import { handleErrorResponse } from "@/services/auth/responseHandler/handle-response-service";

export const contactFailedLoans = props => {
  const tableRef = ref();
  const tabulator = ref();
  const searchTerm = ref();
  const searchType = ref("id");

  const baseUrl = `${process.env.VUE_APP_BASE_URL}/applications/getContactFailedLoans`;

  onMounted(() => {
    initTabulator();
    reInitOnResizeWindow();
  });

  const initTabulator = () => {
    tabulator.value = new Tabulator(tableRef.value, {
      ajaxURL: baseUrl,
      ajaxConfig: {
        method: "GET",
        headers: authGetterService.getAuthHeader()
      },

      ajaxResponse: function(url, params, response) {
        if (response.meta) {
          if (response.meta.last_page) {
            response["last_page"] = response.meta.last_page;
          }

          if (response.meta.current_page) {
            response["current_page"] = response.meta.current_page;
          }
        }
        return response;
      },
      filterMode: "remote",
      sortMode: "remote",
      printAsHtml: true,
      printStyled: true,
      reactiveData: true,
      pagination: true,
      paginationMode: "remote",
      paginationSize: 10,
      paginationSizeSelector: [10, 20, 30, 40],
      layout: "fitColumns",
      responsiveLayout: "collapse",
      placeholder: "No matching results",
      columns: [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false
        },

        // For HTML table
        {
          title: "Application ID",
          minWidth: 150,
          responsive: 0,
          field: "id",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="font-medium whitespace-nowrap"><a target="_blank" href="/customer/${
                  cell.getData().customer.id
                }/loan-application/${cell.getData().id}"><u>${
              cell.getData().id
            }</u></a></div>
              </div>`;
          }
        },
        {
          title: "Customer ID",
          minWidth: 100,
          width: 135,
          field: "customer.id",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="font-medium whitespace-nowrap"><a target="_blank" href="/loan-applications/customer/${
                  cell.getData().customer.id
                }"><u>${cell.getData().customer.id}</u></a></div>
              </div>`;
          }
        },
        {
          title: "Payer",
          minWidth: 200,
          field: "customer.full_name",
          hozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().customer.full_name
                }</div>
              </div>`;
          }
        },
        {
          title: "Loan Term",
          minWidth: 100,
          field: "loan_term",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${cell.getData().loan_term}</div>
              </div>`;
          }
        },
        {
          title: "Total Amount",
          minWidth: 120,
          field: "total_amount",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().total_amount
                }</div>
              </div>`;
          }
        },
        {
          title: "Status",
          minWidth: 200,
          field: "status",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="${styleHelper.getLoanStatusBadgeClass(
                  cell.getData().status
                )}">
                ${cell.getData().status_label}
                </div>
              </div>`;
          }
        },
        {
          title: "Created At",
          minWidth: 200,
          field: "created_at",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().created_at
                }</div>
              </div>`;
          }
        }
      ]
    });
    tabulator.value.on("renderComplete", function() {
      feather.replace({
        "stroke-width": 1.5
      });
    });

    tabulator.value.on("dataLoadError", function(errorResponse) {
      handleErrorResponse(errorResponse);
    });
  };

  // Redraw table onresize
  const reInitOnResizeWindow = () => {
    window.addEventListener("resize", () => {
      tabulator.value.redraw();
      feather.replace({
        "stroke-width": 1.5
      });
    });
  };

  // Filter function

  const onSearch = () => {
    let url = baseUrl;
    if (searchTerm.value) {
      url = `${baseUrl}?search=${encodeURIComponent(searchTerm.value)}`;
      if (searchType.value) {
        url += `&field=${encodeURIComponent(searchType.value)}`;
      }
    }

    tabulator.value.setData(url);
  };

  const onResetSearch = () => {
    searchTerm.value = null;
    searchType.value = "id";
    onSearch();
  };

  return {
    tableRef,
    searchTerm,
    onSearch,
    onResetSearch,
    searchType
  };
};
