export const payoutEnums = () => {
  function getStatusParamForPayoutViews(path) {
    if (path === "/new-payouts") {
      return "status_group=new";
    } else if (path === "/disbursed-payouts") {
      return "status_group=success";
    } else if (path === "/pending-payouts") {
      return "status_group=pending";
    } else if (path === "/pre-check-failed") {
      return "status=pre_disbursal_check_failed";
    } else if (path === "/failed-payouts") {
      return "status=disbursal_failed";
    }

    return "";
  }

  return {
    getStatusParamForPayoutViews
  };
};
