import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";

const state = () => {
  return {
    busy: true,
    automatedDecisions: {}
  };
};

// getters
const getters = {
  busy: state => state.busy,
  automatedDecisions: state => state.automatedDecisions
};

// actions
const actions = {
  getAutomatedDecisionsOfLoan: async (
    { state, dispatch, commit },
    applicationId
  ) => {
    commit("setBusy");
    axiosWrapperService
      .axiosGet("/getAutomatedDecisionsOfLoan/" + applicationId)
      .then(response => {
        commit("setAutomatedDecisions", response.data.data);
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("resetBusy");
        commit("main/resetFullPageLoading", null, { root: true });
      });
  }
};

// mutations
const mutations = {
  setBusy(state) {
    state.busy = true;
  },
  resetBusy(state) {
    state.busy = false;
  },
  setAutomatedDecisions(state, descisionObject) {
    state.automatedDecisions = descisionObject;
  },
  resetAutomatedDecisions(state) {
    state.automatedDecisions = {};
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
