import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";

const state = () => {
  return {
    customerId: 0,
    isBusy: false,
    errorMessage: "",
    validationErrors: [],
    successCount: 0,
    creditCheck: null
  };
};

const getters = {
  customerId: state => state.customerId,
  isBusy: state => state.isBusy,
  errorMessage: state => state.errorMessage,
  validationErrors: state => state.validationErrors,
  successCount: state => state.successCount,
  creditCheck: state => state.creditCheck
};

const mutations = {
  setCustomerId(state, customerId) {
    state.customerId = customerId;
  },
  setIsBusy(state, isBusy) {
    state.isBusy = isBusy;
  },
  setErrorMessage(state, message) {
    state.errorMessage = message;
  },
  setValidationErrors(state, validationErrors) {
    state.validationErrors = validationErrors;
  },
  clearErrors(state) {
    state.validationErrors = [];
    state.errorMessage = "";
  },
  setCreditCheck(state, data) {
    state.creditCheck = data;
  },
  incrementSuccessCount(state) {
    state.successCount++;
  }
};

const actions = {
  getCreditCheck: async ({ dispatch, state, commit }) => {
    commit("setIsBusy", true);
    axiosWrapperService
      .axiosGet(`/customers/${state.customerId}/getCreditCheck`)
      .then(response => {
        commit("setCreditCheck", response.data.data);
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("setIsBusy", false);
      });
  },
  generateCreditCheck: async ({ dispatch, state, commit }) => {
    commit("clearErrors");
    commit("setIsBusy", true);
    axiosWrapperService
      .axiosPost(`/customers/${state.customerId}/generateCreditCheck`)
      .then(response => {
        dispatch("getCreditCheck");
      })
      .catch(error => {
        commit("setIsBusy", false);

        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
