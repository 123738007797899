import { computed, onMounted, reactive, ref, watch } from "vue";
import xlsx from "xlsx";
import feather from "feather-icons";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { useStore } from "@/store";
import Swal from "sweetalert2";
import { customSwalMessageBuilder } from "./utils/customSwalMessageBuilder";
import { useRoute, useRouter } from "vue-router";
import { authGetterService } from "@/services/auth/auth-getter-service";
import { lendingRoleTypeAuthenticators } from "@/composables/utils/lendings/lendingRoleTypeAuthenticators";
import { handleErrorResponse } from "@/services/auth/responseHandler/handle-response-service";
import {
  lenderDashToQueueMapper,
  defaultOrder
} from "@/utils/LendginEnums/lending-dash-list-order-type";
import { loanQualityHelper } from "@/utils/loanQualityHelper";

export const lendingDashboard = () => {
  const { buildSwalSuccess, buildSwalHtmlError } = customSwalMessageBuilder();
  const {
    checkIfAgentAndAgentPage,
    checkIfManagerAndManagerPage,
    checkIfConverterAndConverterPage
  } = lendingRoleTypeAuthenticators();
  const tableRef = ref();
  const tabulator = ref();
  const filter = reactive({
    field: "fullname",
    type: "like",
    value: "",
    from: "",
    to: ""
  });
  const fromDate = reactive({
    field: "from",
    type: ">=",
    value: ""
  });
  const toDate = reactive({
    field: "to",
    type: "<=",
    value: ""
  });
  const newStatus = ["Assigned"];

  const approvedStatus = ["Approved"];

  const declinedStatus = ["Declined"];

  const completedStatus = ["Completed"];

  const approvalRejectionDataObject = reactive({
    uuid: "",
    note: "",
    title: "",
    type: ""
  });

  const route = useRoute();
  const router = useRouter();
  const path = computed(() => route.path);
  const store = useStore();
  const assessmentUuidToNavigate = ref("");
  const reqForAssessmentDetailsNavigationCounter = computed(
    () => store.state.assessmentBucket.reqForAssessmentDetailsNavigationCounter
  );

  watch(
    () => reqForAssessmentDetailsNavigationCounter.value,
    () => {
      if (assessmentUuidToNavigate.value !== "") {
        redirectToAssesmentDetailsPage(assessmentUuidToNavigate.value);
      }
    }
  );

  function redirectToAssesmentDetailsPage(assessmentUuidToNavigate) {
    router.push(
      router.resolve({
        name: "loan-assessment-detail",
        params: { ln_assessment_uuid: assessmentUuidToNavigate }
      })
    );
  }

  const adminUser = computed(() => store.state.auth.loggedUser);

  const assessorRoleType = computed(
    () => store.state.assessmentBucket.assessorRoleType
  );

  const assessmentBucketList = computed(
    () => store.state.assessmentBucket.assessmentBucketList
  );

  const activityStats = computed(
    () => store.state.assessmentBucket.activityStats
  );

  const recentlyAssessedAssessments = computed(
    () => store.state.assessmentBucket.recentlyAssessedAssessments
  );

  const assessmentBucketQueryParam = computed(
    () => store.state.assessmentBucket.assessmentBucketQueryParam
  );

  const assessmentBucketAlertMessage = computed(
    () => store.state.assessmentBucket.assessmentBucketAlertMessage
  );

  const toastMessage = ref("");

  watch(
    () => assessmentBucketAlertMessage.value,
    message => {
      if (message !== "") {
        toastMessage.value = message;
        store.commit("globalToastMessage/incrementToastReqCounter");
      }
    }
  );

  const assessmentBucketListUpdateCounter = computed(
    () => store.state.assessmentBucket.assessmentBucketListUpdateCounter
  );

  watch(
    () => assessmentBucketListUpdateCounter.value,
    () => {
      init();
      reInitOnResizeWindow();
      tabulator.value.replaceData();
    }
  );

  const assessmentBucketSuccessMessage = computed(
    () => store.state.assessmentBucket.assessmentBucketSuccessMessage
  );

  watch(
    () => assessmentBucketSuccessMessage.value,
    message => {
      if (message) {
        Swal.fire(buildSwalSuccess(message)).then(confirmed => {
          if (confirmed.isConfirmed) {
            store.commit("assessmentBucket/clearMessages");
          }
        });
      }
    }
  );

  const assessmentBucketErrorMessage = computed(
    () => store.state.assessmentBucket.assessmentBucketErrorMessage
  );

  watch(
    () => assessmentBucketErrorMessage.value,
    message => {
      if (message) {
        Swal.fire(buildSwalHtmlError(message)).then(confirmed => {
          if (confirmed.isConfirmed) {
            store.commit("assessmentBucket/clearMessages");
          }
        });
      }
    }
  );

  function init() {
    store.commit(
      "assessmentBucket/setAssessorRoleType",
      path.value.split("/")[2]
    );
    store.dispatch("assessmentBucket/getAdminPreferencesForDashList");
    store.dispatch("assessmentBucket/getActivityStatsOfAuthLender");
    store.dispatch("assessmentBucket/getRecentAssessmentsOfAuthLender");
  }

  function onApprovalSubmit() {
    store.commit("main/setFullPageLoading");
    store.dispatch(
      "assessmentBucket/sendForApproval",
      approvalRejectionDataObject
    );
    cash("#approval-rejection-modal").modal("hide");
  }

  function onChangeAssessmentFetchOrder() {
    store.commit("main/setFullPageLoading");
    let dashListOrder =
      lendingDashListOrderType.value !== null
        ? lendingDashListOrderType.value
        : defaultOrder;
    dashListOrder[lenderDashToQueueMapper[path.value.split("/")[2]]] =
      assessmentFetchSelectionType.value;
    store.dispatch(
      "assessmentBucket/setAdminPreferencesForDashList",
      dashListOrder
    );
  }

  function onFetchAssessmentReqSubmit() {
    store.commit("main/setFullPageLoading");
    store.dispatch(
      "assessmentBucket/fetchAssessment",
      assessmentFetchSelectionType.value
    );
  }

  function onRejectionSubmit() {
    store.commit("main/setFullPageLoading");
    store.dispatch(
      "assessmentBucket/sendForRejection",
      approvalRejectionDataObject
    );
    cash("#approval-rejection-modal").modal("hide");
  }

  function getAccessBucketsAction() {
    store.dispatch("assessmentBucket/getAccessBucketList");
  }

  function getIconNameAsPerRecordStatus(status) {
    if ("Assigned" === status) return "file-plus";
    else if ("In Progress" === status) return "clock";
    else if ("On Hold" === status) return "pause-circle";
    else if ("Request Decline" === status) return "message-circle";
    else if ("Completed" === status) return "user-check";
    else if ("Declined" === status) return "x-circle";
    else if ("In Review" === status) return "search";
    else if ("Approved" === status) return "check";
    else if ("Pre Disbursal Check" === status) return "loader";
    else if ("Pending Conversion" === status) return "loader";
    else if ("Converted" === status) return "check-square";
  }

  function getStartLabelAsPerAdminRole() {
    if (checkIfManagerAndManagerPage()) {
      return "Start Review";
    } else if (checkIfAgentAndAgentPage()) {
      return "Start Assessment";
    } else if (adminUser.value.roles.includes("lending-converter")) {
      return "Start Pre Check";
    }
  }

  function getStartActionAsPerAdminRole(ln_assessment_uuid) {
    assessmentUuidToNavigate.value = ln_assessment_uuid;
    if (checkIfManagerAndManagerPage()) {
      onStartReview(ln_assessment_uuid);
    } else if (checkIfAgentAndAgentPage()) {
      onStartAssessment(ln_assessment_uuid);
    } else if (checkIfConverterAndConverterPage()) {
      onStartPreConversionCheck(ln_assessment_uuid);
    }
  }

  function onStartAssessment(ln_assessment_uuid) {
    store.commit("main/setFullPageLoading");
    store.dispatch("assessmentBucket/startAssessment", ln_assessment_uuid);
  }

  function onStartReview(ln_assessment_uuid) {
    store.commit("main/setFullPageLoading");
    store.dispatch("assessmentBucket/startReview", ln_assessment_uuid);
  }

  function onStartPreConversionCheck(ln_assessment_uuid) {
    store.commit("main/setFullPageLoading");
    store.dispatch(
      "assessmentBucket/startPreConversionCheck",
      ln_assessment_uuid
    );
  }

  function compareExistingBucketWithNewListAndBuildAppropriateTokenMessageObject(
    newListData
  ) {
    let newRecord = true;
    if (assessmentBucketList.value.length === newListData.length) {
      newRecord = false;
      for (let item of newListData) {
        if (!assessmentBucketList.value.map(a => a.uuid).includes(item.uuid)) {
          newRecord = true;
          break;
        }
      }
    }

    if (newRecord) {
      return {
        message: "Successfully updated the Bucket",
        type: "success"
      };
    }
    return {
      message:
        "No Record to fetch or bucket limit might have reached ! New changes if any on existing records has been updated.",
      type: "success"
    };
  }

  const assessmentFetchSelectionType = ref("priority");

  const lendingDashListOrderType = computed(
    () => store.state.assessmentBucket.lendingDashListOrderType
  );

  watch(
    () => lendingDashListOrderType.value,
    () => {
      if (lendingDashListOrderType.value) {
        assessmentFetchSelectionType.value =
          lendingDashListOrderType.value[
            lenderDashToQueueMapper[path.value.split("/")[2]]
          ];
      }
    }
  );

  const settingGetCustomerConfigs = computed(
    () => store.state.assessmentBucket.settingGetCustomerConfigs
  );

  function compareExistingBucketWithNewListAndDisplayAppropriateAlert(
    listData
  ) {
    store.dispatch(
      "globalToastMessage/showSimpleToast",
      compareExistingBucketWithNewListAndBuildAppropriateTokenMessageObject(
        listData
      )
    );
  }
  store.commit("assessmentBucket/setSettingGetCustomerConfigs");
  onMounted(() => {
    init();
    initTabulator();
    reInitOnResizeWindow();
  });

  const initTabulator = () => {
    tabulator.value = new Tabulator(tableRef.value, {
      ajaxURL:
        process.env.VUE_APP_BASE_URL +
        "/getLoansForAssessmentBucket/" +
        assessorRoleType.value,
      ajaxConfig: {
        method: "GET", //set request type to GET
        headers: authGetterService.getAuthHeader()
      },
      ajaxRequesting: function(url, params) {
        store.commit("assessmentBucket/setAssessmentBucketQueryParam", {
          params
        });
        //url - the URL of the request
        //params - the parameters passed with the request
      },
      ajaxResponse: function(url, params, response) {
        //url - the URL of the request
        //params - the parameters passed with the request
        //response - the JSON object returned in the body of the response.

        if (assessmentBucketListUpdateCounter.value > 0) {
          compareExistingBucketWithNewListAndDisplayAppropriateAlert(
            response.data
          );
        }

        store.commit("assessmentBucket/setAssessmentBucketList", response.data);
        return response.data; //return the response data to tabulator
      },
      filterMode: "remote",
      sortMode: "remote",
      printAsHtml: true,
      printStyled: true,
      reactiveData: true, //enable reactive data
      layout: "fitColumns",
      responsiveLayout: "collapse",
      placeholder: "No matching records found",
      columns: [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false
        },

        // For HTML table
        {
          title: "CUSTOMER",
          minWidth: 150,
          responsive: 0,
          field: "fullname",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            return `<div>
                <div class="font-medium whitespace-nowrap"><a target="_blank" href="https://fundo.com.au/adminpanel/payments/schedules/${
                  cell.getData().application_id
                }"><u>${cell.getData().fullname}</u></a></div>
                <div class="text-gray-600 text-xs whitespace-nowrap"><i>${
                  cell.getData().email
                }</i></div>
                <div class="text-gray-600 text-xs whitespace-nowrap">${
                  cell.getData().mobile
                }</div>
              </div>`;
          }
        },
        {
          title: "AMOUNT",
          minWidth: 100,
          field: "requested_loan_amount",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            return `<div>
                <div class="font-medium whitespace-nowrap">${
                  cell.getData().loan.total_amount
                }</div>
              </div>`;
          }
        },
        {
          title: "Requested At",
          minWidth: 100,
          field: "created_at",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            return `<div>
                <div class="font-medium whitespace-nowrap">${
                  cell.getData().created_at
                }</div>
                </div>`;
          }
        },
        {
          title: "STATUS",
          minWidth: 100,
          field: "status",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            return `<div class="flex items-center lg:justify-center ${
              newStatus.includes(cell.getData().status)
                ? "text-theme-9"
                : approvedStatus.includes(cell.getData().status) ||
                  completedStatus.includes(cell.getData().status)
                ? "text-blue-400"
                : declinedStatus.includes(cell.getData().status)
                ? "text-red-600"
                : "text-yellow-400"
            }">
                <i data-feather="${getIconNameAsPerRecordStatus(
                  cell.getData().status
                )}" class="w-4 h-4 mr-2"></i> ${cell.getData().status}
              </div>`;
          }
        },
        {
          title: "ACTIONS",
          minWidth: 100,
          field: "ACTIONS",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            let showHightest = false;
            const a = cash(
              `<div class="flex lg:justify-left items-left"></div>`
            );
            const c = cash(`<div></div>`);
            if (
              adminUser.value.permissions.includes("lendings.assess") &&
              (cell.getData().status === "Assigned" ||
                cell.getData().status === "On Hold")
            ) {
              cash(a)
                .append(`<a class="flex items-left text-blue-400 mr-3" href="javascript:;">
                  <i data-feather="play" class="w-4 h-4 mr-1"></i> ${
                    cell.getData().status === "On Hold"
                      ? "Resume"
                      : getStartLabelAsPerAdminRole()
                  }
                </a>`);
              cash(cash(a).children()[0]).on("click", function() {
                getStartActionAsPerAdminRole(cell.getData().uuid);
              });
            } else if (
              adminUser.value.permissions.includes("lendings.approve") &&
              cell.getData().status === "Completed"
            ) {
              showHightest = true;
              cash(a)
                .append(`<a class="flex items-left text-blue-400 mr-3" href="javascript:;">
                  <i data-feather="pen-tool" class="w-4 h-4 mr-1"></i> ${getStartLabelAsPerAdminRole()}
                </a>`);
              cash(cash(a).children()[0]).on("click", function() {
                getStartActionAsPerAdminRole(cell.getData().uuid);
              });
            } else if (
              adminUser.value.permissions.includes("lendings.convert") &&
              (cell.getData().status === "Approved" ||
                cell.getData().status === "Request Decline")
            ) {
              cash(a)
                .append(`<a class="flex items-left text-blue-400 mr-3" href="javascript:;">
                  <i data-feather="play" class="w-4 h-4 mr-1"></i> ${getStartLabelAsPerAdminRole()}
                </a>`);
              cash(cash(a).children()[0]).on("click", function() {
                getStartActionAsPerAdminRole(cell.getData().uuid);
              });
            } else if (
              (adminUser.value.permissions.includes("lendings.convert") ||
                adminUser.value.permissions.includes("lendings.assess") ||
                adminUser.value.permissions.includes("lendings.approve")) &&
              ["In Progress", "In Review", "Pre Conversion Check"].includes(
                cell.getData().status
              )
            ) {
              cash(a).append(`<a class="flex items-left text-blue-400 mr-3">
                  <i data-feather="list" class="w-4 h-4 mr-1"></i> View Details
                </a>`);
              cash(cash(a).children()[0]).on("click", function() {
                redirectToAssesmentDetailsPage(cell.getData().uuid);
              });
            }
            const {
              color,
              quality
            } = loanQualityHelper.getLoanQualityClassByQuality(
              cell.getData()?.loan?.model_assessment_quality,
              "board"
            );
            cell.getRow().getElement().style.backgroundColor = color;
            //If app is highest quality put icon
            if (quality === "highest") {
              if (showHightest) {
                cash(a).append(
                  `<i data-feather="plus" class="w-4 h-4 mr-1 stroke-[2px]"></i>`
                );
              } else {
                cell.getRow().getElement().style.backgroundColor =
                  loanQualityHelper.colors["high"];
              }
            }

            cash(c).append(a[0]);
            return c[0];
          }
        }
      ]
    });

    tabulator.value.on("dataLoadError", function(errorResponse) {
      handleErrorResponse(errorResponse);
    });

    tabulator.value.on("renderComplete", function() {
      feather.replace({
        "stroke-width": 1.5
      });
    });
  };

  // Redraw table onresize
  const reInitOnResizeWindow = () => {
    window.addEventListener("resize", () => {
      tabulator.value.redraw();
      feather.replace({
        "stroke-width": 1.5
      });
    });
  };

  // Filter function
  const onFilter = () => {
    const filterBuilder = [];
    filterBuilder.push({
      field: filter.field,
      type: filter.type,
      value: filter.value
    });
    if (fromDate.value !== "") {
      filterBuilder.push({
        field: fromDate.field,
        type: fromDate.type,
        value: fromDate.value
      });
    }
    if (toDate.value !== "") {
      filterBuilder.push({
        field: toDate.field,
        type: toDate.type,
        value: toDate.value
      });
    }

    tabulator.value.setFilter(filterBuilder);
  };

  // On reset filter
  const onResetFilter = () => {
    filter.field = "fullname";
    filter.type = "like";
    filter.value = "";

    fromDate.field = "from";
    fromDate.type = ">=";
    fromDate.value = "";

    toDate.field = "to";
    toDate.type = "<=";
    toDate.value = "";
    onFilter();
  };

  // Export
  const onExportCsv = () => {
    tabulator.value.download("csv", "data.csv");
  };

  const onExportJson = () => {
    tabulator.value.download("json", "data.json");
  };

  const onExportXlsx = () => {
    const win = window;
    win.XLSX = xlsx;
    tabulator.value.download("xlsx", "data.xlsx", {
      sheetName: "Products"
    });
  };

  const onExportHtml = () => {
    tabulator.value.download("html", "data.html", {
      style: true
    });
  };

  // Print
  const onPrint = () => {
    tabulator.value.print();
  };

  const onExport = () => {
    store.commit("main/setFullPageLoading");
    store.dispatch(
      "assessmentBucket/requestForExportingList",
      assessmentBucketQueryParam.value
    );
  };

  return {
    adminUser,
    tableRef,
    fromDate,
    toDate,
    filter,
    onFilter,
    onResetFilter,
    onExportCsv,
    onExportJson,
    onExportXlsx,
    onExportHtml,
    onPrint,
    onExport,
    path,
    assessmentBucketList,
    activityStats,
    recentlyAssessedAssessments,
    getAccessBucketsAction,
    approvalRejectionDataObject,
    onFetchAssessmentReqSubmit,
    onApprovalSubmit,
    onRejectionSubmit,
    init,
    assessmentFetchSelectionType,
    settingGetCustomerConfigs,
    onChangeAssessmentFetchOrder
  };
};
