import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";

const state = () => {
  return {
    isProcessing: false,
    applicationId: 0,
    successMessage: "",
    errorMessage: "",
    validationErrors: [],
    createMultipleSchedulesSuccessCount: 0
  };
};

const getters = {
  successMessage: state => state.successMessage,
  errorMessage: state => state.errorMessage,
  validationErrors: state => state.validationErrors,
  createMultipleSchedulesSuccessCount: state =>
    state.createMultipleSchedulesSuccessCount
};

const mutations = {
  setApplicationId(state, applicationId) {
    state.applicationId = applicationId;
  },
  setSuccessMessage(state, message) {
    state.successMessage = message;
  },
  setErrorMessage(state, message) {
    state.errorMessage = message;
  },
  setValidationErrors(state, validationErrors) {
    state.validationErrors = validationErrors;
  },

  setIsProcessing(state, isProcessing) {
    state.isProcessing = isProcessing;
  },

  incrementCreateMultipleSchedulesSuccessCount(state) {
    state.createMultipleSchedulesSuccessCount++;
  },

  clearErrors(state) {
    state.validationErrors = [];
    state.errorMessage = "";
  }
};

const actions = {
  createMultipleSchedules: async ({ dispatch, state, commit }, form) => {
    commit("setIsProcessing", true);
    commit("clearErrors");
    axiosWrapperService
      .axiosPost(
        `/applications/${state.applicationId}/createPaymentSchedules`,
        form
      )
      .then(() => {
        commit("incrementCreateMultipleSchedulesSuccessCount");
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: "Payment Schedules Created Successfully!",
            type: "success"
          },
          { root: true }
        );
      })
      .catch(error => {
        if (error.response?.data?.errors) {
          commit("setValidationErrors", error.response.data.errors);
        } else {
          commit("setErrorMessage", formatErrorMessage(error));
        }
      })
      .finally(() => {
        commit("setIsProcessing", false);
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
