<template>
  <div>
    <template v-if="Dependency?.valid && Dependency?.value?.length">
      <p>
        <strong>For {{ Dependency.title }}: {{ Dependency.value }}</strong>
      </p>
    </template>
    <span>{{ convertDescriptionToUserFormat(description) }}</span>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import DocReqHandler from "@/utils/DocumentRequests/Handler";

export default defineComponent({
  components: {},
  props: {
    convertDescriptionToUserFormat: {
      type: Function,
      required: false
    },
    slug: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const Dependency = reactive({
      valid: false,
      title: "",
      value: ""
    });
    init();

    function init() {
      slugExistsInDependencies(props.slug);
    }

    function getHintsVarFromDescription() {
      try {
        let json = JSON.parse(props.description);
        if (
          typeof json === "object" &&
          "hints" in json &&
          "var" in json.hints
        ) {
          return json.hints.var;
        }
      } catch (e) {}
      return "";
    }

    function slugExistsInDependencies(slug) {
      Object.keys(DocReqHandler.lenderDependentDocReq).some(function(
        index,
        value
      ) {
        if (DocReqHandler.lenderDependentDocReq[index].values.includes(slug)) {
          Dependency.valid = true;
          Dependency.title = DocReqHandler.lenderDependentDocReq[index].title;
          Dependency.value = getHintsVarFromDescription();
          return true;
        }
        return false;
      });
    }
    return {
      slugExistsInDependencies,
      Dependency
    };
  }
});
</script>
