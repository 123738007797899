import invalidateToken from "@/services/auth/invalidate-token-service";

export function handleResponse(responsePromise) {
  return new Promise((resolve, reject) => {
    responsePromise
      .then(success => resolve(success))
      .catch(error => {
        handleErrorResponse(error.response);
        reject(error);
      });
  });
}

export function handleErrorResponse(errorResponse) {
  if (errorResponse?.status === 401) {
    invalidateToken();
  }
}
