import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import globalComponents from "./global-components";
import utils from "./utils";
import "./libs";
import axios from "axios";
import VueAxios from "vue-axios";
import identifyUser from "@/services/user-identify-service";
// import CKEditor from "@ckeditor/ckeditor5-vue";

// SASS Theme
import "./assets/sass/app.scss";

const app = createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios);

// appending Authorization in http default headers common
const token = localStorage.getItem("acccess_token");
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

const userJson = localStorage.getItem("user");
if (userJson) {
  const user = JSON.parse(localStorage.getItem("user"));
  identifyUser(user);
}

globalComponents(app);
utils(app);

app.mount("#app");
