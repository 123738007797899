<template>
  <div class="py-2 max-h-[32rem] overflow-auto">
    <strong class="font-medium">History</strong>
    <div v-for="(records, index) in data" :key="index" class="font-medium">
      <div class="flex justify-start items-center px-1 py-2 text-center">
        <span class="bg-theme-32 px-2 py-1 rounded-lg text-xs">{{
          getDateOrTimeFromString(index)
        }}</span>
        <div class="flex-grow h-px bg-gray-400"></div>
      </div>
      <ul class="text-theme-7">
        <li v-for="(record, key) in records" :key="key" class="p-1">
          <div class="flex items-center justify-between">
            <span class="w-24 text-xs">{{
              getDateOrTimeFromString(record.date, false)
            }}</span>
            <span
              class="bg-theme-32 flex items-center justify-center rounded-full w-8 h-6"
            >
              <Edit2Icon class="w-3 h-3" />
            </span>
            <small class="pl-2 w-80">
              {{ userFormatMessage(record, category) }}
            </small>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import moment from "moment";

export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true,
      default: _ => {}
    },
    category: {
      type: String,
      required: true
    }
  },
  setup(props) {
    //Conversion
    function getDateOrTimeFromString(datetime, date = true) {
      return date
        ? moment(datetime).format("MMMM D, Y")
        : moment(datetime).format("HH:mm A");
    }

    //Message
    const userFormatMessage = (record, category) =>
      record.is_added
        ? `${record.agent_name} added ${record.changed} to ${category}`
        : `${record.agent_name} updated ${category} to ${record.changed}`;

    return {
      getDateOrTimeFromString,
      userFormatMessage
    };
  }
});
</script>
<style scoped />
