<template>
  <!-- BEGIN: Modal Content -->
  <div
    id="schedule-date-note-form-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base mr-auto">
            {{ type }}
          </h2>
        </div>
        <!-- END: Modal Header -->
        <!-- BEGIN: Modal Body -->
        <div
          v-if="errorMessage !== ''"
          class="alert alert-danger-soft show flex items-center mb-2"
          role="alert"
        >
          <AlertOctagonIcon class="w-6 h-6 mr-2" />
          {{ errorMessage }}
        </div>
        <div class="modal-body grid grid-cols-12">
          <div class="col-span-12 grid grid-cols-2">
            <div class="mt-2">
              <label class="form-label"> {{ type }} Date:</label>
              <div class="relative w-full mx-auto">
                <input
                  type="date"
                  v-model="form.date"
                  class="form-control w-11/12"
                />
              </div>
              <div v-if="validationErrors.date" class="p-1 text-red-600">
                {{ validationErrors.date[0] }}
              </div>
            </div>
          </div>
          <div class="col-span-12 mt-2">
            <label class="form-label"> Notes:</label>
            <textarea
              id="modal-form-1"
              v-model="form.notes"
              type="text"
              class="form-control"
              rows="5"
            >
            </textarea>
            <div v-if="validationErrors.notes" class="p-1 text-red-600">
              {{ validationErrors.notes[0] }}
            </div>
          </div>
        </div>
        <!-- END: Modal Body -->
        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer text-right">
          <button
            type="button"
            data-dismiss="modal"
            class="btn btn-dark-soft w-20 mr-1"
          >
            Cancel
          </button>
          <button
            v-if="!isProcessing"
            type="button"
            class="btn btn-success w-40"
            @click="submitForm(form)"
          >
            Submit
          </button>
          <button v-else class="btn btn-success w-40">
            Processing
            <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { defineComponent } from "vue";
import { dateNoteForm } from "@/composables/components/payment-schedules-categorized-by-type/payment-schedule-actions/dateNoteForm";

export default defineComponent({
  setup(props) {
    const {
      form,
      submitForm,
      validationErrors,
      errorMessage,
      isProcessing,
      type
    } = dateNoteForm(props);

    return {
      form,
      submitForm,
      validationErrors,
      errorMessage,
      isProcessing,
      type
    };
  }
});
</script>

<style scoped></style>
