import { axiosWrapperService } from "../services/auth/axios-wrapper-service";

const state = () => {
  return {};
};

// getters
const getters = {};

// actions
const actions = {
  requestForExport: async ({ commit, state }, { query }) => {
    try {
      commit("main/setFullPageLoading", null, { root: true });
      let params = {};
      params.responseType = "blob";
      axiosWrapperService
        .axiosGet(
          "/applications/exportUnsignedApprovedLoansForFollowup?" + query,
          params
        )
        .then(response => {
          const blob = new Blob([response.data]);

          const blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.setAttribute("download", "unsigned-loans.xlsx");
          link.click();
          link.remove();
          URL.revokeObjectURL(blobUrl);
          commit("main/resetFullPageLoading", null, { root: true });
        });
    } catch (e) {
      commit("main/resetFullPageLoading", null, { root: true });
    }
  }
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
