<template>
  <!-- BEGIN: Payment Schedules Categorized -->

  <CreateMultipleSchedules :application-id="applicationId" />
  <PaymentScheduleDateNoteForm />
  <EditAmountForm />

  <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
    <button
      class="btn mr-auto box"
      @click="showCreateMultipleSchedulesModal"
    >
      + Create Multiple Schedules
    </button>

    <!--    Begin LoanActions   -->
    <LoanActions :application-id="applicationId" :customer-id="customerId" />
    <!--    End PaymentScheduleActionsLoanActions   -->
  </div>

  <div v-if="showRepayments">
    <h2 class="font-medium text-theme-7 text-base mt-6 mr-auto">
      REPAYMENTS
    </h2>
    <PaymentScheduleTable type="NULL" :application-id="applicationId" />
  </div>

  <div v-if="showReschedules">
    <h2 class="font-medium text-theme-7 text-base mt-6 mr-auto">
      RESCHEDULES
    </h2>
    <PaymentScheduleTable type="RSC" :application-id="applicationId" />
  </div>

  <div v-if="showPromises">
    <h2 class="font-medium text-theme-7 text-base mt-6 mr-auto">
      PROMISES
    </h2>
    <PaymentScheduleTable type="PTP" :application-id="applicationId" />
  </div>

  <h2 class="font-medium text-theme-7 text-base mt-6 mr-auto">
    Transaction Details
  </h2>
  <!-- END: Payment Schedules Categorized-->
</template>

<script>
import { defineComponent, computed, watch } from "vue";
import PaymentScheduleTable from "@/components/payment-schedules-categorized-by-type/PaymentScheduleTable";
import CreateMultipleSchedules from "@/components/payment-schedules-categorized-by-type/CreateMultipleSchedules";
import PaymentScheduleDateNoteForm from "@/components/payment-schedules-categorized-by-type/payment-schedule-actions/PaymentScheduleDateNoteForm";
import EditAmountForm from "@/components/payment-schedules-categorized-by-type/payment-schedule-actions/EditAmountForm";
import LoanActions from "@/components/payment-schedules-categorized-by-type/loan-actions/Main";
import { useStore } from "@/store";

export default defineComponent({
  components: {
    EditAmountForm,
    PaymentScheduleTable,
    CreateMultipleSchedules,
    PaymentScheduleDateNoteForm,
    LoanActions
  },
  props: {
    applicationId: {
      type: Number,
      require: true
    },
    customerId: {
      type: Number,
      require: true
    },
    showRepayments: {
      type: Boolean,
      require: false,
      default: true
    },
    showReschedules: {
      type: Boolean,
      require: false,
      default: true
    },
    showPromises: {
      type: Boolean,
      require: false,
      default: true
    }
  },
  setup(props) {
    const store = useStore();

    function showCreateMultipleSchedulesModal() {
      cash("#create-multiple-schedules-modal").modal("show");
    }

    function dismissMultipleSchedulesForm() {
      cash("#create-multiple-schedules-modal").modal("hide");
    }

    function dismissDateNoteForm() {
      cash("#schedule-date-note-form-modal").modal("hide");
    }

    function dismissEditAmountForm() {
      cash("#edit-amount-form-modal").modal("hide");
    }

    function reloadData() {
      if (props.showRepayments) {
        store.dispatch("paymentScheduleTable/getPaymentSchedules", {
          applicationId: props.applicationId,
          type: "NULL"
        });
      }

      if (props.showReschedules) {
        store.dispatch("paymentScheduleTable/getPaymentSchedules", {
          applicationId: props.applicationId,
          type: "RSC"
        });
      }

      if (props.showPromises) {
        store.dispatch("paymentScheduleTable/getPaymentSchedules", {
          applicationId: props.applicationId,
          type: "PTP"
        });
      }
    }

    const createMultipleSchedulesSuccessCount = computed(
      () =>
        store.getters[
          "createMultipleSchedules/createMultipleSchedulesSuccessCount"
        ]
    );

    const dateNoteFormSuccessCount = computed(
      () =>
        store.getters["paymentScheduleDateNoteForm/dateNoteFormSuccessCount"]
    );

    const editAmountFormSuccessCount = computed(
      () => store.getters["editAmountForm/editAmountSuccessCount"]
    );

    const loanActionSuccessCount = computed(
      () => store.getters["paymentScheduleLoanActions/successCount"]
    );

    watch(
      () => loanActionSuccessCount.value,
      () => {
        reloadData();
      }
    );

    watch(
      () => dateNoteFormSuccessCount.value,
      () => {
        dismissDateNoteForm();
        reloadData();
      }
    );

    watch(
      () => createMultipleSchedulesSuccessCount.value,
      () => {
        dismissMultipleSchedulesForm();
        reloadData();
      }
    );

    watch(
      () => editAmountFormSuccessCount.value,
      () => {
        dismissEditAmountForm();
        reloadData();
      }
    );

    return {
      showCreateMultipleSchedulesModal
    };
  }
});
</script>
