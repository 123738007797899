<template>
  <div>
    <div v-if="!fetchingLoanNotes">
      <div class="flex justify-start align-middle">
        <h2 class="text-lg font-medium">
          Loan Notes
        </h2>
      </div>
      <div v-if="loanNotes && loanNotes.length > 0" class="w-full">
        <table class="table">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                Notes
              </th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                Next Work Date
              </th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                Activity Capture
              </th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                Created By
              </th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                Created At
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="loan in loanNotes"
              :key="loan.id"
              class="hover:bg-gray-200"
            >
              <td>{{ loan.id }}</td>
              <td>{{ loan.notes }}</td>
              <td>{{ loan.next_work_date }}</td>
              <td>{{ loan.activity_capture }}</td>
              <td>{{ loan.modified_by.full_name }}</td>
              <td>{{ loan.created_at }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <h4 class="font-normal mt-5">No notes found</h4>
      </div>
    </div>
    <FetchingRecordDivTemplate v-else />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { getLoanNotes } from "../../composables/loanNotes";
import FetchingRecordDivTemplate from "@/components/fetching-record-div-template/Main";

export default defineComponent({
  components: { FetchingRecordDivTemplate },
  props: {
    customerId: {
      type: Number,
      required: false
    },
    loanApplicationId: {
      type: Number,
      require: false,
      default: null
    }
  },
  setup(props) {
    const { loanNotes, fetchingLoanNotes } = getLoanNotes(props);

    return {
      loanNotes,
      fetchingLoanNotes
    };
  }
});
</script>
<style scoped></style>
