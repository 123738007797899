<template>
  <div>
    <textarea
      @blur="update"
      @keyup.enter="update"
      v-model="value"
      :rows="rows"
      :cols="cols"
      class="px-3 py-1 w-full rounded-lg col-span-6 border border-gray-300 gap-3"
      placeholder=""
    >
    </textarea>
  </div>
</template>

<script setup>
import { defineProps, ref, onMounted, defineEmits } from "vue";

const emit = defineEmits(["update"]);
//Props
const props = defineProps([
  "details",
  "name",
  "index",
  "type"
]);

//Data
const value = ref(null);

const rows = ref(2);

const cols = ref(5);

//Value - Update
function update(e) {
  if (props.type === "income") {
    if (value.value.trim() !== props.details["description"].trim()) {
      emit("update", value.value);
    }
  }
  e.preventDefault();
}

//Mounted lifecycle
onMounted(() => {
  if (props.type === "income") {
    value.value = props.details["description"];
  }
});
</script>

<style scoped>
</style>
