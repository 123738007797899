import { parseMessage } from "../utils/GobalToastMessage/MessageParser";
const state = () => {
  return {
    isSimple: false,
    toastMessageIcon: "",
    toastMessageTitle: "",
    toastMessageBody: "",
    toastMessageType: "warning",
    toastMessageToParse: "",
    toastReqCounter: 0
  };
};

// getters
const getters = {
  isSimple: state => state.isSimple,
  toastMessageIcon: state => state.toastMessageIcon,
  toastMessageTitle: state => state.toastMessageTitle,
  toastMessageBody: state => state.toastMessageBody,
  toastMessageType: state => state.toastMessageType,
  toastMessageToParse: state => state.toastMessageToParse,
  toastReqCounter: state => state.toastReqCounter
};

// actions
const actions = {
  setToastAttributes(
    { state, commit },
    { toastMessageIcon, toastMessageTitle, toastMessageBody }
  ) {
    commit("setToastMessageIcon", toastMessageIcon);
    commit("setToastMessageTitle", toastMessageTitle);
    commit("setToastMessageBody", toastMessageBody);
    commit("incrementToastReqCounter");
  },
  parseAndSetToastAttributes({ dispatch, state }, messageToParse) {
    let parsedMessage = parseMessage(messageToParse);
    state.isSimple = false;
    dispatch("setToastAttributes", {
      toastMessageIcon: parsedMessage.icon,
      toastMessageTitle: parsedMessage.title,
      toastMessageBody: parsedMessage.body
    });
  },
  showSimpleToast({ state, commit }, { message, type }) {
    state.isSimple = true;
    state.toastMessageType = type;
    commit("setToastMessageBody", message);
    commit("incrementToastReqCounter");
  }
};

// mutations
const mutations = {
  incrementToastReqCounter(state) {
    state.toastReqCounter++;
  },
  setIsSimple(state, isSimple) {
    state.isSimple = isSimple;
  },
  setToastMessageIcon(state, toastMessageIcon) {
    state.toastMessageIcon = toastMessageIcon;
  },
  setToastMessageTitle(state, toastMessageTitle) {
    state.toastMessageTitle = toastMessageTitle;
  },
  setToastMessageBody(state, toastMessageBody) {
    state.toastMessageBody = toastMessageBody;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
