import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { buildCustomerAddressAttribute } from "@/utils/customerAttributesBuilder";
import { formatErrorMessage } from "@/utils/messageFormatter";
import {
  getLendingAssessmentModifierRole,
  getLendingAssessmentAccessType
} from "@/composables/utils/lendings/LendingAssessmentAccessTypeHelper";
import router from "@/router";
import { sortRecordAndVerify } from "@/utils/verifications";
// import lendingDetailsJson from "../samples/lending-details-response.json";
// import incomeExpensesDetailsJson from "../samples/income-expenses-response.json";

const state = () => {
  return {
    applicationId: 0,
    customerId: 0,
    customerName: "",
    loanAssessmentUuid: "",
    fetchingApplicantLoans: true,
    fetchingApplicantBankAccounts: true,
    fetchingPersonalLoansDetails: true,
    updatingPersonalLoansDetails: true,
    fetchingIncomeExpensesDetails: true,
    updatingIncomeExpensesDetails: false,
    fetchingLendingDetails: true,
    updatingLendingDetails: false,
    loanAssessment: {},
    assessmentState: "",
    kycWithAdditionalAttributes: {
      basicAttributes: {},
      derivedAttributes: {}
    },
    aggregatorPersonalLoanDetails: {},
    aggregatorLoanDetails: {},
    aggregatorLoanDetailsTransitLobby: {},
    aggregatorIncomeExpenseDetails: {},
    aggregatorIncomeExpenseDetailsTransitLobby: {},
    bankAccountOfApplicantLinkedToAssessment: [],
    latestBankStatement: {},
    bankReportsFetchCounter: 0,
    allBankReports: [],
    pastLoansOfApplicantLinkedToAssessment: [],
    documentRequestList: [],
    documentTypeList: [],
    busy: false,
    applicationTransitionReasonTypes: {},
    assesseable: false,
    withdrawable: false,
    canBeRequestedToDecline: false,
    completeable: false,
    reviewable: false,
    approvable: false,
    preConvertionCheckeable: false,
    preConvertionCanBeFinalizedOrFailed: false,
    reAssignable: false,
    assesmentBucketUpdateReqCounter: 0,
    loanAssessmentFetchCounter: 0,
    docFileUploadValidationMessage: "",
    loanAssessmentDetailAlertMessage: "",
    loanAssessmentDetailErrorMessage: "",
    loanAssessmentDetailSuccessMessage: "",
    loanAssessmentOwners: [],
    toggleUrl: false,
    enabledAgreggatorDataField: "",
    activeDescriptions: [],
    modificationAdminsRoles: {},
    activeInputId: "",
    saccGraceInformation: {},
    isCustomerNameVerified: false
  };
};

// getters
const getters = {
  applicationId: state => state.applicationId,
  customerId: state => state.customerId,
  loanAssessment: state => state.loanAssessment,
  assessmentState: state => state.assessmentState,
  loanAssessmentUuid: state => state.loanAssessmentUuid,
  fetchingApplicantLoans: state => state.fetchingApplicantLoans,
  fetchingApplicantBankAccounts: state => state.fetchingApplicantBankAccounts,
  fetchingPersonalLoansDetails: state => state.fetchingPersonalLoansDetails,
  updatingPersonalLoansDetails: state => state.updatingPersonalLoansDetails,
  fetchingIncomeExpensesDetails: state => state.fetchingIncomeExpensesDetails,
  updatingIncomeExpensesDetails: state => state.updatingIncomeExpensesDetails,
  fetchingLendingDetails: state => state.fetchingLendingDetails,
  updatingLendingDetails: state => state.updatingLendingDetails,
  kycWithAdditionalAttributes: state => state.kycWithAdditionalAttributes,
  aggregatorPersonalLoanDetails: state => state.aggregatorPersonalLoanDetails,
  aggregatorLoanDetails: state => state.aggregatorLoanDetails,
  aggregatorLoanDetailsTransitLobby: state =>
    state.aggregatorLoanDetailsTransitLobby,
  aggregatorIncomeExpenseDetails: state => state.aggregatorIncomeExpenseDetails,
  aggregatorIncomeExpenseDetailsTransitLobby: state =>
    state.aggregatorIncomeExpenseDetailsTransitLobby,
  bankAccountOfApplicantLinkedToAssessment: state =>
    state.bankAccountOfApplicantLinkedToAssessment,
  latestBankStatement: state => state.latestBankStatement,
  allBankReports: state => state.allBankReports,
  pastLoansOfApplicantLinkedToAssessment: state =>
    state.pastLoansOfApplicantLinkedToAssessment,
  documentRequestList: state => state.documentRequestList,
  assesmentBucketUpdateReqCounter: state =>
    state.assesmentBucketUpdateReqCounter,
  loanAssessmentFetchCounter: state => state.loanAssessmentFetchCounter,
  documentTypeList: state => state.documentTypeList,
  busy: state => state.busy,
  applicationTransitionReasonTypes: state =>
    state.applicationTransitionReasonTypes,
  assesseable: state => state.assesseable,
  withdrawable: state => state.withdrawable,
  canBeRequestedToDecline: state => state.canBeRequestedToDecline,
  completeable: state => state.completeable,
  reviewable: state => state.reviewable,
  approvable: state => state.approvable,
  preConvertionCheckeable: state => state.preConvertionCheckeable,
  preConvertionCanBeFinalizedOrFailed: state =>
    state.preConvertionCanBeFinalizedOrFailed,
  reAssignable: state => state.reAssignable,
  docFileUploadValidationMessage: state => state.docFileUploadValidationMessage,
  loanAssessmentDetailAlertMessage: state =>
    state.loanAssessmentDetailAlertMessage,
  loanAssessmentDetailErrorMessage: state =>
    state.loanAssessmentDetailErrorMessage,
  loanAssessmentDetailSuccessMessage: state =>
    state.loanAssessmentDetailSuccessMessage,
  loanAssessmentOwners: state => state.loanAssessmentOwners,
  toggleUrl: state => state.toggleUrl,
  activeDescriptions: state => state.activeDescriptions,
  modificationAdminsRoles: state => state.modificationAdminsRoles,
  activeInputId: state => state.activeInputId,
  saccGraceInformation: state => state.saccGraceInformation,
  isCustomerNameVerified: state => state.isCustomerNameVerified
};

// actions
const actions = {
  getLendersByRoleForOwnerSelectionList: ({ commit, state }, role) => {
    commit("setBusy", true);
    return axiosWrapperService
      .axiosGet(`/getAdminsByRole/${role}`)
      .then(response => {
        commit("setLoanAssessmentOwners", response.data.data);
        return response.data;
      })
      .catch(error => {
        commit("setLoanAssessmentOwners", []);
        throw error;
      })
      .finally(() => {
        commit("resetBusy");
      });
  },
  getLoanAssessment: async ({ dispatch, state, commit }, adminUser) => {
    try {
      commit("clearLoanAssessmentDetailMessages");
      axiosWrapperService
        .axiosGet("/loanAssessments/" + state.loanAssessmentUuid)
        .then(response => {
          commit("setLoanAssessment", response.data.data);
          if (
            !["Applied", "Approved"].includes(response.data.data.loan.status)
          ) {
            router.push({
              name: "loan-application-details",
              params: {
                customerId: response.data.data.user_id,
                applicationId: response.data.data.application_id
              }
            });
          }
          commit(
            "setAssessmentState",
            getLendingAssessmentAccessType(response.data.data)
          );
          dispatch(
            "loanQuality/sortAndSetApplicationQualityByScore",
            {
              score: response.data.data.loan?.model_assessment_quality,
              section: "application"
            },
            { root: true }
          );
          commit("setCustomerName", response.data.data.loan.customer.full_name);
          commit("setApplicationId", response.data.data.application_id);
          commit("setCustomerId", response.data.data.user_id);
          commit("incrementLoanAssessmentFetchCounter");
        })
        .finally(() => {
          commit("main/resetFullPageLoading", null, { root: true });
        });
    } catch (e) {
      commit("setLoanAssessmentDetailError", "Failed to get the list.");
    }
  },
  getKycAttributesForLoanAssessment: async ({ state, commit }) => {
    try {
      commit("clearLoanAssessmentDetailMessages");
      axiosWrapperService
        .axiosGet(
          "/applications/" + state.applicationId + "/getKycAttributesOfLoan"
        )
        .then(response => {
          commit(
            "customerDetails/setCustomerDeleted",
            !!response.data.data?.is_anonymous,
            {
              root: true
            }
          );
          commit(
            "clearAndPushToKycWithAdditionalAttributes",
            response.data.data
          );
          commit("main/resetFullPageLoading", null, { root: true });
        })
        .catch(error => {
          commit(
            "setLoanAssessmentDetailError",
            formatErrorMessage(error, true)
          );
          commit("main/resetFullPageLoading", null, { root: true });
        });
    } catch (e) {
      commit("setLoanAssessmentDetailError", "Failed to get the list.");
    }
  },
  getAggregatorLendingDetailsForLoanAssessment: async (
    { dispatch, state, commit },
    { showLoader = true }
  ) => {
    //Main Loader
    if (showLoader) commit("setFetchingLendingDetails", true);
    commit("clearLoanAssessmentDetailMessages");
    axiosWrapperService
      .axiosGet(
        "/gateway-proxies/fundo-core/loan-configuration/fetch/" +
          state.applicationId
      )
      .then(response => {
        commit("setAggregatorLoanDetails", response.data.data.categories);
        commit(
          "setIsCustomerNameVerified",
          sortRecordAndVerify(
            response.data.data.categories?.loan_summary?.data?.customer_name
              ?.verification
          )
        );
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: "Lending Detail Fetch:" + formatErrorMessage(error),
            type: "error"
          },
          { root: true }
        );
      })
      .finally(() => {
        commit("main/resetFullPageLoading", null, { root: true });
        commit("setFetchingLendingDetails", false);
        commit("setUpdatingLendingDetails", false);
      });
    // commit("setAggregatorLoanDetails", lendingDetailsJson.data.categories);
  },
  submitAggregatorLendingDetails: async (
    { dispatch, state, commit },
    { postData, id = "" }
  ) => {
    postData.application_id = state.applicationId;
    commit("setUpdatingLendingDetails", true);
    commit("setUpdatingPersonalLoans", true);
    commit("setUpdatingIncomeExpensesDetails", true);
    axiosWrapperService
      .axiosPost(
        "/gateway-proxies/fundo-core/loan-configuration/reconfigure",
        postData
      )
      .then(response => {
        commit("setAggregatorLoanDetails", response.data.data.categories);
        commit("setActiveInputId", id);
        dispatch("getAggregatorLendingDetailsForLoanAssessment", {
          showLoader: false
        });
        dispatch("getAggregatorPersonalLoansForLoanAssessment", {
          showLoader: false
        });
        dispatch("getAggregatorIncomeExpensesDetailsForLoanAssessment", {
          showLoader: false
        });
        dispatch("getClosableSaccLoanDetails");
        commit("setLoanAssessmentDetailAlert", "Successfully Updated.");
      })
      .catch(error => {
        commit("setUpdatingLendingDetails", false);
        commit("setUpdatingPersonalLoans", false);
        commit("setUpdatingIncomeExpensesDetails", false);
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: formatErrorMessage(error),
            type: "error"
          },
          { root: true }
        );
      })
      .finally(() => {
        commit("main/resetFullPageLoading", null, { root: true });
      });
  },
  updateAggregatorIncomeExpenses: async (
    { dispatch, state, commit },
    { postData, id = "" }
  ) => {
    postData.application_id = state.applicationId;
    commit("setUpdatingLendingDetails", true);
    commit("setUpdatingPersonalLoans", true);
    commit("setUpdatingIncomeExpensesDetails", true);
    axiosWrapperService
      .axiosPost("/gateway-proxies/fundo-core/income-expenses/update", postData)
      .then(response => {
        commit("setAggregatorLoanDetails", response.data.data.categories);
        commit(
          "setAggregatorIncomeExpensesDetails",
          state.aggregatorIncomeExpenseDetailsTransitLobby
        );
        commit("setActiveInputId", id);
        dispatch("getAggregatorLendingDetailsForLoanAssessment", {
          showLoader: false
        });
        dispatch("getAggregatorPersonalLoansForLoanAssessment", {
          showLoader: false
        });
        dispatch("getAggregatorIncomeExpensesDetailsForLoanAssessment", {
          showLoader: false
        });
        commit("setLoanAssessmentDetailAlert", "Successfully Updated.");
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: formatErrorMessage(error),
            type: "error"
          },
          { root: true }
        );
      })
      .finally(() => {
        commit("main/resetFullPageLoading", null, { root: true });
      });
  },
  updateAggregatorIncomeExpensesDescription: async (
    { dispatch, state, commit },
    postData
  ) => {
    postData.application_id = state.applicationId;
    return axiosWrapperService
      .axiosPost(
        "/gateway-proxies/fundo-core/income-expenses/update-description",
        postData
      )
      .then(res => {
        dispatch("getAggregatorIncomeExpensesDetailsForLoanAssessment", {
          showLoader: false
        });
        commit(
          "setLoanAssessmentDetailAlert",
          `Descripiton successfully ${
            postData.description.length ? "updated" : "cleared"
          }.`
        );
        return res?.data;
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: formatErrorMessage(error),
            type: "error"
          },
          { root: true }
        );
      })
      .finally(() => {
        commit("main/resetFullPageLoading", null, { root: true });
      });
  },
  getAggregatorPersonalLoansForLoanAssessment: async (
    { dispatch, state, commit },
    { showLoader = true }
  ) => {
    commit("clearLoanAssessmentDetailMessages");

    if (showLoader) commit("setFetchingPersonalLoansDetails", true);
    axiosWrapperService
      .axiosGet(
        "/gateway-proxies/fundo-core/personal-loans/fetch-all/" +
          state.applicationId
      )
      .then(response => {
        commit("setAggregatorPersonalLoanDetails", response.data.data);
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: "Personal Loan Fetch:" + formatErrorMessage(error),
            type: "error"
          },
          { root: true }
        );
      })
      .finally(() => {
        commit("main/resetFullPageLoading", null, { root: true });
        commit("resetFetchingPersonalLoansDetails");
        commit("setUpdatingPersonalLoans", false);
      });
  },
  updateAggregatorPersonalLoansRepaymentAmount: async (
    { dispatch, commit },
    { postData, id }
  ) => {
    commit("setUpdatingLendingDetails", true);
    commit("setUpdatingPersonalLoans", true);
    commit("setUpdatingIncomeExpensesDetails", true);
    axiosWrapperService
      .axiosPost(
        "/gateway-proxies/fundo-core/personal-loans/repayment-amount-update",
        postData
      )
      .then(() => {
        commit("setLoanAssessmentDetailAlert", "Successfully Updated.");
        commit("setActiveInputId", "");
        dispatch("getAggregatorPersonalLoansForLoanAssessment", {
          showLoader: false
        });
        dispatch("getAggregatorIncomeExpensesDetailsForLoanAssessment", {
          showLoader: false
        });
        dispatch("getAggregatorLendingDetailsForLoanAssessment", {
          showLoader: false
        });
        commit("setLoanAssessmentDetailAlert", "Successfully Updated.");
        commit("setActiveInputId", id);
      })
      .catch(error => {
        commit("resetAggregatorLoanDetailsTransitLobby");
        commit("resetAggregatorIncomeExpensesDetailsTransitLobby");
        commit("setUpdatingLendingDetails", false);
        commit("setUpdatingPersonalLoans", false);
        commit("setUpdatingIncomeExpensesDetails", false);
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: formatErrorMessage(error),
            type: "error"
          },
          { root: true }
        );
      })
      .finally(() => {
        commit("main/resetFullPageLoading", null, { root: true });
      });
  },
  updateAggregatorPersonalLoansStatus: async (
    { dispatch, commit },
    { postData, id }
  ) => {
    commit("setUpdatingLendingDetails", true);
    commit("setUpdatingPersonalLoans", true);
    commit("setUpdatingIncomeExpensesDetails", true);
    axiosWrapperService
      .axiosPost(
        "/gateway-proxies/fundo-core/personal-loans/status/update",
        postData
      )
      .then(() => {
        commit("setActiveInputId", "");
        dispatch("getAggregatorPersonalLoansForLoanAssessment", {
          showLoader: false
        });
        dispatch("getAggregatorIncomeExpensesDetailsForLoanAssessment", {
          showLoader: false
        });
        dispatch("getAggregatorLendingDetailsForLoanAssessment", {
          showLoader: false
        });
        dispatch("getClosableSaccLoanDetails");
        commit("setLoanAssessmentDetailAlert", "Successfully Updated.");
        commit("setActiveInputId", id);
      })
      .catch(error => {
        commit("resetAggregatorLoanDetailsTransitLobby");
        commit("resetAggregatorIncomeExpensesDetailsTransitLobby");
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: formatErrorMessage(error),
            type: "error"
          },
          { root: true }
        );
      })
      .finally(() => {
        commit("main/resetFullPageLoading", null, { root: true });
      });
  },
  updateAggregatorPersonalLoansType: async (
    { dispatch, commit },
    { postData, id }
  ) => {
    commit("setUpdatingLendingDetails", true);
    commit("setUpdatingPersonalLoans", true);
    commit("setUpdatingIncomeExpensesDetails", true);
    axiosWrapperService
      .axiosPost(
        "/gateway-proxies/fundo-core/personal-loans/type/update",
        postData
      )
      .then(() => {
        commit("setActiveInputId", "");
        dispatch("getAggregatorPersonalLoansForLoanAssessment", {
          showLoader: false
        });
        dispatch("getAggregatorIncomeExpensesDetailsForLoanAssessment", {
          showLoader: false
        });
        dispatch("getAggregatorLendingDetailsForLoanAssessment", {
          showLoader: false
        });
        dispatch("getClosableSaccLoanDetails");
        commit("setLoanAssessmentDetailAlert", "Successfully Updated.");
        commit("setActiveInputId", id);
      })
      .catch(error => {
        commit("resetAggregatorLoanDetailsTransitLobby");
        commit("resetAggregatorIncomeExpensesDetailsTransitLobby");
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: formatErrorMessage(error),
            type: "error"
          },
          { root: true }
        );
      })
      .finally(() => {
        commit("main/resetFullPageLoading", null, { root: true });
      });
  },
  getAggregatorIncomeExpensesDetailsForLoanAssessment: async (
    { dispatch, state, commit },
    { showLoader = true }
  ) => {
    //Main Loader
    if (showLoader) commit("setFetchingIncomeExpensesDetails", true);
    commit("clearLoanAssessmentDetailMessages");
    axiosWrapperService
      .axiosGet(
        "/gateway-proxies/fundo-core/income-expenses/fetch-all/" +
          state.applicationId
      )
      .then(response => {
        if (response.data.data !== undefined) {
          commit(
            "setAggregatorIncomeExpensesDetails",
            response.data.data.categories
          );
        }
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: "Income Expense Fetch:" + formatErrorMessage(error),
            type: "error"
          },
          { root: true }
        );
      })
      .finally(() => {
        commit("main/resetFullPageLoading", null, { root: true });
        commit("setFetchingIncomeExpensesDetails", false);
        commit("setUpdatingIncomeExpensesDetails", false);
      });
  },
  updateLegacyLmsApplicationBankingIncomeAccount: async (
    { dispatch, state, commit },
    postData
  ) => {
    postData.application_id = state.applicationId;

    axiosWrapperService
      .axiosPost(
        "/gateway-proxies/fundo-core/banking/income-account/change",
        postData
      )
      .then(response => {
        dispatch("getBankAccountsOfApplicantLinkedToAssessment");
        commit(
          "setAggregatorLoanDetails",
          response.data.data.loan_details.categories
        );
        commit(
          "setAggregatorIncomeExpensesDetails",
          response.data.data.income_expenses.categories
        );
        dispatch(
          "paymentCards/getPaymentCardsDetailsForLoanAssessment",
          {
            applicationId: state.applicationId,
            adminId: postData.admin_id
          },
          { root: true }
        );
        dispatch("getAggregatorLendingDetailsForLoanAssessment", {
          showLoader: true
        });
        dispatch("getAggregatorPersonalLoansForLoanAssessment", {
          showLoader: true
        });
        dispatch("getAggregatorIncomeExpensesDetailsForLoanAssessment", {
          showLoader: true
        });
        commit(
          "setLoanAssessmentDetailAlert",
          "Successfully Updated Banking Income Account."
        );
      })
      .catch(error => {
        commit("setLoanAssessmentDetailError", formatErrorMessage(error, true));
      })
      .finally(() => {
        commit("main/resetFullPageLoading", null, { root: true });
      });
  },
  getLoansHistoryOfApplicantAssociatedToApplication: async ({
    state,
    commit
  }) => {
    commit("clearLoanAssessmentDetailMessages");
    commit("setPastLoansOfApplicantLinkedToAssessment", []);
    commit("setFetchingApplicantLoans", true);
    axiosWrapperService
      .axiosGet(
        "/getLoansHistoryOfApplicantAssociatedToApplication/" +
          state.applicationId
      )
      .then(response => {
        commit("setPastLoansOfApplicantLinkedToAssessment", response.data.data);
      })
      .catch(error => {
        commit("setLoanAssessmentDetailError", formatErrorMessage(error, true));
      })
      .finally(() => {
        commit("main/resetFullPageLoading", null, { root: true });
        commit("setFetchingApplicantLoans", false);
      });
  },
  getBankAccountsOfApplicantLinkedToAssessment: async ({
    dispatch,
    state,
    commit
  }) => {
    commit("setFetchingApplicantBankAccounts", true);
    axiosWrapperService
      .axiosGet(
        `/gateway-proxies/fundo-core/banking/linked-accounts/${state.applicationId}`
      )
      .then(response => {
        commit(
          "setBankAccountOfApplicantLinkedToAssessment",
          response.data.data.accounts
        );
        commit(
          "setLastedBankStatement",
          response.data.data.latest_bank_statement
        );
      })
      .catch(error => {
        commit("setBankAccountOfApplicantLinkedToAssessment", []);
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message:
              "Applicant Bank Account Fetch:" + formatErrorMessage(error),
            type: "error"
          },
          { root: true }
        );
      })
      .finally(() => {
        commit("main/resetFullPageLoading", null, { root: true });
        commit("setFetchingApplicantBankAccounts", false);
      });
  },
  getAllBankStatements: async ({ dispatch, state, commit }) => {
    axiosWrapperService
      .axiosGet(
        `/gateway-proxies/fundo-core/banking/user-reports/${state.customerId}`
      )
      .then(response => {
        commit("setAllBankStatements", response.data.data);
        commit("incrementBankReportsFetchCounter");
      })
      .catch(error => {
        commit("setAllBankStatements", []);
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: "Bank Statement Fetch:" + formatErrorMessage(error),
            type: "error"
          },
          { root: true }
        );
      })
      .finally(_ => {
        commit("main/resetFullPageLoading", null, { root: true });
      });
  },
  getDocumentRequestsOfLoan: async ({ state, commit }) => {
    commit("clearLoanAssessmentDetailMessages");
    axiosWrapperService
      .axiosGet("/getDocumentRequestsOfLoan/" + state.applicationId)
      .then(response => {
        commit("clearAndPushToDocumentRequestList", response.data.data);
      })
      .catch(error => {
        commit("setLoanAssessmentDetailError", formatErrorMessage(error, true));
      })
      .finally(() => {
        commit("main/resetFullPageLoading", null, { root: true });
      });
  },
  getDocumentGalleryItemFile: async ({ commit }, galleryItemInstance) => {
    commit("clearLoanFollowUpDetailMessages");
    axiosWrapperService
      .axiosGet("/getDocumentGalleryItemFile/" + galleryItemInstance.id, {
        responseType: "blob"
      })
      .then(response => {
        const pdfBlob = new Blob([response.data]);

        const blobUrl = window.URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", galleryItemInstance.file_name);
        link.click();
        link.remove();
        URL.revokeObjectURL(blobUrl);
      })
      .catch(error => {
        commit("setLoanAssessmentDetailError", formatErrorMessage(error, true));
      })
      .finally(() => {
        commit("main/resetFullPageLoading", null, { root: true });
      });
  },
  getAllApplicationTransitionReasons: async ({ commit }) => {
    commit("clearLoanAssessmentDetailMessages");
    axiosWrapperService
      .axiosGet("/getAllApplicationTransitionReasons")
      .then(response => {
        commit("setApplicationTransitionReasonTypes", response.data.data);
      })
      .catch(error => {
        commit("setLoanAssessmentDetailError", formatErrorMessage(error, true));
      })
      .finally(() => {
        commit("main/resetFullPageLoading", null, { root: true });
      });
  },
  singleAssignAssessmentToLender: async (
    { state, commit },
    { role_type, assessment_uuid }
  ) => {
    try {
      commit("clearLoanAssessmentDetailMessages");
      commit("main/setFullPageLoading", null, { root: true });
      axiosWrapperService
        .axiosPatch(
          "/singleAssignAssessmentToLender/" + role_type + "/" + assessment_uuid
        )
        .then(response => {
          commit("setLoanAssessment", response.data.data);
        })
        .catch(error => {
          commit(
            "setLoanAssessmentDetailError",
            formatErrorMessage(error, true)
          );
        })
        .finally(() => {
          commit("main/resetFullPageLoading", null, { root: true });
        });
    } catch (e) {
      commit("setError", "Failed to get the list.");
    }
  },
  changeAssessmentOwner: async (
    { dispatch, state, commit },
    { role_type, assessment_uuid, owner_id }
  ) => {
    try {
      commit("clearLoanAssessmentDetailMessages");
      commit("main/setFullPageLoading", null, { root: true });
      await axiosWrapperService
        .axiosPatch(
          `/changeAssessmentAssignee/${role_type}/${assessment_uuid}/${owner_id}`
        )
        .then(response => {
          dispatch(
            "globalToastMessage/showSimpleToast",
            {
              message: "Assessment owner changed successfully!",
              type: "success"
            },
            { root: true }
          );
          commit("setLoanAssessment", response.data.data);
        })
        .catch(error => {
          commit(
            "setLoanAssessmentDetailError",
            formatErrorMessage(error, true)
          );
        })
        .finally(() => {
          commit("main/resetFullPageLoading", null, { root: true });
        });
    } catch (e) {
      commit("setError", "Failed to update owner.");
    }
  },
  reAssignAssessment: async ({ commit }, lnAssessmentFinalizationObj) => {
    try {
      commit("clearLoanAssessmentDetailMessages");
      axiosWrapperService
        .axiosPatch("/reAssignAssessment/" + lnAssessmentFinalizationObj.uuid, {
          note: lnAssessmentFinalizationObj.note
        })
        .then(response => {
          commit("setLoanAssessment", response.data.data);
          commit("incrementAssesmentBucketUpdateReqCounter");
          commit(
            "setLoanAssessmentDetailSuccess",
            "Successfully Re Assigned the loan Assessment."
          );
        })
        .catch(error => {
          commit(
            "setLoanAssessmentDetailError",
            formatErrorMessage(error, true)
          );
        })
        .finally(() => {
          commit("main/resetFullPageLoading", null, { root: true });
        });
    } catch (e) {
      commit("setError", "Failed to get the list.");
    }
  },
  withdrawLoanApplication: async ({ commit }, lnAssessmentFinalizationObj) => {
    try {
      commit("clearLoanAssessmentDetailMessages");
      axiosWrapperService
        .axiosPatch(
          "/withdrawLoanApplication/" + lnAssessmentFinalizationObj.uuid,
          {
            note: lnAssessmentFinalizationObj.note
          }
        )
        .then(response => {
          commit("setLoanAssessment", response.data.data);
          commit("incrementAssesmentBucketUpdateReqCounter");
          commit(
            "setLoanAssessmentDetailSuccess",
            "Successfully Withdrawn Application."
          );
        })
        .catch(error => {
          commit(
            "setLoanAssessmentDetailError",
            formatErrorMessage(error, true)
          );
        })
        .finally(() => {
          commit("main/resetFullPageLoading", null, { root: true });
        });
    } catch (e) {
      commit("setError", "Failed to get the list.");
    }
  },
  requestToDeclineOnLoanApplication: async (
    { commit },
    lnAssessmentFinalizationObj
  ) => {
    try {
      commit("clearLoanAssessmentDetailMessages");
      axiosWrapperService
        .axiosPatch(
          "/requestToDeclineOnLoanApplication/" +
            lnAssessmentFinalizationObj.uuid,
          {
            note: lnAssessmentFinalizationObj.note,
            transition_reason_id:
              lnAssessmentFinalizationObj.transition_reason_type
          }
        )
        .then(response => {
          commit("setLoanAssessment", response.data.data);
          commit("incrementAssesmentBucketUpdateReqCounter");
          commit(
            "setLoanAssessmentDetailSuccess",
            "Successfully lodged Request To Decline Application."
          );
        })
        .catch(error => {
          commit(
            "setLoanAssessmentDetailError",
            formatErrorMessage(error, true)
          );
        })
        .finally(() => {
          commit("main/resetFullPageLoading", null, { root: true });
        });
    } catch (e) {
      commit("setError", "Failed to get the list.");
    }
  },
  declineApplication: async ({ commit }, lnAssessmentFinalizationObj) => {
    try {
      commit("clearLoanAssessmentDetailMessages");
      axiosWrapperService
        .axiosPatch(
          "/declineLoanApplication/" + lnAssessmentFinalizationObj.uuid,
          {
            note: lnAssessmentFinalizationObj.note,
            reapplication_activation_date:
              lnAssessmentFinalizationObj.reapplication_activation_date,
            transition_reason_id:
              lnAssessmentFinalizationObj.transition_reason_type
          }
        )
        .then(response => {
          commit("setLoanAssessment", response.data.data);
          commit("incrementAssesmentBucketUpdateReqCounter");
          commit(
            "setLoanAssessmentDetailSuccess",
            "Successfully Performed Action."
          );
        })
        .catch(error => {
          commit(
            "setLoanAssessmentDetailError",
            formatErrorMessage(error, false)
          );
        })
        .finally(() => {
          commit("main/resetFullPageLoading", null, { root: true });
        });
    } catch (e) {
      commit("setError", "Failed to get the list.");
    }
  },
  holdAssesment: async ({ commit }, lnAssessmentUuid) => {
    try {
      commit("clearLoanAssessmentDetailMessages");
      axiosWrapperService
        .axiosPatch("/putLoanAssessmentToHold/" + lnAssessmentUuid)
        .then(response => {
          commit("setLoanAssessment", response.data.data);
          commit("incrementAssesmentBucketUpdateReqCounter");
          commit(
            "setLoanAssessmentDetailSuccess",
            "Successfully Performed Action."
          );
        })
        .catch(error => {
          commit(
            "setLoanAssessmentDetailError",
            formatErrorMessage(error, true)
          );
        })
        .finally(() => {
          commit("main/resetFullPageLoading", null, { root: true });
        });
    } catch (e) {
      commit("setError", "Failed to get the list.");
    }
  },
  completeAssesment: async ({ commit }, lnAssessmentFinalizationObj) => {
    try {
      commit("clearLoanAssessmentDetailMessages");
      axiosWrapperService
        .axiosPatch(
          "/setLoanAssessmentAsCompleted/" + lnAssessmentFinalizationObj.uuid,
          {
            note: lnAssessmentFinalizationObj.note
          }
        )
        .then(response => {
          commit("setLoanAssessment", response.data.data);
          commit("incrementAssesmentBucketUpdateReqCounter");
          commit(
            "setLoanAssessmentDetailSuccess",
            "Successfully Performed Action."
          );
        })
        .catch(error => {
          commit(
            "setLoanAssessmentDetailError",
            formatErrorMessage(error, true)
          );
        })
        .finally(() => {
          commit("main/resetFullPageLoading", null, { root: true });
        });
    } catch (e) {
      commit("setError", "Failed to get the list.");
    }
  },
  approveApplication: async (
    { commit, dispatch },
    { lnAssessmentFinalizationObj, applicationId }
  ) => {
    try {
      commit("clearLoanAssessmentDetailMessages");
      axiosWrapperService
        .axiosPatch(
          "/approveLoanApplication/" + lnAssessmentFinalizationObj.uuid,
          {
            note: lnAssessmentFinalizationObj.note
          }
        )
        .then(response => {
          commit("setLoanAssessment", response.data.data);
          commit("incrementAssesmentBucketUpdateReqCounter");
          commit(
            "setLoanAssessmentDetailSuccess",
            "Successfully Performed Action."
          );
        })
        .catch(error => {
          dispatch(
            "loanTags/getLendingNonErrorTagsAttachedToLoan",
            { applicationId: applicationId, messageOnSuccess: "" },
            {
              root: true
            }
          );
          commit(
            "setLoanAssessmentDetailError",
            formatErrorMessage(error, true)
          );
        })
        .finally(() => {
          commit("main/resetFullPageLoading", null, { root: true });
        });
    } catch (e) {
      commit("setError", "Failed to get the list.");
    }
  },
  failPreConversionCheck: async ({ commit }, lnAssessmentFinalizationObj) => {
    try {
      commit("clearLoanAssessmentDetailMessages");
      axiosWrapperService
        .axiosPatch(
          "/setLoanAssessmentStatusAsPreDisbursalFailed/" +
            lnAssessmentFinalizationObj.uuid,
          {
            note: lnAssessmentFinalizationObj.note
          }
        )
        .then(response => {
          commit("setLoanAssessment", response.data.data);
          commit("incrementAssesmentBucketUpdateReqCounter");
          commit(
            "setLoanAssessmentDetailSuccess",
            "Successfully Performed Action."
          );
        })
        .catch(error => {
          commit(
            "setLoanAssessmentDetailError",
            formatErrorMessage(error, true)
          );
        })
        .finally(() => {
          commit("main/resetFullPageLoading", null, { root: true });
        });
    } catch (e) {
      commit("setError", "Failed to get the list.");
    }
  },
  finalizePreConversionCheck: async (
    { commit, dispatch },
    { lnAssessmentFinalizationObj, applicationId }
  ) => {
    try {
      commit("clearLoanAssessmentDetailMessages");
      axiosWrapperService
        .axiosPatch("/convertAssessment/" + lnAssessmentFinalizationObj.uuid, {
          note: lnAssessmentFinalizationObj.note
        })
        .then(response => {
          commit("setLoanAssessment", response.data.data);
          commit("incrementAssesmentBucketUpdateReqCounter");
          commit(
            "setLoanAssessmentDetailSuccess",
            "Successfully Performed Action."
          );
        })
        .catch(error => {
          dispatch(
            "loanTags/getLendingNonErrorTagsAttachedToLoan",
            { applicationId: applicationId, messageOnSuccess: "" },
            {
              root: true
            }
          );
          dispatch(
            "globalToastMessage/showSimpleToast",
            { message: formatErrorMessage(error), type: "error" },
            { root: true }
          );
        })
        .finally(() => {
          commit("main/resetFullPageLoading", null, { root: true });
        });
    } catch (e) {
      commit("setError", "Failed to get the list.");
    }
  },
  fetchOrAddModificationAdminsRole: ({ commit }, adminId) => {
    try {
      commit("setModificationAdminsRole", { adminId, role: null });
      axiosWrapperService
        .axiosGet(`/getAdminById/${adminId}`)
        .then(response => {
          commit("setModificationAdminsRole", {
            adminId,
            role: getLendingAssessmentModifierRole(response.data.data.roles)
          });
        })
        .catch(error => {
          commit(
            "setLoanAssessmentDetailError",
            formatErrorMessage(error, true)
          );
        });
    } catch (e) {
      commit("setError", "Failed to get admin using ID:" + adminId);
    }
  },
  verifyBankAccountOfLoan: async (
    { commit, state, dispatch },
    { type, value, adminId }
  ) => {
    commit("main/setFullPageLoading", null, { root: true });
    try {
      //Verify property using payload
      const payload = {
        application_id: state.applicationId,
        admin_id: adminId,
        verified_data: value
      };
      axiosWrapperService
        .axiosPost(
          "/gateway-proxies/fundo-core/manual-verifications/bank-account/" +
            value,
          payload
        )
        .then(_ => {
          //After verification method invoke
          dispatch("getBankAccountsOfApplicantLinkedToAssessment");
          dispatch(
            "paymentCards/getPaymentCardsDetailsForLoanAssessment",
            {
              applicationId: state.applicationId,
              adminId: adminId
            },
            { root: true }
          );
          dispatch("getAggregatorLendingDetailsForLoanAssessment", {
            showLoader: false
          });
          dispatch("getAggregatorPersonalLoansForLoanAssessment", {
            showLoader: false
          });
          dispatch("getAggregatorIncomeExpensesDetailsForLoanAssessment", {
            showLoader: false
          });
        })
        .catch(error => {
          commit(
            "setLoanAssessmentDetailError",
            formatErrorMessage(error, true)
          );
        })
        .finally(() => {
          commit("main/resetFullPageLoading", null, { root: true });
        });
    } catch (e) {
      commit("setError", "Failed to verify " + type);
    }
  },
  verifyLoanAssessmentProperty: async (
    { commit, state, dispatch },
    { type, value, adminId }
  ) => {
    commit("main/setFullPageLoading", null, { root: true });
    try {
      //Verify property using payload
      const payload = {
        application_id: state.applicationId,
        admin_id: adminId,
        verified_data: value
      };
      axiosWrapperService
        .axiosPost(
          `/gateway-proxies/fundo-core/manual-verifications/${type}`,
          payload
        )
        .then(_ => {
          //After verification method invoke
          dispatch("getAggregatorLendingDetailsForLoanAssessment", {
            showLoader: true
          });
        })
        .catch(error => {
          commit(
            "setLoanAssessmentDetailError",
            formatErrorMessage(error, true)
          );
        })
        .finally(() => {
          commit("main/resetFullPageLoading", null, { root: true });
        });
    } catch (e) {
      commit("setError", "Failed to verify " + type);
    }
  },
  getClosableSaccLoanDetails: ({ dispatch, commit, state }) => {
    return axiosWrapperService
      .axiosGet(
        `/gateway-proxies/fundo-core/personal-loans/sacc-grace/${state.applicationId}/status`
      )
      .then(response => {
        commit("setSaccGraceInformation", response.data);
      })
      .catch(error => {
        commit("setSaccGraceInformation", {});
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: "Error fetching closable Sacc loan details",
            type: "error"
          },
          { root: true }
        );
        throw error;
      });
  },
  closeSaccLoans({ dispatch, commit, state }) {
    return axiosWrapperService
      .axiosPost(
        `/gateway-proxies/fundo-core/personal-loans/sacc-grace/${state.applicationId}/enable`
      )
      .then(response => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: "Graced eligible Sacc Loans Successfully",
            type: "success"
          },
          { root: true }
        );
        dispatch("getClosableSaccLoanDetails");
        dispatch("getAggregatorLendingDetailsForLoanAssessment", {
          showLoader: false
        });
        dispatch("getAggregatorPersonalLoansForLoanAssessment", {
          showLoader: false
        });
        dispatch("getAggregatorIncomeExpensesDetailsForLoanAssessment", {
          showLoader: false
        });
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: "Failed enabling SACC grace",
            type: "error"
          },
          { root: true }
        );
        throw error;
      });
  },
  resetClosedSaccLoans({ dispatch, commit, state }) {
    return axiosWrapperService
      .axiosPost(
        `/gateway-proxies/fundo-core/personal-loans/sacc-grace/${state.applicationId}/reset`
      )
      .then(response => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: "Graced Sacc Loans Reset Successfully",
            type: "success"
          },
          { root: true }
        );
        dispatch("getClosableSaccLoanDetails");
        dispatch("getAggregatorLendingDetailsForLoanAssessment", {
          showLoader: false
        });
        dispatch("getAggregatorPersonalLoansForLoanAssessment", {
          showLoader: false
        });
        dispatch("getAggregatorIncomeExpensesDetailsForLoanAssessment", {
          showLoader: false
        });
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: "Failed disabling SACC grace",
            type: "error"
          },
          { root: true }
        );
        throw error;
      });
  }
};

// mutations
const mutations = {
  setSaccGraceInformation(state, saccGraceInformation) {
    state.saccGraceInformation = saccGraceInformation;
  },
  setFetchingApplicantLoans(state, status = true) {
    state.fetchingApplicantLoans = status;
  },
  setFetchingPersonalLoansDetails(state) {
    state.fetchingPersonalLoansDetails = true;
  },
  setUpdatingPersonalLoans(state, status = true) {
    state.updatingPersonalLoansDetails = status;
  },
  resetFetchingPersonalLoansDetails(state) {
    state.fetchingPersonalLoansDetails = false;
  },
  setFetchingIncomeExpensesDetails(state, status = true) {
    state.fetchingIncomeExpensesDetails = status;
  },
  setUpdatingIncomeExpensesDetails(state, status = true) {
    state.updatingIncomeExpenseDetails = status;
  },
  resetFetchingIncomeExpensesDetails(state) {
    state.fetchingIncomeExpensesDetails = true;
  },
  setFetchingApplicantBankAccounts(state, status = true) {
    state.fetchingApplicantBankAccounts = status;
  },
  setFetchingLendingDetails(state, status = true) {
    state.fetchingLendingDetails = status;
  },
  setUpdatingLendingDetails(state, status = true) {
    state.updatingLendingDetails = status;
  },
  resetFetchingLendingDetails(state) {
    state.fetchingLendingDetails = true;
  },
  resetFetchingApplicantLoans(state) {
    state.fetchingApplicantBankAccounts = true;
  },
  clearAndPushToKycWithAdditionalAttributes(
    state,
    kycWithAdditionalAttributes
  ) {
    state.kycWithAdditionalAttributes.basicAttributes = kycWithAdditionalAttributes;
    state.kycWithAdditionalAttributes.derivedAttributes = buildCustomerAddressAttribute(
      kycWithAdditionalAttributes.additional_attributes
    );
  },
  clearAndPushToDocumentRequestList(state, documentRequestList) {
    state.documentRequestList = documentRequestList;
  },
  clearAndPushToDocumentTypeList(state, documentTypeList) {
    state.documentTypeList = documentTypeList;
  },
  setBusy(state) {
    state.busy = true;
  },
  resetBusy(state) {
    state.busy = false;
  },
  setApplicationTransitionReasonTypes(state, applicationTransitionReasonTypes) {
    state.applicationTransitionReasonTypes = applicationTransitionReasonTypes;
  },
  setAggregatorLoanDetails(state, aggregatorLoanDetails) {
    state.aggregatorLoanDetails = aggregatorLoanDetails;
    state.aggregatorLoanDetailsTransitLobby = JSON.parse(
      JSON.stringify(aggregatorLoanDetails)
    );
  },
  setAggregatorLoanDetailsTransitLobby(
    state,
    aggregatorLoanDetailsTransitLobby
  ) {
    state.aggregatorLoanDetailsTransitLobby = aggregatorLoanDetailsTransitLobby;
  },
  resetAggregatorLoanDetailsTransitLobby(state) {
    state.aggregatorLoanDetailsTransitLobby = JSON.parse(
      JSON.stringify(state.aggregatorLoanDetails)
    );
  },
  setAggregatorPersonalLoanDetails(state, aggregatorPersonalLoanDetails) {
    state.aggregatorPersonalLoanDetails = aggregatorPersonalLoanDetails;
  },
  setAggregatorIncomeExpensesDetails(state, aggregatorIncomeExpenseDetails) {
    state.aggregatorIncomeExpenseDetails = aggregatorIncomeExpenseDetails;
    state.aggregatorIncomeExpenseDetailsTransitLobby = JSON.parse(
      JSON.stringify(aggregatorIncomeExpenseDetails)
    );
  },
  setAggregatorIncomeExpensesDetailsTransitLobby(
    state,
    aggregatorIncomeExpenseDetailsTransitLobby
  ) {
    state.aggregatorIncomeExpenseDetailsTransitLobby = aggregatorIncomeExpenseDetailsTransitLobby;
  },
  resetAggregatorIncomeExpensesDetailsTransitLobby(state) {
    state.aggregatorIncomeExpenseDetailsTransitLobby = JSON.parse(
      JSON.stringify(state.aggregatorIncomeExpenseDetails)
    );
  },
  setApplicationId(state, applicationId) {
    state.applicationId = applicationId;
  },
  setCustomerId(state, customerId) {
    state.customerId = customerId;
  },
  setCustomerName(state, customerName) {
    state.customerName = customerName;
  },
  setLoanAssessmentUuid(state, loanAssessmentUuid) {
    state.loanAssessmentUuid = loanAssessmentUuid;
  },
  setLoanAssessment(state, loanAssessment) {
    state.loanAssessment = loanAssessment;
  },
  setAssessmentState(state, assessmentState) {
    state.assessmentState = assessmentState;
  },
  setPastLoansOfApplicantLinkedToAssessment(
    state,
    pastLoansOfApplicantLinkedToAssessment
  ) {
    state.pastLoansOfApplicantLinkedToAssessment = pastLoansOfApplicantLinkedToAssessment;
  },
  setBankAccountOfApplicantLinkedToAssessment(
    state,
    bankAccountOfApplicantLinkedToAssessment
  ) {
    state.bankAccountOfApplicantLinkedToAssessment = bankAccountOfApplicantLinkedToAssessment;
  },
  setLastedBankStatement(state, latestBankStatement) {
    state.latestBankStatement = latestBankStatement;
  },
  incrementBankReportsFetchCounter(state) {
    state.bankReportsFetchCounter++;
  },
  setAllBankStatements(state, bankReports) {
    state.allBankReports = bankReports;
  },
  setAssesseable(state, assesseable) {
    state.assesseable = assesseable;
  },
  setWithdrawable(state, withdrawable) {
    state.withdrawable = withdrawable;
  },
  setCanBeRequestedToDecline(state, canBeRequestedToDecline) {
    state.canBeRequestedToDecline = canBeRequestedToDecline;
  },
  setCompleteable(state, completeable) {
    state.completeable = completeable;
  },
  setReviewable(state, reviewable) {
    state.reviewable = reviewable;
  },
  setApprovable(state, approvable) {
    state.approvable = approvable;
  },
  setPreConvertionCheckeable(state, preConvertionCheckeable) {
    state.preConvertionCheckeable = preConvertionCheckeable;
  },
  setPreConvertionCanBeFinalizedOrFailed(
    state,
    preConvertionCanBeFinalizedOrFailed
  ) {
    state.preConvertionCanBeFinalizedOrFailed = preConvertionCanBeFinalizedOrFailed;
  },
  setReAssignable(state, reAssignable) {
    state.reAssignable = reAssignable;
  },
  setDocFileUploadValidationMessage(state, docFileUploadValidationMessage) {
    state.loanAssessmentDetailErrorMessage = docFileUploadValidationMessage;
  },
  setLoanAssessmentDetailError(state, loanAssessmentDetailErrorMessage) {
    state.loanAssessmentDetailErrorMessage = loanAssessmentDetailErrorMessage;
  },
  setLoanAssessmentDetailSuccess(state, loanAssessmentDetailSuccessMessage) {
    state.loanAssessmentDetailSuccessMessage = loanAssessmentDetailSuccessMessage;
  },
  setLoanAssessmentDetailAlert(state, loanAssessmentDetailAlertMessage) {
    state.loanAssessmentDetailAlertMessage = loanAssessmentDetailAlertMessage;
  },
  incrementAssesmentBucketUpdateReqCounter(state) {
    state.assesmentBucketUpdateReqCounter++;
  },
  incrementLoanAssessmentFetchCounter(state) {
    state.loanAssessmentFetchCounter++;
  },
  clearLoanAssessmentDetailMessages(state) {
    state.docFileUploadValidationMessage = "";
    state.loanAssessmentDetailErrorMessage = "";
    state.loanAssessmentDetailAlertMessage = "";
    state.loanAssessmentDetailSuccessMessage = "";
  },
  clearLoanAssessmentDetailAlertMessages(state) {
    state.loanAssessmentDetailAlertMessage = "";
  },
  setLoanAssessmentOwners(state, loanAssessmentOwners) {
    state.loanAssessmentOwners = loanAssessmentOwners;
  },
  setEnabledAgreggatorDataField(state, payload) {
    state.enabledAgreggatorDataField = payload;
  },
  setToggleUrl(state) {
    state.toggleUrl = !state.toggleUrl;
  },
  setActiveDescriptions(state, descriptions) {
    state.activeDescriptions = descriptions;
  },
  setModificationAdminsRole(state, { adminId, role }) {
    state.modificationAdminsRoles[adminId] = role;
  },
  setActiveInputId(state, id = "") {
    state.activeInputId = id;
  },
  setIsCustomerNameVerified(state, value) {
    state.isCustomerNameVerified = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
