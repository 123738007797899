import { computed, onMounted, ref } from "vue";
import { useStore } from "@/store";
import { getValuesInBetween } from "./utils/delegationBoardRecodsHelper";

export const lendingConverterDelegationBoard = () => {
  const store = useStore();

  const sideMenuCollapsed = computed(() => store.state.main.sideMenuCollapsed);

  const adminUser = computed(() => store.state.auth.loggedUser);

  function onClickResolveAppDetailsAndReRoute(applicationId) {
    store.dispatch(
      "lnAppDtsRouter/resolveAndReRouteLnAssessmentDtsPage",
      applicationId
    );
  }

  const busy = computed(() => store.state.lnConverterDelegBrd.busy);

  const agentNameForFilter = ref("");

  const selectedAgent = computed(
    () => store.state.lnConverterDelegBrd.selectedAgent
  );

  const lendingAgents = computed(
    () => store.state.lnConverterDelegBrd.lendingAgents
  );

  const fetchingLendingAgents = computed(
    () => store.state.lnConverterDelegBrd.fetchingLendingAgents
  );

  const unassignedLoans = computed(
    () => store.state.lnConverterDelegBrd.unassignedLoans
  );

  const fetchingUnassignedLoans = computed(
    () => store.state.lnConverterDelegBrd.fetchingUnassignedLoans
  );

  const selectedUnassignedLoans = computed(
    () => store.state.lnConverterDelegBrd.selectedUnassignedLoans
  );

  function toggleInsertionToAssignmentSelectionBag(id, massSelection = false) {
    let entries = [];
    let key = "uuid";

    if (selectedAgent.value > 0) {
      //If mass selection applied
      if (massSelection && selectedUnassignedLoans.value.length) {
        let lastEntry =
          selectedUnassignedLoans.value[
            selectedUnassignedLoans.value.length - 1
          ];
        if (lastEntry === id) return false;
        entries = getValuesInBetween({
          records: unassignedLoans.value,
          start: lastEntry,
          end: id,
          reverse: selectedUnassignedLoans.value.includes(id),
          key
        });
      } else {
        entries = getValuesInBetween({
          records: unassignedLoans.value,
          start: id,
          end: null,
          reverse: false,
          key
        });
      }

      entries.forEach(value => {
        if (selectedUnassignedLoans.value.includes(value)) {
          store.commit(
            "lnConverterDelegBrd/removeFromSelectedUnassignedLoans",
            value
          );
        } else {
          store.commit(
            "lnConverterDelegBrd/pushToSelectedUnassignedLoans",
            value
          );
        }
      });
    }
  }

  function removeFromSelectedUnassignedLoans(id) {
    store.commit("lnConverterDelegBrd/removeFromSelectedUnassignedLoans", id);
  }

  function emptySelectedUnassignedLoansBag() {
    store.commit("lnConverterDelegBrd/clearSelectedUnassignedLoans");
  }

  const selectedAssignedLoans = computed(
    () => store.state.lnConverterDelegBrd.selectedAssignedLoans
  );

  function toggleInsertionToUnAssignmentSelectionBag(
    assessmentRecord,
    delegation_allowed_statuses
  ) {
    if (delegation_allowed_statuses.includes(assessmentRecord.status)) {
      if (selectedAssignedLoans.value.includes(assessmentRecord.uuid)) {
        store.commit(
          "lnConverterDelegBrd/removeFromSelectedAssignedLoans",
          assessmentRecord.uuid
        );
      } else {
        store.commit(
          "lnConverterDelegBrd/pushToSelectedAssignedLoans",
          assessmentRecord.uuid
        );
      }
    }
  }

  function removeFromSelectedAssignedLoans(id) {
    store.commit("lnConverterDelegBrd/removeFromSelectedAssignedLoans", id);
  }

  function emptySelectedAssignedLoansBag() {
    store.commit("lnConverterDelegBrd/clearSelectedAssignedLoans");
  }

  function setSelectedAgent(id) {
    store.commit("lnConverterDelegBrd/setSelectedAgent", id);
  }

  function selectAllUnassigned() {
    store.commit("lnConverterDelegBrd/setAllUnassignedLoansForSelection");
  }
  function assignSelectedUnassigned(agentId) {
    if (selectedAgent.value < 1 || selectedUnassignedLoans.value.length < 1) {
      store.dispatch("globalToastMessage/showSimpleToast", {
        message:
          "Both Agent and Applications must be selected for assignment !",
        type: "warning"
      });
    } else {
      store.commit("main/setFullPageLoading");
      store.dispatch(
        "lnConverterDelegBrd/submitUnassignedApplicationsForAssignment",
        agentId
      );
    }
  }

  function unassignSelectedAssigned(agentId) {
    if (selectedAssignedLoans.value.length < 1) {
      store.dispatch("globalToastMessage/showSimpleToast", {
        message:
          "Both Agent and Applications must be selected for Un-Assignment !",
        type: "warning"
      });
    } else {
      store.commit("main/setFullPageLoading");
      store.dispatch(
        "lnConverterDelegBrd/submitAssignedApplicationsForUnassignment",
        agentId
      );
    }
  }

  function resetAgentSelection() {
    store.commit("lnConverterDelegBrd/setSelectedAgent", 0);
    emptySelectedUnassignedLoansBag();
  }

  function getLendingAgents() {
    store.dispatch(
      "lnConverterDelegBrd/getLendingConverterAdminsWithAssociationsForLendingBoard"
    );
    store.dispatch(
      "lnConverterDelegBrd/getUnassignedLoansForLendingConverterAssignment"
    );
  }

  function clearAgentFilter() {
    agentNameForFilter.value = "";
    store.commit(
      "lnConverterDelegBrd/setFilteredAgentName",
      agentNameForFilter.value
    );
    store.dispatch(
      "lnConverterDelegBrd/getLendingConverterAdminsWithAssociationsForLendingBoard"
    );
  }
  function submitAgentFilter() {
    store.commit(
      "lnConverterDelegBrd/setFilteredAgentName",
      agentNameForFilter.value
    );
    if (agentNameForFilter.value !== "")
      store.dispatch(
        "lnConverterDelegBrd/getLendingConverterAdminsWithAssociationsForLendingBoard"
      );
  }

  onMounted(() => {
    getLendingAgents();
  });

  return {
    adminUser,
    agentNameForFilter,
    clearAgentFilter,
    submitAgentFilter,
    fetchingLendingAgents,
    lendingAgents,
    unassignedLoans,
    fetchingUnassignedLoans,
    selectedUnassignedLoans,
    selectedAssignedLoans,
    onClickResolveAppDetailsAndReRoute,
    toggleInsertionToAssignmentSelectionBag,
    toggleInsertionToUnAssignmentSelectionBag,
    removeFromSelectedUnassignedLoans,
    removeFromSelectedAssignedLoans,
    emptySelectedUnassignedLoansBag,
    emptySelectedAssignedLoansBag,
    selectAllUnassigned,
    assignSelectedUnassigned,
    unassignSelectedAssigned,
    selectedAgent,
    setSelectedAgent,
    resetAgentSelection,
    busy,
    sideMenuCollapsed
  };
};
