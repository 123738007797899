<template>
  <div class="font-medium text-theme-7 text-base mt-1 mr-auto">
    <div
      :class="[
        { 'bg-theme-32': isActive },
        `loan-${getActiveApplicationQualityClass()}`
      ]"
      class="flex mt-1 p-4 box cursor-pointer section-header"
      @click="setNavActiveSection('verification-detail-title')"
    >
      <h2
        id="verification-detail-title"
        class="font-medium text-theme-7 text-base w-full"
      >
        VERIFICATION
      </h2>
      <ChevronDownIcon :class="{ 'transform rotate-180': !isActive }" />
    </div>
    <div v-if="isActive" id="skip-check-section" class="grid grid-cols-2 gap-6">
      <SkipChecks :application-id="applicationId" />
      <CreditCheck :customer-id="customerId" />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import SkipChecks from "@/components/loan-application-details/skip-checks/Main.vue";
import CreditCheck from "@/components/loan-application-details/credit-check/Main.vue";
import { useStore } from "@/store";
import { loanQuality } from "@/composables/loanQuality";

export default defineComponent({
  components: {
    SkipChecks,
    CreditCheck
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    setNavActiveSection: {
      type: Function,
      default: () => {}
    },
    applicationId: {
      type: Number,
      default: 0
    },
    customerId: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const store = useStore();
    const loggedUser = computed(() => store.state.auth.loggedUser);
    const userRoles = loggedUser.value.roles;
    const { getActiveApplicationQualityClass } = loanQuality();

    function hasConverterRole() {
      return userRoles.includes("lending-converter");
    }

    return { hasConverterRole, getActiveApplicationQualityClass };
  }
});
</script>
