<template>
  <div class="grid grid-rows-3 text-xs">
    <span>
      Amount: <b>{{ amount }}</b></span
    >
    <template v-if="has_expressed_willingness && expressed_willingness">
      <span class="underline">Relative Information</span>
      <span>
        Relation: <b>{{ expressed_willingness.relation }}</b></span
      >
      <span> Name: {{ expressed_willingness.third_party_name }} </span>
      <span> Email: {{ expressed_willingness.third_party_email }} </span>
      <span> Phone: {{ expressed_willingness.third_party_phone }} </span>
    </template>
    <span v-else>
      Extra Income Source: <b>{{ source }}</b>
    </span>
  </div>
</template>
<script>
import { defineComponent, toRefs } from "vue";
export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup: function(props) {
    const {
      amount,
      source,
      expressed_willingness,
      has_expressed_willingness
    } = toRefs(props.data);

    return {
      amount,
      source,
      expressed_willingness,
      has_expressed_willingness
    };
  }
});
</script>
