<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        Application Follow Up Dashboard
      </h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="flex col-span-2 mt-12 sm:mt-5">
        <div class="flex flex-col w-full">
          <div class="intro-y flex-1 box py-8 mb-5 lg:mb-0">
            <UserIcon
              class="block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"
            />
            <div class="text-xl font-medium text-center mt-10">
              {{ adminUser.fullname }}
            </div>
            <div
              class="text-xl font-medium text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"
            >
              Follow Up Admin
            </div>
          </div>
        </div>
      </div>
      <!-- BEGIN: Assessment Counter -->

      <!-- END: Assessment Counter -->
      <div class="intro-y col-span-10">
        <!-- BEGIN: Striped Rows -->
        <div class="intro-y box mt-5">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200"
          >
            <h2 class="font-medium text-base mr-auto">Activity Overview</h2>
            <div
              class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
            ></div>
          </div>
          <div id="activity-overview-table" class="p-5">
            <div class="preview">
              <div class="overflow-x-auto">
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Activity
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Today
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        This Week
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Last 7 days
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        This Month
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(activity,
                      activityKey,
                      activityIterationIndex) in loanFollowUpsActivityStats"
                      :key="activityKey"
                      :class="{
                        'bg-gray-200 dark:bg-dark-1':
                          activityIterationIndex % 2 < 1
                      }"
                    >
                      <td class="border-b dark:border-dark-5">
                        {{
                          activityKey
                            .split("_")
                            .map(w => w.charAt(0).toUpperCase() + w.slice(1))
                            .join(" ")
                        }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ activity.total_follow_ups_for_today }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ activity.total_follow_ups_for_this_week }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ activity.total_follow_ups_for_last_7_days }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ activity.total_follow_ups_for_this_month }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Striped Rows -->
      </div>
      <div class="intro-y col-span-12">
        <!-- BEGIN: Table Head Options -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200"
          >
            <h2 class="font-medium text-base mr-auto">Follow Up Bucket</h2>
            <div
              class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
            >
              <div class="sm:flex items-center sm:mr-2">
                <label class="w-full flex-none xl:flex-initial mr-2"
                  >Followup Type</label
                >
                <select
                  id="tabulator-html-filter-field"
                  v-model="selectedFollowUpType"
                  class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
                >
                  <option value="">None</option>
                  <option value="docs_req">Doc Request</option>
                  <option value="card_verf">Card Verification</option>
                  <option value="bank_reauth">Bank Reauth</option>
                  <option value="bank_statements_unavailable"
                    >Bank Statement Unavailable</option
                  >
                  <option value="unsigned_loan">Unsigned Loan</option>
                </select>
              </div>
              <button
                v-if="
                  adminUser.permissions.includes('lnfollowups.assigned_to.self')
                "
                id="tabulator-print"
                class="btn hover:bg-green-700 bg-green-500 w-1/2 sm:w-auto mr-2"
                @click="onAssignFollowUpAndGetReqSubmit"
              >
                <span class="text-white"
                  ><PlayCircleIcon class="w-6 h-6 mr-2" /> Get Customer</span
                >
              </button>
            </div>
          </div>
          <div id="head-options-table" class="p-5">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
                  id="tabulator"
                  ref="tableRef"
                  class="mt-5 table-report table-report--tabulator"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Table Head Options -->
        <!-- BEGIN: Striped Rows -->
        <div class="intro-y box mt-5">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200"
          >
            <h2 class="font-medium text-base mr-auto">Recently Assessed</h2>
            <div
              class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
            >
              <button
                id="recently-assessed-view-all"
                class="btn hover:bg-green-700 bg-green-500 w-1/2 sm:w-auto mr-2 text-white"
              >
                <ArrowRightIcon class="w-6 h-6 mr-2" /> View All ...
              </button>
            </div>
          </div>
          <div id="striped-rows-table" class="p-5">
            <div class="preview">
              <div class="overflow-x-auto">
                <table class="table">
                  <tr>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Customer Name
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Offered Amount
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Loan Amount
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Frequency
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Repayments
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Rep.Amount
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Current Status
                    </th>
                  </tr>
                  <tbody>
                    <tr
                      v-for="(pastFollowUp,
                      pastFollowUpKey,
                      pastAssesmentIndex) in recentFollowUps"
                      :key="pastFollowUpKey"
                      :class="{
                        'bg-gray-200 dark:bg-dark-1': pastAssesmentIndex % 2 < 1
                      }"
                    >
                      <td class="border-b dark:border-dark-5">
                        {{ pastFollowUp.fullname }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ pastFollowUp.loan.total_amount }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ pastFollowUp.loan.total_amount }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ pastFollowUp.loan.repayment_frequency }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ pastFollowUp.loan.repayments }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ pastFollowUp.loan.repayment_amount }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ pastFollowUp.status }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Striped Rows -->
      </div>
    </div>
    <!-- BEGIN: Success Notification Content -->
    <div id="success-alert-toast-content" class="toastify-content hidden flex">
      <XCircleIcon class="text-theme-3" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Success!</div>
        <div id="alert-note-message-body" class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <!-- END: Success Notification Content -->
    <FundoLmsToastAlert />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { customerFollowUpDashboard } from "../../composables/customerFollowUpDashboard";
import FundoLmsToastAlert from "@/components/fundo-lms-toast-alert/Main";

export default defineComponent({
  components: {
    FundoLmsToastAlert
  },
  setup() {
    const {
      selectedFollowUpType,
      adminUser,
      tableRef,
      fromDate,
      toDate,
      filter,
      onFilter,
      onResetFilter,
      onExport,
      breadcrumb,
      path,
      loanFollowUpsActivityStats,
      recentFollowUps,
      loanFollowUpsBucketList,
      getLoanFollowUpsListAction,
      getFollowUpStartActionAsPerAdminRole,
      approvalRejectionDataObject,
      onAssignFollowUpAndGetReqSubmit,
      init
    } = customerFollowUpDashboard();

    return {
      selectedFollowUpType,
      adminUser,
      tableRef,
      fromDate,
      toDate,
      filter,
      onFilter,
      onResetFilter,
      onExport,
      breadcrumb,
      path,
      loanFollowUpsActivityStats,
      recentFollowUps,
      loanFollowUpsBucketList,
      getLoanFollowUpsListAction,
      getFollowUpStartActionAsPerAdminRole,
      approvalRejectionDataObject,
      onAssignFollowUpAndGetReqSubmit,
      init
    };
  }
});
</script>
