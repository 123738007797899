<template>
  <div
    v-if="show"
    class="flex justify-center items-center relative z-10 top-1/2 mx-4"
  >
    <LoadingIcon class="w-4 h-4" color="green" icon="oval" />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: false,
      required: true
    }
  }
});
</script>
