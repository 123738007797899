<template>
  <div>
    <div id="doc-req-title"></div>
    <div
      :class="[
        { 'bg-theme-32': isActive },
        `loan-${getActiveApplicationQualityClass()}`
      ]"
      class="flex mt-1 p-4 box cursor-pointer section-header"
      @click="setNavActiveSection('doc-req-title')"
    >
      <h2 class="font-medium text-theme-7 text-base w-full">
        DOCUMENT REQUESTS
      </h2>
      <ChevronDownIcon :class="{ 'transform rotate-180': !isActive }" />
    </div>
    <!-- BEGIN: Striped Rows -->
    <div v-if="isActive">
      <DocRequestContent :application-id="applicationId"></DocRequestContent>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import DocRequestContent from "@/components/ln-app-doc-req/DocRequestContent";
import { loanQuality } from "@/composables/loanQuality";

export default defineComponent({
  components: {
    DocRequestContent
  },
  props: {
    applicationId: {
      type: Number,
      required: true
    },
    customerName: {
      type: String,
      default: "",
      required: false
    },
    isActive: {
      type: Boolean,
      default: false,
      required: false
    },
    setNavActiveSection: {
      type: Function,
      require: true,
      default: null
    }
  },
  setup(props) {
    const { getActiveApplicationQualityClass } = loanQuality();
    return {
      getActiveApplicationQualityClass
    };
  }
});
</script>
