<template>
  <div class="loader w-4">
    <LoadingIcon class="w-2 h-2" color="green" icon="oval" size="16" />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {}
});
</script>

<style scoped></style>
