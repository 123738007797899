import { formatErrorMessage } from "@/utils/messageFormatter";
import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";

const state = () => {
  return {
    isBusy: false,
    successMessage: "",
    errorMessage: "",
    validationErrors: [],
    successCounter: 0,
    newLoans: []
  };
};

const getters = {
  isBusy: state => state.isBusy,
  validationErrors: state => state.validationErrors,
  errorMessage: state => state.errorMessage,
  successCounter: state => state.successCounter
};

const actions = {
  addMultiplePersonalLoans: async ({ state, commit }, payload) => {
    commit("setBusy");
    payload["loans"] = state.newLoans;
    axiosWrapperService
      .axiosPost(
        `/gateway-proxies/fundo-core/personal-loans/add-multiple`,
        payload
      )
      .then(_ => {
        commit("incrementSuccessCounter");
        state.newLoans = [];
      })
      .catch(error => {
        commit("clearErrors");
        if (error.response?.data?.errors) {
          commit("setValidationErrors", error.response.data.errors);
        } else {
          commit("setErrorMessage", formatErrorMessage(error));
        }
      })
      .finally(_ => {
        commit("resetBusy");
      });
  }
};

//mutations
const mutations = {
  setBusy(state) {
    state.isBusy = true;
  },
  resetBusy(state) {
    state.isBusy = false;
  },
  setValidationErrors(state, validationErrors) {
    state.validationErrors = validationErrors;
  },
  clearErrors(state) {
    state.validationErrors = [];
    state.errorMessage = "";
  },
  incrementSuccessCounter(state) {
    state.successCounter++;
  },
  setErrorMessage(state, message) {
    state.errorMessage = message;
  },
  removeLoanIndex(state, index) {
    state.newLoans.splice(index, 1);
  },
  clearNewLoans(state) {
    state.newLoans = [];
  },

  addNewLoan(state) {
    state.newLoans.push({
      loan_provider: "",
      loan_type: "BNPL",
      repayment_amount: 0,
      loan_frequency: "Monthly",
      actions: "ongoing"
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
