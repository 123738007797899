<template>
  <!-- BEGIN: No record template-->
  <div class="col-span-12">
    <div class="box" :class="[mt]">
      <div class="p-5">
        <h1 class="text-center text-theme-13" style="font-size: large;">
          <strong> No Records to show!</strong>
        </h1>
      </div>
    </div>
  </div>
  <!-- END: No record template-->
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    marginTop: {
      type: String,
      require: false,
      default: "5"
    }
  },
  setup(props) {
    const mt = props.marginTop ? `mt-${props.marginTop}` : "mt-5";

    return { mt };
  }
});
</script>
