<template>
  <div>
    <!-- BEGIN: Success Notification Content -->
    <div
        id="success-notification-content"
        class="toastify-content hidden flex"
    >
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Publish success!</div>
        <div class="text-gray-600 mt-1">
          {{
            route.name !== "content-management-edit-page"
                ? "Content Saved successfully"
                : "Content updated successfully"
          }}
        </div>
      </div>
    </div>
    <!-- END: Success Notification Content -->
    <!-- BEGIN: Failed Notification Content -->
    <div
        id="failed-notification-content"
        class="toastify-content hidden flex"
    >
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Publish failed!</div>
        <div class="text-gray-600 mt-1">
          Please check the field form.
        </div>
      </div>
    </div>
    <!-- END: Failed Notification Content -->
    <!-- BEGIN: Failed Notification Content -->
    <div
        id="failed-validations-content"
        class="toastify-content hidden flex"
    >
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Publish failed!</div>
        <div class="text-gray-600 mt-1">
          <li v-for="(error, key) in errors" :key="key">{{ error }}</li>
        </div>
      </div>
    </div>
    <!-- END: Failed Notification Content -->
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Post Form</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          :disabled="!saved"
          class="btn box text-gray-700 dark:text-gray-300 mr-2 flex items-center ml-auto sm:ml-0"
          type="button"
          @click="_ => openPreview(formData.slug)"
        >
          <EyeIcon class="w-4 h-4 mr-2" /> Preview
        </button>
        <!--        <div class="dropdown">-->
        <Loader v-if="isBusy" :show="isBusy" />
        <template v-else>
          <button
            aria-expanded="false"
            class=" btn btn-primary shadow-md flex items-center"
            @click="submitForm"
          >
            {{ submitButtonSlug }}
          </button>
        </template>
        <!--        </div>-->
        <button
          aria-expanded="false"
          class=" btn shadow-md flex items-center"
          @click="resetForm"
        >
          Reset
        </button>
      </div>
    </div>
    <Tabs :tabs="childRoutes"/>
    <RouterView :key="activeComponentKey"></RouterView>
  </div>
</template>

<script>
import { defineComponent, ref, watch, nextTick, provide, computed } from "vue";
import Toastify from "toastify-js";
import Loader from "@/components/content-management/metas/Loader";
import { cmsDetails } from "@/composables/components/content-manager/cms";
import Tabs from "@/views/content-management/Tabs.vue";

export default defineComponent({
  components: {
    Tabs,
    Loader
  },
  setup(props) {
    const {
      route,
      isBusy,
      openPreview,
      successMessage,
      clearMessage,
      validationErrors,
      getChildRoutes
    } = cmsDetails(props);
    const saved = ref(false);
    const errors = ref([]);
    const childRoutes = getChildRoutes();
    const activeComponentKey = ref(0);
    let validateAndSubmit = ref(null);

    provide("validateAndSubmit", validateAndSubmit);

    const submitButtonSlug = computed(() => {
      return ['content-management-edit-page', 'content-management-edit-component'].includes(route.name) ? 'Update' : 'Submit';
    });

    function resetForm() {
      activeComponentKey.value += 1;
    }

    const submitForm = async () => {
      try {
        await validateAndSubmit.value();
      } catch (e) {
        Toastify({
          node: cash("#failed-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      }
    };

    function beautifySlug(givenSlug) {
      return givenSlug.toUpperCase().charAt(0) + givenSlug.slice(1);
    }

    watch(
      () => successMessage.value,
      message => {
        if (message.length) {
          Toastify({
            node: cash("#success-notification-content")
              .clone()
              .removeClass("hidden")[0],
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: "top",
            position: "right",
            stopOnFocus: true
          }).showToast();
          clearMessage();
        }
      }
    );

    watch(
      () => validationErrors.value,
      validations => {
        errors.value = [];
        if (Object.keys(validations).length) {
          Object.keys(validations).forEach(key => {
            errors.value = [...errors.value, ...validations[key]];
          });
        } else if (typeof validations === 'string') {
          errors.value = [...errors.value, validations];
        }

        if (Object.keys(validations).length) {
          nextTick(() => {
            Toastify({
              node: cash("#failed-validations-content")
                .clone()
                .removeClass("hidden")[0],
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: "top",
              position: "right",
              stopOnFocus: true
            }).showToast();
            clearMessage();
          });
        }
      });

    return {
      beautifySlug,
      submitForm,
      route,
      isBusy,
      errors,
      openPreview,
      saved,
      clearMessage,
      resetForm,
      validationErrors,
      childRoutes,
      activeComponentKey,
      submitButtonSlug
    };
  }
});
</script>
