import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";
import { existsInSets } from "@/utils/verifications";

const state = () => {
  return {
    applicationId: 0,
    status: "",
    isVerified: false,
    isBusy: false,
    errorMessage: "",
    validationErrors: []
  };
};

const getters = {
  status: state => state.status,
  isVerified: state => state.isVerified,
  isBusy: state => state.isBusy,
  type: state => state.type,
  errorMessage: state => state.errorMessage,
  validationErrors: state => state.validationErrors
};

const mutations = {
  setApplicationId(state, applicationId) {
    state.applicationId = applicationId;
  },
  setStatus(state, status) {
    state.status = status;
  },
  setIsVerified(state, value) {
    state.isVerified = value;
  },
  setIsBusy(state, isBusy) {
    state.isBusy = isBusy;
  },
  setType(state, type) {
    state.type = type;
  },
  setErrorMessage(state, message) {
    state.errorMessage = message;
  },
  setValidationErrors(state, validationErrors) {
    state.validationErrors = validationErrors;
  },
  clearErrors(state) {
    state.validationErrors = [];
    state.errorMessage = "";
  }
};

const actions = {
  getSystemChecks: async ({ dispatch, state, commit }) => {
    commit("setIsBusy", true);
    axiosWrapperService
      .axiosGet(`/applications/${state.applicationId}/systemChecks`)
      .then(response => {
        commit("setStatus", response.data?.system_customer_name);
        commit(
          "setIsVerified",
          existsInSets(response.data?.system_customer_name)
        );
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("setIsBusy", false);
      });
  },
  manualSystemCheck: async ({ dispatch, state, commit }) => {
    commit("clearErrors");
    commit("setIsBusy", true);
    const payload = {
      type: state.type
    };

    axiosWrapperService
      .axiosPatch(`/applications/${state.applicationId}/systemChecks`, payload)
      .then(response => {
        let status = response.data?.system_customer_name;
        commit("setStatus", status);
        commit(
          "setIsVerified",
          existsInSets(response.data?.system_customer_name)
        );
        if (status !== "none") {
          dispatch(
            "globalToastMessage/showSimpleToast",
            {
              message: `Verification successful.`,
              type: "success"
            },
            { root: true }
          );
        } else {
          dispatch(
            "globalToastMessage/showSimpleToast",
            { message: "Not able to verify. Please try again.", type: "error" },
            { root: true }
          );
        }
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("setIsBusy", false);
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
