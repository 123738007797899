import { computed, onMounted, ref } from "vue";
import { useStore } from "@/store";

export const customerFollowUpDelegationBoard = () => {
  const store = useStore();

  const sideMenuCollapsed = computed(() => store.state.main.sideMenuCollapsed);

  const adminUser = computed(() => store.state.auth.loggedUser);

  function onClickResolveAppDetailsAndReRoute(applicationId) {
    store.dispatch(
      "lnAppDtsRouter/resolveAndReRouteLnAssessmentDtsPage",
      applicationId
    );
  }

  const busy = computed(() => store.state.lnFollowUpDelegationBoard.busy);

  const agentNameForFilter = ref("");

  const selectedFollowUpAgent = computed(
    () => store.state.lnFollowUpDelegationBoard.selectedFollowUpAgent
  );

  const followUpAgents = computed(
    () => store.state.lnFollowUpDelegationBoard.followUpAgents
  );

  const fetchingFollowUpAgents = computed(
    () => store.state.lnFollowUpDelegationBoard.fetchingFollowUpAgents
  );

  const unassignedLoanFollowUps = computed(
    () => store.state.lnFollowUpDelegationBoard.unassignedLoanFollowUps
  );

  const fetchingUnassignedLoanFollowUps = computed(
    () => store.state.lnFollowUpDelegationBoard.fetchingUnassignedLoanFollowUps
  );

  const selectedUnassignedLoanFollowUps = computed(
    () => store.state.lnFollowUpDelegationBoard.selectedUnassignedLoanFollowUps
  );

  function toggleInsertionToFollowUpAssignmentSelectionBag(id) {
    if (selectedFollowUpAgent.value > 0) {
      if (selectedUnassignedLoanFollowUps.value.includes(id)) {
        store.commit(
          "lnFollowUpDelegationBoard/removeFromSelectedUnassignedLoanFollowUps",
          id
        );
      } else {
        store.commit(
          "lnFollowUpDelegationBoard/pushToSelectedUnassignedLoanFollowUps",
          id
        );
      }
    }
  }

  function removeFromSelectedUnassignedLoanFollowUps(id) {
    store.commit(
      "lnFollowUpDelegationBoard/removeFromSelectedUnassignedLoanFollowUps",
      id
    );
  }

  function emptySelectedUnassignedFollowUpsBag() {
    store.commit(
      "lnFollowUpDelegationBoard/clearSelectedUnassignedLoanFollowUps"
    );
  }

  const selectedAssignedLoanFollowUps = computed(
    () => store.state.lnFollowUpDelegationBoard.selectedAssignedLoanFollowUps
  );

  function toggleInsertionToFollowUpUnAssignmentSelectionBag(id) {
    if (selectedAssignedLoanFollowUps.value.includes(id)) {
      store.commit(
        "lnFollowUpDelegationBoard/removeFromSelectedAssignedLoanFollowUps",
        id
      );
    } else {
      store.commit(
        "lnFollowUpDelegationBoard/pushToSelectedAssignedLoanFollowUps",
        id
      );
    }
  }

  function removeFromSelectedAssignedLoanFollowUps(id) {
    store.commit(
      "lnFollowUpDelegationBoard/removeFromSelectedAssignedLoanFollowUps",
      id
    );
  }

  function emptySelectedAssignedFollowUpsBag() {
    store.commit(
      "lnFollowUpDelegationBoard/clearSelectedAssignedLoanFollowUps"
    );
  }

  function setSelectedFollowUpAgent(id) {
    store.commit("lnFollowUpDelegationBoard/setSelectedFollowUpAgent", id);
  }

  function selectAllUnassigned() {
    store.commit(
      "lnFollowUpDelegationBoard/setAllUnassignedLoanFollowUpsForSelection"
    );
  }
  function submitAssignedFollowUpsForUnassignment() {
    if (selectedAssignedLoanFollowUps.value.length < 1) {
      store.dispatch("globalToastMessage/showSimpleToast", {
        message:
          "Both Agent and Applications must be selected for assignment !",
        type: "warning"
      });
    }
    if (selectedAssignedLoanFollowUps.value.length > 0) {
      store.commit("main/setFullPageLoading");
      store.dispatch(
        "lnFollowUpDelegationBoard/submitAssignedFollowUpsForUnassignment"
      );
    }
  }
  function assignSelectedUnassigned(agentId) {
    if (
      selectedFollowUpAgent.value < 1 ||
      selectedUnassignedLoanFollowUps.value.length < 1
    ) {
      store.dispatch("globalToastMessage/showSimpleToast", {
        message:
          "Both Agent and Applications must be selected for assignment !",
        type: "warning"
      });
    }
    if (
      selectedFollowUpAgent.value > 0 &&
      selectedUnassignedLoanFollowUps.value.length > 0
    ) {
      store.commit("main/setFullPageLoading");
      store.dispatch(
        "lnFollowUpDelegationBoard/submitUnassignedFollowUpsForAssignment",
        agentId
      );
    }
  }

  function resetFollowUpAgentSelection() {
    store.commit("lnFollowUpDelegationBoard/setSelectedFollowUpAgent", 0);
  }

  function getFollowUpAgents() {
    store.dispatch(
      "lnFollowUpDelegationBoard/getAdminsWithAssociationsForFollowUpBoard"
    );
    store.dispatch("lnFollowUpDelegationBoard/getUnassignedLoanFollowUps");
  }

  function clearAgentFilter() {
    agentNameForFilter.value = "";
    store.commit(
      "lnFollowUpDelegationBoard/setFilteredFollowUpAgentName",
      agentNameForFilter.value
    );
    store.dispatch(
      "lnFollowUpDelegationBoard/getAdminsWithAssociationsForFollowUpBoard"
    );
  }
  function submitAgentFilter() {
    store.commit(
      "lnFollowUpDelegationBoard/setFilteredFollowUpAgentName",
      agentNameForFilter.value
    );
    if (agentNameForFilter.value !== "")
      store.dispatch(
        "lnFollowUpDelegationBoard/getAdminsWithAssociationsForFollowUpBoard"
      );
  }

  onMounted(() => {
    getFollowUpAgents();
  });

  return {
    adminUser,
    agentNameForFilter,
    clearAgentFilter,
    submitAgentFilter,
    fetchingFollowUpAgents,
    followUpAgents,
    unassignedLoanFollowUps,
    fetchingUnassignedLoanFollowUps,
    selectedUnassignedLoanFollowUps,
    onClickResolveAppDetailsAndReRoute,
    toggleInsertionToFollowUpAssignmentSelectionBag,
    removeFromSelectedUnassignedLoanFollowUps,
    emptySelectedUnassignedFollowUpsBag,
    selectAllUnassigned,
    assignSelectedUnassigned,
    selectedAssignedLoanFollowUps,
    toggleInsertionToFollowUpUnAssignmentSelectionBag,
    removeFromSelectedAssignedLoanFollowUps,
    emptySelectedAssignedFollowUpsBag,
    submitAssignedFollowUpsForUnassignment,
    selectedFollowUpAgent,
    setSelectedFollowUpAgent,
    resetFollowUpAgentSelection,
    busy,
    sideMenuCollapsed
  };
};
