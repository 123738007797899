<template>
  <div>
    <h6 class="m-2 text-center">Transaction Details</h6>
    <div class="transactions">
      <FetchingRecordDivTemplate v-if="isBusy" :animate-intro="false" />
      <table v-else class="table table-compact table-bordered table-sm">
        <thead class="thead-light">
          <tr>
            <th>#</th>
            <th>Type</th>
            <th>Credit Amount</th>
            <th>Debit Amount</th>
            <th>Payment Method</th>
            <th>Transaction Date</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="transactions.length > 0">
            <tr v-for="transaction in transactions" :key="transaction.id">
              <td>{{ transaction.id }}</td>
              <td>{{ transaction.type }}</td>
              <td>{{ transaction.credit_amount }}</td>
              <td>{{ transaction.debit_amount }}</td>
              <td>{{ transaction.payment_method }}</td>
              <td>{{ transaction.transaction_date }}</td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="6" class="text-center">No records were found</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted } from "vue";
import FetchingRecordDivTemplate from "@/components/fetching-record-div-template/Main";
import { useStore } from "@/store";

export default defineComponent({
  components: {
    FetchingRecordDivTemplate
  },
  props: {
    scheduleId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const store = useStore();

    const isBusy = computed(
      () => store.getters["paymentScheduleTransactions/isBusy"]
    );

    const transactions = computed(
      () => store.getters["paymentScheduleTransactions/transactions"]
    );
    onMounted(() => {
      store.dispatch("paymentScheduleTransactions/getTransactions", {
        scheduleId: props.scheduleId
      });
    });

    return {
      isBusy,
      transactions
    };
  }
});
</script>

<style scoped>
.row-content {
  cursor: pointer;
}
</style>
