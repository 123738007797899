export function buildCustomerAddressAttribute(additional_attributes) {
  let street = "";
  let city = "";
  let addressState = "";
  let zipCode = "";
  additional_attributes.forEach(function(attributeItem) {
    if (attributeItem.attr === "Street") {
      street = attributeItem.value;
    }
    if (attributeItem.attr === "City") {
      city = attributeItem.value;
    }
    if (attributeItem.attr === "State") {
      addressState = attributeItem.value;
    }
    if (attributeItem.attr === "Zip_Code") {
      zipCode = attributeItem.value;
    }
  });

  return { address: street + " " + city + " " + addressState + " " + zipCode };
}
