<template>
  <div
    v-if="verified"
    class="text-theme-20 p-2 flex items-center justify-start"
    style="min-width: 115px"
  >
    <template v-if="cover">
      (
      <CheckCircleIcon :width="iconWidth" class="text-theme-20 h-full" />
      <span
        :class="{
          'text-[10px]': small,
          'text-[12px]': !small
        }"
      >
        {{ mode }} Verified </span
      >)
    </template>
    <template v-else>
      <CheckCircleIcon :width="iconWidth" class="text-theme-20 h-full" />
      <span
        :class="{
          'text-[10px]': small,
          'text-[12px]': !small
        }"
      >
        {{ mode }} Verified
      </span>
    </template>
  </div>

  <button
    v-else-if="verification.isRequired()"
    :class="{
      'w-20 h-8': small,
      'w-18': !small
    }"
    class="btn bg-theme-3 text-white form-control"
    @click="verify()"
  >
    Verify
  </button>
</template>
<script setup>
import { defineProps, computed, onMounted, ref, watch, toRefs } from "vue";
import { useStore } from "@/store";

const store = useStore();

const props = defineProps({
  details: {
    type: Object,
    required: true,
    default: () => ({
      system_verified: false,
      last_modified_by: 0
    })
  },
  small: {
    type: Boolean,
    default: false
  },
  operation: {
    type: Object,
    required: false,
    default: () => ({})
  },
  cover: {
    type: Boolean,
    default: false
  }
});

const mode = ref(null);

const { details, small, operation, cover } = toRefs(props);

const adminId = details.value.last_modified_by;

const systemVerified = details.value.system_verified;

const modelVerified = details.value?.model_verified;

const verified = systemVerified || modelVerified || adminId > 0;

const parsedVerificationRequired =
  Object.prototype.hasOwnProperty.call(
    details.value,
    "verification_required"
  ) && details.value.verification_required;

const verification = {
  requiredFor: ['customer-name', 'payment-frequency'],
  isRequired: function () {
    return this.requiredFor.includes(operation.value.payload.type)
      ? systemVerified < 1 && adminId < 1 && parsedVerificationRequired
      : parsedVerificationRequired
  }
}

const iconWidth = small ? "16" : "24";

const payload = operation.value.payload;

const adminsRoles = computed(
  () => store.getters["loanAssessmentInfos/modificationAdminsRoles"]
);

const loggedAdminUser = computed(() => store.state.auth.loggedUser);

function getModificationAdminsRole(adminId) {
  if (adminId > 0 && !(adminId in adminsRoles.value)) {
    store.dispatch(
      "loanAssessmentInfos/fetchOrAddModificationAdminsRole",
      adminId
    );
  }
}

function verify() {
  if (payload.type === "bank-account") {
    store.dispatch("loanAssessmentInfos/verifyBankAccountOfLoan", {
      ...payload,
      adminId: loggedAdminUser.value.id
    });
  } else {
    store.dispatch("loanAssessmentInfos/verifyLoanAssessmentProperty", {
      ...payload,
      adminId: loggedAdminUser.value.id
    });
  }
}

watch(
  () => adminsRoles.value,
  rolesByAdminId => {
    if (!systemVerified && !modelVerified) mode.value = rolesByAdminId[adminId];
  },
  { deep: true }
);

onMounted(() => {
  if (systemVerified) {
    //system verified
    mode.value = "System";
  } else if (modelVerified) {
    //Model verified
    mode.value = "Model";
  } else {
    //agent verified
    if (adminsRoles.value[adminId]) {
      mode.value = adminsRoles.value[adminId];
    } else {
      getModificationAdminsRole(adminId);
    }
  }
});
</script>
