<template>
        <div class="post__content tab-content">
          <div class="pos px-5 intro-y grid grid-cols-12 gap-5 mt-5">
            <!-- BEGIN: Post Content -->
            <div class="intro-y col-span-12 lg:col-span-12">
              <input
                id="validation-form-2"
                v-model.trim="validate.title.$model"
                :class="{ 'border-theme-6': validate.title.$error }"
                class="intro-y form-control py-3 px-4 box pr-10 placeholder-theme-13"
                name="title"
                placeholder="Title"
                type="text"
                @change="createSlug"
              />
              <template v-if="validate.title.$error">
                <div
                  v-for="(error, index) in validate.title.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
          </div>
          <div
            id="content"
            aria-labelledby="content-tab"
            class="tab-pane active"
            role="tabpanel"
          >

            <div
              class="border border-gray-200 dark:border-dark-5 rounded-md p-5"
            >
              <div
                class="font-medium flex items-center border-gray-200 dark:border-dark-5"
              >
                <ChevronDownIcon class="w-4 h-4 mr-2" /> H1
              </div>
              <input
                id="validation-form-2"
                v-model.trim="validate.h1.$model"
                :class="{ 'border-theme-6': validate.h1.$error }"
                class="intro-y form-control py-3 px-4 box pr-10 placeholder-theme-13"
                name="title"
                placeholder="H1 for the page"
                type="text"
              />
            </div>

            <div
              class="border border-gray-200 dark:border-dark-5 rounded-md p-5"
            >
              <div
                class="font-medium flex items-center border-gray-200 dark:border-dark-5"
              >
                <ChevronDownIcon class="w-4 h-4 mr-2" /> Metas
              </div>
              <Metas
                ref="metaComp"
                :data="formData.meta"
                v-on:remove="removeExisitingMeta"
                v-on:update="addMetasToPayload"
              />
            </div>
            <div
              class="border border-gray-200 dark:border-dark-5 rounded-md p-5 mt-5"
            >
              <div
                class="font-medium flex items-center border-gray-200 dark:border-dark-5 "
              >
                <ChevronDownIcon class="w-4 h-4 mr-2" /> Content
              </div>
              <div class="mt-2">
                <p class="pb-4 text-xs">
                  * Note: Do not add extra spaces between paragraphs *
                </p>
                <CKEditor v-model="validate.body.$model" />
              </div>
              <template v-if="validate.body.$error">
                <div
                  v-for="(error, index) in validate.body.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div
              class="border border-gray-200 dark:border-dark-5 rounded-md p-5 mt-5"
            >
              <div
                class="font-medium flex items-center border-gray-200 dark:border-dark-5"
              >
                <ChevronDownIcon class="w-4 h-4 mr-2" />
                Slug
              </div>
              <div class="mt-5">
                <div>
                  <!--                    <label class="form-label" for="post-form-7">Slug</label>-->
                  <input
                    id="post-form-7"
                    v-model.trim="validate.slug.$model"
                    :class="{ 'border-theme-6': validate.slug.$error }"
                    class="form-control"
                    name="slug"
                    placeholder="Will be autogenerated using title"
                    type="text"
                  />
                  <template v-if="validate.slug.$error">
                    <div
                      v-for="(error, index) in validate.slug.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
                </div>
              </div>
            </div>

            <div
              class="border border-gray-200 dark:border-dark-5 rounded-md p-5 mt-5"
            >
              <div class="grid grid-cols-3 gap-4 items-center justify-center">
                <div>
                  <span class="text-left font-medium pl-4">Status </span>
                  <p class="text-xs">
                    *User can access this page or not*
                  </p>
                </div>
                <select
                  v-model="formData.is_published"
                  class="form-select w-full ml-2"
                >
                  <option value="1">Publish</option>
                  <option value="0">UnPublish</option>
                </select>
              </div>
            </div>

            <div
              class="border border-gray-200 dark:border-dark-5 rounded-md p-5 mt-5"
            >
              <div
                class="font-medium flex items-center border-gray-200 dark:border-dark-5"
              >
                <ChevronDownIcon class="w-4 h-4 mr-2" />
                Description
              </div>
              <div class="mt-5">
                <div>
                  <!--                    <label class="form-label" for="post-form-8"-->
                  <!--                      >Description</label-->
                  <!--                    >-->
                  <textarea
                    id="post-form-8"
                    v-model.trim="validate.description.$model"
                    :class="{ 'border-theme-6': validate.description.$error }"
                    class="form-control"
                    placeholder="Write description"
                    type="text"
                  />
                  <template v-if="validate.description.$error">
                    <div
                      v-for="(error, index) in validate.description.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, inject, onMounted } from "vue";
import { required, minLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import Metas from "@/components/content-management/metas/Main";
import { cmsDetails } from "@/composables/components/content-manager/cms";
export default defineComponent({
  components: {
    Metas
  },
  setup(props) {
    const {
      adminUser,
      route,
      activeRecord,
      savePage,
      updatePage,
      validationErrors
    } = cmsDetails(props);
    const ID = route.params.uuid;
    const apiEndpoints = {
      save: "/cms/pages/add",
      update: `/cms/pages/${ID}/update`
    }
    const errors = ref([]);
    let data = {};
    if (route.name === "content-management-edit-page") {
      const result = activeRecord.value;
      let meta = Array.isArray(result.meta)
        ? result.meta
        : JSON.parse(result.meta);
      data = {
        title: result.title,
        h1: result.h1,
        slug: result.url_slug,
        meta: meta,
        description: result.description,
        body: result.body,
        is_published: +result.is_published
      };
    } else {
      data = {
        title: "",
        h1: "",
        slug: "",
        meta: [],
        description: "",
        body: "<p>Page content here.</p>",
        is_published: 1
      };
    }
    const formData = reactive(data);

    const rules = {
      title: {
        required,
        minLength: minLength(2)
      },
      h1: {
        required,
        minLength: minLength(2)
      },
      slug: {
        required,
        minLength: minLength(2)
      },
      meta: {
        required,
        minLength: minLength(1)
      },
      description: {
        required,
        minLength: minLength(6)
      },
      body: {
        required,
        maxLength: minLength(6)
      }
    };

    function addMetasToPayload(metas) {
      formData.meta = [...formData.meta, ...metas];
    }

    function removeExisitingMeta(index) {
      formData.meta.splice(index, 1);
    }

    const validate = useVuelidate(rules, toRefs(formData));
    const metaComp = ref();

    function createSlug() {
      formData.slug = beautifySlug(
        "/" +
        formData.title
          .toLowerCase()
          .replace(/ /g, "-")
          .replace(/[^\w-]+/g, "")
      );
    }

    const save = async () => {
      validate.value.$touch();
      if (!formData.meta.length) {
        metaComp.value.saveAllNewMetas();
      }
      if (validate.value.$invalid) {
        throw new Error("Form Validation Failed");
      } else {
        formData.created_by = adminUser.value.id;
        await savePage(apiEndpoints.save, formData);
      }
    };

    const update = async () => {
      validate.value.$touch();
      if (!formData.meta.length) {
        metaComp.value.saveAllNewMetas();
      }
      if (validate.value.$invalid) {
        throw new Error("Form Validation Failed");
      }
      formData.updated_by = adminUser.value.id;
      await updatePage(apiEndpoints.update, formData);
    };

    onMounted(() => {
      pickSubmitFunction();
    })
    const pickSubmitFunction = () => {
      const validateAndSubmit = inject("validateAndSubmit");
      if (route.name === 'content-management-edit-page') {
        validateAndSubmit.value = update;
      } else if (route.name === 'content-management-add-page') {
        validateAndSubmit.value = save;
      }
    }

    function beautifySlug(givenSlug) {
      return givenSlug.toUpperCase().charAt(0) + givenSlug.slice(1);
    }

    return {
      beautifySlug,
      validate,
      metaComp,
      formData,
      createSlug,
      save,
      route,
      errors,
      update,
      addMetasToPayload,
      removeExisitingMeta,
      validationErrors
    };
  }
});
</script>

<style scoped>

</style>
