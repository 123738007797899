import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";

const state = () => {
  return {
    filteredAgentName: "",
    selectedAgent: 0,
    lendingAgents: [],
    unassignedLoans: [],
    selectedUnassignedLoans: [],
    selectedAssignedLoans: [],
    busy: true,
    fetchingLendingAgents: false,
    fetchingUnassignedLoans: false,
    lnConverterDelegBrdAlertMessage: "",
    lnConverterDelegBrdErrorMessage: "",
    lnConverterDelegBrdSuccessMessage: ""
  };
};

// getters
const getters = {
  filteredAgentName: state => state.filteredAgentName,
  selectedAgent: state => state.selectedAgent,
  lendingAgents: state => state.lendingAgents,
  unassignedLoans: state => state.unassignedLoans,
  selectedUnassignedLoans: state => state.selectedUnassignedLoans,
  selectedAssignedLoans: state => state.selectedAssignedLoans,
  busy: state => state.busy,
  fetchingLendingAgents: state => state.fetchingLendingAgents,
  fetchingUnassignedLoans: state => state.fetchingUnassignedLoans,
  lnConverterDelegBrdAlertMessage: state =>
    state.lnConverterDelegBrdAlertMessage,
  lnConverterDelegBrdErrorMessage: state =>
    state.lnConverterDelegBrdErrorMessage,
  lnConverterDelegBrdSuccessMessage: state =>
    state.lnConverterDelegBrdSuccessMessage
};

// actions
const actions = {
  getUnassignedLoansForLendingConverterAssignment: async (
    { commit, dispatch },
    customSuccessMessage = ""
  ) => {
    commit("setFetchingUnassignedLoans");
    axiosWrapperService
      .axiosGet("/getUnassignedLoansForLendingConverterAssignment")
      .then(response => {
        commit("setUnassignedLoans", response.data.data);
        if (customSuccessMessage !== "") {
          dispatch(
            "globalToastMessage/showSimpleToast",
            { message: customSuccessMessage, type: "success" },
            { root: true }
          );
        }
      })
      .catch(error => {
        commit(
          "setLnDelegationBrdErrorMessage",
          formatErrorMessage(error, true)
        );
      })
      .finally(() => {
        commit("resetFetchingUnassignedLoans");
      });
  },
  getLendingConverterAdminsWithAssociationsForLendingBoard: async (
    { commit, dispatch, state },
    customSuccessMessage = ""
  ) => {
    commit("setFetchingLendingAgents");
    let url = "/getLendingConverterAdminsWithAssociationsForLendingBoard";
    if (state.filteredAgentName !== "")
      url += "?name=" + state.filteredAgentName;
    axiosWrapperService
      .axiosGet(url)
      .then(response => {
        commit("setLendingAgents", response.data.data);
        if (customSuccessMessage !== "") {
          dispatch(
            "globalToastMessage/showSimpleToast",
            { message: customSuccessMessage, type: "success" },
            { root: true }
          );
        }
      })
      .catch(error => {
        commit("setAdminListErrorMessage", formatErrorMessage(error, true));
      })
      .finally(() => {
        commit("resetFetchingLendingAgents");
      });
  },
  submitUnassignedApplicationsForAssignment: async ({
    commit,
    dispatch,
    state
  }) => {
    axiosWrapperService
      .axiosPost(
        "/assignAssessmentsToLendingConverter/" + state.selectedAgent,
        {
          unassigned_assessment_uuids: state.selectedUnassignedLoans
        }
      )
      .then(() => {
        commit("clearSelectedUnassignedLoans");
        commit("setSelectedAgent", 0);
        dispatch("getLendingConverterAdminsWithAssociationsForLendingBoard");
        dispatch(
          "getUnassignedLoansForLendingConverterAssignment",
          "Successfully assigned Applications to the agent !"
        );
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("main/resetFullPageLoading", null, { root: true });
      });
  },
  submitAssignedApplicationsForUnassignment: async ({
    commit,
    dispatch,
    state
  }) => {
    axiosWrapperService
      .axiosPost("/unassignAssessmentsFromLendingConverter", {
        assigned_assessment_uuids: state.selectedAssignedLoans
      })
      .then(() => {
        commit("clearSelectedAssignedLoans");
        commit("setSelectedAgent", 0);
        dispatch("getLendingConverterAdminsWithAssociationsForLendingBoard");
        dispatch(
          "getUnassignedLoansForLendingConverterAssignment",
          "Successfully unassigned Applications from agent !"
        );
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("main/resetFullPageLoading", null, { root: true });
      });
  }
};

// mutations
const mutations = {
  setFilteredAgentName(state, agentId) {
    state.filteredAgentName = agentId;
  },
  setSelectedAgent(state, agentId) {
    state.selectedAgent = agentId;
  },
  setAllUnassignedLoansForSelection(state, agentId) {
    state.selectedUnassignedLoans = state.unassignedLoans.map(unln => unln.id);
  },
  pushToSelectedAssignedLoans(state, assignedLoanId) {
    if (!state.selectedAssignedLoans.includes(assignedLoanId)) {
      state.selectedAssignedLoans.push(assignedLoanId);
    }
  },
  removeFromSelectedAssignedLoans(state, assignedLoanId) {
    if (state.selectedAssignedLoans.includes(assignedLoanId)) {
      state.selectedAssignedLoans = state.selectedAssignedLoans.filter(
        item => item !== assignedLoanId
      );
    }
  },
  pushToSelectedUnassignedLoans(state, unassignedLoanId) {
    if (!state.selectedUnassignedLoans.includes(unassignedLoanId)) {
      state.selectedUnassignedLoans.push(unassignedLoanId);
    }
  },
  removeFromSelectedUnassignedLoans(state, unassignedLoanId) {
    if (state.selectedUnassignedLoans.includes(unassignedLoanId)) {
      state.selectedUnassignedLoans = state.selectedUnassignedLoans.filter(
        item => item !== unassignedLoanId
      );
    }
  },
  clearSelectedUnassignedLoans(state) {
    state.selectedUnassignedLoans = [];
  },
  clearSelectedAssignedLoans(state) {
    state.selectedAssignedLoans = [];
  },
  setBusy(state) {
    state.busy = true;
  },
  resetBusy(state) {
    state.busy = false;
  },
  setFetchingLendingAgents(state) {
    state.fetchingLendingAgents = true;
  },
  resetFetchingLendingAgents(state) {
    state.fetchingLendingAgents = false;
  },
  setFetchingUnassignedLoans(state) {
    state.fetchingUnassignedLoans = true;
  },
  resetFetchingUnassignedLoans(state) {
    state.fetchingUnassignedLoans = false;
  },
  setLendingAgents(state, lendingAgents) {
    state.lendingAgents = lendingAgents;
  },
  setUnassignedLoans(state, unassignedLoans) {
    state.unassignedLoans = unassignedLoans;
  },
  setLnDelegationBrdAlertMessage(state, lnConverterDelegBrdAlertMessage) {
    state.lnConverterDelegBrdAlertMessage = lnConverterDelegBrdAlertMessage;
  },
  setLnDelegationBrdErrorMessage(state, lnConverterDelegBrdErrorMessage) {
    state.lnConverterDelegBrdErrorMessage = lnConverterDelegBrdErrorMessage;
  },
  setLnDelegationBrdSuccessMessage(state, lnConverterDelegBrdSuccessMessage) {
    state.lnConverterDelegBrdSuccessMessage = lnConverterDelegBrdSuccessMessage;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
