<template>
  <div>
    <div class="post intro-y flex overflow-hidden box mt-5 bg-gray-300">
      <div v-for="tab in tabs" :key="tab.name">
        <template v-if="!isEditable">
          <RouterLink :to="tab.path">
            <div
                class="post__tabs nav nav-tabs flex-col sm:flex-row bg-gray-300 dark:bg-dark-2 text-gray-600"
                role="tablist"
            >
              <Tippy
                  id="content-tab"
                  :class="{
                  'bg-white': isActiveTab(tab.name),
                   'text-theme-1': isActiveTab(tab.name)
                 }"
                  :content="formatTabName(tab.name)"
                  aria-controls="content"
                  aria-selected="true"
                  class="w-full sm:w-40 py-4 text-center flex justify-center items-center"
                  data-target="#content"
                  data-toggle="tab"
                  href="javascript:;"
                  role="tab"
                  tag="a"
              >
                <FileTextIcon class="w-4 h-4 mr-2"/>
                {{ tab.label }}
              </Tippy>
            </div>
          </RouterLink>
        </template>
        <div v-else>
          <div
              class="post__tabs nav nav-tabs flex-col sm:flex-row bg-gray-300 dark:bg-dark-2 text-gray-600"
              role="tablist"
          >
            <Tippy
                id="content-tab"
                :class="{
              'bg-white': isActiveTab(tab.name),
              'text-theme-1': isActiveTab(tab.name)
              }"
                :content="formatTabName(tab.name)"
                aria-selected="false"
                class="w-full sm:w-40 py-4 text-center flex justify-center items-center"
            >
              <FileTextIcon class="w-4 h-4 mr-2"/>
              {{ tab.label }}
            </Tippy>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue";
import router from "@/router";

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  tabs: {
    type: Array,
    required: true
  }
});
const isActiveTab = (name) => {
  return router.currentRoute.value.name.endsWith(name);
};

const isEditable = computed(() => {
  return ['content-management-edit-page', 'content-management-edit-component'].includes(router.currentRoute.value.name);
});

const formatTabName = (name) => {
  return name.replace(/-/g, " ");
};
</script>
