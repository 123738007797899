export function parseMessage(message) {
  let icon = "";
  let title = "";
  let body = "";

  if (message.includes("%iconbreak%")) {
    icon = message.split("%iconbreak%")[0];
    body = message.split("%iconbreak%")[1];
  }
  if (message.includes("%titlebreak%")) {
    title = body.split("%titlebreak%")[0];
    body = body.split("%titlebreak%")[1];
  }

  return {
    icon: icon,
    title: title,
    body: body
  };
}
