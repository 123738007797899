import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { buildCustomerAddressAttribute } from "@/utils/customerAttributesBuilder";
import { formatErrorMessage } from "../utils/messageFormatter";

const state = () => {
  return {
    applicationId: 0,
    customerId: 0,
    loanFollowUpUuid: "",
    assessmentUuid: "",
    loanFollowUp: {},
    assessment: {},
    kycWithAdditionalAttributes: {
      basicAttributes: {},
      derivedAttributes: {}
    },
    documentRequestList: [],
    documentTypeList: [],
    busy: false,
    followUpBucketUpdateReqCounter: 0,
    docFileUploadValidationMessage: "",
    loanFollowUpDetailAlertMessage: "",
    loanFollowUpDetailErrorMessage: "",
    loanFollowUpDetailSuccessMessage: ""
  };
};

// getters
const getters = {
  applicationId: state => state.applicationId,
  customerId: state => state.customerId,
  assessment: state => state.assessment,
  loanFollowUp: state => state.loanFollowUp,
  assessmentUuid: state => state.assessmentUuid,
  loanFollowUpUuid: state => state.loanFollowUpUuid,
  kycWithAdditionalAttributes: state => state.kycWithAdditionalAttributes,
  documentRequestList: state => state.documentRequestList,
  documentTypeList: state => state.documentTypeList,
  busy: state => state.busy,
  followUpBucketUpdateReqCounter: state => state.followUpBucketUpdateReqCounter,
  docFileUploadValidationMessage: state => state.docFileUploadValidationMessage,
  loanFollowUpDetailAlertMessage: state => state.loanFollowUpDetailAlertMessage,
  loanFollowUpDetailErrorMessage: state => state.loanFollowUpDetailErrorMessage,
  loanFollowUpDetailSuccessMessage: state =>
    state.loanFollowUpDetailSuccessMessage
};

// actions
const actions = {
  getLoanFollowUp: async ({ dispatch, state, commit }) => {
    try {
      commit("clearLoanFollowUpDetailMessages");
      axiosWrapperService
        .axiosGet("/loanFollowUps/" + state.loanFollowUpUuid)
        .then(response => {
          commit("setLoanFollowUp", response.data.data);
          commit("setApplicationId", response.data.data.application_id);
          // Setting the ids for follow-up page
          commit(
            "loanAssessmentInfos/setApplicationId",
            response.data.data.application_id,
            { root: true }
          );
          commit("setCustomerId", response.data.data.assessment.user_id);
          //Follow up components
          commit("setAssessmentUuid", response.data.data.assessment.uuid);
          // TODO : remove duplicate action methods and invocations
          dispatch("getKycAttributesForLoanFollowUp");
          dispatch("getDocumentRequestsOfLoan");
          commit("main/resetFullPageLoading", null, { root: true });
        });
    } catch (e) {
      commit("setLoanFollowUpDetailError", "Failed to get the list.");
    }
  },
  completeLoanFollowUp: async ({ state, commit }, closureNote) => {
    try {
      commit("clearLoanFollowUpDetailMessages");
      axiosWrapperService
        .axiosPatch(
          "/setLoanFollowUpStatusToCompleted/" + state.loanFollowUpUuid,
          { note: closureNote }
        )
        .then(response => {
          commit("setLoanFollowUp", response.data.data);
          commit("incrementFollowUpBucketUpdateReqCounter");
        })
        .catch(error => {
          commit("setLoanFollowUpDetailError", formatErrorMessage(error, true));
        });
    } catch (e) {
      commit("setLoanFollowUpDetailError", "Failed to get the list.");
    }
  },
  setLoanFollowUpAsContactFailed: async ({ state, commit }, closureNote) => {
    try {
      commit("clearLoanFollowUpDetailMessages");
      axiosWrapperService
        .axiosPatch(
          "/setLoanFollowUpStatusToContactFailed/" + state.loanFollowUpUuid,
          { note: closureNote }
        )
        .then(response => {
          commit("setLoanFollowUp", response.data.data);
          commit("incrementFollowUpBucketUpdateReqCounter");
        })
        .catch(error => {
          commit("setLoanFollowUpDetailError", formatErrorMessage(error, true));
        });
    } catch (e) {
      commit("setLoanFollowUpDetailError", "Failed to get the list.");
    }
  },
  setLoanFollowUpStatusToFollowUpStatusCancelled: async (
    { state, commit },
    closureNote
  ) => {
    try {
      commit("clearLoanFollowUpDetailMessages");
      axiosWrapperService
        .axiosPatch(
          "/setLoanFollowUpStatusToFollowUpStatusCancelled/" +
            state.loanFollowUpUuid,
          { note: closureNote }
        )
        .then(response => {
          commit("setLoanFollowUp", response.data.data);
          commit("incrementFollowUpBucketUpdateReqCounter");
        })
        .catch(error => {
          commit("setLoanFollowUpDetailError", formatErrorMessage(error, true));
        });
    } catch (e) {
      commit("setLoanFollowUpDetailError", "Failed to get the list.");
    }
  },
  withdrawLoanApplication: async ({ commit }, lnAssessmentFinalizationObj) => {
    try {
      commit("clearLoanFollowUpDetailMessages");
      axiosWrapperService
        .axiosPatch(
          "/withdrawLoanApplication/" + lnAssessmentFinalizationObj.uuid,
          {
            note: lnAssessmentFinalizationObj.note
          }
        )
        .then(() => {
          commit("incrementFollowUpBucketUpdateReqCounter");
        })
        .catch(error => {
          commit("setLoanFollowUpDetailError", formatErrorMessage(error, true));
        })
        .finally(() => {
          commit("main/resetFullPageLoading", null, { root: true });
        });
    } catch (e) {
      commit("setError", "Failed to get the list.");
    }
  },
  getKycAttributesForLoanFollowUp: async ({ state, commit }) => {
    try {
      commit("clearLoanFollowUpDetailMessages");
      axiosWrapperService
        .axiosGet(
          "/applications/" + state.applicationId + "/getKycAttributesOfLoan"
        )
        .then(response => {
          commit(
            "customerDetails/setCustomerDeleted",
            !!response.data.data?.is_anonymous,
            {
              root: true
            }
          );
          commit(
            "clearAndPushToKycWithAdditionalAttributes",
            response.data.data
          );
        });
    } catch (e) {
      commit("setLoanFollowUpDetailError", "Failed to get the list.");
    }
  },
  getDocumentRequestsOfLoan: async ({ state, commit }) => {
    try {
      commit("clearLoanFollowUpDetailMessages");
      axiosWrapperService
        .axiosGet("/getDocumentRequestsOfLoan/" + state.applicationId)
        .then(response => {
          commit("clearAndPushToDocumentRequestList", response.data.data);
        });
    } catch (e) {
      commit("setLoanFollowUpDetailError", "Failed to get the list.");
    }
  },
  getDocumentGalleryItemFile: async ({ commit }, galleryItemInstance) => {
    try {
      commit("clearLoanFollowUpDetailMessages");
      axiosWrapperService
        .axiosGet("/getDocumentGalleryItemFile/" + galleryItemInstance.id, {
          responseType: "blob"
        })
        .then(response => {
          const pdfBlob = new Blob([response.data]);

          const blobUrl = window.URL.createObjectURL(pdfBlob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.setAttribute("download", galleryItemInstance.file_name);
          link.click();
          link.remove();
          URL.revokeObjectURL(blobUrl);
        });
    } catch (e) {
      commit("setLoanFollowUpDetailError", "Failed to get the list.");
    }
  },
  getAllDefaultDocumentTypes: async ({ commit }) => {
    try {
      commit("clearLoanFollowUpDetailMessages");
      axiosWrapperService
        .axiosGet("/getAllDefaultDocumentTypes")
        .then(response => {
          commit("clearAndPushToDocumentTypeList", response.data.data);
        });
    } catch (e) {
      commit("setLoanFollowUpDetailError", "Failed to get the list.");
    }
  }
};

// mutations
const mutations = {
  clearAndPushToKycWithAdditionalAttributes(
    state,
    kycWithAdditionalAttributes
  ) {
    state.kycWithAdditionalAttributes.basicAttributes = kycWithAdditionalAttributes;
    state.kycWithAdditionalAttributes.derivedAttributes = buildCustomerAddressAttribute(
      kycWithAdditionalAttributes.additional_attributes
    );
  },
  setBusy(state) {
    state.busy = true;
  },
  resetBusy(state) {
    state.busy = false;
  },
  clearAndPushToDocumentRequestList(state, documentRequestList) {
    state.documentRequestList = documentRequestList;
  },
  clearAndPushToDocumentTypeList(state, documentTypeList) {
    state.documentTypeList = documentTypeList;
  },
  setApplicationId(state, applicationId) {
    state.applicationId = applicationId;
  },
  setCustomerId(state, customerId) {
    state.customerId = customerId;
  },
  setLoanFollowUpUuid(state, loanFollowUpUuid) {
    state.loanFollowUpUuid = loanFollowUpUuid;
  },
  setAssessmentUuid(state, assessmentUuid) {
    state.assessmentUuid = assessmentUuid;
  },
  setLoanFollowUp(state, loanFollowUp) {
    state.loanFollowUp = loanFollowUp;
  },
  setDocFileUploadValidationMessage(state, docFileUploadValidationMessage) {
    state.docFileUploadValidationMessage = docFileUploadValidationMessage;
  },
  setLoanFollowUpDetailError(state, loanFollowUpDetailErrorMessage) {
    state.loanFollowUpDetailErrorMessage = loanFollowUpDetailErrorMessage;
  },
  setLoanFollowUpDetailSuccess(state, loanFollowUpDetailSuccessMessage) {
    state.loanFollowUpDetailSuccessMessage = loanFollowUpDetailSuccessMessage;
  },
  setLoanFollowUpDetailAlert(state, loanFollowUpDetailAlertMessage) {
    state.loanFollowUpDetailAlertMessage = loanFollowUpDetailAlertMessage;
  },
  incrementFollowUpBucketUpdateReqCounter(state) {
    state.followUpBucketUpdateReqCounter++;
  },
  clearLoanFollowUpDetailMessages(state) {
    state.docFileUploadValidationMessage = "";
    state.loanFollowUpDetailErrorMessage = "";
    state.loanFollowUpDetailAlertMessage = "";
    state.loanFollowUpDetailSuccessMessage = "";
  },
  clearLoanFollowUpDetailAlertMessages(state) {
    state.loanFollowUpDetailAlertMessage = "";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
