const state = () => {
  return {
    menu: [
      {
        icon: "HomeIcon",
        pageName: "side-menu-dashboard-overview-1",
        title: "Dashboard"
      },
      // {
      //   icon: "BoxIcon",
      //   pageName: "side-menu-menu-layout",
      //   title: "Menu Layout",
      //   subMenu: [
      //     {
      //       icon: "",
      //       pageName: "side-menu-dashboard-overview-1",
      //       title: "Side Menu",
      //       ignore: true
      //     },
      //     {
      //       icon: "",
      //       pageName: "simple-menu-dashboard-overview-1",
      //       title: "Simple Menu",
      //       ignore: true
      //     },
      //     {
      //       icon: "",
      //       pageName: "top-menu-dashboard-overview-1",
      //       title: "Top Menu",
      //       ignore: true
      //     }
      //   ]
      // },
      {
        icon: "SearchIcon",
        pageName: "side-menu-search",
        title: "Search"
      },
      {
        icon: "InboxIcon",
        pageName: "payouts",
        title: "Payouts",
        subMenu: [
          {
            icon: "menu",
            pageName: "payouts",
            title: "All Conversions"
          },
          {
            icon: "download",
            pageName: "disbursed-payouts",
            title: "Fund Disbursed"
          },
          {
            icon: "pause",
            pageName: "pending-payouts",
            title: "Pending Clearance"
          },
          {
            icon: "pause",
            pageName: "pre-check-failed",
            title: "Pre-check Failed"
          },
          {
            icon: "x-octagon",
            pageName: "failed-payouts",
            title: "Disbursal Failed"
          }
        ]
      },
      {
        icon: "ShareIcon",
        pageName: "refunds",
        title: "Refund Requests",
        subMenu: [
          {
            icon: "menu",
            pageName: "new-refunds",
            title: "New Requests"
          },
          {
            icon: "menu",
            pageName: "completed-refunds",
            title: "Completed Refunds"
          },
          {
            icon: "menu",
            pageName: "rejected-refunds",
            title: "Rejected Refunds"
          },
          {
            icon: "download",
            pageName: "refunds",
            title: "Refunds History"
          },
          {
            icon: "pause",
            pageName: "failed-refunds",
            title: "Refund Failed"
          }
        ]
      },
      {
        icon: "ClipboardIcon",
        pageName: "lending-dashboard",
        title: "Lending Dashboard",
        subMenu: [
          {
            icon: "ClipboardIcon",
            pageName: "assessment-lending-dashboard",
            title: "Assessments Dashboard"
          },
          {
            icon: "ClipboardIcon",
            pageName: "approval-lending-dashboard",
            title: "Approval Dashboard"
          },
          {
            icon: "ClipboardIcon",
            pageName: "conversion-lending-dashboard",
            title: "Conversion Dashboard"
          },
          {
            icon: "ClipboardIcon",
            pageName: "closure-review-dashboard",
            title: "Closure Review Dashboard"
          }
        ]
      },
      {
        icon: "UserCheckIcon",
        pageName: "delegation-dashboard",
        title: "Delegation Dashboard",
        subMenu: [
          {
            icon: "ClipboardIcon",
            pageName: "ln-app-assessor-delegation-board",
            title: "Assessor Delegation Board"
          },
          {
            icon: "ClipboardIcon",
            pageName: "ln-app-approver-delegation-board",
            title: "Approver Delegation Board"
          },
          {
            icon: "ClipboardIcon",
            pageName: "ln-app-converter-delegation-board",
            title: "Converter Delegation Board"
          },
          {
            icon: "ClipboardIcon",
            pageName: "ln-app-closure-review-delegation-board",
            title: "Closure Review Delegation Board"
          },
          {
            icon: "PhoneForwardedIcon",
            pageName: "ln-follow-up-delegation-brd",
            title: "FollowUp Delegation Board"
          }
        ]
      },
      {
        icon: "PhoneCallIcon",
        pageName: "loan-follow-up-dashboard",
        title: "Application Follow Ups"
      },
      {
        icon: "UsersIcon",
        pageName: "admins",
        title: "Admins",
        subMenu: [
          {
            icon: "menu",
            pageName: "add-admin",
            title: "Add Admin"
          },
          {
            icon: "star",
            pageName: "admin-list",
            title: "Admin Lists"
          }
        ]
      },
      {
        icon: "UserCheckIcon",
        pageName: "customers",
        title: "Customers"
      },
      {
        icon: "MenuIcon",
        pageName: "loan-applications",
        title: "Loan Applications"
      },
      {
        icon: "MenuIcon",
        pageName: "unsigned-loans",
        title: "Unsigned Loans"
      },
      {
        icon: "PhoneMissedIcon",
        pageName: "contact-failed-loans",
        title: "Contact Failed Loans"
      },
      {
        icon: "EditIcon",
        pageName: "bank-account-list",
        title: "Payer Bank Accounts"
      },
      {
        icon: "LayoutIcon",
        pageName: "content-management",
        title: "Content Management",
        subMenu: [
          {
            icon: "menu",
            pageName: "content-management-add",
            title: "Add"
          },
          {
            icon: "star",
            pageName: "content-management-list",
            title: "List"
          }
        ]
      }
    ]
  };
};

// getters
const getters = {
  menu: state => state.menu
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
