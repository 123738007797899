<template>
  <!-- BEGIN: Modal Content -->
  <div
    id="skip-checks-reason-form-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base mr-auto">
            Skip {{ $h.capsFirstLetterOfWord(type) }} Check
          </h2>
        </div>
        <!-- END: Modal Header -->
        <!-- BEGIN: Modal Body -->
        <div
          v-if="errorMessage !== ''"
          class="alert alert-danger-soft show flex items-center mb-2"
          role="alert"
        >
          <AlertOctagonIcon class="w-6 h-6 mr-2" />
          {{ errorMessage }}
        </div>
        <div class="modal-body grid grid-cols-12">
          <div class="col-span-12 grid grid-cols-2"></div>
          <div class="col-span-12 mt-2">
            <label class="form-label"> Reason to bypass:</label>
            <textarea
              v-model="reason"
              type="text"
              class="form-control"
              rows="2"
            >
            </textarea>
            <div v-if="validationErrors.reason" class="p-1 text-red-600">
              {{ validationErrors.reason[0] }}
            </div>
          </div>
        </div>
        <!-- END: Modal Body -->
        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer text-right">
          <button
            type="button"
            data-dismiss="modal"
            class="btn btn-dark-soft w-20 mr-1"
          >
            Cancel
          </button>
          <button
            v-if="!isBusy"
            type="button"
            class="btn btn-success w-40"
            @click="submitForm"
          >
            Submit
          </button>
          <button v-else class="btn btn-success w-40">
            Processing
            <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  setup() {
    const store = useStore();

    const reason = computed({
      get: () => store.getters["skipChecks/reason"],
      set: value => {
        store.commit("skipChecks/setReason", value);
      }
    });

    const type = computed(() => store.getters["skipChecks/type"]);

    const isBusy = computed(() => store.getters["skipChecks/isBusy"]);

    const validationErrors = computed(
      () => store.getters["skipChecks/validationErrors"]
    );
    const errorMessage = computed(
      () => store.getters["skipChecks/errorMessage"]
    );

    function submitForm() {
      store.dispatch("skipChecks/skipCheck", {
        reason: reason.value
      });
    }

    return {
      reason,
      submitForm,
      validationErrors,
      errorMessage,
      isBusy,
      type
    };
  }
});
</script>

<style scoped></style>
