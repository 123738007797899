<template>
  <div>
    <div
      :class="[
        { 'bg-theme-32': isActive },
        `loan-${getActiveApplicationQualityClass()}`
      ]"
      class="flex mt-1 p-4 box cursor-pointer section-header"
      @click="setNavActiveSection('ln-approval-pre-check-title')"
    >
      <h2
        id="ln-approval-pre-check-title"
        class="font-medium text-theme-7 text-base w-full"
      >
        APPROVAL PRE REQUISITE CHECKS
      </h2>
      <ChevronDownIcon :class="{ 'transform rotate-180': !isActive }" />
    </div>
    <!-- BEGIN: Striped Rows -->
    <div v-if="isActive" class="box">
      <div
        class="
          flex flex-col
          sm:flex-row
          items-center
          p-5
          border-b border-gray-200
          dark:border-dark-5
        "
      >
        <h2 class="font-medium text-theme-3 text-base mr-auto">CHECKS</h2>
      </div>
      <div id="doc-req-table" class="p-5">
        <div class="preview">
          <div class="overflow-x-auto">
            <table class="table">
              <thead>
                <tr>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Verifications
                  </th>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Status
                  </th>
                </tr>
              </thead>
              <template v-if="!busy">
                <tbody v-if="lnApprovalPreRequisiteVerifications.length > 0">
                  <tr
                    v-for="(verifData,
                    verifDataIndex) in lnApprovalPreRequisiteVerifications"
                    :key="verifDataIndex"
                    :class="{
                      'bg-gray-200 dark:bg-dark-1': verifDataIndex % 2 > 0
                    }"
                  >
                    <td class="border-b">
                      {{ humanizeVerifLabelSlug(verifData.request) }}
                    </td>
                    <td class="border-b dark:border-dark-5">
                      <span
                        v-if="verifData.process_skipped > 0"
                        class="text-theme-11"
                      >
                        <MinusCircleIcon class="h-5 w-5" />
                        Skipped
                      </span>
                      <span
                        v-else-if="verifData.completed_at !== null"
                        class="text-theme-1"
                      >
                        <CheckCircleIcon class="h-5 w-5" />
                        Verified
                      </span>

                      <span v-else class="text-theme-6">
                        <XCircleIcon class="h-5 w-5" /> Not Verified
                      </span>
                    </td>
                  </tr>
                </tbody>
                <noRecordCustTblTemplate v-else />
              </template>
              <FetchingRecordTblTemplate v-else />
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted } from "vue";
import { useStore } from "@/store";
import noRecordCustTblTemplate from "@/components/no-record-tbl-template/Main.vue";
import FetchingRecordTblTemplate from "@/components/fetching-record-tbl-template/Main";
import { loanQuality } from "@/composables/loanQuality";

export default defineComponent({
  components: {
    noRecordCustTblTemplate,
    FetchingRecordTblTemplate
  },
  props: {
    applicationId: {
      type: Number,
      require: true
    },
    isActive: {
      type: Boolean,
      require: false,
      default: true
    },
    setNavActiveSection: {
      type: Function,
      require: true,
      default: null
    }
  },
  setup(props) {
    const store = useStore();

    const busy = computed(() => store.state.lnApprovalPreRequisites.busy);

    const lnApprovalPreRequisiteVerifications = computed(
      () =>
        store.state.lnApprovalPreRequisites.lnApprovalPreRequisiteVerifications
    );

    function humanizeVerifLabelSlug(slug) {
      const translations = {
        id: "ID",
        auth: "Bank Verification",
        card: "Card Verification",
        docs: "Document Signed"
      };

      if (slug in translations) {
        return translations[slug];
      }

      return slug;
    }

    const { getActiveApplicationQualityClass } = loanQuality();

    onMounted(() => {
      store.dispatch(
        "lnApprovalPreRequisites/getApprovalPreRequisiteVerificationsOfLoan",
        props.applicationId
      );
    });

    return {
      lnApprovalPreRequisiteVerifications,
      busy,
      humanizeVerifLabelSlug,
      getActiveApplicationQualityClass
    };
  }
});
</script>
